function calculateTotalPackagePrice(packagePrice: number, numberOfGuest: number): number {
  if (numberOfGuest <= 0) {
    throw new Error('Number of guests must be greater than 0.');
  }
  const totalPrice = packagePrice * numberOfGuest;
  let discount = 0;

  if (process.env.REACT_APP_PROJECT_COUNTRY === 'uk') {
    if (totalPrice >= 6000) {
      discount = 300;
    } else if (totalPrice >= 5000) {
      discount = 250;
    } else if (totalPrice >= 4000) {
      discount = 200;
    } 
    // else if (totalPrice >= 3000) {
    //   discount = 150;
    // }
  } else if (`${process.env.REACT_APP_PROJECT_COUNTRY}` === 'ie') {
    discount = 100;
  }

  // Calculate total price after applying discount

  return totalPrice - discount;
}

export function calculatePerPersonPrice(packagePrice: number, numberOfGuest: number): number {
  if (numberOfGuest <= 0) {
    throw new Error('Number of guests must be greater than 0.');
  }

  // Calculate total price after discount
  const totalPriceAfterDiscount = calculateTotalPackagePrice(packagePrice, numberOfGuest);

  // Calculate per-person price
  const perPersonPrice = totalPriceAfterDiscount / numberOfGuest;

  return perPersonPrice;
}
