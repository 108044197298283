import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { colors } from "../../../styles/colors";
import attractionImage from "../../../assets/images/card.png";
import Image from "../../../components/Image";
import useWindowWidth from '../../../hooks/useWindowWidth';

interface attractionInterface {
  isPlataniumHotel?: boolean;
}

export const AttractionComponent = ({ isPlataniumHotel }: attractionInterface) => {
  const [selectedAttraction, setSelectedAttraction] = useState(-1);
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL;
  const windowWidth = useWindowWidth();

  return (
    <Box
      sx={{
        backgroundColor: colors.basics.secondary,
        border: `1px solid ${colors.border.grey}`,
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          "&:not(:last-child)": {
            borderBottom: "1px solid #ccc",
          },
        }}
      >
        <Box sx={{ padding: "20px" }}>
          <Typography variant="h1">Attractions</Typography>
          <Grid container
            columnSpacing={2}>
            {[...Array(3)].map((e, i: number) => {
              return (
                <Grid key={i} lg={12} md={6} sm={6} xs={12} item>
                  <Box
                    sx={{
                      border: `1px solid ${colors.border.grey}`,
                      borderRadius: "4px",
                      display: "flex",
                      flexDirection: { lg: "row", md: "column", sm: "column", xs: "column" },
                      padding: 2,
                      gap: 2,
                      mb: 2,
                    }}
                  >
                    <img
                      src={attractionImage}
                      alt="attraction"
                      width={windowWidth < 426 ? "100%" : "160px"}
                      height={"auto"}
                      style={{ objectFit: "cover" }}
                    />
                    <Box sx={{ flex: 1 }}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: { sm: 5, xs: 2 },
                          flex: 1,
                          flexDirection: { lg: "row", md: "column", sm: "column", xs: "column" },
                          alignItems: { lg: "end", md: "start", sm: "start", xs: "start" },
                          paddingBottom: 2,
                          borderBottom: `1px solid ${colors.border.grey}`,
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item lg={7}>
                          <Box display={"flex"} flexDirection={"column"} gap={1}>
                            <Typography variant="h1">First Attraction name</Typography>
                            <Box display={"flex"} flexDirection={"column"} gap={1}>
                              <Box display={"flex"} alignItems={"center"} gap={1}>
                                <TaskAltIcon />
                                <Typography variant="body2">Include this and that</Typography>
                              </Box>
                              <Box display={"flex"} alignItems={"center"} gap={1}>
                                <TaskAltIcon />
                                <Typography variant="body2">Also include all this stuff</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={5} gap={1} sx={{ display: "flex", flexDirection: "column", gap: 1, width: { sm: "100%", xs: "100%", md: "auto" } }}>
                          {[...Array(2)].map((e, i: number) => {
                            return (
                              <Box
                                display={"flex"}
                                gap={{ md: 2, sm: 5, xs: 2 }}
                                flex={1}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Typography variant="body2" fontWeight={700}>
                                  {i % 2 !== 0 ? "Child (2-11 years)" : "Adults"}
                                </Typography>

                                <Box
                                  display={"flex"}
                                  gap={1}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <Typography variant="body2" fontWeight={700}>
                                    {signForPricing}0
                                  </Typography>
                                  <RemoveCircleIcon
                                    sx={{
                                      cursor: "pointer",
                                      color: isPlataniumHotel
                                        ? `${colors.basics.plataniumGreenColor}`
                                        : `${colors.basics.primary}`,
                                    }}
                                  />
                                  <Typography variant="body2" fontWeight={700}>
                                    3
                                  </Typography>
                                  <AddCircleIcon
                                    sx={{
                                      cursor: "pointer",
                                      color: isPlataniumHotel
                                        ? `${colors.basics.plataniumGreenColor}`
                                        : `${colors.basics.primary}`,
                                    }}
                                  />
                                </Box>
                              </Box>
                            );
                          })}
                        </Grid>
                      </Box>
                      <Box pt={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant="h1" fontSize={"30px"}>
                          {signForPricing}2
                        </Typography>
                        <Box
                          sx={{
                            "& .MuiButtonBase-root": {
                              background:
                                i === selectedAttraction
                                  ? colors.basics.black
                                  : isPlataniumHotel
                                    ? `${colors.basics.plataniumGreenColor}`
                                    : `${colors.basics.primary}`,
                              color: colors.basics.white,
                              border:
                                i === selectedAttraction
                                  ? colors.basics.black
                                  : isPlataniumHotel
                                    ? `1px solid ${colors.basics.plataniumGreenColor}`
                                    : `1px solid ${colors.basics.primary}`,
                              "&:hover": {
                                background:
                                  i === selectedAttraction
                                    ? colors.basics.black
                                    : isPlataniumHotel
                                      ? `${colors.basics.plataniumGreenColor}`
                                      : `${colors.basics.primary}`,
                                color: colors.basics.white,
                                border:
                                  i === selectedAttraction
                                    ? colors.basics.black
                                    : isPlataniumHotel
                                      ? `1px solid ${colors.basics.plataniumGreenColor}`
                                      : `1px solid ${colors.basics.primary}`,
                              },
                            },
                          }}
                        >
                          <Button
                            startIcon={i === selectedAttraction ? <TaskAltIcon /> : <AddCircleIcon />}
                            onClick={() => setSelectedAttraction(i)}
                          >
                            {i === selectedAttraction ? "Selected Attraction" : "Add"}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
