import React, { useState } from 'react';
import { Box, Typography, Button, Alert, Snackbar, IconButton } from '@mui/material';
import { CustomContainer } from '../../components/CustomContainer';
import { colors } from '../../styles/colors';
import { TextInput } from '../../components/TextInput';
import { useDispatch } from 'react-redux';
import { checkUserEmailIsExist } from '../../features/actions';
import { userLoginAPI } from '../../features/userAuthentication/userLoginSlice';
import { useAuth } from '../../context/AuthContext';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { setUserDetails } from '../../features/userAuthentication/userInformation';
import { setLoading } from '../../features/loading/loadingSlice';
import { branchCode } from '../../constants/branchCode';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { removeSessionElements } from '../../common/removeSessionElements';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import useWindowWidth from '../../hooks/useWindowWidth';

const validationSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup.string().required("Password is required"),
});

export default function Signin() {
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setIsUserLoggedIn, setLogInUserDetails } = useAuth();
  const [apiErrors, setApiErrors] = useState("")
  const [formData, setFormData] = useState<any>({ password: '', email: '' });
  const [formError, setFormErrors] = useState<any>({});
  // eslint-disable-next-line
  const [_, setCookie] = useCookies(['user-email']);
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [hidePassword, setHidePassword] = useState(true)
  const [buttonClick, setButtonClick] = useState(false)

  const handleFormData = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    const val = { ...formData, [name]: value }
    setFormData(val);

    if (buttonClick) {
      validationSchema
        .validate(val, { abortEarly: false })
        .then(async () => {
          setFormErrors({});
        })
        .catch((err) => {
          const errors: any = {};
          err?.inner?.forEach((e: any) => {
            errors[e.path] = e.message;
          });
          setFormErrors(errors);
          dispatch(setLoading(false))
        });
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleLoginData = (e: any) => {
    setInvalidLogin(false)
    setButtonClick(true)
    e.preventDefault();
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(async () => {
        // console.log(formData, 'with validation');
        dispatch(setLoading(true))
        setFormErrors({});
        const resp = await dispatch(checkUserEmailIsExist(formData?.email));
        if (resp?.payload === '1') {
          // login user account

          const resp = await dispatch(
            userLoginAPI([
              {
                email: formData?.email,
                password: formData?.password,
                check_branch_code: 1,
                branch_code: branchCode,
              },
            ])
          );
          if (resp?.payload?.success === 1) {
            localStorage.setItem('userData', JSON.stringify(resp?.payload?.data));
            removeSessionElements()
            setLogInUserDetails(resp?.payload);
            dispatch(setUserDetails(resp?.payload?.data))
            setIsUserLoggedIn(true);
            setCookie('user-email', resp?.payload?.data?.CustomerUser?.email);
            sessionStorage.removeItem("passenger-info");
            setTimeout(() => {
              dispatch(setLoading(false))
              navigate('/profile/dashboard');
            }, 2)
          } else {
            dispatch(setLoading(false))
            setApiErrors("Invalid login credentials")
            setInvalidLogin(true);
            setOpen(true)
          }
        } else {
          setApiErrors("Something Wrong")
          setInvalidLogin(true);
          setOpen(true)
          dispatch(setLoading(false))
          console.log('🚀  file: GuestAccountDetails.tsx:92  .then ~ login user credential wrong:');
        }
      })
      .catch((err) => {
        const errors: any = {};
        err?.inner?.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setFormErrors(errors);
        dispatch(setLoading(false))
      });
  };

  const forgotPassword = async () => {
    navigate("/forgot-password")
  }

  const handleClickShowPassword = () => {
    setHidePassword(!hidePassword)
  }

  return (
    <>
      <Box bgcolor={colors.basics.secondary} pt={{ xs: windowWidth < 376 ? '130px' : '70px', xl: "0px", lg: '20px', md: '30px', sm: '44px' }}>
        <CustomContainer style={{ marginTop: window.innerWidth > 1200 ? '71px' : '31px', marginBottom: window.innerWidth > 1200 ? '71px' : '31px' }}>
          <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
            <Box m={{ md: '51px', xs: '20px 0' }} mr={{ xl: '0px' }} width={{ xs: '100%' }} maxWidth='700px'>

              {invalidLogin && <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "9px",
                  background: colors.basics.primary,
                  padding: "10px",
                  color: colors.basics.white,
                  width: "fit-content",
                  borderRadius: "5px"
                }}
              >
                <ErrorOutlineIcon />
                <Typography fontWeight={500} color={colors.basics.white}>
                  {apiErrors}
                </Typography>

              </Box>}

              <Typography variant='h1' mt={invalidLogin ? "12px" : 0} fontSize='35px' fontWeight={700} lineHeight='42px'>
                Sign in to your account
              </Typography>
              <Typography variant='body2' mt={{ xs: 1, sm: 3 }} sx={{ fontWeight: 300 }}>
                Upon booking, an account was created for you. Access to this account allows you to save itineraries, confirm bookings and make payments.
              </Typography>
              <Box my={{ xs: '17px', sm: '17px' }} sx={{
                '.Mui-error': {
                  margin: "0",
                },
                '& .MuiOutlinedInput-root': {
                  width: { xs: "100%", sm: "60%" }
                  // windowWidth >= 600 ? "60%" : "100%"
                }
              }}>
                <form className='add-secondary-colour'>
                  <TextInput
                    title='EMAIL ADDRESS'
                    name='email'
                    onChange={handleFormData}
                    error={formError && formError['email'] ? true : false}
                    helperText={formError && formError['email'] ? formError['email'] : ''}
                  />
                  <TextInput
                    title='PASSWORD'
                    type={!hidePassword ? "text" : "password"}
                    name='password'
                    onChange={handleFormData}
                    error={formError && formError['password'] ? true : false}
                    helperText={formError && formError['password'] ? formError['password'] : ''}
                    style={{ marginTop: '25px', marginBottom: '17px' }}
                    EndIcon={
                      <IconButton onClick={() => handleClickShowPassword()}>
                        {!hidePassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                      </IconButton>
                    }
                  />
                  <Box width={{ xs: "100%", sm: "60%" }} mt={formError['password'] ? 4 : 0} display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap'>
                    <Button sx={{ width: { xs: '100%', sm: 'auto' } }} onClick={handleLoginData}>
                      LOG IN
                    </Button>
                    <Typography
                      onClick={() => {
                        forgotPassword()
                      }}
                      sx={{
                        cursor: "pointer"
                      }}
                      mb={"auto"}
                      mt={{ xs: "5px", sm: "0" }}
                      variant='body2'
                      fontWeight={300}
                      textAlign={{ xs: 'right' }}
                      flex={{ xs: 1 }}
                      width={{ xs: '100%' }}
                    >
                      Forgot Password
                    </Typography>
                  </Box>

                </form>
              </Box>
            </Box>
          </Box >
        </CustomContainer >
      </Box>
    </>
  );
}
