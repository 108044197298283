
import { Box, Typography, Tooltip, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { isEmpty } from "../helpers/isEmpty";
import { colors } from "../styles/colors";
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import { ReactComponent as Bed } from '../assets/icons/Bed.svg';
import { ReactComponent as Building } from '../assets/icons/Building.svg';
import { ReactComponent as Calender } from '../assets/icons/Calender.svg';
import { ReactComponent as Car } from '../assets/icons/Car.svg';
import { ReactComponent as Person } from '../assets/icons/Person.svg';
import { ReactComponent as Spoon } from '../assets/icons/Spoon.svg';
import { ReactComponent as KeyboardDoubleArrowRightIcon } from '../assets/icons/DoubleArrow.svg';
// import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { capitalizeFirstLetter } from "../helpers/capitalizeFirstLetter";
import LuggageIcon from '@mui/icons-material/Luggage';
import { capitalizeFirstLetterEachWords } from "../helpers/capitalizeFirstLetterEachWords";
import React, { useEffect, useState } from "react";
import { RoomMealWrapper } from "../helpers/roomMealWrapper";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppSelector } from "../hooks/useAppReducer";
import { formatStringTime } from "../helpers/formatStringTime";
import useWindowWidth from "../hooks/useWindowWidth";

const CustomizeHolidaySummary = (props: any) => {
    const { holiday, disableButton, guest, nights, flightRoom, checkOutDate, hotelName, activeIndex } = props
    const [expanded, setExpanded] = useState<string | false>("panel1");
    const { hotelDetails } = useAppSelector((state) => state.hotelDetails);
    const isPlataniumHotel = Number(hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel) !== 0
    const windowWidth = useWindowWidth()
    useEffect(() => {
        if (activeIndex === 0) {
            setExpanded("panel1")
        } else if (activeIndex === 1) {
            setExpanded("panel2")
        }
    }, [activeIndex])

    const renderFlightUI = () => {
        return (
            <Box sx={{
                width: { lg: "50%", xs: "100%" },
                padding: { sm: "0 10px", xs: "0 0 10px" }
            }}
            >
                <Box display={{ xs: "none", lg: "block" }} mb='10px' ml='5px'>
                    <Typography variant="h1" sx={{ fontWeight: "400", fontSize: "20px" }}>Flight Details</Typography>
                </Box>
                <Box
                    sx={{
                        '& ul': {
                            padding: '0',
                            listStyle: 'none',
                            '& li': {
                                padding: '0px 5px',
                                '& .content': {
                                    alignSelf: 'center'
                                },
                                '&:nth-of-type(odd)': {
                                    // background: colors.grey[20],
                                },
                            },
                        },
                        '.Icon': {
                            width: '100%',
                            maxWidth: '36px',
                            fontSize: { lg: "1.7rem", xs: windowWidth < 375 ? '1.4rem' : '1.5rem' },
                        },
                        '.Icon svg path': {
                            fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary
                          }
                    }}
                >
                    <ul>
                        <li>
                            {isEmpty(holiday?.flight?.outbound[0]?.des_airport !== "") && <Box display='flex' flexDirection='column'>
                                <Box display='flex' mb='10px'>
                                    <Box
                                        className='Icon'
                                        // mt={0.4}
                                        sx={{
                                            '& svg': {
                                                fill: colors.basics.primary,
                                                transform: 'rotate(-270deg)',
                                            },
                                        }}
                                    >
                                        <Tooltip title='Outbound'>
                                            <AirplanemodeActiveIcon />
                                        </Tooltip>
                                    </Box>
                                    <Box className='content'>

                                        <Typography variant='body1' fontWeight={500} lineHeight={'1.2'} fontSize={{ lg: "16px", xs: "14px" }}>
                                            {isEmpty(holiday?.flight?.outbound[0]?.outbound_date)}
                                        </Typography>
                                        <Typography variant='body1' fontWeight={500} lineHeight={'1.2'} fontSize={{ lg: "16px", xs: "14px" }}>
                                            {/* {isEmpty(holiday?.flight?.outbound[0]?.outbound_date)}  {isEmpty(holiday?.flight?.outbound[0].airline_name) !== "" && <>
                                                {`${" - " + holiday?.flight?.outbound[0].airline_name + " - "} (${isEmpty(holiday?.flight?.outbound[0].airline_code)})`}
                                            </>} */}
                                            {isEmpty(holiday?.flight?.outbound[0].airline_name) !== "" && <>
                                                {`${holiday?.flight?.outbound[0].airline_name + " - "} (${isEmpty(holiday?.flight?.outbound[0].airline_code)})`}
                                            </>}
                                        </Typography>
                                        {isEmpty(holiday.flight.outbound[0].dep_airport) !== "" && isEmpty(holiday.flight.outbound[0].des_airport) !== "" && <Typography
                                            variant='body1'
                                            lineHeight={'1.2'}
                                            fontWeight={500}
                                            fontSize={{ lg: "16px", xs: "14px" }}
                                            sx={{
                                                '& svg': {
                                                    display: "inline-block",
                                                    verticalAlign: "center",
                                                    height: { lg: "14px", xs: "12px" },
                                                    width: { lg: "14px", xs: "12px" },
                                                    ml: "4px"
                                                }
                                            }}
                                        >
                                            {isEmpty(holiday.flight.outbound[0].flightDeparture) !== "" && " (" + isEmpty(holiday.flight.outbound[0].flightDeparture) + ") -  "}
                                            <span >{isEmpty(holiday.flight.outbound[0].dep_airport)} <KeyboardDoubleArrowRightIcon /> </span>
                                            {' '}
                                        </Typography>
                                        }

                                        {isEmpty(holiday.flight.outbound[0].des_airport) !== "" && <Typography fontSize={{ lg: "16px", xs: "14px" }} variant='body1'
                                            lineHeight={'1.2'}
                                            fontWeight={500}
                                        >
                                            {isEmpty(holiday?.flight?.outbound[0].arrival_time) !== "" && <span>{"(" + isEmpty(holiday?.flight?.outbound[0].arrival_time) + ") - "}</span>}
                                            {isEmpty(holiday?.flight?.outbound[0].des_airport) !== "" && <span>{isEmpty(holiday?.flight?.outbound[0].des_airport)}</span>}
                                        </Typography>}

                                        <Typography mt="8px" variant='body1' fontSize={{ lg: "16px", xs: "14px" }} fontWeight={500} lineHeight={'1.2'}>
                                            {isEmpty(formatStringTime(holiday?.outboundTime))}  ({isEmpty(holiday?.flight?.outbound[0].stop)})
                                        </Typography>
                                        <Typography variant="body1" fontSize={{ lg: "16px", xs: "14px" }} fontWeight={500} lineHeight={1.2}>
                                            {Array.isArray(holiday?.flight?.outbound[0].layover) && (holiday?.flight?.outbound[0].layover?.filter((layover: any) => layover !== null)
                                                .map((layover: any, index: any) => (
                                                    <span style={{ fontWeight: '500', lineHeight: '1.2' }} key={index}>
                                                        {index > 0 && <br />}
                                                        {`Layover ${index + 1} (${layover?.time
                                                            ?.replace(/0\s*hr\s*/g, '')                    // Remove "0 hr"
                                                            .replace(/\s*m/g, 'm')                         // Remove space before "m"
                                                            .replace(/(\d+)\s*hr/g, '$1hr')                // Ensure "1 hr" becomes "1hr"
                                                            .trim()                                        // Remove any leading/trailing spaces
                                                            }) `}
                                                        - {layover?.layover_airport_city} ({layover?.code})
                                                    </span>
                                                )))}
                                        </Typography>


                                    </Box>
                                </Box>
                                <Box display='flex'>
                                    <Box
                                        className='Icon'
                                        // mt={0.4}
                                        sx={{
                                            '& svg': {
                                                fill: colors.basics.primary,
                                                transform: 'rotate(270deg)',
                                            },
                                        }}
                                    >
                                        <Tooltip title='Inbound'>
                                            <AirplanemodeActiveIcon />
                                        </Tooltip>
                                    </Box>

                                    <Box className='content'>
                                        <Typography variant='body1' fontSize={{ lg: "16px", xs: "14px" }} fontWeight={500} lineHeight='1.2'>
                                            {holiday.flight.inbound[0].inbound_date + " "}
                                            {/* {holiday.flight.inbound[0].inbound_date + " "} - {`${holiday?.flight?.inbound[0]?.airline_name + " - "}  (${holiday?.flight?.inbound[0]?.airline_code})`} */}
                                        </Typography>
                                        <Typography variant='body1' fontSize={{ lg: "16px", xs: "14px" }} fontWeight={500} lineHeight='1.2'>
                                            {isEmpty(holiday?.flight?.inbound[0]?.airline_name) !== "" && <>
                                                {`${holiday?.flight?.inbound[0]?.airline_name + " - "}  (${holiday?.flight?.inbound[0]?.airline_code})`}
                                            </>}
                                        </Typography >
                                        {/* <Typography variant='body1' fontWeight={500} lineHeight={'1.2'}>

            </Typography> */}

                                        {isEmpty(holiday.flight.inbound[0].des_airport) !== "" && isEmpty(holiday.flight.inbound[0].dep_airport) !== ""
                                            && <Typography fontSize={{ lg: "16px", xs: "14px" }} sx={{
                                                '& svg': {
                                                    display: "inline-block",
                                                    verticalAlign: "center",
                                                    height: { lg: "14px", xs: "12px" },
                                                    width: { lg: "14px", xs: "12px" },
                                                    ml: "4px"
                                                }
                                            }} variant='body1' fontWeight={500} lineHeight='1.2'
                                                alignItems={'center'}>
                                                {isEmpty(holiday.flight.inbound[0].flightDeparture) !== "" && "(" + holiday.flight.inbound[0].flightDeparture + ") - "}
                                                <span>{holiday.flight.inbound[0].dep_airport} <KeyboardDoubleArrowRightIcon /></span>
                                                {/* <KeyboardDoubleArrowRightIcon sx={{ height: '24px', width: '24px', display: 'block' }} />{' '} */}
                                            </Typography>}

                                        {isEmpty(holiday.flight.inbound[0].des_airport) !== "" && <Typography fontSize={{ lg: "16px", xs: "14px" }} variant='body1'
                                            fontWeight={500}
                                            lineHeight='1.2'>
                                            {isEmpty(holiday.flight.inbound[0].arrival_time) && <span>{"(" + isEmpty(holiday.flight.inbound[0].arrival_time) + ") - "}</span>}
                                            <span>{holiday.flight.inbound[0].des_airport}</span>
                                        </Typography>}

                                        <Typography mt="8px" variant='body1' fontSize={{ lg: "16px", xs: "14px" }} fontWeight={500} lineHeight='1.2'>
                                            {isEmpty(formatStringTime(holiday.inboundTime))}  ({isEmpty(holiday.flight.inbound[0].stop)})
                                        </Typography>
                                        <Typography variant="body1" fontSize={{ lg: "16px", xs: "14px" }} fontWeight={500} lineHeight={1.2}>
                                            {Array.isArray(holiday?.flight?.inbound[0].layover) && (holiday?.flight?.inbound[0].layover?.filter((layover: any) => layover !== null)
                                                .map((layover: any, index: any) => (
                                                    <span style={{ fontWeight: '500', lineHeight: '1.2' }} key={index}>
                                                        {index > 0 && <br />}
                                                        {/* {`Layover ${index + 1} (${layover.replace(/(\d+)\s*hr\s*(\d+)\s*m/g, '$1hr $2m')})`} */}
                                                        {`Layover ${index + 1} (${layover?.time
                                                            ?.replace(/0\s*hr\s*/g, '')                    // Remove "0 hr"
                                                            .replace(/\s*m/g, 'm')                         // Remove space before "m"
                                                            .replace(/(\d+)\s*hr/g, '$1hr')                // Ensure "1 hr" becomes "1hr"
                                                            .trim()                                        // Remove any leading/trailing spaces
                                                            }) `}
                                                             - {layover?.layover_airport_city} ({layover?.code})

                                                    </span>
                                                )))}
                                        </Typography>
                                    </Box>

                                </Box>
                                <Box display={'flex'}>
                                    {isEmpty(holiday?.baggage) !== '' &&
                                        <Box
                                            className='Icon'
                                            mt={0.4}
                                            sx={{
                                                '& svg': {
                                                    width: "26px",
                                                    height: "26px",
                                                    fill: colors.basics.primary,
                                                    // transform: 'rotate(-270deg)',
                                                },
                                            }}>
                                            <Tooltip title='Baggage' >
                                                <LuggageIcon />
                                            </Tooltip>
                                        </Box>}
                                    {isEmpty(holiday?.baggage) !== '' && <Box className='content'>
                                        <Typography variant='body1' fontSize={{ lg: "16px", xs: "14px" }} fontWeight={500} lineHeight='1.2'>
                                            {holiday?.baggage.includes("kg") ? holiday?.baggage : holiday?.baggage.replace("K", "K")}
                                        </Typography>
                                    </Box>}
                                </Box>
                            </Box>}
                        </li>
                        <li />

                    </ul>
                </Box>

            </Box>
        )
    }

    const renderHotelUI = () => {
        return <Box
            sx={{
                borderLeft: { lg: "2px solid black", xs: 'none' },
                width: { lg: "50%", xs: "100%" },
                padding: { sm: "0 10px", xs: "0 0 10px" },
            }}
        >
            {" "}
            <Box display={{ xs: "none", lg: "flex" }} ml='5px' mb='10px'><Typography variant="h1" sx={{ fontWeight: "400", fontSize: "20px" }}>Room Details</Typography></Box>
            <Box sx={{}}>
                <Box
                    sx={{
                        '& ul': {
                            padding: '0',
                            listStyle: 'none',
                            '& li': {
                                padding: '0px 5px',
                                // color: disableButton ? 'red' : 'pink',
                                '& .content': {
                                    alignSelf: 'center',
                                },
                                '&:nth-of-type(odd)': {
                                    // background: disableButton ? 'yellow' : colors.grey[20],
                                    // background: colors.grey[20],
                                },
                            },
                        },
                        '.Icon': {
                            width: '100%',
                            maxWidth: '40px',
                            '& svg': {
                                filter: disableButton ? 'opacity(0.5) ' : ''
                            }
                        },
                        '.Icon svg path': {
                            fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary
                          }
                    }}
                >
                    <ul>

                        <li>
                            <Box display='flex' mb='10px'>
                                <Box className='Icon ' mt={0.5}>
                                    <Tooltip title='Hotel'>
                                        <Building />
                                    </Tooltip>
                                </Box>
                                <Box className='content'>
                                    <Typography variant='body1' fontSize={{ lg: "16px", xs: "14px" }} fontWeight={500} lineHeight='1.2' color={disableButton ? '#999999' : ''}>
                                        {nights ? (
                                            <span style={{ color: disableButton ? '#999999' : '' }}>
                                                {`${nights} nights at the`} <span dangerouslySetInnerHTML={{ __html: capitalizeFirstLetterEachWords(isEmpty(hotelName)) }} />
                                            </span>
                                        ) : (
                                            'Loading...'
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display='flex' mb='10px'>
                                <Box className='Icon' mt={0.3}>
                                    <Tooltip title='Holiday Date'>
                                        <Calender />
                                    </Tooltip>
                                </Box>
                                <Box className='content' sx={{ paddingTop: '3px' }}>
                                    <Typography variant='body1' fontSize={{ lg: "16px", xs: "14px" }} fontWeight={500} lineHeight='1.2' color={disableButton ? '#999999' : ''}>
                                        <span>Check In: </span>
                                        <span>
                                            {holiday?.packageDate?.checkin} {" "}
                                        </span>
                                        <span>(1500 hrs)</span>
                                    </Typography>
                                    <Typography variant='body1' fontSize={{ lg: "16px", xs: "14px" }} fontWeight={500} lineHeight='1.2' color={disableButton ? '#999999' : ''}>
                                        <span>Checkout: </span>
                                        <span>
                                            {checkOutDate || 'Loading...'} {" "}
                                        </span>
                                        <span>(1000 hrs)</span>
                                    </Typography>
                                </Box>
                            </Box>

                            <Box display='block' >
                                {flightRoom?.multiRooms?.map((f: any, i: any) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <Box display='flex' mb='5px'>
                                                <Box alignItems={"center"} className='Icon' mt={0.3}>
                                                    <Tooltip title='Room'>
                                                        <Bed />
                                                    </Tooltip>
                                                </Box>
                                                <Box className='content' sx={{
                                                    paddingTop: '3px',
                                                    "& p:not(:last-child)": {
                                                        paddingBottom: '8px'
                                                    }

                                                }}>
                                                    <Typography variant='body1' fontSize={{ lg: "16px", xs: "14px" }} fontWeight={500} lineHeight='1.2' color={disableButton ? '#999999' : ''} textTransform={'capitalize'}>
                                                        Room {i + 1} -  {capitalizeFirstLetter(f?.room_name)}
                                                    </Typography>
                                                </Box>
                                            </Box >
                                            <Box alignItems={"center"} display={'flex'}>
                                                <Box className='Icon' mt={0.4}>
                                                    <Tooltip title='Guest'>
                                                        <Person />
                                                    </Tooltip>
                                                </Box>
                                                <Box>
                                                    <Typography fontSize={{ lg: "16px", xs: "14px" }} color={disableButton ? '#999999' : ''} variant='body1' fontWeight={500} lineHeight='1.2'>
                                                        {guest[i].adults + " Adults "}
                                                        {guest[i].children?.length === 0
                                                            ? ''
                                                            : guest[i].children?.length === 1
                                                                ? `${guest[i].infants.length === 0 ? "and" : ","} ${guest[i].children?.length} Children `
                                                                : `${guest[i].infants.length === 0 ? "and" : ","} ${guest[i].children?.length} Childrens `}
                                                        {`${guest[i].infants.length > 0 ? "and " + guest[i].infants.length + (guest[i].infants.length === 1 ? " Infant" : " Infants") : ""}`}

                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box alignItems={"center"} mt={0.5} mb={0.5} display={'flex'}>
                                                <Box className='Icon' mt={0.3}>
                                                    <Tooltip title='Board'>
                                                        <Spoon />
                                                    </Tooltip>
                                                </Box>
                                                <Box className='content'>
                                                    <Box
                                                        sx={{
                                                            '& p': {
                                                                fontFamily: 'Raleway',
                                                                fontStyle: 'normal',
                                                                fontWeight: 500,
                                                                fontSize: { lg: 16, xs: 14 },
                                                                lineHeight: '19px',
                                                                color: colors.basics.black,
                                                            },
                                                            fontFamily: 'Raleway',
                                                            fontStyle: 'normal',
                                                            fontWeight: 500,
                                                            fontSize: { lg: 16, xs: 14 },
                                                            lineHeight: '19px',
                                                            color: disableButton ? '#999999' : colors.basics.black,
                                                            textTransform: "capitalize"
                                                        }}
                                                        // color={disableButton ? '#999999' : ''}
                                                        dangerouslySetInnerHTML={{ __html: RoomMealWrapper(f?.meal_name?.toLowerCase()) || 'Loading...' }}
                                                    />
                                                </Box>
                                            </Box>
                                        </React.Fragment>
                                    )
                                })}
                            </Box>
                        </li>
                    </ul>
                </Box>
            </Box>
        </Box>
    }

    const handleChange = (panel: string) => (
        event: React.SyntheticEvent,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box
            sx={{
                width: '100%',
                border: "1px solid #999999",
                borderRadius: "5px",
                background:  colors.grey[20],
                padding: { sm: "15px 8px", xs: " 10px" },
                boxShadow: {
                    xs: "1px 2px 1px -1px rgba(0, 0, 0, 0), 1px 1px 1px 0px rgba(0, 0, 0, 0.14), -2px -2px 3px 0px rgba(0, 0, 0, 0.12) !important"
                    , sm: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                },
                mb: "20px !important",
                // boxSizing:'borde';
                display: { lg: "flex", xs: 'block' },
                // flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "space-between",
                position: "relative",
                zIndex: '2',
                "& .MuiPaper-root": {
                    boxShadow: "1px 2px 1px -1px rgba(0, 0, 0, 0), 1px 1px 1px 0px rgba(0, 0, 0, 0.14), -2px -2px 3px 0px rgba(0, 0, 0, 0.12) !important",
                    margin: "10px 0 !important",
                    cursor: "unset",
                    '&:before': {
                        display: 'none',
                    },
                },
                "& .MuiAccordionSummary-root": {
                    background: isPlataniumHotel ? '#89806420' : '#fde8e6',
                    borderBottom: "1px solid #999999",
                    minHeight: "47px",
                    height: "47px",
                    padding: '0 10px'
                },
                "& .Mui-disabled": {
                    boxShadow: "unset",
                    backgroundColor: "#99999926"
                },
                "& .MuiAccordionDetails-root": {
                    background: isPlataniumHotel ? '#89806420' : '#fde8e6',
                    padding: { xs: "10px 5px", sm: "20px 16px" }
                }
            }}  >
            <Box display={{ xs: "block", lg: "none" }}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography variant="h1" sx={{ fontWeight: "400", fontSize: "16px !important" }}>Flight Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {renderFlightUI()}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography variant="h1" sx={{ fontWeight: "400", fontSize: "16px !important" }}>Room Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {renderHotelUI()}
                    </AccordionDetails>
                </Accordion>
            </Box>

            <Box
        sx={{
          width: { lg: '100%', xs: '100%' },
        }}
        display={{ lg: 'block', xs: 'none' }}
      >
        <Accordion expanded={expanded === 'detail-panel'} onChange={handleChange('detail-panel')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="detail-panel-content"
            id="detail-panel-header"
          >
            <Typography variant="h1" sx={{ fontWeight: '400' }} fontSize={{ sm: '20px', xs: '16px' }}>
              Check Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display={{ xs: 'none', lg: 'flex' }} flex={1}>
              {renderFlightUI()}
              {renderHotelUI()}
              {/* {renderTransferUI()} */}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
    )
}

export default CustomizeHolidaySummary