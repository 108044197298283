export const isDiscountValidDate = () => {
  const currentDate = new Date();
  let targetDate;
  if(process.env.REACT_APP_PROJECT_COUNTRY === "uk"){
     targetDate = new Date("2025-02-18"); // Example of potentially invalid input YYYY-MM-DD formate
  }else{
     targetDate = new Date("2025-02-18"); // Example of potentially invalid input YYYY-MM-DD formate
  }

  // Only compare dates without time
  const currentDateOnly = new Date(currentDate.toISOString().split('T')[0]);
  const targetDateOnly = new Date(targetDate.toISOString().split('T')[0]);

  // Check if today is on or before the target date
  return currentDateOnly <= targetDateOnly;
};