// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CustomSelectInput from "../../../components/CustomSelectInput";
import { useAppSelector } from "../../../hooks/useAppReducer";
import { RoomMealWrapper } from "../../../helpers/roomMealWrapper";
import { ReactComponent as Spoon } from "../../../assets/icons/Spoon.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/ArrowUp.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/icons/Check2.svg";
import parseHTML from "../../../helpers/parseHtml";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { colors } from "../../../styles/colors";
import { setFlightRoomState } from "../../../features/flightRoom/flightRoomSlice";
import { useDispatch } from "react-redux";
import { isEmpty } from "../../../helpers/isEmpty";
import { capitalizeFirstLetter } from "../../../helpers/capitalizeFirstLetter";

import { roundPrice } from "../../../helpers/RoundPrice";
import { getSessionStorage } from "../../../helpers/storage";
import { addCommasToNumber } from "../../../helpers/commaSeparatedPrice";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import Slider from "react-slick";
import { createPortal } from "react-dom";
import Image from "../../../components/Image";
import { setPackagePricePerPerson } from "../../../features/holidayPackage/packageDetail";
import { ApplicationStateType } from "../../../features/store";
const parseText = (text: string) => {
  // Create a dummy element to parse the HTML entities
  const dummyElement = document.createElement("div");
  dummyElement.innerHTML = text;

  // Extract the decoded text content
  const decodedText = dummyElement.textContent;
  return decodedText;
};

interface roomAvailableProps {
  setExpanded?: any;
  setShowButtons?: any;
  saveBasket?: (state?: any) => void;
  handleSelectRoom?: (state?: any) => void;
  setOnChangeMultiRooms?: (state?: any) => void;
  onChangeMultiRoom?: any;
  accordionOpen?: any;
  setRoomDisableButton?: any;
  roomDisableButton?: boolean;
  setDisableButton?: any;
  showPreviousComponent?: any;
  saveRoom?: any;
  roomPrice?: any;
  setRoomPrice?: any;
  setOnChangeMultiRoom?: any;
  getRoomIds?: any;
  selectedRoomPriceForGuests?: any;
  setSelectedRoomPriceForGuests?: any;
  // setSelectedOptions?: any
  // selectedOptions?: any
}

interface RoomAndMealData {
  [key: string]: any[];
}

interface RoomPagination {
  index: number | null;
  page: number;
}

interface Meal {
  index: string | number | null;
  value: string;
}

type Package = {
  id: string;
  searchCriteriaId: string;
  room_name: string;
  days_spent: string;
  hotel_id: string;
  meal_name: string;
  checkInDate: string;
  checkOutDate: string;
  total_package: string;
  package_breakdown: {
    flight_tot_net_amt: string;
    room_tot_net_amt: string;
    auto_fee_perc: string;
    auto_fee_amt: string;
    markup_amount: string;
    total_package_amt: string;
    amt_per_person: string;
  };
  hotel_room_id: string;
};

export const CustomRoom: FC<roomAvailableProps> = ({
  setOnChangeMultiRoom,
  setDisableButton,
  roomPrice,
  setRoomPrice,
  setRoomDisableButton,
  saveRoom,
  handleSelectRoom,
  roomDisableButton,
  accordionOpen,
  setExpanded,
  saveBasket,
  showPreviousComponent,
  onChangeMultiRoom,
  setOnChangeMultiRooms,
  setShowButtons,
  getRoomIds,
  selectedRoomPriceForGuests,
  setSelectedRoomPriceForGuests,
}) => {
  const dispatch = useDispatch();
  const scrollableRef = useRef<(HTMLDivElement | null)[]>([]);
  const { flightRoom } = useAppSelector((state) => state.flightRoom);
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  const { hotelDetails } = useAppSelector((state) => state.hotelDetails);
  const { adults, childrens, guest } = flightRoom.searchCriteria;
  const isPlataniumHotel = Number(hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel) !== 0;
  const [meals, setMeals] = useState<string[]>([]);
  // const [roomSpecificMeal, setRoomSpecificMeal] = useState([])
  const [isMealChanged, setIsMealChanged] = useState(false);
  const [filteredRoomAndMealData, setFilteredRoomAndMealData] = useState<RoomAndMealData>({});
  const [selectedMealPlan, setSelectedMealPlan] = useState<Meal[]>([]);
  const [selectedId, setSelectedId] = useState<any>([flightRoom?.multiRooms[0]?.id]);
  const [roomPagination, setRoomPagination] = useState<RoomPagination[]>([{ index: 0, page: 3 }]);
  // eslint-disable-next-line
  const [flightPage, setFlightPage] = useState<number>(200);
  const windowWidth = useWindowWidth();
  const totalGuest = adults + childrens;
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL || "";

  useEffect(() => {
    setOnChangeMultiRoom(flightRoom.multiRooms);
  }, []);
  useEffect(() => {
    let price: any = [];
    flightRoom.multiRooms.map((f: any) => {
      price.push(f?.total_package);
      return <></>;
    });
    setRoomPrice(price);
  }, [flightRoom.multiRooms]);

  function mergeGroupArrays<T>(groups: Record<number, T[]>): T[] {
    return Object.values(groups).flat();
  }

  const handleScroll = (index: number) => {
    if (scrollableRef.current) {
      scrollableRef.current[index]?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const rooms: any = useMemo(() => {
    const removeDuplicates: any = [];
    const multiRooms = saveRoom?.[0]?.SearchResult?.Rooms;
    // ?.map((room, index) => ({
    //   ...room,
    //   room_id: index
    // }));

    if (multiRooms) {
      if (flightRoom.multiRooms.length > 0) {
        const flightMeals = RoomMealWrapper(flightRoom?.multiRooms[0]?.meal_name?.toLowerCase());
        if (!removeDuplicates.includes(flightMeals)) {
          removeDuplicates.push(flightMeals);
        }
      }
      const arr = Object.values(multiRooms);
      const roomNames = (arr[0] as any[])?.map((item: any) => {
        // const roomNames = mergeGroupArrays(multiRooms)?.map((item: any) => {
        return RoomMealWrapper(item?.meal_name?.toLowerCase());
      });

      roomNames.map((e: any) => {
        const parsedDocument = parseHTML(e);
        const textContent = parsedDocument.body.textContent;
        if (!removeDuplicates.includes(textContent)) {
          removeDuplicates.push(textContent);
        }
        return <></>;
      });
    }

    return removeDuplicates;
  }, [saveRoom, flightRoom.multiRooms]);

  // const onSelectMeal = (value: any) => {
  const onSelectMeal = (event: SelectChangeEvent<typeof meals> | null, name: any, value: any, index: any) => {
    setMeals(typeof value === "string" ? value.split(",") : value);
    // setMeals(prevMeals => {
    //   const updatedMeals = [...prevMeals];
    //   updatedMeals[index] = value; // Update at specified index
    //   return updatedMeals;
    // });
  };

  useEffect(() => {
    if (rooms?.length > 0) {
      const filteredRooms = rooms.filter((item: any) => item !== undefined);
      // setMeals(flightRoom?.multiRooms.map((flight: any) => RoomMealWrapper(flight?.meal_name.toLowerCase())))
      setMeals([filteredRooms[0]]);
    } else {
      setMeals([]);
    }
  }, [saveRoom]);

  const filterDuplicateRoomNames = (data: string[]) => {
    function convertNumberToWord(text: string): string {
      // Ensure string input
      if (typeof text !== "string") {
        throw new TypeError("Input must be a string.");
      }
      // Loop through each word in the string
      const words = text.trim().split(/\s+/);
      for (let i = 0; i < words.length; i++) {
        const word = words[i];

        if (/^\d+$/.test(word)) {
          const number = parseInt(word, 10);
          if (number === 1) {
            words[i] = `One`;
          } else {
            const numberWords = new Map([
              [2, "Two"],
              [3, "Three"],
              [4, "Four"],
              [5, "Five"],
              [6, "Six"],
              [7, "Seven"],
              [8, "Eight"],
              [9, "Nine"],
            ]);
            words[i] = numberWords.get(number) || word; // Replace with word from map or original word
          }
        }
      }
      return words.join(" "); // Join the modified words back into a string
    }

    function formatText(text: string) {
      const regex = /([a-z])([A-Z])(?!\))/g;
      return text.replace(regex, "$1 $2");
    }

    const addSpaceToStaticWords = (str: any) => {
      const staticWords = {
        Seaview: "Sea View",
        Gardenview: "Garden View",
        Resortview: "Resort View",
        Creekview: "Creek View",
        Juniorsuite: "Junior Suite",
        Seniorsuite: "Senior Suite",
        Familyroom: "Family Room",
        Familysuite: "Family Suite",
        Poolview: "Pool View",
      };
      let newStr = str;
      for (const [key, value] of Object.entries(staticWords)) {
        newStr = newStr.replace(new RegExp(key, "gi"), value);
      }

      return newStr;
    };

    const cleanString = (str: string) => {
      return str
        ?.split(/[ \W]+/)
        .filter(Boolean)
        .join(" ");
    };

    const sortingRoomNames = (str: string) => {
      return str
        .split(/[ \W]+/)
        .filter(Boolean)
        .sort()
        .join(" ");
    };
    const removeDuplicates = (arr: string[]) => {
      const uniqueArray: string[] = [];
      const storeRoomNames: string[] = [];

      arr.forEach((item: any) => {
        const b = addSpaceToStaticWords(isEmpty(item.room_name));
        const c = convertNumberToWord(b);
        const a = formatText(c);
        const cleanedItem = cleanString(a);

        if (
          !uniqueArray.some((room: any) => cleanString(room.room_name).includes(cleanedItem)) &&
          !storeRoomNames.includes(sortingRoomNames(isEmpty(item.room_name)))
        ) {
          uniqueArray.push(item);
          storeRoomNames.push(
            cleanedItem
              .split(/[ \W]+/)
              .filter(Boolean)
              .sort()
              .join(" ")
          );
        }
      });
      return uniqueArray;
    };

    return removeDuplicates(data);
  };

  const filteredRoomData = useCallback(() => {
    let data: any = [];
    if (saveRoom?.[0]?.SearchResult?.Rooms) {
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(saveRoom?.[0]?.SearchResult?.Rooms)) {
        if (Array.isArray(value)) {
          const val = value.filter((item: any, index: number) => {
            const parsedDocument = RoomMealWrapper(item?.meal_name.toLowerCase());
            return index < flightPage && (meals?.includes(parsedDocument) || meals.length === 0);
          });
          data.push(val);
        }
      }
    }

    const filterUniqueData = data.map((d: any, i: number) => {
      let uniqueRoomNames: any = [];
      let storeArr: any = [];

      if (
        meals?.includes(RoomMealWrapper(flightRoom?.multiRooms[i]?.meal_name?.toLowerCase())) ||
        meals?.length === 0
      ) {
        uniqueRoomNames.push(flightRoom?.multiRooms[i]?.room_name?.toLowerCase());
        storeArr.push(flightRoom?.multiRooms[i]);
      }

      function capitalizeWords(str: any) {
        return str.replace(/\w\S*/g, (txt: any) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
      }
      const capitalizeRoomNames = (storeArr: any) => {
        return storeArr?.map((item: any) => ({
          ...item,
          room_name: capitalizeWords(item.room_name),
        }));
      };
      filterDuplicateRoomNames(capitalizeRoomNames(d)).map((e: any) => {
        if (!uniqueRoomNames.includes(e.room_name.toLowerCase())) {
          uniqueRoomNames.push(e.room_name.toLowerCase());
          storeArr.push(e);
        }
        return <></>;
      });
      return storeArr;
    });

    return filterUniqueData;
  }, [saveRoom, meals, roomPrice]);

  const filterRoomPrice = useMemo(() => {
    // console.log('saveRoom?.[0]?.SearchResult?.Rooms', saveRoom?.[0]?.SearchResult?.Rooms, filteredRoomData())
    const multiRooms = saveRoom?.[0]?.SearchResult?.Rooms; //filteredRoomData()
    // Object.fromEntries(filteredRoomData().map((item: any, index: number) => [index + 1, item]));
    if (multiRooms) {
      const newMultiRooms = Object.fromEntries(
        Object.entries(multiRooms).map(([key, array]) => [
          key,
          (array as any[]).map((item: any) => ({ ...item, room_id: parseInt(key) - 1 })),
        ])
      );
      // const reducedData = mergeGroupArrays(newMultiRooms).reduce((result: any, item: any) => {
      //   const key = item.room_name.toLowerCase();
      //   const mealKey = RoomMealWrapper(item.meal_name.toLowerCase());
      //   // const mealKey = item.meal_name.toLowerCase();
      //   console.log('roomPrice', roomPrice, item.room_id)
      //   const price = (Number(item?.total_package) - Number(roomPrice[0])) / totalGuest;

      //   if (!result[key]) {
      //     result[key] = [];
      //   }

      //   // Check if a meal with the same name already exists
      //   const existingMeal = result[key].find((entry: any) => mealKey in entry);

      //   if (!existingMeal) {
      //     // If no meal with the same name exists, add the current item
      //     result[key].push({ [mealKey]: price });
      //   } else {
      //     // If a meal with the same name exists, keep the one with the lower price
      //     const existingPrice = existingMeal[mealKey];
      //     if (price < existingPrice) {
      //       existingMeal[mealKey] = price; // Update with the lower price
      //     }
      //   }
      //   return result;
      // }, {});
      const reducedData = Object.entries(newMultiRooms).reduce<any>((acc, [key, array]) => {
        acc[key] = array.reduce((result, item) => {
          const key = item.room_name.toLowerCase();
          const mealKey = RoomMealWrapper(item.meal_name.toLowerCase());
          const price = (Number(item?.total_package) - Number(roomPrice[item.room_id])) / totalGuest;

          if (!result[key]) {
            result[key] = [];
          }

          // Check if a meal with the same name already exists
          const existingMeal = result[key].find((entry: any) => mealKey in entry);

          if (!existingMeal) {
            // If no meal with the same name exists, add the current item
            result[key].push({ [mealKey]: price });
          } else {
            // If a meal with the same name exists, keep the one with the lower price
            const existingPrice = existingMeal[mealKey];
            if (price < existingPrice) {
              existingMeal[mealKey] = price; // Update with the lower price
            }
          }
          return result;
        }, {}); // Reduce for each individual array
        return acc;
      }, {});
      setFilteredRoomAndMealData(reducedData as RoomAndMealData);
      return reducedData;
    }
  }, [saveRoom, meals, flightRoom.multiRooms, roomPrice]);
  // }, [saveRoom, meals, flightRoom.multiRooms, flightPage, flightRoom.searchCriteria, selectedMealPlan]);

  useEffect(() => {
    filteredRoomData();
  }, [saveRoom, meals, flightRoom.multiRooms, flightPage]);

  useEffect(() => {
    if (roomPagination.every((room) => room.page === 3)) {
      setRoomPagination(
        Array.from({ length: filteredRoomData().length }, (_, i) => ({
          index: i,
          page: 3,
        }))
      );
    }
  }, [filteredRoomAndMealData]);

  useEffect(() => {
    setSelectedMealPlan(
      flightRoom.multiRooms.reduce(
        (acc: any, curr: any, index: any) => ({ ...acc, [index]: curr?.meal_name }),
        {}
      )
    );
  }, []);

  useEffect(() => {
    setSelectedRoomPriceForGuests(
      Array.from({ length: filteredRoomData()?.length }, (_, i) => ({
        index: i,
        price: null,
        guest:
          flightRoom.searchCriteria.guest[i].adults + flightRoom.searchCriteria.guest[i].children.length || 0,
      }))
    );
  }, [flightRoom.searchCriteria]);

  const handleFilterChange = useCallback(
    (item: any, i: any, v?: any) => {
      // const handleFilterChange = (item: any, i: any, v?: any) => {
      const price = (Number(item?.total_package) - Number(roomPrice[i])) / totalGuest;
      setRoomDisableButton(true);
      const guestPerRoom =
        flightRoom.searchCriteria.guest[i].adults + flightRoom.searchCriteria.guest[i].children.length || 0;

      if (v) {
        const arr = [...onChangeMultiRoom];
        arr[i] = item;
        // arr[i] = { ...item, meal_name: parseText(selectedMealPlan[i]) };
        // arr[i] = { ...item, meal_name: v ?? selectedMealPlan.find((meal) => meal.index === item.id)?.value };
        // total_package: String(Number(item?.total_package) + (price * guestPerRoom))
        setSelectedRoomPriceForGuests((prev: any) =>
          prev.some((item: any) => item.index === i)
            ? prev.map((item: any) => (item.index === i ? { ...item, price: price * item?.guest } : item))
            : [...prev, { index: i, price: item.price, guest: item.guest }]
        );
        setOnChangeMultiRooms?.(arr);
        let selectId = [...selectedId];
        selectId[i] = item.id;
        setSelectedId(item.id);
        // let price: any = [...roomPrice]
        // price[i] = item.total_package
        // setRoomPrice(price);
        // dispatch(setFlightRoomState({
        //   ...flightRoom,
        //   isNewFlightSelected: {
        //     ...flightRoom.isNewFlightSelected,
        //     room: true
        //   },
        // }))

        const updated = dispatch(
          setFlightRoomState({
            ...flightRoom,
            isNewFlightSelected: {
              ...flightRoom.isNewFlightSelected,
              room: true,
            },
            multiRooms: arr,
            roomids: getRoomIds(arr, guest),
            roomAddoon: price * guestPerRoom,
            // storeMultiRoom: arr,
          })
        ).payload;

        const newData = { ...updated };
        if (newData.hasOwnProperty("roomData")) {
          delete newData["roomData"];
        }
        // const updateprice = newData.multiRooms.reduce((sum, obj) => sum + Number(obj.total_package), 0)

        // console.log('newData', newData, (Number(updateprice) - Number(roomPrice[i])) / totalGuest)
        saveBasket?.({
          updatedFlightRoomState: newData,
          yourHolidaySummary: null,
        });
      }
      // goToFirstSlide();
      // setChooseRoom(true)
    },
    [selectedMealPlan, meals, flightRoom]
  );

  const totalGuests = flightRoom.searchCriteria.adults + flightRoom.searchCriteria.childrens;

  const flightPrice: any = useMemo(() => {
    let price: any = 0;
    const flightPrice = getSessionStorage("stepper") === "0" ? "0" : flightRoom.diffPrice || "0";

    if (getSessionStorage("nextAndSelectedFlights") === "false") {
      price = Math.ceil(flightPrice);
    } else {
      if (flightPrice.includes("-")) {
        price = Math.floor(flightPrice);
      } else {
        price = Math.ceil(flightPrice);
      }
    }
    return price;
  }, [flightRoom.diffPrice, getSessionStorage("stepper"), getSessionStorage("nextAndSelectedFlights")]);

  const packagePricePerPerson: any = useMemo(() => {
    const flattenedRoomData = filteredRoomData().flat();
    const roomData = filteredRoomData();
    // Calculate price per room and return as array
    const totalPrices = roomData?.map((roomGroup: any) => {
      return roomGroup?.map((room: any) => {
        // Calculate total package price per person for the current room
        const roomTotalPrice = Number(room.total_package) + flightPrice; // Include flight price

        const roomPricePerGuest = roomTotalPrice / totalGuests; // Divide by total guests

        return {
          id: room.id,
          room_name: room.room_name,
          price_per_person: roomPricePerGuest, // Price per person as a string with two decimals
        };
      });
    });

    // Log total prices for debugging

    return totalPrices;
  }, [filteredRoomData(), flightRoom.multiRooms, totalGuests, flightPrice]);

  const additionalFlightPrice = useMemo(() => {
    let price = 0;
    if (flightRoom.multiRooms.length > 0) {
      flightRoom.multiRooms.map((f: any) => {
        price = price + Number(f?.package_breakdown?.flight_tot_net_amt);
        return <></>;
      });
    }

    return price;
  }, [flightRoom.multiRooms]);

  const totalPrice = useMemo(() => {
    const packagePP = packagePricePerPerson?.[0]?.[0]?.price_per_person ?? 'N/A';
    return Number(roundPrice(String(packagePP))) * totalGuests;
  }, [packagePricePerPerson, totalGuests, flightPrice]);

  // const totalPricesForGroups = packagePricePerPerson.map(roomGroup => Number(roomGroup.price_per_person))
  // console.log('totalPricesForGroups :', totalPricesForGroups);

  // const updatedRoomData = () => {
  //   const multiRoom = saveRoom?.[0]?.SearchResult?.Rooms;
  //   if (multiRoom) {
  //     const data = Object.fromEntries(
  //       Object.entries(multiRoom).map(([key, value]) => {
  //         const roomMap = value.reduce((map, obj) => {
  //           const room_name = obj.room_name.toLowerCase();
  //           const meal_name = RoomMealWrapper(obj.meal_name.toLowerCase());
  //           const { total_package } = obj;
  //           // const price = (Number(obj?.total_package) - Number(roomPrice[0])) / totalGuest;
  //           if (!map[room_name]) {
  //             map[room_name] = { ...obj, meals: {} };
  //           }
  //           map[room_name].meals[meal_name] = parseFloat(total_package);

  //           if (parseFloat(total_package) < parseFloat(map[room_name].total_package)) {
  //             map[room_name] = { ...obj, meals: map[room_name].meals };
  //           }
  //           return map;
  //         }, {});
  //         return [key, Object.values(roomMap)];
  //       })
  //     );
  //     return data;
  //   }
  // };

  function reorderArrayWithMapping(arr: Package[], targetId: any): Package[] {
    if (targetId) {
      const targetIndex = arr.findIndex(
        (obj) => obj.room_name.toLowerCase() === targetId?.room_name.toLowerCase()
      );

      if (targetIndex !== -1) {
        const [targetObj] = arr.splice(targetIndex, 1);
        arr.sort((a, b) => parseInt(a?.total_package) - parseInt(b?.total_package));
        arr.unshift(targetObj);
      }

      // if (targetIndex !== -1) {
      //   const [targetObj] = arr.splice(targetIndex, 1);
      //   arr.unshift(targetObj);
      // } else {
      //   arr.unshift(targetId);
      // }
    }
    return arr;
  }

  return (
    <Box
      sx={{
        backgroundColor: colors.basics.secondary,
        border: `1px solid ${colors.border.grey}`,
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {filteredRoomData().length > 0 ? (
        filteredRoomData()?.map((items: any, i: number) => (
          <Box
            key={i}
            sx={{
              "&:not(:last-child)": {
                borderBottom: "1px solid #ccc",
              },
            }}
          >
            <Box sx={{ padding: "20px" }} ref={(el) => (scrollableRef.current[i] = el as HTMLDivElement | null)}>
              <Typography variant="h2">Your included room and board basis</Typography>
              <Typography variant="body1">
                {i + 1} room for {guest[i].adults + " Adults "}{" "}
                {guest[i].children?.length === 0
                  ? ""
                  : guest[i].children?.length === 1
                    ? `+ ${flightRoom?.searchCriteria?.guest[i].children?.length} Child `
                    : ` + ${flightRoom?.searchCriteria?.guest[i].children?.length} Children `}
                {`${guest[i].infants.length > 0
                  ? "+ " +
                  guest[i].infants.length +
                  (guest[i].infants.length === 1 ? " Infant" : " Infants")
                  : ""
                  }`}
              </Typography>
            </Box>
            <Box
              sx={{
                "& .slick-slide": {
                  padding: "0 10px",
                },
              }}
            >
              {/* <Box
                p={{ md: '10px 0px', sm: '20px 40px', xs: '0px 20px 20px' }}
                // bgcolor={colors.basics.secondary}
                maxWidth='100%'
                sx={{
                  '.slick-track': {
                    display: 'flex',
                    gap: { sm: '10px', md: '10px', xs: '0px' },
                  },
                  '.slick-slide': {
                    height: 'auto !important',
                    minHeight: '100%',
                    padding: 0,
                  },
                  '.slick-slide > div': {
                    height: '100%',
                  },
                  '.slick-prev ': {
                    color: {
                      xs: 'black !important',
                      md: 'transparent !important',
                      sm: 'transparent !important',
                    },
                    marginLeft: '3px',
                  },
                  '.slick-next ': {
                    color: {
                      xs: 'black !important',
                      md: 'transparent !important',
                      sm: 'transparent !important',
                    },
                    marginRight: '3px',
                  },
                }}
              > */}
              <Grid container spacing={2} padding={2}>
                {/* <Slider ref={roomSliderRef} {...settings}> */}
                {reorderArrayWithMapping(items, onChangeMultiRoom[i])
                  .slice(0, roomPagination[i]?.page)
                  .map((item: any, index: number) => {
                    const price = (Number(item?.total_package) - Number(roomPrice[i])) / totalGuest;
                    const room_name = item.room_name.toLowerCase();
                    const multiRooms = saveRoom?.[0]?.SearchResult?.Rooms;
                    const priceText = Object.values(filterRoomPrice)?.[i]?.[room_name]
                      ?.map((obj: any) => obj[parseText(meals[0])])
                      ?.filter((value: any) => value !== undefined);
                    const priceData = packagePricePerPerson[i]?.find((p: any) => p.id === item.id);
                    // const meal_name = RoomMealWrapper(item.meal_name.toLowerCase());
                    // const mealPrice = Object.values(filterRoomPrice)?.[i]?.[room_name].find(item => item[parseText(meals[i])])
                    // const meal = mealPrice && Object.values(mealPrice)[0] || 0
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        key={index}
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Box
                          sx={{
                            border: `1px solid ${colors.border.grey}`,
                            borderRadius: "4px",
                            height: "100%",
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            backgroundColor: "#f5f5f5",
                          }}
                        >
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html:
                                isEmpty(item?.room_name) !== "" ? capitalizeFirstLetter(item?.room_name) : "",
                            }}
                            sx={{
                              textTransform: "capitalize",
                              padding: "10px",
                              // display: "-webkit-box",
                              // WebkitBoxOrient: "vertical",
                              // WebkitLineClamp: 2,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              // maxHeight: "3em",
                            }}
                            variant="body1"
                            fontWeight={700}
                          ></Typography>
                          <Box
                            sx={{
                              mb: { sm: 0, xs: "5px" },
                              paddingBottom: 2,
                              paddingRight: "10px",
                              paddingLeft: "10px",
                              borderBottom: `1px solid ${colors.border.grey}`,
                              "& .custom-dropdown": {
                                marginTop: "2px !important",
                              },
                            }}
                            position={"relative"}
                          >
                            <Typography variant="subtitle2" fontSize={13} color={colors.grey[40]}>
                              Board basis
                            </Typography>
                            <CustomSelectInput
                              // boxStyle={{
                              //   minWidth: "180px",
                              // }}
                              capitilize={true}
                              data={(Object.values(filterRoomPrice) as any)?.[i]?.[room_name]?.map(
                                (obj: any) => Object.keys(obj)[0]
                              )}
                              extraData={(Object.values(filterRoomPrice) as any)?.[i]?.[room_name]?.map(
                                (obj: any) => Object.values(obj)[0]
                              )}
                              // data={rooms}
                              // ids={`meals`}
                              ids={`meals-${item?.id}`}
                              // keys={`meals-${item?.id}`}
                              multiple={false}
                              checkbox={false}
                              onChange={(e: any, n: any, v: any) => {
                                // setSelectedMealPlan((prev) =>
                                //   prev.map((meal) => (meal.index === item.id ? { ...meal, value: v } : meal))
                                // );
                                onSelectMeal(e, n, v, i);
                                setSelectedMealPlan((prevState) => ({
                                  ...prevState,
                                  [i]: v,
                                }));
                                // setIsMealChanged(!isMealChanged)
                                const multiRooms = saveRoom?.[0]?.SearchResult?.Rooms;
                                const findRoomAndMeal = mergeGroupArrays(multiRooms)
                                  .filter((room: any) => {
                                    return (
                                      room.room_name.toLowerCase() === room_name &&
                                      RoomMealWrapper(room.meal_name.toLowerCase()) === v
                                    );
                                  })
                                  .reduce((minRoom: any, currentRoom: any) => {
                                    return minRoom &&
                                      Number(minRoom?.total_package) < Number(currentRoom?.total_package)
                                      ? minRoom
                                      : currentRoom;
                                  }, null);
                                // onChangeMultiRoom[i]?.room_name.toLowerCase() === room_name &&
                                handleFilterChange(
                                  findRoomAndMeal,
                                  i,
                                  onChangeMultiRoom[i]?.room_name.toLowerCase() === room_name
                                );
                              }}
                              value={
                                // index === 0
                                //   ? RoomMealWrapper(flightRoom?.multiRooms[0]?.meal_name)
                                //   :
                                // parseText(i ? meals[i] : meals[0]) || selectedMealPlan?.find((meal) => meal?.index === item?.id)?.value ||
                                // flightRoom?.multiRooms[0]?.meal_name
                                parseText(meals[0])
                                // parseText(meals[i])
                              }
                              // value={meals.length > 0 ? parseText(meals[0]) : []}
                              placeholder="Select Meal"
                              StartIcon={<Spoon width={"18px"} height={"18px"} />}
                            // isPlataniumHotel={isPlataniumHotel}
                            />
                            {/* ----- for slider room selection ----- */}
                            {/* <FormControl fullWidth>
                              <Select
                                sx={{
                                  fontSize: "14px",
                                  textTransform: "capitalize",
                                  background: "white"
                                }}
                                defaultValue={30}
                                inputProps={{
                                  name: 'meals',
                                  id: 'uncontrolled-native',
                                }}
                                value={
                                  parseText(meals[0])
                                }
                                onChange={() => {
                                  // setSelectedMealPlan((prev) =>
                                  //   prev.map((meal) => (meal.index === item.id ? { ...meal, value: v } : meal))
                                  // );
                                  onSelectMeal(null, null, parseText(meals[0]), i);
                                  setSelectedMealPlan((prevState) => ({
                                    ...prevState,
                                    [i]: parseText(meals[0]),
                                  }));
                                  // setIsMealChanged(!isMealChanged)
                                  const multiRooms = saveRoom?.[0]?.SearchResult?.Rooms;
                                  const findRoomAndMeal = mergeGroupArrays(multiRooms).find(
                                    (room) =>
                                      room.room_name.toLowerCase() === room_name &&
                                      RoomMealWrapper(room.meal_name.toLowerCase()) === parseText(meals[0])
                                  );
                                  // onChangeMultiRoom[i]?.room_name.toLowerCase() === room_name &&
                                  handleFilterChange(
                                    findRoomAndMeal,
                                    i,
                                    onChangeMultiRoom[i]?.room_name.toLowerCase() === room_name
                                  );
                                }}
                                // onChange={(e) => {
                                //   onSelectMeal(e.target.value);
                                //   setSelectedMealPlan((prevState) => ({
                                //     ...prevState,
                                //     [i]: e.target.value,
                                //   }));
                                //   // // onChangeMultiRoom[i]?.room_name === item?.room_name &&
                                //   handleFilterChange(item, i);
                                // }}
                                renderValue={(selected) => (
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Spoon width={'18px'} height={'18px'} />
                                    {selected}
                                  </Box>
                                )}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      border: '1px solid #999',
                                      borderRadius: '4px'
                                    },
                                  },
                                }}
                              >
                                {Object.values(filterRoomPrice)?.[i]?.[room_name]?.map((obj: any) => Object.keys(obj)[0]).map((room: any, index: any) => (
                                  <MenuItem sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: "14px", textTransform: "capitalize" }} key={index} value={room} onClick={() => console.log('item from filter select', item, room)}>
                                    {room}
                                    <Box>
                                      <Typography>{Object.values(filterRoomPrice)?.[i]?.[room_name]?.map((obj: any) => Object.values(obj)[0])[index]}</Typography>
                                    </Box>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl> */}
                          </Box>
                          <Box
                            sx={{
                              padding: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              // alignItems: 'center',
                              flexDirection: "column",
                              // flexDirection: 'row',
                              gap: 1,
                            }}
                          >
                            {/* <Typography variant="body1" fontWeight={700}>
                              {" "}
                              {price === 0
                                ? `+${signForPricing}0`
                                : price > 0
                                  ? `+${signForPricing + roundPrice(Math.ceil(price).toString())}`
                                  : price < 0
                                    ? `-${signForPricing + roundPrice(Math.abs(Math.floor(price)).toString())}`
                                    : `-${signForPricing + price}`}{" "}
                              pp
                            </Typography> */}
                            <Typography variant="body1" fontWeight={700}>
                              {" "}
                              {priceText[0] === 0
                                ? `+${signForPricing}0`
                                : price > 0
                                  ? `+${signForPricing + (Math.ceil(price).toString())}`
                                  : price < 0
                                    ? `-${signForPricing + (Math.abs(Math.floor(price)).toString())}`
                                    : `-${signForPricing + price}`}{" "}
                              pp
                            </Typography>
                            {/* <Typography variant='body2' color={colors.grey[30]}>
                            Total Price {`${signForPricing}${addCommasToNumber(roundPrice(priceData?.price_per_person) * totalGuests)}`}
                          </Typography> */}
                            <Box
                              width={"100%"}
                              sx={{
                                "& .MuiButton-endIcon": {
                                  marginLeft: "0",
                                },
                                "& .MuiButtonBase-root": {
                                  color:
                                    onChangeMultiRoom[i]?.room_name.toLowerCase() === room_name
                                      ? colors.grey[10]
                                      : isPlataniumHotel
                                        ? `${colors.basics.plataniumGreenColor}`
                                        : `${colors.basics.primary}`, //"white",
                                  background:
                                    onChangeMultiRoom[i]?.room_name.toLowerCase() === room_name
                                      ? isPlataniumHotel
                                        ? colors.basics.plataniumGreenAccentColor
                                        : colors.basics.pink
                                      : "transparent",
                                  // background:
                                  //   onChangeMultiRoom[i]?.room_name.toLowerCase() === room_name
                                  //     ? colors.grey[10]
                                  //     : isPlataniumHotel
                                  //       ? `${colors.basics.plataniumGreenColor} !important`
                                  //       : `${colors.basics.primary} !important`,
                                  height: { xs: "40px", sm: "50px" },
                                  border: isPlataniumHotel
                                    ? `1px solid ${colors.basics.plataniumGreenColor} !important`
                                    : `1px solid ${colors.basics.primary} !important`,
                                  "&:hover": {
                                    color:
                                      onChangeMultiRoom[i]?.room_name.toLowerCase() === room_name
                                        ? colors.grey[10]
                                        : isPlataniumHotel
                                          ? `${colors.basics.plataniumGreenColor}`
                                          : `${colors.basics.primary}`, //"white",
                                    background:
                                      onChangeMultiRoom[i]?.room_name.toLowerCase() === room_name
                                        ? isPlataniumHotel
                                          ? colors.basics.plataniumGreenAccentColor
                                          : colors.basics.pink
                                        : "transparent",
                                    // onChangeMultiRoom[i]?.room_name.toLowerCase() === room_name
                                    //   ? colors.grey[10]
                                    //   : isPlataniumHotel
                                    //     ? colors.basics.plataniumGreenColor
                                    //     : colors.basics.primary,
                                    borderColor: "transparent",
                                    // onChangeMultiRoom[i]?.room_name.toLowerCase() === room_name
                                    //   ? colors.grey[10]
                                    //   : isPlataniumHotel
                                    //     ? colors.basics.plataniumGreenColor
                                    //     : colors.basics.primary,
                                  },
                                },
                              }}
                              onClick={() => {
                                // handleFilterChange(item, i)
                                onSelectMeal(null, null, parseText(meals[0]), i);
                                setSelectedMealPlan((prevState: any) => ({
                                  ...prevState,
                                  [i]: parseText(meals[0]),
                                }));
                                setIsMealChanged(!isMealChanged);
                                const multiRooms = saveRoom?.[0]?.SearchResult?.Rooms;
                                const findRoomAndMeal = mergeGroupArrays(multiRooms)
                                  .filter((room: any) => {
                                    return (
                                      room.room_name.toLowerCase() === room_name &&
                                      RoomMealWrapper(room.meal_name.toLowerCase()) === parseText(meals[0]) // meals[i]
                                    );
                                  })
                                  .reduce((minRoom, currentRoom) => {
                                    return minRoom &&
                                      Number(minRoom.total_package) < Number(currentRoom.total_package)
                                      ? minRoom
                                      : currentRoom;
                                  }, null);
                                // onChangeMultiRoom[i]?.room_name.toLowerCase() === room_name &&
                                handleFilterChange(findRoomAndMeal, i, true);
                                windowWidth < 426 && handleScroll(i);
                                setRoomPagination((prev) =>
                                  prev.some((item) => item.index === i)
                                    ? prev.map((item) => (item.index === i ? { ...item, page: 3 } : item))
                                    : [...prev, { index: i, page: 3 }]
                                );
                              }}
                            >
                              <Button
                                sx={{
                                  mt: 1,
                                  p: "10px 15px ",
                                  whiteSpace: "nowrap",
                                  fontSize: { sm: "16px", xs: "14px" },
                                  width: "100%",
                                  flexDirection: "row-reverse",
                                  "& .MuiButton-endIcon": {
                                    marginRight: 1,
                                  },
                                  "& svg": {
                                    fill: isPlataniumHotel
                                      ? colors.basics.plataniumGreenColor
                                      : colors.basics.primary,
                                  },
                                }}
                                {...(onChangeMultiRoom[i]?.room_name.toLowerCase() === room_name
                                  ? {
                                    endIcon: (
                                      <CheckedIcon
                                        // src={CheckedIcon}
                                        // alt="check"
                                        fill={colors.grey[10]}
                                      // sx={{
                                      //   "& svg": {
                                      //     width: "6px",
                                      //     "& path": {
                                      //       fill: colors.grey[10] //"#fff",
                                      //     },
                                      //   },
                                      // }}
                                      />
                                      // <CheckedIcon fill='white'
                                      // // sx={{ marginRight: 2, marginLeft: 0, color: '#fff !important' }}
                                      // />
                                    ),
                                  }
                                  : {})}
                              >
                                {onChangeMultiRoom[i]?.room_name.toLowerCase() === room_name
                                  ? "Selected Room"
                                  : "Choose Room"}
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                        {/* {console.log('onChangeMultiRoom', onChangeMultiRoom, (Number(item?.total_package) - Number(roomPrice[i])) / totalGuest)} */}
                      </Grid>
                    );
                  })}
              </Grid>
              {/* </Slider> */}
              {/* </Box> */}
            </Box>
            {(roomPagination?.find((room: any) => room.index === i)?.page ?? 0) > 3 ? (
              <Box
                width={"100%"}
                sx={{
                  padding: "0 17px 17px 17px",
                  "& .MuiButtonBase-root": {
                    color: colors.basics.black,
                    background: colors.grey[50],
                    height: { xs: "40px", sm: "50px" },
                    border: colors.grey[50],
                    "&:hover": {
                      background: colors.grey[50],
                      color: `${colors.basics.black} !important`,
                    },
                  },
                  // "& .MuiButtonBase-root": {
                  //   color: colors.grey[10],
                  //   background: "transparent",
                  //   height: { xs: "40px", sm: "50px" },
                  //   border: `1px solid ${colors.grey[10]}`,
                  //   "&:hover": {
                  //     background: "transparent",
                  //   },
                  // },
                }}
                onClick={() =>
                  setRoomPagination((prev) =>
                    prev.some((item) => item.index === i)
                      ? prev.map((item) => (item.index === i ? { ...item, page: item.page - 3 } : item))
                      : [...prev, { index: i, page: 3 }]
                  )
                }
              >
                <Button
                  sx={{
                    p: "10px 15px ",
                    whiteSpace: "nowrap",
                    fontSize: { sm: "16px", xs: "14px" },
                    width: "100%",
                  }}
                >
                  Show less options
                </Button>
              </Box>
            ) : null}
            {(roomPagination?.find((room: any) => room.index === i)?.page ?? 0) < items.length ? (
              <Box
                width={"100%"}
                sx={{
                  padding: "0 17px 17px 17px",
                  "& .MuiButtonBase-root": {
                    color: colors.basics.white,
                    background: colors.basics.black,
                    // background: isPlataniumHotel
                    //   ? `${colors.basics.plataniumGreenColor} !important`
                    //   : `${colors.basics.primary} !important`,
                    height: { xs: "40px", sm: "50px" },
                    border: colors.basics.black,
                    // isPlataniumHotel
                    //   ? `1px solid ${colors.basics.plataniumGreenColor} !important`
                    //   : `1px solid ${colors.basics.primary} !important`,
                    "&:hover": {
                      background: colors.basics.black,
                      // isPlataniumHotel
                      //   ? colors.basics.plataniumGreenColor
                      //   : colors.basics.primary,
                      color: `${colors.basics.white} !important`,
                    },
                  },
                  // "& .MuiButtonBase-root": {
                  //   color: colors.grey[10],
                  //   background: "transparent",
                  //   height: { xs: "40px", sm: "50px" },
                  //   border: `1px solid ${colors.grey[10]}`,
                  //   "&:hover": {
                  //     background: "transparent",
                  //   },
                  // },
                }}
                onClick={() =>
                  setRoomPagination((prev) =>
                    prev.some((item) => item.index === i)
                      ? prev.map((item) => (item.index === i ? { ...item, page: item.page + 3 } : item))
                      : [...prev, { index: i, page: 3 }]
                  )
                }
              >
                <Button
                  sx={{
                    p: "10px 15px ",
                    whiteSpace: "nowrap",
                    fontSize: { sm: "16px", xs: "14px" },
                    width: "100%",
                  }}
                >
                  Show more options
                </Button>
              </Box>
            ) : null}
            {/* {
            !!onChangeMultiRoom[i]?.id ? <Box
              width={"100%"}
              sx={{
                padding: '0 20px 20px 20px',
                "& .MuiButtonBase-root": {
                  color: colors.basics.white,
                  background: isPlataniumHotel
                    ? `${colors.basics.plataniumGreenColor} !important`
                    : `${colors.basics.primary} !important`,
                  border: isPlataniumHotel
                    ? `1px solid ${colors.basics.plataniumGreenColor} !important`
                    : `1px solid ${colors.basics.primary} !important`,
                  "&:hover": {
                    background: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                    color: `${colors.basics.white} !important`,
                  },
                  height: { xs: "40px", sm: "50px" }
                },
              }}
              onClick={handleSelectRoom}
            >
              <Button
                sx={{
                  p: "10px 15px ",
                  whiteSpace: "nowrap",
                  fontSize: { sm: "16px", xs: "14px" },
                  width: "100%",
                }}
              >
                Confirm
              </Button>
            </Box> : null
          } */}
          </Box>
        ))
      ) : (
        <Typography
          variant="h1"
          my={5}
          textAlign="center"
          color={isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary}
        >
          No Rooms Found
        </Typography>
      )}
    </Box>
  );
};
