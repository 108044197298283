import React from 'react'
import { CarHireComponent } from './Components/CarHireComponent'
import { CarComponent } from './Components/CarComponent'

const CarHire = () => {
  return (
    <div>
      <CarComponent />
    </div>
  )
}

export default CarHire