import { Grid, Typography } from "@mui/material";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import { colors } from "../../../styles/colors";
import { useAppSelector } from "../../../hooks/useAppReducer";
import { formatTime } from "../../../helpers/formatTime";
import CustomSelectInput from "../../../components/CustomSelectInput";
import { useState } from "react";
import { setCarQtyFilter, setDriverAgeFilter } from "../../../features/carHire/carHireSlice";
import { useDispatch } from "react-redux";

export const CarTopSection = () => {
  const dispatch = useDispatch();
  const { flightRoom } = useAppSelector((state) => state.flightRoom);
  const [filters] = useState({
    car_qty: ["1", "2", "3"],
    driver_age: ["18-24", "25+"],
  });
  const [selectedFilter, setSelectedFilter] = useState({
    car_qty: "",
    driver_age: "",
  });

  const handleSelectedCar = (key: any, e: any) => {
    setSelectedFilter((prev) => ({ ...prev, [key]: e.target.textContent }));
  };

  return (
    <Grid
      container
      columnSpacing={2}
      rowSpacing={2}
      sx={{
        backgroundColor: colors.basics.secondary,
        mb: 2,
        mt: 1,
      }}
    >
      <Grid
        item
        xs={6}
        sm={6}
        lg={2}
        sx={{
          "& svg": {
            fill: colors.basics.primary,
            transform: "rotate(-270deg)",
          },
        }}
      >
        <Typography variant="body2" fontWeight={600} lineHeight={1.2}>
          Outbound
        </Typography>
        <AirplanemodeActiveIcon />
      </Grid>
      <Grid item xs={6} sm={6} lg={4}>
        <Typography variant="body2" fontWeight={600} lineHeight={1.2}>
          Collection date & time
        </Typography>
        <Typography variant="body2" fontWeight={400} lineHeight={1.2}>
          {flightRoom?.selectedFlights?.outbound[0]?.FlightPswFlight?.departure_date}{" "}
          {formatTime(flightRoom?.selectedFlights?.outbound[0]?.FlightPswFlight?.departure_time)}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} lg={3}>
        <Typography variant="body2" fontWeight={600} lineHeight={1.2}>
          Collection point
        </Typography>
        <Typography variant="body2" fontWeight={400} lineHeight={1.2}>
          {flightRoom?.selectedFlights?.outbound[0]?.FlightPswFlight?.Airport?.depart_airport_name}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} lg={3}>
        <Typography variant="body2" fontWeight={600} lineHeight={1.2}>
          Number of Cars
        </Typography>
        <CustomSelectInput
          ids={"carQtyFilter"}
          data={filters.car_qty}
          menuOnClick={(e: any) => {
            handleSelectedCar("car_qty", e);
            dispatch(setCarQtyFilter(e.target.textContent));
          }}
          multiple={false}
          value={selectedFilter.car_qty}
          placeholder="Select Cars"
        />
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        lg={2}
        sx={{
          "& svg": {
            fill: colors.basics.primary,
            transform: "rotate(270deg)",
          },
        }}
      >
        <Typography variant="body2" fontWeight={600} lineHeight={1.2}>
          Inbound
        </Typography>
        <AirplanemodeActiveIcon />
      </Grid>
      <Grid item xs={6} sm={6} lg={4}>
        <Typography variant="body2" fontWeight={600} lineHeight={1.2}>
          Drop off date & time
        </Typography>
        <Typography variant="body2" fontWeight={400} lineHeight={1.2}>
          {flightRoom?.selectedFlights?.inbound[0]?.FlightPswFlight?.departure_date}{" "}
          {formatTime(flightRoom?.selectedFlights?.inbound[0]?.FlightPswFlight?.departure_time)}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} lg={3}>
        <Typography variant="body2" fontWeight={600} lineHeight={1.2}>
          Drop off point
        </Typography>
        <Typography variant="body2" fontWeight={400} lineHeight={1.2}>
          {flightRoom?.selectedFlights?.inbound[0]?.FlightPswFlight?.Airport?.depart_airport_name}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} lg={3}>
        <Typography variant="body2" fontWeight={600} lineHeight={1.2}>
          Driver's age
        </Typography>
        <CustomSelectInput
          ids={"driverAgeFilter"}
          data={filters.driver_age}
          menuOnClick={(e: any) => {
            handleSelectedCar("driver_age", e);
            dispatch(setDriverAgeFilter(e.target.textContent));
          }}
          multiple={false}
          value={selectedFilter.driver_age}
          placeholder="Select driver's age"
        />
      </Grid>
    </Grid>
  );
};
