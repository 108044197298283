import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setStepperState } from '../../features/stepper/stepperSlice';
import { Box, Typography } from '@mui/material';
import { CustomContainer } from '../../components/CustomContainer';
import { StepperCard } from '../../components/StepperComponent';
import { colors } from '../../styles/colors';
import FindHolidayWidget from '../../components/FindHolidayWidget';
import { setIsClickedFilters, setIsHolidayApiCall, setPosition } from '../../features/commonState';
import { getSessionStorage } from '../../helpers/storage';
import { useAppSelector } from '../../hooks/useAppReducer';
import { useLocation } from 'react-router-dom';
import useWindowWidth from '../../hooks/useWindowWidth';

const Search = () => {

    const { isClickedFilters, isHolidayApiCall, position } = useSelector((state: any) => state.commonState);
    const windowWidth = useWindowWidth()
    const dispatch = useDispatch();
    const elementRef = useRef(null);
    const { loading } = useSelector((state: any) => state.loading)
    const location = useLocation()

    const { holidayPackage, filteredHolidayPackage, status } = useAppSelector((state) => state.holidayPackage);

    useEffect(() => {
        dispatch(setStepperState(0));
        // if (elementRefValue.current) {
        //     dispatch(setElementRef(elementRefValue.current)); // Dispatch only if it's not null
        // }
    }, [dispatch]);



    // useEffect(() => {
    //     if (location.hash === "#holiday-search" && elementRef.current) {
    //         elementRef.current.scrollIntoView({ behavior: "smooth" });
    //     }
    // }, [location]);
    const getPosition = (data?: any, isButtonClicked?: boolean, filterApply?: any) => {
        // const element = elementRef.current;
        // if (element) {
        //     const rect = (element as any).getBoundingClientRect();
        //     const pos = {
        //         top: rect.top,
        //         left: rect.left,
        //     }
        //     if (window.innerWidth < 1025 && window.innerWidth > 769) {
        //         if (filterApply !== true) {
        //             dispatch(setPosition(pos))
        //         }
        //         setTimeout(() => {
        //             window.scrollTo({
        //                 top: filterApply ? position.top : !loading && data?.Packages?.results?.length > 0 ? pos.top : 120,
        //                 behavior: 'smooth',
        //             });
        //         }, 5)
        //     } else if (window.innerWidth < 769) {
        //         if (filterApply !== true) {
        //             dispatch(setPosition(pos))
        //         }
        //         setTimeout(() => {
        //             window.scrollTo({
        //                 top: filterApply ? position.top : !loading && data?.Packages?.results?.length > 0 ? pos.top : 64,
        //                 behavior: 'smooth',
        //             });
        //         }, 5)
        //     } else {
        //         if (!isButtonClicked) {
        //             setTimeout(() => {
        //                 window.scrollTo({
        //                     top: !loading && data?.Packages?.results?.length > 0 ? 435 : 200,
        //                     behavior: 'smooth',
        //                 });
        //             }, 5)
        //         }
        //     }
        // }
    }

    // useLayoutEffect(() => {
    //     return () => {
    //         elementRef.current = null
    //     }
    // }, [])


    useEffect(() => {
        if (window.innerWidth < 1024) {
            if (getSessionStorage("click") === "false") {
                getPosition(filteredHolidayPackage, false, true)
            }
        }
    }, [window.innerWidth, filteredHolidayPackage, getSessionStorage("click")])
    return (
        <Box position='relative'>
            <Box
                mb={{ xs: 1, lg: '35px', sm: '20px' }}
                pt={{ xs: windowWidth < 376 ? '130px' : '70px', xl: "10px", lg: '20px', md: '30px', sm: '60px' }}
            >
            </Box>
            <Box>
                <CustomContainer >
                    <StepperCard />
                </CustomContainer>
                <Box bgcolor={colors.basics.secondary} sx={{ mt: { xl: "35px", xs: '20px' }, pb: { md: '35px', xs: '20px' }, pt: { md: '35px', xs: '20px' } }}>
                    <CustomContainer style={{ maxWidth: '1340px !important' }}>
                        <Box>
                            <Typography variant='h1' fontSize='28px' fontWeight={600} lineHeight='44px' mb='15px'>
                                Find your perfect holiday
                            </Typography>
                            {window.location.host.includes("localhost") && <h2 style={{ marginBottom: "10px" }}> Search date after 44 months</h2>}
                            <FindHolidayWidget
                                setIsHolidayApiCall={setIsHolidayApiCall}
                                setIsClickedFilters={setIsClickedFilters}
                                getPosition={getPosition}
                            />
                        </Box>
                    </CustomContainer>
                </Box>
                <Typography my={{ xs: 6, sm: '80px' }}
                    mx={{ xs: 2, sm: '0' }}
                    // marginTop={{
                    //   sm: 0, xs: "20px"
                    // }}
                    variant='h1' textAlign='center' color={colors.basics.primary} >
                    Please complete the form above to explore our range of holidays.
                </Typography>

                {/* <HotelComponent /> */}
            </Box>
        </Box>
    )
}

export default Search