import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import TextInput from '../../components/TextInput';
import { useNavigate } from 'react-router-dom';
import { CustomContainer } from '../../components/CustomContainer';
import * as yup from 'yup';
import { colors } from '../../styles/colors';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../features/loading/loadingSlice';
import { resetPassword } from '../../features/resetPassword/resetPassword';
import useWindowWidth from '../../hooks/useWindowWidth';

export const validationSchema = yup.object().shape({
    email: yup.string().email().required("Email is required"),
});

const ForgotPassword = () => {
    /**
     * @Hooks
     */
    const windowWidth = useWindowWidth();
    const [formData, setFormData] = useState<{ email: string }>({
        email: '',
    });
    const [formError, setFormErrors] = useState<any>({});
    const [apiErrors, setApiErrors] = useState("")
    const [message, setMessage] = useState("")
    const [open, setOpen] = React.useState(false);
    const [invalidLogin, setInvalidLogin] = useState(false);
    const [buttonClick, setButtonClick] = useState(false)

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    /**
     * @Variables
     */
    const navigate = useNavigate();
    const dispatch = useDispatch();
    /**
     * @Functions
     */

    const handleRegisterFormData = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = evt.target;
        const obj = { ...formData, [name]: value }
        setFormData(obj);
        if (buttonClick) {
            validationSchema
                .validate(obj, { abortEarly: false })
                .then(async () => {
                    setFormErrors({});
                })
                .catch((err) => {
                    const errors: any = {};
                    err.inner.forEach((e: any) => {
                        errors[e.path] = e.message;
                    });
                    setFormErrors(errors);
                    dispatch(setLoading(false));
                });
        }
    };

    const handleSubmit = async (e: any) => {
        setButtonClick(true)
        e.preventDefault();
        validationSchema
            .validate(formData, { abortEarly: false })
            .then(async () => {
                setFormErrors({});
                dispatch(setLoading(true))
                const body = [1, { "email_address": formData.email }]
                const response = await dispatch(resetPassword(body))
                const data = response.payload
                if (data.error) {
                    setApiErrors(data.message)
                    dispatch(setLoading(false))
                    setInvalidLogin(true);
                    setOpen(true)
                    setMessage("")
                } else {
                    dispatch(setLoading(false))
                    setFormData({
                        email: ""
                    })
                    setMessage("Password reset instructions have been sent to your email address. Please check your inbox.")
                    // navigate(`/reset-password/${data.key}/${data.id}`)
                }
            })
            .catch((err) => {
                const errors: any = {};
                err.inner.forEach((e: any) => {
                    errors[e.path] = e.message;
                });
                setFormErrors(errors);
                setMessage("")
                dispatch(setLoading(false));
            });
    };

    const handleBack = () => {
        navigate('/signin')
    }
    /**
     * @Returns
     */

    return (
        <>
            <Box bgcolor={colors.basics.secondary} pt={{ xs: windowWidth < 376 ? '130px' : '70px', xl: "0px", lg: '20px', md: '30px', sm: '44px' }}>
                <CustomContainer style={{ marginTop: window.innerWidth > 1200 ? '71px' : '31px', marginBottom: window.innerWidth > 1200 ? '71px' : '31px' }}>
                    <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
                        <Box m={{ md: '51px', xs: '20px 0' }} mr={{ xl: '0px' }} width={{ xs: '100%' }} maxWidth='700px'>
                            <Typography variant='h1' fontSize={'32px'} fontWeight={700} >
                                Forgot Password
                            </Typography>
                            <form>
                                <Typography mt={message !== "" ? 1 : 0} variant='subtitle2' >
                                    {message}
                                </Typography>
                                <Box my={'17px'} sx={{
                                    '.Mui-error': {
                                        margin: "0",
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        width: { xs: "100%", sm: "60%" }
                                        // windowWidth >= 600 ? "60%" : "100%"
                                    }
                                }}>
                                    <TextInput
                                        title='Email'
                                        required
                                        value={formData.email}
                                        onChange={(evt) => handleRegisterFormData(evt)}
                                        // textFieldStyle={{ width: '80%' }}
                                        name={`email`}
                                        error={formError && formError['email'] ? true : false}
                                        helperText={formError && formError['email'] ? formError['email'] : ''}
                                    />
                                </Box>
                                <Box width={{ xs: "100%", sm: "60%" }} mt={formError?.email ? 4 : 3} sx={{ display: "flex", justifyContent: 'space-between' }}>
                                    <Button variant='contained' onClick={handleBack}>
                                        Back
                                    </Button>
                                    <Button variant='contained' onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Box >

                </CustomContainer >
            </Box >
            {
                invalidLogin && (
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert variant='filled' severity='error'>
                            {apiErrors}
                        </Alert>
                    </Snackbar>
                )
            }</>
    );
}

export default ForgotPassword