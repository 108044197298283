// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box, Button, Drawer, Grid, Typography } from "@mui/material";
// import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import Image from "../../../components/Image";
import { FlightTrack } from "../../../components/FlightTrack";
import {
  flightTime,
  getFlightTimesObject,
  singleFlightTravelTime,
  sumTime,
} from "../../../helpers/TotalFlightTime";
import { formatTime } from "../../../helpers/formatTime";
import { colors } from "../../../styles/colors";
import { useAppSelector } from "../../../hooks/useAppReducer";
import React, { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import { ApplicationStateType } from "../../../features/store";
import useWindowWidth from "../../../hooks/useWindowWidth";
import ChangeFlightDrawer from "./ChangeFlightDrawer";
import { ReactComponent as CloseModal } from "../../../assets/icons/CloseModal.svg";
import { ReactComponent as RightChevron } from "../../../assets/icons/RightChervon.svg";
import { ReactComponent as FlightTakeoffIcon } from "../../../assets/icons/FlightTakeOff.svg";
import LuggageIcon from "@mui/icons-material/Luggage";
import NoLuggageIcon from "@mui/icons-material/NoLuggage";
import { isEmpty } from "../../../helpers/isEmpty";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import { ReactComponent as TimeDuration } from "../../../assets/icons/TimeDuration.svg";
import { ReactComponent as Moon } from "../../../assets/icons/Moon.svg";
import { setSessionStorage } from "../../../helpers/storage";
import { calculateDateDifference } from "../../../helpers/calculateDateDifference";

interface SelcetFlightsProps {
  isPlataniumHotel: any;
  toggleFlightDrawer: any;
  setInOutOpenFlightDrawer: any;
  openInOutFlightDrawer: any;
  toggleFlightDrawerInOut: any;
  nights: any;
  setIsFlightChanged: any;
  setOpenChangeFlightDrawer: any;

  inboundSorting: any;
  setIsLoading: any;
  setInboundSorting: any;
  showButtonsAndFirstComponent?: () => void;

}
export const SelectedFlight = ({
  setOpenChangeFlightDrawer,
  showButtonsAndFirstComponent,
  nights,
  isPlataniumHotel,
  toggleFlightDrawer,
  setInOutOpenFlightDrawer,
  openInOutFlightDrawer,
  toggleFlightDrawerInOut,
  setIsFlightChanged,
  inboundSorting,
  setInboundSorting,
  setIsLoading
}: SelcetFlightsProps) => {
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  const { hotelDetails } = useAppSelector((state) => state.hotelDetails);
  const { holidayPackage } = useAppSelector((state) => state.holidayPackage);
  const { flightRoom } = useAppSelector((state: ApplicationStateType) => state.flightRoom);
  // console.log('flightRoom :', flightRoom);
  // console.log('flightRoom :', flightRoom);
  const data = flightRoom.selectedFlights.outbound;
  // console.log('data :', data);
  const dataIn = flightRoom.selectedFlights.inbound;
  // console.log('dataIn :', dataIn);
  // console.log('dataIn :', dataIn);
  const windowWidth = useWindowWidth();
  const isFlightArrivingNextDay = useMemo(() => {
    return data?.map((item: any) => item?.FlightPswFlight.dep_arr_date_diff).includes("1");
  }, [data]);

  const departureLocation =
    flightRoom?.searchCriteria?.departure &&
    flightRoom?.searchCriteria?.departure.depart_airport_name.split(" ")[0];
  const ArrivalLocation =
    flightRoom?.searchCriteria?.destination &&
    flightRoom?.searchCriteria?.destination["Display Name #1"]?.split(", ")[0];

  const totalFlightTime = useCallback(() => {
    let time: string[] = [];
    if (data?.length) {
      time = getFlightTimesObject(data);
    }

    const parts = sumTime(time).split(" ").filter(Boolean);
    const formattedLayoverTime =
      parts.length > 0 ? (parts[0] === "0hr" ? parts[1] : `${parts[0] + " "}${parts[1]}`) : "";
    return formattedLayoverTime;
  }, [data]);

  const departure_time = useMemo(() => {
    return `${dayjs(data[0]?.FlightPswFlight?.departure_date).format("MMM DD")} ${formatTime(
      data[0]?.FlightPswFlight?.departure_time
    )}`;
  }, [data]);

  const arrival_time =
    dayjs(data[data?.length - 1]?.FlightPswFlight?.arrival_date).format("MMM DD") +
    " " +
    formatTime(data[data?.length - 1]?.FlightPswFlight?.arrival_time);

  const totalFlightTimeInbound = useCallback(() => {
    let time: string[] = [];
    if (dataIn?.length) {
      time = getFlightTimesObject(dataIn);
    }

    const parts = sumTime(time).split(" ").filter(Boolean);
    const formattedLayoverTime =
      parts.length > 0 ? (parts[0] === "0hr" ? parts[1] : `${parts[0] + " "}${parts[1]}`) : "";
    return formattedLayoverTime;
  }, [dataIn]);

  // console.log('data :', data);
  // console.log('dataIn :', dataIn);
  const departure_time_inbound = useMemo(() => {
    return `${dayjs(dataIn?.[0]?.FlightPswFlight?.departure_date).format("MMM DD")} ${formatTime(
      dataIn?.[0]?.FlightPswFlight?.departure_time
    )}`;
  }, [dataIn]);

  const arrival_time_inbound =
    dayjs(dataIn?.[dataIn?.length - 1]?.FlightPswFlight?.arrival_date).format("MMM DD") +
    " " +
    formatTime(dataIn?.[dataIn?.length - 1]?.FlightPswFlight?.arrival_time);

  function formatDateToShortMonthDay(dateString: any) {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Format the date to "MMM DD" format
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "short", // Abbreviated month
      day: "numeric", // Day of the month
    });

    return formattedDate;
  }

  const handleChangeFlightsDrawer = () => {
    // setInboundSorting(true)
    // setIsFlightChanged(false)
    setIsLoading(true)
    // setOpenChangeFlightDrawer(true);
    showButtonsAndFirstComponent?.();
  };



  return (
    <>
      <Box
        sx={{
          backgroundColor: colors.basics.white,
          border: `1px solid ${colors.border.grey}`,
          borderRadius: "4px",
          display: flightRoom.selectedFlights.outbound.length > 0 ? "flex" : "none",
          flexDirection: "column",
          gap: 1,
          "& ::selection": {
            background: isPlataniumHotel
              ? colors.basics.plataniumGreenColor
              : colors.basics.primary,
          },
        }}
      >
        <Typography
          sx={{ padding: "20px 20px 0 20px", fontSize: { sm: "20px", xs: "18px" } }}
          variant="h2"
          lineHeight={"31.26px"}
        >
          Your included flight
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            // padding: { md: '0 20px' },
          }}
        >
          <Box sx={{ padding: { md: "0 24px", xs: "0 10px" }, md: "10px 20px 0 20px" }}>
            <Box
              width="100%"
              display={{ md: "flex", xs: "block" }}
              justifyContent="space-between"
              sx={{
                background: { md: colors.basics.white, xs: colors.grey[20] },
              }}
            >
              <Box
                pt={{ lg: "23px", sm: "20px", xs: "16px" }}
                pb={{ lg: "23px", sm: "20px", xs: "16px" }}
                pl={{ lg: "0", sm: "20px", xs: "16px" }}
                pr={{ lg: "0", sm: "20px", xs: "16px" }}
                sx={{
                  // backgroundColor: "pink",
                  borderBottom: { md: " none", xs: `0.5px solid ${colors.grey[30]}` },
                  width: { md: "25%", xs: "100%" },
                  display: "flex",
                  justifyContent: { md: "start", xs: "space-between" },
                  flexDirection: "row",
                  marginBottom: { md: "10px", xs: "0" },
                  flex: 1,
                  gap: "0px",
                  alignItems: "center",
                  "& img": {
                    width: "80px",
                    // height: "fit-content",
                  },
                }}
              >
                <Box sx={{ display: "flex" }}>
                  {/* <Box>
                    <Image
                      src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${data[0]?.FlightPswFlight?.airline_code}.png`}
                      alt=""
                    />
                  </Box> */}
                  <Box sx={{ display: { md: "none", xs: "flex" }, }}>
                    {/* <Typography variant="body2" fontSize={'13px'}>{data[0]?.FlightPswFlight?.psw_result_id}</Typography> */}

                    <Typography variant="body2" fontSize={"13px"}>
                      {data[0]?.FlightPswFlight?.airline_name}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  {/* <Typography variant="body2" fontSize={'13px'}>{data[0]?.FlightPswFlight?.psw_result_id}</Typography> */}
                  <Image
                    src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${data[0]?.FlightPswFlight?.airline_code}.png`}
                    alt=""
                  />
                  <Typography variant="body2" fontSize={"13px"} textAlign={'left'} sx={{ display: { md: "flex", xs: "none" } }}>
                    {data[0]?.FlightPswFlight?.airline_name}
                  </Typography>
                  <Typography
                    variant="body2"
                    fontSize={"13px"}
                    color={colors.grey[40]}
                    fontWeight={"400"}
                    textAlign={'right'}
                    sx={{ display: { md: "flex", xs: "none" }, }}
                  >
                    {data[0]?.FlightPswFlight?.class_name}
                    {", "}
                    {data[0]?.FlightPswFlight?.airline_code}
                    {data[0]?.FlightPswFlight?.flight_number}
                  </Typography>
                </Box>
                <Box sx={{ display: { md: "none", xs: "flex" } }}>
                  <Typography textAlign={'right'} variant="body2" fontSize={"13px"} color={colors.grey[40]} fontWeight={"400"}>
                    {data[0]?.FlightPswFlight?.class_name}
                    {", "}
                    {data[0]?.FlightPswFlight?.airline_code}
                    {data[0]?.FlightPswFlight?.flight_number}
                  </Typography>
                </Box>
              </Box>
              <Box
                pt={{ lg: "23px", sm: "20px", xs: "16px" }}
                pb={{ lg: "23px", sm: "20px", xs: "16px" }}
                pl={{ lg: "0", sm: "20px", xs: "16px" }}
                pr={{ lg: "0", sm: "20px", xs: "16px" }}
                sx={{ width: { md: "75%", xs: "100%" } }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    "& img": {
                      width: "60px",
                      height: "fit-content",
                    },
                    background: "red",
                  }}
                ></Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  m="auto"
                  width={"100%"}
                  sx={{
                    "& .MuiTypography-root": {
                      fontWeight: 500,
                      lineHeight: "130%",
                      // mt: { sm: '10px', xs: "0" },
                    },
                  }}
                >
                  <Box width={'20%'}>
                    {/* <Box sx={{ textAlign: 'left' }}> */}
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      lineHeight="120%"
                      sx={{ fontSize: { sm: "16px", xs: "13px" } }}
                    >
                      {`${data[0]?.FlightPswFlight?.departure_airport}`}
                    </Typography>
                    {/* </Box> */}
                    <Typography
                      variant="body1"
                      fontWeight={300}
                      lineHeight="120%"
                      sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                    >
                      {departure_time.split(" ")[2]}
                    </Typography>
                    <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' } }}>
                      {formatDateToShortMonthDay(data[0]?.FlightPswFlight?.departure_date)}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={300}
                      lineHeight="120%"
                      sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                    >
                      {data[0]?.FlightPswFlight?.Airport?.depart_airport_city}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ textAlign: "center" }}
                    width={{ md: "60%", xs: windowWidth < 375 ? "55%" : "60%" }}
                    m="auto"
                  >
                    {window.location.host.includes("localhost") && <span style={{ fontSize: "12px" }}>{data?.[0]?.FlightPswFlight?.psw_result_id}</span>}
                    <Box width="100%" mt="5px">
                      <FlightTrack stopPoint={data?.length - 1 === 0 ? 0 : 1} singleLine={true} />
                    </Box>
                    <Typography
                      variant="body2"
                      fontSize={"13px"}
                      fontWeight={300}
                      lineHeight={"15.26px"}
                      mt="8px"
                    >
                      {singleFlightTravelTime(data?.[0]?.FlightPswFlight?.segment1_elapse_flying_time)?.replace(/(\d+)\s?hr/g, '$1hr') // Remove space between number and 'hr'
                        .replace(/\s?m/g, 'm')} • {" "}
                      {/* {totalFlightTime()} • {" "} */}
                      {/* {data?.length - 1 === 0 ? "Direct)" : data?.length - 1 + " stop)"} */}
                      {data?.length - 1 === 0
                        ? "(Direct)"
                        : data?.length - 1 === 1
                          ? "(1 stop)"
                          : `(${data?.length - 1} stops)`}
                      <br />
                    </Typography>
                  </Box>
                  <Box textAlign="right" width={'20%'}>
                    {/* <Box textAlign='right'> */}
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      lineHeight="120%"
                      sx={{ fontSize: { sm: "16px", xs: "13px" } }}
                    >
                      {data[data?.length - 1]?.FlightPswFlight?.arrival_airport}
                    </Typography>
                    {/* </Box> */}
                    <Typography
                      variant="body1"
                      fontWeight={300}
                      lineHeight="120%"
                      sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                    >
                      {arrival_time.split(" ")[2]}{" "}  <span
                        style={{
                          color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                        }}
                      >


                        {(() => {
                          const difference = calculateDateDifference(
                            data[0]?.FlightPswFlight?.departure_date,
                            data[data?.length - 1]?.FlightPswFlight?.arrival_date
                          );
                          return difference !== null ? `+${difference}` : null;
                        })()}
                      </span>
                      {/* <span
                        style={{
                          color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                        }}
                      >
                        {isFlightArrivingNextDay ? "+1" : null}
                      </span> */}
                    </Typography>
                    <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' } }}>
                      {formatDateToShortMonthDay(data[data?.length - 1]?.FlightPswFlight?.arrival_date)}

                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={300}
                      lineHeight="120%"
                      sx={{
                        fontSize: { sm: "13px", xs: "13px" },
                        // wordBreak: "break-word",
                        // hyphens: "manual",
                      }}
                    >
                      {data[data?.length - 1]?.FlightPswFlight?.Airport?.arrive_airport_city}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              mt={{ md: "0", xs: "6px" }}
              width="100%"
              display={{ md: "flex", xs: "block" }}
              justifyContent="space-between"
              sx={{
                background: { md: colors.basics.white, xs: colors.grey[20] },
              }}
            >
              <Box
                pt={{ lg: "23px", sm: "20px", xs: "16px" }}
                pb={{ lg: "23px", sm: "20px", xs: "16px" }}
                pl={{ lg: "0", sm: "20px", xs: "16px" }}
                pr={{ lg: "0", sm: "20px", xs: "16px" }}
                sx={{
                  // backgroundColor: "pink",
                  borderBottom: { md: " none", xs: `0.5px solid ${colors.grey[30]}` },
                  width: { md: "25%", xs: "100%" },
                  display: "flex",
                  justifyContent: { md: "start", xs: "space-between" },
                  flexDirection: "row",
                  marginBottom: { md: "10px", xs: "0" },
                  flex: 1,
                  gap: "0px",
                  alignItems: "center",
                  "& img": {
                    width: "80px",
                    // height: "fit-content",
                  },
                }}
              >
                <Box sx={{ display: "flex" }}>
                  {/* <Box>
                    <Image
                      src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${dataIn?.[0]?.FlightPswFlight?.airline_code}.png`}
                      alt=""
                    />
                  </Box> */}
                  <Box sx={{ display: { md: "none", xs: "flex" }, }}>
                    {/* <Typography variant="body2" fontSize={'13px'}>{dataIn?.[0]?.FlightPswFlight?.psw_result_id}</Typography> */}

                    <Typography variant="body2" fontSize={"13px"}>
                      {dataIn?.[0]?.FlightPswFlight?.airline_name}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  {/* <Typography variant="body2" fontSize={'13px'}>{dataIn?.[0]?.FlightPswFlight?.psw_result_id}</Typography> */}
                  <Image
                    src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${dataIn?.[0]?.FlightPswFlight?.airline_code}.png`}
                    alt=""
                  />
                  <Typography variant="body2" fontSize={"13px"} sx={{ display: { md: "flex", xs: "none" } }}>
                    {dataIn?.[0]?.FlightPswFlight?.airline_name}
                  </Typography>
                  <Typography
                    variant="body2"
                    fontSize={"13px"}
                    color={colors.grey[40]}
                    fontWeight={"400"}
                    textAlign={'right'}
                    sx={{ display: { md: "flex", xs: "none" } }}
                  >
                    {data[0]?.FlightPswFlight?.class_name}
                    {", "}
                    {dataIn?.[0]?.FlightPswFlight?.airline_code}
                    {dataIn?.[0]?.FlightPswFlight?.flight_number}
                  </Typography>
                </Box>
                <Box sx={{ display: { md: "none", xs: "flex" } }}>
                  <Typography textAlign={'right'} variant="body2" fontSize={"13px"} color={colors.grey[40]} fontWeight={"400"}>
                    {data[0]?.FlightPswFlight?.class_name}
                    {", "}
                    {dataIn?.[0]?.FlightPswFlight?.airline_code}
                    {dataIn?.[0]?.FlightPswFlight?.flight_number}
                  </Typography>
                </Box>
              </Box>
              <Box
                pt={{ lg: "23px", sm: "20px", xs: "16px" }}
                pb={{ lg: "23px", sm: "20px", xs: "16px" }}
                pl={{ lg: "0", sm: "20px", xs: "16px" }}
                pr={{ lg: "0", sm: "20px", xs: "16px" }}
                sx={{ width: { md: "75%", xs: "100%" } }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    "& img": {
                      width: "60px",
                      height: "fit-content",
                    },
                    background: "red",
                  }}
                ></Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  m="auto"
                  sx={{
                    "& .MuiTypography-root": {
                      fontWeight: 500,
                      lineHeight: "130%",
                      // mt: { sm: '10px', xs: "0" },
                    },
                  }}
                >
                  <Box width={'20%'}>
                    {/* <Box sx={{ textAlign: 'left' }}> */}
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      lineHeight="120%"
                      sx={{ fontSize: { sm: "16px", xs: "13px" } }}
                    >
                      {`${dataIn?.[0]?.FlightPswFlight?.departure_airport}`}
                    </Typography>
                    {/* </Box> */}
                    <Typography
                      variant="body1"
                      fontWeight={300}
                      lineHeight="120%"
                      sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                    >
                      {departure_time_inbound.split(" ")[2]}
                    </Typography>

                    <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' } }}>
                      {formatDateToShortMonthDay(dataIn?.[0]?.FlightPswFlight?.departure_date)}

                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={300}
                      lineHeight="120%"
                      sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                    >
                      {dataIn?.[0]?.FlightPswFlight?.Airport?.depart_airport_city}
                    </Typography>
                  </Box>

                  <Box
                    sx={{ textAlign: "center" }}
                    width={{ md: "60%", xs: windowWidth < 375 ? "55%" : "60%" }}
                    m="auto"
                  >
                    {window.location.host.includes("localhost") && <span style={{ fontSize: "12px" }}>{dataIn?.[0]?.FlightPswFlight?.psw_result_id}</span>}

                    <Box width="100%" mt="5px">
                      <FlightTrack stopPoint={dataIn?.length - 1 === 0 ? 0 : 1} singleLine={true} />
                    </Box>
                    <Typography
                      variant="body2"
                      fontSize={"13px"}
                      fontWeight={300}
                      lineHeight={"15.26px"}
                      mt="8px"
                    >
                      {singleFlightTravelTime(dataIn?.[0]?.FlightPswFlight?.segment2_elapse_flying_time)?.replace(/(\d+)\s?hr/g, '$1hr').replace(/\s?m/g, 'm')} • {" "}
                      {/* {totalFlightTimeInbound()} • {" "} */}
                      {/* {dataIn?.length - 1 === 0 ? "Direct)" : dataIn?.length - 1 + " stop)"}  */}
                      {dataIn?.length - 1 === 0
                        ? "(Direct)"
                        : dataIn?.length - 1 === 1
                          ? "(1 stop)"
                          : `(${dataIn?.length - 1} stops)`}
                      <br />
                    </Typography>
                  </Box>
                  <Box textAlign="right" width={'20%'}>
                    {/* <Box textAlign='right'> */}
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      lineHeight="120%"
                      sx={{ fontSize: { sm: "16px", xs: "13px" } }}
                    >
                      {dataIn?.[dataIn?.length - 1]?.FlightPswFlight?.arrival_airport}
                    </Typography>
                    {/* </Box> */}
                    <Typography
                      variant="body1"
                      fontWeight={300}
                      lineHeight="120%"
                      sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                    >
                      {arrival_time_inbound.split(" ")[2]}{" "}
                      <span
                        style={{
                          color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                        }}
                      >

                        {/* {dataIn?.some((flight: any) => flight?.FlightPswFlight?.dep_arr_date_diff === "1") ? "+1" : null} */}

                        {(() => {
                          const difference = calculateDateDifference(
                            dataIn[0]?.FlightPswFlight?.departure_date,
                            dataIn[dataIn?.length - 1]?.FlightPswFlight?.arrival_date
                          );
                          return difference !== null ? `+${difference}` : null;
                        })()}
                        {/* +{calculateDateDifference(dataIn[0]?.FlightPswFlight?.departure_date, dataIn[dataIn?.length - 1]?.FlightPswFlight?.arrival_date)} */}

                        {/* {isFlightArrivingNextDay ? "+1" : null} */}
                      </span>
                    </Typography>
                    <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' } }}>
                      {formatDateToShortMonthDay(dataIn?.[dataIn?.length - 1]?.FlightPswFlight?.arrival_date)}

                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={300}
                      lineHeight="120%"
                      sx={{
                        fontSize: { sm: "13px", xs: "13px" },
                        // wordBreak: "break-word",
                        // hyphens: "manual",
                      }}
                    >
                      {dataIn?.[dataIn?.length - 1]?.FlightPswFlight?.Airport?.arrive_airport_city}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: { md: '14px 20px', xs: "14px 14px" },
              justifyContent: "space-between",
              borderTop: "1px solid #ccc",
            }}
          >
            <Box
              onClick={toggleFlightDrawerInOut(true)}
              sx={{ display: "flex", alignItems: "center", gap: 1, cursor: "pointer" }}
            >
              <FlightTakeoffIcon
                width={24}
                height={24}
                sx={{
                  color: isPlataniumHotel
                    ? `${colors.basics.plataniumGreenColor} !important`
                    : `${colors.basics.primary} !important`,
                }}
              />
              <Typography variant="body1" fontWeight={500}>
                Flight details
              </Typography>
            </Box>
            <Box sx={{
              display: "flex", alignItems: "center", marginRight: 2, color: isPlataniumHotel
                ? `${colors.basics.plataniumGreenColor} !important`
                : `${colors.basics.primary} !important`,
            }} >
              {data[0]?.FlightPswFlight?.Baggage ? <LuggageIcon /> : <NoLuggageIcon />}
              <Typography variant="body2" sx={{ marginLeft: 1 }}>
                {/* {data[0]?.FlightPswFlight?.Baggage} */}
                {/* {data[0]?.FlightPswFlight?.Baggage.replace("K", `${" "}kg`)} */}
                {data[0]?.FlightPswFlight?.Baggage.replace("K", "K").replace("pc", "p").replace("p", "p")}
              </Typography>
            </Box>

            <Box
              sx={{
                display: windowWidth < 376 ? 'none' : 'flex',
                "& .MuiButtonBase-root": {
                  color: colors.basics.white,
                  background: isPlataniumHotel
                    ? `${colors.basics.plataniumGreenColor} !important`
                    : `${colors.basics.primary} !important`,
                  height: { xs: "40px", sm: "50px" },
                  border: isPlataniumHotel
                    ? `1px solid ${colors.basics.plataniumGreenColor} !important`
                    : `1px solid ${colors.basics.primary} !important`,
                  "&:hover": {
                    background: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                    color: `${colors.basics.white} !important`,
                  },
                },
              }}
            >
              <Button
                sx={{
                  p: "8px 20px ",
                  whiteSpace: "nowrap",
                  fontSize: { sm: "14px", xs: "12px" },
                  width: "100%",
                  height: "33px !important",
                }}
                onClick={handleChangeFlightsDrawer}
              >
                Change flight
              </Button>
            </Box>



          </Box>
          <Box sx={{
            display: windowWidth < 376 ? 'flex' : 'none',
            alignItems: "center",
            padding: { md: '14px 20px', xs: "0px 14px 14px" },
            justifyContent: "space-between",
            // borderTop: "1px solid #ccc",
          }}>

            <Box width={'100%'}
              sx={{
                display: windowWidth < 376 ? 'flex' : 'none',
                "& .MuiButtonBase-root": {
                  color: colors.basics.white,
                  background: isPlataniumHotel
                    ? `${colors.basics.plataniumGreenColor} !important`
                    : `${colors.basics.primary} !important`,
                  height: { xs: "40px", sm: "50px" },
                  border: isPlataniumHotel
                    ? `1px solid ${colors.basics.plataniumGreenColor} !important`
                    : `1px solid ${colors.basics.primary} !important`,
                  "&:hover": {
                    background: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                    color: `${colors.basics.white} !important`,
                  },
                },
              }}
            >
              <Button
                sx={{
                  // p: "8px 20px ",
                  whiteSpace: "nowrap",
                  fontSize: { sm: "14px", xs: "12px" },
                  width: "100%",
                  height: "33px !important",
                }}
                onClick={handleChangeFlightsDrawer}
              >
                Change flight
              </Button>
            </Box>
          </Box>

        </Box>
        <Drawer
          anchor={"right"}
          open={openInOutFlightDrawer}
          // onClose={toggleFlightDrawerInOut(false)}
          PaperProps={{
            sx: { width: { md: 600, xs: "100%" } },
          }}
        >
          <Box
            sx={{
              backgroundColor: colors.grey[50],
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "& .MuiButton-root svg": {
                width: "25.46px",
                height: "18px",
                p: "0",
                // fill: "red"

              },
              "& .MuiButton-root": {

                px: {
                  sm:
                    "10px", xs: '6px'
                }, minWidth: '0',
                background: "white"
              },
              p: { sm: "15px 16px", xs: "15px 10px" },
            }
            }
          >
            <Button disableRipple
              variant="text"
              onClick={() => {
                setInOutOpenFlightDrawer(false);
                // setInnerAccordionExpanded(Number(expand ? -1 : current))
              }}
            >
              {" "}
              <CloseModal />{" "}
            </Button>
            <Box>Your flight details</Box>
            <Box></Box>
          </Box>
          <Box

            m={{ md: "20px 0px 0px 0px", sm: 0, xs: "15px 10px 20px 10px" }}
            sx={{ padding: { md: "10px 50px", sm: "20px" } }}
            display={"block"}
            alignItems={"center"}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="h1"
                fontSize={{ sm: "20px", xs: "14px" }}
                sx={{ textAlign: { lg: "left", xs: "center" } }}
              >
                Outbound {holiday.flight.outbound[0].outbound_date?.slice(0, -6) || ""}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& svg path": {
                    fill: colors.grey[10],
                  },
                }}
              >
                <Box>
                  <Typography lineHeight={1.2} variant="h3" fontSize="20px">
                    {departureLocation}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mx: "10px" }}>
                  <RightChevron width={10} height={14} />
                </Box>
                <Box>
                  <Typography lineHeight={1.2} variant="h3" fontSize="20px">
                    {ArrivalLocation}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                border: "2px solid #E0E0E0",
                borderRadius: "8px",
                marginBottom: "10px",
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <Box
                  width="100%"
                  display={"block"}
                  justifyContent="space-between"
                >
                  <Box
                    pt={{ lg: "16px", sm: "20px", xs: "16px" }}
                    pb={{ lg: "16px", sm: "20px", xs: "16px" }}
                    pl={{ lg: "16px", sm: "20px", xs: "16px" }}
                    pr={{ lg: "16px", sm: "20px", xs: "16px" }}
                    sx={{
                      // backgroundColor: "pink",
                      borderBottom: `0.5px solid ${colors.grey[30]}`,
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      marginBottom: "0",
                      flex: 1,
                      gap: "10px",
                      alignItems: "center",
                      "& img": {
                        width: "60px",
                        // height: "fit-content",
                      },
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Box>
                        <Image
                          src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${data[0]?.FlightPswFlight?.airline_code}.png`}
                          alt=""
                        />
                      </Box>
                      <Box sx={{ display: "flex", ml: "20px" }}>
                        <Typography variant="body2" fontSize={"13px"}>
                          {data[0]?.FlightPswFlight?.airline_name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      {/* <Typography variant="body2" fontSize={'13px'} sx={{ display: { md: "flex", xs: "none" } }}>{data[0]?.FlightPswFlight?.airline_name}</Typography> */}
                      {/* <Typography variant="body2" fontSize={'13px'} color={colors.grey[30]} fontWeight={'400'} sx={{ display: { md: "flex", xs: "none" } }}>
                    {data[0]?.FlightPswFlight?.airline_code}
                    {data[0]?.FlightPswFlight?.flight_number}
                  </Typography> */}
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        variant="body2"
                        fontSize={"13px"}
                        color={colors.grey[40]}
                        fontWeight={"400"}
                        textAlign={'right'}
                      >

                        {data[0]?.FlightPswFlight?.class_name}{", "}
                        {data[0]?.FlightPswFlight?.airline_code}
                        {data[0]?.FlightPswFlight?.flight_number}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    pt={{ lg: "16px", sm: "20px", xs: "16px" }}
                    pb={{ lg: "16px", sm: "20px", xs: "16px" }}
                    pl={{ lg: "16px", sm: "20px", xs: "16px" }}
                    pr={{ lg: "16px", sm: "20px", xs: "16px" }}
                    sx={{ width: "100%" }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        "& img": {
                          width: "60px",
                          height: "fit-content",
                        },
                        background: "red",
                      }}
                    >
                      <Grid container columnSpacing={1}>
                        <Grid xs={4} item>
                          {/* <Box sx={{ textAlign: 'left' }}>
                        <Typography
                          variant='body1'
                          fontWeight={500}
                          lineHeight='120%'
                          sx={{ fontSize: { sm: '16px', xs: '14px' } }}
                        >
                          {data[0]?.FlightPswFlight?.Airport?.depart_airport_city}{' '}
                          {`(${data[0]?.FlightPswFlight?.departure_airport})`}
                        </Typography>
                      </Box> */}
                        </Grid>
                        {/* <Grid xs={4} item>
                      <Box textAlign='center'>
                        <Typography
                          variant='body1'
                          fontWeight={500}
                          lineHeight='130%'
                          sx={{ fontSize: { sm: '16px', xs: '14px' } }}
                        >
                          {totalFlightTime()}
                        </Typography>
                      </Box>
                    </Grid> */}
                        <Grid xs={4} item sx={{ pl: "5px !important" }}>
                          {/* <Box textAlign='right'>
                        <Typography
                          variant='body1'
                          fontWeight={500}
                          lineHeight='130%'
                          sx={{ fontSize: { sm: '16px', xs: '14px' }, wordBreak: "break-word", hyphens: "manual" }}
                        >
                          {data[data?.length - 1]?.FlightPswFlight?.Airport?.arrive_airport_city} (
                          {data[data?.length - 1]?.FlightPswFlight?.arrival_airport})
                        </Typography>
                      </Box> */}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      m="auto"
                      width={"100%"}
                      sx={{
                        "& .MuiTypography-root": {
                          fontWeight: 500,
                          lineHeight: "130%",
                          // mt: { sm: '10px', xs: "0" },
                        },
                      }}
                    >
                      <Box width={'20%'}>
                        {/* <Box sx={{ textAlign: 'left' }}> */}
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          lineHeight="120%"
                          sx={{ fontSize: { sm: "16px", xs: "13px" } }}
                        >
                          {`${data[0]?.FlightPswFlight?.departure_airport}`}
                        </Typography>
                        {/* </Box> */}
                        <Typography
                          variant="body1"
                          fontWeight={300}
                          lineHeight="120%"
                          sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                        >

                          {departure_time.split(" ")[2]}
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={300}
                          lineHeight="120%"
                          sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                        >
                          {/* {formatDateToShortMonthDay(data[0]?.FlightPswFlight?.departure_date)} */}

                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={300}
                          lineHeight="120%"
                          sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                        >
                          {data[0]?.FlightPswFlight?.Airport?.depart_airport_city}
                        </Typography>
                      </Box>

                      <Box
                        sx={{ textAlign: "center" }}
                        width={{ md: "60%", xs: windowWidth < 375 ? "55%" : "60%" }}
                        m="auto"
                      >
                        <Box width="100%" mt="5px">
                          <FlightTrack stopPoint={data?.length - 1 === 0 ? 0 : 1} singleLine={true} />
                        </Box>
                        <Typography
                          variant="body2"
                          fontSize={"13px"}
                          fontWeight={300}
                          lineHeight={"15.26px"}
                          mt="8px"
                        >
                          {singleFlightTravelTime(data?.[0]?.FlightPswFlight?.segment1_elapse_flying_time)?.replace(/(\d+)\s?hr/g, '$1hr').replace(/\s?m/g, 'm')} • {" "}
                          {/* {totalFlightTime()} • {" "} */}
                          {/* {data?.length - 1 === 0 ? "Direct)" : data?.length - 1 + " stop)"} */}
                          {data?.length - 1 === 0
                            ? "(Direct)"
                            : data?.length - 1 === 1
                              ? "(1 stop)"
                              : `(${data?.length - 1} stops)`}
                          <br />
                        </Typography>
                      </Box>
                      <Box textAlign="right" width={'20%'}>
                        {/* <Box textAlign='right'> */}
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          lineHeight="120%"
                          sx={{ fontSize: { sm: "16px", xs: "13px" } }}
                        >
                          {data[data?.length - 1]?.FlightPswFlight?.arrival_airport}
                        </Typography>
                        {/* </Box> */}
                        <Typography
                          variant="body1"
                          fontWeight={300}
                          lineHeight="120%"
                          sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                        >
                          {/* {formatDateToShortMonthDay(data[0]?.FlightPswFlight?.arrival_date)} •{" "} */}
                          {arrival_time.split(" ")[2]}{" "}
                          <span
                            style={{
                              color: isPlataniumHotel
                                ? colors.basics.plataniumGreenColor
                                : colors.basics.primary,
                            }}
                          >
                            {(() => {
                              const difference = calculateDateDifference(
                                data[0]?.FlightPswFlight?.departure_date,
                                data[data?.length - 1]?.FlightPswFlight?.arrival_date
                              );
                              return difference !== null ? `+${difference}` : null;
                            })()}
                            {/* {isFlightArrivingNextDay ? "+1" : null} */}
                          </span>
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={300}
                          lineHeight="120%"
                          sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                        >
                          {/* {formatDateToShortMonthDay(data[0]?.FlightPswFlight?.arrival_date)} */}
                          {/* {arrival_time.split(" ")[2]}{" "} */}
                          {/* <span
                              style={{
                                color: isPlataniumHotel
                                  ? colors.basics.plataniumGreenColor
                                  : colors.basics.primary,
                              }}
                            >
                              {isFlightArrivingNextDay ? "+1" : null}
                            </span> */}
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={300}
                          lineHeight="120%"
                          sx={{
                            fontSize: { sm: "13px", xs: "13px" },
                            // wordBreak: "break-word",
                            // hyphens: "manual",
                          }}
                        >
                          {data[data?.length - 1]?.FlightPswFlight?.Airport?.arrive_airport_city}
                        </Typography>
                      </Box>
                    </Box>
                    {/* <Box justifyContent='space-between' alignItems='center' textAlign='center'>
                  <Box>
                    <Typography
                      variant='body1'
                      fontWeight={500}
                      lineHeight='130%'
                      sx={{ fontSize: { sm: '16px', xs: '14px' } }}
                    >
                      {"("}{data?.length - 1 === 0 ? "Direct)" : data?.length - 1 + " stop)"} <br />
                    </Typography>
                  </Box>
                </Box> */}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderTop: {
                      md: "0.5px solid #E0E0E0",
                      xs: `0.5px solid ${colors.grey[10]}`,
                    },
                    borderBottom: {
                      md: "0.5px solid #E0E0E0",
                      xs: `0.5px solid ${colors.grey[10]}`,
                    },

                    justifyContent: "space-between",
                    background: "transparent",
                    "& .MuiSvgIcon-root": {
                      color: isPlataniumHotel
                        ? `${colors.basics.plataniumGreenColor} !important`
                        : `${colors.basics.primary} !important`,
                    },
                  }}
                  padding={"16px 16px"}
                >
                  <Box sx={{ display: "flex", alignItems: "center", marginRight: 2 }}>
                    {data[0]?.FlightPswFlight?.Baggage ? <LuggageIcon /> : <NoLuggageIcon />}
                    <Typography variant="body2" sx={{ marginLeft: 1 }}>
                      {/* {data[0]?.FlightPswFlight?.Baggage} */}
                      {/* {data[0]?.FlightPswFlight?.Baggage.replace("K", `${" "}kg`)} */}
                      {data[0]?.FlightPswFlight?.Baggage.replace("K", "K").replace("pc", "p").replace("p", "p")}
                    </Typography>
                  </Box>
                  {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                      <NoLuggageIcon sx={{
                        color: isPlataniumHotel
                          ? `${ colors.basics.plataniumGreenColor }!important`
                          : `${ colors.basics.primary }!important`
                      }} />
                      <Typography variant="body2" sx={{ marginLeft: 1 }}>
                        1 remains
                      </Typography>
                    </Box> */}
                </Box>
                {/* {expand && ( */}

                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={1}
                  width="100%"
                  pt={{ lg: "16px", sm: "20px", xs: windowWidth > 376 ? "16px" : "8px" }}
                  pb={{ lg: "16px", sm: "20px", xs: windowWidth > 376 ? "16px" : "8px" }}
                  pl={{ lg: "16px", sm: "20px", xs: windowWidth > 376 ? "16px" : "8px" }}
                  pr={{ lg: "16px", sm: "20px", xs: windowWidth > 376 ? "16px" : "8px" }}
                >
                  {data?.map((item: any, index: number) => {
                    item = item?.FlightPswFlight;
                    return (
                      <Box key={index} mb={index !== data[data.length - 1] ? 1 : 0}>
                        {/* {index !== 0 && <Divider />} */}
                        {/* <Box
                      mt={index !== 0 ? 1 : 0}
                      sx={{
                        display: 'flex',
                        alignItems: "center",
                        marginBottom: "20px",
                        gap: "5px",
                        '& img': {
                          width: '120px', height: 'fit-content'
                        }
                      }}
                    >
                      <Image src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${item?.airline_code}.png`} alt='' />
                        <Box ml="auto" >
                          <Typography textAlign={"right"} fontSize={"12px !important"}> ({isEmpty(item?.airline_name) !== "" && item?.airline_name + ", "}{item?.airline_code}
                            {item?.flight_number})</Typography>
                        </Box>
                    </Box> */}
                        {/* {
                      index !== data?.length - 1 && (
                        <Box
                          alignItems='center '
                          justifyContent='center'
                          textAlign='center'
                          p='10px'
                          m='auto'
                          width={{ sm: '70%', xs: 'fit-content' }}
                          mt={{ sm: '30px', xs: '10px' }}
                          mb={{ sm: '30px', xs: '15px' }}
                          bgcolor={"#e3e3e36e"}
                        >
                          <Typography variant='body1'>
                            {flightTime(
                              new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
                              new Date(
                                `${data[index + 1]?.FlightPswFlight?.departure_date} ${formatTime(
                                  data[index + 1]?.FlightPswFlight?.departure_time
                                )}`
                              ), true
                            )}{' '}
                            layover in {item?.Airport?.arrive_airport_city} ({item?.arrival_airport})
                          </Typography>
                        </Box>
                      )
                    } */}

                        <Box>
                          <Box sx={{ position: "relative" }}>
                            <Box sx={{ display: "flex", gap: "34px" }}>
                              <Box
                                sx={{
                                  minWidth: { xs: windowWidth > 376 ? "70px" : "50px" },

                                  position: "relative",
                                  display: "flex",
                                  flexDirection: "column",
                                  paddingRight: { xs: windowWidth > 376 ? "30px" : "16px" },
                                  borderRight: `1px solid ${colors.basics.black}`,
                                  "&:before": {
                                    content: "''",
                                    position: "absolute",
                                    width: "6px",
                                    height: "5px",
                                    backgroundColor: colors.basics.white,
                                    border: `1px solid ${colors.basics.black}`,
                                    right: "-5px",
                                    top: 0,
                                  },
                                  "&:after": {
                                    content: "''",
                                    position: "absolute",
                                    width: "6px",
                                    height: "5px",
                                    backgroundColor: colors.basics.white,
                                    border: `1px solid ${colors.basics.black}`,
                                    right: "-5px",
                                    bottom: "0",
                                  },
                                }}
                              >
                                <Box className="pickup-time">
                                  <Typography
                                    variant="body2"
                                    fontWeight={"500"}
                                    sx={{ fontSize: "16px", display: "block" }}
                                  >
                                    {" "}
                                    {formatTime(item?.departure_time)}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    fontWeight={"300"}
                                    sx={{ fontSize: "12px" }}
                                    color={colors.grey[30]} whiteSpace={'nowrap'}
                                  >
                                    {formatDateToShortMonthDay(item.departure_date)}
                                  </Typography>
                                </Box>
                                <Box sx={{ mt: "auto" }}>
                                  <Typography variant="body2" sx={{ fontSize: "16px", display: "block" }}>
                                    {" "}
                                    {formatTime(item?.arrival_time)}{" "}
                                    <span
                                      style={{
                                        color: isPlataniumHotel
                                          ? colors.basics.plataniumGreenColor
                                          : colors.basics.primary,
                                      }}
                                    >
                                      {/* {isFlightArrivingNextDay ? '+1' : null} */}
                                    </span>
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    fontWeight={"300"}
                                    sx={{ fontSize: "12px" }}
                                    color={colors.grey[30]} whiteSpace={'nowrap'}
                                  >
                                    {formatDateToShortMonthDay(item.arrival_date)}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box sx={{ width: "100%" }}>
                                <Typography sx={{ fontSize: { sm: "14px", xs: "13px" }, mb: "24px", display: "block" }} >
                                  {" "}
                                  ({item?.departure_airport}) {item?.Airport?.depart_airport_city}{" "}
                                </Typography>
                                <Box
                                  sx={{
                                    position: "relative",
                                    border: `0.5px solid ${colors.grey[10]}`,
                                    borderRadius: "5px",
                                    mb: "44px",
                                    width: "100%",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "20px",
                                      alignItems: "center",
                                      p: "10px 10px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        "& img": {
                                          width: "60px",
                                          // height: "fit-content",
                                        },
                                      }}
                                    >
                                      <Image src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${item?.airline_code}.png`} alt='' />
                                      <Typography textAlign={"left"} variant="body2" fontWeight={'300'} fontSize={"13px !important"}>
                                        {isEmpty(item?.airline_name) !== "" && item?.airline_name + ", "}</Typography></Box>
                                    <Box ml='10px'>
                                      <Typography variant="body2" color={colors.grey[40]} fontWeight={'400'} >{item?.airline_code}
                                        {item?.flight_number}</Typography>
                                      <Typography display={{ xs: windowWidth < 374 ? 'flex' : "none" }} variant="body2" color={colors.grey[40]} fontWeight={'400'} > {singleFlightTravelTime(item?.travel_time, true)}</Typography>

                                    </Box>
                                    <Box display={{ xs: windowWidth < 374 ? 'none' : "flex" }}>
                                      <Typography variant="body2" color={colors.grey[40]} fontWeight={'400'} > {singleFlightTravelTime(item?.travel_time, true)}</Typography>

                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      "& svg": {
                                        transform: "rotate(90deg)",
                                        fill: isPlataniumHotel
                                          ? `${colors.basics.plataniumGreenColor} !important`
                                          : `${colors.basics.primary} !important`,
                                      },
                                      position: "absolute",
                                      left: { md: "-13%", sm: "-7%", xs: windowWidth > 374 ? "-17%" : "-23%" },
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                      background: colors.basics.white,
                                      p: "3px 0 0 0",
                                    }}
                                  >
                                    <AirplanemodeActiveIcon />
                                  </Box>
                                </Box>
                                <Typography sx={{ fontSize: { sm: "14px", xs: "13px" }, mb: "5px", display: "block" }}>
                                  ({item?.arrival_airport}) {item?.Airport?.arrive_airport_city}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        {
                          index !== data?.length - 1 && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                position: "relative",
                                borderLeft: "1px dashed black",
                                padding: "15px 0 15px 34px",
                                margin: "8px 0",
                                marginLeft: { sm: "69px", xs: windowWidth > 376 ? "69px" : "49px" },
                                // "&:before": {
                                //   content: "''",
                                //   position: "absolute",
                                //   width: "1px",
                                //   height: "100%",
                                //   top: 0,
                                //   left: 0,
                                // }
                              }}
                            >
                              {/* <Typography variant="body2">(JFK)</Typography> */}
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "10px",
                                  border: `0.5px solid ${colors.grey[10]}`,
                                  borderRadius: "5px",
                                  alignItems: "center",
                                  padding: "10px 10px",
                                  "& svg": {
                                    "& path": {
                                      fill: isPlataniumHotel
                                        ? colors.basics.plataniumGreenColor + "!important"
                                        : colors.basics.primary,
                                    },
                                  },
                                }}
                              >
                                <TimeDuration />
                                {/* <img
                              width={"auto"}
                              height={20}
                              src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/QR.png`}
                              alt=""
                            /> */}
                                <Typography variant="body2" fontWeight={"300"} fontSize={"13px"} sx={{}}>
                                  Stop Duration :{" "}
                                  {flightTime(
                                    new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
                                    new Date(
                                      `${data[index + 1]?.FlightPswFlight?.departure_date} ${formatTime(
                                        data[index + 1]?.FlightPswFlight?.departure_time
                                      )}`
                                    ),
                                    true
                                  )}{" "}
                                  {/* layover in {item?.Airport?.arrive_airport_city} ({item?.arrival_airport}) */}
                                </Typography>
                              </Box>
                            </Box>
                          )
                        }
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Box>
              {/* <Box
                  mt="24px"
                  sx={{
                    p: "10px 0",
                    background: colors.grey[20],
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Moon />
                  </Box>
                  <Box ml="14px">
                    <Typography variant="body2" fontWeight={500} fontSize={"16px"}>
                      {" "}
                      {nights} nights in {ArrivalLocation}
                    </Typography>
                  </Box>
                </Box> */}

              <Box mt="24px" sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="h1"
                  fontSize={{ sm: "20px", xs: "14px" }}
                  sx={{ textAlign: { lg: "left", xs: "center" } }}
                >
                  Inbound {holiday.flight.inbound[0].inbound_date?.slice(0, -6) || ""}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "& svg path": {
                      fill: colors.grey[10],
                    },
                  }}
                >
                  <Box>
                    <Typography lineHeight={1.2} variant="h3" fontSize="20px">
                      {ArrivalLocation}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", mx: "10px" }}>
                    <RightChevron width={10} height={14} />
                  </Box>
                  <Box>
                    <Typography lineHeight={1.2} variant="h3" fontSize="20px">
                      {departureLocation}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  border: "2px solid #E0E0E0",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Box
                    width="100%"
                    display={"block"}
                    justifyContent="space-between"
                  >
                    <Box
                      pt={{ lg: "16px", sm: "20px", xs: "16px" }}
                      pb={{ lg: "16px", sm: "20px", xs: "16px" }}
                      pl={{ lg: "16px", sm: "20px", xs: "16px" }}
                      pr={{ lg: "16px", sm: "20px", xs: "16px" }}
                      sx={{
                        // backgroundColor: "pink",
                        borderBottom: `0.5px solid ${colors.grey[30]}`,
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        marginBottom: "0",
                        flex: 1,
                        gap: "10px",
                        alignItems: "center",
                        "& img": {
                          width: "60px",
                          // height: "fit-content",
                        },
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Box>
                          <Image
                            src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${dataIn?.[0]?.FlightPswFlight?.airline_code}.png`}
                            alt=""
                          />
                        </Box>
                        <Box sx={{ display: "flex", ml: "20px" }}>
                          <Typography variant="body2" fontSize={"13px"}>
                            {dataIn?.[0]?.FlightPswFlight?.airline_name}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        {/* <Typography variant="body2" fontSize={'13px'} sx={{ display: { md: "flex", xs: "none" } }}>{dataIn?.[0]?.FlightPswFlight?.airline_name}</Typography> */}
                        {/* <Typography variant="body2" fontSize={'13px'} color={colors.grey[30]} fontWeight={'400'} sx={{ display: { md: "flex", xs: "none" } }}>
                    {dataIn?.[0]?.FlightPswFlight?.airline_code}
                    {dataIn?.[0]?.FlightPswFlight?.flight_number}
                  </Typography> */}
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          variant="body2"
                          fontSize={"13px"}
                          color={colors.grey[40]}
                          fontWeight={"400"}
                          textAlign={'right'}
                        >
                          {dataIn?.[0]?.FlightPswFlight?.class_name}{", "}
                          {dataIn?.[0]?.FlightPswFlight?.airline_code}
                          {dataIn?.[0]?.FlightPswFlight?.flight_number}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      pt={{ lg: "16px", sm: "20px", xs: "16px" }}
                      pb={{ lg: "16px", sm: "20px", xs: "16px" }}
                      pl={{ lg: "16px", sm: "20px", xs: "16px" }}
                      pr={{ lg: "16px", sm: "20px", xs: "16px" }}
                      sx={{ width: "100%" }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          "& img": {
                            width: "60px",
                            height: "fit-content",
                          },
                          background: "red",
                        }}
                      >
                        <Grid container columnSpacing={1}>
                          <Grid xs={4} item>
                            {/* <Box sx={{ textAlign: 'left' }}>
                        <Typography
                          variant='body1'
                          fontWeight={500}
                          lineHeight='120%'
                          sx={{ fontSize: { sm: '16px', xs: '14px' } }}
                        >
                          {data[0]?.FlightPswFlight?.Airport?.depart_airport_city}{' '}
                          {`(${data[0]?.FlightPswFlight?.departure_airport})`}
                        </Typography>
                      </Box> */}
                          </Grid>
                          {/* <Grid xs={4} item>
                      <Box textAlign='center'>
                        <Typography
                          variant='body1'
                          fontWeight={500}
                          lineHeight='130%'
                          sx={{ fontSize: { sm: '16px', xs: '14px' } }}
                        >
                          {totalFlightTime()}
                        </Typography>
                      </Box>
                    </Grid> */}
                          <Grid xs={4} item sx={{ pl: "5px !important" }}>
                            {/* <Box textAlign='right'>
                        <Typography
                          variant='body1'
                          fontWeight={500}
                          lineHeight='130%'
                          sx={{ fontSize: { sm: '16px', xs: '14px' }, wordBreak: "break-word", hyphens: "manual" }}
                        >
                          {data[data?.length - 1]?.FlightPswFlight?.Airport?.arrive_airport_city} (
                          {data[data?.length - 1]?.FlightPswFlight?.arrival_airport})
                        </Typography>
                      </Box> */}
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        m="auto"
                        sx={{
                          "& .MuiTypography-root": {
                            fontWeight: 500,
                            lineHeight: "130%",
                            // mt: { sm: '10px', xs: "0" },
                          },
                        }}
                      >
                        <Box width={'20%'}>
                          {/* <Box sx={{ textAlign: 'left' }}> */}
                          <Typography
                            variant="body1"
                            fontWeight={500}
                            lineHeight="120%"
                            sx={{ fontSize: { sm: "16px", xs: "13px" } }}
                          >
                            {`${dataIn?.[0]?.FlightPswFlight?.departure_airport}`}
                          </Typography>
                          {/* </Box> */}
                          <Typography
                            variant="body1"
                            fontWeight={300}
                            lineHeight="120%"
                            sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                          >
                            {/* {formatDateToShortMonthDay(dataIn?.[0]?.FlightPswFlight?.departure_date)}  */}
                            {departure_time_inbound.split(" ")[2]}
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight={300}
                            lineHeight="120%"
                            sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                          >
                            {/* {formatDateToShortMonthDay(dataIn?.[0]?.FlightPswFlight?.departure_date)} */}
                            {/* {departure_time_inbound.split(" ")[2]} */}
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight={300}
                            lineHeight="120%"
                            sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                          >
                            {dataIn?.[0]?.FlightPswFlight?.Airport?.depart_airport_city}
                          </Typography>
                        </Box>

                        <Box
                          sx={{ textAlign: "center" }}
                          width={{ md: "60%", xs: windowWidth < 375 ? "55%" : "60%" }}
                          m="auto"
                        >
                          <Box width="100%" mt="5px">
                            <FlightTrack stopPoint={dataIn?.length - 1 === 0 ? 0 : 1} singleLine={true} />
                          </Box>
                          <Typography
                            variant="body2"
                            fontSize={"13px"}
                            fontWeight={300}
                            lineHeight={"15.26px"}
                            mt="8px"
                          >
                            {singleFlightTravelTime(dataIn?.[0]?.FlightPswFlight?.segment2_elapse_flying_time)?.replace(/(\d+)\s?hr/g, '$1hr').replace(/\s?m/g, 'm')} • {" "}
                            {/* {totalFlightTimeInbound()} • {" "} */}

                            {/* {dataIn?.length - 1 === 0 ? "Direct)" : dataIn?.length - 1 + " stop)"} */}
                            {dataIn?.length - 1 === 0
                              ? "(Direct)"
                              : dataIn?.length - 1 === 1
                                ? "(1 stop)"
                                : `(${dataIn?.length - 1} stops)`}
                            <br />
                          </Typography>
                        </Box>
                        <Box textAlign="right" width={'20%'}>
                          {/* <Box textAlign='right'> */}
                          <Typography
                            variant="body1"
                            fontWeight={500}
                            lineHeight="120%"
                            sx={{ fontSize: { sm: "16px", xs: "13px" } }}
                          >
                            {dataIn?.[dataIn?.length - 1]?.FlightPswFlight?.arrival_airport}
                          </Typography>
                          {/* </Box> */}
                          <Typography
                            variant="body1"
                            fontWeight={300}
                            lineHeight="120%"
                            sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                          >
                            {/* {formatDateToShortMonthDay(dataIn?.[0]?.FlightPswFlight?.arrival_date)} */}
                            {arrival_time_inbound.split(" ")[2]}{" "}
                            <span
                              style={{
                                color: isPlataniumHotel
                                  ? colors.basics.plataniumGreenColor
                                  : colors.basics.primary,
                              }}
                            >
                              {(() => {
                                const difference = calculateDateDifference(
                                  dataIn[0]?.FlightPswFlight?.departure_date,
                                  dataIn[dataIn?.length - 1]?.FlightPswFlight?.arrival_date
                                );
                                return difference !== null ? `+${difference}` : null;
                              })()}
                              {/* {dataIn?.some((flight: any) => flight?.FlightPswFlight?.dep_arr_date_diff === "1") ? "+1" : null} */}
                              {/* {isFlightArrivingNextDay ? "+1" : null} */}
                            </span>
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight={300}
                            lineHeight="120%"
                            sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                          >
                            {/* {formatDateToShortMonthDay(dataIn?.[0]?.FlightPswFlight?.arrival_date)} */}
                            {/* {arrival_time_inbound.split(" ")[2]}{" "} */}
                            {/* <span
                                style={{
                                  color: isPlataniumHotel
                                    ? colors.basics.plataniumGreenColor
                                    : colors.basics.primary,
                                }}
                              >
                                {isFlightArrivingNextDay ? "+1" : null}
                              </span> */}
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight={300}
                            lineHeight="120%"
                            sx={{
                              fontSize: { sm: "13px", xs: "13px" },
                              // wordBreak: "break-word",
                              // hyphens: "manual",
                            }}
                          >
                            {dataIn?.[dataIn?.length - 1]?.FlightPswFlight?.Airport?.arrive_airport_city}
                          </Typography>
                        </Box>
                      </Box>
                      {/* <Box justifyContent='space-between' alignItems='center' textAlign='center'>
                  <Box>
                    <Typography
                      variant='body1'
                      fontWeight={500}
                      lineHeight='130%'
                      sx={{ fontSize: { sm: '16px', xs: '14px' } }}
                    >
                      {"("}{dataIn?.length - 1 === 0 ? "Direct)" : dataIn?.length - 1 + " stop)"} <br />
                    </Typography>
                  </Box>
                </Box> */}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderTop: {
                        md: "0.5px solid #E0E0E0",
                        xs: `0.5px solid ${colors.grey[10]}`,
                      },
                      borderBottom: {
                        md: "0.5px solid #E0E0E0",
                        xs: `0.5px solid ${colors.grey[10]}`,
                      },

                      justifyContent: "space-between",
                      background: "transparent",
                      "& .MuiSvgIcon-root": {
                        color: isPlataniumHotel
                          ? `${colors.basics.plataniumGreenColor} !important`
                          : `${colors.basics.primary} !important`,
                      },
                    }}
                    padding={"16px 16px"}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", marginRight: 2 }}>
                      {dataIn?.[0]?.FlightPswFlight?.Baggage ? <LuggageIcon /> : <NoLuggageIcon />}
                      <Typography variant="body2" sx={{ marginLeft: 1 }}>
                        {dataIn?.[0]?.FlightPswFlight?.Baggage.replace("K", "K").replace("pc", "p").replace("p", "p")}
                      </Typography>
                    </Box>
                    {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                        <NoLuggageIcon sx={{
                          color: isPlataniumHotel
                            ? `${colors.basics.plataniumGreenColor} !important`
                            : `${colors.basics.primary} !important`
                        }} />
                        <Typography variant="body2" sx={{ marginLeft: 1 }}>
                          1 remains
                        </Typography>
                      </Box> */}
                  </Box>
                  {/* {expand && ( */}

                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                    width="100%"
                    pt={{ lg: "16px", sm: "20px", xs: windowWidth > 376 ? "16px" : "8px" }}
                    pb={{ lg: "16px", sm: "20px", xs: windowWidth > 376 ? "16px" : "8px" }}
                    pl={{ lg: "16px", sm: "20px", xs: windowWidth > 376 ? "16px" : "8px" }}
                    pr={{ lg: "16px", sm: "20px", xs: windowWidth > 376 ? "16px" : "8px" }}
                  >
                    {dataIn?.map((item: any, index: number) => {
                      item = item?.FlightPswFlight;
                      return (
                        <Box key={index} mb={index !== dataIn[dataIn.length - 1] ? 1 : 0}>
                          {/* {index !== 0 && <Divider />} */}
                          {/* <Box
                      mt={index !== 0 ? 1 : 0}
                      sx={{
                        display: 'flex',
                        alignItems: "center",
                        marginBottom: "20px",
                        gap: "5px",
                        '& img': {
                          width: '120px', height: 'fit-content'
                        }
                      }}
                    >
                      <Image src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${item?.airline_code}.png`} alt='' />
                      <Box ml="auto" >
                        <Typography textAlign={"right"} fontSize={"12px !important"}> ({isEmpty(item?.airline_name) !== "" && item?.airline_name + ", "}{item?.airline_code}
                          {item?.flight_number})</Typography>
                      </Box>
                    </Box> */}
                          {/* {
                      index !== dataIn?.length - 1 && (
                        <Box
                          alignItems='center '
                          justifyContent='center'
                          textAlign='center'
                          p='10px'
                          m='auto'
                          width={{ sm: '70%', xs: 'fit-content' }}
                          mt={{ sm: '30px', xs: '10px' }}
                          mb={{ sm: '30px', xs: '15px' }}
                          bgcolor={"#e3e3e36e"}
                        >
                          <Typography variant='body1'>
                            {flightTime(
                              new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
                              new Date(
                                `${dataIn[index + 1]?.FlightPswFlight?.departure_date} ${formatTime(
                                  dataIn[index + 1]?.FlightPswFlight?.departure_time
                                )}`
                              ), true
                            )}{' '}
                            layover in {item?.Airport?.arrive_airport_city} ({item?.arrival_airport})
                          </Typography>
                        </Box>
                      )
                    } */}

                          <Box>
                            <Box sx={{ position: "relative" }}>
                              <Box sx={{ display: "flex", gap: "34px" }}>
                                <Box
                                  sx={{
                                    minWidth: { xs: windowWidth > 376 ? "70px" : "50px" },
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingRight: { xs: windowWidth > 376 ? "30px" : "16px" },
                                    borderRight: `1px solid ${colors.basics.black}`,
                                    "&:before": {
                                      content: "''",
                                      position: "absolute",
                                      width: "6px",
                                      height: "5px",
                                      backgroundColor: colors.basics.white,
                                      border: `1px solid ${colors.basics.black}`,
                                      right: "-5px",
                                      top: 0,
                                    },
                                    "&:after": {
                                      content: "''",
                                      position: "absolute",
                                      width: "6px",
                                      height: "5px",
                                      backgroundColor: colors.basics.white,
                                      border: `1px solid ${colors.basics.black}`,
                                      right: "-5px",
                                      bottom: "0",
                                    },
                                  }}
                                >
                                  <Box className="pickup-time">
                                    <Typography
                                      variant="body2"
                                      fontWeight={"500"}
                                      sx={{ fontSize: "16px", display: "block" }}
                                    >
                                      {" "}
                                      {formatTime(item?.departure_time)}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      fontWeight={"300"}
                                      sx={{ fontSize: "12px" }}
                                      color={colors.grey[30]} whiteSpace={'nowrap'}
                                    >
                                      {formatDateToShortMonthDay(item.departure_date)}
                                    </Typography>
                                  </Box>
                                  <Box sx={{ mt: "auto" }}>
                                    <Typography variant="body2" sx={{ fontSize: "16px", display: "block" }}>
                                      {" "}
                                      {formatTime(item?.arrival_time)}{" "}
                                      <span
                                        style={{
                                          color: isPlataniumHotel
                                            ? colors.basics.plataniumGreenColor
                                            : colors.basics.primary,
                                        }}
                                      >
                                        {/* {isFlightArrivingNextDay ? '+1' : null} */}
                                      </span>
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      fontWeight={"300"}
                                      sx={{ fontSize: "12px" }}
                                      color={colors.grey[30]} whiteSpace={'nowrap'}
                                    >
                                      {formatDateToShortMonthDay(item.arrival_date)}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box sx={{ width: "100%" }}>
                                  <Typography sx={{ fontSize: { sm: "14px", xs: "13px" }, mb: "24px", display: "block" }}>
                                    {" "}
                                    ({item?.departure_airport}) {item?.Airport?.depart_airport_city}{" "}
                                  </Typography>
                                  <Box
                                    sx={{
                                      position: "relative",
                                      border: `0.5px solid ${colors.grey[10]}`,
                                      borderRadius: "5px",
                                      mb: "44px",
                                      width: "100%",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: "20px",
                                        alignItems: "center",
                                        p: "10px 10px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          "& img": {
                                            width: "60px",
                                            // height: "fit-content",
                                          },
                                        }}
                                      >
                                        <Image src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${item?.airline_code}.png`} alt='' />
                                        <Typography textAlign={"left"} variant="body2" fontWeight={'300'} fontSize={"13px !important"}>
                                          {isEmpty(item?.airline_name) !== "" && item?.airline_name + ", "}</Typography></Box>
                                      <Box ml='10px'>
                                        <Typography variant="body2" color={colors.grey[40]} fontWeight={'400'} >{item?.airline_code}
                                          {item?.flight_number}</Typography>
                                        <Typography display={{ xs: windowWidth < 374 ? 'flex' : "none" }} variant="body2" color={colors.grey[40]} fontWeight={'400'} > {singleFlightTravelTime(item?.travel_time, true)}</Typography>

                                      </Box>
                                      <Box display={{ xs: windowWidth < 374 ? 'none' : "flex" }}>
                                        <Typography variant="body2" color={colors.grey[40]} fontWeight={'400'} > {singleFlightTravelTime(item?.travel_time, true)}</Typography>

                                      </Box></Box>
                                    <Box
                                      sx={{
                                        "& svg": {
                                          transform: "rotate(90deg)",
                                          fill: isPlataniumHotel
                                            ? `${colors.basics.plataniumGreenColor} !important`
                                            : `${colors.basics.primary} !important`,
                                        },
                                        position: "absolute",
                                        left: { md: "-13%", sm: "-7%", xs: windowWidth > 374 ? "-17%" : "-23%" },
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        background: colors.basics.white,
                                        p: "3px 0 0 0",
                                      }}
                                    >
                                      <AirplanemodeActiveIcon />
                                    </Box>
                                  </Box>
                                  <Typography sx={{ fontSize: { sm: "14px", xs: "13px" }, mb: "5px", display: "block" }}>
                                    ({item?.arrival_airport}) {item?.Airport?.arrive_airport_city}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          {
                            index !== dataIn?.length - 1 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                  position: "relative",
                                  borderLeft: "1px dashed black",
                                  padding: "15px 0 15px 34px",
                                  margin: "8px 0",
                                  marginLeft: { sm: "69px", xs: windowWidth > 376 ? "69px" : "49px" },
                                  // "&:before": {
                                  //   content: "''",
                                  //   position: "absolute",
                                  //   width: "1px",
                                  //   height: "100%",
                                  //   top: 0,
                                  //   left: 0,
                                  // }
                                }}
                              >
                                {/* <Typography variant="body2">(JFK)</Typography> */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "10px",
                                    border: `0.5px solid ${colors.grey[10]}`,
                                    borderRadius: "5px",
                                    alignItems: "center",
                                    padding: "10px 10px",
                                    "& svg": {
                                      "& path": {
                                        fill: isPlataniumHotel
                                          ? colors.basics.plataniumGreenColor + "!important"
                                          : colors.basics.primary,
                                      },
                                    },
                                  }}
                                >
                                  <TimeDuration />
                                  {/* <img
                              width={"auto"}
                              height={20}
                              src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/QR.png`}
                              alt=""
                            /> */}
                                  <Typography variant="body2" fontWeight={"300"} fontSize={"13px"} sx={{}}>
                                    Stop Duration :{" "}
                                    {flightTime(
                                      new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
                                      new Date(
                                        `${dataIn[index + 1]?.FlightPswFlight?.departure_date} ${formatTime(
                                          dataIn[index + 1]?.FlightPswFlight?.departure_time
                                        )}`
                                      ),
                                      true
                                    )}{" "}
                                    {/* layover in {item?.Airport?.arrive_airport_city} ({item?.arrival_airport}) */}
                                  </Typography>
                                </Box>
                              </Box>
                            )
                          }
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* </Box> */}
        </Drawer >
      </Box >
    </>
  );
};
