import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Image from "../../../components/Image";
import { colors } from "../../../styles/colors";
import { FlightTrack } from "../../../components/FlightTrack";
import { ReactComponent as RightChevron } from "../../../assets/icons/RightChervon.svg";
import LuggageIcon from "@mui/icons-material/Luggage";
import NoLuggageIcon from "@mui/icons-material/NoLuggage";
import FlightIcon from "@mui/icons-material/Flight";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { formatTime } from "../../../helpers/formatTime";
import { flightTime, getFlightTimesObject, singleFlightTravelTime, sumTime } from "../../../helpers/TotalFlightTime";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { TabType } from "./ChangeFlightDrawer";
import { useAppSelector } from "../../../hooks/useAppReducer";
import { ReactComponent as CloseModal } from '../../../assets/icons/CloseModal.svg';
import useWindowWidth from "../../../hooks/useWindowWidth";
import { isEmpty } from "../../../helpers/isEmpty";
import FlightTrackColumnWise from "../../../components/FlightTrackColumnWise";
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { ReactComponent as KeyboardDoubleArrowRightIcon } from './../../../assets/icons/DoubleArrow.svg';
import { ReactComponent as TimeDuration } from './../../../assets/icons/TimeDuration.svg';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import { calculateDateDifference } from "../../../helpers/calculateDateDifference";

interface viewFlightDetailsProps {
  data?: any,
  selected: any,
  dataIn: any,
  isPlataniumHotel: any,
  currentTab: any,
  departureLocation: any,
  ArrivalLocation: any,
  openFlightDrawer: any,
  setOpenFlightDrawer: any,
  expand: any,
  isEqual: any,
  departure_time: any,
  arrival_time: any,
  type: any,
  isFlightArrivingNextDay: any,
  current: any,
  setInnerAccordionExpanded: any,
}

function mapWithStopTime(dataArray: any) {
  const result: any = [];

  dataArray.forEach((flight: any, index: any) => {
    result.push(flight);

    if (index < dataArray.length - 1) {
      const nextFlight = dataArray[index + 1].FlightPswFlight;
      const currentArrivalTime = flight.FlightPswFlight.arrival_time;
      const nextDepartureTime = nextFlight.departure_time;

      const arrivalMinutes = currentArrivalTime;
      const departureMinutes = nextDepartureTime;
      let stopTimeMinutes = Math.abs(departureMinutes - arrivalMinutes);

      result.push({
        stop_time: stopTimeMinutes.toString().padStart(4, "0"),
      });
    }
  });

  return result;
}

const ViewFlightDetailDrawer = ({
  data,
  selected,
  dataIn,
  isPlataniumHotel,
  currentTab,
  departureLocation,
  ArrivalLocation,
  openFlightDrawer,
  setOpenFlightDrawer,
  expand,
  isEqual,
  departure_time,
  arrival_time,
  type,
  isFlightArrivingNextDay,
  current,
  setInnerAccordionExpanded,

}: viewFlightDetailsProps) => {
  const { hotelDetails } = useAppSelector((state) => state.hotelDetails);
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  // console.log('data :', data);
  // console.log('dataIn :', dataIn);
  // const [flightStallData] = useState(mapWithStopTime(data));
  // console.log('flightStallData :', flightStallData);
  // const updatedFlightData = mapWithStopTime(data);
  const windowWidth = useWindowWidth();
  const totalFlightTime = useCallback(() => {
    let time: string[] = [];
    if (data?.length) {
      time = getFlightTimesObject(data);
    }

    const parts = sumTime(time).split(' ').filter(Boolean);
    const formattedLayoverTime = parts.length > 0 ? parts[0] === "0hr" ? parts[1] : `${parts[0] + " "}${parts[1]}` : "";
    return formattedLayoverTime;
  }, [data]);

  function formatDateToShortMonthDay(dateString: any) {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Format the date to "MMM DD" format
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'short', // Abbreviated month
      day: 'numeric', // Day of the month
    });

    return formattedDate;
  }

  const firstInItem = selected?.in[0];


  // Retrieve the psw_result_id and corresponding flight data from the first item
  const pswResultId = Object.keys(firstInItem)[0];
  const flightDataIn = firstInItem[pswResultId];

  const arrival_timeIn =
    dayjs(flightDataIn[flightDataIn?.length - 1]?.FlightPswFlight?.arrival_date).format(
      "MMM DD"
    ) +
    " " +
    formatTime(flightDataIn[flightDataIn?.length - 1]?.FlightPswFlight?.arrival_time);


  const departure_timeIn = useMemo(() => {
    return `${dayjs(flightDataIn[0]?.FlightPswFlight?.departure_date).format(
      "MMM DD"
    )} ${formatTime(flightDataIn[0]?.FlightPswFlight?.departure_time)}`;
  }, [flightDataIn]);

  const totalFlightTimeIn = useCallback(() => {
    let time: string[] = [];
    if (flightDataIn?.length) {
      time = getFlightTimesObject(flightDataIn);
    }

    const parts = sumTime(time).split(" ").filter(Boolean);
    const formattedLayoverTime =
      parts.length > 0
        ? parts[0] === "0hr"
          ? parts[1]
          : `${parts[0] + " "}${parts[1]}`
        : "";
    return formattedLayoverTime;
  }, [flightDataIn]);

  return (
    <Box
      sx={{
        "& .title-style": {
          fontWeight: 500,
          lineHeight: "130%",
          color: colors.grey[30],
          mb: "2px",
        },
        "& ::selection": {
          background: isPlataniumHotel
            ? colors.basics.plataniumGreenColor
            : colors.basics.primary,
        },
      }}
    >
      <Box sx={{
        backgroundColor: colors.grey[50], display: "flex", justifyContent: "space-between", alignItems: "center",
        '& .MuiButton-root svg': {
          width: "25.46px",
          height: "18px",
          p: "0"
        },
        "& .MuiButton-root": {
          px: {
            sm:
              "10px", xs: '6px'
          }, minWidth: '0',
          background: "white"
        },
        p: { sm: "15px 16px", xs: "15px 10px" },
        // p: "15px 16px"
      }}>

        <Button disableRipple
          variant='text' onClick={() => {

            setOpenFlightDrawer(false);
            // setInnerAccordionExpanded(Number(expand ? -1 : current))
          }}
        > <CloseModal /> </Button>
        <Box >Your flight details</Box>
        <Box></Box>
      </Box>
      <Box

        m={{ md: "20px 0px 0px 0px", sm: 0, xs: "15px 10px 20px 10px" }}
        sx={{ padding: { md: "10px 50px", sm: "20px" } }}
        display={"block"}
        alignItems={"center"}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h1"
            fontSize={{ sm: "20px", xs: "14px" }}
            sx={{ textAlign: { lg: "left", xs: "center" } }}
          >
            Outbound {holiday.flight.outbound[0].outbound_date?.slice(0, -6) || ""}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& svg path": {
                fill: colors.grey[10],
              },
            }}
          >
            <Box>
              <Typography lineHeight={1.2} variant="h3" fontSize="20px">
                {currentTab === TabType.Outbound && departureLocation}
                {currentTab === TabType.Inbound &&
                  (ArrivalLocation ?? hotelDetails?.HotelInfo?.Destination?.name)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", mx: "10px" }}>
              <RightChevron width={10} height={14} />
            </Box>
            <Box>
              <Typography lineHeight={1.2} variant="h3" fontSize="20px">
                {currentTab === TabType.Outbound &&
                  (ArrivalLocation ?? hotelDetails?.HotelInfo?.Destination?.name)}
                {currentTab === TabType.Inbound && departureLocation}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            border: "2px solid #E0E0E0",
            borderRadius: "8px",
            marginBottom: "10px",
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <Box width='100%' borderRadius="8px" display={'block'} justifyContent='space-between' sx={{
              background: colors.basics.white
            }}>
              <Box
                pt={{ lg: '16px', sm: '20px', xs: '16px' }}
                pb={{ lg: '16px', sm: '20px', xs: '16px' }}
                pl={{ lg: '16px', sm: '20px', xs: '16px' }}
                pr={{ lg: '16px', sm: '20px', xs: '16px' }}
                sx={{
                  // backgroundColor: "pink",
                  borderBottom: `0.5px solid ${colors.grey[30]}`,
                  width: "100%",
                  display: "flex",
                  justifyContent: 'space-between',
                  flexDirection: "row",
                  marginBottom: "0",
                  flex: 1,
                  gap: "10px",
                  alignItems: "center",
                  "& img": {
                    width: "60px",
                    // height: "fit-content",
                  },
                }}>
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <Image
                      src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${selected?.out[0]?.FlightPswFlight?.airline_code}.png`}
                      alt=""
                    /></Box>
                  <Box sx={{ display: "flex", ml: '20px' }}>
                    <Typography variant="body2" fontSize={'13px'}>{selected?.out[0]?.FlightPswFlight?.airline_name}</Typography>

                  </Box>
                </Box>
                <Box>
                  {/* <Typography variant="body2" fontSize={'13px'} sx={{ display: { md: "flex", xs: "none" } }}>{selected?.out[0]?.FlightPswFlight?.airline_name}</Typography> */}
                  {/* <Typography variant="body2" fontSize={'13px'} color={colors.grey[30]} fontWeight={'400'} sx={{ display: { md: "flex", xs: "none" } }}>
                    {selected?.out[0]?.FlightPswFlight?.airline_code}
                    {selected?.out[0]?.FlightPswFlight?.flight_number}
                  </Typography> */}
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography textAlign={'right'} variant="body2" fontSize={'13px'} color={colors.grey[40]} fontWeight={'400'} >
                    {selected?.out[0]?.FlightPswFlight?.class_name}{", "}
                    {selected?.out[0]?.FlightPswFlight?.airline_code}
                    {selected?.out[0]?.FlightPswFlight?.flight_number}
                  </Typography>
                </Box>
              </Box>
              <Box pt={{ lg: '16px', sm: '20px', xs: '16px' }}
                pb={{ lg: '16px', sm: '20px', xs: '16px' }}
                pl={{ lg: '16px', sm: '20px', xs: '16px' }}
                pr={{ lg: '16px', sm: '20px', xs: '16px' }} sx={{ width: "100%" }}>
                <Box display='flex' justifyContent='space-between' alignItems='center' sx={{
                  "& img": {
                    width: "60px",
                    // height: "fit-content",
                  },
                  background: "red"
                }}>

                </Box>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  m='auto'
                  sx={{
                    '& .MuiTypography-root': {
                      fontWeight: 500,
                      lineHeight: '130%',
                      // mt: { sm: '10px', xs: "0" },
                    },
                  }}
                >
                  <Box>
                    {/* <Box sx={{ textAlign: 'left' }}> */}
                    <Typography
                      variant='body1'
                      fontWeight={500}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                    >

                      {`${selected?.out[0]?.FlightPswFlight?.departure_airport}`}
                    </Typography>
                    {/* </Box> */}
                    <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' } }}>
                      {departure_time.split(' ')[2]}
                    </Typography>
                    {/* <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' } }}>
                      {formatDateToShortMonthDay(selected?.out[0]?.FlightPswFlight?.departure_date)}
                    </Typography> */}
                    <Typography
                      variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' } }}
                    >
                      {selected?.out[0]?.FlightPswFlight?.Airport?.depart_airport_city}
                    </Typography>
                  </Box>

                  <Box sx={{ textAlign: "center" }} width={{ md: '60%', xs: windowWidth < 375 ? '55%' : '70%' }} m='auto'>
                    <Box width="100%" mt="5px">
                      <FlightTrack stopPoint={selected?.out?.length - 1 === 0 ? 0 : 1} singleLine={true} />
                    </Box>
                    <Typography variant="body2" fontSize={'13px'} fontWeight={300} lineHeight={'15.26px'} mt='8px'>
                      {singleFlightTravelTime(selected?.out?.[0]?.FlightPswFlight?.segment1_elapse_flying_time)?.replace(/(\d+)\s?hr/g, '$1hr').replace(/\s?m/g, 'm')} • {" "}
                      {/* {totalFlightTime()}  •{" "} */}
                      {/* {"("}{selected?.out?.length - 1 === 0 ? "Direct)" : selected?.out?.length - 1 + " stop)"} */}
                      {selected?.out?.length - 1 === 0
                        ? "(Direct)"
                        : selected?.out?.length - 1 === 1
                          ? "(1 stop)"
                          : `(${selected?.out?.length - 1} stops)`}
                      <br />
                    </Typography>
                  </Box>
                  <Box textAlign='right' >
                    {/* <Box textAlign='right'> */}
                    <Typography
                      variant='body1'
                      fontWeight={500}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                    >

                      {selected?.out[selected?.out?.length - 1]?.FlightPswFlight?.arrival_airport}
                    </Typography>
                    {/* </Box> */}
                    <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' } }}>
                      {arrival_time.split(' ')[2]}{' '}
                      <span style={{ color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary }}>
                        {/* {isFlightArrivingNextDay ? '+1' : null} */}
                        {(() => {
                          const difference = calculateDateDifference(
                            selected?.out[0]?.FlightPswFlight?.departure_date,
                            selected?.out[selected?.out?.length - 1]?.FlightPswFlight?.arrival_date
                          );
                          return difference !== null ? `+${difference}` : null;
                        })()}
                      </span>
                    </Typography>
                    {/* <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' } }}>
                      {formatDateToShortMonthDay(selected?.out[selected?.out?.length - 1]?.FlightPswFlight?.arrival_date)}
                      <span style={{ color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary }}>
                        {isFlightArrivingNextDay ? '+1' : null}
                      </span>
                    </Typography> */}
                    <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' }, wordBreak: "break-word", hyphens: "manual" }}
                    >
                      {selected?.out[selected?.out?.length - 1]?.FlightPswFlight?.Airport?.arrive_airport_city}
                    </Typography>
                  </Box>
                </Box>
                {/* <Box justifyContent='space-between' alignItems='center' textAlign='center'>
                  <Box>
                    <Typography
                      variant='body1'
                      fontWeight={500}
                      lineHeight='130%'
                      sx={{ fontSize: { sm: '16px', xs: '14px' } }}
                    >
                      {"("}{selected?.out?.length - 1 === 0 ? "Direct)" : selected?.out?.length - 1 + " stop)"} <br />
                    </Typography>
                  </Box>
                </Box> */}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderTop: {
                  md: "0.5px solid #E0E0E0", xs: `0.5px solid ${colors.grey[10]}`
                },
                borderBottom: {
                  md: "0.5px solid #E0E0E0", xs: `0.5px solid ${colors.grey[10]}`
                },

                justifyContent: "space-between",
                background: "transparent",
                '& .MuiSvgIcon-root': {
                  color: isPlataniumHotel
                    ? `${colors.basics.plataniumGreenColor} !important`
                    : `${colors.basics.primary} !important`
                }

              }}

              padding={'16px 16px'}
            >
              <Box sx={{
                display: "flex", alignItems: "center", marginRight: 2,

              }} >
                {
                  selected?.out[0]?.FlightPswFlight?.Baggage ? <LuggageIcon /> : <NoLuggageIcon />
                }
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {selected?.out[0]?.FlightPswFlight?.Baggage.replace("K", "K").replace("pc", "p").replace("p", "p")}
                </Typography>
              </Box>
              {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                <NoLuggageIcon />
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  1 remains
                </Typography>
              </Box> */}
            </Box>
            {/* {expand && ( */}

            <Box display={'flex'} flexDirection={"column"} gap={1} width='100%' pt={{ lg: '16px', sm: '20px', xs: windowWidth > 376 ? '16px' : "8px" }}
              pb={{ lg: '16px', sm: '20px', xs: windowWidth > 376 ? '16px' : "8px" }}
              pl={{ lg: '16px', sm: '20px', xs: windowWidth > 376 ? '16px' : "8px" }}
              pr={{ lg: '16px', sm: '20px', xs: windowWidth > 376 ? '16px' : "8px" }}>
              {selected?.out?.map((item: any, index: number) => {
                item = item?.FlightPswFlight;
                return <Box key={index} mb={index !== selected?.out[selected?.out.length - 1] ? 1 : 0}>
                  {/* {index !== 0 && <Divider />} */}
                  {/* <Box
                      mt={index !== 0 ? 1 : 0}
                      sx={{
                        display: 'flex',
                        alignItems: "center",
                        marginBottom: "20px",
                        gap: "5px",
                        '& img': {
                          width: '120px', height: 'fit-content'
                        }
                      }}
                    >
                      <Image src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${item?.airline_code}.png`} alt='' />
                      <Box ml="auto" >
                        <Typography textAlign={"right"} fontSize={"12px !important"}> ({isEmpty(item?.airline_name) !== "" && item?.airline_name + ", "}{item?.airline_code}
                          {item?.flight_number})</Typography>
                      </Box>
                    </Box> */}
                  {/* {
                      index !== selected?.out?.length - 1 && (
                        <Box
                          alignItems='center '
                          justifyContent='center'
                          textAlign='center'
                          p='10px'
                          m='auto'
                          width={{ sm: '70%', xs: 'fit-content' }}
                          mt={{ sm: '30px', xs: '10px' }}
                          mb={{ sm: '30px', xs: '15px' }}
                          bgcolor={"#e3e3e36e"}
                        >
                          <Typography variant='body1'>
                            {flightTime(
                              new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
                              new Date(
                                `${selected?.out[index + 1]?.FlightPswFlight?.departure_date} ${formatTime(
                                  selected?.out[index + 1]?.FlightPswFlight?.departure_time
                                )}`
                              ), true
                            )}{' '}
                            layover in {item?.Airport?.arrive_airport_city} ({item?.arrival_airport})
                          </Typography>
                        </Box>
                      )
                    } */}

                  <Box >
                    <Box sx={{ position: "relative" }}>
                      <Box sx={{ display: "flex", gap: '34px' }}>
                        <Box sx={{
                          minWidth: { xs: windowWidth > 376 ? "70px" : "50px" },
                          position: "relative", display: "flex", flexDirection: "column", paddingRight: { xs: windowWidth > 376 ? "30px" : '16px' }, borderRight: `1px solid ${colors.basics.black}`,
                          "&:before": {
                            content: "''",
                            position: "absolute",
                            width: '6px',
                            height: '5px',
                            backgroundColor: colors.basics.white,
                            border: `1px solid ${colors.basics.black}`,
                            right: '-5px',
                            top: 0,
                          },
                          "&:after": {
                            content: "''",
                            position: "absolute",
                            width: '6px',
                            height: '5px',
                            backgroundColor: colors.basics.white,
                            border: `1px solid ${colors.basics.black}`,
                            right: '-5px',
                            bottom: "0"
                          }

                        }}>
                          <Box className="pickup-time">
                            <Typography variant="body2" fontWeight={'500'} sx={{ fontSize: "16px", display: "block" }}>  {formatTime(item?.departure_time)}</Typography>
                            <Typography variant="body2" whiteSpace={'nowrap'} fontWeight={'300'} sx={{ fontSize: "12px" }} color={colors.grey[30]}>{formatDateToShortMonthDay(item.departure_date)}</Typography>
                          </Box>
                          <Box sx={{ mt: "auto" }}>
                            <Typography variant="body2" sx={{ fontSize: "16px", display: "block" }}> {formatTime(item?.arrival_time)}{' '}
                              {/* <span style={{ color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary }}>
                                {isFlightArrivingNextDay ? '+1' : null}
                              </span> */}
                            </Typography>
                            <Typography variant="body2" whiteSpace={'nowrap'} fontWeight={'300'} sx={{ fontSize: "12px" }} color={colors.grey[30]}>{formatDateToShortMonthDay(item.arrival_date)}</Typography>
                          </Box>
                        </Box>
                        <Box sx={{ width: "100%", }}>
                          <Typography sx={{ fontSize: { sm: "14px", xs: "13px" }, mb: "24px", display: "block" }}> ({item?.departure_airport})  {item?.Airport?.depart_airport_city}{' '}</Typography>
                          <Box sx={{ position: "relative", border: `0.5px solid ${colors.grey[10]}`, borderRadius: "5px", mb: "44px", width: "100%" }}>
                            <Box sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              gap: { sm: "20px", xs: "10px" },
                              alignItems: "center",
                              p: "10px 10px",
                            }}>
                              <Box sx={{
                                "& img": {
                                  width: "60px",
                                  // height: "fit-content",
                                },
                              }}>
                                <Image src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${item?.airline_code}.png`} alt='' />
                                <Typography textAlign={"right"} variant="body2" fontWeight={'300'} fontSize={"13px !important"}>
                                  {isEmpty(item?.airline_name) !== "" && item?.airline_name + ", "}</Typography></Box>
                              <Box ml='10px'>
                                <Typography variant="body2" color={colors.grey[40]} fontWeight={'400'} >{item?.airline_code}
                                  {item?.flight_number}
                                </Typography>
                                <Typography display={{ xs: windowWidth < 374 ? 'flex' : "none" }} variant="body2" color={colors.grey[30]} fontWeight={'400'} > {singleFlightTravelTime(item.travel_time, true)}</Typography>

                              </Box>
                              <Box display={{ xs: windowWidth < 374 ? 'none' : "flex" }}>
                                <Typography variant="body2" color={colors.grey[40]} fontWeight={'400'} > {singleFlightTravelTime(item.travel_time, true)}</Typography>

                              </Box></Box>
                            <Box sx={{

                              '& svg': {
                                transform: "rotate(90deg)",
                                fill: isPlataniumHotel ? `${colors.basics.plataniumGreenColor} !important` : `${colors.basics.primary} !important`
                              }, position: "absolute", left: { md: '-13%', sm: "-8%", xs: windowWidth > 374 ? "-17%" : "-23%" }, top: "50%", transform: 'translateY(-50%)', background: colors.basics.white, p: "3px 0 0 0"
                            }}><AirplanemodeActiveIcon /></Box>
                          </Box>
                          <Typography sx={{ fontSize: { sm: "14px", xs: "13px" }, mb: "5px", display: "block" }}>({item?.arrival_airport})   {item?.Airport?.arrive_airport_city}</Typography>
                        </Box>
                      </Box>
                    </Box>


                  </Box>
                  {
                    index !== selected?.out?.length - 1 && (
                      <Box sx={{
                        display: "flex", flexDirection: "column", gap: "10px", position: "relative", borderLeft: "1px dashed black", padding: "15px 0 15px 34px", margin: "8px 0",
                        marginLeft: { sm: "69px", xs: windowWidth > 376 ? "69px" : "49px" },
                        // "&:before": {
                        //   content: "''",
                        //   position: "absolute",
                        //   width: "1px",
                        //   height: "100%",
                        //   top: 0,
                        //   left: 0,
                        // }
                      }}>
                        {/* <Typography variant="body2">(JFK)</Typography> */}
                        <Box

                          sx={{
                            display: "flex",
                            gap: "10px",
                            border: `0.5px solid ${colors.grey[10]}`,
                            borderRadius: "5px",
                            alignItems: 'center',
                            padding: "10px 10px",
                            '& svg': {
                              '& path': {
                                fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + "!important" : colors.basics.primary,

                              },
                            },
                          }}
                        >
                          <TimeDuration />
                          {/* <img
                              width={"auto"}
                              height={20}
                              src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/QR.png`}
                              alt=""
                            /> */}
                          <Typography variant="body2" fontWeight={'300'} fontSize={"13px"} sx={{}}>
                            Stop Duration :{" "}
                            {flightTime(
                              new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
                              new Date(
                                `${selected?.out[index + 1]?.FlightPswFlight?.departure_date} ${formatTime(
                                  selected?.out[index + 1]?.FlightPswFlight?.departure_time
                                )}`
                              ), true
                            )}{' '}
                            {/* layover in {item?.Airport?.arrive_airport_city} ({item?.arrival_airport}) */}
                          </Typography>
                        </Box>
                      </Box>)
                  }
                </Box>

              })}

            </Box>

          </Box>
        </Box>
      </Box>




      <Box

        m={{ sm: "0px 0px 0px 0px", xs: "15px 10px 20px 10px" }}
        sx={{ padding: { md: "10px 50px", sm: "20px" } }}
        display={"block"}
        alignItems={"center"}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h1"
            fontSize={{ sm: "20px", xs: "14px" }}
            sx={{ textAlign: { lg: "left", xs: "center" } }}
          >
            Inbound {holiday.flight.inbound[0].inbound_date?.slice(0, -6) || ""}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& svg path": {
                fill: colors.grey[10],
              },
            }}
          >
            <Box>
              <Typography lineHeight={1.2} variant="h3" fontSize="20px">
                {currentTab === TabType.Outbound &&
                  (ArrivalLocation ?? hotelDetails?.HotelInfo?.Destination?.name)}
                {currentTab === TabType.Inbound && departureLocation}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", mx: "10px" }}>
              <RightChevron width={10} height={14} />
            </Box>
            <Box>
              <Typography lineHeight={1.2} variant="h3" fontSize="20px">

                {currentTab === TabType.Outbound && departureLocation}
                {currentTab === TabType.Inbound &&
                  (ArrivalLocation ?? hotelDetails?.HotelInfo?.Destination?.name)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            border: "2px solid #E0E0E0",
            borderRadius: "8px",
            marginBottom: "10px",
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <Box width='100%' display={'block'} borderRadius="8px" justifyContent='space-between' sx={{
              background: colors.basics.white
            }}>
              <Box
                pt={{ lg: '16px', sm: '20px', xs: '16px' }}
                pb={{ lg: '16px', sm: '20px', xs: '16px' }}
                pl={{ lg: '16px', sm: '20px', xs: '16px' }}
                pr={{ lg: '16px', sm: '20px', xs: '16px' }}
                sx={{
                  // backgroundColor: "pink",
                  borderBottom: `0.5px solid ${colors.grey[30]}`,
                  width: "100%",
                  display: "flex",
                  justifyContent: 'space-between',
                  flexDirection: "row",
                  marginBottom: "0",
                  flex: 1,
                  gap: "10px",
                  alignItems: "center",
                  "& img": {
                    width: "60px",
                    // height: "fit-content",
                  },
                }}>
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <Image
                      src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${flightDataIn[0]?.FlightPswFlight?.airline_code}.png`}
                      alt=""
                    /></Box>
                  <Box sx={{ display: "flex", ml: '20px' }}>
                    <Typography variant="body2" fontSize={'13px'}>{flightDataIn[0]?.FlightPswFlight?.airline_name}</Typography>

                  </Box>
                </Box>
                <Box>
                  {/* <Typography variant="body2" fontSize={'13px'} sx={{ display: { md: "flex", xs: "none" } }}>{flightDataIn[0]?.FlightPswFlight?.airline_name}</Typography> */}
                  {/* <Typography variant="body2" fontSize={'13px'} color={colors.grey[30]} fontWeight={'400'} sx={{ display: { md: "flex", xs: "none" } }}>
            {flightDataIn[0]?.FlightPswFlight?.airline_code}
            {flightDataIn[0]?.FlightPswFlight?.flight_number}
          </Typography> */}
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography textAlign={'right'} variant="body2" fontSize={'13px'} color={colors.grey[40]} fontWeight={'400'} >
                    {flightDataIn[0]?.FlightPswFlight?.class_name}{", "}
                    {flightDataIn[0]?.FlightPswFlight?.airline_code}
                    {flightDataIn[0]?.FlightPswFlight?.flight_number}
                  </Typography>
                </Box>
              </Box>
              <Box pt={{ lg: '16px', sm: '20px', xs: '16px' }}
                pb={{ lg: '16px', sm: '20px', xs: '16px' }}
                pl={{ lg: '16px', sm: '20px', xs: '16px' }}
                pr={{ lg: '16px', sm: '20px', xs: '16px' }} sx={{ width: "100%" }}>
                <Box display='flex' justifyContent='space-between' alignItems='center' sx={{
                  "& img": {
                    width: "60px",
                    // height: "fit-content",
                  },
                  background: "red"
                }}>

                </Box>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  m='auto'
                  sx={{
                    '& .MuiTypography-root': {
                      fontWeight: 500,
                      lineHeight: '130%',
                      // mt: { sm: '10px', xs: "0" },
                    },
                  }}
                >
                  <Box>
                    {/* <Box sx={{ textAlign: 'left' }}> */}
                    <Typography
                      variant='body1'
                      fontWeight={500}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                    >

                      {`${flightDataIn[0]?.FlightPswFlight?.departure_airport}`}
                    </Typography>
                    {/* </Box> */}
                    <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' } }}>
                      {departure_timeIn.split(' ')[2]}
                    </Typography>
                    {/* <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' } }}>
                      {formatDateToShortMonthDay(flightDataIn[0]?.FlightPswFlight?.departure_date)}
                    </Typography> */}
                    <Typography
                      variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' } }}
                    >
                      {flightDataIn[0]?.FlightPswFlight?.Airport?.depart_airport_city}
                    </Typography>
                  </Box>

                  <Box sx={{ textAlign: "center" }} width={{ md: '60%', xs: windowWidth < 375 ? '55%' : '70%' }} m='auto'>
                    <Box width="100%" mt="5px">
                      <FlightTrack stopPoint={flightDataIn?.length - 1 === 0 ? 0 : 1} singleLine={true} />
                    </Box>
                    <Typography variant="body2" fontSize={'13px'} fontWeight={300} lineHeight={'15.26px'} mt='8px'>
                      {/* {totalFlightTimeIn()}  •{" "} */}
                      {singleFlightTravelTime(flightDataIn?.[0]?.FlightPswFlight?.segment2_elapse_flying_time)?.replace(/(\d+)\s?hr/g, '$1hr').replace(/\s?m/g, 'm')} • {" "}

                      {/* {"("}{flightDataIn?.length - 1 === 0 ? "Direct)" : flightDataIn?.length - 1 + " stop)"} */}
                      {flightDataIn?.length - 1 === 0
                        ? "(Direct)"
                        : flightDataIn?.length - 1 === 1
                          ? "(1 stop)"
                          : `(${flightDataIn?.length - 1} stops)`}
                      <br />
                    </Typography>
                  </Box>
                  <Box textAlign='right' >
                    {/* <Box textAlign='right'> */}
                    <Typography
                      variant='body1'
                      fontWeight={500}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                    >

                      {flightDataIn[flightDataIn?.length - 1]?.FlightPswFlight?.arrival_airport}
                    </Typography>
                    {/* </Box> */}
                    <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' } }}>
                      {arrival_timeIn.split(' ')[2]}{' '}
                      <span style={{ color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary }}>
                        {(() => {
                          const difference = calculateDateDifference(
                            flightDataIn[0]?.FlightPswFlight?.departure_date,
                            flightDataIn[flightDataIn?.length - 1]?.FlightPswFlight?.arrival_date
                          );
                          return difference !== null ? `+${difference}` : null;
                        })()}
                        {/* {flightDataIn?.some((flight: any) => flight?.FlightPswFlight?.dep_arr_date_diff === "1") ? "+1" : null} */}
                        {/* {isFlightArrivingNextDay ? '+1' : null} */}
                      </span>
                    </Typography>
                    {/* <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '14px' } }}>
                      {formatDateToShortMonthDay(flightDataIn[flightDataIn?.length - 1]?.FlightPswFlight?.arrival_date)}
                      <span style={{ color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary }}>
                        {isFlightArrivingNextDay ? '+1' : null}
                      </span>
                    </Typography> */}
                    <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' }, wordBreak: "break-word", hyphens: "manual" }}
                    >
                      {flightDataIn[flightDataIn?.length - 1]?.FlightPswFlight?.Airport?.arrive_airport_city}
                    </Typography>
                  </Box>
                </Box>
                {/* <Box justifyContent='space-between' alignItems='center' textAlign='center'>
          <Box>
            <Typography
              variant='body1'
              fontWeight={500}
              lineHeight='130%'
              sx={{ fontSize: { sm: '16px', xs: '14px' } }}
            >
              {"("}{flightDataIn?.length - 1 === 0 ? "Direct)" : flightDataIn?.length - 1 + " stop)"} <br />
            </Typography>
          </Box>
        </Box> */}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderTop: {
                  md: "0.5px solid #E0E0E0", xs: `0.5px solid ${colors.grey[10]}`
                },
                borderBottom: {
                  md: "0.5px solid #E0E0E0", xs: `0.5px solid ${colors.grey[10]}`
                },

                justifyContent: "space-between",
                background: "transparent",
                '& .MuiSvgIcon-root': {
                  color: isPlataniumHotel
                    ? `${colors.basics.plataniumGreenColor} !important`
                    : `${colors.basics.primary} !important`
                }

              }}

              padding={'16px 16px'}
            >
              <Box sx={{
                display: "flex", alignItems: "center", marginRight: 2,

              }} >
                {
                  flightDataIn[0]?.FlightPswFlight?.Baggage ? <LuggageIcon /> : <NoLuggageIcon />
                }
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {flightDataIn[0]?.FlightPswFlight?.Baggage.replace("K", "K").replace("pc", "p").replace("p", "p")}
                </Typography>
              </Box>

            </Box>
            {/* {expand && ( */}

            <Box display={'flex'} flexDirection={"column"} gap={1} width='100%' pt={{ lg: '16px', sm: '20px', xs: windowWidth > 376 ? '16px' : "8px" }}
              pb={{ lg: '16px', sm: '20px', xs: windowWidth > 376 ? '16px' : "8px" }}
              pl={{ lg: '16px', sm: '20px', xs: windowWidth > 376 ? '16px' : "8px" }}
              pr={{ lg: '16px', sm: '20px', xs: windowWidth > 376 ? '16px' : "8px" }}>
              {flightDataIn?.map((item: any, index: number) => {
                item = item?.FlightPswFlight;
                return <Box key={index} mb={index !== flightDataIn[flightDataIn.length - 1] ? 1 : 0}>


                  <Box >
                    <Box sx={{ position: "relative" }}>
                      <Box sx={{ display: "flex", gap: '34px' }}>
                        <Box sx={{
                          minWidth: { xs: windowWidth > 376 ? "70px" : "50px" },
                          position: "relative", display: "flex", flexDirection: "column", paddingRight: { xs: windowWidth > 376 ? "30px" : '16px' }, borderRight: `1px solid ${colors.basics.black}`,
                          "&:before": {
                            content: "''",
                            position: "absolute",
                            width: '6px',
                            height: '5px',
                            backgroundColor: colors.basics.white,
                            border: `1px solid ${colors.basics.black}`,
                            right: '-5px',
                            top: 0,
                          },
                          "&:after": {
                            content: "''",
                            position: "absolute",
                            width: '6px',
                            height: '5px',
                            backgroundColor: colors.basics.white,
                            border: `1px solid ${colors.basics.black}`,
                            right: '-5px',
                            bottom: "0"
                          }

                        }}>
                          <Box className="pickup-time">
                            <Typography variant="body2" fontWeight={'500'} sx={{ fontSize: "16px", display: "block" }}>  {formatTime(item?.departure_time)}</Typography>
                            <Typography variant="body2" whiteSpace={'nowrap'} fontWeight={'300'} sx={{ fontSize: "12px" }} color={colors.grey[30]}>{formatDateToShortMonthDay(item?.departure_date)}</Typography>
                          </Box>
                          <Box sx={{ mt: "auto" }}>
                            <Typography variant="body2" sx={{ fontSize: "16px", display: "block" }}> {formatTime(item?.arrival_time)}{' '}
                              <span style={{ color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary }}>
                                {/* {isFlightArrivingNextDay ? '+1' : null} */}
                              </span></Typography>
                            <Typography variant="body2" whiteSpace={'nowrap'} fontWeight={'300'} sx={{ fontSize: "12px" }} color={colors.grey[30]}>{formatDateToShortMonthDay(item?.arrival_date)}</Typography>
                          </Box>
                        </Box>
                        <Box sx={{ width: "100%", }}>
                          <Typography sx={{ fontSize: { sm: "14px", xs: "13px" }, mb: "24px", display: "block" }}> ({item?.departure_airport})  {item?.Airport?.depart_airport_city}{' '}</Typography>
                          <Box sx={{ position: "relative", border: `0.5px solid ${colors.grey[10]}`, borderRadius: "5px", mb: "44px", width: "100%" }}>
                            <Box sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              gap: "20px",
                              alignItems: "center",
                              p: "10px 10px",
                            }}>
                              <Box sx={{
                                "& img": {
                                  width: "60px",
                                  // height: "fit-content",
                                },
                              }}>
                                <Image src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${item?.airline_code}.png`} alt='' />
                                <Typography textAlign={"right"} variant="body2" fontWeight={'300'} fontSize={"13px !important"}>
                                  {isEmpty(item?.airline_name) !== "" && item?.airline_name + ", "}</Typography></Box>
                              <Box ml='10px'>
                                <Typography variant="body2" color={colors.grey[40]} fontWeight={'400'} >{item?.airline_code}
                                  {item?.flight_number}</Typography>
                                <Typography display={{ xs: windowWidth < 374 ? 'flex' : "none" }} variant="body2" color={colors.grey[30]} fontWeight={'400'} > {singleFlightTravelTime(item?.travel_time, true)}</Typography>

                              </Box>
                              <Box display={{ xs: windowWidth < 374 ? 'none' : "flex" }}>
                                <Typography variant="body2" color={colors.grey[40]} fontWeight={'400'} > {singleFlightTravelTime(item?.travel_time, true)}</Typography>

                              </Box></Box>
                            <Box sx={{

                              '& svg': {
                                transform: "rotate(90deg)",
                                fill: isPlataniumHotel ? `${colors.basics.plataniumGreenColor} !important` : `${colors.basics.primary} !important`
                              }, position: "absolute", left: { md: '-13%', sm: "-8%", xs: windowWidth > 374 ? "-17%" : "-23%" }, top: "50%", transform: 'translateY(-50%)', background: colors.basics.white, p: "3px 0 0 0"
                            }}><AirplanemodeActiveIcon /></Box>
                          </Box>
                          <Typography sx={{ fontSize: { sm: "14px", xs: "13px" }, mb: "5px", display: "block" }}>({item?.arrival_airport})   {item?.Airport?.arrive_airport_city}</Typography>
                        </Box>
                      </Box>
                    </Box>


                  </Box>
                  {
                    index !== flightDataIn?.length - 1 && (
                      <Box sx={{
                        display: "flex", flexDirection: "column", gap: "10px", position: "relative", borderLeft: "1px dashed black", padding: "15px 0 15px 34px", margin: "8px 0",
                        marginLeft: { sm: "69px", xs: windowWidth > 376 ? "69px" : "49px" },
                        // "&:before": {
                        //   content: "''",
                        //   position: "absolute",
                        //   width: "1px",
                        //   height: "100%",
                        //   top: 0,
                        //   left: 0,
                        // }
                      }}>
                        {/* <Typography variant="body2">(JFK)</Typography> */}
                        <Box

                          sx={{
                            display: "flex",
                            gap: "10px",
                            border: `0.5px solid ${colors.grey[10]}`,
                            borderRadius: "5px",
                            alignItems: 'center',
                            padding: "10px 10px",
                            '& svg': {
                              '& path': {
                                fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + "!important" : colors.basics.primary,

                              },
                            },
                          }}
                        >
                          <TimeDuration />
                          {/* <img
                      width={"auto"}
                      height={20}
                      src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/QR.png`}
                      alt=""
                    /> */}
                          <Typography variant="body2" fontWeight={'300'} fontSize={"13px"} sx={{}}>
                            Stop Duration :{" "}
                            {flightTime(
                              new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
                              new Date(
                                `${flightDataIn[index + 1]?.FlightPswFlight?.departure_date} ${formatTime(
                                  flightDataIn[index + 1]?.FlightPswFlight?.departure_time
                                )}`
                              ), true
                            )}{' '}
                            {/* layover in {item?.Airport?.arrive_airport_city} ({item?.arrival_airport}) */}
                          </Typography>
                        </Box>
                      </Box>)
                  }
                </Box>

              })}

            </Box>

          </Box>
        </Box>
      </Box>



    </Box >
  );
};

export default ViewFlightDetailDrawer;
