import { Box, Button, Grid, Typography } from "@mui/material";
import { colors } from "../../../styles/colors";
import CustomSelectInput from "../../../components/CustomSelectInput";
import { ReactComponent as TransmissionIcon } from "../../../assets/icons/TransmissionIcon.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/UserIcon.svg";
import { ReactComponent as LuggageIcon } from "../../../assets/icons/luggage.svg";
import { useEffect, useState } from "react";
import { ReactComponent as CloseModal } from "../../../assets/icons/CloseModal.svg";
import { useAppSelector } from "../../../hooks/useAppReducer";
import { CarList } from "./CarList";

interface carHireProps {
  setOpenFlightDrawer?: any;
  handleAddCarAmountToFlight?: (carElement: any, selectedCar?: any) => void;
  isPlataniumHotel?: boolean;
}

interface FilterState {
  passenger_qty: any;
  baggage_qty: any;
  transmission_type: any;
}

export const CarHireComponent = ({
  setOpenFlightDrawer,
  handleAddCarAmountToFlight,
  isPlataniumHotel,
}: carHireProps) => {
  const { availability } = useAppSelector((state) => state.carHire);
  const [carHireData, setCarHireData] = useState(availability?.newResult);
  const { flightRoom } = useAppSelector((state) => state.flightRoom);
  const [filters, setFilters] = useState({
    baggage_qty: ["1", "2", "3"],
    passenger_qty: ["1", "2", "3"],
    transmission_type: ["Automatic"],
  });
  const priceFilter = ["Low to High", "High to Low"];
  const [sortingFilter, setSortingFilter] = useState("Low to High");
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL;
  // const isModalOpen = open
  const [selectedFilter, setSelectedFilter] = useState<FilterState>({
    passenger_qty: "",
    baggage_qty: "",
    transmission_type: "",
  });

  const handleSelectPassenger = (key: any, e: any) => {
    setSelectedFilter((prev) => ({ ...prev, [key]: e.target.textContent }));
  };

  useEffect(() => {
    const uniqueValues = ["baggage_qty", "passenger_qty", "type", "transmission_type"].reduce<any>(
      (acc, key) => {
        acc[key] = availability?.newResult.reduce((set: any, item: any) => {
          if (!set.includes(item[key])) {
            set.push(item[key]);
          }
          return set;
        }, []);
        return acc;
      },
      {}
    );
    setFilters(uniqueValues);
    setCarHireData(availability?.newResult);
  }, [availability?.newResult]);

  useEffect(() => {
    const carList = availability?.newResult
      ?.filter((item: any) => {
        return Object.keys(selectedFilter).every((key) => {
          const filterKey = key as keyof FilterState;
          const filterValue = selectedFilter[filterKey];

          if (filterValue === "" || filterValue === undefined) {
            return true;
          }

          return item[filterKey] === filterValue;
        });
      })
      .sort((a: any, b: any) => {
        if (sortingFilter) {
          const priceA = parseFloat(a.base_rate) || 0;
          const priceB = parseFloat(b.base_rate) || 0;

          if (sortingFilter === "Low to High") {
            return priceA - priceB;
          } else if (sortingFilter === "High to Low") {
            return priceB - priceA;
          }
        }

        return 0;
      });

    setCarHireData(carList);
  }, [selectedFilter, sortingFilter]);

  return (
    <Box
      sx={{
        "& .title-style": {
          fontWeight: 500,
          lineHeight: "130%",
          color: colors.grey[30],
          mb: "2px",
        },
        "& ::selection": {
          background: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: colors.grey[50],
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& .MuiButton-root svg": {
            width: "25.46px",
            height: "18px",
            p: "0",
          },
          "& .MuiButton-root": {
            "&:hover": {
              background: colors.basics.white,
            },
            px: {
              sm: "10px",
              xs: "6px",
            },
            minWidth: "0",
            background: colors.basics.white,
          },
          p: { sm: "15px 16px", xs: "15px 10px" },
        }}
      >
        <Button
          disableRipple
          variant="text"
          onClick={() => {
            setOpenFlightDrawer(false);
            document.body.style.overflow = "visible";
          }}
        >
          {" "}
          <CloseModal />{" "}
        </Button>
        Car hire options
        <Box></Box>
      </Box>
      <Box
        m={{ sm: "20px 0px 30px 0px", xs: "15px 0 20px 0" }}
        sx={{ padding: { md: "10px 50px", sm: "20px" } }}
        display={"block"}
        alignItems={"center"}
        width={"100%"}
        marginLeft={"0 !important"}
      >
        <Grid
          sx={{
            padding: { lg: 0, sm: "0 8px", xs: "0 8px" },
            borderBottom: "1px solid #ccc",
          }}
          container
          spacing={1}
          columnSpacing={1}
          width={"100% !important"}
          marginLeft={"0 !important"}
        >
          <Grid
            lg={3}
            xs={6}
            sm={6}
            item
            sx={{
              "& .custom-dropdown .dropdown-toggle svg": {
                marginTop: "3px",
              },
              pl: { lg: "0 !important", sm: "8px", xs: "8px" },
              mb: "20px",
              "& svg path": {
                fill: isPlataniumHotel
                  ? colors.basics.plataniumGreenColor + "!important"
                  : colors.basics.primary,
                width: "18px",
                height: "18px",
              },
            }}
          >
            <Typography variant="body1" className="title-style">
              Price
            </Typography>
            <CustomSelectInput
              ids={"priceFilter"}
              data={priceFilter}
              menuOnClick={(e: any) => setSortingFilter(e.target.textContent)}
              multiple={false}
              value={sortingFilter}
              // isPlataniumHotel={isPlataniumHotel}
              placeholder="Select Price"
              StartIcon={<UserIcon />}
            />
          </Grid>
          <Grid
            lg={3}
            xs={6}
            sm={6}
            item
            sx={{
              "& .custom-dropdown .dropdown-toggle svg": {
                marginTop: "3px",
              },
              mb: "20px",
              "& svg path": {
                fill: isPlataniumHotel
                  ? colors.basics.plataniumGreenColor + "!important"
                  : colors.basics.primary,
                width: "18px",
                height: "18px",
              },
            }}
          >
            <Typography variant="body1" className="title-style">
              Passengers
            </Typography>
            <CustomSelectInput
              ids={"passenger"}
              data={filters?.passenger_qty}
              menuOnClick={(e: any) => handleSelectPassenger("passenger_qty", e)}
              multiple={false}
              value={selectedFilter?.passenger_qty || ""}
              // isPlataniumHotel={isPlataniumHotel}
              placeholder="Select Passenger"
              StartIcon={<UserIcon />}
            />
          </Grid>
          <Grid
            lg={3}
            xs={6}
            sm={6}
            item
            sx={{
              mb: "20px",
              "& svg path": {
                fill: isPlataniumHotel
                  ? colors.basics.plataniumGreenColor + "!important"
                  : colors.basics.primary,
                width: "20px",
                height: "22px",
              },
            }}
          >
            <Box width="100%">
              <Typography variant="body1" className="title-style">
                Luggage
              </Typography>
              <CustomSelectInput
                ids="luggage"
                data={filters?.baggage_qty}
                menuOnClick={(e: any) => handleSelectPassenger("baggage_qty", e)}
                value={selectedFilter.baggage_qty || ""}
                multiple={false}
                // isPlataniumHotel={isPlataniumHotel}
                placeholder="Select Luggage"
                StartIcon={<LuggageIcon />}
              />
            </Box>
          </Grid>
          <Grid
            lg={3}
            xs={6}
            sm={6}
            item
            sx={{
              mb: "10px",
              "& svg path": {
                fill: isPlataniumHotel
                  ? colors.basics.plataniumGreenColor + "!important"
                  : colors.basics.primary,
                width: "20px",
                height: "22px",
              },
            }}
          >
            <Box width="100%">
              <Typography variant="body1" className="title-style">
                Transmission
              </Typography>
              <CustomSelectInput
                ids="transmission"
                data={filters?.transmission_type}
                menuOnClick={(e: any) => handleSelectPassenger("transmission_type", e)}
                value={selectedFilter?.transmission_type || ""}
                multiple={false}
                // isPlataniumHotel={isPlataniumHotel}
                placeholder="Select Transmission"
                StartIcon={<TransmissionIcon />}
              />
            </Box>
            <Typography
              variant="body2"
              sx={{
                textDecoration: "underline",
                textAlign: "end",
                mt: 1,
                cursor: "pointer",
                color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
              }}
              onClick={() => {
                setSelectedFilter({
                  passenger_qty: "",
                  baggage_qty: "",
                  transmission_type: "",
                });
                setSortingFilter("Low to High");
              }}
            >
              Reset Filters
            </Typography>
          </Grid>
        </Grid>
        <Grid
          sx={{
            // padding: "0 10px",
            padding: { lg: 0, sm: "0 16px", xs: "0 16px" },
            "& button": {
              backgroundColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
              "&:hover": {
                backgroundColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
              },
            },
          }}
          container
          // spacing={2}
          // rowSpacing={2}
          columnSpacing={2}
          mt={2}
        >
          {!!carHireData?.length ? (
            carHireData?.map((car) => {
              const selected = flightRoom?.selectedCarHired?.model.toLowerCase() === car.model.toLowerCase();
              return (
                <CarList
                  car={car}
                  isDrawer={true}
                  selected={selected}
                  selectedCar={flightRoom?.selectedCarHired}
                  isPlataniumHotel={isPlataniumHotel}
                  signForPricing={signForPricing}
                  handleAddCarAmountToFlight={handleAddCarAmountToFlight}
                />
              );
            })
          ) : (
            <Typography
              variant="h1"
              sx={{ color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary }}
            >
              No data found
            </Typography>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
