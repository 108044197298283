import { useRef } from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../assets/lottieAnimation/comp.json'
import { Box, Dialog, Grid, Typography } from '@mui/material';
import { colors } from '../styles/colors';
import Atol from '../assets/images/atol.png';
import Image from './Image';
import StarIcon from '@mui/icons-material/Star';
import Feefo from "../assets/images/feefo-logo.jpg"
import TropicalAward from "../assets/images/tropical-award.jpg"
import useWindowWidth from '../hooks/useWindowWidth';
interface customContainerProps {
    textChange?: boolean
}

const LottieAnimation = ({ textChange }: customContainerProps) => {
    const animationRef = useRef(null);
    const windowWidth = useWindowWidth()
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const logos = [
        {
            image: process.env.REACT_APP_PROJECT_COUNTRY === "ie" ? `${process.env.REACT_APP_REDIRECT_URL}FrontEndGlobalAssets/dist/img/iaa.png` : Atol,
            heading: process.env.REACT_APP_PROJECT_COUNTRY === "ie" ? "IAA Protected" : "ATOL Protected",
            text: "Peace of mind before and during your holiday",
            width: windowWidth > 1023 ? "140px" : "70px",
            zoom: windowWidth > 1023 ? 0.75 : 1,
            marginLeft: process.env.REACT_APP_PROJECT_COUNTRY === "ie" ? windowWidth > 1023 ? 0 : "-55px" : windowWidth > 1023 ? 0 : "-49px"
        },
        {
            image: TropicalAward,
            heading: "We're Award Winning",
            text: "Our awards reflect our dedication to delivering incredible tailor-made holidays",
            width: windowWidth > 1023 ? "65px" : "35px",
            zoom: windowWidth > 1023 ? 0.75 : 1,
            marginLeft: windowWidth > 1023 ? '0' : "-55px" // 0
        },
        {
            image: Feefo,
            heading: "Superb",
            text: "Our awards reflect our dedication to delivering incredible tailor-made holidays",
            width: windowWidth > 1023 ? "260px" : "80px",
            zoom: windowWidth > 1023 ? 0.6 : 1,
            marginLeft: windowWidth > 1023 ? '0' : "-45px" // 0
        },
    ];

    return (
        <Dialog sx={{
            '& .MuiDialog-container': { 
                // alignItems: { xs: "unset", sm: "center" }
            },
            '& .MuiModal-backdrop': {
                backgroundColor: colors.basics.secondary
            },
            '& .MuiPaper-root': {
                border: `1px solid ${colors.grey[30]}`,
                boxShadow: "none",
                backgroundColor: colors.basics.white,
                borderRadius: "5px",
                maxWidth: 1000,
                margin: windowWidth > 370 ? "32px" : "10px",
                // overflow: "hidden",
                paddingTop: "10px !important",
                paddingBottom: { xs: "0px !important", lg: "30px !important" },
                padding: {
                    sm: "40px", xs: "20px"
                },
            }
        }} open={true} >
            <Box sx={{
                // height: "100%",
                // zoom: windowWidth > 800 ? 1 : 0.8
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    '& svg': {
                        maxHeight: { xs: "250px", sm: "322px", md: "450px" },
                        maxWidth: "600px",
                        width: "100% !important",
                        height: "100% !important",
                        justifyContent: "center",
                        display: "flex",
                        // zoom: { xs: 1.7, sm: 1 },
                        // marginTop: { xs: "-90px", sm: "0px" }
                    },
                    '& > :first-of-type': {
                        display: "flex",
                        justifyContent: "center"
                    },
                }} >
                    <Lottie
                        options={defaultOptions}
                        speed={1}
                        ref={animationRef}
                        isClickToPauseDisabled={true}
                        isStopped={false} />
                </Box>

                <Box mt={{ xs: windowWidth < 457 ? '-50px' : "-30px", sm: "-65px", md: "-98px" }}>
                    <Typography lineHeight={1.2} fontSize={{ xs: windowWidth < 410 ? "10px" : windowWidth < 456 ? "12px" : "14px", sm: '16px', md: "18px" }} color={colors.grey[30]} textAlign={"center"} variant='h1'>{textChange ? window.location.search.includes("isView") ? "Your summary is loading. Please wait for a while." : "Your payment has been successful.We are confirming your booking."
                        : "Please wait while we search for the perfect holiday for you."}</Typography>
                    <Typography mt={"5px"} lineHeight={1.2} fontSize={{ xs: windowWidth < 410 ? "10px" : windowWidth < 456 ? "12px" : "14px", sm: '16px', md: "18px" }} color={colors.grey[30]} textAlign={"center"} variant='h1'>{textChange ? window.location.search.includes("isView") ? "" : "Please do not close your tab / window until the confirmation page loads"
                        : "We are checking for current availability and pricing."}</Typography>
                </Box>
                <Box sx={{
                    marginBottom: { xs: "-15px", lg: "unset" },
                    marginTop: { xs: "-20px", lg: "unset" },
                    transform: 'scale(0.6)',
                    // zoom: 0.6,
                    "& .MuiGrid-container": {
                        marginBottom: "0px",
                    },
                }}>
                    <Grid sx={{
                        '& .outer-grid': {
                            maxWidth: "475px !important",
                        },
                        '& .MuiGrid-item': {
                            paddingTop: "20px",
                        }
                    }} justifyContent={"center"} display={"flex"} flexDirection={{ xs: "column", lg: "row" }} mt={{ xs: "0px", lg: "20px" }} mb={5} container spacing={5}>
                        {logos.map((l, i) => {
                            return (
                                <Grid key={i} className="outer-grid" textAlign={{ lg: "center", xs: "left" }} item xs={12} lg={4}>
                                    <Grid display={{ xs: "flex", lg: "block" }}
                                        sx={{
                                            '& .MuiGrid-item': {
                                                paddingTop: 0,
                                            }
                                        }} container spacing={1}>
                                        <Grid minWidth={{ xs: "auto", sm: "140px" }} height={{ xs: "30px", sm: "80px" }} textAlign={"center"} pl={0} item sm={5} xs={3} lg={12}>
                                            <Image style={{
                                                // zoom: l?.zoom ? l.zoom : 1,
                                                transform: `scale(${l?.zoom ? l.zoom : 1})`,
                                                marginTop: "6px",
                                                maxWidth: l.width,
                                                width: "100%",
                                                marginLeft: l.marginLeft
                                            }} src={l.image} />
                                        </Grid>
                                        <Grid mt={{ xs: 0, lg: 1 }} item pl={1} sm={7} xs={9} lg={12}>
                                            <Box sx={{
                                                '& svg': {
                                                    fill: "#fad000",
                                                    width: "20px",
                                                    marginTop: "-3px"
                                                }
                                            }} display={"flex"} justifyContent={{ xs: "left", lg: "center" }}>
                                                <Typography mr={1} textAlign={{ md: "center", xs: "left" }} lineHeight={1.2} variant='h1'>{l.heading}</Typography>
                                                {l.heading === "Superb" &&
                                                    [1, 2, 3, 4, 5].map((l) => {
                                                        return (
                                                            <StarIcon key={l} />
                                                        )
                                                    })
                                                }
                                            </Box>
                                            <Typography fontSize={{ xs: "12px", sm: "16px" }} mt={"4px"} variant='body2'>{l.text}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            </Box>
        </Dialog>
    )
}

export default LottieAnimation