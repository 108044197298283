// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  AccordionProps,
  Divider,
  Drawer,
  Chip,
  FormControlLabel,
  Radio,
} from "@mui/material";
// @ts-nocheck
import dayjs from "dayjs";
import { colors } from "../../../styles/colors";
import { ReactComponent as RightChevron } from "../../../assets/icons/RightChervon.svg";
import { FlightTrack } from "../../../components/FlightTrack";
import { formatTime } from "../../../helpers/formatTime";
import LuggageIcon from "@mui/icons-material/Luggage";
import NoLuggageIcon from "@mui/icons-material/NoLuggage";
import { useAppDispatch, useAppSelector } from "../../../hooks/useAppReducer";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import { ReactComponent as ArrowDown } from "../../../assets/icons/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/ArrowUp.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/icons/Check2.svg";
import {
  flightTime,
  singleFlightTravelTime,
  sumTime,
  getFlightTimesObject,
} from "../../../helpers/TotalFlightTime";
import { setFlightRoomState } from "../../../features/flightRoom/flightRoomSlice";
import { isEmpty } from "../../../helpers/isEmpty";
import Image from "../../../components/Image";
import { getSessionStorage, setSessionStorage } from "../../../helpers/storage";
import FlightTrackColumnWise from "../../../components/FlightTrackColumnWise";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { ReactComponent as KeyboardDoubleArrowRightIcon } from "./../../../assets/icons/DoubleArrow.svg";
import ViewFlightDetailDrawer from "./ViewFlightDetailDrawer";
import { setHolidayDetailsState } from "../../../features/holidaySummary/holidaySummarySlice";
import { setLoading } from "../../../features/loading/loadingSlice";
import { API_METHOD } from "../../../api/services/apiMethods";
import { API } from "../../../api/services";
import { setFilteredHolidayPackage } from "../../../features/holidayPackage/holidayPackageSlice";
import { fetchAsyncChangeFlight, setFlightOptions } from "../../../features/holidayPackage/changeFlight";
import { fetchAsyncHolidayPackageDetails } from "../../../features/holidayPackage/packageDetail";
import { calculateDateDifference } from "../../../helpers/calculateDateDifference";
// dayjs.extend(duration);

interface InnerAccordionProps {
  type: string;
  duration?: any;
  item?: object[];
  itemIn?: object[];
  current: number;
  expanded: number | any;
  targetObj?: any;
  psw_result_id: string;
  selectedInboundFlight?: any;
  selectedOutboundFlight?: any;
  minMaxSlider?: any;
  setSelectedInboundFlight?: any;
  setSelectedOutboundFlight?: any;
  setSelectedPswId: (id: any) => void;
  setCurrentTab: (tab: string) => void;
  currentTab?: string;
  departureLocation?: string;
  ArrivalLocation?: string;
  setInnerAccordionExpanded: (accordion: number) => void;
  cheapestFlightPSW?: string;
  setDisableButton: any;
  setRenderInitially: (isRender: boolean) => void;
  setisRender: (isRender: boolean) => void;
  // handleSelectFlight: (
  //   isEqual: boolean,
  //   type: string,
  //   psw_result_id: string,
  //   data: any
  // ) => void;
  setIsDirect?: any;
  // handleConfirmSelectedFlight?: any;
  setActiveIndex?: any;
  setOnChangeMultiRoom?: any;
  showPreviousComponent?: any;
  openChangeFlightDrawer?: any;
  setOpenChangeFlightDrawer?: any;
  // handleSelectFlights?: any;
  matchedFlights?: any;
  selectedPswId?: any;
  getHolidayPackageRetrieveData?: any;
  inboundSorting: any;
  setInboundSorting: any;
  saveBasket?: any;
  sortRoom?: any;
  setFirstTimeLoad: any;
  firstTimeLoad: any;
  setContiuneToBookClick: any;
  contiuneToBookClick: any;
  setSaveRooms: any;
  isRetrieveBasketApiCall?: any;
  retrieveholidayBasketRetrieve?: any;
}

type FlightDetailsState = {
  state: boolean;
  data: any | null;
};
interface FlightItem {
  inboundFlights?: any[]; // Replace `any[]` with the specific type if known
  outboundFlight?: {
    outboundFlight?: any[];
  };
  // Add other properties of `item` as needed
}

export const FlightDetailsAccordion = ({
  type,
  item,
  itemIn,
  current,
  expanded,
  targetObj,
  psw_result_id,
  setCurrentTab,
  currentTab,
  departureLocation,
  ArrivalLocation,
  setSelectedPswId,
  cheapestFlightPSW,
  minMaxSlider,
  selectedInboundFlight,
  selectedOutboundFlight,
  setSelectedInboundFlight,
  setSelectedOutboundFlight,
  setRenderInitially,
  duration,
  setInnerAccordionExpanded,
  setDisableButton,
  setisRender,
  // handleSelectFlight,
  setIsDirect,
  setActiveIndex,
  // handleConfirmSelectedFlight,
  setOnChangeMultiRoom,
  showPreviousComponent,
  openChangeFlightDrawer,
  setOpenChangeFlightDrawer,
  // handleSelectFlights,
  matchedFlights,
  selectedPswId, getHolidayPackageRetrieveData,
  saveBasket,
  sortRoom,
  isRetrieveBasketApiCall,
  retrieveholidayBasketRetrieve,
  inboundSorting,
  setInboundSorting,
  // setInboundSorting,
  setFirstTimeLoad,
  firstTimeLoad,
  contiuneToBookClick,
  setContiuneToBookClick,
  setSaveRooms,
  // setSelectedOptions, selectedOptions
}: InnerAccordionProps) => {
  // console.log(matchedFlights, 'matchedFlights');
  const [isInbound, setIsInbound] = useState(false)
  // console.log('isInbound :', isInbound);
  // console.log(inboundSorting, 'inboundSorting');
  const dispatch = useAppDispatch();
  const windowWidth = useWindowWidth();
  const { flightRoom } = useAppSelector((state) => state.flightRoom);
  const { hotelDetails } = useAppSelector((state) => state.hotelDetails);
  const expand = expanded === current;
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  const { holidayPackage } = useAppSelector((state) => state.holidayPackage);
  const { filters } = useAppSelector((state: any) => state.filters);
  // console.log(targetObj, 'targetObj');
  const [selectedInboundRadio, setSelectedInboundRadio] = useState(flightRoom.psw_result_id_combo)
  // console.log('selectedInboundRadio :', selectedInboundRadio);
  const data: any = JSON.parse(
    JSON.stringify((item as FlightItem)?.outboundFlight?.outboundFlight)
  );
  const [selected, setSelected] = useState({});
  // const data1: any = JSON.parse(JSON.stringify(item?.inboundFlights));


  const data1 = JSON.parse(
    JSON.stringify((item as FlightItem)?.inboundFlights || [])
  );
  const [showAllFlights, setShowAllFlights] = useState(false); // State to control whether to show all flights
  const [isSelected, setIsSelected] = useState(false)



  // useEffect(() => {

  // }, [])
  // const a = () => {
  //   // console.log('abcdefrg', item?.inboundFlights);

  //   // Process each item in matchedFlights using map
  //   const sortedFlightsArray = matchedFlights.map((item, index) => {
  //     if (!item?.inboundFlights) return []; // Return an empty array if inboundFlights is undefined

  //     // Filter and sort the inboundFlights
  //     const sortedFlights = item.inboundFlights
  //       .filter((flight) => flight.inboundFlight) // Keep only valid inboundFlight entries
  //       .sort((a, b) => {
  //         const shouldSort = data[0]?.FlightPswFlight?.psw_result_id === flightRoom.psw_result_id;
  //         if (!shouldSort) return 0; // Keep the original order if shouldSort is false

  //         const aMatches = a.inboundFlight[0]?.FlightPswFlight?.psw_result_id === selectedInboundRadio;
  //         const bMatches = b.inboundFlight[0]?.FlightPswFlight?.psw_result_id === selectedInboundRadio;

  //         console.log('aMatches:', aMatches, 'bMatches:', bMatches);

  //         return bMatches - aMatches; // Sort b first if bMatches is true
  //       });

  //     console.log('Sorted Flights:', sortedFlights);
  //     return sortedFlights; // Return the sorted flights for this item
  //   });

  //   // Flatten the array if needed
  //   const flattenedSortedFlights = sortedFlightsArray.flat();

  //   return flattenedSortedFlights; // Return the flattened sorted flights
  // };

  // useEffect(() => {
  //   console.log('abcd!!!!', a());

  // }, [])

  // console.log('abcd!!!!', a());






  const handleToggleFlights = () => {
    setShowAllFlights(!showAllFlights);
    // setTimeout(() => {

    //   !showAllFlights ? setInboundSorting(true) : setInboundSorting(false)
    // }, 3000);
  };

  const isCheapestFlight = cheapestFlightPSW === psw_result_id;
  // console.log('psw_result_id :', psw_result_id);
  // console.log('isCheapestFlight :', isCheapestFlight);
  // console.log('psw_result_id :', psw_result_id);
  const selectedFlight =
    type === "Outbound" ? selectedOutboundFlight : selectedInboundFlight;

  const selectedFlightInbound = flightRoom?.selectedFlights?.inbound;

  const isPrevious = getSessionStorage("previous");
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL || "";
  const isPlataniumHotel =
    Number(hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel) !== 0;
  // console.log('isPlataniumHotel :', isPlataniumHotel);

  const isEqual =
    isEmpty(selectedOutboundFlight?.length) !== "" && selectedOutboundFlight?.length
      ? selectedOutboundFlight[0]?.FlightPswFlight?.psw_result_id ===
      data[0]?.FlightPswFlight?.psw_result_id
      : false;


  // const isEqual =
  //   isEmpty(data.length) !== "" && data.length
  //     ? flightRoom.psw_result_id ===
  //     data[0]?.FlightPswFlight?.psw_result_id
  //     : false;
  // console.log('isEqual :', isEqual);



  const [showFlightDetails, setShowFlightDetails] = useState<FlightDetailsState>({ state: false, data: null });

  const arrival_time =
    dayjs(data[data?.length - 1]?.FlightPswFlight?.arrival_date).format(
      "MMM DD"
    ) +
    " " +
    formatTime(data[data?.length - 1]?.FlightPswFlight?.arrival_time);

  // const arrival_timeInbound = data1?.map((flights: any) =>
  //   flights?.inboundFlight?.length > 0
  //     ? // Access the last element in the inboundFlight array
  //     flights?.inboundFlight[flights?.inboundFlight.length - 1]
  //       ?.FlightPswFlight?.arrival_date &&
  //       flights?.inboundFlight[flights?.inboundFlight.length - 1]
  //         ?.FlightPswFlight?.arrival_time
  //       ? `${dayjs(
  //         flights?.inboundFlight[flights?.inboundFlight.length - 1]
  //           ?.FlightPswFlight?.arrival_date
  //       ).format("MMM DD")} ${formatTime(
  //         flights?.inboundFlight[flights?.inboundFlight.length - 1]
  //           ?.FlightPswFlight?.arrival_time
  //       )}`
  //       : ""
  //     : ""
  // );


  const arrival_timeInboundDemo = (flightData: any) => {
    // console.log('flightData%%%%%%% :', flightData);
    // const time = flightData?.inboundFlight?.map((flights: any) => {
    //   console.log('flights!!! :', flights);
    const time = flightData?.inboundFlight?.[flightData?.inboundFlight?.length - 1]
      ?.FlightPswFlight?.arrival_date &&
      flightData?.inboundFlight?.[flightData?.inboundFlight?.length - 1]
        ?.FlightPswFlight?.arrival_time
      ? `${dayjs(
        flightData?.inboundFlight?.[flightData?.inboundFlight?.length - 1]
          ?.FlightPswFlight?.arrival_date
      ).format("MMM DD")} ${formatTime(
        flightData?.inboundFlight?.[flightData?.inboundFlight?.length - 1]
          ?.FlightPswFlight?.arrival_time
      )}`
      : "5"
    // }
    // flights.length > 0
    // Access the last element in the inboundFlight array

    // : ""
    // );
    // console.log('time((((())))) :', time);
    return time.split(" ")[2]
  }
  // console.log("arrival_timeInbound", arrival_timeInbound);


  useEffect(() => {
    let temp = {
      in: data1?.map((flightData: any) => ({
        [flightData.psw_result_id]: flightData?.inboundFlight,
      })),
      out: data,
    };
    setSelected(temp); // If you want the first item in temp.in
  }, [item]);

  const [openFlightDrawer, setOpenFlightDrawer] = useState(false);

  const toggleFlightDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenFlightDrawer(open);
    };

  const flightPrice = useMemo(() => {
    return Math.ceil(
      Number(
        data?.[0]?.FlightPswFlight?.FlightPswResult?.holiday_diff_per_person
      )
    );
  }, [data]);

  // useEffect(() => {
  //   if (
  //     data &&
  //     !selectedOutboundFlight?.length &&
  //     type === "Outbound" &&
  //     current === 0 &&
  //     !firstTimeLoad
  //     // isPrevious === "false"
  //   ) {
  //     if (setSelectedOutboundFlight) {
  //       // console.log("OUTT DATA SETTT");

  //       setSelectedOutboundFlight(data);
  //     }
  //   }
  //   // if (
  //   //   data &&
  //   //   !selectedInboundFlight?.length &&
  //   //   type === "Inbound" &&
  //   //   current === 0 &&
  //   //   isPrevious === "false"
  //   // ) {
  //   //   if (setSelectedInboundFlight) {
  //   //     setSelectedInboundFlight(data);
  //   //   }
  //   // }
  // }, [
  //   type,
  //   data,
  //   current,
  //   // selectedInboundFlight,
  //   selectedOutboundFlight,
  //   // isPrevious,
  // ]);

  // useEffect(() => {
  //   if (isPrevious === "true") {
  //     if (type === "Outbound") {
  //       const outboundFlightsJSON = getSessionStorage("OutboundFlights");
  //       if (outboundFlightsJSON !== null) {
  //         const parsedOutboundFlights = JSON.parse(outboundFlightsJSON);
  //         setSelectedOutboundFlight(parsedOutboundFlights);
  //       }
  //     } else {
  //       const inboundFlightsJSON = getSessionStorage("InboundFlights");
  //       if (inboundFlightsJSON !== null) {
  //         const parsedInboundFlights = JSON.parse(inboundFlightsJSON);
  //         setSelectedInboundFlight(parsedInboundFlights);
  //       }
  //     }
  //   }
  // }, [
  //   isPrevious,
  //   getSessionStorage("OutboundFlights"),
  //   getSessionStorage("InboundFlights"),
  //   type,
  // ]);

  // useEffect(() => {
  //   setInnerAccordionExpanded(0);
  // }, [type]);

  // useEffect(() => {
  //   if (isCheapestFlight && type === "Outbound") {
  //     // console.log("ISSUE---16")
  //     dispatch(
  //       setFlightRoomState({
  //         ...flightRoom,
  //         flightPrice: {
  //           isCheapest: true,
  //           price: targetObj?.total_fare,
  //           tempPrice: null,
  //           cheapestFlightPrice: targetObj?.total_fare,
  //         },
  //       })
  //     );
  //   }
  // }, [targetObj, cheapestFlightPSW, type, isCheapestFlight]);

  const totalFlightTime = useCallback(() => {
    let time: string[] = [];
    if (data?.length) {
      time = getFlightTimesObject(data);
    }

    const parts = sumTime(time).split(" ").filter(Boolean);
    const formattedLayoverTime =
      parts.length > 0
        ? parts[0] === "0hr"
          ? parts[1]
          : `${parts[0] + " "}${parts[1]}`
        : "";
    return formattedLayoverTime;
  }, [data]);

  // const totalFlightTimeInbound = useCallback(() => {
  //   let time: string[] = [];
  //   const timeSegments =
  //     data1?.map((flights: any) => {
  //       const individualFlights = flights?.inboundFlight || []; // Handle each segment individually
  //       return getFlightTimesObject(individualFlights); // Get time array for each segment
  //     }) || [];
  //   // if (inboundFlights?.length) {
  //   //   time = getFlightTimesObject(inboundFlights);
  //   // }
  //   // console.log('timeSegments@@@@ :', timeSegments);
  //   const formattedLayoverTimes = timeSegments?.map((segmentTimes: any) => {
  //     // console.log('segmentTimes***** :', segmentTimes);
  //     const parts = sumTime(segmentTimes)?.split(" ")?.filter(Boolean);
  //     return parts.length > 0
  //       ? parts[0] === "0hr"
  //         ? parts[1]
  //         : `${parts[0] + " "}${parts[1]}`
  //       : "";
  //   });
  //   // console.log('formattedLayoverTime@@@@ :', formattedLayoverTimes);
  //   return formattedLayoverTimes;
  // }, [data1]);


  const totalFlightTimeInboundDemo = (flightData: any) => {
    let time: string[] = [];
    const individualFlights = flightData?.inboundFlight || [];
    const timeSegments = getFlightTimesObject(individualFlights); // Get time array for each segment
    // console.log('timeSegments :', timeSegments);

    // if (inboundFlights?.length) {
    //   time = getFlightTimesObject(inboundFlights);
    // }
    // const formattedLayoverTimes =
    // timeSegments?.map((segmentTimes: any) => {
    // console.log('segmentTimes^^^ :', segmentTimes);
    const parts = sumTime(timeSegments)?.split(" ")?.filter(Boolean);
    return parts.length > 0
      ? parts[0] === "0hr"
        ? parts[1]
        : `${parts[0] + " "}${parts[1]}`
      : "";
    // return parts
    // return parts
    // });
    // console.log('formattedLayoverTimes :', parts);
    // return parts;
  };

  // console.log(totalFlightTimeInbound(), 'totalFlightTimeInbound');


  const departure_time = useMemo(() => {
    return `${dayjs(data[0]?.FlightPswFlight?.departure_date).format(
      "MMM DD"
    )} ${formatTime(data[0]?.FlightPswFlight?.departure_time)}`;
  }, [data]);

  // const departure_timeInbound = useMemo(() => {
  //   // console.log("************");

  //   return data1?.map((flights: any) =>
  //     flights?.inboundFlight?.map((flight: any) => {
  //       const departureDate = flight.FlightPswFlight?.departure_date;
  //       // console.log('departureDate :', departureDate);
  //       const departureTime = flight.FlightPswFlight?.departure_time;
  //       // console.log('departureTime :', departureTime);
  //       return `${dayjs(departureDate).format("MMM DD")} ${formatTime(
  //         departureTime
  //       )}`;
  //     })
  //   );
  // }, [data1]);

  const departure_timeInboundDemo = (flightData: any) => {

    const formattedDates = flightData?.inboundFlight?.map((flight: any) => {
      const departureDate = flight.FlightPswFlight?.departure_date;
      // console.log('departureDate :', departureDate);
      const departureTime = flight.FlightPswFlight?.departure_time;
      // console.log('departureTime :', departureTime);
      return `${dayjs(departureDate).format("MMM DD")} ${formatTime(
        departureTime
      )}`;
    })
    // console.log('flightData---> :', flightData);
    return formattedDates[0].split(" ")[2];
  }



  // console.log('departure_timeInbound', departure_timeInbound);


  const isFlightArrivingNextDay = useMemo(() => {
    return data
      ?.map((item: any) => item.FlightPswFlight.dep_arr_date_diff)
      .includes("1");
  }, [data]);


  useEffect(() => {
    setInnerAccordionExpanded(Number(0));
  }, [type]);

  const renderFlightPrice = () => {
    if (getSessionStorage("nextAndSelectedFlights") === "false") {
      // console.log("IFF");

      return Math.ceil(
        Number(
          data?.[0]?.FlightPswFlight?.FlightPswResult?.holiday_diff_per_person |
          0
        ) -
        Number(
          type === "Outbound"
            ? Math.ceil(
              // selectedOutboundFlight?.[0]?.FlightPswFlight?.FlightPswResult
              //   ?.holiday_diff_per_person | 0
              data?.[0]?.FlightPswFlight?.FlightPswResult
                ?.holiday_diff_per_person | 0
            )
            : Math.ceil(
              selectedInboundFlight?.[0]?.FlightPswFlight?.FlightPswResult
                ?.holiday_diff_per_person | 0
            )
        )
      );
    } else {
      // console.log("else");
      if (
        data?.[0]?.FlightPswFlight?.FlightPswResult?.holiday_diff_per_person?.includes(
          "-"
        )
      ) {
        return (
          Math.floor(
            data?.[0]?.FlightPswFlight?.FlightPswResult?.holiday_diff_per_person
          ) || 0
        );
      } else {
        return (
          Math.ceil(
            data?.[0]?.FlightPswFlight?.FlightPswResult?.holiday_diff_per_person
          ) || 0
        );
      }
    }
  };
  // console.log('renderFlightPrice :', renderFlightPrice());
  /**
   * @returns
   */

  // const handleSelectFlights = () => {
  //   setSessionStorage("nextButtonClicked", "true")
  //   if (flightRoom.isNewFlightSelected.flights) {
  //     setIsDirect(false)
  //     setSessionStorage("nextButtonClicked", "true")
  //     handleConfirmSelectedFlight()
  //   } else {
  //     setIsDirect(true)
  //     setSessionStorage(
  //       "OutboundFlights",
  //       JSON.stringify(flightRoom?.selectedFlights?.outbound)
  //     );
  //     setSessionStorage(
  //       "InboundFlights",
  //       JSON.stringify(flightRoom?.selectedFlights?.inbound)
  //     );
  //     setOnChangeMultiRoom(flightRoom.multiRooms);
  //     // if (windowWidth < 600) {
  //     //   setTimeout(() => {
  //     //     scrollToText()
  //     //   }, 1)
  //     // }
  //     // setActiveIndex((prevIndex: any) => (prevIndex + 1) % components.length);
  //   }

  // }
  const handleConfirmFlight = (inboundFlight: any, out: any, id: any) => {

    //* Outbound to Redux
    // console.log("ISSUE---17")
    const updatedFlightRoomState = dispatch(
      setFlightRoomState({
        ...flightRoom,
        psw_result_id_combo: inboundFlight[0].FlightPswFlight.psw_result_id,
        psw_result_id: out[0].FlightPswFlight.psw_result_id,
        store_psw_result_id: out[0].FlightPswFlight.psw_result_id,
        selectedFlights: { inbound: inboundFlight, outbound: out },
        storeSelectedFlights: { inbound: inboundFlight, outbound: out },
      })
    ).payload;
    const yourHolidaySummary = dispatch(
      setHolidayDetailsState({
        ...holiday,
        outboundTime: singleFlightTravelTime(out?.[0]?.FlightPswFlight?.segment1_elapse_flying_time)?.replace(/(\d+)\s?hr/g, '$1hr').replace(/\s?m/g, 'm'),
        // sumTime(getFlightTimesObject(out)),
        inboundTime: singleFlightTravelTime(inboundFlight?.[0]?.FlightPswFlight?.segment2_elapse_flying_time)?.replace(/(\d+)\s?hr/g, '$1hr').replace(/\s?m/g, 'm'),
        // sumTime(getFlightTimesObject(inboundFlight)),
      })
    ).payload;

    return { updatedFlightRoomState, yourHolidaySummary };
  };
  const getChangeFlight = async (flightRoom: any, noLoaderCall?: boolean) => {
    // console.log('6666666666:', flightRoom);

    const res = await dispatch(
      fetchAsyncChangeFlight([
        {
          psc_request_id: flightRoom.psc_request_id,
          psw_result_id: flightRoom.psw_result_id,
          grouped_results: true,
        },
      ])
    );
    // console.log("ISSUE---18")
    dispatch(
      setFlightRoomState({
        ...flightRoom,
        psc_request_id: flightRoom.psc_request_id,
        psw_result_id: flightRoom.psw_result_id,
      })
    );
    if (res) {
      dispatch(setFlightOptions(res));
      if (!noLoaderCall) {
        dispatch(setLoading(false));
      }
    }
    return res;
  };
  const handleSelectFlightsApiCall = async (inboundFlight: any, out: any, id: any) => {
    // console.log('out :', out);
    // console.log('inboundFlight :', inboundFlight);
    setFirstTimeLoad(true)
    setSessionStorage("selectdInboundRadio", JSON.stringify(inboundFlight))
    setIsSelected(false)
    setSessionStorage("stepper", "1");
    dispatch(setLoading(true));
    const updatedState: any = await handleConfirmFlight(inboundFlight, out, id);

    // console.log('updatedState :', updatedState);
    const updatedflightRoom: any = updatedState?.updatedFlightRoomState;
    // console.log('updatedflightRoom :', updatedflightRoom);
    const flightRes: any = await getChangeFlight?.(updatedflightRoom, true);
    // console.log('flightRes :', flightRes);
    // dispatch(setLoading(true));
    if (flightRes) {
      // setIsFlightChanged(true);
      // let price: any = "0";

      // if (
      //   updatedflightRoom?.selectedFlights?.outbound[0]?.FlightPswFlight?.FlightPswResult?.holiday_diff_per_person?.includes(
      //     "-"
      //   )
      // ) {
      //   price = Math.floor(
      //     Number(
      //       updatedflightRoom?.selectedFlights?.outbound[0]?.FlightPswFlight?.FlightPswResult
      //         ?.holiday_diff_per_person
      //     ) + Number(flightRoom.diffPrice)
      //   );
      // } else {
      //   price = Math.ceil(
      //     Number(
      //       updatedflightRoom?.selectedFlights?.outbound[0].FlightPswFlight?.FlightPswResult
      //         ?.holiday_diff_per_person
      //     ) + Number(flightRoom.diffPrice)
      //   );
      // }
      // setSessionStorage("OutboundFlights", JSON.stringify(updatedflightRoom.selectedFlights.outbound));
      // setSessionStorage("InboundFlights", JSON.stringify(updatedflightRoom.selectedFlights.inbound));
      // console.log(updatedflightRoom.psw_result_id === flightRoom.store_psw_result_id, 'updatedflightRoom.psw_result_id === flightRoom.store_psw_result_id');

      const flightObj = {
        ...updatedState?.updatedFlightRoomState,
        // diffPrice: price.toString(),
        psc_request_id: updatedflightRoom.psc_request_id,
        psw_result_id: updatedflightRoom.psw_result_id,
        confirmSelection: {
          ...updatedState?.updatedFlightRoomState.confirmSelection,
          flights: updatedflightRoom.psw_result_id === flightRoom.store_psw_result_id ? false : true,
        },
      };

      const response = isRetrieveBasketApiCall ? await retrieveholidayBasketRetrieve(updatedflightRoom, true)
        : await getHolidayPackageRetrieveData(updatedflightRoom);
      // const response = await getHolidayPackageRetrieveData(updatedflightRoom);

      if (response) {

        // setSaveRooms(response.Packages.results);
        // setSaveRooms()
        dispatch(setFilteredHolidayPackage(holidayPackage));

        const selectedRooms =
          response.Packages.results.length > 0
            ? sortRoom(response.Packages.results[0].SearchResult, filters)
            : [];
        let roomArr = [];
        let index = 0;
        if (response.Packages.results.length > 0) {
          for (const [key, value] of Object.entries(response.Packages.results[0].SearchResult.Rooms)) {
            const isRoomAvaialble: any = Array.isArray(value)
              ? value.filter((f: any) => f.id === flightRoom.multiRooms[index].id)
              : [];
            if (isRoomAvaialble.length > 0) {
              roomArr.push(isRoomAvaialble[0]);
            } else {
              roomArr.push(selectedRooms[index]);
            }
            index = index + 1;
          }
        }
        // setSaveRooms(response.Packages.results)
        // console.log('roomArr :', roomArr);
        // setOnChangeMultiRoom(roomArr);
        const body = [
          {
            psw_result_id: flightRoom?.psw_result_id,
            roomids: flightRoom.roomids,
            packageSearchId: flightRoom?.packageSearchId || "",
            holidayDetailLogId: flightRoom.holidayDetailLogId,
            destination: flightRoom?.searchCriteria?.destination["Searc ID"],
          },
        ];

        // const containsTrue = Object.values(flightRoom.confirmSelection).some((value) => value === true);
        // if (containsTrue) {
        dispatch(setLoading(true));
        await dispatch(fetchAsyncHolidayPackageDetails(body));
        // }
        // console.log("ISSUE---19")
        const updated = await dispatch(
          setFlightRoomState({
            ...flightObj,
            packageSearchId: response?.packageSearchId || "",
            flightSearchCriteriaId: response.SearchCriteria[1].ids.flightSearchCriteriaId,
            hotelSearchCriteriaIds: response.SearchCriteria[1].ids.hotelSearchCriteriaIds,
            hotelSearchCriteriaId_nextDay: response.SearchCriteria[1].ids.hotelSearchCriteriaId_nextDay,
            multiRooms: roomArr,
            roomData: response.Packages.results,
          })
        ).payload;
        setOnChangeMultiRoom(roomArr);
        setSaveRooms(response.Packages.results);
        setDisableButton(false);
        const newData = { ...updated };
        if (newData.hasOwnProperty("roomData")) {
          delete newData["roomData"];
        }

        await saveBasket(
          {
            updatedFlightRoomState: newData,
            yourHolidaySummary: updatedState?.yourHolidaySummary,
          },
          true
        );

        // setTimeout(() => {
        //   scrollToText();
        // }, 1);

        // await setActiveIndex((prevIndex: any) => (prevIndex + 1) % components.length);
        // setIsFlightChanged(false)
        // console.log("BUTTON CLICK111");

        setSelectedOutboundFlight(out)
        setSelectedInboundFlight(inboundFlight)
        setOpenChangeFlightDrawer(false);
        setInboundSorting(false)

        setSessionStorage('nextAndSelectedFlights', 'false');
        // setonlyShowFlights(false);
        await dispatch(setLoading(false));
      } else {
        setSaveRooms([]);
        setOnChangeMultiRoom([]);

        setDisableButton(false);

        const body = [
          {
            psw_result_id: flightRoom?.psw_result_id,
            roomids: flightRoom.roomids,
            packageSearchId: flightRoom?.packageSearchId || "",
            holidayDetailLogId: flightRoom.holidayDetailLogId,
          },
        ];

        // const containsTrue = Object.values(flightRoom.confirmSelection).some((value) => value === true);
        // if (containsTrue) {
        dispatch(setLoading(true));
        await dispatch(fetchAsyncHolidayPackageDetails(body));
        // }


        const newData = { ...flightObj };
        if (newData.hasOwnProperty("roomData")) {
          delete newData["roomData"];
        }
        saveBasket({
          updatedFlightRoomState: newData,
          yourHolidaySummary: updatedState?.yourHolidaySummary,
        });
        setSessionStorage("previous", "true");
        setSessionStorage("nextAndSelectedFlights", "true");
        // setTimeout(() => {
        //   scrollToText();
        // }, 1);
        // await setActiveIndex((prevIndex: any) => (prevIndex + 1) % components.length);
        // setIsFlightChanged(false)
        // console.log("BUTTON CLICK22");

        setSelectedOutboundFlight(out)
        setSelectedInboundFlight(inboundFlight)
        setOpenChangeFlightDrawer(false);
        setInboundSorting(false)

        // setonlyShowFlights(false);
        dispatch(setLoading(false));
      }
    }
    // console.log("BUTTON CLICK333");

    // setSelectedOutboundFlight(out)
    // setSelectedInboundFlight(inboundFlight)
    // setOpenChangeFlightDrawer(false);
  }

  function formatDateToShortMonthDay(dateString: any) {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Format the date to "MMM DD" format
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'short', // Abbreviated month
      day: 'numeric', // Day of the month
    });

    return formattedDate;
  }
  // console.log('+++++', Object.values(selected?.in[0])[0]);
  function reorderArrayWithMapping(arr: any, targetId: any) {
    // Extract the psw_result_id from targetId
    const targetPswResultId = targetId[0]?.FlightPswFlight?.psw_result_id;

    if (!targetPswResultId) {
      // console.log('Invalid targetId structure');
      return arr;
    }

    // Separate the matching and non-matching items
    const matching = arr.filter(
      (item: any) => item.inboundFlight[0]?.FlightPswFlight?.psw_result_id === targetPswResultId
    );
    const nonMatching = arr.filter(
      (item: any) => item.inboundFlight[0]?.FlightPswFlight?.psw_result_id !== targetPswResultId
    );

    // Combine matching items first, followed by non-matching items
    const reorderedArray = [...matching, ...nonMatching];

    // console.log('Reordered Array:', reorderedArray);
    return reorderedArray;
  }
  const inBoundSelectedFlight: any = getSessionStorage('selectdInboundRadio')
    ? JSON.parse(getSessionStorage('selectdInboundRadio') as string)
    : [];
  // const inBoundSelectedFlight:any = JSON.parse(getSessionStorage('selectdInboundRadio')) ?? []

  return isEqual && !isSelected
    ? flightDisplayList()
    : !data[0]?.FlightPswFlight?.Baggage ||
      data[0]?.FlightPswFlight?.Baggage == "0p" ||
      data[0]?.FlightPswFlight?.Baggage == "0pc"
      ? null
      : flightDisplayList();

  function flightDisplayList(): React.ReactElement<any, any> | null {
    return (
      <Box
        sx={{
          border: "1px solid #BDBDBD",
          borderRadius: "8px",
          marginBottom: "10px",
          // display: "flex",
          display:
            item?.outboundFlight?.outboundFlight?.length > 0 &&
              Array.isArray(item?.inboundFlights) &&
              item.inboundFlights.length > 0
              ? "flex"
              : "none",
          flexDirection: "column",
          // "&:hover": {
          //   background: "#f5f5f5",
          // },
        }}
      >
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            md={9.5}
            sx={{
              borderRadius: "8px 0 0 0",
              // "&:hover": {
              //   background: {
              //     md: "white !important",
              //     xs: isEqual ? colors.grey[50] : colors.basics.white,
              //   },
              // },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                display: !showAllFlights ? "none" : "flex",
                p: "10px",
                fontWeight: "500",
              }}
            >
              Outbound Flights
            </Typography>

            <Box
              borderBottom={showAllFlights ? `1px solid ${colors.grey[20]}` : "none"}
              width="100%"
              display={{ md: "flex", xs: "block" }}
              justifyContent="space-between"
              sx={{
                borderRadius: {
                  md: !showAllFlights ? "8px 0 0 0" : "0px 0 0 0",
                  xs: !showAllFlights ? "8px 8px 0 0" : "0",
                },
                // background: isEqual ? colors.grey[50] : colors.basics.white,
                background: colors.grey[50],
              }}
            >
              <Box
                pt={{ lg: "10px", sm: "20px", xs: "8px" }}
                pb={{ lg: "10px", sm: "20px", xs: "8px" }}
                pl={{ lg: "10px", sm: "20px", xs: "8px" }}
                pr={{ lg: "10px", sm: "20px", xs: "8px" }}
                sx={{
                  // backgroundColor: "pink",
                  borderBottom: {
                    md: " none",
                    xs: `0.5px solid ${colors.grey[30]}`,
                  },
                  md: !showAllFlights ? "10%" : "100%",
                  xs: "100%",
                  display: { md: !showAllFlights ? "none" : "flex", xs: "none" },
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "row",
                  marginBottom: { md: "10px", xs: "0" },
                  flex: 1,
                  gap: "10px",
                  alignItems: "center",
                  "& img": {
                    width: "60px",
                    // height: "fit-content",
                  },
                }}
              >
                <Box
                  sx={{
                    display: {
                      md: !showAllFlights ? "none" : "flex",
                      xs: "none",
                    },
                    "& .MuiFormControlLabel-root": {
                      mr: "0",
                    },
                    "& .MuiButtonBase-root-MuiRadio-root": {
                      p: 0,
                    },
                    "& .Mui-checked": {
                      color: isPlataniumHotel
                        ? `${colors.basics.plataniumGreenColor} !important`
                        : `${colors.basics.primary} !important`,
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        value={data?.[0]?.FlightPswFlight?.psw_result_id}
                        // checked={isEqualInbound}
                        checked={data?.[0]?.FlightPswFlight?.psw_result_id}
                      />
                    }
                  />
                </Box>
                {/* <Box sx={{ display: "flex" }}>
          <Box sx={{ display: { md: "none", xs: "flex" }, ml: "20px" }}>
            <Typography variant="body2" fontSize={"13px"}>
              {data?.[0]?.FlightPswFlight?.psw_result_id}
            </Typography>
            <Typography variant="body2" fontSize={"13px"}>
              {data?.[0]?.FlightPswFlight?.airline_name}
            </Typography>
          </Box>
        </Box> */}
                {/* <Box>
          <Typography variant="body2" fontSize={"13px"}>
            {data?.[0]?.FlightPswFlight?.psw_result_id}
          </Typography>

          <Typography
            variant="body2"
            fontSize={"13px"}
            sx={{ display: { md: "flex", xs: "none" } }}
          >
            {data?.[0]?.FlightPswFlight?.airline_name}
          </Typography>
          <Typography
            variant="body2"
            fontSize={"13px"}
            color={colors.grey[30]}
            fontWeight={"400"}
            sx={{ display: { md: "flex", xs: "none" } }}
          >
            {data?.[0]?.FlightPswFlight?.airline_code}
            {data?.[0]?.FlightPswFlight?.flight_number}
          </Typography>
        </Box> */}
                {/* <Box sx={{ display: { md: "none", xs: "flex" } }}>
          <Typography
            variant="body2"
            fontSize={"13px"}
            color={colors.grey[30]}
            fontWeight={"400"}
          >
            {data?.[0]?.FlightPswFlight?.airline_code}
            {data?.[0]?.FlightPswFlight?.flight_number}
          </Typography>
        </Box> */}
              </Box>
              <Box
                pt={{ lg: "10px", sm: "20px", xs: showAllFlights ? "16px" : "8px" }}
                pb={{ lg: "10px", sm: "20px", xs: showAllFlights ? "16px" : "8px" }}
                pl={{ lg: "10px", sm: "20px", xs: showAllFlights ? "16px" : "8px" }}
                pr={{ lg: "10px", sm: "20px", xs: showAllFlights ? "16px" : "8px" }}
                sx={{ width: { md: !showAllFlights ? "100%" : "90%", xs: "100%" } }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    background: "red",
                  }}
                ></Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  m="auto"
                  sx={{
                    "& .MuiTypography-root": {
                      fontWeight: 500,
                      lineHeight: "130%",
                      // mt: { sm: '10px', xs: "0" },
                    },
                    gap: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: {
                        md: "none",
                        xs: !showAllFlights ? "none" : "flex",
                      },
                      "& .MuiFormControlLabel-root": {
                        mr: "0",
                        ml: {
                          xs: windowWidth < 374 ? "-20px" : "-11px",
                        },
                        "& .MuiButtonBase-root-MuiRadio-root": {
                          p: "2px !important",
                        },
                        "& .Mui-checked": {
                          color: isPlataniumHotel
                            ? `${colors.basics.plataniumGreenColor} !important`
                            : `${colors.basics.primary} !important`,
                        },
                        // background: "pink"
                      },
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          value={data?.[0]?.FlightPswFlight?.psw_result_id}
                          // checked={isEqualInbound}
                          checked={data?.[0]?.FlightPswFlight?.psw_result_id}
                        />
                      }
                    />
                  </Box>
                  <Box width="20%">
                    {/* <Box sx={{ textAlign: 'left' }}> */}
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      lineHeight="120%"
                      sx={{ fontSize: { sm: "16px", xs: "13px" } }}
                    >
                      {`${data?.[0]?.FlightPswFlight?.departure_airport}`}
                    </Typography>
                    {/* </Box> */}
                    <Typography
                      variant="body1"
                      fontWeight={300}
                      lineHeight="120%"
                      sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                    >
                      {departure_time.split(" ")[2]}
                    </Typography>
                    {/* <Typography variant='body1'
          fontWeight={300}
          lineHeight='120%'
          sx={{ fontSize: { sm: '13px', xs: '13px' } }}>
          {formatDateToShortMonthDay(data?.[0]?.FlightPswFlight?.departure_date)}
        </Typography> */}
                    <Typography
                      variant="body1"
                      fontWeight={300}
                      lineHeight="120%"
                      sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                    >
                      {data?.[0]?.FlightPswFlight?.Airport?.depart_airport_city}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      textAlign: "center",
                      "& img": {
                        width: "70px",
                        // height: "fit-content",
                      },
                    }}
                    width={{ md: "60%", xs: windowWidth < 374 ? "50%" : "55%" }}
                    m="auto"
                  >
                    {window.location.host.includes("localhost") && (
                      <span style={{ fontSize: "12px" }}>{data?.[0]?.FlightPswFlight?.psw_result_id}</span>
                    )}
                    {/* <span style={{ fontSize: "12px" }}>{data?.[0]?.FlightPswFlight?.psw_result_id}</span> */}
                    <Image
                      src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${data?.[0]?.FlightPswFlight?.airline_code}.png`}
                      alt=""
                    />
                    <Box width="100%" mt="5px">
                      <FlightTrack stopPoint={data?.length - 1 === 0 ? 0 : 1} singleLine={true} />
                    </Box>
                    <Typography
                      variant="body2"
                      fontSize={"13px"}
                      fontWeight={300}
                      lineHeight={"15.26px"}
                      mt="8px"
                    >
                      {singleFlightTravelTime(data?.[0]?.FlightPswFlight?.segment1_elapse_flying_time)
                        ?.replace(/(\d+)\s?hr/g, "$1hr")
                        .replace(/\s?m/g, "m")}{" "}
                      • {/* {totalFlightTime()} • {" "} */}
                      {/* {data?.length - 1 === 0
          ? "Direct)"
          : data?.length - 1 + " stop)"}{" "} */}
                      {data?.length - 1 === 0
                        ? "(Direct)"
                        : data?.length - 1 === 1
                          ? "(1 stop)"
                          : `(${data?.length - 1} stops)`}
                      <br />
                    </Typography>
                  </Box>
                  <Box textAlign="right" width="20%">
                    {/* <Box textAlign='right'> */}
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      lineHeight="120%"
                      sx={{ fontSize: { sm: "16px", xs: "13px" } }}
                    >
                      {data?.[data?.length - 1]?.FlightPswFlight?.arrival_airport}
                    </Typography>

                    {/* </Box> */}
                    <Typography
                      variant="body1"
                      fontWeight={300}
                      lineHeight="120%"
                      sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                    >
                      {arrival_time.split(" ")[2]}{" "}
                      <span
                        style={{
                          color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                        }}
                      >
                        {/* {isFlightArrivingNextDay ? '+1' : null} */}
                        {(() => {
                          const difference = calculateDateDifference(
                            data[0]?.FlightPswFlight?.departure_date,
                            data[data?.length - 1]?.FlightPswFlight?.arrival_date
                          );
                          return difference !== null ? `+${difference}` : null;
                        })()}
                      </span>
                      {/* <span
          style={{
            color: isPlataniumHotel
              ? colors.basics.plataniumGreenColor
              : colors.basics.primary,
          }}
        >
          {isFlightArrivingNextDay ? "+1" : null}
        </span> */}
                    </Typography>
                    {/* <Typography variant='body1'
          fontWeight={300}
          lineHeight='120%'
          sx={{ fontSize: { sm: '13px', xs: '13px' } }}>
          {formatDateToShortMonthDay(data?.[data?.length - 1]?.FlightPswFlight?.arrival_date)}
          <span style={{ color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary }}>
            {isFlightArrivingNextDay ? '+1' : null}
          </span>
        </Typography> */}
                    <Typography
                      variant="body1"
                      fontWeight={300}
                      lineHeight="120%"
                      sx={{
                        fontSize: { sm: "13px", xs: "13px" },
                        wordBreak: "break-word",
                        hyphens: "manual",
                      }}
                    >
                      {data?.[data?.length - 1]?.FlightPswFlight?.Airport?.arrive_airport_city}
                    </Typography>
                  </Box>
                </Box>
                {/* <Box justifyContent='space-between' alignItems='center' textAlign='center'>
            <Box>
              <Typography
                variant='body1'
                fontWeight={500}
                lineHeight='130%'
                sx={{ fontSize: { sm: '16px', xs: '14px' } }}
              >
                {"("}{data?.length - 1 === 0 ? "Direct)" : data?.length - 1 + " stop)"} <br />
              </Typography>
            </Box>
          </Box> */}
              </Box>
            </Box>

            <Typography
              variant="body1"
              sx={{ display: !showAllFlights ? "none" : "flex", p: "10px", fontWeight: "500" }}
            >
              Inbound Flights
            </Typography>
            {/* {data1
          ?.slice(0, showAllFlights ? data1?.length : 1)

          .map((flightData: any, index: any) => {
            const abc = flightData.inboundFlight[0]?.FlightPswFlight?.psw_result_id === selectedInboundRadio;

            console.log("flightData.inboundFlight[0].FlightPswFlight.psw_result_id:", flightData.inboundFlight[0]?.FlightPswFlight?.psw_result_id);
            console.log("abc:", abc); */}

            {reorderArrayWithMapping(data1, inBoundSelectedFlight)
              ?.slice(0, showAllFlights ? data1?.length : 1)
              .map((flightData: any, index: any) => {
                // console.log('flightData :', flightData);
                return (
                  <>
                    <Box
                      key={flightData.targetSelfId}
                      width="100%"
                      display={{ md: "flex", xs: "block" }}
                      justifyContent="space-between"
                      sx={{
                        borderRadius: { md: "8px 0 0 0", xs: "0" },
                        // background: { xs: isEqual && colors.grey[50], md: colors.basics.white }
                        background: colors.basics.white,
                      }}
                    >
                      <Box
                        pl={{ lg: "10px", sm: "20px", xs: "16px" }}
                        pr={{ lg: "10px", sm: "20px", xs: "16px" }}
                        sx={{
                          width: {
                            md: !showAllFlights ? "10%" : "100%",
                            xs: "100%",
                          },
                          // borderBottom: {
                          //   md: " none",
                          //   xs: `0.5px solid ${colors.grey[30]}`,
                          // },
                          // borderTop: {
                          //   md: " none",
                          //   xs: `0.5px solid ${colors.grey[30]}`,
                          // },
                          display: { md: !showAllFlights ? "none" : "flex", xs: "none" },
                          justifyContent: "center",
                          flexDirection: "row",
                          marginBottom: { md: "10px", xs: "0" },
                          flex: 1,
                          gap: "10px",
                          alignItems: "center",
                          "& img": {
                            width: "60px",
                            // height: "fit-content",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            // display: !showAllFlights ? "none" : "flex",
                            display: {
                              md: !showAllFlights ? "none" : "flex",
                              xs: "none",
                            },
                            "& .MuiFormControlLabel-root": {
                              mr: "0",
                            },
                            "& .MuiButtonBase-root-MuiRadio-root": {
                              p: 0,
                            },
                            "& .Mui-checked": {
                              color: isPlataniumHotel
                                ? `${colors.basics.plataniumGreenColor} !important`
                                : `${colors.basics.primary} !important`,
                            },
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                value={flightData.psw_result_id}
                                // checked={isEqualInbound}
                                // checked={
                                //   selected?.in?.[0]?.[`${flightData?.psw_result_id}`]?.[0]?.FlightPswFlight?.psw_result_id ===
                                //   flightData?.inboundFlight[0]?.FlightPswFlight?.psw_result_id
                                // }
                                checked={
                                  data[0].FlightPswFlight?.psw_result_id === flightRoom.psw_result_id
                                    ? selectedInboundRadio === null
                                      ? selected?.in?.[0]?.[`${flightData?.psw_result_id}`]?.[0]
                                        ?.FlightPswFlight?.psw_result_id ===
                                      flightData?.inboundFlight[0]?.FlightPswFlight?.psw_result_id
                                      : selectedInboundRadio ===
                                      flightData?.inboundFlight[0]?.FlightPswFlight?.psw_result_id
                                    : selected?.in?.[0]?.[`${flightData?.psw_result_id}`]?.[0]
                                      ?.FlightPswFlight?.psw_result_id ===
                                    flightData?.inboundFlight[0]?.FlightPswFlight?.psw_result_id
                                }
                                onChange={(e) => {
                                  setIsInbound(true);
                                  setIsSelected(true);
                                  setInboundSorting(true);

                                  setSelectedInboundRadio(
                                    flightData?.inboundFlight[0].FlightPswFlight.psw_result_id
                                  );
                                  setSelected((prev) => ({
                                    ...prev,
                                    in: [
                                      {
                                        [`${flightData?.psw_result_id}`]: flightData?.inboundFlight,
                                      },
                                    ],
                                  }));
                                }}
                              />
                            }
                          />
                        </Box>
                        {/* <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{ display: { md: "none", xs: "flex" }, ml: "20px" }}
                      >
                        <Typography variant="body2" fontSize={"13px"}>
                          {
                            flightData.inboundFlight[0]?.FlightPswFlight
                              ?.psw_result_id
                          }
                        </Typography>
                        <Typography variant="body2" fontSize={"13px"}>
                          {
                            flightData.inboundFlight[0]?.FlightPswFlight
                              ?.airline_name
                          }
                        </Typography>
                      </Box>
                    </Box> */}

                        {/* <Box>
                      <Typography variant="body2" fontSize={"13px"}>
                        {
                          flightData.inboundFlight[0]?.FlightPswFlight
                            ?.psw_result_id
                        }
                      </Typography>
                      <Typography
                        variant="body2"
                        fontSize={"13px"}
                        sx={{ display: { md: "flex", xs: "none" } }}
                      >
                        {
                          flightData.inboundFlight[0]?.FlightPswFlight
                            ?.airline_name
                        }
                      </Typography>
                      <Typography
                        variant="body2"
                        fontSize={"13px"}
                        color={colors.grey[30]}
                        fontWeight={"400"}
                        sx={{ display: { md: "flex", xs: "none" } }}
                      >
                        {
                          flightData.inboundFlight[0]?.FlightPswFlight
                            ?.airline_code
                        }
                        {
                          flightData.inboundFlight[0]?.FlightPswFlight
                            ?.flight_number
                        }
                      </Typography>
                    </Box> */}
                        {/* <Box sx={{ display: { md: "none", xs: "flex" } }}>
                      <Typography
                        variant="body2"
                        fontSize={"13px"}
                        color={colors.grey[30]}
                        fontWeight={"400"}
                      >
                        {
                          flightData.inboundFlight[0]?.FlightPswFlight
                            ?.airline_code
                        }
                        {
                          flightData.inboundFlight[0]?.FlightPswFlight
                            ?.flight_number
                        }
                      </Typography>
                    </Box> */}
                      </Box>

                      <Box
                        pt={{ lg: "23px", sm: "20px", xs: showAllFlights ? "16px" : "8px" }}
                        pb={{ lg: "23px", sm: "20px", xs: showAllFlights ? "16px" : "8px" }}
                        pl={{ lg: "10px", sm: "20px", xs: showAllFlights ? "16px" : "8px" }}
                        pr={{ lg: "10px", sm: "20px", xs: showAllFlights ? "16px" : "8px" }}
                        sx={{ width: { md: !showAllFlights ? "100%" : "90%", xs: "100%" } }}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{
                            "& img": {
                              width: "60px",
                              height: "fit-content",
                            },
                            background: "red",
                          }}
                        ></Box>

                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          m="auto"
                          sx={{
                            "& .MuiTypography-root": {
                              fontWeight: 500,
                              lineHeight: "130%",
                            },
                            gap: "5px",
                          }}
                        >
                          <Box
                            sx={{
                              // display: !showAllFlights ? "none" : "flex",
                              display: {
                                md: "none",
                                xs: !showAllFlights ? "none" : "flex",
                              },
                              "& .MuiFormControlLabel-root": {
                                mr: "0",
                                ml: {
                                  xs: windowWidth < 374 ? "-20px" : "-11px",
                                },
                                "& .MuiButtonBase-root-MuiRadio-root": {
                                  p: "2px !important",
                                },
                                "& .Mui-checked": {
                                  color: isPlataniumHotel
                                    ? `${colors.basics.plataniumGreenColor} !important`
                                    : `${colors.basics.primary} !important`,
                                },
                                // background: "pink"
                              },
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Radio
                                  value={flightData.psw_result_id}
                                  // checked={isEqualInbound}
                                  checked={
                                    selected?.in?.[0]?.[`${flightData?.psw_result_id}`]?.[0]?.FlightPswFlight
                                      ?.psw_result_id ===
                                    flightData?.inboundFlight[0]?.FlightPswFlight?.psw_result_id
                                  }
                                  onChange={(e) => {
                                    setIsSelected(true);
                                    setSelected((prev) => ({
                                      ...prev,
                                      in: [
                                        {
                                          [`${flightData?.psw_result_id}`]: flightData?.inboundFlight,
                                        },
                                      ],
                                    }));
                                  }}
                                />
                              }
                            />
                          </Box>
                          <Box width="20%">
                            <Typography
                              variant="body1"
                              fontWeight={500}
                              lineHeight="120%"
                              sx={{ fontSize: { sm: "16px", xs: "13px" } }}
                            >
                              {flightData.inboundFlight[0]?.FlightPswFlight?.departure_airport}
                            </Typography>
                            <Box display={"block"} sx={{}}>
                              <Typography
                                variant="body1"
                                fontWeight={300}
                                lineHeight="120%"
                                sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                              >
                                {/* {departure_timeInbound.split(' ')[2]} */}
                                {departure_timeInboundDemo(flightData)}
                                {/* {console.log(' departure_(((', departure_timeInboundDemo(flightData))} */}
                                {/* {departure_timeInbound[index]?.[0]?.split(" ")[2]} */}
                              </Typography>
                              {/* <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' } }}>
                      {formatDateToShortMonthDay(flightData.inboundFlight[0]?.FlightPswFlight?.departure_date)}
                    </Typography> */}
                            </Box>
                            <Typography
                              variant="body1"
                              fontWeight={300}
                              lineHeight="120%"
                              sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                            >
                              {flightData.inboundFlight[0]?.FlightPswFlight?.Airport?.depart_airport_city}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              textAlign: "center",
                              "& img": {
                                width: "70px",
                                // height: "fit-content",
                              },
                            }}
                            width={{
                              md: "60%",
                              xs: windowWidth < 374 ? "50%" : "55%",
                            }}
                            m="auto"
                          >
                            {window.location.host.includes("localhost") && (
                              <span style={{ fontSize: "12px" }}>
                                {flightData.inboundFlight[0]?.FlightPswFlight?.psw_result_id}
                              </span>
                            )}
                            {/* <span style={{ fontSize: "12px" }}>{
                      flightData.inboundFlight[0]?.FlightPswFlight
                        ?.psw_result_id
                    }</span> */}
                            <Image
                              src={`https://tsr.tropicalsky.co.uk/media/images/airlines/240_70/${flightData.inboundFlight[0]?.FlightPswFlight?.airline_code}.png`}
                              alt=""
                            />
                            <Box width="100%" mt="5px">
                              <FlightTrack
                                stopPoint={flightData.inboundFlight.length - 1 === 0 ? 0 : 1}
                                singleLine={true}
                              />
                            </Box>
                            <Box sx={{}}>
                              <Typography
                                variant="body2"
                                fontSize={"13px"}
                                fontWeight={300}
                                lineHeight={"15.26px"}
                                mt="8px"
                              >
                                {singleFlightTravelTime(
                                  flightData.inboundFlight?.[0]?.FlightPswFlight?.segment2_elapse_flying_time
                                )
                                  ?.replace(/(\d+)\s?hr/g, "$1hr")
                                  .replace(/\s?m/g, "m")}{" "}
                                • {/* {totalFlightTimeInboundDemo(flightData)} •{" "} */}
                                {/* {totalFlightTimeInbound()[index]} • {"("} */}
                                {/* {flightData.inboundFlight.length - 1 === 0
                      ? "Direct)"
                      : flightData.inboundFlight.length -
                      1 +
                      " stop)"}{" "} */}
                                {flightData?.inboundFlight?.length - 1 === 0
                                  ? "(Direct)"
                                  : flightData?.inboundFlight?.length - 1 === 1
                                    ? "(1 stop)"
                                    : `(${flightData?.inboundFlight?.length - 1} stops)`}
                                <br />
                              </Typography>
                            </Box>
                          </Box>

                          <Box textAlign="right" width="20%">
                            <Typography
                              variant="body1"
                              fontWeight={500}
                              lineHeight="120%"
                              sx={{ fontSize: { sm: "16px", xs: "13px" } }}
                            >
                              {
                                flightData.inboundFlight[flightData.inboundFlight.length - 1]?.FlightPswFlight
                                  ?.arrival_airport
                              }
                            </Typography>
                            <Typography
                              variant="body1"
                              fontWeight={300}
                              lineHeight="120%"
                              sx={{ fontSize: { sm: "13px", xs: "13px" } }}
                            >
                              {/* {arrival_timeInbound.split(' ')[2]}{' '} */}
                              {/* arrival_timeInbound */}

                              {arrival_timeInboundDemo(flightData)}
                              <span
                                style={{
                                  color: isPlataniumHotel
                                    ? colors.basics.plataniumGreenColor
                                    : colors.basics.primary,
                                }}
                              >
                                {(() => {
                                  const difference = calculateDateDifference(
                                    flightData?.inboundFlight[0]?.FlightPswFlight?.departure_date,
                                    flightData?.inboundFlight[flightData?.inboundFlight?.length - 1]
                                      ?.FlightPswFlight?.arrival_date
                                  );
                                  return difference !== null ? `+${difference}` : null;
                                })()}
                                {/* {console.log('flightData :', flightData)} */}
                                {/* {flightData?.inboundFlight?.map((item: any) => item.FlightPswFlight.dep_arr_date_diff.includes("1")) ? "+2" : null} */}
                                {/* W  {flightData?.inboundFlight[flightData.inboundFlight.length - 1]?.FlightPswFlight.dep_arr_date_diff === "1" ? "+1" : null} */}
                                {/* {flightData?.inboundFlight.some((flight: any) => flight.FlightPswFlight.dep_arr_date_diff === "1") ? "+1" : null} */}
                                {/* {isFlightArrivingNextDay ? "+1" : null} */}
                              </span>
                              {/* {arrival_timeInbound[index]?.split(" ")[2]} */}
                              {/* <span
                      style={{
                        color: isPlataniumHotel
                          ? colors.basics.plataniumGreenColor
                          : colors.basics.primary,
                      }}
                    >
                      {isFlightArrivingNextDay ? "+1" : null}
                    </span> */}
                            </Typography>
                            {/* <Typography variant='body1'
                      fontWeight={300}
                      lineHeight='120%'
                      sx={{ fontSize: { sm: '13px', xs: '13px' } }}>
                      {formatDateToShortMonthDay(flightData.inboundFlight[
                        flightData.inboundFlight.length - 1
                      ]?.FlightPswFlight?.arrival_date)} */}
                            {/* <span
                        style={{
                          color: isPlataniumHotel
                            ? colors.basics.plataniumGreenColor
                            : colors.basics.primary,
                        }}
                      >
                        {isFlightArrivingNextDay ? "+1" : null}
                      </span> */}
                            {/* </Typography> */}
                            <Typography
                              variant="body1"
                              fontWeight={300}
                              lineHeight="120%"
                              sx={{
                                fontSize: { sm: "13px", xs: "13px" },
                                wordBreak: "break-word",
                                hyphens: "manual",
                              }}
                            >
                              {
                                flightData.inboundFlight[flightData.inboundFlight.length - 1]?.FlightPswFlight
                                  ?.Airport?.arrive_airport_city
                              }
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </>
                );
              })}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderTop: {
                  md: "2px solid #E0E0E0",
                  xs: `0.5px solid ${colors.grey[30]}`,
                },
                borderBottom: {
                  md: "none",
                  xs: `0.5px solid ${colors.grey[30]}`,
                },
                justifyContent: "space-between",
                background: {
                  md: "transparent",
                  // xs: isEqual ? colors.grey[50] : colors.basics.white,
                  xs: colors.basics.white,
                },
                // '&:hover': {
                //   background: { md: "transparent !important", xs: isEqual ? 'transparent !important' : 'transparent !important' },
                // },
                "& .MuiSvgIcon-root": {
                  color: isPlataniumHotel
                    ? `${colors.basics.plataniumGreenColor} !important`
                    : `${colors.basics.primary} !important`,
                },
              }}
              padding={{
                md: "16px",
                sm: "10px",
                xs: windowWidth < 374 ? "0 10px" : "0 10px",
              }}
            >
              <Box
                sx={{ cursor: "pointer" }}
                fontSize={{ sm: "16px", xs: windowWidth < 374 ? "13px" : "14px" }}
                fontWeight={"500"}
                height={"40px"}
                justifyContent={"center"}
                alignItems={"center"}
                display={"flex"}
                color={
                  isPlataniumHotel
                    ? `${colors.basics.plataniumGreenColor} !important`
                    : `${colors.basics.primary} !important`
                }
                onClick={toggleFlightDrawer(true)}
              >
                Flight details
              </Box>
              <Box
                sx={{
                  display: { sm: "flex", xs: "none" },
                  alignItems: "center",
                  marginRight: 2,
                }}
              >
                {data[0]?.FlightPswFlight?.Baggage ? <LuggageIcon /> : <NoLuggageIcon />}
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {data[0]?.FlightPswFlight?.Baggage.replace("K", "K")
                    .replace("pc", "p")
                    .replace("p", "p")}
                </Typography>
              </Box>
              <Box
                fontSize={{}}
                sx={{
                  display: data1?.length > 1 ? "flex" : "none",
                  alignItems: "center",
                  "& .MuiButtonBase-root": {
                    fontSize: { sm: "16px", xs: windowWidth < 374 ? "13px" : "14px" },
                    background: "transparent !important",
                    color: isPlataniumHotel
                      ? `${colors.basics.plataniumGreenColor} !important`
                      : `${colors.basics.primary} !important`,
                    padding: "0",
                    // "&:hover": {
                    //   background: "transparent !important",
                    // },
                  },
                  "& svg path": {
                    fill: isPlataniumHotel
                      ? `${colors.basics.plataniumGreenColor} !important`
                      : `${colors.basics.primary} !important`,
                  },
                }}
              >
                <Button
                  variant="text"
                  endIcon={showAllFlights ? <ArrowUp /> : <ArrowDown />}
                  onClick={handleToggleFlights}
                  sx={{ background: colors.basics.primary, color: "white" }}
                >
                  {showAllFlights ? "Fewer flight times" : "More flight times"}
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={2.5}
            padding={{ sm: "10px", xs: windowWidth < 374 ? "10px" : "10px" }}
            sx={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "column",
              borderLeft: { md: "2px solid #ccc", xs: "none" },
              background: {
                md: "transparent",
                // xs: isEqual ? colors.grey[50] : colors.basics.white,
                xs: colors.basics.white,
              },
              borderRadius: { md: "0 8px 8px 0", xs: "0 0 8px 8px" },
              // '&:hover': {
              //   background: {
              //     xs: "transparent",
              //   },
              // }
            }}
          >
            <Box
              sx={{
                display: "flex",
                // width: "100%",
                justifyContent: { md: "space-between", sm: "flex-end", xs: "space-between" },
                "& .MuiSvgIcon-root": {
                  color: isPlataniumHotel
                    ? `${colors.basics.plataniumGreenColor} !important`
                    : `${colors.basics.primary} !important`,
                },
              }}
            >
              <Box
                sx={{
                  display: { sm: "none", xs: "flex" },
                  alignItems: "center",
                  marginRight: 2,
                }}
              >
                {data[0]?.FlightPswFlight?.Baggage ? <LuggageIcon /> : <NoLuggageIcon />}
                <Typography variant="body2" sx={{ marginLeft: "4px" }}>
                  {data[0]?.FlightPswFlight?.Baggage.replace("K", "K")
                    .replace("pc", "p")
                    .replace("p", "p")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: { md: "none", xs: "flex" },
                  // width: "100%",
                  justifyContent: "end",
                }}
              >
                <Typography variant="body1" fontWeight={600} fontSize={20}>
                  {/* From{" "} */}
                  {renderFlightPrice() >= 0
                    ? `+${signForPricing + renderFlightPrice()}`
                    : `-${signForPricing + Math.abs(renderFlightPrice())}`}{" "}
                  pp
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                justifyContent: "space-between",
                marginTop: { sm: 2, xs: 1 },
                paddingTop: 1,
              }}
            >
              <Typography
                sx={{ display: { md: "flex", xs: "none" } }}
                variant="body1"
                fontWeight={600}
                fontSize={20}
              >
                {/* From{" "} */}
                {renderFlightPrice() >= 0
                  ? `+${signForPricing + renderFlightPrice()}`
                  : `-${signForPricing + Math.abs(renderFlightPrice())}`}{" "}
                pp
              </Typography>
              <Box
                sx={{
                  display: { md: "block", xs: "flex" },
                }}
              >
                {/* <Box
          p={{ md: 0, xs: "0px 10px 0 0" }}
          width={"100%"}
          sx={{
            "& .MuiButtonBase-root": {
              color: !isEqual
                ? isPlataniumHotel
                  ? `${colors.basics.plataniumGreenColor} !important`
                  : `${colors.basics.primary} !important`
                : colors.basics.white,

              background: !isEqual
                ? "transparent"
                : isPlataniumHotel
                  ? `${colors.basics.plataniumGreenColor} !important`
                  : `${colors.basics.primary} !important`,
              height: { xs: "40px", sm: "40px" },
              border: !isEqual
                ? isPlataniumHotel
                  ? `1px solid ${colors.basics.plataniumGreenColor} !important`
                  : `1px solid ${colors.basics.primary} !important`
                : "none !important",
              "&:hover": {
                background: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                color: `${colors.basics.white} !important`,
              },
            },
          }}
        >
          <Button variant="text"
            sx={{

              p: "10px 15px ",
              whiteSpace: "nowrap",
              fontSize: { sm: "16px", xs: "14px" },
              width: "100%",
            }}
            onClick={() => handleSelectFlight(isEqual, type, psw_result_id, data)}
          >
            {isEqual ? (type === "Inbound" ? "Show Outbound" : "Show Inbound") : "Choose this flight"}
          </Button>
        </Box> */}
                <Box
                  mt={{ md: "10px", xs: "0" }}
                  display={"flex"}
                  width={"100%"}
                  sx={{
                    "& .MuiButtonBase-root": {
                      height: { xs: "40px", sm: "40px" },
                      background: {
                        md:
                          isEqual && !isSelected
                            ? "#E3E3E3"
                            : isPlataniumHotel
                              ? `${colors.basics.plataniumGreenColor} `
                              : `${colors.basics.primary} !important`,

                        xs:
                          isEqual && !isSelected
                            ? "#E3E3E3"
                            : isPlataniumHotel
                              ? `${colors.basics.plataniumGreenColor} !important`
                              : `${colors.basics.primary} !important`,
                      },

                      color: isEqual && !isSelected ? `${colors.grey[30]} !important` : "#fff",
                      ":hover": {
                        backgroundColor:
                          isEqual && !isSelected
                            ? "#E3E3E3"
                            : isPlataniumHotel
                              ? `${colors.basics.plataniumGreenColor} !important `
                              : `${colors.basics.primary} !important`,
                      },
                      borderRadius: "8px",
                    },
                    "& svg": {
                      fill: colors.grey[30],
                    },
                  }}
                >
                  <Button
                    startIcon={isEqual && !isSelected ? <CheckedIcon /> : ""}
                    sx={{ width: "100%" }}
                    disabled={isEqual && !isSelected}
                    // onClick={() => handleSelectFlights()}
                    // onClick={() => handleSelectFlightsApiCall(data1?.[0]?.inboundFlight, data, data1?.[0].combo[0])}
                    onClick={() =>
                      handleSelectFlightsApiCall(
                        Object.values(selected?.in[0])[0],
                        selected?.out,
                        data1?.[0].combo[0]
                      )
                    }
                  >
                    {isEqual && !isSelected ? "Selected" : "Select"}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Drawer
              anchor={"right"}
              open={openFlightDrawer}
              onClose={toggleFlightDrawer(false)}
              PaperProps={{
                sx: { width: { md: 600, xs: "100%" } },
              }}
            >
              <ViewFlightDetailDrawer
                selected={selected}
                current={current}
                setInnerAccordionExpanded={setInnerAccordionExpanded}
                type={type}
                isFlightArrivingNextDay={isFlightArrivingNextDay}
                arrival_time={arrival_time}
                departure_time={departure_time}
                isEqual={isEqual}
                expand={expand}
                currentTab={currentTab}
                departureLocation={departureLocation}
                ArrivalLocation={ArrivalLocation}
                data={data}
                dataIn={data1}
                isPlataniumHotel={isPlataniumHotel}
                setOpenFlightDrawer={setOpenFlightDrawer}
                openFlightDrawer={openFlightDrawer}
              />
            </Drawer>
          </Grid>
        </Grid>
      </Box>
    );
  }
};

export default FlightDetailsAccordion;
