export const phoneCodeByRegionIE = [
    {
        "Destination": "Boston",
        "Region": "Middle East",
        "code": "middle-east-holidays",
        "Airport": "BOS",
        "TSRID": "10982",
        "ClassName": "01 6649900",
        "phone": "01 6649900"
    },
    {
        "Destination": "Chicago",
        "Region": "Middle East",
        "code": "middle-east-holidays",
        "Airport": "ORD",
        "TSRID": "11366",
        "ClassName": "01 6649900",
        "phone": "01 6649900"
    },
    {
        "Destination": "Hawaii - Kauai, HI",
        "Region": "Middle East",
        "code": "middle-east-holidays",
        "Airport": "LIH",
        "TSRID": "31248",
        "ClassName": "01 6649900",
        "phone": "01 6649900"
    },
    {
        "Destination": "Hawaii - Maui, HI",
        "Region": "Middle East",
        "code": "middle-east-holidays",
        "Airport": "OGG",
        "TSRID": "31251",
        "ClassName": "01 6649900",
        "phone": "01 6649900"
    },
    {
        "Destination": "Hawaii - The Big Island, HI",
        "Region": "Middle East",
        "code": "middle-east-holidays",
        "Airport": "KOA",
        "TSRID": "31238",
        "ClassName": "01 6649900",
        "phone": "01 6649900"
    },
    {
        "Destination": "Honolulu, HI",
        "Region": "Middle East",
        "code": "middle-east-holidays",
        "Airport": "HNL",
        "TSRID": "31237",
        "ClassName": "01 6649900",
        "phone": "01 6649900"
    },
    {
        "Destination": "Las Vegas",
        "Region": "Middle East",
        "code": "middle-east-holidays",
        "Airport": "LAS",
        "TSRID": "13863",
        "ClassName": "01 6649900",
        "phone": "01 6649900"
    },
    {
        "Destination": "Los Angeles, CA",
        "Region": "Middle East",
        "code": "middle-east-holidays",
        "Airport": "LAX",
        "TSRID": "13868",
        "ClassName": "01 6649900",
        "phone": "01 6649900"
    },
    {
        "Destination": "Miami",
        "Region": "Middle East",
        "code": "middle-east-holidays",
        "Airport": "MIA",
        "TSRID": "19403",
        "ClassName": "01 6649960",
        "phone": "01 6649960"
    },
    {
        "Destination": "New York",
        "Region": "Middle East",
        "code": "middle-east-holidays",
        "Airport": "NYC",
        "TSRID": "15134",
        "ClassName": "01 6649900",
        "phone": "01 6649900"
    },
    {
        "Destination": "Orlando",
        "Region": "Middle East",
        "code": "middle-east-holidays",
        "Airport": "MCO",
        "TSRID": "31806",
        "ClassName": "01 6649960",
        "phone": "01 6649960"
    },
    {
        "Destination": "Philadelphia",
        "Region": "Middle East",
        "code": "middle-east-holidays",
        "Airport": "PHL",
        "TSRID": "15539",
        "ClassName": "01 6649900",
        "phone": "01 6649900"
    },
    {
        "Destination": "San Francisco",
        "Region": "Middle East",
        "code": "middle-east-holidays",
        "Airport": "SFO",
        "TSRID": "16836",
        "ClassName": "01 6649900",
        "phone": "01 6649900"
    },
    {
        "Destination": "Seattle",
        "Region": "Middle East",
        "code": "middle-east-holidays",
        "Airport": "SEA",
        "TSRID": "16800",
        "ClassName": "01 6649900",
        "phone": "01 6649900"
    },
    {
        "Destination": "Washington, DC",
        "Region": "Middle East",
        "code": "middle-east-holidays",
        "Airport": "IAD",
        "TSRID": "19373",
        "ClassName": "01 6649900",
        "phone": "01 6649900"
    },
  ]