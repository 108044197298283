import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../api/services";
import { API_METHOD } from "../../api/services/apiMethods";

export const fetchAsyncHotelDetails: any = createAsyncThunk(
  "hotel_details/fetchAsyncHotelDetails",
  async (id) => {
    const response = await API.post(API_METHOD.Hotel.GET_HOTEL_DETAILS, [id]);
    return response.data;
  }
);

// export const fetchAsyncTripadvisorHotelDetails: any = createAsyncThunk(
//   'hotel_details/fetchAsyncTripadvisorHotelDetails',
//   async (id) => {
//     const response = await API.post(API_METHOD.Hotel.GET_TRIP_ADVISOR_HOTEL_DETAILS, [id]);
//     return response.data;
//   }
// );

const initialState: any = {
  hotelDetails: {},
  tripHotelDetails: {},
  hotelInfo: {},
};

const hotelDetailSlice = createSlice({
  name: "hoteldetails",
  initialState,
  reducers: {
    setHotelDetailsState: (state, { payload }) => {
      state.hotelDetails = payload;
    },
  },
  extraReducers: {
    [fetchAsyncHotelDetails.pending]: (state) => {
      state.tripHotelDetails = [];
      state.hotelInfo = {};
    },
    [fetchAsyncHotelDetails.fulfilled]: (state, { payload }) => {
      state.tripHotelDetails = payload.Tripadv.TripAdvisorCache;
      state.hotelInfo = payload.HotelInfo.Vendor;
    },
    [fetchAsyncHotelDetails.rejected]: (state) => {
      state.tripHotelDetails = [];
      state.hotelInfo = {};
    },
  },
});

export const { setHotelDetailsState } = hotelDetailSlice.actions;

export default hotelDetailSlice.reducer;
