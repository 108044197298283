import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomContainer } from '../../components/CustomContainer';
import { TabComponent } from '../../components/TabComponent';
import { Dashboardtabs } from '../../common/tab';
import { TabPanel } from '../../components/TabPanel';
import DashboardComponent from './components/DashBoardComponent';
import { colors } from '../../styles/colors';
import MyAccount from './components/MyAccount/MyAccount';
import TravelDocuments from './components/TravelDocuments/TravelDocuments';
import { useAppSelector } from '../../hooks/useAppReducer';
import PaymentTab from '../AccountAndSummary/components/PaymentTab';
import { setLoading } from '../../features/loading/loadingSlice';
import { branchCode } from '../../constants/branchCode';
import { useDispatch } from 'react-redux';
import { getBookingHistory } from '../../features/bookingHistory/bookingHistory';
import useWindowWidth from '../../hooks/useWindowWidth';

const Profile = () => {
  //! variables
  let { tab = 'dashboard' } = useParams();
  const navigate = useNavigate();
  const { userDetails } = useAppSelector((state) => state.userInformation);
  const dispatch = useDispatch();
  const { loading } = useAppSelector((state: any) => state.loading);
  const windowWidth = useWindowWidth();
  //! Hooks
  const [pastBookings, setPastBookings] = useState([]);
  const [upcomingBookings, setupcomingBookings] = useState([]);
  const [saveitItinerary, setItineraryBookings] = useState([]);
  const [currentTab, setCurrentTab] = useState(tab);

  //! Functions
  const handleChange = (newValue: string) => {
    navigate(`/profile/${newValue}`, { replace: true });
  };

  useEffect(() => {
    setCurrentTab(tab);
  }, [tab]);

  useEffect(() => {
    const fetchData = async () => {
      const body = [userDetails?.CustomerUser?.email, 1, branchCode, 1, 1];
      try {
        dispatch(setLoading(true));
        const response = await dispatch(getBookingHistory(body))
        const result = await response?.payload || {};

        if (Object.keys(result).length > 0) {
          const resultArray = Object.values(result);

          const filteredData = resultArray?.filter(
            (r: any) => r?.branch_code === branchCode
          );

          const saveItianaryData: any = filteredData.filter(
            (s: any) => s?.booking_status === "Quote"
          );
          setItineraryBookings(saveItianaryData);

          const bookingData = filteredData?.filter(
            (s: any) => s?.booking_status !== "Quote" && s?.booking_status !== "Cancelled"
          );
          console.log('bookingData :', bookingData);

          const today = new Date();
          today.setHours(0, 0, 0, 0);

          // Filter past bookings
          const filteredBookings: any = bookingData?.filter(
            (booking: any) => new Date(booking?.start_date_time) < today
          );
          setPastBookings(filteredBookings);

          const filteredBookingsUpcoming: any = bookingData?.filter(
            (booking: any) => new Date(booking?.start_date_time) > today
          );
          setupcomingBookings(filteredBookingsUpcoming);
          dispatch(setLoading(false));
        } else {
          dispatch(setLoading(false));
        }
      } catch (error) {
        console.error("Error:", error);
        dispatch(setLoading(false));
      }
    };

    fetchData();
  }, [userDetails?.CustomerUser?.email, dispatch]);

  return (
    <Box>
      <Box>
        <CustomContainer>
          <Box pt={{ xs: windowWidth < 376 ? '130px' : '70px', xl: "40px", lg: '40px', md: '30px', sm: '80px' }} pb={{ md: 5, xs: 2 }}>
            <Typography variant='h1' fontSize='28px' fontWeight={600}>
              Welcome {userDetails?.CustomerUser?.first_name}
            </Typography>
          </Box>
        </CustomContainer>
      </Box >
      <Box
        bgcolor={colors.grey[50]}
        sx={{
          mb: '50px',
          '& .MuiTabs-root': {
            bgcolor: colors.basics.transparent,

            '& .Mui-selected': {
              position: 'relative',
              borderRadius: '5px 5px 0px 0px',
            },

            '& button': {
              height: '44px',
              borderRadius: '5px 5px 0px 0px',

              '& p': {
                fontFamily: 'Raleway',
                fontSize: '16px',
                height: 'auto',
                fontWeight: 500,

                '&:after': {
                  position: 'absolute',
                  width: '100%',
                  height: '5px',
                  bgcolor: colors.basics.primary,
                },
              },
            },
          },
          '& MuiTabs-root': {
            paddingLeft: { sm: "8px", xs: '0' },
            paddingRight: { sm: "8px", xs: '0' },
          },
          '& .MuiTabs-root .MuiButtonBase-root': {
            paddingLeft: { sm: "39px", xs: '10px' },
            paddingRight: { sm: "39px", xs: '10px' },
          },
        }}
      >
        <CustomContainer>
          <TabComponent
            tabs={Dashboardtabs.map((item: string) => item.toLowerCase().replace(/ /g, ''))}
            labels={Dashboardtabs}
            value={currentTab}
            handleChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile={true}
          />
        </CustomContainer>
      </Box>
      <Box>
        <TabPanel currentTab={currentTab?.replace(/ /g, '')} tab='dashboard'>
          {currentTab === "dashboard" ? <DashboardComponent pastBookings={pastBookings} upcomingBookings={upcomingBookings} saveitItinerary={saveitItinerary} /> : <></>}
        </TabPanel>
        {/* <TabPanel currentTab={currentTab?.replace(/ /g, '')} tab='traveldocuments'>
          {currentTab === "traveldocuments" ? <TravelDocuments pastBookings={pastBookings} upcomingBookings={upcomingBookings} loading={loading} /> : <></>}
        </TabPanel> */}
        <TabPanel currentTab={currentTab?.replace(/ /g, '')} tab='myaccount'>
          {currentTab === "myaccount" ? <MyAccount /> : <></>}
        </TabPanel>
        <TabPanel currentTab={currentTab?.replace(/ /g, '')} tab='payment'>
          {currentTab === "payment" ? <PaymentTab upcomingBookings={upcomingBookings} loading={loading} /> : <></>}
        </TabPanel>
      </Box>
    </Box >
  );
};

export default Profile;
