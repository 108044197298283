import { colors } from '../styles/colors';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ReactComponent as TropicalSkyLogo } from '../assets/images/white-logo.svg';
import { ReactComponent as Pencil } from '../assets/icons/Pencil.svg';
import { ReactComponent as Facebook } from '../assets/icons/Facebook.svg';
import { ReactComponent as Twitter } from '../assets/icons/Twitter.svg';
import { ReactComponent as Youtube } from '../assets/icons/Youtube.svg';
import { ReactComponent as Instagram } from '../assets/icons/Instagram.svg';
import { ReactComponent as Pinterest } from '../assets/icons/Pinterest.svg';
import { ReactComponent as Email } from '../assets/icons/Email.svg';
import { Link } from 'react-router-dom';
import { CustomIconButton } from './CustomIconButton';
import { ReactComponent as DownChervon } from '../assets/icons/ArrowChervon.svg';
import Atol from '../assets/images/atol.png';
import Bbta from '../assets/images/bbta.png';
import Iata from '../assets/images/iata.png';
import Logo from '../assets/images/logo.png';
import Abtot from '../assets/images/abtot-logo.png';
import { CustomContainer } from './CustomContainer';
import { useSelector, useDispatch } from 'react-redux';
import { setDialogState } from '../features/dialog/dialogSlice';
import { useState } from 'react';
import { linkBaseUrl } from '../constants/menuItems';
import Image from './Image';

const socialIcons = [
  {
    icon: <Facebook />,
    path: 'https://www.facebook.com/TropicalSky',
  },
  {
    icon: <Twitter />,
    path: 'https://twitter.com/TropicalSky',
  },
  {
    icon: <Youtube />,
    path: 'https://www.youtube.com/user/TropicalSkyUK',
  },
  {
    icon: <Instagram />,
    path: 'https://www.instagram.com/tropicalsky/',
  },
  {
    icon: <Pinterest />,
    path: 'https://www.pinterest.co.uk/tropicalsky/',
  },
];


const links = [
  {
    linkName: 'About Us',
    path: `${linkBaseUrl}about-us`,
  },
  {
    linkName: 'Careers',
    path: `${linkBaseUrl}careers`,
  },
  {
    linkName: 'Privacy & Cookies Policy ',
    path: `${linkBaseUrl}privacy-policy`,
  },
  {
    linkName: 'Booking Conditions',
    path: `${linkBaseUrl}booking-conditions`,
  },
  {
    linkName: 'Contact Us',
    path: `${linkBaseUrl}contact-us`,
  },
  {
    linkName: 'Site Map',
    path: `${linkBaseUrl}site-map`,
  },
  {
    linkName: 'Hotels A-Z',
    path: `${linkBaseUrl}a-hotels`,
  },
]


const logoImagesUK = [
  {
    image: Atol,
    path: `${linkBaseUrl}memberships`,
  },
  {
    image: Bbta,
    path: `${linkBaseUrl}memberships`,
  },
  {
    image: Iata,
    path: `${linkBaseUrl}memberships`,
  },
  {
    image: Logo,
    path: `https://www.feefo.com/en-GB/reviews/tropical-sky?displayFeedbackType=SERVICE&timeFrame=ALL`,
  },
  {
    image: Abtot,
    path: `https://www.abtot.com/`,
  },
];

const logoImagesIE = [
  {
    image: linkBaseUrl + "/FrontEndGlobalAssets/dist/img/iata.png",
    path: `${linkBaseUrl}memberships`,
  },
  {
    image: linkBaseUrl + "/FrontEndGlobalAssets/dist/img/iaa.png",
    path: `${linkBaseUrl}memberships`,
  },
  {
    image: linkBaseUrl + "/FrontEndGlobalAssets/dist/img/itaa.png",
    path: `${linkBaseUrl}memberships`,
  },
  {
    image: linkBaseUrl + "/FrontEndGlobalAssets/dist/img/worldchoice.png",
    path: `${linkBaseUrl}memberships`,
  },
  {
    image: "https://api.feefo.com/api/logo?merchantidentifier=tropical-sky-ireland",
    path: "https://www.feefo.com/en-GB/reviews/tropical-sky-ireland?displayFeedbackType=SERVICE&amp;timeFrame=ALL",
  },
];

const logoUS = [
  {
    image: linkBaseUrl + "/FrontEndGlobalAssets/dist/img/arc-logo-sm.png",
    path: `${linkBaseUrl}memberships`,
  },
  {
    image: linkBaseUrl + "/FrontEndGlobalAssets/dist/img/iata.png",
    path: `${linkBaseUrl}memberships`,
  },
]

const Footer = () => {
  //! Hooks
  const [visible, setVisible] = useState(false)
  const dialogState = useSelector((state: any) => state.dialog.dialogState);
  const { selectedRegion } = useSelector((state: any) => state.commonState);
  const dispatch = useDispatch();
  //! Functions
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const toggleVisible = () => {
    const screenWidth = document.body.clientWidth;
    const scrolled = document.documentElement.scrollTop;
    if (screenWidth <= 425) {
      if (scrolled > 150) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    } else {
      // Screen width is greater than 425
      if (scrolled > 300) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }

  window.addEventListener('scroll', toggleVisible);

  return (
    <Box sx={{
      '& h1': {
        fontFamily: "El Messiri",
        fontWeight: 700
      },
      "& button .MuiTypography-root": {
        fontWeight: 500
      }

    }}>
      <Box
        role='complementary'
        sx={{
          backgroundColor: '#212529',
          color: colors.basics.white,
          fontWeight: '400',
          padding: { lg: '40px 20px', xs: "1.875rem" },
          mt: { md: '40px', xs: '20px ' }
          // pt: {
          //   md: '20px', xs: '0'
          // }
        }
        }
      >
        <CustomContainer>
          <Grid container>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <Box display={"flex"} margin={"auto"} maxWidth={"200px"} textAlign={{ lg: 'start', xs: 'center' }} p={{ xs: '0px 0px 10px 0px' }}>
                <Image style={{ width: "100%" }} src={process.env.REACT_APP_FOOTER_LOGO} />
              </Box>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              sm={6}
              xs={12}
              sx={{
                "& h3": {
                  fontSize: "18px !important"
                },
                mt: { lg: 0, xs: 0, md: 2 },
                textAlign: { lg: 'start', sm: 'start', xs: 'center' },
                padding: { lg: '0px 30px', sm: '0px 30px', xs: '30px 0px' },

                '& a': {
                  textDecoration: 'none',
                  '& h1': {
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  },
                },
              }}
            >
              <Typography variant='h1' color={colors.basics.white}>
                Talk to our friendly team
              </Typography>
              <Link to='/' >
                <Typography className={`${selectedRegion?.ClassName || process.env.REACT_APP_CONTACT_US_NO}`} variant='h1' color={colors.basics.primary} fontSize={{ lg: '36px', xs: '20px' }}>
                  {selectedRegion?.phone || process.env.REACT_APP_CONTACT_US_NO}
                </Typography>
              </Link>
              <Typography color={colors.basics.white} mb='15px' variant='h3'>
                Call us 9am-7pm Mon-Fri / 9am-5pm Sat-Sun
              </Typography>
              <Box
                sx={{
                  '& Button': {
                    bgcolor: '#28bf3f !important',
                    '&:hover': {
                      color: colors.basics.white,
                      '& svg': {
                        '& path': {
                          fill: colors.basics.white,
                        },
                      },
                    },
                    '&:hover , &:focus': {
                      bgcolor: '#24aa38 !important',

                      '& .footerIcon': {
                        bgcolor: '#28bf3f !important',
                      },
                    },

                    '& .FooterButton , & .footerIcon': {
                      bgcolor: colors.basics.transparent,
                    },
                    '& .FooterButton': {
                      flex: '0 0 85%',
                    },
                    '& .footerIcon': {
                      flex: '0 0 15%',
                    },
                  },
                }}
              >
                <CustomIconButton
                  onClick={() => dispatch(setDialogState({ ...dialogState, emailEnquiryDialog: true }))}
                  className='FooterButton'
                  IconClassName='footerIcon'
                  label={
                    <Box display='flex' alignItems='center' textAlign='center' p='3px 8px 3px 0px'>
                      <Box mr={1}>
                        <Email />
                      </Box>
                      <Box>
                        <Typography variant='body2' color={colors.basics.white} fontFamily='sans-serif'>
                          Email enquiry
                        </Typography>
                      </Box>
                    </Box>
                  }
                // onClick={() => console.log('')}
                />
              </Box>
            </Grid>
            <Grid
              item
              lg={3}
              xs={12}
              md={6}
              sm={6}
              sx={{
                borderLeft: { lg: '1px solid #939292', md: 'none' },
                borderRight: { lg: '1px solid #939292', md: 'none' },
                borderTop: { lg: 'none', sm: 'none', md: 'none', xs: '1px solid #939292' },
                borderBottom: { lg: 'none', sm: 'none', md: 'none', xs: '1px solid #939292' },
                padding: { lg: '0px 25px', md: '0px 30px', sm: '0px 30px', xs: '30px 0px' },
                mt: { lg: 0, xs: 0, md: 2 },
              }}
            >
              <Typography variant='h1' color={colors.basics.white} mb='8px'>
                Get exclusive holiday offers & deals delivered to your inbox
              </Typography>
              <Box
                sx={{
                  '& Button': {
                    bgcolor: colors.basics.primary,
                    '&:hover ': {
                      bgcolor: '#b10c32 !important',
                      '&:hover': {
                        color: colors.basics.white,
                        '& svg': {
                          '& path': {
                            fill: colors.basics.white,
                          },
                        },
                      },

                      '& .footerIcon': {
                        bgcolor: colors.basics.primary,
                      },
                    },

                    '& .FooterButton , & .footerIcon': {
                      bgcolor: 'transparent',
                    },
                    '& .FooterButton': {
                      flex: '0 0 85%',
                    },
                    '& .footerIcon': {
                      flex: '0 0 15%',
                    },
                  },
                }}
              >
                <CustomIconButton
                  className='FooterButton'
                  IconClassName='footerIcon'
                  label={
                    <Box display='flex' alignItems='center' p='3px 8px 3px 0px'>
                      <Box mr={1}>
                        <Pencil />
                      </Box>
                      <Box>
                        <Typography variant='body2' color={colors.basics.white} >
                          Sign up
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
              </Box>
            </Grid>
            <Grid
              item
              lg={3}
              md={12}
              sm={12}
              xs={12}
              sx={{
                padding: {
                  lg: '0px 0px 0px 30px',
                  sm: "10px 0px 0px 30px",
                  xs: '10px 0px 0px 30px',
                  md: '10px 0px 0px 30px',
                },
                textAlign: { lg: 'start', md: 'center', sm: 'center', xs: 'center' },
              }}
              mt={{ sm: "0", xs: "8px" }}
            >
              <Typography variant='h1' fontWeight={500} fontSize={{ lg: "20px", xs: "16px" }} color={colors.basics.white} mb={1}>
                Join us on social
              </Typography>
              <Box display='flex' justifyContent={{ md: 'normal', xs: 'center' }}>
                {socialIcons?.map((icon, i) => {
                  return (
                    <a href={icon?.path} rel='noreferrer' key={i} target='_blank'>
                      <Box
                        sx={{
                          fontSize: '23px',
                          mr: 2,
                          color: colors.basics.white,
                          '&:hover': {
                            color: colors.basics.primary,
                          },
                        }}
                      >
                        {icon.icon}
                      </Box>
                    </a>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </CustomContainer>
      </Box>

      {/* Back to Top */}
      {/* <Box>
        <Box
          bgcolor='#16191b'
          padding='16px'
          color={colors.basics.white}
          display='flex'
          textAlign='center'
          justifyContent='center'
          alignItems='center'
        > */}
      {
        visible && <Button
          onClick={scrollToTop}
          endIcon={<DownChervon />}
          variant='text'
          sx={{
            color: colors.basics.white,
            border: 'none',
            backgroundColor: '#16191b',
            padding: '3px',
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            minWidth: '40px',
            height: '40px',
            borderRadius: '50%',
            '&:hover': {
              color: colors.basics.black,
              backgroundColor: '#16191b',
            },
            '& span': {
              margin: '0'
            }
          }}
        >
        </Button>
      }
      {/* </Box>
      </Box> */}

      <Box p={{ lg: '20px' }}>
        <CustomContainer>
          <Box>
            <Box
              display='flex'
              flexWrap='wrap'
              p={{ lg: '8px 0px', xs: '20px 0px' }}
              sx={{
                '& a ': {
                  '&:last-child h6': {
                    borderRight: 'none',
                  },
                  '& h6': {
                    '&:hover': {
                      textDecoration: "underline !important"
                    },
                  },

                },
              }}
            >
              {links?.map((link, index) => (
                <a
                  rel='noreferrer'
                  href={link?.path}
                  target='_blank'
                  key={index}
                  style={{
                    padding: '0px 2px',
                  }}
                >
                  <Typography
                    variant='subtitle2'
                    fontWeight={400}
                    lineHeight='130%'
                    color='#303030'
                    fontSize='12px'
                    display='inline-block'
                    borderRight='1px solid #212529'
                    padding='0px 5px 0px 5px '
                    margin={0}
                  >
                    {link?.linkName}
                  </Typography>
                </a>
              ))}
            </Box>
            <Box p={{ lg: '15px 10px', xs: '0px 20px' }} textAlign={{ md: 'start', xs: 'center' }}>
              {(process.env.REACT_APP_PROJECT_COUNTRY === "ie" ? logoImagesIE : process.env.REACT_APP_PROJECT_COUNTRY === "us" ? logoUS : logoImagesUK)
                .map((img, i) => {
                  return (
                    <a
                      rel='noreferrer'
                      href={img?.path}
                      target='_blank'
                      key={i}
                      style={{
                        display: 'inline-block',
                        padding: '2px 4px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Image src={img?.image} alt='logo' />
                    </a>
                  );
                })}
            </Box>
            <Box p={{ xs: '15px 5px ', lg: '15px 5px 0px' }}>
              <Typography variant='subtitle2' fontSize='12px' color='#212529'>
                <strong>Your money is safe with Tropical Sky.</strong> For your financial security the air
                holiday packages shown are ATOL protected by the Civil Aviation Authority.Our ATOL number is
                ATOL 9759.
                <br /> Tropical House, Garland Road, East Grinstead, West Sussex. RH19 1NJ ©Tropical Sky Ltd.
                All Rights Reserved
              </Typography>
            </Box>
          </Box>
        </CustomContainer>
      </Box>
    </Box >
  );
};

export default Footer;
