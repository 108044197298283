export const API_METHOD = {
  Authentication: {
    GENERATE_TOKEN: `Authentication/generatetoken`,
  },
  Car: {
    CAR_CITIES: `getcities`,
    CAR_HIRE_AVAILABILITY: `getcarhireavailability`,
    CAR_HIRE_AVAILABILITY_RESULTS: `getcarhireavailabilityresults`,
    CAR_HIRE_AVAILABILITY_CRITERIA: `getcarhireavailabilitycriteria`,
    CAR_HIRE_DETAILS: `getcarhiredetail`,
  },
  Flight: {
    GET_AIRLINES: `getairlines`,
    GET_AIRPORTS: `getairports`,
    FLIGHTS_AVAILABILITY_SEARCH: `flightsavailabilitysearch`,
    FLIGHTS_PRICE_CHECK: `flightspricecheck`,
    FLIGHTS_PRICE_CHECK_BY_PARAMS: `flightspricecheckbyparams`,
    ADD_FLIGHT_TO_FOLDER: `addflighttofolder`,
  },
  Hotel: {
    HOTEL_AVAILABILITY: `hotelavailability`,
    HOTEL_AVAILABILITY_SEARCH_DXB: "HotelAvailabilitySearchDXB",
    HOTEL_AVAILABILITY_SEARCH_2_ROOMS_DXB: "HotelAvailabilitySearch2roomsDXB",
    HOTEL_AVAILABILITY_BY_SUPPLIER_ID_EXPEDIA_140:
      "HotelAvailabilitybySupplierIDExpedia140",
    HOTEL_AVAILABILITY_BY_SUPPLIER_ID_STUBA_125:
      "HotelAvailabilitybySupplierIDStuba125",
    HOTEL_AVAILABILITY_BY_VENDOR_IDS: "HotelAvailabilitybyVendorIDs",
    GET_HOTELS: `gethotelsindubai`,
    HOTEL_SEARCH_DETAILS: `hotelsearchdetails`,
    ADD_HOTEL_TO_FOLDER: `addhoteltofolder`,
    GET_HOTEL_DETAILS: `gethoteldetails`,
    GET_HOTEL_DETAILS_WITH_OFFERS: "gethoteldetailswithoffers",
  },
  Misc: {
    GET_ALL_DESTINATIONS: `getalldestinations`,
    LOGIN: "login",
    REGISTER: "register",
    CREATE_BASIC_FOLDER: `createbasicfolder`,
    CREATE_FULL_FOLDER: `createfullfolder`,
    CREATE_FULL_FOLDER_2: `createfullfolder2`,
    ADD_FLIGHT_AND_HOTEL_TO_FOLDER: `addflightandhoteltofolder`,
    GET_FOLDER_DETAILS_BASIC: "getfolderdetailsbasic",
    GET_FOLDER_DETAILS_FULL_RQ: "getfolderdetailsfullrq",
    GET_FOLDER_DETAILS: `getfolderdetails`,
    CONFIRM_ITINERARY: `confirmitinerary`,
    BOOKINGS_REPORT: `bookingsreport`,
    SEND_CONFIRMATION_EMAIL: `sendconfirmationemail`,
    USER_INFORMATION: "userinformation",
    USER_EMAIL_CHECK: "useremailcheck",
    RESET_PASSWORD: "resetpasswordvalidatesecretkey",
    GET_POSTAL_ADDRESS: "getpostaladdress",
    GET_POSTAL_ADDRESS_IE: "getieaddress",
    GET_PAYMENT_CONFIGURATION: "getpaymentconfiguration",
    GET_BOOKING_HISTORY: "getbookinghistory",
    UPDATE_USER_INFORMATION: "savecustomerdetails",
    GET_CMS_OFFERS: 'getcmsoffers',
    GET_VALIDATION_FAILURE_LOGS: "getvalidationfailurelogs"
  },
  Package: {
    HOLIDAY_PACKAGE_SEARCH: `holidaypackagesearch`,
    FLIGHT_HOTELS_LOAD_PACKAGES: "flightshotelsloadpackages",
    CHANGE_FLIGHTS: "changeflights",
    HOLIDAY_DETAIL: `holidaydetail`,
    HOLIDAY_CHANGE_FLIGHT: "holiday_change_flights",
    GET_TROPICALSKY_LOGS: "gettropicalskylogs",
    UPDATE_DEPOSIT_DUE: "updatedepositdue",
    UPDATE_SELL_PRICE: "updatesellprice",
    GET_TROPICAL_SKY_LOGS_2: "gettropicalskylogstype2",
    GET_PACKAGE_LOGS: "getpackagelogs"
  },
  Transfer: {
    GET_TRANSFER_COUNTRIES: "gettransfercountries",
    TRANSFER_POINTS: "transferpoints",
    TRANSFER_SEARCH: "transferssearch",
    TRANSFER_SEARCH_CRITERIA: "transfersearchcriteria",
    TRANSFER_SEARCH_RESULT: "transfersearchresults",
    TRANSFER_SEARCH_RESULT_DETAIL: "transferssearchresultdetail",
    ADD_TRANSFER_TO_FOLDER: "addtransfertofolder",
  },
  Basket: {
    SAVE_BASKET_TO_DB: "savebaskettodb",
    RETRIEVE_BASKET_FROM_DB: "retrieveBasketFromDB",
  },
  Email: {
    SEND_EMAIL: "sendEmail",
    SEND_RESET_PASSWORD_MAIL: `sendresetpasswordmail`,
    SEND_BOOKING_CONFIRMATION_EMAIL: "sendbookingconfirmationmailtemplate",
  },
  Others: {
    SAVE_HOLIDAY_SUMMARY: "saveholidaysummary",
    GET_HOLIDAY_SUMMARY: "getholidaysummary",
    GET_EMAIL_ITINERARY_STATUS: "getemailitinerarystatus",
    SAVE_EMAIL_ITINEARY_STATUS: "saveemailitinerarystatus",
    SAVE_NOT_FOUND_LOGS:"savenotfoundlogs",
    GET_NOT_FOUND_LOGS:"getnotfoundlogs"
  }
};
