import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Occasiondata, titlePreferences, childPreferences } from '../../constants/PersonalDetails';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { retrieveBasket } from '../../features/actions';
import { setPassengerState, userRegisterAPI } from '../../features/userAuthentication/userRegisterSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';
import { CustomContainer } from '../../components/CustomContainer';
import { HotelDetails } from '../HolidaySummary/Components/HotelDetails';
import { PricingCard } from '../../components/PricingCard';
import { StepperCard } from '../../components/StepperComponent';
import TextInput from '../../components/TextInput';
import { addHotelFlightDetailsToFolder } from '../../features/profileDetails/addHotelFlightDetailSlice';
import { colors } from '../../styles/colors';
import { generatePassword } from '../../helpers/password';
import { getFolderDetails } from '../../features/profileDetails/getFolderSlice';
import { postUserFolderDetails } from '../../features/profileDetails/createFolderSlice';
import { setLoading } from '../../features/loading/loadingSlice';
import { setStepperState } from '../../features/stepper/stepperSlice';
import { useAppSelector } from '../../hooks/useAppReducer';
import { useDispatch, useSelector } from 'react-redux';
import { validationSchema, validationSchemaPostalAddress } from './ValidationSchema';
import { isEmpty } from '../../helpers/isEmpty';
import { onlyNumber } from '../../helpers/onlyNumber';
import { getPostalAddress } from '../../features/postalAddress/postalAddress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
import { getFolder } from '../../features/getFolder/getFolder';
import RoomAllocation from '../../components/RoomAllocation';
import { branchCode } from '../../constants/branchCode';
import { checkUserEmailIsExist } from '../../features/actions';
import { setFlightRoomState } from '../../features/flightRoom/flightRoomSlice';
import { setHolidayDetailsState } from '../../features/holidaySummary/holidaySummarySlice';
import {
  fetchAsyncHolidayPackages,
  setFilteredHolidayPackage,
  setHolidayPackage,
} from '../../features/holidayPackage/holidayPackageSlice';
import { DestinationList } from '../../constants/Destinations';
import { countriesWithCountryCode } from '../../constants/countries';
import { formatTime } from '../../helpers/formatTime';
import {
  flightTime,
  getFlightTimesObjectHolidayPackage,
  singleFlightTravelTime,
} from '../../helpers/TotalFlightTime';
import dayjs from 'dayjs';
import { roundPrice } from '../../helpers/RoundPrice';
import { directFlights, directFlightsIE } from '../../constants/directFlights';
import { IE_DEPARTURE, UK_DEPARTURE, US_DEPARTURE } from '../../constants/departure';
import { DateObject } from 'react-multi-date-picker';
import { fetchAsyncHolidayPackageDetails } from '../../features/holidayPackage/packageDetail';
import { capitalizeFirstLetterEachWords } from '../../helpers/capitalizeFirstLetterEachWords';
import CustomSelectInput from '../../components/CustomSelectInput';
import {
  clearSessionValues,
  getSessionStorage,
  removeSessionStorage,
  setSessionStorage,
} from '../../helpers/storage';
import { removeSessionElements } from '../../common/removeSessionElements';
import useWindowWidth from '../../hooks/useWindowWidth';
import { sortRoom } from '../../helpers/sortRoom';
import { setLottieLoading } from '../../features/lottieLoader/lottieLoader';
import { RoomMealWrapper } from '../../helpers/roomMealWrapper';
import { setFilterState } from '../../features/filter/filterSlice';
import Swal from 'sweetalert2';
import { calculatePerPersonPrice } from '../../helpers/calculatePerPersonPrice';
import { calculateTotalPackagePrice } from '../../helpers/calculateTotalPackagePrice';
import { isDiscountValidDate } from '../../helpers/isDiscountValidDate';
import { formatPrice } from '../../helpers/formatPrice';

var store = require('store');
interface FormDatum {
  title?: string;
  fname?: string;
  mname?: string;
  sname?: string;
  pnumber?: string;
  email?: string;
  day?: number | null;
  month?: number | null;
  year?: number | null;
  id?: number;
  gender?: string;
  pax_type?: string;
  isDateValid?: any;
  isLoggedIn: boolean;
  arrayIndex: number;
}

interface CABIN_CLASS_ENUM_TYPE {
  Any: string;
  Economy: string;
  Premium: string;
  'Business Class': string;
  'First Class': string;
}

const CABIN_CLASS_ENUM: CABIN_CLASS_ENUM_TYPE = {
  Any: '',
  Economy: 'M',
  Premium: 'W',
  'Business Class': 'C',
  'First Class': 'F',
};

type BillingOption = {
  value: string;
  postcode?: string;
};

type CabinClass = keyof typeof CABIN_CLASS_ENUM;

const PersonalDetail = () => {
  //! States
  const stepperState: number = useAppSelector((state: any) => state.stepper.stepper);
  const { availability } = useAppSelector((state) => state.carHire);
  const { filters } = useAppSelector((state: any) => state.filters);
  const { flightRoom } = useAppSelector((state: any) => state.flightRoom);
  const { holidayPackage } = useAppSelector((state) => state.holidayPackage);
  const { holidayDetials } = useSelector((state: any) => state.holidayDetailsState);
  const holidayDetailsResults = holidayDetials?.map((detail: any) => detail.success === 1);

  const {
    flightRoom: {
      searchCriteria: { guest, destination, adults, childrens, infants },
      selectedFlights,
      search_result_id,
      // selectedTransfer,
      multiRooms,
    },
  } = useAppSelector((state: any) => state.flightRoom);
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  const { loading } = useAppSelector((state: any) => state.loading);
  const { userDetails } = useAppSelector((state: any) => state.userInformation);
  const { session_id }: any = useParams();
  const windowWidth = useWindowWidth();
  const hotelDetails = holidayPackage?.Packages?.results?.filter(
    (f: any) => f?.SearchResult?.hotel_id === flightRoom?.hotel_id
  );
  const isPlataniumHotel =
    hotelDetails?.length > 0 ? Number(hotelDetails[0]?.HotelInfo?.platinumHotel) !== 0 : false;
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL || '';
  // eslint-disable-next-line
  const [scrollToError, setScrollToError] = useState(false);
  const [preventScroll, setPreventScroll] = useState(true);
  const [formData, setFormData] = React.useState<FormDatum[]>([]);
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [comments, setComments] = useState('');
  const [basketId, setBasketId] = useState(getSessionStorage('basketId'));
  const [address, setAddress] = React.useState<any>({
    manually: { postcode: '', street: '', city: '', county: '', house: '', country: '' },
    searching: { postcode: '', street: '', city: '', county: '', house: '', country: '' },
    index: -1,
  });
  const [billingAddess, setBillingAddress] = React.useState<any>({
    storeManualBillingData: { postcode: '', street: '', city: '', county: '', house: '', country: '' },
    storeSearchingBillingData: { postcode: '', street: '', city: '', county: '', house: '', country: '' },
    manually: { postcode: '', street: '', city: '', county: '', house: '', country: '' },
    searching: { postcode: '', street: '', city: '', county: '', house: '', country: '' },
    index: -1,
  });
  const [travelPurpose, setTravelPurpose] = React.useState('Holiday');
  const [timer, setTimer] = useState<any>(null);
  const [options, setOptions] = useState({
    address: [],
    billingAddess: [],
  });
  const [submitForm, setSubmitForm] = useState(false);
  const [validation, setValidation] = useState(false);
  const [isShownAddress, setisShownAddress] = useState({
    addressManually: false,
    hideBillingAddress: true,
    hidePostalAddress: true,
    isPostalAddress: true,
    billingAddressManually: false,
    showBillingAddress: false,
  });
  const [emailTextCheckbox, setEmailTextCheckbox] = useState(false);
  const [isFirstTimeGetFolderCall, setIsFirstTimeGetFolderCall] = useState(
    flightRoom.packageSearchId !== '' ? true : false
  );
  const [childAndInfantAges, setChildAndInfantAges] = useState<any>({
    childAgeValids: {},
    infantAgeValid: {},
    childAges: [],
    infantAges: [],
  });
  const [addressBillingErrors, setAddressBillingErrors] = useState<any>({
    manuallyAddressErrors: {
      postcode: '',
      street: '',
      city: '',
      country: '',
      house: '',
    },
    searchingAddressErrors: {
      postcode: '',
      street: '',
      city: '',
      country: '',
      house: '',
    },
    manuallyBillingErrors: {
      postcode: '',
      street: '',
      city: '',
      country: '',
      house: '',
    },
    searchingBillingErrors: {
      postcode: '',
      street: '',
      city: '',
      country: '',
      house: '',
    },
    postalCodeError: '',
    billingPostcodeError: '',
    showBasicErrors: false,
    errors: {
      postcode: 'Postcode is required',
      street: 'Street is required',
      city: 'City is required',
      country: 'Country is required',
      house: 'House number is required',
    },
  });
  const initialSelectedOptions = flightRoom.searchCriteria.guest.map(() => ({
    adults: [],
    children: [],
    infants: [],
  }));
  const [selectedOptions, setSelectedOptions] = useState([...initialSelectedOptions]);
  const [roomErrorsArray, setRoomArray] = useState([
    ...selectedOptions.map(() => ({
      adults: [],
      children: [],
      infants: [],
    })),
  ]);
  const [isDataLoaded, setIsDataLoaded] = useState(true);
  const [isFolderValues, setIsFolderValues] = useState({
    email: '',
    phoneNumber: '',
  });
  const [timeLeft, setTimeLeft] = useState(15 * 60);
  const [isTimeExpired, setIsTimeExpired] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoginUser = Object.keys(userDetails).length > 0;
  const loginData = userDetails?.CustomerUser || {};

  const totalGuests = flightRoom.searchCriteria.adults + flightRoom.searchCriteria.childrens;

  let staticObj = {
    postcode: '',
    street: '',
    city: '',
    county: '',
    house: '',
    country: '',
  };

  const ArrivalLocation =
    flightRoom?.searchCriteria?.destination &&
    flightRoom?.searchCriteria?.destination['Display Name #1']?.split(', ')[0];

  const passengersInfoArray = flightRoom?.searchCriteria.guest?.map((guest: any) => {
    const adults = guest.adults > 0 ? `${guest.adults} ${guest.adults === 1 ? 'Adult' : 'Adults'}` : '';
    const children =
      guest.children.length > 0
        ? `${guest.children.length} ${guest.children.length === 1 ? 'Children' : 'Childrens'}`
        : '';
    const infants =
      guest.infants.length > 0
        ? `${guest.infants.length} ${guest.infants.length === 1 ? 'Infant' : 'Infants'}`
        : '';
    const parts = [adults, children, infants].filter(Boolean);
    return `${parts?.join(', ')?.replace(/,([^,]*)$/, ' and$1')}`;
  });

  const roomArray = holiday?.room?.map((room: any, index: any) => ({
    meal_name: RoomMealWrapper(room?.meal_name),
    passangers_info: passengersInfoArray[index],
    room_name: room?.room_name,
  }));

  const selectedValuesAddressOption = useMemo(() => {
    if (options.address.length > 0) {
      if (address.index !== -1) {
        setAddress({
          ...address,
          searching: {
            ...address.searching,
            postcode: (options.address[Number(address.index)] as any).postcode,
          },
        });
        return options.address[Number(address.index)];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [options.address, address.index]);

  const selectedValuesBillingOption: BillingOption | null = useMemo(() => {
    if (options.billingAddess.length > 0) {
      const selectedBilling = options.billingAddess[Number(billingAddess.index)] as BillingOption | undefined;

      if (selectedBilling) {
        setBillingAddress({
          ...billingAddess,
          searching: {
            ...billingAddess.searching,
            postcode: selectedBilling.postcode,
          },
        });
        return selectedBilling;
      }
    }
    return null;
  }, [options.billingAddess, billingAddess.index]);

  const queryData = useMemo(() => {
    const search = window.location.search;
    const urlSearchParams = new URLSearchParams(search);
    const queryData: any = {};
    urlSearchParams.forEach((value, key) => {
      queryData[key] = value;
    });
    return queryData;
  }, []);

  const isFolderAlreadyAdded =
    queryData?.folder === 'null' ? false : window.location.search.includes('folder');
  const flightPrice: any = useMemo(() => {
    let price: any = 0;
    const flightPrice = getSessionStorage('stepper') === '0' ? '0' : flightRoom.diffPrice || '0';
    if (getSessionStorage('nextAndSelectedFlights') === 'false') {
      price = Math.ceil(flightPrice);
    } else {
      if (flightPrice.includes('-')) {
        price = Math.floor(flightPrice);
      } else {
        price = Math.ceil(flightPrice);
      }
    }
    return price;
  }, [flightRoom.diffPrice, getSessionStorage('stepper'), getSessionStorage('nextAndSelectedFlights')]);

  const packagePricePerPerson: any = useMemo(() => {
    let price = 0;
    if (flightRoom.multiRooms.length > 0) {
      flightRoom.multiRooms.map((f: any) => {
        price = price + Number(f?.total_package);
        return <></>;
      });
    }

    // const totalPrice = (price + totalTransfer) / totalGuests
    const totalPrice = price / totalGuests;

    return String(totalPrice);
  }, [flightRoom.multiRooms, totalGuests]);

  const totalPrice = useMemo(() => {
    return Number(roundPrice(String(packagePricePerPerson))) * totalGuests;
  }, [packagePricePerPerson, totalGuests]);
  const tPrice = roundPrice(String(calculatePerPersonPrice(packagePricePerPerson, totalGuests)));
  const discountTotalPrice = calculateTotalPackagePrice(totalPrice, totalGuests);
  const discountPerPersonPrice = formatPrice(calculatePerPersonPrice(roundPrice(packagePricePerPerson), totalGuests));

  // useEffect(() => {
  //   let times = timeLeft
  //   const intervalId = setInterval(() => {
  //     if (times <= 1) {
  //       clearInterval(intervalId);
  //       setIsTimeExpired(true);
  //       return Swal.fire({
  //         title: "Please refresh your search for the latest prices",
  //         text: 'Flight price changes frequently due to availabilty and demand. We want to make sure you always see the best prices, guaranteed',
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
  //         cancelButtonColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
  //         confirmButtonText: 'New Search',
  //         cancelButtonText: "Reload",
  //         allowOutsideClick: false,
  //         allowEscapeKey: false,
  //         customClass: {
  //           confirmButton: 'confirmButton',
  //           actions: 'actions',
  //           icon: isPlataniumHotel ? "platinum-icon-class" : "primary-icon-class",
  //         },
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           newSearch()
  //         } else {
  //           window.location.reload()
  //         }
  //       });
  //     }
  //     times = times - 1
  //     setTimeLeft(times)
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, [isPlataniumHotel]);

  useEffect(() => {
    if (isTimeExpired) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isTimeExpired]);

  useEffect(() => {
    if (getSessionStorage('holidayPageLoad') === 'false' || getSessionStorage('holidayPageLoad') === null) {
      dispatch(
        setFlightRoomState({
          ...flightRoom,
          callHolidaySummaryApi: true,
        })
      );
    } else {
      dispatch(
        setFlightRoomState({
          ...flightRoom,
          callHolidaySummaryApi: false,
        })
      );
    }

    if (Object.keys(holidayPackage).length === 0) {
      setIsDataLoaded(false);
    }
  }, []);

  useEffect(() => {
    let timer: any;
    const startTimer = () => {
      timer = setTimeout(() => {
        window.location.reload();
      }, 15 * 60 * 1000);
    };
    startTimer();
    return () => {
      clearTimeout(timer);
    };
  }, []);

  // useEffect(() => {
  //   if (getSessionStorage("apiCall") === "false" && getSessionStorage("mapApiCall") === "false" && window.location.search.includes("s=b")) {
  //     dispatch(setLottieLoading(true))
  //     setIsDataLoaded(false)
  //     renderOnLoadHolidayPackageApi(queryData)
  //   }
  // }, [getSessionStorage("apiCall"), getSessionStorage("mapApiCall")])

  useEffect(() => {
    const search = window.location.search;
    if (
      (search.includes('departure') || search.includes('destination')) &&
      getSessionStorage('holidayRetrieveApiCall') === 'false' &&
      (queryData.hotelId || queryData.hotel_id)
    ) {
      setSessionStorage('holidayRetrieveApiCall', 'true');
      if (Object.keys(holidayPackage).length === 0) {
        dispatch(setLottieLoading(true));
        setIsDataLoaded(false);
        removeSessionElements();
        renderOnLoadHolidayPackageApi(queryData);
      }
    }
  }, [queryData]);

  useEffect(() => {
    setSelectedOptions([...initialSelectedOptions]);
  }, [flightRoom.searchCriteria.guest]);

  useEffect(() => {
    stepperState !== 2 && dispatch(setStepperState(2));
    const baseObj: FormDatum = {
      title: '',
      fname: '',
      mname: '',
      sname: '',
      gender: 'M',
      pnumber: '',
      email: '',
      day: null,
      month: null,
      year: null,
      isDateValid: false,
      isLoggedIn: false,
      arrayIndex: 0,
    };

    let arr = [...Array(adults)].map((el, i) => {
      return {
        ...baseObj,
        pax_type: 'ADT',
        id: i + 1,
      };
    });
    const childArr = [...Array(childrens)].map((el, i) => {
      return {
        ...baseObj,
        pax_type: 'CHD',
        id: i + 1,
      };
    });

    const infantsArr = [...Array(infants)].map((el, i) => {
      return {
        ...baseObj,
        pax_type: 'INF',
        id: i + 1,
      };
    });

    if (Object.keys(userDetails).length > 0) {
      const newFirstValue = {
        day: null,
        email: userDetails?.CustomerUser?.email,
        fname: userDetails?.CustomerUser?.first_name,
        gender: 'M',
        id: 1,
        isDateValid: false,
        mname: '',
        month: null,
        pax_type: 'ADT',
        pnumber: userDetails?.CustomerUser?.contact_person,
        sname: userDetails?.CustomerUser.name ?? '',
        title: userDetails?.CustomerUser?.title,
        year: null,
        isLoggedIn: false,
        arrayIndex: 1,
      };

      setisShownAddress({
        ...isShownAddress,
        addressManually: true,
        hidePostalAddress: false,
      });

      setAddress({
        ...address,
        manually: {
          postcode: userDetails?.CustomerUser?.post_code,
          street: userDetails?.CustomerUser?.address2,
          city: userDetails?.CustomerUser?.city_name,
          county: '',
          house: userDetails?.CustomerUser?.address1,
        },
      });

      arr[0] = newFirstValue;
    }

    const ageArray = [...arr, ...childArr, ...infantsArr];

    const childAges: any = guest?.map((guest: any) =>
      guest.children.map((child: any) => parseInt(child.age))
    );

    const mergedAges = [].concat(...childAges);

    const infantAges: any = guest?.map((guest: any) =>
      guest.infants.map((child: any) => parseInt(child.age))
    );

    const mergedInfantAges = [].concat(...infantAges);

    setChildAndInfantAges({
      ...childAndInfantAges,
      childAges: mergedAges,
      infantAges: mergedInfantAges,
    });
    ageArray.map((f, i) => (f.arrayIndex = i + 1));
    setFormData([...ageArray]);
  }, [adults, childrens, dispatch, guest, infants, stepperState]);

  useEffect(() => {
    if (getSessionStorage('basketId')) {
      setBasketId(getSessionStorage('basketId'));
    } else if (window.location.search.includes('?email')) {
      setBasketId(window.location.search.replace('?email=', ''));
    } else {
      setBasketId(null);
    }
  }, [getSessionStorage('basketId'), window.location.search]);

  useEffect(() => {
    if (
      isFolderAlreadyAdded &&
      formData.length > 0 &&
      flightRoom.packageSearchId !== '' &&
      isFirstTimeGetFolderCall
    ) {
      setFolderAddress();
    }
  }, [
    isFolderAlreadyAdded,
    flightRoom.packageSearchId,
    getSessionStorage('folderApiCall'),
    isFirstTimeGetFolderCall,
    formData,
    queryData,
  ]);

  useEffect(() => {
    const getPassengerInfo = getSessionStorage('passenger-info') || null;
    const passengerInfo = getPassengerInfo !== null ? JSON.parse(getPassengerInfo) : null;
    if (passengerInfo !== null) {
      if (passengerInfo.length > 0) {
        setIsFolderValues({
          email: passengerInfo[0].email,
          phoneNumber: passengerInfo[0].pnumber.length < 11 ? '' : passengerInfo[0].pnumber,
        });
      }
      setFormData(passengerInfo);
    }

    const getPassengerAddress = getSessionStorage('passenger-address') || null;
    const passengerAddress = getPassengerAddress !== null ? JSON.parse(getPassengerAddress) : null;
    if (passengerAddress !== null) {
      setAddress(passengerAddress);
    }

    const getPassengerBillingAddress = getSessionStorage('passenger-billing-info') || null;
    const passengerBillingAddressInfo =
      getPassengerBillingAddress !== null ? JSON.parse(getPassengerBillingAddress) : null;
    if (passengerBillingAddressInfo !== null) {
      setBillingAddress(passengerBillingAddressInfo);
    }

    const getInstruction = getSessionStorage('instruction') || null;
    const passengerInstruction = getInstruction !== null ? JSON.parse(getInstruction) : null;
    if (passengerInstruction !== null) {
      setTravelPurpose(passengerInstruction.travelPurpose);
      setComments(passengerInstruction.comments);
    }

    const getPassengerInfoHidden = getSessionStorage('passenger-info-hidden') || null;
    const passengerInfoHidden = getPassengerInfoHidden !== null ? JSON.parse(getPassengerInfoHidden) : null;
    if (passengerInfoHidden !== null) {
      setisShownAddress(passengerInfoHidden);
    }
  }, []);

  useEffect(() => {
    setSessionStorage('passenger-info', JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    setSessionStorage('passenger-address', JSON.stringify(address));
  }, [address]);

  useEffect(() => {
    setSessionStorage('passenger-billing-info', JSON.stringify(billingAddess));
  }, [billingAddess]);

  useEffect(() => {
    let obj = {
      travelPurpose: travelPurpose,
      comments: comments,
    };
    setSessionStorage('instruction', JSON.stringify(obj));
  }, [travelPurpose, comments]);

  useEffect(() => {
    setSessionStorage('passenger-info-hidden', JSON.stringify(isShownAddress));
  }, [isShownAddress]);

  useEffect(() => {
    const hotel_id = queryData.hotelId || queryData.hotel_id || null;
    if (
      basketId &&
      flightRoom.psw_result_id === null &&
      getSessionStorage('more-details-click') === 'false' &&
      !hotel_id
    ) {
      dispatch(setLottieLoading(true));
      setIsDataLoaded(false);
      retrieveDB();
    }
  }, [basketId, getSessionStorage('more-details-click'), flightRoom.psw_result_id]);

  useEffect(() => {
    if (!preventScroll) {
      const childAgeValid: any = childAndInfantAges.childAgeValids;
      const infantAgeValid: any = childAndInfantAges.infantAgeValid;
      const errorKeys = sortingError(formErrors);

      const firstErrorKey = errorKeys[0];
      let formIndex: any = extractFormIndex(firstErrorKey);
      let containsFalseValue = false;

      for (const key in childAgeValid) {
        for (const innerKey in childAgeValid[key]) {
          if (childAgeValid[key][innerKey] === false) {
            containsFalseValue = true;
            break;
          }
        }
        if (containsFalseValue) {
          break;
        }
      }

      if (Object.keys(childAgeValid).length > 0) {
        Object.values(childAgeValid).map((v, i) => {
          let firstKeyWithFalseValue = null;
          let index = 0;

          for (const key in childAgeValid) {
            if (Object.values(childAgeValid[key])[0] === false) {
              firstKeyWithFalseValue = childAgeValid[key];
              index = childAgeValid[key].index;
              break;
            }
          }

          if (
            (formIndex === null && index !== 0) ||
            (firstKeyWithFalseValue !== null && formIndex >= index)
          ) {
            scrollToFormWithIndex(index);
          } else {
            scrollToFormWithIndex(formIndex);
          }
          return <></>;
        });
      }

      if (!containsFalseValue && Object.keys(infantAgeValid).length > 0) {
        Object.values(infantAgeValid).map((v, i) => {
          let firstKeyWithFalseValue = null;
          let index = 0;

          for (const key in infantAgeValid) {
            if (Object.values(infantAgeValid[key])[0] === false) {
              firstKeyWithFalseValue = infantAgeValid[key];
              index = infantAgeValid[key].index;
              break;
            }
          }

          if (
            (formIndex === null && index !== 0) ||
            (firstKeyWithFalseValue !== null && formIndex >= index)
          ) {
            scrollToFormWithIndex(index);
          } else {
            scrollToFormWithIndex(formIndex);
          }
          return <></>;
        });
      }

      if (Object.keys(childAgeValid).length === 0 && Object.keys(infantAgeValid).length === 0) {
        scrollToFormWithIndex(formIndex);
      }
      setPreventScroll(true);
    }
  }, [
    formErrors,
    submitForm,
    scrollToError,
    addressBillingErrors,
    childAndInfantAges.childAgeValids,
    childAndInfantAges.infantAgeValid,
    preventScroll,
  ]);

  const convertDateStringToDate = (dateString: any) => {
    const dateObject = new Date(dateString);
    return dateObject;
  };

  const calculateAge = (day: any, month: any, year: any, checkOut?: any) => {
    if (isEmpty(day) !== '' && isEmpty(month) !== '' && isEmpty(year) !== '') {
      const currentDate = new Date();
      const birthDate = new Date(year, month - 1, day);
      const cDate = convertDateStringToDate(checkOut);

      let date = checkOut ? cDate : currentDate;

      let years = date.getFullYear() - birthDate.getFullYear();
      let months = date.getMonth() - birthDate.getMonth();
      let days = date.getDate() - birthDate.getDate();

      if (days < 0) {
        months -= 1;
        days += new Date(date.getFullYear(), date.getMonth(), 0).getDate();
      }
      if (months < 0) {
        years -= 1;
        months += 12;
      }
      return years;
    } else {
      return null;
    }
  };
  // const calculateAge = (day: any, month: any, year: any, checkOut?: any) => {
  //   if (isEmpty(day) !== "" && isEmpty(month) !== "" && isEmpty(year) !== "") {
  //     const currentDate = new Date();
  //     const birthDate = new Date(year, month - 1, day);
  //     const cDate = convertDateStringToDate(checkOut)
  //     let ageInYears = (checkOut ? cDate?.getFullYear() : currentDate.getFullYear()) - birthDate.getFullYear();
  //     if (
  //       currentDate.getMonth() < birthDate.getMonth() ||
  //       (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
  //     ) {
  //       ageInYears--;
  //     }

  //     return ageInYears
  //   } else {
  //     return null
  //   }
  // };

  const setFolderAddress = async () => {
    if (getSessionStorage('folderApiCall') !== 'true') {
      setSessionStorage('folderApiCall', 'true');
      dispatch(setLoading(true));
      const payload = await getFolderApiCall(queryData.folder, queryData.itinerary_number);
      if (payload) {
        const passengerInfo = payload?.pax || [];
        let customerInfo = payload?.Customer || {};
        const folderDetails = payload?.folderDetails?.Folder || {};
        const passengerData = payload?.pagedata || [];
        const filterHotelType = passengerData.filter((p: any) => p.Segment.fi_type === 'HOT');
        const filterRoomPassengerNames = filterHotelType.length > 0 ? filterHotelType.reverse() : [];

        // set multiple rooms data
        const selectedRoomData = [...selectedOptions];
        filterRoomPassengerNames.forEach((f: any, index: number) => {
          const paxID =
            f?.Segment?.linked_passengers !== null ? f?.Segment?.linked_passengers?.split(',') : [];
          const removeDuplicatepaxId = new Set(paxID);

          removeDuplicatepaxId.forEach((p) => {
            const singlePaxData = passengerInfo?.filter((pInfo: any) => p === pInfo?.Passenger?.pax_no);

            if (singlePaxData?.length > 0) {
              const passengerData = singlePaxData[0]?.Passenger;
              if (p === passengerData?.pax_no) {
                if (passengerData?.pax_type === 'ADT') {
                  selectedRoomData[index]?.adults?.push({
                    id: p,
                    name: passengerData?.first_name + ' ' + passengerData?.last_name,
                  });
                } else if (passengerData?.pax_type === 'CHD') {
                  selectedRoomData[index]?.children?.push({
                    id: p,
                    name: passengerData?.first_name + ' ' + passengerData?.last_name,
                  });
                } else {
                  selectedRoomData[index]?.infants?.push({
                    id: p,
                    name: passengerData?.first_name + ' ' + passengerData?.last_name,
                  });
                }
              }
            }
          });
        });

        setSelectedOptions(selectedRoomData);
        if (folderDetails) {
          setTravelPurpose(folderDetails?.travel_purpose || travelPurpose);
          setComments('');
        }

        // if (customerInfo) {
        //   const add = customerInfo.address1 ? customerInfo.address1.split(",") : []
        //   if (add.length > 0) {
        //     setAddress({
        //       ...address,
        //       manually: {
        //         postcode: add.length > 3 ? add[3]?.trim() : "",
        //         street: add.length > 0 ? add[0]?.trim() : "",
        //         city: add.length > 1 ? add[1]?.trim() : "",
        //         country: add.length > 2 ? add[2]?.trim() : "",
        //         house: add.length > 3 ? add[4]?.trim() : "",
        //       },
        //     })

        //     setisShownAddress({
        //       ...isShownAddress,
        //       addressManually: true,
        //       hidePostalAddress: false
        //     })
        //   }
        // }
        const getUniquePassengers = (passengers: any) => {
          return passengers?.reduce((uniquePassengers: any, current: any) => {
            const { Passenger } = current;
            if (Passenger.api_gender === null) {
              return uniquePassengers;
            }
            if (
              !uniquePassengers?.some(
                (u: any) =>
                  u?.Passenger?.first_name === Passenger?.first_name &&
                  u?.Passenger?.title === Passenger?.title &&
                  u?.Passenger?.age === Passenger?.age &&
                  u?.Passenger?.last_name === Passenger?.last_name &&
                  u?.Passenger?.birth_date === Passenger?.birth_date &&
                  u?.Passenger?.api_gender === Passenger?.api_gender &&
                  u?.Passenger?.title === Passenger?.title &&
                  u?.Passenger?.pax_type === Passenger?.pax_type
              )
            ) {
              uniquePassengers.push(current);
            }

            return uniquePassengers;
          }, []);
        };
        const uniquePassengerArray = getUniquePassengers(passengerInfo);
        if (uniquePassengerArray?.length > 0) {
          let passengerArr = [...formData];
          uniquePassengerArray?.map((p: any, i: number) => {
            const obj = p?.Passenger;
            const getCustomerInfo = customerInfo ? customerInfo?.CustomerNumbers : [];
            let birthDate = obj?.birth_date ? obj?.birth_date?.split('-') : [];
            passengerArr[i].title = isEmpty(obj?.title);
            passengerArr[i].fname = obj?.first_name;
            passengerArr[i].mname = obj?.api_middle_name;
            passengerArr[i].sname = obj?.last_name;
            passengerArr[i].gender = obj?.api_gender;
            passengerArr[i].pnumber = getCustomerInfo?.length > 1 ? getCustomerInfo[1]?.contact_info : '';
            passengerArr[i].email = getCustomerInfo?.length > 0 ? getCustomerInfo[0]?.contact_info : '';
            passengerArr[i].day = birthDate?.length > 0 ? birthDate[2] : null;
            passengerArr[i].month = birthDate?.length > 0 ? birthDate[1] : null;
            passengerArr[i].year = birthDate?.length > 0 ? birthDate[0] : null;
            passengerArr[i].isDateValid = false;
            passengerArr[i].arrayIndex = i + 1;

            if (i === 0) {
              setIsFolderValues({
                email: getCustomerInfo.length > 0 ? getCustomerInfo[0]?.contact_info : '',
                phoneNumber: getCustomerInfo.length > 1 ? getCustomerInfo[1].contact_info : '',
              });
            }
            return <></>;
          });

          setFormData(passengerArr);
        }
      }
      await dispatch(setLoading(false));
    }
  };

  const dateDiffInMonths = (a: any, b: any) => {
    const monthsDiff = (b.getFullYear() - a.getFullYear()) * 12 + (b.getMonth() - a.getMonth());
    if (b.getDate() < a.getDate()) {
      return monthsDiff - 1;
    }
    return monthsDiff;
  };

  const calculateInfantAge = (day: any, month: any, year: any, checkOutDate?: string) => {
    if (isEmpty(day) !== '' && isEmpty(month) !== '' && isEmpty(year) !== '' && year.length === 4) {
      const date1 = new Date(year, month - 1, day);
      const date2 = checkOutDate ? new Date(checkOutDate) : new Date();

      const diffInMonths = dateDiffInMonths(date1, date2);
      if (diffInMonths >= 0 && diffInMonths <= 24 && date1 < date2) {
        return diffInMonths;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const updateSellPrice = async (folder_no: any, itineararyNumber: any) => {
    const body = [
      {
        folderNumber: folder_no,
        itineraryNumber: itineararyNumber,
        new_sell_price: flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountUK && process.env.REACT_APP_PROJECT_COUNTRY === "uk" && isDiscountValidDate()
          ? discountTotalPrice?.finalPriceDiscount : flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountIE && process.env.REACT_APP_PROJECT_COUNTRY === "ie" && isDiscountValidDate()
            ? discountTotalPrice?.finalPriceDiscount : String(totalPrice),
        chargeCustomer: 'true',
        api: '1',
      },
    ];
    await API.post(API_METHOD.Package.UPDATE_SELL_PRICE, body)
      .then((result: any) => { })
      .catch((error: any) => {
        console.log('Error:', error);
      });
  };

  const conFirmItenary = async (folder_no: any, isSaveItenaryNo: string, itineararyNumber: string) => {
    const body = [
      {
        folderNumber: Number(folder_no),
        itineraryNumber: itineararyNumber,
        validateOnly: isSaveItenaryNo,
        api: 1,
        branchCode: branchCode,
      },
    ];

    const res = await API.post(API_METHOD.Misc.CONFIRM_ITINERARY, body)
      .then((result: any) => {
        console.log(result, 'confirm -itinerary');
        if (result?.data) {
          if (
            result?.data?.validateflights?.status === 'OK' &&
            result?.data?.validatehotel?.status === 'OK'
          ) {
            return { success: true, priceChange: false, result: result?.data };
          } else if (
            result?.data?.validateflights?.status === 'false' &&
            result.data.validateflights.error === 'ERROR: (794) FSC40003 - Error Obtaining Price Quote' &&
            result?.data?.validatehotel?.status === 'OK'
          ) {
            return { success: true, priceChange: false, result: result?.data };
          } else if (
            result?.data?.validateflights?.status === 'PD' &&
            result?.data?.validatehotel?.status === 'OK'
          ) {
            // } else if (result?.data?.validateflights?.status === "PD") {
            return { success: false, priceChange: true, result: result?.data };
          } else {
            return { success: false, priceChange: false, result: result?.data };
          }
        } else {
          return { success: false, priceChange: false, result: result?.data };
        }
      })
      .catch((error: any) => {
        console.log('Error:', error);
        return false;
      });

    return await res;
  };

  const summaryApi = async (folderNo: string, itineararyNumber: Number) => {
    const summaryBody = {
      folder_no: folderNo,
      destination_name: ArrivalLocation,
      itinerary_number: itineararyNumber,
      email: formData[0].email,
      branch_code: branchCode,
      flight: {
        outbound: {
          arrival_date: holiday?.flight?.outbound[0].outbound_date,
          airline_name: holiday?.flight?.outbound[0].airline_name,
          airline_code: holiday?.flight?.outbound[0].airline_code,
          arrival_time: holiday?.flight?.outbound[0].flightDeparture,
          arrival_airport_name: holiday?.flight?.outbound[0].dep_airport,
          // arrival_airport_code: holiday?.flight?.outbound[0].airline_code,
          departure_time: holiday?.flight?.outbound[0].arrival_time,
          departure_airport_name: holiday?.flight?.outbound[0].des_airport,
          // departure_airport_code: holiday?.flight?.outbound[0].airline_code,
          outbound_time: holiday?.outboundTime,
          stops: holiday?.flight?.outbound[0].stop,
          layover_stops: holiday?.flight?.outbound[0].layover?.map((layover: any) => ({
            layover1: layover || '',
          })),
        },
        inbound: {
          departure_date: holiday?.flight?.inbound[0].inbound_date,
          airline_name: holiday?.flight?.inbound[0].airline_name,
          airline_code: holiday?.flight?.inbound[0].airline_code,
          departure_time: holiday?.flight?.inbound[0].flightDeparture,
          departure_airport_name: holiday?.flight?.inbound[0].dep_airport,
          arrival_time: holiday?.flight?.inbound[0].arrival_time,
          arrival_airport_name: holiday?.flight?.inbound[0].des_airport,
          inboundTime: holiday?.inboundTime,
          stops: holiday?.flight?.inbound[0].stop,
          layover_stops: holiday?.flight?.inbound[0].layover?.map((layover: any) => ({
            layover1: layover || '', // Use the layover time if available
          })),
        },
        baggage: holiday?.baggage.includes('kg') ? holiday?.baggage : holiday?.baggage.replace('K', 'K'),
      },
      hotel: {
        hotel_description: holiday?.hotel,
        check_in: holiday?.packageDate?.checkin,
        check_out: holiday?.packageDate?.checkout,
        room: roomArray,
      },
      transfer: {
        airport_to_hotel_vehicle_name: '',
        hotel_to_airport_vehicle_name: '',
      },
      deposit: {
        isDeposit: holiday.isDepositPayment,
        due_date: holiday.balance_due_date,
        deposit_payment: (holiday?.deposit as any)?.replace(signForPricing, ''),
        // per_person_price: packagePricePerPerson,
        per_person_price: flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountUK && process.env.REACT_APP_PROJECT_COUNTRY === "uk" ?
          discountPerPersonPrice : flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountIE && process.env.REACT_APP_PROJECT_COUNTRY === "ie" ? discountPerPersonPrice : packagePricePerPerson,
        total_price: flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountUK && process.env.REACT_APP_PROJECT_COUNTRY === "uk" && isDiscountValidDate() ?
          discountTotalPrice?.finalPriceDiscount : flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountIE && process.env.REACT_APP_PROJECT_COUNTRY === "ie" && isDiscountValidDate()
            ? discountTotalPrice?.finalPriceDiscount : totalPrice,
      },
    };
    await API.post(API_METHOD.Others.SAVE_HOLIDAY_SUMMARY, summaryBody)
      .then((result: any) => { })
      .catch((error: any) => {
        console.log('Error:', error);
      });
  };

  const getFolderApiCall = async (folderNo: any, itineararyNumber: any) => {
    const { payload } = await dispatch(
      getFolderDetails([
        {
          fold_no: folderNo,
          branch_code: branchCode,
          itinerary_id: itineararyNumber,
          packageSearchId: flightRoom?.packageSearchId || '',
        },
      ])
    );
    return await payload;
  };

  const updatePDPrice = async (
    folderNo: any,
    itineararyNumber: any,
    priceDif: any,
    depositAmount?: any,
    isPayment?: any
  ) => {
    function roundPriceDif(priceDif: any) {
      const numericPriceDif = Number(priceDif);
      // Check if the price is a whole number
      if (numericPriceDif % 1 === 0) {
        return numericPriceDif; // No changes needed if it's already a whole number
      } else {
        return Math.round(numericPriceDif); // Round to the nearest whole number
      }
    }
    // console.log((totalPrice + Number(priceDif)), 'INNNINNNN(totalPrice + Number(updatePDPriceState))');
    // const updateDeposit = (totalPrice + Number(priceDif))
    // console.log('INNNINNNN updateDeposit :', updateDeposit);
    // const depositValue = calculateDeposit({
    //   tripDate: formattedDate || '',
    //   ticketingDate: Object.keys(holidayPackage).length > 0 && holidayPackage?.FlightDetails
    //     ? (holidayPackage.FlightDetails.outbound?.flights?.length > 0
    //       ? holidayPackage.FlightDetails.outbound?.flights[0]?.FlightPswResult.last_ticket_date
    //       : ""
    //     )
    //     : "",
    //   totalCost: updateDeposit,
    //   totalGuests,
    //   totalGuestsWithInfants,
    //   flightPrice: additionalFlightPrice,
    //   depositeConfiguration
    // });
    // setDeposit(depositValue)
    // console.log('INNNINNNNdepositValue :', depositValue);
    dispatch(setLoading(true));
    const resConfirmItinerary = await conFirmItenary(folderNo, '1', itineararyNumber);
    if (holiday.isDepositPayment) {
      await updatedDeposit(folderNo, depositAmount, itineararyNumber);
    }
    dispatch(setLoading(true));
    await summaryApi(folderNo, Number(itineararyNumber));
    // updateSellPrice(folderNo, itineararyNumber);
    const totalPriceNumber = flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountUK && process.env.REACT_APP_PROJECT_COUNTRY === "uk" && isDiscountValidDate() ?
      Number(discountTotalPrice?.finalPriceDiscount) :
      flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountIE && process.env.REACT_APP_PROJECT_COUNTRY === "ie" && isDiscountValidDate() ?
        Number(discountTotalPrice?.finalPriceDiscount) :
        Number(totalPrice);
    const updatedPrice = totalPriceNumber + roundPriceDif(priceDif);

    const body = [
      {
        folderNumber: folderNo,
        itineraryNumber: itineararyNumber,
        new_sell_price: String(updatedPrice),
        // new_sell_price: process.env.REACT_APP_PROJECT_COUNTRY === "uk" && isDiscountValidDate() ? discountTotalPrice?.finalPriceDiscount : String(totalPrice),
        chargeCustomer: 'true',
        api: '1',
      },
    ];
    await API.post(API_METHOD.Package.UPDATE_SELL_PRICE, body)
      .then((result: any) => { })
      .catch((error: any) => {
        console.log('Error:', error);
      });

    const payload = await getFolderApiCall(folderNo, itineararyNumber);
    if (payload) {
      updateStateAndNavigate(payload, itineararyNumber, isPayment);
      const data = [...formData];
      data.map((e) => {
        e.day = null;
        e.email = '';
        e.fname = '';
        e.gender = '';
        e.mname = '';
        e.month = null;
        e.pnumber = '';
        e.sname = '';
        e.title = '';
        e.year = null;
        return e;
      });

      setFormData(data);
      setComments('');
      setAddress({
        manually: {
          postcode: '',
          street: '',
          city: '',
          county: '',
          house: '',
          country: '',
        },
        searching: {
          postcode: '',
          street: '',
          city: '',
          county: '',
          house: '',
          country: '',
        },
        index: -1,
      });
      setValidation(false);
      setBillingAddress({
        storeManualBillingData: {
          postcode: '',
          street: '',
          city: '',
          county: '',
          house: '',
          country: '',
        },
        storeSearchingBillingData: {
          postcode: '',
          street: '',
          city: '',
          county: '',
          house: '',
          country: '',
        },
        manually: {
          postcode: '',
          street: '',
          city: '',
          county: '',
          house: '',
          country: '',
        },
        searching: {
          postcode: '',
          street: '',
          city: '',
          county: '',
          house: '',
          country: '',
        },
        index: -1,
      });
      setTravelPurpose('');
      setFormErrors({});
      dispatch(setLoading(false));
      if (isLoginUser) {
        navigate('/profile/dashboard');
      } else {
        navigate('/signin');
      }
    }
  };

  const postUserDetailsAPI = async (isPayment: boolean) => {
    setFormErrors({});
    try {
      const userRequests = getUserRequests();
      const response = isFolderAlreadyAdded ? true : await dispatch(postUserFolderDetails(userRequests));

      if (response) {
        const folderNo = isFolderAlreadyAdded ? queryData.folder : response.payload;
        if (!isNaN(folderNo)) {
          const createdFolderNumber: any = await createFolderDetails(folderNo);
          const depositAmount: any = (holiday?.deposit as any)?.replace(signForPricing, '');
          const addDetailsResponse = await dispatch(addHotelFlightDetailsToFolder(createdFolderNumber));
          if (addDetailsResponse) {
            try {
              const itineararyNumber = createdFolderNumber[0].itineraryNumber;

              const resConfirmItinerary = await conFirmItenary(folderNo, '1', itineararyNumber);
              const destinationFrom = flightRoom?.searchCriteria2[0]?.DestinationFrom;
              const destination = flightRoom.searchCriteria2[0].Destination.split(' ').join('_');
              const rooms = flightRoom.searchCriteria2[0].rooms;
              const cabin_class = flightRoom.searchCriteria.class.includes('_')
                ? flightRoom.searchCriteria.class
                : flightRoom.searchCriteria.class.split(' ').join('_');
              const adults = flightRoom.searchCriteria2[0].adults.join(',');
              const children = flightRoom.searchCriteria2[0].children.join(',');
              const infants = flightRoom.searchCriteria2[0].infants.join(',');
              const hotel_id = flightRoom.searchCriteria2[0].hotel_id;

              const flightSearchCriteriaId = flightRoom.searchCriteria2[1].ids.flightSearchCriteriaId;
              const hotelSearchCriteriaIds = flightRoom.searchCriteria2[1].ids.hotelSearchCriteriaIds;
              const selectedFlight = flightRoom.searchCriteria2[1].ids.selectedFlight;
              const hotelSearchCriteriaId_nextDay =
                flightRoom.searchCriteria2[1].ids.hotelSearchCriteriaId_nextDay;
              const date = flightRoom.searchCriteria.date.join(',');
              const childAge = JSON.stringify(flightRoom.searchCriteria.child_age);
              const infantAges = JSON.stringify(flightRoom.searchCriteria.infant_age);
              if (resConfirmItinerary.success) {
                if (holiday.isDepositPayment) {
                  await updatedDeposit(folderNo, depositAmount, itineararyNumber);
                }
                await summaryApi(folderNo, itineararyNumber);
                await updateSellPrice(folderNo, itineararyNumber);

                const payload = await getFolderApiCall(folderNo, itineararyNumber);
                if (payload) {
                  updateStateAndNavigate(payload, itineararyNumber, isPayment);
                  const data = formData.map((e) => ({
                    ...e,
                    day: null,
                    email: '',
                    fname: '',
                    gender: '',
                    mname: '',
                    month: null,
                    pnumber: '',
                    sname: '',
                    title: '',
                    year: null,
                  }));

                  setFormData(data);
                  setComments('');
                  setAddress({
                    manually: {
                      postcode: '',
                      street: '',
                      city: '',
                      county: '',
                      house: '',
                      country: '',
                    },
                    searching: {
                      postcode: '',
                      street: '',
                      city: '',
                      county: '',
                      house: '',
                      country: '',
                    },
                    index: -1,
                  });

                  setBillingAddress({
                    storeManualBillingData: {
                      postcode: '',
                      street: '',
                      city: '',
                      county: '',
                      house: '',
                      country: '',
                    },
                    storeSearchingBillingData: {
                      postcode: '',
                      street: '',
                      city: '',
                      county: '',
                      house: '',
                      country: '',
                    },
                    manually: {
                      postcode: '',
                      street: '',
                      city: '',
                      county: '',
                      house: '',
                      country: '',
                    },
                    searching: {
                      postcode: '',
                      street: '',
                      city: '',
                      county: '',
                      house: '',
                      country: '',
                    },
                    index: -1,
                  });
                  setValidation(false);
                  setTravelPurpose('');
                  setFormErrors({});
                  // dispatch(setLoading(false));
                } else {
                  console.log('error');
                  dispatch(setLoading(false));
                }
              } else {
                dispatch(setLoading(false));
                dispatch(setLottieLoading(false));
                const priceDif = resConfirmItinerary.result.validateflights.priceDif;
                const validateFlightsStatus = resConfirmItinerary.result.validateflights.status;
                const validateHotelStatus = resConfirmItinerary.result.validatehotel.status;
                let swalText = '';
                if (validateFlightsStatus === 'false' && validateHotelStatus === 'false') {
                  swalText =
                    'Your chosen flight and hotel room are not available anymore, please update to an alternative flight and room or alternatively you can do a new search.';
                } else if (validateFlightsStatus === 'false') {
                  swalText =
                    'Your chosen flight is not available anymore, please change the flight or alternatively you can do a new search.';
                } else if (validateHotelStatus === 'false') {
                  swalText =
                    'Your chosen hotel room is not available anymore, please update to another room or alternatively you can do a new search.';
                }
                if (resConfirmItinerary.priceChange) {
                  Swal.fire({
                    title: 'Price Changed',
                    text: `The overall package price has increased by ${signForPricing}${priceDif}.\n This is due to an increase in one or more elements of your itinerary.`,
                    icon: 'warning',
                    // showCancelButton: false,
                    showCancelButton: true,
                    confirmButtonColor: isPlataniumHotel
                      ? colors.basics.plataniumGreenColor
                      : colors.basics.primary,
                    cancelButtonColor: isPlataniumHotel
                      ? colors.basics.plataniumGreenColor
                      : colors.basics.primary,
                    confirmButtonText: 'New Search',
                    cancelButtonText: 'Accept New Price',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    customClass: {
                      confirmButton: 'confirmButton',
                      actions: 'actions',
                      icon: isPlataniumHotel ? 'platinum-icon-class' : 'primary-icon-class',
                      cancelButton: isPlataniumHotel ? 'platinum-cancel-button' : 'primary-cancel-button',
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      newSearch();
                    } else if (result.isDismissed) {
                      updatePDPrice(folderNo, itineararyNumber, priceDif, depositAmount, isPayment);
                    }
                  });
                } else {
                  Swal.fire({
                    title: 'Something Went Wrong',
                    text: swalText,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: isPlataniumHotel
                      ? colors.basics.plataniumGreenColor
                      : colors.basics.primary,
                    cancelButtonColor: isPlataniumHotel
                      ? colors.basics.plataniumGreenColor
                      : colors.basics.primary,
                    confirmButtonText: 'New Search',
                    cancelButtonText: 'Change Ititnery', //Confirm ititnery
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    customClass: {
                      confirmButton: 'confirmButton',
                      actions: 'actions',
                      icon: isPlataniumHotel ? 'platinum-icon-class' : 'primary-icon-class',
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      newSearch();
                    } else if (result.isDismissed) {
                      // const url = `${process.env.REACT_APP_LIVE_PAYMENT_URL}?folder=${session_id}&branch=${branchCode}&itinerary=${session_id}&s=b&session_id=${session_id}&departure=${destinationFrom}&destination=${destination}&room_count=${rooms}&cabin_class=${cabin_class}&adult=${adults}&children_count=${children}&infant_count=${infants}&hotel_id=${hotel_id}&flight_search_criteria_id=${flightSearchCriteriaId}&hotel_search_criteria_ids=${hotelSearchCriteriaIds}&selected_flight=${selectedFlight}&hotel_search_criteria_id_nextDay=${hotelSearchCriteriaId_nextDay}&daterange=${date}&child_age=${childAge}&infant_age=${infantAges}`;
                      // console.log('url :', url);
                      // const url1 = `/holiday-summary/${session_id}?folder=${folderNo}&itinerary_number=${session_id}&s=b&departure=${destinationFrom}&destination=${destination}&room_count=${rooms}&cabin_class=${cabin_class}&adult=${adults}&children_count=${children}&infant_count=${infants}&hotel_id=${hotel_id}&flight_search_criteria_id=${flightSearchCriteriaId}&hotel_search_criteria_ids=${hotelSearchCriteriaIds}&child_age=${childAge}&selected_flight=${selectedFlight}&infant_age=${infantAges}&hotel_search_criteria_id_nextDay=${hotelSearchCriteriaId_nextDay}&daterange=${date}`
                      // console.log('url1 :', url1);
                      // window.location.reload();
                      const url = `${session_id}?folder=${folderNo}&itinerary_number=${itineararyNumber}&s=b&departure=${destinationFrom}&destination=${destination}&room_count=${rooms}&cabin_class=${cabin_class}&adult=${adults}&children_count=${children}&infant_count=${infants}&hotel_id=${hotel_id}&flight_search_criteria_id=${flightSearchCriteriaId}&hotel_search_criteria_ids=${hotelSearchCriteriaIds}&child_age=${childAge}&selected_flight=${selectedFlight}&infant_age=${infantAges}&hotel_search_criteria_id_nextDay=${hotelSearchCriteriaId_nextDay}&daterange=${date}&confirmitinearary_fails=true&holiday_details_fails=${holidayDetailsResults}`;
                      // debugger
                      cancelSearch(url);
                    }
                  });
                }
              }
            } catch (e) {
              dispatch(setLoading(false));
              dispatch(setLottieLoading(false));
            }
          }
        } else {
          dispatch(setLottieLoading(false));
          dispatch(setLoading(false));
          Swal.fire({
            title: 'Something Went Wrong',
            text: 'Folder Number is not created',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
            cancelButtonColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
            confirmButtonText: 'Reload',
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
              confirmButton: 'confirmButton',
              actions: 'actions',
              icon: isPlataniumHotel ? 'platinum-icon-class' : 'primary-icon-class',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      }
    } catch (error) {
      // Handle error appropriately
      console.error('Error:', error);
      dispatch(setLoading(false));
    }
  };

  const getUserRequests = () => {
    const ad = isShownAddress.addressManually ? address.manually : address.searching;

    return formData.map((el) => {
      return {
        customer_type: 'C',
        title: el.title,
        last_name: el.sname,
        first_name: el.fname,
        // address: `${ad.street}, ${ad.city}, ${ad.country}, ${ad.postcode} , ${ad.house}`,
        address2: `${ad.house}`,
        address3: `${ad.street}`,
        city: `${ad.city}`,
        state: ` ${ad.county}`,
        country: `${ad.country}`,
        contact_types: {
          '0': {
            type: 'EMAILTO',
            contact: el.email,
          },
          '1': {
            type: 'HOME',
            contact: el.pnumber,
          },
        },
        branch_code: branchCode,
        zip_code: ad.postcode,
        des_airport_code: destination?.airport_code,
        departuredate:
          selectedFlights?.outbound[0]?.FlightPswFlight?.departure_date ||
          dayjs(flightRoom.searchCriteria.date[0]).format('YYYY-DD-MM'),
        // staff_code: 'SYS',
        // owned_by: 'SYS',
        brandId: '1',
        folder_status: 'Online Folder',
        marketsource: '264',
      };
    });
  };

  const createFolderDetails = (folderNumber: string) => {
    let arr = [];
    arr.push(createFlightRequestData());
    arr.push(createHotelRequestData());
    // arr.push(createCarHireRequestData());
    // if (isEmpty(selectedTransfer) !== "") {
    //   arr.push(createTransferRequestData())
    // }

    let totalItenary = 1;

    if (queryData?.itinerary_number) {
      totalItenary = Number(queryData.itinerary_number);
      // const containsTrue = Object.values(flightRoom.confirmSelection).some(value => value === true);
      // if (containsTrue) {
      totalItenary += 1;
      // }
    }

    return [
      {
        folderNumber,
        itineraryNumber: totalItenary.toString(),
        foldcur: process.env.REACT_APP_PROJECT_COUNTRY === 'ie' ? 'EUR' : 'GBP',
        travelPurpose,
        comments: [comments],
        // set_as_preferred_itinerary: isItineraryUpdated,
        set_as_preferred_itinerary: true,
        passengers: formData.map((el) => {
          let obj: any = {
            title: el.title,
            first_name: el.fname,
            middle_name: el.mname,
            last_name: el.sname,
            pax_type: el.pax_type,
            api_gender: el.gender,
          };

          let birth_date = `${el.year}-${el.month}-${el.day}`;
          obj.birth_date = birth_date;
          return obj;
        }),
        requestData: arr,
      },
    ];
  };

  const retrieveholidayBasketRetrieve = async (flightRoom: any) => {
    let isDirectFlight: boolean = false;
    const adultPerRoom: any = [];
    const childPerRoom: any = [];
    const childAge: any = [];
    const cabinClass: CabinClass = flightRoom.searchCriteria.class;
    const room = flightRoom.searchCriteria.guest;
    const infantPerRoom: any = [];
    removeSessionElements();
    if (room.length > 0) {
      room?.map((r: any) => {
        adultPerRoom.push(r.adults.toString());
        childPerRoom.push(r.children.length.toString());
        infantPerRoom.push(r.infants.length.toString());
        const childrenAges: any = {};

        if (r?.children?.length > 0) {
          r?.children.forEach((child: any) => {
            childrenAges[child.child.toString()] = child.age;
          });
          childAge.push(childrenAges);
        } else {
          childAge.push({});
        }
        return r;
      });
    }

    if (process.env.REACT_APP_PROJECT_COUNTRY === 'ie') {
      directFlightsIE.map((d: any) => {
        if (
          d.dep_code === flightRoom.searchCriteria.departure.departure_airport &&
          d.des_code.toUpperCase() ===
          isEmpty(
            flightRoom.searchCriteria.destination.airport_code.toUpperCase() +
            ' ;' +
            flightRoom.searchCriteria.destination['Searc ID']
          )
        ) {
          isDirectFlight = true;
          return d;
        } else {
          return <></>;
        }
      });
    } else if (process.env.REACT_APP_PROJECT_COUNTRY === 'uk') {
      directFlights.map((d: any) => {
        if (
          d.dep_code === flightRoom.searchCriteria.departure.departure_airport &&
          d.des_code.toUpperCase() ===
          isEmpty(
            flightRoom.searchCriteria.destination.airport_code.toUpperCase() +
            ' ;' +
            flightRoom.searchCriteria.destination['Searc ID']
          )
        ) {
          isDirectFlight = true;
          return d;
        } else {
          return <></>;
        }
      });
    }

    const fetchClass: any = CABIN_CLASS_ENUM[cabinClass];
    let holidayPackageJson: any = [
      {
        DestinationFrom: flightRoom.searchCriteria.departure.departure_airport,
        Destination:
          flightRoom.searchCriteria.destination.airport_code?.toUpperCase() +
          ' ;' +
          flightRoom.searchCriteria.destination['Searc ID'],
        departure_date: flightRoom.searchCriteria.date[0],
        rooms: flightRoom.searchCriteria.guest.length.toString(),
        nights: flightRoom.searchCriteria.night,
        cabin_class: fetchClass,
        adults: adultPerRoom,
        children: childPerRoom,
        infants: infantPerRoom,
        siteId: process.env.REACT_APP_SITE_ID,
        cheapestFareCat: 'IT',
        direct_flight_only: isDirectFlight ? 1 : 0,
        basketId: basketId,
        hotel_id: Number(flightRoom?.hotel_id),
        only_pu_fares: false,
      },
    ];

    if (basketId !== '0') {
      holidayPackageJson[0].packageSearchId = flightRoom.packageSearchId;
    }

    if (flightRoom?.searchCriteria?.childrens > 0) {
      holidayPackageJson[0].child_age = flightRoom?.searchCriteria?.child_age;
    }
    if (flightRoom?.searchCriteria?.infants > 0) {
      holidayPackageJson[0].infant_ages = flightRoom?.searchCriteria?.infant_age;
    }

    const { payload } = await dispatch(fetchAsyncHolidayPackages(holidayPackageJson));
    if (payload?.Packages) {
      await setIsDataLoaded(true);
      await setLottieLoading(false);
      await dispatch(setLoading(false));
    } else {
      await setIsDataLoaded(false);
      await dispatch(setLottieLoading(false));
      await dispatch(setLoading(false));
    }

    return payload;
  };

  const retrieveDB = async () => {
    let res = await dispatch(retrieveBasket(basketId));
    res = res.payload;
    removeSessionElements();
    let data: any = {};
    if (res?.data?.length > 0) {
      data = res.data[0];
      let flightData: any = {};
      flightData = JSON.parse(Object.values(data.basket.cart.flights)[0] as string);
      dispatch(setLottieLoading(true));
      setSessionStorage('more-details-click', 'true');
      setSessionStorage('OutboundFlights', JSON.stringify(flightData.selectedFlights.outbound));
      setSessionStorage('InboundFlights', JSON.stringify(flightData.selectedFlights.inbound));
      setSessionStorage('stepper', '1');
      const holidayData: any = JSON.parse(data.basket.cart.holiday);
      flightData = {
        ...flightData,
        searchCriteria2: await holidayPackageStoreCriteria(flightData, holidayData),
      };
      dispatch(setFlightRoomState(flightData));
      dispatch(setHolidayDetailsState(holidayData));
      const resp = await retrieveholidayBasketRetrieve(flightData);
      if (resp) {
        await dispatch(setHolidayPackage(resp));
        dispatch(setLoading(false));
        dispatch(setLottieLoading(false));
        setIsDataLoaded(true);
      }
      // await dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
    }
  };

  const createFlightRequestData = () => {
    return {
      type: 'flight',
      psw_result_id: flightRoom.psw_result_id_combo,
      passengers: [...Array(adults + childrens + infants)]
        .map((value, index) => (index + 1).toString())
        .join(','),
    };
  };

  const createCarHireRequestData = () => {
    return {
      type: 'car',
      searchCriteriaId: availability?.searchCriteriaId,
      searchResultId: '',
      booster_seat: '1',
      child_seat: '1',
      airline_name: flightRoom.selectedFlights.inbound[0].FlightPswFlight.airline_name,
      arrival_flight_num: flightRoom.selectedFlights.inbound[0].FlightPswFlight.flight_number,
    };
  };

  const createHotelRequestData = () => {
    const roomsArr: any = multiRooms.map((e: any) => e.id);
    let passengerDetails: any = {};
    roomsArr.map((r: any, i: any) => {
      let id: any = [];
      if (flightRoom.multiRooms.length > 1) {
        selectedOptions.map((a: any, paxIndex: any) => {
          if (paxIndex === i) {
            a.adults.map((s: any) => {
              id.push(s.id);
              return <></>;
            });

            a.children.map((s: any) => {
              id.push(s.id);
              return <></>;
            });

            a.infants.map((s: any) => {
              id.push(s.id);
              return <></>;
            });
          }
          return <></>;
        });
      } else {
        const arr = [...formData];

        const filterAdults = arr.filter((f, i) => f.pax_type === 'ADT');
        const filterChildren = arr.filter((f, i) => f.pax_type === 'CHD');
        const filterInfant = arr.filter((f, i) => f.pax_type === 'INF');

        filterAdults.map((f) => {
          id.push(f.arrayIndex);
          return <></>;
        });

        filterChildren.map((f) => {
          id.push(f.arrayIndex);
          return <></>;
        });

        filterInfant.map((f) => {
          id.push(f.arrayIndex);
          return <></>;
        });
      }

      passengerDetails[r] = id.join(',');
      return <></>;
    });

    return {
      type: 'hotel',
      search_result_id: search_result_id,
      passengers: passengerDetails,
      roomIds: flightRoom.roomids,
    };
  };

  const openInNewTab = (url: string) => {
    window.location.replace(url);
  };

  const redirectToPaymentSite = (folderDetails: any, foldertoken: any, itineraryNumber: string) => {
    const destinationFrom = flightRoom.searchCriteria2[0].DestinationFrom;
    const destination = flightRoom.searchCriteria2[0].Destination.split(' ').join('_');
    const rooms = flightRoom.searchCriteria2[0].rooms;
    const cabin_class = flightRoom.searchCriteria.class.includes('_')
      ? flightRoom.searchCriteria.class
      : flightRoom.searchCriteria.class.split(' ').join('_');
    const adults = flightRoom.searchCriteria2[0].adults.join(',');
    const children = flightRoom.searchCriteria2[0].children.join(',');
    const infants = flightRoom.searchCriteria2[0].infants.join(',');
    const hotel_id = flightRoom.searchCriteria2[0].hotel_id;

    const flightSearchCriteriaId = flightRoom.searchCriteria2[1].ids.flightSearchCriteriaId;
    const hotelSearchCriteriaIds = flightRoom.searchCriteria2[1].ids.hotelSearchCriteriaIds;
    const selectedFlight = flightRoom.searchCriteria2[1].ids.selectedFlight;
    const hotelSearchCriteriaId_nextDay = flightRoom.searchCriteria2[1].ids.hotelSearchCriteriaId_nextDay;
    const date = flightRoom.searchCriteria.date.join(',');
    const childAge = JSON.stringify(flightRoom.searchCriteria.child_age);
    const infantAges = JSON.stringify(flightRoom.searchCriteria.infant_age);
    const url = `${process.env.REACT_APP_LIVE_PAYMENT_URL}?folder=${folderDetails?.Folder?.folder_no}&token=${foldertoken}&branch=${folderDetails?.Folder?.branch_code}&itinerary=${itineraryNumber}&s=b&session_id=${session_id}&departure=${destinationFrom}&destination=${destination}&room_count=${rooms}&cabin_class=${cabin_class}&adult=${adults}&children_count=${children}&infant_count=${infants}&hotel_id=${hotel_id}&flight_search_criteria_id=${flightSearchCriteriaId}&hotel_search_criteria_ids=${hotelSearchCriteriaIds}&selected_flight=${selectedFlight}&hotel_search_criteria_id_nextDay=${hotelSearchCriteriaId_nextDay}&daterange=${date}&child_age=${childAge}&infant_age=${infantAges}&redirect_var=${process.env.REACT_APP_PAYMENT_REDIRECT_URL}`;
    // if (!needToShowLoginScreen) {
    //   dispatch(setLoading(false));
    //   navigate("/signin")
    // } else {
    setTimeout(() => {
      dispatch(setLoading(false));
      openInNewTab(url);
    }, 800);
    // }
  };

  const updateStateAndNavigate = async (payload: any, itineraryNumber: string, isPayment: boolean) => {
    dispatch(setPassengerState(formData));
    dispatch(setStepperState(2));

    const { folderDetails, foldertoken } = payload;

    const resp = await dispatch(checkUserEmailIsExist(formData[0].email));
    if (resp.payload === '1' || Object.keys(userDetails).length > 0) {
      // if (isPayment) {
      //   const needToShowLoginScreen = Object.keys(userDetails).length > 0
      redirectToPaymentSite(folderDetails, foldertoken, itineraryNumber);
      // } else {
      //   dispatch(setLoading(false));
      //   if (Object.keys(userDetails).length > 0) {
      //     navigate("/profile/dashboard")
      //   } else {
      //     navigate("/signin")
      //   }
      // }
    } else {
      registerUser(folderDetails, foldertoken, itineraryNumber);
    }
  };

  const registerUser = async (folderDetails: any, foldertoken: any, itineraryNumber: string) => {
    dispatch(setLoading(true));

    // register user account
    const password = generatePassword();

    const { payload } = await dispatch(
      userRegisterAPI([
        {
          name: formData[0]?.sname,
          first_name: formData[0]?.fname,
          username: formData[0]?.email,
          password,
          address1: isShownAddress.addressManually ? address.manually.house : address.searching.house,
          address2: isShownAddress.addressManually ? address.manually.street : address.searching.street,
          city_name: isShownAddress.addressManually ? address.manually.city : address.searching.city,
          country_code: '',
          mobile_no: formData[0]?.pnumber,
          telephone_no: formData[0]?.pnumber,
          email: formData[0]?.email,
          create_customer: '1',
          check_branch_code: '1',
          branch_code: branchCode,
          customer_type: 'C',
          register: true,
          mail_subject: 'Customer Registration',
          mail_from_email: 'customerservices@awebsite.co.uk',
          mail_from_name: 'American Sky',
          mail_site_name: 'Uk American Sky',
          mail_from_tel: '020 7725 7000',
          mail_to_email: formData[0]?.email,
          mail_to_name: formData[0]?.fname,
          mail_login_url: process.env.REACT_APP_LIVE_URL + 'signin',
        },
      ])
    );

    if (payload?.success) {
      if (!emailTextCheckbox) {
        const body = {
          email: formData[0]?.email,
          password,
        };

        await API.post(API_METHOD.Email.SEND_RESET_PASSWORD_MAIL, body);
      }
      redirectToPaymentSite(folderDetails, foldertoken, itineraryNumber);
    } else {
      await dispatch(setLoading(false));
    }
  };

  const handleFormChangeData = (index: number, evt: InputEvent) => {
    const { name, value } = evt.target;
    const updatedFormData: any = [...formData];

    if (name === 'title') {
      updatedFormData[index]['gender'] = value === 'Mr' ? 'M' : 'F';
      updatedFormData[index][name] = value;
    } else if (name === 'email') {
      for (let i = 0; i < updatedFormData.length; i++) {
        updatedFormData[i]['email'] = value ? value : '';
      }
    } else if (name === 'pnumber') {
      for (let i = 0; i < updatedFormData.length; i++) {
        updatedFormData[i]['pnumber'] = value ? value : null;
      }
    } else if (name === 'day' || name === 'year' || name === 'month') {
      (updatedFormData as any)[index][name] = value === '' ? null : value;
    } else {
      (updatedFormData as any)[index][name] = value;
    }
    setFormData(updatedFormData);

    let fErrors = formErrors;
    fErrors = {};

    updatedFormData.map((f: any, i: any) => {
      if (
        isEmpty(f.day) !== '' &&
        isEmpty(f.month) !== '' &&
        isEmpty(f.year) !== '' &&
        f.year.length === 4 &&
        f.day.length <= 2 &&
        f.month.length <= 2
      ) {
        f.isDateValid = isValidDate(f.year, f.month, f.day);
        if (!f.isDateValid) {
          fErrors[`[${i}].day`] = 'Invalid day';
        }
      }
      return <></>;
    });
    setFormErrors(fErrors);

    // If user change the names after Room Selection
    if (name === 'fname' || name === 'mname' || name === 'sname') {
      const getFullname =
        name === 'fname'
          ? (
            value +
            ' ' +
            (updatedFormData as any)[index]['mname'] +
            ' ' +
            (updatedFormData as any)[index]['sname']
          ).trim()
          : name === 'mname'
            ? (
              (updatedFormData as any)[index]['fname'] +
              ' ' +
              value +
              ' ' +
              (updatedFormData as any)[index]['sname']
            ).trim()
            : (
              (updatedFormData as any)[index]['fname'] +
              ' ' +
              (updatedFormData as any)[index]['mname'] +
              ' ' +
              value
            ).trim();

      const pax_type = (updatedFormData as any)[index]['pax_type'];
      const arrayIndex = (updatedFormData as any)[index]['arrayIndex'];
      const newSelectedRoomArr: any = [...selectedOptions];

      newSelectedRoomArr.map((a: any, paxIndex: any) => {
        if (pax_type === 'ADT') {
          a.adults.map((adultObj: any, adultIndex: number) => {
            if (Number(adultObj.id) === Number(arrayIndex)) {
              if (adultObj.name !== getFullname) {
                newSelectedRoomArr[paxIndex]['adults'][adultIndex] = {
                  ...newSelectedRoomArr[paxIndex]['adults'][adultIndex],
                  name: getFullname,
                };
              }
            }
            return <></>;
          });
        } else if (pax_type === 'CHD') {
          a.children.map((adultObj: any, adultIndex: number) => {
            if (Number(adultObj.id) === Number(arrayIndex)) {
              if (adultObj.name !== getFullname) {
                newSelectedRoomArr[paxIndex]['children'][adultIndex] = {
                  ...newSelectedRoomArr[paxIndex]['children'][adultIndex],
                  name: getFullname,
                };
              }
            }
            return <></>;
          });
        } else {
          a.infants.map((adultObj: any, adultIndex: number) => {
            if (Number(adultObj.id) === Number(arrayIndex)) {
              if (adultObj.name !== getFullname) {
                newSelectedRoomArr[paxIndex]['infants'][adultIndex] = {
                  ...newSelectedRoomArr[paxIndex]['infants'][adultIndex],
                  name: getFullname,
                };
              }
            }
            return <></>;
          });
        }
        return <></>;
      });

      if (validation) {
        validateAdultsChildrenInfants(newSelectedRoomArr);
      }

      setSelectedOptions(newSelectedRoomArr);
    }

    validation &&
      validationSchema
        .validate(updatedFormData, { abortEarly: false })
        .then(() => { })
        .catch((err) => {
          const errors: any = {};

          formData.map((f: any, i) => {
            if (!f.isDateValid) {
              err.inner.forEach((e: any) => {
                if (e.path !== `[${i}].day` && fErrors[`[${i}].day`]) {
                  errors[`[${i}].day`] = fErrors[`[${i}].day`];
                }
              });
            }
            return <></>;
          });
          err.inner.forEach((e: any) => {
            errors[e.path] = e.message;
          });

          dispatch(setLoading(false));
          setFormErrors(errors);
        });
  };

  const extractFormIndex = (errorKey: any) => {
    const matches = errorKey?.match(/\[(\d+)\]/);
    if (matches && matches.length > 1) {
      return parseInt(matches[1], 10);
    }
    return null;
  };

  const scrollToFormWithIndex = (formIndex: any) => {
    const formContainer = document.querySelector(`#formContainer${formIndex}`);
    const postContainer = document.querySelector('#postalId');
    const billingContainer = document.querySelector('#billingId');
    const roomContainer = document.querySelector('#roomAllocation');
    const allValuesEmpty = isShownAddress.addressManually
      ? Object.values(addressBillingErrors.manuallyAddressErrors).every((value) => value === '')
      : Object.values(addressBillingErrors.searchingAddressErrors).every((value) => value === '');
    const billingValuesEmpty = isShownAddress.billingAddressManually
      ? Object.values(addressBillingErrors.manuallyBillingErrors).every((value) => value === '')
      : Object.values(addressBillingErrors.searchingBillingErrors).every((value) => value === '');

    if (formContainer) {
      const headerOffset = formIndex === 0 ? 270 : 190;
      const containerPosition = formContainer?.getBoundingClientRect().top;
      const offsetPosition = containerPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    } else if (flightRoom.multiRooms.length > 1 && !submitForm) {
      const headerOffset = 120;
      const roomPosition: any = roomContainer?.getBoundingClientRect().top;
      const roomSetPosition = roomPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: roomSetPosition,
        behavior: 'smooth',
      });
    } else if (!allValuesEmpty) {
      const headerOffset = 120;
      const postPosition: any = postContainer?.getBoundingClientRect().top;
      const postSetPosition = postPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: postSetPosition,
        behavior: 'smooth',
      });
    } else if (!billingValuesEmpty) {
      const headerOffset = 120;
      const billingPosition: any = billingContainer?.getBoundingClientRect().top;
      const billingSetPostion = billingPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: billingSetPostion,
        behavior: 'smooth',
      });
    }
  };

  const reverseString = (str: any) => {
    var splitString = str?.split('/');
    var reverseArray = splitString.reverse();
    var joinArray = reverseArray.join('/');
    return joinArray;
  };

  const setHotelData = (holidayPackage: any, flightRoom: any) => {
    const outBoundFlights = holidayPackage?.FlightDetails?.outbound.flights;
    const inboundFlights = holidayPackage?.FlightDetails?.inbound.flights;
    let obj = {
      ...holiday,
      hotel_name: flightRoom.hotel_name,
      hotel_id: flightRoom.hotel_id,
      hotel: `${flightRoom?.multiRooms[0]?.days_spent} nights at ${flightRoom.hotel_name}`,
      packageDate: {
        checkin: `${dayjs(flightRoom?.multiRooms[0]?.checkInDate).format('DD MMM, YYYY')}`,
        checkout: `${dayjs(flightRoom?.multiRooms[0]?.checkOutDate).format('DD MMM, YYYY')}`,
      },
      selectedFlight: flightRoom?.selectedFlights,
      guest: flightRoom.searchCriteria.guest,
      room: flightRoom?.multiRooms,
      transfer: '',
      outboundTime: singleFlightTravelTime(holidayPackage?.FlightDetails?.outbound?.flying_time)
        ?.replace(/ (\d+) \s?hr/g, '$1hr')
        .replace(/\s?m/g, 'm'),
      //  getFlightTimesObjectHolidayPackage(outBoundFlights)[0],
      inboundTime: singleFlightTravelTime(holidayPackage?.FlightDetails?.inbound?.flying_time)
        ?.replace(/ (\d+) \s?hr/g, '$1hr')
        .replace(/\s?m/g, 'm'),
      // getFlightTimesObjectHolidayPackage(inboundFlights)[0],
    };

    const outboundLayover: any = [];
    const inboundLayover: any = [];

    if (isEmpty(outBoundFlights) !== '' && isEmpty(inboundFlights) !== '') {
      obj.baggage = inboundFlights[0].Baggage.includes('kg')
        ? inboundFlights[0].Baggage
        : inboundFlights[0].Baggage.replace('K', 'K');
      outBoundFlights.length > 0 &&
        outBoundFlights.map((item: any, index: number) => {
          return index !== outBoundFlights.length - 1
            ? outboundLayover.push({
              time: flightTime(
                new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
                new Date(
                  `${outBoundFlights[index + 1]?.departure_date} ${formatTime(
                    outBoundFlights[index + 1]?.departure_time
                  )}`
                )
              ),
              code: item?.arrival_airport,
              layover_airport_name: item?.arrive_airport_name,
              layover_airport_city: item?.arrive_airport_city,
            })
            : outboundLayover.push(null);
        });
      inboundFlights.length > 0 &&
        inboundFlights.map((item: any, index: number) => {
          return index !== inboundFlights.length - 1
            ? inboundLayover.push({
              time: flightTime(
                new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
                new Date(
                  `${inboundFlights[index + 1]?.departure_date} ${formatTime(
                    inboundFlights[index + 1]?.departure_time
                  )}`
                )
              ),

              code: item?.arrival_airport,
              layover_airport_name: item?.arrive_airport_name,
              layover_airport_city: item?.arrive_airport_city,
            })
            : inboundLayover.push(null);
        });
      obj.flight = {
        inbound: [
          {
            airline_code: inboundFlights[0]?.airline_code,
            airline_name: inboundFlights[0]?.airline_name,
            inbound_date: `${dayjs(inboundFlights[0]?.departure_date).format('DD MMM, YYYY')}`,
            dep_airport: `${inboundFlights[0]?.depart_airport_name
              } (${inboundFlights[0]?.departure_airport?.toUpperCase()})`,
            des_airport: `${inboundFlights[inboundFlights?.length - 1]?.arrive_airport_name
              } (${inboundFlights[inboundFlights?.length - 1]?.arrival_airport?.toUpperCase()})`,
            layover: inboundLayover,
            arrival_time:
              inboundFlights.length > 0
                ? formatTime(inboundFlights[inboundFlights.length - 1]?.arrival_time)
                : '',
            stop: `${inboundFlights?.length - 1 !== 0 ? inboundFlights?.length - 1 + ' stops' : 'Direct'}`,
            flightDeparture:
              isEmpty(inboundFlights[0]?.departure_time) !== ''
                ? formatTime(inboundFlights[0]?.departure_time)
                : '',
          },
        ],
        outbound: [
          {
            airline_code: outBoundFlights[0]?.airline_code,
            airline_name: outBoundFlights[0]?.airline_name,
            outbound_date: `${dayjs(outBoundFlights[0]?.departure_date).format('DD MMM, YYYY')}`,
            dep_airport: `${outBoundFlights[0]?.depart_airport_name
              } (${outBoundFlights[0]?.departure_airport?.toUpperCase()})`,
            des_airport:
              outBoundFlights.length > 0
                ? `${outBoundFlights[outBoundFlights?.length - 1]?.arrive_airport_name
                } (${flightRoom?.searchCriteria?.destination?.airport_code?.toUpperCase()})`
                : '',
            layover: outboundLayover,
            arrival_time:
              outBoundFlights.length > 0
                ? formatTime(outBoundFlights[outBoundFlights.length - 1]?.arrival_time)
                : '',
            departure_date: outBoundFlights[0]?.departure_date,
            arrival_date: outBoundFlights[outBoundFlights?.length - 1]?.arrival_date,
            stop: `${outBoundFlights?.length - 1 !== 0 ? outBoundFlights?.length - 1 + ' stops' : 'Direct'}`,
            flightDeparture:
              isEmpty(outBoundFlights[0]?.departure_time) !== ''
                ? formatTime(outBoundFlights[0]?.departure_time)
                : '',
          },
        ],
      };
    }

    dispatch(setHolidayDetailsState(obj));
    return obj;
  };

  const fetchHolidayDetail = async (flightRoom: any) => {
    const body = [
      {
        psw_result_id: flightRoom?.psw_result_id,
        roomids: flightRoom?.roomids,
        packageSearchId: flightRoom.packageSearchId,
      },
    ];
    let res = await dispatch(fetchAsyncHolidayPackageDetails(body));
    res = res.payload;
    return res;
  };

  const setInitialDeparture = (e: string, code?: boolean) => {
    let data =
      process.env.REACT_APP_PROJECT_COUNTRY === 'ie'
        ? [...IE_DEPARTURE]
        : process.env.REACT_APP_PROJECT_COUNTRY === 'us'
          ? [...US_DEPARTURE]
          : [...UK_DEPARTURE];
    data = data.map((item: any, index: number) => {
      return {
        ...item,
        selected: index === 0 ? true : false,
      };
    });

    let d = data?.map((item) => {
      code === true
        ? item.departure_airport === e
          ? (item.selected = true)
          : (item.selected = false)
        : item.full_name === e
          ? (item.selected = true)
          : (item.selected = false);
      return item;
    });
    return d;
  };

  const newSearch = async () => {
    await setBasketId(null);
    await clearSessionValues();
    await removeSessionStorage('basketId');
    await removeSessionStorage('search');
    await dispatch(setHolidayPackage({}));
    await dispatch(setFilteredHolidayPackage({}));
    await dispatch(
      setFilterState({
        holidayType: [],
        starRating: [],
        hotelName: '',
        price: [],
        sorting: 'Popularity',
        regionType: [],
      })
    );
    await dispatch(
      setFlightRoomState({
        ...flightRoom,
        psw_result_id: null,
        searchCriteria: {
          departure: {
            depart_airport_name: 'London All Airports',
            departure_airport: 'LON',
            full_name: 'London All Airports LON',
          },
          destination: null,
          // isAdditionalTransferAmountAdd: false,
          date: [dayjs().add(7, 'days').toISOString(), dayjs().add(14, 'days').toISOString()],
          child_age: {},
          guest: [
            {
              room: 1,
              adults: 2,
              children: [],
              infants: [],
            },
          ],
          class: 'Economy',
          night: dayjs().add(14, 'days').diff(dayjs().add(7, 'days'), 'day'),
          adults: 2,
          childrens: 0,
          infants: 0,
        },
      })
    );
    await setTimeout(() => {
      navigate('/');
    }, 1000);
  };

  const cancelSearch = async (url: any) => {
    await setBasketId(null);
    await clearSessionValues();
    // debugger
    await removeSessionStorage('basketId');
    await removeSessionStorage('search');
    await dispatch(setHolidayPackage({}));
    await dispatch(setFilteredHolidayPackage({}));
    await dispatch(
      setFilterState({
        holidayType: [],
        starRating: [],
        hotelName: '',
        price: [],
        sorting: 'Popularity',
        regionType: [],
      })
    );
    await dispatch(
      setFlightRoomState({
        ...flightRoom,
        psw_result_id: null,
        searchCriteria: {
          departure: {
            depart_airport_name: 'London All Airports',
            departure_airport: 'LON',
            full_name: 'London All Airports LON',
          },
          destination: null,
          isAdditionalTransferAmountAdd: false,
          date: [dayjs().add(7, 'days').toISOString(), dayjs().add(14, 'days').toISOString()],
          child_age: {},
          guest: [
            {
              room: 1,
              adults: 2,
              children: [],
              infants: [],
            },
          ],
          class: 'Economy',
          night: dayjs().add(14, 'days').diff(dayjs().add(7, 'days'), 'day'),
          adults: 2,
          childrens: 0,
          infants: 0,
        },
      })
    );
    await setTimeout(() => {
      navigate(`/holiday-summary/${url}`);
    }, 1000);
  };

  const holidayPackageStoreCriteria = (flightRoom: any, holiday: any) => {
    const criteria = flightRoom.searchCriteria;

    const adultPerRoom: any = [];
    const childPerRoom: any = [];
    const infantPerRoom: any = [];

    if (criteria.guest.length > 0) {
      criteria.guest?.map((r: any) => {
        adultPerRoom.push(r.adults.toString());
        childPerRoom.push(r.children.length.toString());
        infantPerRoom.push(r.infants.length.toString());
        return r;
      });
    }

    let holidayPackageJson: any = [
      {
        DestinationFrom: criteria.departure.departure_airport,
        Destination: `${criteria.destination.airport_code} ;${criteria.destination?.['Searc ID']}`,
        departure_date: criteria.date[0],
        rooms: criteria.guest.length,
        nights: criteria.night,
        cabin_class: criteria.class,
        adults: adultPerRoom,
        children: childPerRoom,
        infants: infantPerRoom,
        siteId: process.env.REACT_APP_SITE_ID,
        cheapestFareCat: 'IT',
        hotel_id: Number(flightRoom?.hotel_id),
        // hotel_name: flightRoom.roomData[0]?.SearchResult?.hotel_name
      },
      {
        ids: {
          flightSearchCriteriaId: Number(flightRoom.flightSearchCriteriaId),
          hotelSearchCriteriaIds: Number(flightRoom.hotelSearchCriteriaIds),
          selectedFlight: Number(flightRoom?.psw_result_id),
          hotelSearchCriteriaId_nextDay: Number(flightRoom?.hotelSearchCriteriaId_nextDay),
        },
        filter: {
          // "selected_flight_id": Number(flightRoom?.psw_result_id),
          selected_flight_id: Number(flightRoom.psw_result_id),
          selected_hotel_id: Number(flightRoom?.hotel_id),
        },
      },
    ];

    if (criteria?.childrens > 0) {
      holidayPackageJson[0].child_age = criteria?.child_age;
    }
    return holidayPackageJson;
  };

  useLayoutEffect(() => {
    /**
     * @Hook
       set holidayRetrieveApiCall to false when DOM is loaded
     */
    setSessionStorage('holidayRetrieveApiCall', 'false');
  }, [dispatch]);

  const renderOnLoadHolidayPackageApi = async (queryData: any) => {
    const adult = queryData.adult.split(',');
    dispatch(setLottieLoading(true));
    const cabinClass: CabinClass = capitalizeFirstLetterEachWords(queryData.cabin_class)
      .split('_')
      .join(' ') as CabinClass;
    const child_age =
      queryData?.child_age && queryData?.child_age?.length > 0 ? JSON.parse(queryData.child_age) : [];
    const children = queryData.children_count.split(',');
    const startDate = queryData.daterange.split(',')[0];
    const endDate = queryData.daterange.split(',')[1];
    let destination = queryData.destination;
    const infantAge =
      queryData.infant_age && queryData.infant_age !== 'undefined' && queryData.infant_age.length > 0
        ? JSON.parse(queryData.infant_age)
        : [];
    const infantCount = queryData.infant_count.split(',');
    const roomCount = queryData.room_count;
    const DestinationFrom = queryData.departure.toUpperCase();
    const des = queryData.destinationId || null;

    if (des) {
      const destinationData = DestinationList.filter((f) => Number(des) === f['Searc ID']);
      if (destinationData.length > 0) {
        destination = destinationData[0].airport_code + '_;' + destinationData[0]['Searc ID'];
      }
    }

    const destinationCode = destination.split('_').join('').toUpperCase();
    const desParts = destinationCode?.split(';');
    const desCodeName = desParts[0] + ' ;' + desParts[1];
    const hotelId = queryData.hotelId || queryData.hotel_id || null;

    let isDirectFlight: boolean = false;

    if (process.env.REACT_APP_PROJECT_COUNTRY === 'ie') {
      directFlightsIE.map((d: any) => {
        if (d.dep_code === DestinationFrom && d.des_code === isEmpty(desCodeName?.toUpperCase())) {
          isDirectFlight = true;
          return d;
        } else {
          return <></>;
        }
      });
    } else if (process.env.REACT_APP_PROJECT_COUNTRY === 'uk') {
      directFlights.map((d: any) => {
        if (d.dep_code === DestinationFrom && d.des_code === isEmpty(desCodeName?.toUpperCase())) {
          isDirectFlight = true;
          return d;
        } else {
          return <></>;
        }
      });
    }

    // Set Rooms
    let roomArr: any = [];
    for (let i = 0; i < Number(roomCount); i++) {
      let childArr: any = [];
      let infantArr: any = [];

      for (let j = 0; j < Number(children[i]); j++) {
        let child = {
          child: j + 1,
          age: Number(child_age[i][j + 1]),
        };

        childArr.push(child);
      }

      for (let j = 0; j < Number(infantCount[i]); j++) {
        let child = {
          child: j + 1,
          age: Number(infantAge[i][j + 1]),
        };

        infantArr.push(child);
      }

      let obj = {
        room: i + 1,
        adults: Number(adult[i]),
        children: childArr,
        infants: infantArr,
      };
      roomArr.push(obj);
    }

    // Set Departure
    const dep = setInitialDeparture(DestinationFrom, true);

    // Set Dates
    const date = [
      new DateObject(dayjs(reverseString(startDate)).format('YYYY/MM/DD')),
      new DateObject(dayjs(reverseString(endDate)).format('YYYY/MM/DD')),
    ];

    // Set Destination
    const selectedDestination = DestinationList.filter(
      (f) => f.airport_code.toUpperCase() + ';' + f['Searc ID'] === destinationCode
    );

    // set individual adults , infants and childrens
    const adults = roomArr?.reduce((a: number, b: any) => a + b?.adults, 0);
    const childrens = roomArr?.reduce(
      (a: number, b: any) =>
        a + b?.children?.filter((item: { age: string }) => Number(item?.age) >= 2)?.length || 0,
      0
    );
    const infants = roomArr?.reduce((a: number, b: any) => a + b?.infants?.length, 0);

    const date1: any = new Date(reverseString(startDate));
    const date2: any = new Date(reverseString(endDate));

    const timeDifference = date2 - date1;

    const nights = timeDifference / (1000 * 60 * 60 * 24);
    const isDubai =
      selectedDestination?.length > 0 ? selectedDestination[0]['Display Name #1'].includes('Dubai') : false;

    const totalChildren = roomArr.flatMap((el: any) => el.children);
    const hasAgeSelected = totalChildren?.every((el: any) => el.age);
    const totalInfantsChildren = roomArr.flatMap((el: any) => el.infants);
    const hasInfantAgeSelected = totalInfantsChildren?.every((el: any) => el.age);
    const totalGuestWithInfants = adults + childrens + infants;

    let isError = false;
    if (DestinationFrom === '') {
      isError = true;
    } else if (selectedDestination?.length === 0) {
      isError = true;
    } else if (isDubai === false && !(nights >= 3)) {
      isError = true;
    } else if (!hasAgeSelected) {
      isError = true;
    } else if (!hasInfantAgeSelected) {
      isError = true;
    } else if (totalGuestWithInfants === 0) {
      isError = true;
    } else if (isEmpty(cabinClass) === '') {
      isError = true;
    } else if (roomCount === '') {
      isError = true;
    } else if (queryData.adult === '') {
      isError = true;
    } else {
      isError = false;
    }

    if (hotelId === null) {
      window.location.href = '/' + window.location.search;
    }

    if (hasAgeSelected && hasInfantAgeSelected && selectedDestination?.length > 0 && !isError) {
      let body: any = [
        {
          DestinationFrom,
          Destination:
            selectedDestination?.length > 0
              ? selectedDestination[0].airport_code?.toUpperCase() + ' ;' + selectedDestination[0]['Searc ID']
              : '',
          departure_date: startDate,
          rooms: roomCount,
          nights: String(nights),
          cabin_class: CABIN_CLASS_ENUM[cabinClass],
          adults: adult,
          children: children,
          infants: infantCount,
          siteId: process.env.REACT_APP_SITE_ID,
          only_it_fares: true,
          cheapestFareCat: 'IT',
          hotel_id: hotelId,
          direct_flight_only: isDirectFlight ? 1 : 0,
          basketId: '0',
          only_pu_fares: false,
        },
      ];

      // if (queryData?.flight_search_criteria_id) {
      //   body[1] = {
      //     ids: {
      //       "flightSearchCriteriaId": Number(queryData.flight_search_criteria_id),
      //       "hotelSearchCriteriaIds": Number(queryData.hotel_search_criteria_ids),
      //       "selectedFlight": Number(queryData.selected_flight),
      //       "hotelSearchCriteriaId_nextDay": Number(queryData.hotel_search_criteria_id_nextDay),
      //     },
      //     filter: {
      //       // "selected_flight_id": Number(flightRoom?.psw_result_id),
      //       "selected_flight_id": Number(queryData.selected_flight),
      //       "selected_hotel_id": Number(queryData.hotel_id)
      //     }
      //   }
      // }

      if (childrens !== 0) {
        body[0].child_age = child_age;
      }
      if (infants !== 0) {
        body[0].infant_ages = infantAge;
      }

      setSessionStorage('search', JSON.stringify(body));
      setSessionStorage('room', JSON.stringify({ room: roomArr, date }));

      const { payload } = await dispatch(fetchAsyncHolidayPackages(body));

      const selectedDeparture = dep.filter((f) => f.selected);
      const { depart_airport_name, departure_airport, full_name } = selectedDeparture[0];
      setSessionStorage('more-details-click', 'true');

      if (payload?.Packages) {
        dispatch(setHolidayPackage(payload));
        const res = payload?.Packages?.results;

        if (res?.length > 0) {
          const step = 1;
          let filterHotel =
            hotelId !== null
              ? payload?.Packages?.results.filter((f: any) => f.SearchResult.hotel_id === hotelId)
              : res;
          filterHotel = filterHotel?.length > 0 ? filterHotel : res;
          const multiRoomIds = getRoomIds(
            sortRoom(
              hotelId !== null && step === 1
                ? filterHotel[0].SearchResult
                : payload.Packages.results[0].SearchResult,
              filters
            ),
            roomArr
          );
          let obj = {
            ...flightRoom,
            packageSearchId: payload.packageSearchId,
            flightSearchCriteriaId: payload.SearchCriteria[1].ids.flightSearchCriteriaId,
            hotelSearchCriteriaIds: payload.SearchCriteria[1].ids.hotelSearchCriteriaIds,
            hotelSearchCriteriaId_nextDay: payload.SearchCriteria[1].ids.hotelSearchCriteriaId_nextDay,
            psc_request_id: payload.flightSearchCriteriaId,
            psw_result_id: payload.FlightDetails.outbound.flights[0].psw_result_id,
            roomids: multiRoomIds,
            store_psw_result_id: payload.FlightDetails.outbound.flights[0].psw_result_id,
            hotel_name:
              hotelId !== null && step === 1
                ? filterHotel[0].SearchResult.hotel_name
                : res[0].SearchResult.hotel_name,
            hotel_id: hotelId !== null && step === 1 ? hotelId : res[0].SearchResult.hotel_id,
            selectHotelId:
              hotelId !== null && step === 1 ? filterHotel[0].SearchResult.id : res[0].SearchResult.id,
            search_result_id:
              hotelId !== null && step === 1 ? filterHotel[0].SearchResult : res[0].SearchResult.id,
            multiRooms:
              hotelId !== null && step === 1
                ? sortRoom(filterHotel[0].SearchResult, filters)
                : sortRoom(res[0].SearchResult, filters),
            storeMultiRoom:
              hotelId !== null && step === 1
                ? sortRoom(filterHotel[0].SearchResult, filters)
                : sortRoom(res[0].SearchResult, filters),
            roomData: hotelId !== null && step === 1 ? filterHotel : res,
            searchCriteria: {
              // isAdditionalTransferAmountAdd: selectedDestination.length > 0 ? selectedDestination[0]?.transfer_add : false,
              child_age: child_age,
              departure: {
                depart_airport_name,
                departure_airport,
                full_name,
                selected: true,
              },
              infant_age: infantAge,
              destination: selectedDestination[0],
              date: [startDate, endDate],
              guest: roomArr,
              class: cabinClass,
              cabin_class: cabinClass,
              night: nights,
              adults,
              childrens,
              infants,
            },
          };

          await dispatch(setFlightRoomState(obj));
          await fetchHolidayDetail(obj);
          await setHotelData(payload, obj);
          setIsDataLoaded(true);
          dispatch(setLoading(false));
          dispatch(setLottieLoading(false));
        } else {
          setIsDataLoaded(false);
          dispatch(setLoading(false));
          dispatch(setLottieLoading(false));
        }
      } else {
        setIsDataLoaded(false);
        dispatch(setHolidayPackage({}));
        // console.log(payload)
        dispatch(setLoading(false));
        dispatch(setLottieLoading(false));
      }
    } else {
      setIsDataLoaded(false);
      dispatch(setLottieLoading(false));
    }
  };

  const getRoomIds = (arr: any, guest: any) => {
    const roomsArr: any = arr.map((e: any) => e.id);
    let passengerDetails: any = {};

    let totalPassenger = [...Array(adults + childrens)].map((value, index) => (index + 1).toString());

    roomsArr.map((r: any, i: any) => {
      const totalLength = guest[i].adults + guest[i].children?.length;

      const totalPassengerPerRoom = [];

      for (let i = 0; i < totalLength; i++) {
        totalPassengerPerRoom.push(totalPassenger[i]);
      }

      totalPassenger.splice(0, totalLength);
      return (passengerDetails[r] = totalPassengerPerRoom.join(','));
    });
    return roomsArr.toString();
  };
  const sortingError = (data: any) => {
    const keysArray = Object.keys(data);

    keysArray.sort((a: any, b: any) => {
      const indexA: any = parseInt(a.match(/\[(\d+)\]/)[1]);
      const indexB: any = parseInt(b.match(/\[(\d+)\]/)[1]);

      return indexA - indexB;
    });

    return keysArray;
  };

  const isValidDate = (year: any, month: any, day: any) => {
    const date = new Date(year, month - 1, day);

    return (
      date.getFullYear() === Number(year) &&
      date.getMonth() === Number(month - 1) &&
      date.getDate() === Number(day)
    );
  };

  const updatedDeposit = (folder_no: any, depositAmount: any, itineararyNumber: string) => {
    const body = [
      {
        folder_no: folder_no,
        itinerary_id: itineararyNumber,
        deposit_due: depositAmount.replace(',', ''),
      },
    ];
    API.post(API_METHOD.Package.UPDATE_DEPOSIT_DUE, body)
      .then((result: any) => { })
      .catch((error: any) => {
        console.log('Error:', error);
      });
  };

  const validatePostalAddress = async (addressObj: any, addressError: any) => {
    await validationSchemaPostalAddress
      .validate(addressObj, { abortEarly: false })
      .then(() => {
        if (isShownAddress.addressManually) {
          addressError = {
            ...addressError,
            manuallyAddressErrors: staticObj,
            searchingAddressErrors: staticObj,
          };
        } else {
          addressError = {
            ...addressError,
            searchingAddressErrors: staticObj,
            manuallyAddressErrors: staticObj,
          };
        }
      })
      .catch((err) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });

        if (isShownAddress.addressManually) {
          addressError = {
            ...addressError,
            manuallyAddressErrors: errors,
            searchingAddressErrors: staticObj,
          };
        } else {
          addressError = {
            ...addressError,
            searchingAddressErrors: errors,
            manuallyAddressErrors: staticObj,
          };
        }
      });

    return await addressError;
  };

  const validateBillingAddress = async (billingAddressObj: any, addressError: any) => {
    await validationSchemaPostalAddress
      .validate(billingAddressObj, { abortEarly: false })
      .then(() => {
        if (isShownAddress.billingAddressManually) {
          addressError = {
            ...addressError,
            manuallyBillingErrors: staticObj,
            searchingBillingErrors: staticObj,
          };
        } else {
          addressError = {
            ...addressError,
            searchingBillingErrors: staticObj,
            manuallyBillingErrors: staticObj,
          };
        }
      })
      .catch((err) => {
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });

        if (isShownAddress.billingAddressManually) {
          addressError = {
            ...addressError,
            manuallyBillingErrors: errors,
            searchingBillingErrors: staticObj,
          };
        } else {
          addressError = {
            ...addressError,
            searchingBillingErrors: errors,
            manuallyBillingErrors: staticObj,
          };
        }
      });
    return await addressError;
  };

  const checkAllValuesTrue = (obj: any, checkValue: any) => {
    return Object.values(obj).every((e) => e === checkValue);
  };

  useEffect(() => {
    if (isShownAddress.isPostalAddress) {
      const mode = isShownAddress.addressManually ? 'manually' : 'searching';
      setBillingAddress((prevBillingAddress: any) => {
        return {
          ...prevBillingAddress,
          [mode]: {
            postcode: address[mode].postcode || prevBillingAddress[mode].postcode,
            street: address[mode].street || prevBillingAddress[mode].street,
            city: address[mode].city || prevBillingAddress[mode].city,
            county: address[mode].county || prevBillingAddress[mode].county,
            house: address[mode].house || prevBillingAddress[mode].house,
            country: address[mode].country || prevBillingAddress[mode].country,
          },
        };
      });
    }
  }, [address, isShownAddress.addressManually, isShownAddress.isPostalAddress]);

  const handleSubmit = async (e: any, isPayment: boolean) => {
    e.preventDefault();
    setPreventScroll(false);
    let submitRooms = await validateAdultsChildrenInfants(selectedOptions);
    // setSubmitForm(submitForm)
    setScrollToError(!scrollToError);

    let isShippingAddressValid = true;
    let addressError = addressBillingErrors;
    const infantAgeValid: any = childAndInfantAges.infantAgeValid;
    let isBillingAddressValid = true;

    // Check whether date is valid or not

    let fErrors = formErrors;
    fErrors = {};
    formData.map((f: any, i) => {
      if (
        isEmpty(f.day) !== '' &&
        isEmpty(f.month) !== '' &&
        isEmpty(f.year) !== '' &&
        f?.year?.length === 4 &&
        f?.day?.length <= 2 &&
        f?.month?.length <= 2
      ) {
        f.isDateValid = isValidDate(f.year, f.month, f.day);
        if (!f.isDateValid) {
          fErrors[`[${i}].day`] = 'Invalid day';
        }
      }
      return <></>;
    });

    setFormData(formData);
    setFormErrors(fErrors);

    let addressObj = {
      postcode: isShownAddress.addressManually ? address.manually.postcode : address.searching.postcode,
      house: isShownAddress.addressManually ? address.manually.house : address.searching.house,
      street: isShownAddress.addressManually ? address.manually.street : address.searching.street,
      city: isShownAddress.addressManually ? address.manually.city : address.searching.city,
      county: isShownAddress.addressManually ? address.manually.county : address.searching.county,
      country: isShownAddress.addressManually ? address.manually.country : address.searching.country,
    };

    let billingAddressObj = {
      postcode: isShownAddress.billingAddressManually
        ? billingAddess.manually.postcode
        : billingAddess.searching.postcode,
      house: isShownAddress.billingAddressManually
        ? billingAddess.manually.house
        : billingAddess.searching.house,
      street: isShownAddress.billingAddressManually
        ? billingAddess.manually.street
        : billingAddess.searching.street,
      city: isShownAddress.billingAddressManually
        ? billingAddess.manually.city
        : billingAddess.searching.city,
      county: isShownAddress.billingAddressManually
        ? billingAddess.manually.county
        : billingAddess.searching.county,
      country: isShownAddress.billingAddressManually
        ? billingAddess.manually.country
        : billingAddess.searching.country,
    };

    addressError = await validatePostalAddress(addressObj, addressError);
    addressError = await validateBillingAddress(billingAddressObj, addressError);
    isShippingAddressValid = isShownAddress.addressManually
      ? checkAllValuesTrue(addressError.manuallyAddressErrors, ''.trim())
      : checkAllValuesTrue(addressError.searchingAddressErrors, ''.trim());
    isBillingAddressValid = isShownAddress.isPostalAddress
      ? true
      : isShownAddress.billingAddressManually
        ? checkAllValuesTrue(addressError.manuallyBillingErrors, ''.trim())
        : checkAllValuesTrue(addressError.searchingBillingErrors, ''.trim());

    await validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        if (formData.every((item: any) => item.isDateValid) === true) {
          setFormErrors({});
        }

        const childAgeValid: any = childAndInfantAges.childAgeValids;
        let containsFalseValue = false;

        for (const key in childAgeValid) {
          for (const innerKey in childAgeValid[key]) {
            if (childAgeValid[key][innerKey] === false) {
              containsFalseValue = true;
              break;
            }
          }
          if (containsFalseValue) {
            break;
          }
        }

        let infantChildValue = false;

        for (const key in infantAgeValid) {
          for (const innerKey in infantAgeValid[key]) {
            if (infantAgeValid[key][innerKey] === false) {
              infantChildValue = true;
              break;
            }
          }
          if (infantChildValue) {
            break;
          }
        }

        addressError = {
          ...addressError,
          showBasicErrors: true,
        };
        if (
          !containsFalseValue &&
          !infantChildValue &&
          isShippingAddressValid &&
          isBillingAddressValid &&
          formData.every((item: any) => item.isDateValid) === true
        ) {
          if (
            (flightRoom.multiRooms?.length < 2 || (flightRoom.multiRooms?.length > 1 && submitRooms)) &&
            formData.every((item: any) => item.isDateValid) === true
          ) {
            dispatch(setLoading(true));
            addressError = {
              ...addressError,
              showBasicErrors: false,
            };
            if (formData.every((item: any) => item.isDateValid) === true) {
              setFormErrors({});
            }

            if (isPayment) {
              postUserDetailsAPI(isPayment);
            } else {
              saveItinaryFolderApiCall();
            }
          }
        } else {
          if (formData.every((item: any) => item.isDateValid) === true) {
            setFormErrors({});
          }

          addressError = {
            ...addressError,
            showBasicErrors: true,
          };
        }
      })
      .catch((err) => {
        addressError = {
          ...addressError,
          showBasicErrors: true,
        };
        const errors: any = {};

        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });

        formData.map((f: any, i) => {
          if (!f.isDateValid) {
            err.inner.forEach((e: any) => {
              if (e.path !== `[${i}].day` && fErrors[`[${i}].day`]) {
                errors[`[${i}].day`] = fErrors[`[${i}].day`];
              }
            });
          }
          return <></>;
        });

        dispatch(setLoading(false));
        setFormErrors(errors);
        dispatch(setLoading(false));
        setFormErrors(errors);
      });

    await setAddressBillingErrors(addressError);
  };

  const handleAddressChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;

    let obj = { ...address };

    if (isShownAddress.addressManually) {
      obj.manually = {
        ...address.manually,
        [name]: value,
      };
    } else {
      obj.searching = {
        ...address.searching,
        [name]: value,
      };
    }

    setAddress(obj);

    // If Billing -> Same as postal address
    // if (isShownAddress.isPostalAddress) {
    //   if (isShownAddress.billingAddressManually) {
    //     setBillingAddress({
    //       ...billingAddess,
    //       manually: {
    //         ...billingAddess.manually,
    //         [name]: value,
    //       },
    //     });
    //   } else {
    //     setBillingAddress({
    //       ...billingAddess,
    //       searching: {
    //         ...billingAddess.manually,
    //         [name]: value,
    //       },
    //     });
    //   }
    // }

    if (validation) {
      const addressError = await validatePostalAddress(
        isShownAddress.addressManually ? obj.manually : obj.searching,
        addressBillingErrors
      );
      await setAddressBillingErrors(addressError);
    }
  };

  const handleBillingAddressChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;

    let obj = { ...billingAddess };

    if (isShownAddress.billingAddressManually) {
      obj.storeManualBillingData = {
        ...billingAddess.storeManualBillingData,
        [name]: value,
      };
      obj.manually = {
        ...billingAddess.manually,
        [name]: value,
      };
    } else {
      obj.storeSearchingBillingData = {
        ...billingAddess.storeSearchingBillingData,
        [name]: value,
      };
      obj.searching = {
        ...billingAddess.searching,
        [name]: value,
      };
    }
    setBillingAddress(obj);
    if (validation) {
      const addressError = await validateBillingAddress(
        isShownAddress.billingAddressManually ? obj.manually : obj.searching,
        addressBillingErrors
      );
      await setAddressBillingErrors(addressError);
    }
  };

  const updateOptions = async (inputValue: any, isAddress: boolean) => {
    let opt = options;
    dispatch(setLoading(true));
    const val = inputValue.replace(/, /g, ',').split(',');

    const res = await dispatch(getPostalAddress(JSON.stringify(val)));

    const errors = addressBillingErrors;

    if (res?.payload?.length > 0) {
      let arr = [...res?.payload];
      let newArr: any = [];
      arr.map((r) => {
        let obj = {
          label2:
            r.PostCode +
            ',' +
            (r.Property === '' ? '' : r.Property + ',') +
            r.Street +
            ',' +
            r.Town +
            ',' +
            r.Country,
          label: r.PostCode,
          property: r.Property,
          city: r.Town,
          street: r.Street,
          county: r.County,
          country: r.Country,
          postcode: r.PostCode,
          value: r.PostCode,
        };
        newArr.push(obj);
        return r;
      });

      const filterData: any = [...newArr];
      if (isAddress) {
        opt = {
          ...opt,
          address: filterData,
        };

        errors.searchingAddressErrors = {
          postcode: '',
          street: '',
          city: '',
          country: '',
          house: '',
        };
        errors.postalCodeError = '';
        setAddress({
          ...address,
          index: -1,
        });
      } else {
        opt = {
          ...opt,
          billingAddess: filterData,
        };
        errors.billingPostcodeError = '';
        setBillingAddress({
          ...billingAddess,
          index: -1,
        });
      }

      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
      if (isAddress) {
        opt = {
          ...opt,
          address: [],
        };
        setAddress({
          ...address,
          searching: {
            postcode: '',
            street: '',
            city: '',
            county: '',
            house: '',
            country: '',
          },
          index: -1,
        });
        errors.postalCodeError = errors.errors.postcode;
      } else {
        opt = {
          ...opt,
          billingAddess: [],
        };
        setBillingAddress({
          ...billingAddess,
          searching: {
            postcode: '',
            street: '',
            city: '',
            county: '',
            house: '',
            country: '',
          },
          storeSearchingBillingData: {
            postcode: '',
            street: '',
            city: '',
            county: '',
            house: '',
            country: '',
          },
          index: -1,
        });
        errors.billingPostcodeError = errors.errors.postcode;
      }
    }
    setAddressBillingErrors(errors);
    setOptions(opt);
  };

  const saveItinaryFolderApiCall = async () => {
    setFormErrors({});
    try {
      const userRequests = getUserRequests();
      const response = isFolderAlreadyAdded ? true : await dispatch(postUserFolderDetails(userRequests));
      if (response) {
        const folderNo = isFolderAlreadyAdded ? queryData.folder : response.payload;
        const createdFolderNumber = createFolderDetails(folderNo);
        const depositAmount: any = (holiday.deposit as any).replace(signForPricing, '');

        const addDetailsResponse = await dispatch(addHotelFlightDetailsToFolder(createdFolderNumber));
        if (addDetailsResponse) {
          try {
            const itineararyNumber = createdFolderNumber[0].itineraryNumber;
            // if (holiday.isDepositPayment) {
            //   await updatedDeposit(folderNo, depositAmount, itineararyNumber)
            // }
            const resultConfirmItinerary = await conFirmItenary(folderNo, '1', itineararyNumber);
            const destinationFrom = flightRoom.searchCriteria2[0].DestinationFrom;
            const destination = flightRoom.searchCriteria2[0].Destination.split(' ').join('_');
            const rooms = flightRoom.searchCriteria2[0].rooms;
            const cabin_class = flightRoom.searchCriteria.class.includes('_')
              ? flightRoom.searchCriteria.class
              : flightRoom.searchCriteria.class.split(' ').join('_');
            const adults = flightRoom.searchCriteria2[0].adults.join(',');
            const children = flightRoom.searchCriteria2[0].children.join(',');
            const infants = flightRoom.searchCriteria2[0].infants.join(',');
            const hotel_id = flightRoom.searchCriteria2[0].hotel_id;

            const flightSearchCriteriaId = flightRoom.searchCriteria2[1].ids.flightSearchCriteriaId;
            const hotelSearchCriteriaIds = flightRoom.searchCriteria2[1].ids.hotelSearchCriteriaIds;
            const selectedFlight = flightRoom.searchCriteria2[1].ids.selectedFlight;
            const hotelSearchCriteriaId_nextDay =
              flightRoom.searchCriteria2[1].ids.hotelSearchCriteriaId_nextDay;
            const date = flightRoom.searchCriteria.date.join(',');
            const childAge = JSON.stringify(flightRoom.searchCriteria.child_age);
            const infantAges = JSON.stringify(flightRoom.searchCriteria.infant_age);

            if (resultConfirmItinerary.success) {
              if (holiday.isDepositPayment) {
                await updatedDeposit(folderNo, depositAmount, itineararyNumber);
              }
              await summaryApi(folderNo, Number(itineararyNumber));
              await updateSellPrice(folderNo, itineararyNumber);
              const params = [
                {
                  id: folderNo,
                  branch_code: branchCode,
                  folder_no: folderNo,
                  packageSearchId: flightRoom.packageSearchId,
                },
              ];
              const { payload } = await dispatch(getFolder(params));
              if (payload) {
                const data = [...formData];
                data.map((e) => {
                  e.day = null;
                  e.email = '';
                  e.fname = '';
                  e.gender = '';
                  e.mname = '';
                  e.month = null;
                  e.pnumber = '';
                  e.sname = '';
                  e.title = '';
                  e.year = null;
                  return e;
                });

                setFormData(data);
                setComments('');
                setAddress({
                  manually: {
                    postcode: '',
                    street: '',
                    city: '',
                    county: '',
                    house: '',
                    country: '',
                  },
                  searching: {
                    postcode: '',
                    street: '',
                    city: '',
                    county: '',
                    house: '',
                    country: '',
                  },
                  index: -1,
                });
                setValidation(false);
                setBillingAddress({
                  storeManualBillingData: {
                    postcode: '',
                    street: '',
                    city: '',
                    county: '',
                    house: '',
                    country: '',
                  },
                  storeSearchingBillingData: {
                    postcode: '',
                    street: '',
                    city: '',
                    county: '',
                    house: '',
                    country: '',
                  },
                  manually: {
                    postcode: '',
                    street: '',
                    city: '',
                    county: '',
                    house: '',
                    country: '',
                  },
                  searching: {
                    postcode: '',
                    street: '',
                    city: '',
                    county: '',
                    house: '',
                    country: '',
                  },
                  index: -1,
                });
                setTravelPurpose('');
                setFormErrors({});
                dispatch(setLoading(false));
                if (isLoginUser) {
                  navigate('/profile/dashboard');
                } else {
                  navigate('/signin');
                }
              }
            } else {
              dispatch(setLoading(false));
              dispatch(setLottieLoading(false));
              const priceDif = resultConfirmItinerary?.result?.validateflights?.priceDif;
              const validateFlightsStatus = resultConfirmItinerary.result.validateflights.status;
              const validateHotelStatus = resultConfirmItinerary.result.validatehotel.status;
              let swalText = '';
              if (validateFlightsStatus === 'false' && validateHotelStatus === 'false') {
                swalText =
                  'Your chosen flight and hotel room are not available anymore, please update to an alternative flight and room or alternatively you can do a new search.';
              } else if (validateFlightsStatus === 'false') {
                swalText =
                  'Your chosen flight is not available anymore, please change the flight or alternatively you can do a new search.';
              } else if (validateHotelStatus === 'false') {
                swalText =
                  'Your chosen hotel room is not available anymore, please update to another room or alternatively you can do a new search.';
              }
              if (resultConfirmItinerary?.priceChange) {
                Swal.fire({
                  title: 'Price Changed',
                  text: `The overall package price has increased by ${signForPricing}${priceDif}.\n This is due to an increase in one or more elements of your itinerary.`,
                  icon: 'warning',
                  // showCancelButton: false,
                  showCancelButton: true, //Confirm ititnery
                  confirmButtonColor: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor
                    : colors.basics.primary,
                  cancelButtonColor: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor
                    : colors.basics.primary,
                  confirmButtonText: 'New Search',
                  cancelButtonText: 'Accept New Price', //Confirm ititnery
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  customClass: {
                    confirmButton: 'confirmButton',
                    actions: 'actions',
                    icon: isPlataniumHotel ? 'platinum-icon-class' : 'primary-icon-class',
                    cancelButton: isPlataniumHotel ? 'platinum-cancel-button' : 'primary-cancel-button',
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    newSearch();
                  } else if (result.isDismissed) {
                    updatePDPrice(folderNo, itineararyNumber, depositAmount, priceDif);
                  }
                });
              } else {
                Swal.fire({
                  title: 'Something Went Wrong',
                  text: swalText,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor
                    : colors.basics.primary,
                  cancelButtonColor: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor
                    : colors.basics.primary,
                  confirmButtonText: 'New Search',
                  cancelButtonText: 'Change Ititnery',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  customClass: {
                    confirmButton: 'confirmButton',
                    actions: 'actions',
                    icon: isPlataniumHotel ? 'platinum-icon-class' : 'primary-icon-class',
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    newSearch();
                  } else if (result.isDismissed) {
                    // const url = `${process.env.REACT_APP_LIVE_PAYMENT_URL}?folder=${session_id}&branch=${branchCode}&itinerary=${session_id}&s=b&session_id=${session_id}&departure=${destinationFrom}&destination=${destination}&room_count=${rooms}&cabin_class=${cabin_class}&adult=${adults}&children_count=${children}&infant_count=${infants}&hotel_id=${hotel_id}&flight_search_criteria_id=${flightSearchCriteriaId}&hotel_search_criteria_ids=${hotelSearchCriteriaIds}&selected_flight=${selectedFlight}&hotel_search_criteria_id_nextDay=${hotelSearchCriteriaId_nextDay}&daterange=${date}&child_age=${childAge}&infant_age=${infantAges}`;
                    // console.log('url :', url);
                    // const url1 = `/holiday-summary/${session_id}?folder=${folderNo}&itinerary_number=${session_id}&s=b&departure=${destinationFrom}&destination=${destination}&room_count=${rooms}&cabin_class=${cabin_class}&adult=${adults}&children_count=${children}&infant_count=${infants}&hotel_id=${hotel_id}&flight_search_criteria_id=${flightSearchCriteriaId}&hotel_search_criteria_ids=${hotelSearchCriteriaIds}&child_age=${childAge}&selected_flight=${selectedFlight}&infant_age=${infantAges}&hotel_search_criteria_id_nextDay=${hotelSearchCriteriaId_nextDay}&daterange=${date}`
                    // console.log('url1 :', url1);
                    // window.location.reload();
                    const url = `${session_id}?folder=${folderNo}&itinerary_number=${itineararyNumber}&s=b&departure=${destinationFrom}&destination=${destination}&room_count=${rooms}&cabin_class=${cabin_class}&adult=${adults}&children_count=${children}&infant_count=${infants}&hotel_id=${hotel_id}&flight_search_criteria_id=${flightSearchCriteriaId}&hotel_search_criteria_ids=${hotelSearchCriteriaIds}&child_age=${childAge}&selected_flight=${selectedFlight}&infant_age=${infantAges}&hotel_search_criteria_id_nextDay=${hotelSearchCriteriaId_nextDay}&daterange=${date}&confirmitinearary_fails=true&holiday_details_fails=${holidayDetailsResults}`;
                    cancelSearch(url);
                  }
                });
              }
            }
          } catch (e) { }
        } else {
          dispatch(setLoading(false));
        }
      }
    } catch (error) {
      // Handle error appropriately
      console.error('Error:', error);
      dispatch(setLoading(false));
    }
  };

  const dayOnChangeValidation = (form: any, evt: any, i: any) => {
    let childsAndInfant = childAndInfantAges;
    let childObj: any = childsAndInfant.childAgeValids;
    let infantObj = childAndInfantAges.infantAgeValid;
    const checkOut = holiday?.packageDate?.checkout;
    if (onlyNumber(evt.target.value) && evt.target.value?.length <= 2) {
      if (form.pax_type === 'CHD') {
        const childAge = childsAndInfant.childAges[form.id - 1];
        const isZero =
          evt.target.value === '00'
            ? null
            : evt.target.value.includes('0') && evt.target.value?.length === 1
              ? null
              : evt.target.value;
        const isMonthZero =
          form.month === null || form.month === '00'
            ? null
            : form.month.includes('0') && form.month?.length === 1
              ? null
              : form.month;
        const yearValidation =
          form.year === null || form.year === '0000'
            ? null
            : form.year.includes('0000') && form.year?.length === 4
              ? null
              : form.year;
        // const age = calculateAge(isZero, isMonthZero, yearValidation)
        const checkOut = holiday?.packageDate?.checkout;
        const checkOutAge = calculateAge(isZero, isMonthZero, yearValidation, checkOut);

        handleFormChangeData(i, evt);

        if (checkOutAge === childAge) {
          childsAndInfant = {
            ...childsAndInfant,
            childAgeValids: {
              ...childObj,
              [form.id]: {
                [form.id]: true,
                index: i,
              },
            },
          };
        } else {
          childsAndInfant = {
            ...childsAndInfant,
            childAgeValids: {
              ...childObj,
              [form.id]: {
                [form.id]: false,
                index: i,
              },
            },
          };
        }
      } else if (form.pax_type === 'INF') {
        const childAge = childsAndInfant.infantAges[form.id - 1];

        const isZero =
          evt.target.value === '00'
            ? null
            : evt.target.value.includes('0') && evt.target.value?.length === 1
              ? null
              : evt.target.value;
        const isMonthZero =
          form.month === null || form.month === '00'
            ? null
            : form.month.includes('0') && form.month?.length === 1
              ? null
              : form.month;
        const yearValidation =
          form.year === null || form.year === '0000'
            ? null
            : form.year.includes('0000') && form.year?.length === 4
              ? null
              : form.year;

        // const age = calculateInfantAge(isZero, isMonthZero, yearValidation)
        const checkOutAge = calculateInfantAge(isZero, isMonthZero, yearValidation, checkOut);
        handleFormChangeData(i, evt);

        if (checkOutAge === childAge) {
          childsAndInfant = {
            ...childsAndInfant,
            infantAgeValid: {
              ...infantObj,
              [form.id]: {
                [form.id]: true,
                index: i,
              },
            },
          };
        } else {
          childsAndInfant = {
            ...childsAndInfant,
            infantAgeValid: {
              ...infantObj,
              [form.id]: {
                [form.id]: false,
                index: i,
              },
            },
          };
        }
      } else {
        handleFormChangeData(i, evt);
      }
    }
    setChildAndInfantAges(childsAndInfant);
  };

  const monthOnChangeValidation = (form: any, evt: any, i: any) => {
    let childsAndInfant = childAndInfantAges;
    let childObj: any = childsAndInfant.childAgeValids;
    let infantObj = childAndInfantAges.infantAgeValid;
    const checkOut = holiday?.packageDate?.checkout;
    if (onlyNumber(evt?.target?.value) && evt?.target?.value?.length <= 2) {
      if (form.pax_type === 'CHD') {
        const childAge = childsAndInfant.childAges[form.id - 1];

        const isZero =
          evt.target.value === '00'
            ? null
            : evt.target.value.includes('0') && evt?.target?.value?.length === 1
              ? null
              : evt.target.value;
        const dayValidation =
          form.day === null || form.day === '00'
            ? null
            : form.day.includes('0') && form?.day?.length === 1
              ? null
              : form.day;
        const yearValidation =
          form.year === null || form.year === '0000'
            ? null
            : form.year.includes('0000') && form?.year?.length === 4
              ? null
              : form.year;
        // const age = calculateAge(dayValidation, isZero, yearValidation)
        const checkOut = holiday?.packageDate?.checkout;
        const checkOutAge = calculateAge(dayValidation, isZero, yearValidation, checkOut);
        handleFormChangeData(i, evt);

        if (checkOutAge === childAge) {
          childsAndInfant = {
            ...childsAndInfant,
            childAgeValids: {
              ...childObj,
              [form.id]: {
                [form.id]: true,
                index: i,
              },
            },
          };
        } else {
          childsAndInfant = {
            ...childsAndInfant,
            childAgeValids: {
              ...childObj,
              [form.id]: {
                [form.id]: false,
                index: i,
              },
            },
          };
        }
      } else if (form.pax_type === 'INF') {
        const childAge = childsAndInfant.infantAges[form.id - 1];

        const isZero =
          evt.target.value === '00'
            ? null
            : evt.target.value.includes('0') && evt?.target?.value?.length === 1
              ? null
              : evt.target.value;
        const dayValidation =
          form.day === null || form.day === '00'
            ? null
            : form.day.includes('0') && form?.day?.length === 1
              ? null
              : form.day;
        const yearValidation =
          form.year === null || form.year === '0000'
            ? null
            : form.year.includes('0000') && form?.year?.length === 4
              ? null
              : form.year;

        // const age = calculateInfantAge(dayValidation, isZero, yearValidation)
        const checkOutAge = calculateInfantAge(dayValidation, isZero, yearValidation, checkOut);
        handleFormChangeData(i, evt);

        if (checkOutAge === childAge) {
          childsAndInfant = {
            ...childsAndInfant,
            infantAgeValid: {
              ...infantObj,
              [form.id]: {
                [form.id]: true,
                index: i,
              },
            },
          };
        } else {
          childsAndInfant = {
            ...childsAndInfant,
            infantAgeValid: {
              ...infantObj,
              [form.id]: {
                [form.id]: false,
                index: i,
              },
            },
          };
        }
      } else {
        handleFormChangeData(i, evt);
      }
    }

    setChildAndInfantAges(childsAndInfant);
  };

  const yearOnChangeValidation = (form: any, evt: any, i: any) => {
    const value = evt.target.value;
    let childsAndInfant = childAndInfantAges;
    let childObj: any = childsAndInfant.childAgeValids;
    let infantObj = childAndInfantAges.infantAgeValid;
    const checkOut = holiday?.packageDate?.checkout;

    if (onlyNumber(evt.target.value) && evt?.target?.value?.length <= 4) {
      if (form.pax_type === 'CHD') {
        const childAge = childsAndInfant.childAges[form.id - 1];

        const dayValidation =
          form.day === null || form.day === '00'
            ? null
            : form.day.includes('0') && form?.day?.length === 1
              ? null
              : form.day;
        const monthValidation =
          form.month === null || form.month === '00'
            ? null
            : form.month.includes('0') && form?.month?.length === 1
              ? null
              : form.month;
        // const age = calculateAge(dayValidation, monthValidation, value)
        const checkOut = holiday?.packageDate?.checkout;
        // const checkOutAge = calculateAge(dayValidation, monthValidation,value,checkOut)
        const checkOutAge = calculateAge(dayValidation, monthValidation, value, checkOut);
        handleFormChangeData(i, evt);

        if (checkOutAge === childAge) {
          childsAndInfant = {
            ...childsAndInfant,
            childAgeValids: {
              ...childObj,
              [form.id]: {
                [form.id]: true,
                index: i,
              },
            },
          };
        } else {
          childsAndInfant = {
            ...childsAndInfant,
            childAgeValids: {
              ...childObj,
              [form.id]: {
                [form.id]: false,
                index: i,
              },
            },
          };
        }
      } else if (form.pax_type === 'INF') {
        const childAge = childsAndInfant.infantAges[form.id - 1];
        const dayValidation =
          form.day === null || form.day === '00'
            ? null
            : form.day.includes('0') && form?.day?.length === 1
              ? null
              : form.day;
        const monthValidation =
          form.month === null || form.month === '00'
            ? null
            : form.month.includes('0') && form?.month?.length === 1
              ? null
              : form.month;

        // const age = calculateInfantAge(dayValidation, monthValidation, value)
        const checkOutAge = calculateInfantAge(dayValidation, monthValidation, value, checkOut);

        handleFormChangeData(i, evt);

        if (checkOutAge === childAge) {
          childsAndInfant = {
            ...childsAndInfant,
            infantAgeValid: {
              ...infantObj,
              [form.id]: {
                [form.id]: true,
                index: i,
              },
            },
          };
        } else {
          childsAndInfant = {
            ...childsAndInfant,
            infantAgeValid: {
              ...infantObj,
              [form.id]: {
                [form.id]: false,
                index: i,
              },
            },
          };
        }
      } else {
        handleFormChangeData(i, evt);
      }
    }
    setChildAndInfantAges(childsAndInfant);
  };

  const validateAdultsChildrenInfants = async (selectedOptions: any) => {
    const errors: any = [];
    const roomsError: any = [...roomErrorsArray];
    const message = `Room Allocation is required, please allocate this person to a room`;

    selectedOptions.forEach((room: any, roomIndex: number) => {
      const expectedAdults = flightRoom.searchCriteria.guest[roomIndex].adults;
      const expectedChildren = flightRoom.searchCriteria.guest[roomIndex].children.length;
      const expectedInfants = flightRoom.searchCriteria.guest[roomIndex].infants.length;
      const roomErrors = {
        adults: Array(expectedAdults).fill(''),
        children: Array(expectedChildren).fill(''),
        infants: Array(expectedInfants).fill(''),
      };

      for (let index = 0; index < expectedAdults; index++) {
        if (!room.adults[index] || room.adults[index].name === '') {
          roomErrors.adults[index] = message;
        }
      }

      for (let index = 0; index < expectedChildren; index++) {
        if (!room.children[index] || room.children[index].name === '') {
          roomErrors.children[index] = message;
        }
      }

      for (let index = 0; index < expectedInfants; index++) {
        if (!room.infants[index] || room.infants[index].name === '') {
          roomErrors.infants[index] = message;
        }
      }

      errors.push({ roomIndex, ...roomErrors });
      roomsError[roomIndex] = { ...roomErrors };
    });

    setRoomArray(roomsError);
    const result = await areAllArraysEmpty(roomErrorsArray);
    setSubmitForm(result);
    return result;
  };

  const areAllArraysEmpty = (objArray: any) => {
    for (const obj of objArray) {
      if (
        obj.adults.filter((item: any) => item.trim() !== '').length > 0 ||
        obj.children.filter((item: any) => item.trim() !== '').length > 0 ||
        obj.infants.filter((item: any) => item.trim() !== '').length > 0
      ) {
        return false;
      }
    }
    return true;
  };

  const handleSelect = (e: any, name: any, value: any, index: any) => {
    const updatedFormData: any = [...formData];
    updatedFormData[index]['gender'] = value === 'Mr' ? 'M' : 'F';
    updatedFormData[index][name] = value;
    setFormData(updatedFormData);

    let fErrors = formErrors;
    fErrors = {};

    updatedFormData.map((f: any, i: any) => {
      if (
        isEmpty(f.day) !== '' &&
        isEmpty(f.month) !== '' &&
        isEmpty(f.year) !== '' &&
        f.year.length === 4 &&
        f.day.length <= 2 &&
        f.month.length <= 2
      ) {
        f.isDateValid = isValidDate(f.year, f.month, f.day);
        if (!f.isDateValid) {
          fErrors[`[${i}].day`] = 'Invalid day';
        }
      }
      return <></>;
    });
    setFormErrors(fErrors);

    validation &&
      validationSchema
        .validate(updatedFormData, { abortEarly: false })
        .then(() => { })
        .catch((err) => {
          const errors: any = {};

          formData.map((f: any, i) => {
            if (!f.isDateValid) {
              err.inner.forEach((e: any) => {
                if (e.path !== `[${i}].day` && fErrors[`[${i}].day`]) {
                  errors[`[${i}].day`] = fErrors[`[${i}].day`];
                }
              });
            }
            return <></>;
          });
          err.inner.forEach((e: any) => {
            errors[e.path] = e.message;
          });

          dispatch(setLoading(false));
          setFormErrors(errors);
        });
  };

  const postalAddressManually = (e: any) => {
    let obj = isShownAddress;

    obj = {
      ...obj,
      addressManually: !obj.addressManually,
    };

    if (e.target.checked) {
      obj = {
        ...obj,
        hidePostalAddress: false,
      };
    } else {
      if (address.searching.postcode !== '') {
        obj = {
          ...obj,
          hidePostalAddress: false,
        };
      } else {
        obj = {
          ...obj,
          hidePostalAddress: true,
        };
      }
    }

    let addressError = addressBillingErrors;
    if (validation) {
      const newAddressErrors = {
        postcode: '',
        street: '',
        city: '',
        country: '',
        house: '',
      };

      let address1 = e.target.checked ? address.manually : address.searching;

      if (!address1.postcode) {
        newAddressErrors.postcode = addressError.errors.postcode;
      }
      if (!address1.street) {
        newAddressErrors.street = addressError.errors.street;
      }
      if (!address1.city) {
        newAddressErrors.city = addressError.errors.city;
      }
      if (!address1.country) {
        newAddressErrors.country = addressError.errors.country;
      }
      if (!address1.house) {
        newAddressErrors.house = addressError.errors.house;
      }

      if (e.target.checked) {
        addressError = {
          ...addressError,
          manuallyAddressErrors: newAddressErrors,
          postalCodeError: '',
        };
      } else {
        addressError = {
          ...addressError,
          searchingAddressErrors: newAddressErrors,
          postalCodeError: '',
        };
      }
    }
    setAddressBillingErrors(addressError);
    setisShownAddress(obj);
  };

  const sameAsPostalAddress = (e: any) => {
    let obj = isShownAddress;

    if (e.target.checked) {
      obj = {
        ...obj,
        showBillingAddress: true,
        billingAddressManually: false,
        hideBillingAddress: true,
      };

      setBillingAddress({
        ...billingAddess,
        manually: isShownAddress.addressManually ? address.manually : address.searching,
        searching: isShownAddress.addressManually ? address.manually : address.searching,
      });
    } else {
      obj = {
        ...obj,
        showBillingAddress: true,
      };

      if (!isShownAddress.billingAddressManually) {
        if (billingAddess.storeSearchingBillingData.postcode !== '') {
          obj = {
            ...obj,
            hideBillingAddress: false,
          };
        } else {
          obj = {
            ...obj,
            hideBillingAddress: true,
          };
        }
      }

      setBillingAddress({
        ...billingAddess,
        manually: billingAddess.storeManualBillingData,
        searching: billingAddess.storeSearchingBillingData,
      });
    }
    obj = {
      ...obj,
      isPostalAddress: e.target.checked,
    };
    setisShownAddress(obj);
  };

  const billingManually = (e: any) => {
    if (e.target.checked) {
      setisShownAddress({
        ...isShownAddress,
        hideBillingAddress: true,
        billingAddressManually: e.target.checked,
        isPostalAddress: false,
      });
      setBillingAddress({
        ...billingAddess,
        manually: { postcode: '', street: '', city: '', county: '', house: '', country: '' },
      });
    } else {
      if (billingAddess.searching.postcode !== '') {
        setisShownAddress({
          ...isShownAddress,
          hideBillingAddress: false,
          billingAddressManually: e.target.checked,
        });
      } else {
        setisShownAddress({
          ...isShownAddress,
          hideBillingAddress: true,
          billingAddressManually: e.target.checked,
          isPostalAddress: true,
        });
      }
    }

    if (validation) {
      let isBillingAddressValid = true;
      let addressError = addressBillingErrors;
      const newBillingAddressErrors = {
        postcode: '',
        street: '',
        city: '',
        country: '',
        house: '',
      };

      let billingAddress1 = e.target.checked ? billingAddess.manually : billingAddess.searching;

      if (!billingAddress1.city) {
        newBillingAddressErrors.city = addressError.errors.city;
        isBillingAddressValid = false;
      }
      if (!billingAddress1.country) {
        newBillingAddressErrors.country = addressError.errors.country;
        isBillingAddressValid = false;
      }
      if (!billingAddress1.postcode) {
        newBillingAddressErrors.postcode = addressError.errors.postcode;
        isBillingAddressValid = false;
      }
      if (!billingAddress1.street) {
        newBillingAddressErrors.street = addressError.errors.street;
        isBillingAddressValid = false;
      }
      if (!billingAddress1.house) {
        newBillingAddressErrors.house = addressError.errors.house;
        isBillingAddressValid = false;
      }

      if (e.target.checked) {
        addressError = {
          ...addressError,
          manuallyBillingErrors: newBillingAddressErrors,
          billingPostcodeError: '',
        };
      } else {
        addressError = {
          ...addressError,
          searchingBillingErrors: newBillingAddressErrors,
          billingPostcodeError: '',
        };
      }

      setAddressBillingErrors(addressError);
    }
  };

  const extractNumbers = (str: string) => {
    const numbersArray = str.match(/\d+/g);
    if (numbersArray) {
      return numbersArray.join('');
    } else {
      return '';
    }
  };

  const extractWords = (str: string) => {
    const wordsArray = str.match(/[a-zA-Z]+/g);
    if (wordsArray) {
      return wordsArray.join(' ');
    } else {
      return '';
    }
  };

  const billingAutoCompleteOnChange = (event: any, newValue: any) => {
    let houseNumber =
      newValue.property === ''
        ? newValue.street !== ''
          ? extractNumbers(newValue.street.split(' ')[0]).length
            ? extractNumbers(newValue.street.split(' ')[0])
            : newValue.street.split(',')[0]
          : ''
        : newValue.property;
    // let houseNumber = newValue.property === "" ? newValue.street !== "" ? extractNumbers(newValue.street.split(" ")[0]) : "" : newValue.property
    let streetAddress =
      newValue.property === ''
        ? newValue.street !== ''
          ? newValue.street.split(',')[1]?.trim() || ''
          : ''
        : newValue.street;
    let result = newValue.street.split(' ').slice(1).join(' ');

    setBillingAddress({
      ...billingAddess,
      storeSearchingBillingData: {
        postcode: newValue.postcode,
        street: streetAddress !== '' ? streetAddress : streetAddress + result,
        city: newValue.city,
        county: newValue.county,
        country: newValue.country,
        house: houseNumber,
      },
      index: (event.target as any).attributes[3].nodeValue || 0,
      searching: {
        postcode: newValue.postcode,
        street: streetAddress !== '' ? streetAddress : streetAddress + result,
        city: newValue.city,
        county: newValue.county,
        country: newValue.country,
        house: houseNumber,
      },
    });
    setisShownAddress({
      ...isShownAddress,
      hideBillingAddress: false,
    });

    validation &&
      setAddressBillingErrors({
        ...addressBillingErrors,
        searchingBillingErrors: {
          ...addressBillingErrors.searchingBillingErrors,
          house: houseNumber === '' ? addressBillingErrors.errors.house : '',
          country: '',
          city: '',
          street: '',
          postcode: '',
        },
      });
  };

  const addressAutoCompleteOnChange = (event: any, newValue: any) => {
    let obj = isShownAddress;

    let houseNumber =
      newValue.property === ''
        ? newValue.street !== ''
          ? extractNumbers(newValue.street.split(' ')[0]).length
            ? extractNumbers(newValue.street.split(' ')[0])
            : newValue.street.split(',')[0]
          : ''
        : newValue.property;
    let streetAddress =
      newValue.property === ''
        ? newValue.street !== ''
          ? newValue.street.split(',')[1]?.trim() || ''
          : ''
        : newValue.street;
    let result = newValue.street.split(' ').slice(1).join(' ');

    setAddress({
      ...address,
      searching: {
        postcode: newValue.postcode,
        street: streetAddress !== '' ? streetAddress : streetAddress + result,
        city: newValue.city,
        county: newValue.county,
        country: newValue.country,
        house: houseNumber,
      },
      index: (event.target as any).attributes[3].nodeValue || 0,
    });

    if (isShownAddress.addressManually) {
      validation &&
        setAddressBillingErrors({
          ...addressBillingErrors,
          manuallyAddressErrors: {
            ...addressBillingErrors.manuallyAddressErrors,
            house: newValue.property === '' ? addressBillingErrors.errors.house : '',
          },
        });
    } else {
      validation &&
        setAddressBillingErrors({
          ...addressBillingErrors,
          searchingAddressErrors: {
            ...addressBillingErrors.searchingAddressErrors,
            house: newValue.street === '' ? addressBillingErrors.errors.house : '',
          },
        });
    }

    if (obj.isPostalAddress) {
      if (isShownAddress.billingAddressManually) {
        setBillingAddress({
          ...billingAddess,
          manually: {
            city: newValue.postcode,
            county: newValue.street,
            country: newValue.country,
            postcode: newValue.city,
            street: streetAddress !== '' ? streetAddress : streetAddress + result,
            house: houseNumber,
          },
        });
      } else {
        setBillingAddress({
          ...billingAddess,
          searching: {
            city: newValue.postcode,
            county: newValue.street,
            country: newValue.country,
            postcode: newValue.city,
            street: streetAddress !== '' ? streetAddress : streetAddress + result,
            house: houseNumber,
          },
        });
      }
    }
    obj = {
      ...obj,
      hidePostalAddress: false,
    };
    setisShownAddress(obj);
  };

  // useEffect(() => {
  //   if (isTimeExpired) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }
  // }, [isTimeExpired]);

  return (
    // isTimeExpired ? <></> : <>
    <>
      {isDataLoaded ? (
        <Box
          sx={{
            '& ::selection': {
              background: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
            },
          }}
          pt={{ xs: windowWidth < 376 ? '130px' : '70px', xl: '0px', lg: '20px', md: '30px', sm: '44px' }}
        >
          <CustomContainer>
            <Box>
              <Box
                mt={{ xs: 2, xl: 5 }}
                sx={{
                  '& .stepper-all': {
                    // display: 'none',
                  },
                  '& .MuiBox-root': {
                    '& .MuiButtonBase-root': {
                      // display: 'none',
                    },
                  },
                }}
              >
                <StepperCard />
              </Box>
            </Box>
          </CustomContainer>
          <Box
            sx={{
              '& .MuiOutlinedInput-input': {
                textOverflow: 'ellipsis',
              },
            }}
          >
            <Box mt={{ md: 5, xs: 2, sm: 3 }}>
              <Box>
                <Box bgcolor={colors.grey[20]} mb={{ md: '20px', xs: '10px' }}>
                  <CustomContainer>
                    <Typography fontSize='28px' lineHeight='44px' fontWeight={600} variant='h1'>
                      Passenger Details
                    </Typography>
                  </CustomContainer>
                </Box>
                <CustomContainer>
                  <Grid container sx={{ mb: 8, mt: { md: '20px', xs: '10px' } }}>
                    <Grid
                      item
                      lg={8}
                      xs={12}
                      sx={{
                        pr: { xl: 10, lg: 8, xs: 0 },
                        order: { lg: 1, xs: 2 },
                        border: { xs: '1px solid #999999', lg: 'none' },
                        padding: { xs: '0px 12px 15px', lg: 0 },
                        paddingRight: { xs: '12px', lg: '20px !important' },
                        marginTop: { xs: '30px', lg: '0px' },
                        borderRadius: { xs: '8px', lg: '0px' },
                      }}
                    >
                      <Box
                        mb={{ sm: 3, xs: '25px' }}
                        sx={{
                          position: { xs: 'sticky', sm: 'relative' },
                          bottom: 0,
                          background: {
                            xs: '#ffffff !important',
                            sm: 'transparent',
                          },
                          // padding: { xs: "14px 10px 0px", sm: "0" },
                          // boxShadow: {
                          //   xs: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          //   sm: "none",
                          // },
                          zIndex: 1,
                        }}
                      >
                        <Box display={{ md: 'block', sm: 'block', xs: 'none' }}>
                          <Box
                            mt={{ md: '0', sm: '10px' }}
                            display={'flex'}
                            sx={{
                              zoom: windowWidth > 350 ? 1 : 0.9,
                              '& button': {
                                fontSize: windowWidth < 405 ? '12px' : '16px',
                              },
                            }}
                            justifyContent='space-between'
                          >
                            <Box
                              mb={{ md: 0, xs: '14px' }}
                              sx={{
                                '& button': {
                                  width: { md: 'auto', xs: '100%' },
                                },
                                '& .MuiButtonBase-root': {
                                  border: isPlataniumHotel
                                    ? `1px solid ${colors.basics.plataniumGreenColor}`
                                    : `1px solid ${colors.basics.primary}`,
                                  color: isPlataniumHotel
                                    ? colors.basics.plataniumGreenColor
                                    : colors.basics.primary,
                                  // background: colors.grey[30],
                                  '&:hover': {
                                    border: isPlataniumHotel
                                      ? `1px solid ${colors.basics.plataniumGreenColor}`
                                      : `1px solid ${colors.basics.primary}`,
                                  },
                                },
                              }}
                            >
                              <Button
                                variant='outlined'
                                sx={{ bgcolor: 'transparent' }}
                                onClick={() => {
                                  dispatch(setStepperState(1));
                                  navigate(`/holiday-summary/${session_id}${window.location.search}`);
                                }}
                              >
                                Back
                              </Button>
                            </Box>

                            {/* <Box
                              sx={{
                                margin: '0 15px',
                                '& .MuiButtonBase-root': {
                                  '&:hover': {
                                    backgroundColor: colors.grey[30],
                                  },
                                },
                              }}
                              width={'100%'}
                              textAlign={'center'}
                            >
                              <Button
                                disabled={emailTextCheckbox}
                                onClick={(e) => {
                                  setValidation(true);
                                  handleSubmit(e, false);
                                }}
                                sx={{
                                  padding: windowWidth > 435 ? '0 10px !imporatant' : '0px 8px !important',
                                  marginRight: '10px',
                                  marginBottom: { xs: '14px', md: '' },
                                  width: windowWidth > 389 ? 'fit-content' : '100%',
                                  backgroundColor: colors.grey[30],
                                }}
                                type="submit"
                              >
                                Save Itinerary
                              </Button>
                            </Box> */}

                            <Box display={{ sm: 'block', md: 'flex' }}>
                              <form
                                onSubmit={(e) => {
                                  setValidation(true);
                                  handleSubmit(e, true);
                                }}
                              >
                                <Box
                                  mb={{ md: 0, xs: '14px' }}
                                  sx={{
                                    '& button': {
                                      width: { md: 'auto', xs: '100%' },
                                    },
                                    '& .MuiButtonBase-root': {
                                      background: isPlataniumHotel
                                        ? colors.basics.plataniumGreenColor
                                        : colors.basics.primary,
                                      '&:hover': {
                                        background: isPlataniumHotel
                                          ? colors.basics.plataniumGreenColor
                                          : colors.basics.primary,
                                        color: colors.basics.white,
                                      },
                                    },
                                  }}
                                >
                                  <Button className='btn-olb-payment' id='submit-next-btn-top' type='submit'>
                                    Payment
                                  </Button>
                                </Box>
                              </form>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      {validation &&
                        (addressBillingErrors.showBasicErrors === true ||
                          (flightRoom.multiRooms.length > 1 && !submitForm) ||
                          Object.keys(formErrors).length > 0) && (
                          <Box
                            sx={{
                              '& .MuiAlert-root': {
                                border: `1px solid ${colors.basics.red}`,
                                bgcolor: colors.basics.secondary,
                                mb: '21px',
                                mt: { md: '0', xs: '24px' },

                                '& .MuiAlert-message': {
                                  color: colors.basics.black,
                                  fontWeight: '400',
                                  display: 'flex',
                                  alignItems: 'center',
                                },

                                '& svg': {
                                  bgcolor: isPlataniumHotel
                                    ? colors.basics.plataniumGreenColor
                                    : colors.basics.primary,
                                  borderRadius: '100%',

                                  '& path': {
                                    fill: colors.basics.white,
                                  },
                                },
                              },
                            }}
                          >
                            <Alert severity='error'>There are some fields that need correcting.</Alert>
                          </Box>
                        )}
                      <form>
                        {formData.length > 0 ? (
                          formData.map((form: any, i: number) => {
                            const childAge =
                              form?.pax_type === 'CHD' ? childAndInfantAges?.childAges[form?.id - 1] : null;
                            const infantAge =
                              form?.pax_type === 'INF' ? childAndInfantAges?.infantAges[form?.id - 1] : null;
                            return (
                              <Box
                                pb={{ md: '20px', xs: '10px' }}
                                borderBottom={
                                  formData.length - 1 === i ? '0px' : `1px solid ${colors.basics.black}`
                                }
                                sx={{
                                  '& .MuiFormHelperText-root': {
                                    margin: 0,
                                    marginTop: '2px',
                                    whiteSpace: 'nowrap',
                                    lineHeight: '1.2',
                                  },
                                }}
                                key={i}
                              >
                                <Box
                                  mt={2}
                                  mb={{ md: '20px', xs: '10px' }}
                                  display={'block'}
                                  alignItems={'center'}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: '21px',
                                    }}
                                    variant='h2'
                                  >
                                    {form.pax_type === 'ADT'
                                      ? 'Adult'
                                      : form.pax_type === 'CHD'
                                        ? 'Child'
                                        : 'Infant'}{' '}
                                    {form.id}
                                    {form?.pax_type === 'CHD' && childAge !== null
                                      ? ` (Age - ${childAge})`
                                      : form?.pax_type === 'INF' && infantAge !== null
                                        ? ` (Age - ${infantAge})`
                                        : ''}
                                    {i === 0 && `(Lead passenger, primary contact)`}
                                  </Typography>
                                  <Typography variant='body2' ml='2px'>
                                    (Passenger name must be as shown in their passport)
                                  </Typography>
                                </Box>
                                <Box>
                                  <Grid
                                    container
                                    id={`formContainer${i}`}
                                    sx={{
                                      '& .MuiFormHelperText-root': {
                                        whiteSpace: {
                                          xl: 'nowrap !important',
                                          sm: 'nowrap !important',
                                          xs: 'unset !important',
                                        },
                                      },
                                      '& .MuiOutlinedInput-input': {
                                        height: '52.8px !important',
                                        boxSizing: 'border-box !important',
                                      },
                                      '& .MuiTextField-root': {
                                        maxHeight: 'unset',
                                      },
                                    }}
                                  >
                                    <Grid
                                      item
                                      sm={2}
                                      xs={12}
                                      sx={{
                                        '& .custom-dropdown .dropdown-toggle span': {
                                          maxWidth: '100%',
                                        },
                                        pr: { md: 0, sm: 0, xs: 0 },
                                        mb: 1,
                                        '& .MuiFormHelperText-root': {
                                          whiteSpace: {
                                            xl: 'nowrap !important',
                                            sm: 'nowrap !important',
                                            xs: 'unset !important',
                                          },
                                        },
                                      }}
                                    >
                                      <Typography mb={1} variant='subtitle2'>
                                        Title *
                                      </Typography>
                                      <CustomSelectInput
                                        data={form.pax_type === 'ADT' ? titlePreferences : childPreferences}
                                        onChange={(e: any, n: any, v: any) => {
                                          handleSelect(e, n, v, i);
                                        }}
                                        ids={`title${i}`}
                                        disabled={
                                          i === 0 && isLoginUser
                                            ? isEmpty(loginData.title) !== ''
                                              ? true
                                              : false
                                            : false
                                        }
                                        name={'title'}
                                        value={form.title}
                                        multiple={false}
                                        checkbox={false}
                                        error={formErrors && formErrors[`[${i}].title`]}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      sm={5}
                                      xs={6}
                                      sx={{
                                        pr: { md: 0, sm: 0, xs: '6px' },
                                        mt: { xs: '5px', sm: 0 },
                                        mb: {
                                          md: formErrors[`[${i}].fname`] ? '26px' : '16px !important',
                                          xs: formErrors[`[${i}].fname`] ? '6px' : '16px !important',
                                        },
                                        pl: { sm: 1, xs: 0 },
                                      }}
                                    >
                                      <TextInput
                                        title='First name *'
                                        textStyle={'none !important'}
                                        required
                                        disabled={
                                          i === 0 && isLoginUser
                                            ? isEmpty(loginData.first_name) !== ''
                                              ? true
                                              : false
                                            : false
                                        }
                                        value={form.fname}
                                        onChange={(evt) => handleFormChangeData(i, evt)}
                                        name={`fname`}
                                        key={`fname${i}`}
                                        error={formErrors && formErrors[`[${i}].fname`] ? true : false}
                                        helperText={
                                          formErrors && formErrors[`[${i}].fname`]
                                            ? formErrors[`[${i}].fname`]
                                            : ''
                                        }
                                      />
                                    </Grid>

                                    <Grid
                                      item
                                      sm={5}
                                      xs={6}
                                      sx={{
                                        pr: { md: 0, sm: 0, xs: 0 },
                                        mt: { xs: '5px', sm: 0 },
                                        mb: {
                                          md: formErrors[`[${i}].sname`] ? '26px' : '16px !important',
                                          xs: formErrors[`[${i}].sname`] ? '6px' : '16px !important',
                                        },
                                        pl: { sm: 1, xs: '6px' },
                                      }}
                                    >
                                      <TextInput
                                        title='Surname *'
                                        textStyle={'none !important'}
                                        required
                                        value={form.sname}
                                        onChange={(evt) => handleFormChangeData(i, evt)}
                                        name={`sname`}
                                        disabled={
                                          i === 0 && isLoginUser
                                            ? isEmpty(loginData.name) !== ''
                                              ? true
                                              : false
                                            : false
                                        }
                                        key={`sname${i}`}
                                        error={formErrors && formErrors[`[${i}].sname`] ? true : false}
                                        helperText={
                                          formErrors && formErrors[`[${i}].sname`]
                                            ? formErrors[`[${i}].sname`]
                                            : ''
                                        }
                                      />
                                    </Grid>

                                    {i === 0 && (
                                      <>
                                        <Grid
                                          item
                                          md={6}
                                          xs={6}
                                          sx={{
                                            pr: { md: 0, sm: 1, xs: '5px' },
                                            mb: {
                                              md: formErrors[`[0].email`] ? '26px' : '16px !important',
                                              xs: formErrors[`[0].email`] ? '6px' : '16px !important',
                                            },
                                            pl: { md: 0, xs: 0 },
                                          }}
                                        >
                                          <TextInput
                                            title='Email *'
                                            textStyle={'none !important'}
                                            onChange={(evt) => {
                                              handleFormChangeData(i, evt);
                                            }}
                                            name={`email`}
                                            value={form.email}
                                            disabled={
                                              isLoginUser
                                                ? isEmpty(loginData.email) !== ''
                                                  ? true
                                                  : false
                                                : isFolderAlreadyAdded
                                                  ? isEmpty(isFolderValues.email) !== ''
                                                    ? true
                                                    : false
                                                  : false
                                            }
                                            key={`email${i}`}
                                            error={formErrors && formErrors[`[0].email`] ? true : false}
                                            helperText={
                                              formErrors && formErrors[`[0].email`]
                                                ? formErrors[`[0].email`]
                                                : ''
                                            }
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          md={6}
                                          xs={6}
                                          sx={{
                                            pr: { md: 0, sm: 0, xs: 0 },
                                            mb: {
                                              md: formErrors[`[${i}].pnumber`] ? '26px' : '16px !important',
                                              xs: formErrors[`[${i}].pnumber`] ? '6px' : '16px !important',
                                            },
                                            pl: { md: 1, xs: '5px' },
                                          }}
                                        >
                                          <TextInput
                                            title='Phone number *'
                                            textStyle={'none !important'}
                                            onChange={(evt) => {
                                              if (
                                                onlyNumber(evt.target.value) &&
                                                  process.env.REACT_APP_PROJECT_COUNTRY === 'ie'
                                                  ? evt.target.value.length <= 10
                                                  : evt.target.value.length <= 11
                                              ) {
                                                handleFormChangeData(i, evt);
                                              }
                                            }}
                                            name={`pnumber`}
                                            value={form.pnumber}
                                            key={`pnumber${i}`}
                                            disabled={
                                              isLoginUser
                                                ? isEmpty(loginData.contact_person) !== ''
                                                  ? true
                                                  : false
                                                : isFolderAlreadyAdded
                                                  ? isEmpty(isFolderValues.phoneNumber) !== ''
                                                    ? true
                                                    : false
                                                  : false
                                            }
                                            error={formErrors && formErrors[`[${i}].pnumber`] ? true : false}
                                            helperText={
                                              formErrors && formErrors[`[${i}].pnumber`]
                                                ? formErrors[`[${i}].pnumber`]
                                                : ''
                                            }
                                          />
                                        </Grid>
                                      </>
                                    )}
                                    <Grid
                                      item
                                      md={12}
                                      xs={12}
                                      sx={{
                                        pr: { md: 1, xs: 0 },
                                        mb: 3,
                                        pl: 0,
                                      }}
                                    >
                                      <Box>
                                        <Typography
                                          variant='subtitle2'
                                          color={colors.grey[10]}
                                          mb='8px'
                                        // textTransform="uppercase"
                                        >
                                          Date of birth ( DD/MM/YYYY ) *
                                        </Typography>
                                      </Box>
                                      <Box display='flex' width={{ xs: '100%' }}>
                                        <Box maxWidth='90px' mr='8px'>
                                          <TextInput
                                            placeholder='DD'
                                            required
                                            inputProps={{
                                              maxLength: 2,
                                            }}
                                            value={form.day === null ? '' : form.day}
                                            onChange={(evt) => {
                                              dayOnChangeValidation(form, evt, i);
                                            }}
                                            name={`day`}
                                            key={`day${i}`}
                                            error={formErrors && formErrors[`[${i}].day`] ? true : false}
                                            helperText={
                                              formErrors && formErrors[`[${i}].day`]
                                                ? formErrors[`[${i}].day`]
                                                : ''
                                            }
                                          />
                                        </Box>
                                        <Box maxWidth='90px' mr='8px'>
                                          <TextInput
                                            placeholder='MM'
                                            required
                                            onChange={(evt) => {
                                              monthOnChangeValidation(form, evt, i);
                                            }}
                                            name={`month`}
                                            value={form.month === null ? '' : form.month}
                                            key={`month${i}`}
                                            inputProps={{
                                              maxLength: 2,
                                            }}
                                            error={formErrors && formErrors[`[${i}].month`] ? true : false}
                                            helperText={
                                              formErrors && formErrors[`[${i}].month`]
                                                ? formErrors[`[${i}].month`]
                                                : ''
                                            }
                                          />
                                        </Box>
                                        <Box maxWidth='26%' mr='13px'>
                                          <Box display={'flex'}>
                                            <TextInput
                                              placeholder='YYYY'
                                              required
                                              onChange={(evt) => {
                                                yearOnChangeValidation(form, evt, i);
                                              }}
                                              name={`year`}
                                              inputProps={{
                                                maxLength: 4,
                                              }}
                                              value={form.year === null ? '' : form.year}
                                              key={`year${i}`}
                                              error={
                                                (formErrors && formErrors[`[${i}].year`]) ||
                                                  (form.pax_type === 'CHD' &&
                                                    Object.keys(childAndInfantAges.childAgeValids).length > 0 &&
                                                    childAndInfantAges.childAgeValids[form.id] &&
                                                    childAndInfantAges.childAgeValids[form.id][form.id] ===
                                                    false &&
                                                    validation) ||
                                                  (form.pax_type === 'INF' &&
                                                    Object.keys(childAndInfantAges.infantAgeValid).length > 0 &&
                                                    childAndInfantAges.infantAgeValid[form.id] &&
                                                    childAndInfantAges.infantAgeValid[form.id][form.id] ===
                                                    false &&
                                                    validation)
                                                  ? true
                                                  : false
                                              }
                                              helperText={
                                                formErrors && formErrors[`[${i}].year`]
                                                  ? formErrors[`[${i}].year`]
                                                  : ''
                                              }
                                            />
                                            {form.pax_type === 'CHD' &&
                                              Object.keys(childAndInfantAges.childAgeValids).length > 0 &&
                                              childAndInfantAges.childAgeValids[form.id] &&
                                              childAndInfantAges.childAgeValids[form.id][form.id] ===
                                              false && (
                                                <Tooltip
                                                  title={`The date of birth of the child has to match the age in the search criteria. Please note that the child age has to be correct in accordance with the age at the time of the checkout`}
                                                >
                                                  <ErrorOutlineIcon
                                                    sx={{
                                                      color: 'red',
                                                      fontSize: '19px',
                                                      marginLeft: '1px',
                                                    }}
                                                  />
                                                </Tooltip>
                                              )}
                                            {form.pax_type === 'INF' &&
                                              Object.keys(childAndInfantAges.infantAgeValid).length > 0 &&
                                              childAndInfantAges.infantAgeValid[form.id] &&
                                              childAndInfantAges.infantAgeValid[form.id][form.id] ===
                                              false && (
                                                <Tooltip
                                                  title={`The date of birth of the infant has to match the age in the search criteria. Please note that the infant age has to be correct in accordance with the age at the time of the checkout`}
                                                >
                                                  <ErrorOutlineIcon
                                                    sx={{
                                                      color: 'red',
                                                      fontSize: '19px',
                                                      marginLeft: '1px',
                                                    }}
                                                  />
                                                </Tooltip>
                                              )}
                                          </Box>
                                          {form.pax_type === 'CHD' &&
                                            Object.keys(childAndInfantAges.childAgeValids).length > 0 &&
                                            childAndInfantAges.childAgeValids[form.id] &&
                                            childAndInfantAges.childAgeValids[form.id][form.id] === false &&
                                            formErrors &&
                                            !formErrors[`[${i}].year`] &&
                                            validation && (
                                              <Typography
                                                sx={{
                                                  fontSize: '12px',
                                                  color: '#d32f2f',
                                                  lineHeight: '1.6',
                                                  fontFamily: 'El Messiri,Raleway,sans-serif',
                                                }}
                                              >
                                                DOB of the child does not match the age in the search criteria
                                                based on date of check out
                                              </Typography>
                                            )}
                                          {form.pax_type === 'INF' &&
                                            Object.keys(childAndInfantAges.infantAgeValid).length > 0 &&
                                            childAndInfantAges.infantAgeValid[form.id] &&
                                            childAndInfantAges.infantAgeValid[form.id][form.id] === false &&
                                            formErrors &&
                                            !formErrors[`[${i}].year`] &&
                                            validation && (
                                              <Typography
                                                sx={{
                                                  fontSize: '12px',
                                                  color: '#d32f2f',
                                                  lineHeight: '1.6',
                                                  fontFamily: 'El Messiri,Raleway,sans-serif',
                                                }}
                                              >
                                                DOB of the infant does not match the age in the search
                                                criteria based on date of check out
                                              </Typography>
                                            )}
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Grid>{' '}
                                </Box>
                              </Box>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </form>
                      {flightRoom.multiRooms.length > 1 && (
                        <>
                          <Box
                            bgcolor={colors.grey[20]}
                            mb={{ md: '20px', xs: '10px' }}
                            id={'roomAllocation'}
                          >
                            <CustomContainer>
                              <Typography fontSize='28px' lineHeight='44px' fontWeight={600} variant='h1'>
                                Room Allocation
                              </Typography>
                            </CustomContainer>
                          </Box>

                          <Box mb={{ md: '20px', xs: '10px' }}>
                            {formData.length > 0 && (
                              <RoomAllocation
                                validation={validation}
                                roomErrorsArray={roomErrorsArray}
                                validateAdultsChildrenInfants={(v: any) => validateAdultsChildrenInfants(v)}
                                selectedOptions={selectedOptions}
                                setSelectedOptions={setSelectedOptions}
                                flightRoom={flightRoom}
                                formData={formData}
                              />
                            )}
                          </Box>
                        </>
                      )}

                      {/* Postal Address */}
                      <Box
                        bgcolor={colors.grey[20]}
                        mb={{ md: '20px', xs: '10px' }}
                        mt={{ md: '0px', xs: '0px' }}
                        id={'postalId'}
                      >
                        <CustomContainer>
                          <Typography fontSize='28px' lineHeight='44px' fontWeight={600} variant='h1'>
                            Postal Address
                          </Typography>
                        </CustomContainer>
                      </Box>

                      <form>
                        <Grid
                          container
                          sx={{
                            '& .MuiFormHelperText-root': {
                              margin: 0,
                              whiteSpace: 'nowrap',
                            },
                          }}
                        >
                          {!isShownAddress.hidePostalAddress && (
                            <>
                              <Grid
                                item
                                sm={6}
                                xs={12}
                                sx={{
                                  pr: { md: 1, sm: 1, xs: 0 },
                                  mb: {
                                    xs: isShownAddress.addressManually
                                      ? addressBillingErrors.manuallyAddressErrors.house
                                        ? '26px'
                                        : '16px !important'
                                      : addressBillingErrors.searchingAddressErrors.house
                                        ? '26px'
                                        : '16px !important',
                                  },
                                  pl: 0,
                                }}
                              >
                                <TextInput
                                  title='House number/name *'
                                  textStyle={'none !important'}
                                  value={
                                    isShownAddress.addressManually
                                      ? address.manually.house
                                      : address.searching.house
                                  }
                                  onChange={(evt) => {
                                    handleAddressChange(evt);
                                  }}
                                  name='house'
                                  error={
                                    !isShownAddress.addressManually
                                      ? addressBillingErrors.searchingAddressErrors.house
                                        ? true
                                        : false
                                      : addressBillingErrors.manuallyAddressErrors &&
                                        addressBillingErrors.manuallyAddressErrors.house
                                        ? true
                                        : false
                                  }
                                  helperText={
                                    !isShownAddress.addressManually
                                      ? addressBillingErrors.searchingAddressErrors.house
                                        ? addressBillingErrors.searchingAddressErrors.house
                                        : ''
                                      : addressBillingErrors.manuallyAddressErrors &&
                                        addressBillingErrors.manuallyAddressErrors.house
                                        ? addressBillingErrors.manuallyAddressErrors.house
                                        : ''
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                sm={6}
                                xs={12}
                                sx={{
                                  pr: { md: 0, xs: 0 },
                                  mb: {
                                    xs: isShownAddress.addressManually
                                      ? addressBillingErrors.manuallyAddressErrors.street
                                        ? '26px'
                                        : '16px !important'
                                      : addressBillingErrors.searchingAddressErrors.street
                                        ? '26px'
                                        : '16px !important',
                                  },
                                  pl: { md: 0, xs: 0 },
                                }}
                              >
                                <TextInput
                                  title='Street address *'
                                  textStyle={'none !important'}
                                  onChange={(evt) => {
                                    handleAddressChange(evt);
                                  }}
                                  value={
                                    isShownAddress.addressManually
                                      ? address.manually.street
                                      : address.searching.street
                                  }
                                  name='street'
                                  error={
                                    !isShownAddress.addressManually
                                      ? addressBillingErrors.searchingAddressErrors.street
                                        ? true
                                        : false
                                      : addressBillingErrors.manuallyAddressErrors &&
                                        addressBillingErrors.manuallyAddressErrors.street
                                        ? true
                                        : false
                                  }
                                  helperText={
                                    !isShownAddress.addressManually
                                      ? addressBillingErrors.searchingAddressErrors.street
                                        ? addressBillingErrors.searchingAddressErrors.street
                                        : ''
                                      : addressBillingErrors.manuallyAddressErrors &&
                                        addressBillingErrors.manuallyAddressErrors.street
                                        ? addressBillingErrors.manuallyAddressErrors.street
                                        : ''
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                sm={6}
                                xs={12}
                                sx={{
                                  pr: { md: 1, sm: 1, xs: 0 },
                                  mb: {
                                    xs: isShownAddress.addressManually
                                      ? addressBillingErrors.manuallyAddressErrors.city
                                        ? '26px'
                                        : '16px !important'
                                      : addressBillingErrors.searchingAddressErrors.city
                                        ? '26px'
                                        : '16px !important',
                                  },
                                  pl: 0,
                                }}
                              >
                                <TextInput
                                  title='City *'
                                  textStyle={'none !important'}
                                  name='city'
                                  value={
                                    isShownAddress.addressManually
                                      ? address.manually.city
                                      : address.searching.city
                                  }
                                  onChange={(evt) => {
                                    handleAddressChange(evt);
                                  }}
                                  error={
                                    !isShownAddress.addressManually
                                      ? addressBillingErrors.searchingAddressErrors.city
                                        ? true
                                        : false
                                      : addressBillingErrors.manuallyAddressErrors &&
                                        addressBillingErrors.manuallyAddressErrors.city
                                        ? true
                                        : false
                                  }
                                  helperText={
                                    !isShownAddress.addressManually
                                      ? addressBillingErrors.searchingAddressErrors.city
                                        ? addressBillingErrors.searchingAddressErrors.city
                                        : ''
                                      : addressBillingErrors.manuallyAddressErrors &&
                                        addressBillingErrors.manuallyAddressErrors.city
                                        ? addressBillingErrors.manuallyAddressErrors.city
                                        : ''
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                sm={6}
                                xs={12}
                                sx={{
                                  pr: { md: 0, xs: 0 },
                                  mb: '16px !important',
                                  pl: { md: 0, xs: 0 },
                                }}
                              >
                                <TextInput
                                  title='County'
                                  textStyle={'none !important'}
                                  value={
                                    isShownAddress.addressManually
                                      ? address.manually.county
                                      : address.searching.county
                                  }
                                  onChange={(evt) => {
                                    handleAddressChange(evt);
                                  }}
                                  name='county'
                                // error={!isShownAddress.addressManually ? addressBillingErrors.searchingAddressErrors.county ? true : false : addressBillingErrors.manuallyAddressErrors && addressBillingErrors.manuallyAddressErrors.county ? true : false}
                                // helperText={!isShownAddress.addressManually ? addressBillingErrors.searchingAddressErrors.county ? addressBillingErrors.searchingAddressErrors.county : "" :
                                //   addressBillingErrors.manuallyAddressErrors && addressBillingErrors.manuallyAddressErrors.county ? addressBillingErrors.manuallyAddressErrors.county : ''
                                // }
                                />
                              </Grid>
                              <Grid
                                item
                                sm={6}
                                xs={12}
                                sx={{
                                  pr: { md: 1, sm: 1, xs: 0 },
                                  mb: {
                                    xs: isShownAddress.addressManually
                                      ? addressBillingErrors.manuallyAddressErrors.country
                                        ? '26px'
                                        : '16px !important'
                                      : addressBillingErrors.searchingAddressErrors.country
                                        ? '26px'
                                        : '16px !important',
                                  },
                                  pl: { md: 0, xs: 0 },
                                }}
                              >
                                <TextInput
                                  title='Country *'
                                  textStyle={'none !important'}
                                  value={
                                    isShownAddress.addressManually
                                      ? address.manually.country
                                      : address.searching.country
                                  }
                                  onChange={(evt) => {
                                    handleAddressChange(evt);
                                  }}
                                  name='country'
                                  error={
                                    !isShownAddress.addressManually
                                      ? addressBillingErrors.searchingAddressErrors.country
                                        ? true
                                        : false
                                      : addressBillingErrors.manuallyAddressErrors &&
                                        addressBillingErrors.manuallyAddressErrors.country
                                        ? true
                                        : false
                                  }
                                  helperText={
                                    !isShownAddress.addressManually
                                      ? addressBillingErrors.searchingAddressErrors.country
                                        ? addressBillingErrors.searchingAddressErrors.country
                                        : ''
                                      : addressBillingErrors.manuallyAddressErrors &&
                                        addressBillingErrors.manuallyAddressErrors.country
                                        ? addressBillingErrors.manuallyAddressErrors.country
                                        : ''
                                  }
                                />
                              </Grid>
                            </>
                          )}
                          <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            sx={{
                              '& .MuiFormHelperText-root': {
                                margin: 0,
                                whiteSpace: 'nowrap',
                              },
                              pr: { md: 1, sm: 1, xs: 0 },
                              mb: {
                                xs: isShownAddress.addressManually
                                  ? addressBillingErrors.manuallyAddressErrors.postcode
                                    ? '20px'
                                    : '10px'
                                  : addressBillingErrors.searchingAddressErrors.postcode
                                    ? '20px'
                                    : '10px',
                              },
                              pl: 0,
                            }}
                          >
                            {!isShownAddress.addressManually ? (
                              <Autocomplete
                                noOptionsText=''
                                disableClearable={true}
                                getOptionLabel={(option) => option.value}
                                renderOption={(props: any, option: any) => (
                                  <li key={option.value} {...props}>
                                    {' '}
                                    {option.postcode + ','}{' '}
                                    {option.property === '' ? '' : option.property + ','}{' '}
                                    {option.street + ','} {option.city + ','} {option.country}
                                  </li>
                                )}
                                onChange={(event, newValue) => {
                                  addressAutoCompleteOnChange(event, newValue);
                                }}
                                value={selectedValuesAddressOption}
                                filterOptions={(options, state) => {
                                  const inputValue = state?.inputValue?.toLowerCase()?.split(' ')?.join('');
                                  return options.filter((option) => {
                                    const optionValue = option?.label2?.toLowerCase()?.split(' ')?.join('');
                                    return optionValue.includes(inputValue);
                                  });
                                }}
                                renderInput={(params) => (
                                  <>
                                    <h6
                                      style={{
                                        margin: '0px 0px 8px',
                                        fontFamily: 'Raleway',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '140%',
                                        color: 'rgb(51, 51, 51)',
                                        // textTransform: "uppercase",
                                      }}
                                      className='MuiTypography-root MuiTypography-subtitle2 css-i3tux2-MuiTypography-root'
                                    >
                                      Postal code *
                                    </h6>
                                    <TextField
                                      value={address.searching.postcode}
                                      error={
                                        addressBillingErrors.searchingAddressErrors.postcode ? true : false
                                      }
                                      helperText={
                                        addressBillingErrors.searchingAddressErrors.postcode
                                          ? addressBillingErrors.searchingAddressErrors.postcode
                                          : ''
                                      }
                                      placeholder='Search Postal Code'
                                      {...params}
                                      onChange={(e) => {
                                        clearTimeout(timer);
                                        const newTimer = setTimeout(() => {
                                          updateOptions(e.target.value, true);
                                        }, 800);
                                        setTimer(newTimer);
                                      }}
                                    />
                                  </>
                                )}
                                id='combo-box-demo'
                                options={options.address}
                              />
                            ) : (
                              <TextInput
                                title='Postal code *'
                                textStyle={'none !important'}
                                onChange={(evt) => {
                                  handleAddressChange(evt);
                                }}
                                value={address.manually.postcode}
                                name='postcode'
                                error={
                                  addressBillingErrors.manuallyAddressErrors &&
                                    addressBillingErrors.manuallyAddressErrors.postcode
                                    ? true
                                    : false
                                }
                                helperText={
                                  addressBillingErrors.manuallyAddressErrors &&
                                    addressBillingErrors.manuallyAddressErrors.postcode
                                    ? addressBillingErrors.manuallyAddressErrors.postcode
                                    : ''
                                }
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Box mb='15px'>
                          <FormControl component='fieldset' variant='standard'>
                            <FormGroup
                              sx={{
                                display: 'flex',
                                ml: '10px',
                                flexWrap: 'wrap',
                                flexDirection: 'row',
                                '& label': {
                                  flex: '0 0 100%',
                                  alignItems: 'flex-start',
                                  '& span': {
                                    '&:nth-of-type(2)': {
                                      mt: 0.5,
                                    },
                                  },
                                },
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    sx={{
                                      pr: { sm: '12px', xs: '6px' },
                                      color: isPlataniumHotel
                                        ? colors.basics.plataniumGreenColor + '!important'
                                        : colors.basics.primary,
                                    }}
                                    disableRipple
                                    checked={isShownAddress.addressManually}
                                    value={isShownAddress.addressManually}
                                    onChange={(e) => {
                                      postalAddressManually(e);
                                    }}
                                  />
                                }
                                label='Enter address manually'
                              />
                            </FormGroup>
                          </FormControl>
                        </Box>
                      </form>

                      {/*  Billing Address */}
                      <Box mt={2} id={'billingId'}>
                        <Box
                          bgcolor={colors.grey[20]}
                          mb={{ md: '20px', xs: '10px' }}
                          mt={{ md: '5px', xs: '20px' }}
                        >
                          <CustomContainer>
                            <Typography fontSize='28px' lineHeight='44px' fontWeight={600} variant='h1'>
                              Billing Address
                            </Typography>
                          </CustomContainer>
                        </Box>
                        <Box
                          sx={{
                            '& .MuiFormHelperText-root': {
                              margin: 0,
                              whiteSpace: 'nowrap',
                            },
                            pr: { md: 1, xs: 0 },
                            mb: 2,
                            pl: 0,
                          }}
                        >
                          <Box display={'flex'} mb='15px'>
                            <FormControl component='fieldset' variant='standard'>
                              <FormGroup
                                sx={{
                                  display: 'flex',
                                  ml: '10px',
                                  flexWrap: 'wrap',
                                  flexDirection: 'row',
                                  '& label': {
                                    flex: '0 0 100%',
                                    alignItems: 'flex-start',
                                    '& span': {
                                      '&:nth-of-type(2)': {
                                        mt: { sm: 0.5, xs: 0 },
                                      },
                                    },
                                  },
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      value={isShownAddress.isPostalAddress}
                                      checked={isShownAddress.isPostalAddress}
                                      sx={{
                                        pr: { sm: '12px', xs: '6px' },
                                        color: isPlataniumHotel
                                          ? colors.basics.plataniumGreenColor + '!important'
                                          : colors.basics.primary,
                                      }}
                                      disableRipple
                                      onChange={(e) => {
                                        sameAsPostalAddress(e);
                                      }}
                                    />
                                  }
                                  label='Same as postal address'
                                />
                              </FormGroup>
                            </FormControl>
                            {/* {isShownAddress.showBillingAddress && ( */}
                            <Box>
                              <FormControl component='fieldset' variant='standard'>
                                <FormGroup
                                  sx={{
                                    display: 'flex',
                                    ml: '10px',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                    '& label': {
                                      flex: '0 0 100%',
                                      alignItems: 'flex-start',
                                      '& span': {
                                        '&:nth-of-type(2)': {
                                          mt: { sm: 0.5, xs: 0 },
                                        },
                                      },
                                    },
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        sx={{
                                          pr: { sm: '12px', xs: '6px' },
                                          color: isPlataniumHotel
                                            ? colors.basics.plataniumGreenColor + '!important'
                                            : colors.basics.primary,
                                        }}
                                        disableRipple
                                        checked={isShownAddress.billingAddressManually}
                                        value={isShownAddress.billingAddressManually}
                                        onChange={(e) => {
                                          billingManually(e);
                                        }}
                                      />
                                    }
                                    label='Enter address manually'
                                  />
                                </FormGroup>
                              </FormControl>
                            </Box>
                            {/* )} */}
                          </Box>
                          {/* {isShownAddress.showBillingAddress && ( */}
                          <Box>
                            <form>
                              <Grid container>
                                {((!isShownAddress.isPostalAddress &&
                                  isShownAddress.billingAddressManually) ||
                                  (selectedValuesBillingOption?.value &&
                                    !isShownAddress.isPostalAddress &&
                                    !isShownAddress.billingAddressManually)) && (
                                    <>
                                      <Grid
                                        item
                                        sm={6}
                                        xs={12}
                                        sx={{
                                          pr: { md: 1, sm: 1, xs: 0 },
                                          mb: {
                                            xs: isShownAddress.billingAddressManually
                                              ? addressBillingErrors.manuallyBillingErrors.house
                                                ? '26px'
                                                : '16px !important'
                                              : addressBillingErrors.searchingBillingErrors.house
                                                ? '26px'
                                                : '16px !important',
                                          },
                                          pl: 0,
                                        }}
                                      >
                                        <TextInput
                                          title='HOUSE NUMBER/NAME *'
                                          value={
                                            isShownAddress.billingAddressManually
                                              ? billingAddess.manually.house
                                              : billingAddess.searching.house
                                          }
                                          onChange={(evt) => {
                                            handleBillingAddressChange(evt);
                                          }}
                                          name='house'
                                          error={
                                            isShownAddress.billingAddressManually
                                              ? addressBillingErrors.manuallyBillingErrors &&
                                                addressBillingErrors.manuallyBillingErrors.house
                                                ? true
                                                : false
                                              : addressBillingErrors.searchingBillingErrors.house
                                                ? true
                                                : false
                                          }
                                          helperText={
                                            isShownAddress.billingAddressManually
                                              ? addressBillingErrors.manuallyBillingErrors &&
                                                addressBillingErrors.manuallyBillingErrors.house
                                                ? addressBillingErrors.manuallyBillingErrors.house
                                                : ''
                                              : addressBillingErrors.searchingBillingErrors.house
                                                ? addressBillingErrors.searchingBillingErrors.house
                                                : ''
                                          }
                                        />
                                      </Grid>{' '}
                                      <Grid
                                        item
                                        sm={6}
                                        xs={12}
                                        sx={{
                                          pr: { md: 0, xs: 0 },
                                          mb: {
                                            xs: isShownAddress.billingAddressManually
                                              ? addressBillingErrors.manuallyBillingErrors.street
                                                ? '26px'
                                                : '16px !important'
                                              : addressBillingErrors.searchingBillingErrors.street
                                                ? '26px'
                                                : '16px !important',
                                          },
                                          pl: { md: 0, xs: 0 },
                                        }}
                                      >
                                        <TextInput
                                          title='STREET ADDRESS *'
                                          onChange={handleBillingAddressChange}
                                          name='street'
                                          value={
                                            isShownAddress.billingAddressManually
                                              ? billingAddess.manually.street
                                              : billingAddess.searching.street
                                          }
                                          error={
                                            isShownAddress.billingAddressManually
                                              ? addressBillingErrors.manuallyBillingErrors &&
                                                addressBillingErrors.manuallyBillingErrors.street
                                                ? true
                                                : false
                                              : addressBillingErrors.searchingBillingErrors.street
                                                ? true
                                                : false
                                          }
                                          helperText={
                                            isShownAddress.billingAddressManually
                                              ? addressBillingErrors.manuallyBillingErrors &&
                                                addressBillingErrors.manuallyBillingErrors.street
                                                ? addressBillingErrors.manuallyBillingErrors.street
                                                : ''
                                              : addressBillingErrors.searchingBillingErrors.street
                                                ? addressBillingErrors.searchingBillingErrors.street
                                                : ''
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        sm={6}
                                        xs={12}
                                        sx={{
                                          pr: { md: 1, sm: 1, xs: 0 },
                                          mb: {
                                            xs: isShownAddress.billingAddressManually
                                              ? addressBillingErrors.manuallyBillingErrors.city
                                                ? '26px'
                                                : '16px !important'
                                              : addressBillingErrors.searchingBillingErrors.city
                                                ? '26px'
                                                : '16px !important',
                                          },
                                          pl: 0,
                                        }}
                                      >
                                        <TextInput
                                          title='CITY *'
                                          onChange={handleBillingAddressChange}
                                          name='city'
                                          value={
                                            isShownAddress.billingAddressManually
                                              ? billingAddess.manually.city
                                              : billingAddess.searching.city
                                          }
                                          error={
                                            isShownAddress.billingAddressManually
                                              ? addressBillingErrors.manuallyBillingErrors &&
                                                addressBillingErrors.manuallyBillingErrors.city
                                                ? true
                                                : false
                                              : addressBillingErrors.searchingBillingErrors.city
                                                ? true
                                                : false
                                          }
                                          helperText={
                                            isShownAddress.billingAddressManually
                                              ? addressBillingErrors.manuallyBillingErrors &&
                                                addressBillingErrors.manuallyBillingErrors.city
                                                ? addressBillingErrors.manuallyBillingErrors.city
                                                : ''
                                              : addressBillingErrors.searchingBillingErrors.city
                                                ? addressBillingErrors.searchingBillingErrors.city
                                                : ''
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        sm={6}
                                        xs={12}
                                        sx={{
                                          pr: { md: 0, xs: 0 },
                                          mb: '16px !important',
                                          pl: { md: 0, xs: 0 },
                                        }}
                                      >
                                        <TextInput
                                          title='COUNTY'
                                          onChange={handleBillingAddressChange}
                                          name='county'
                                          value={
                                            isShownAddress.billingAddressManually
                                              ? billingAddess.manually.county
                                              : billingAddess.searching.county
                                          }
                                        // error={isShownAddress.billingAddressManually ? addressBillingErrors.manuallyBillingErrors && addressBillingErrors.manuallyBillingErrors.county ? true : false :
                                        //   addressBillingErrors.searchingBillingErrors.county ? true : false
                                        // }
                                        // helperText={isShownAddress.billingAddressManually ?
                                        //   addressBillingErrors.manuallyBillingErrors && addressBillingErrors.manuallyBillingErrors.county ? addressBillingErrors.manuallyBillingErrors.county : '' :
                                        //   addressBillingErrors.searchingBillingErrors.county ? addressBillingErrors.searchingBillingErrors.county : ""
                                        // }
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        sm={6}
                                        xs={12}
                                        sx={{
                                          pr: { md: 1, sm: 1, xs: 0 },
                                          mb: {
                                            xs: isShownAddress.billingAddressManually
                                              ? addressBillingErrors.manuallyBillingErrors.country
                                                ? '26px'
                                                : '16px !important'
                                              : addressBillingErrors.searchingBillingErrors.country
                                                ? '26px'
                                                : '16px !important',
                                          },
                                          pl: { md: 0, xs: 0 },
                                        }}
                                      >
                                        <TextInput
                                          title='COUNTRY *'
                                          onChange={handleBillingAddressChange}
                                          name='country'
                                          value={
                                            isShownAddress.billingAddressManually
                                              ? billingAddess.manually.country
                                              : billingAddess.searching.country
                                          }
                                          error={
                                            isShownAddress.billingAddressManually
                                              ? addressBillingErrors.manuallyBillingErrors &&
                                                addressBillingErrors.manuallyBillingErrors.country
                                                ? true
                                                : false
                                              : addressBillingErrors.searchingBillingErrors.country
                                                ? true
                                                : false
                                          }
                                          helperText={
                                            isShownAddress.billingAddressManually
                                              ? addressBillingErrors.manuallyBillingErrors &&
                                                addressBillingErrors.manuallyBillingErrors.country
                                                ? addressBillingErrors.manuallyBillingErrors.country
                                                : ''
                                              : addressBillingErrors.searchingBillingErrors.country
                                                ? addressBillingErrors.searchingBillingErrors.country
                                                : ''
                                          }
                                        />
                                      </Grid>
                                    </>
                                  )}
                                <Grid
                                  item
                                  md={6}
                                  xs={12}
                                  sm={6}
                                  sx={{
                                    pr: { md: 1, sm: 1, xs: 0 },
                                    mb: {
                                      xs: isShownAddress.billingAddressManually
                                        ? addressBillingErrors.manuallyBillingErrors.postcode
                                          ? '20px'
                                          : '10px !important'
                                        : addressBillingErrors.searchingBillingErrors.postcode
                                          ? '20px'
                                          : '10px !important',
                                    },
                                    pl: 0,
                                  }}
                                >
                                  {!isShownAddress.isPostalAddress &&
                                    !isShownAddress.billingAddressManually ? (
                                    <Autocomplete
                                      noOptionsText=''
                                      getOptionLabel={(option) => option.value}
                                      renderOption={(props: any, option: any) => (
                                        <li key={option.value} {...props}>
                                          {' '}
                                          {option.postcode + ','}{' '}
                                          {option.property === '' ? '' : option.property + ','}{' '}
                                          {option.street + ','} {option.city + ','} {option.country}
                                        </li>
                                      )}
                                      disableClearable={true}
                                      value={selectedValuesBillingOption}
                                      onChange={(event, newValue) => {
                                        billingAutoCompleteOnChange(event, newValue);
                                      }}
                                      filterOptions={(options, state) => {
                                        const inputValue = state?.inputValue
                                          ?.toLowerCase()
                                          ?.split(' ')
                                          ?.join('');
                                        return options.filter((option) => {
                                          const optionValue = option?.label2
                                            ?.toLowerCase()
                                            ?.split(' ')
                                            ?.join('');
                                          return optionValue.includes(inputValue);
                                        });
                                      }}
                                      renderInput={(params) => (
                                        <>
                                          <h6
                                            style={{
                                              margin: '0px 0px 8px',
                                              fontFamily: 'Raleway',
                                              fontStyle: 'normal',
                                              fontWeight: 400,
                                              fontSize: '14px',
                                              lineHeight: '140%',
                                              color: 'rgb(51, 51, 51)',
                                              textTransform: 'uppercase',
                                            }}
                                            className='MuiTypography-root MuiTypography-subtitle2 css-i3tux2-MuiTypography-root'
                                          >
                                            Postal code *
                                          </h6>
                                          <TextField
                                            error={
                                              addressBillingErrors.searchingBillingErrors.postcode
                                                ? true
                                                : false
                                            }
                                            helperText={
                                              addressBillingErrors.searchingBillingErrors.postcode
                                                ? addressBillingErrors.searchingBillingErrors.postcode
                                                : ''
                                            }
                                            placeholder='Search Postal Code'
                                            {...params}
                                            onChange={(e) => {
                                              clearTimeout(timer);
                                              const newTimer = setTimeout(() => {
                                                updateOptions(e.target.value, false);
                                              }, 800);
                                              setTimer(newTimer);
                                            }}
                                          />
                                        </>
                                      )}
                                      id='combo-box-demo'
                                      options={options.billingAddess}
                                    />
                                  ) : !isShownAddress.isPostalAddress &&
                                    isShownAddress.billingAddressManually ? (
                                    <TextInput
                                      title='Postal code *'
                                      textStyle={'none !important'}
                                      onChange={handleBillingAddressChange}
                                      name='postcode'
                                      value={billingAddess.manually.postcode}
                                      error={
                                        addressBillingErrors.manuallyBillingErrors &&
                                          addressBillingErrors.manuallyBillingErrors.postcode
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        addressBillingErrors.manuallyBillingErrors &&
                                          addressBillingErrors.manuallyBillingErrors.postcode
                                          ? addressBillingErrors.manuallyBillingErrors.postcode
                                          : ''
                                      }
                                    />
                                  ) : isShownAddress.billingAddressManually ? (
                                    ''
                                  ) : (
                                    ''
                                  )}
                                </Grid>
                              </Grid>
                            </form>
                          </Box>
                          {/* )} */}
                        </Box>
                      </Box>

                      {/* Special Instructions */}
                      <Box mt={2}>
                        <Box bgcolor={colors.grey[20]} mb={{ md: '20px', xs: '10px' }}>
                          <CustomContainer>
                            <Typography fontSize='28px' lineHeight='44px' fontWeight={600} variant='h1'>
                              Special Instructions
                            </Typography>
                          </CustomContainer>
                        </Box>

                        <Box>
                          <Grid>
                            <Grid item md={12}>
                              <Box>
                                <Box sx={{ mb: { sm: '10px', xs: '0' } }}>
                                  <Typography variant='h2' fontSize='21px'>
                                    Occasion
                                  </Typography>
                                </Box>
                                <Box>
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexWrap: 'wrap !important',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <RadioGroup
                                      aria-labelledby='demo-radio-buttons-group-label'
                                      name='radio-buttons-group'
                                      value={travelPurpose}
                                      sx={{
                                        flexWrap: 'wrap !important',
                                        flexDirection: 'row',
                                      }}
                                      onChange={(e) => {
                                        setTravelPurpose(e.target.value);
                                      }}
                                    >
                                      {Occasiondata?.map((item, index) => {
                                        return (
                                          <Box
                                            flex='0 0 50%'
                                            sx={{
                                              '& span': {
                                                '& svg': {
                                                  fill: isPlataniumHotel
                                                    ? colors.basics.plataniumGreenColor
                                                    : colors.basics.primary,
                                                },
                                              },
                                            }}
                                            key={index}
                                          >
                                            <FormControlLabel
                                              checked={travelPurpose === item.name}
                                              value={item.name}
                                              control={<Radio disableRipple />}
                                              label={item.name}
                                              // sx={{ m: 0 }}
                                              sx={{
                                                m: 0,
                                                p: 0,
                                                border: 'none !important',
                                                bgcolor: 'transparent !important',
                                              }}
                                            />
                                          </Box>
                                        );
                                      })}
                                    </RadioGroup>
                                  </FormControl>
                                </Box>
                                <Box mt='30px'>
                                  <TextInput
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                    type='text'
                                    placeholder='Notes'
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>

                      <FormControlLabel
                        sx={{
                          marginTop: '10px',
                          alignItems: windowWidth > 1270 ? 'center' : 'flex-start',
                          ml: 0,
                          '& .MuiTypography-body1': {
                            fontWeight: 300,
                            fontSize: '14px',
                          },
                          '& .Mui-checked': {
                            color: isPlataniumHotel
                              ? `${colors.basics.plataniumGreenColor} !important`
                              : `${colors.basics.primary} !important`,
                          },
                        }}
                        control={
                          <Checkbox
                            value={emailTextCheckbox}
                            onChange={(e) => setEmailTextCheckbox(!emailTextCheckbox)}
                            sx={{ pr: '12px' }}
                            disableRipple
                          />
                        }
                        label='If you prefer not to have an account created for viewing bookings and saved itineraries, please check this box.'
                      />
                      {/* Buttons */}
                      <Box
                        mt={{ sm: 3, xs: '25px' }}
                        sx={{
                          position: { xs: 'sticky', sm: 'relative' },
                          bottom: 0,
                          background: {
                            xs: '#ffffff !important',
                            sm: 'transparent',
                          },
                          padding: { xs: '14px 10px 0px', sm: '0' },
                          // boxShadow: {
                          //   xs: "rgba(0, 0, 0, 0.24) 0px 3px 8px", sm: "none"
                          // },
                          zIndex: 1,
                        }}
                      >
                        <Box>
                          <Box
                            display={'flex'}
                            sx={{
                              zoom: windowWidth > 350 ? 1 : 0.9,
                              '& button': {
                                fontSize: windowWidth < 405 ? '12px' : '16px',
                              },
                            }}
                            justifyContent='space-between'
                          >
                            <Box
                              mb={{ md: 0, xs: '14px' }}
                              sx={{
                                '& button': {
                                  width: { md: 'auto', xs: '100%' },
                                },
                                '& .MuiButtonBase-root': {
                                  border: isPlataniumHotel
                                    ? `1px solid ${colors.basics.plataniumGreenColor}`
                                    : `1px solid ${colors.basics.primary}`,
                                  color: isPlataniumHotel
                                    ? colors.basics.plataniumGreenColor
                                    : colors.basics.primary,
                                  // background: colors.grey[30],
                                  '&:hover': {
                                    border: isPlataniumHotel
                                      ? `1px solid ${colors.basics.plataniumGreenColor}`
                                      : `1px solid ${colors.basics.primary}`,
                                  },
                                },
                              }}
                            >
                              <Button
                                variant='outlined'
                                sx={{ bgcolor: 'transparent' }}
                                onClick={() => {
                                  // dispatch(
                                  //   setFlightRoomState({
                                  //     ...flightRoom,
                                  //     callHolidaySummaryApi: false,
                                  //   }))
                                  dispatch(setStepperState(1));
                                  navigate(`/holiday-summary/${session_id}${window.location.search}`);
                                }}
                              >
                                Back
                              </Button>
                            </Box>

                            <Box
                              sx={{
                                margin: '0 15px',
                                '& .MuiButtonBase-root': {
                                  '&:hover': {
                                    backgroundColor: colors.grey[30],
                                  },
                                },
                              }}
                              width={'100%'}
                              textAlign={'center'}
                            >
                              <Button
                                disabled={emailTextCheckbox}
                                onClick={(e) => {
                                  setValidation(true);
                                  handleSubmit(e, false);
                                }}
                                sx={{
                                  padding: windowWidth > 435 ? '0 10px !imporatant' : '0px 8px !important',
                                  marginRight: '10px',
                                  marginBottom: { xs: '14px', md: '' },
                                  width: windowWidth > 389 ? 'fit-content' : '100%',
                                  backgroundColor: colors.grey[30],
                                }}
                                type='submit'
                              >
                                Save Itinerary
                              </Button>
                            </Box>

                            <Box display={{ sm: 'block', md: 'flex' }}>
                              <form
                                onSubmit={(e) => {
                                  setValidation(true);
                                  handleSubmit(e, true);
                                }}
                              >
                                <Box
                                  mb={{ md: 0, xs: '14px' }}
                                  sx={{
                                    '& button': {
                                      width: { md: 'auto', xs: '100%' },
                                    },
                                    '& .MuiButtonBase-root': {
                                      background: isPlataniumHotel
                                        ? colors.basics.plataniumGreenColor
                                        : colors.basics.primary,
                                      '&:hover': {
                                        background: isPlataniumHotel
                                          ? colors.basics.plataniumGreenColor
                                          : colors.basics.primary,
                                        color: colors.basics.white,
                                      },
                                    },
                                  }}
                                >
                                  <Button className='btn-olb-payment' id='submit-next-btn' type='submit'>
                                    Payment
                                  </Button>
                                </Box>
                              </form>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      width={'100%'}
                      item
                      xl={4}
                      lg={4}
                      xs={12}
                      sx={{
                        order: { lg: 2, xs: 1 },
                        position: { lg: 'sticky !important', xs: 'unset' },
                        top: '110px',
                        height: 'fit-content',
                        mt: { md: '0', xs: '0' },
                      }}
                    >
                      <Box
                        // mt={3}
                        mt={{ md: 0, xs: 2 }}
                        sx={{
                          '& .book-button': {
                            display: 'none',
                          },
                          '& .price-summary': {
                            display: 'flex',
                          },
                          '& .car-book-button': {
                            display: 'none',
                          },
                          // '& .MuiBox-root svg':{
                          //   fill:'red'
                          // }
                        }}
                      >
                        <PricingCard />
                      </Box>
                      <Box
                        mt={{ sm: 3, xs: 2 }}
                        // m="0 0 22px"
                        sx={{
                          '& .MuiAccordionSummary-expandIconWrapper': {
                            // display: expanded == 1 ? 'none' : 'flex'
                            display: 'none',
                          },
                          '& .MuiAccordionDetails-root': {
                            paddingTop: '0',
                            padding: { sm: '0px 16px 16px', xs: '0px 8px 8px' },
                          },
                          '& .MuiAccordionSummary-root': {
                            cursor: 'text !important',
                          },
                          '& .MuiAccordionSummary-content ': {
                            // margin: '16px 0',
                            display: 'flex',

                            // fontSize: { sm: "26px", xs: "24px" }
                            width: '100%',
                          },
                          '& svg': {
                            '& circle:first-of-type': {
                              fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : '',
                            },
                            '& path': {
                              fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : '',
                            },
                            fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : '',
                          },
                        }}
                      // p={'21px 16px'}
                      // p={{ sm: "16px 16px", xs: "0 8px 8px" }}
                      // border={`1px solid ${colors.grey[30]}`}
                      // borderRadius="5px"
                      // bgcolor={colors.basics.secondary}
                      // sx={{

                      // }}
                      >
                        {/* <Box>
                          <Typography
                            variant="body1"
                            textAlign={{ sm: "left", xs: "center" }}
                            fontWeight={500}
                            lineHeight="20.8px"
                            sx={{
                              fontFamily: "El Messiri",
                              fontSize: { sm: "26px", xs: "24px" },
                              color: colors.basics.black,
                            }}
                            mb={2}
                            pt={{ sm: "0", xs: "21px" }}
                            pl={{ sm: "0", xs: "8px" }}
                          >
                            {`${ArrivalLocation} Holiday Summary`}
                          </Typography>
                        </Box> */}
                        <HotelDetails showButtons={true} />
                      </Box>
                    </Grid>
                  </Grid>
                </CustomContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : !loading ? (
        <Box
          my={{ sm: '120px', xs: windowWidth > 376 ? '120px' : '175px' }}
          sx={{
            width: '100%',
            // mt: "20px",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // gap: "10px"
          }}
        >
          <Typography variant='h1' textAlign='center' color={colors.basics.primary}>
            Unfortunately, no results were returned for the package you have searched.
          </Typography>
          <Typography variant='h1' textAlign='center' color={colors.basics.primary}>
            Please search again for alternatives.
          </Typography>
          <Button
            sx={{
              mt: '20px',
              width: 'fit-content',
            }}
            onClick={() => {
              newSearch();
            }}
          >
            New Search
          </Button>
        </Box>
      ) : (
        <Box minHeight={500}></Box>
      )}
    </>
  );
};

export default PersonalDetail;
