import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { ReactComponent as TransmissionIcon } from "../../../assets/icons/TransmissionIcon.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/UserIcon.svg";
import { ReactComponent as LuggageIcon } from "../../../assets/icons/luggage.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/icons/Check2.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Image from "../../../components/Image";
import { colors } from "../../../styles/colors";
import CustomSelectInput from "../../../components/CustomSelectInput";

interface carListProps {
  car: any;
  selected: any;
  signForPricing?: any;
  isPlataniumHotel?: any;
  handleAddCarAmountToFlight?: (carElement: any, selectedCar?: any) => void;
  isDrawer?: boolean;
  selectedCar?: any;
}

export const CarList = ({
  car,
  selected,
  signForPricing,
  isPlataniumHotel,
  handleAddCarAmountToFlight,
  isDrawer,
  selectedCar,
}: carListProps) => {
  const [expandedCarFeatures, setExpandedCarFeatures] = useState(false);
  const [filters, setFilters] = useState([
    { rate_plan: "Fully Inclusive", price: null },
    { rate_plan: "Platinum", price: null },
  ]);
  const [selectedFilter, setSelectedFilter] = useState({
    rate_plan: selectedCar?.selectedCarPrice?.rate_plan,
    price: selectedCar?.selectedCarPrice?.price,
  });

  const handleCarAccordionChange = () => {
    setExpandedCarFeatures((prev: any) => !prev);
  };

  console.log('selectedCar', selectedCar)

  useEffect(() => {
    const filteredItems = Object.keys(car)
      .filter((key) => key.startsWith("option"))
      .map((key) => car[key]);
    setFilters(
      Array.from(
        filteredItems
          .reduce((map, item) => {
            if (!map.has(item.rate_plan) || item.price < map.get(item.rate_plan).price) { // store data with lower price in filters state
              map.set(item.rate_plan, item);
            }
            return map;
          }, new Map())
          .values()
      )
    );
  }, []);

  return (
    <Grid
      key={car.id}
      xl={!isDrawer && 6}
      lg={isDrawer ? 4 : 6}
      md={6}
      xs={12}
      sm={6}
      item
      sx={{
        // pl: "0px !important",
        mb: "20px",
        backgroundColor: "transparent",
      }}
    >
      <Box
        sx={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: colors.basics.white,
        }}
      >
        <Box
          sx={{
            backgroundColor: colors.basics.white,
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              py: 2,
              overflow: "hidden",
            }}
            width={"100%"}
            height={"auto"}
          >
            <Image src={car.image} alt={car.model} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, padding: "0 16px" }}>
            <Typography variant="body1" fontWeight={700}>
              {car.model}
            </Typography>
            <Typography variant="body2" fontWeight={400}>
              {car.ota_car_type}
            </Typography>
          </Box>
          <Grid
            container
            columnSpacing={1}
            mt={2}
            mb={2}
            sx={{ padding: "0 16px", justifyContent: "space-between" }}
          >
            <Grid lg={4} item>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  "& svg": {
                    "& path": {
                      fill: colors.basics.black,
                    },
                  },
                }}
              >
                <UserIcon />
                <Typography variant="subtitle2">{car.passenger_qty} People</Typography>
              </Box>
            </Grid>
            <Grid lg={4} item>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  "& svg": {
                    "& path": {
                      fill: colors.basics.black,
                    },
                  },
                }}
              >
                <LuggageIcon />
                <Typography variant="subtitle2">{car.baggage_qty} Bags</Typography>
              </Box>
            </Grid>
            <Grid lg={4} item>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  "& svg": {
                    "& path": {
                      fill: colors.basics.black,
                    },
                  },
                }}
              >
                <TransmissionIcon />
                <Typography variant="subtitle2">{car.type}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ padding: "0 16px" }}>
            <CustomSelectInput
              ids={`car-${car.id}`}
              data={filters.map((filter: any) => filter?.rate_plan)}
              menuOnClick={(e: any) => {
                setSelectedFilter({
                  rate_plan: e.target.textContent,
                  price: filters.find((filter: any) => filter?.rate_plan === e.target.textContent)?.price,
                });
                if (selected) {
                  handleAddCarAmountToFlight?.(
                    car,
                    filters.find((filter: any) => filter?.rate_plan === e.target.textContent)
                  )
                }
              }}
              multiple={false}
              value={selectedFilter.rate_plan}
              placeholder="Select Car"
            />
          </Box>
          <Accordion
            expanded={expandedCarFeatures}
            onChange={handleCarAccordionChange}
            sx={{
              display: "block",
              boxShadow: "none",
              "&::before": {
                height: "0 !important",
              },
              "&": {
                margin: "0 !important",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                "&": {
                  minHeight: "48px !important",
                  margin: "0 !important",
                },
                "& .Mui-expanded": {
                  margin: "12px 0 !important",
                },
              }}
            >
              <Typography variant="body1" fontWeight={600} fontSize={"14px"}>
                Features & Price Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ bgcolor: "white", "&": { padding: "10px" } }}>
              <Box sx={{ px: "6px" }}>
                {car.tax_rates.map((tax: any, i: number) =>
                  tax.description ? (
                    <Box sx={{ display: "flex", justifyContent: "space-between" }} key={i}>
                      <Typography
                        variant="body2"
                        fontSize={"13px"}
                        textTransform={"capitalize"}
                        lineHeight={1.2}
                      >
                        {tax.description}
                      </Typography>
                      <Typography
                        variant="body2"
                        fontSize={"13px"}
                        lineHeight={1.2}
                        sx={{ color: tax.value === "INCLUDED" ? "green" : "red" }}
                      >
                        {tax.value}
                      </Typography>
                    </Box>
                  ) : null
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            justifyContent: "space-between",
            padding: "10px 16px",
            borderTop: "1px solid #ccc",
            backgroundColor: colors.basics.white,
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {/* <Typography variant="body1" fontWeight={600}>
                                  + ${car.base_rate} pp
                                </Typography> */}
            <Typography variant="body2">
              Price: {signForPricing}
              {selected ? selectedFilter?.price : filters.find((filter: any) => filter?.rate_plan === selectedFilter.rate_plan)?.price || car.cust_tot_sell_amt}
            </Typography>
          </Box>
          <Box
            sx={{
              "& .MuiButtonBase-root": {
                background: selected
                  ? colors.grey[10]
                  : isPlataniumHotel
                    ? `${colors.basics.plataniumGreenColor} !important`
                    : `${colors.basics.primary} !important`,
                color: colors.basics.white,
                border: selected
                  ? colors.grey[10]
                  : isPlataniumHotel
                    ? `1px solid ${colors.basics.plataniumGreenColor} !important`
                    : `1px solid ${colors.basics.primary} !important`,
                // background: colors.grey[30],
                "&:hover": {
                  background: selected
                    ? colors.grey[10]
                    : isPlataniumHotel
                      ? `${colors.basics.plataniumGreenColor} !important`
                      : `${colors.basics.primary} !important`,
                  color: colors.basics.white,
                  border: selected
                    ? colors.grey[10]
                    : isPlataniumHotel
                      ? `1px solid ${colors.basics.plataniumGreenColor} !important`
                      : `1px solid ${colors.basics.primary} !important`,
                },
              },
            }}
          >
            <Button
              id="customize-holiday-btn"
              sx={{ width: "100%" }}
              variant="outlined"
              startIcon={selected ? <CheckedIcon fill={colors.basics.white} /> : <AddCircleIcon />}
              onClick={() =>
                handleAddCarAmountToFlight?.(
                  car,
                  filters.find((filter: any) => filter?.rate_plan === selectedFilter.rate_plan)
                )
              }
            >
              {selected ? "Selected" : "Add"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
