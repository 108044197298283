import { Box, Button, Dialog, Grid, IconButton, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API } from '../../api/services';
import { API_METHOD } from '../../api/services/apiMethods';
import Pagination from '@mui/material/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../features/loading/loadingSlice';
import { isEmpty } from '../../helpers/isEmpty';
import { colors } from '../../styles/colors';
import TextInput from '../../components/TextInput';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import * as yup from 'yup';
import { CustomContainer } from '../../components/CustomContainer';
import { CSVLink } from "react-csv";
import "./Logs.css";
import CustomSelectInput from '../../components/CustomSelectInput';
import { getSessionStorage, setSessionStorage } from '../../helpers/storage';
import { DestinationList } from '../../constants/Destinations';
import AutoComplete from '../../components/AutoComplete';
import { ReactComponent as CloseModal } from '../../assets/icons/CloseModal.svg';
import DatePicker, { Calendar, DateObject } from 'react-multi-date-picker';
import dayjs from 'dayjs';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import useWindowWidth from '../../hooks/useWindowWidth';
import parseHTML from '../../helpers/parseHtml';
import { capitalizeFirstLetterEachWords } from '../../helpers/capitalizeFirstLetterEachWords';

interface filters {
    tempQuote: string,
    passengerInfo: string,
    branch: string,
    dateFilterSelection: string,
    destinations: string,
    folderNo: string,
    rowsPerPage: number,
    startDate: string,
    endDate: string
}

const Logs = () => {
    const windowWidth = useWindowWidth()
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useState(1)
    const [refWidth, setRefWidth] = useState(null)
    const [totalRows, setTotalRows] = useState(0)
    const [download, setDownload] = useState(false);

    const dispatch = useDispatch()
    const wrapper1Ref = useRef<any>(null);
    const wrapper2Ref = useRef<any>(null);
    const [hidePassword, setHidePassword] = useState(true)
    const [formError, setFormErrors] = useState<any>({});
    const [buttonClick, setButtonClick] = useState(false)
    const [filter, setFilter] = useState<filters>({
        tempQuote: "",
        passengerInfo: "",
        branch: "",
        dateFilterSelection: "",
        destinations: "",
        folderNo: "",
        rowsPerPage: 30,
        startDate: "",
        endDate: ""

    })
    const [open, setOpen] = React.useState(false);
    const pageCount = Math.ceil(totalRows / filter?.rowsPerPage);
    const [csvData, setCSVData] = useState([])
    const [password, setPassword] = useState("")
    const { loading } = useSelector((state: any) => state.loading);

    const tableRef = useRef<any>(null);
    const [isUserAlreadyLogin, setIsUserAlreadyLogin] = useState(getSessionStorage("isAuthLogs") !== null ? Boolean(getSessionStorage("isAuthLogs")) : false)
    const [values, setValues] = useState<any>([
        new DateObject().add(7, 'days'),
        new DateObject().add(0, 'days'),
    ]);

    const startDate = dayjs(values[0]).format("DD MMM YY");
    const endDate = dayjs(values[1]).format("DD MMM YY");

    const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const style = {
        // position: 'relative',
        marginBottom: '3px',
        // height: '47px',
        // display: 'flex',
        alignItems: 'center',
        '& .rmdp-shadow': {
            boxShadow: 'none',
            // border: `1px solid ${colors.grey[30]}`,
        },
        "& .rmdp-day-picker > div:first-of-type": {
            marginRight: windowWidth > 1023 ? "20px !important" : 0,
        },
        '& .rmdp-calendar': {
            padding: '24px',
            '& .rmdp-header': {
                marginBottom: { sm: 0, xs: '10px' }
            }
        },

        '& .rmdp-day-picker ': {
            display: { sm: 'flex !important', xs: 'block !important' },
        },

        '& .rmdp-day span': {
            left: '0 !important',
            right: '0 !important',
            top: '0 !important',
            borderRadius: '0 !important',
            bottom: '0 !important',
        },
        ' & .rmdp-range': {
            backgroundColor: '#e5e5e5',
            borderColor: colors.basics.lightmain,
            color: colors.basics.black,
        },
        '& .rmdp-day': {
            borderColor: '#d5d5d5 !important',
            // backgroundColor: `${colors.basics.white}`,
            borderLeft: `0.5px solid ${colors.basics.lightmain}`,
            borderTop: `0.5px solid ${colors.basics.lightmain}`,
            boxShadow: 'none !important',
            borderRight: `0.5px solid ${colors.basics.lightmain}`,
            borderBottom: `0.5px solid ${colors.basics.lightmain}`,
            '& span': {
                backgroundColor: `${colors.basics.white} !important`,
                borderColor: 'transparent',
                color: `${colors.basics.black} !important`,
            },
        },

        '& .rmdp-day-hidden': {
            visibility: 'hidden',
        },
        '& .rmdp-day span:hover': {
            backgroundColor: '#c4c4c4 !important',
            color: `${colors.basics.black} !important`,
            borderRadius: '0 !important',
            top: '0 !important',
            left: '0 !important',
            bottom: '0 !important',
            right: '0 !important',
        },
        '& .rmdp-day.rmdp-range': {
            backgroundColor: '#e5e5e5 !important',
            borderRadius: '0',
        },

        '& .rmdp-arrow-container': {
            '&:hover': {
                backgroundColor: `${colors.basics.white} !important`,
                boxShadow: 'none',
                '& .rmdp-arrow': {
                    borderColor: colors.grey[30],
                },
            },
        },

        '& .rmdp-day.rmdp-range span': {
            backgroundColor: '#e5e5e5 !important',
        },
        '& .rmdp-day.rmdp-range.start span': {
            backgroundColor: '#c4c4c4 !important',
            left: '0 !important',
            right: '0 !important',
            top: '0 !important',
            borderRadius: '0 !important',
            bottom: '0 !important',
        },
        ' & .rmdp-day.rmdp-range.end span': {
            backgroundColor: '#c4c4c4 !important',
            left: '0 !important',
            right: '0 !important',
            top: '0 !important',
            borderRadius: '0 !important',
            bottom: '0 !important',
        },

        '& .rmdp-week-day': {
            color: colors.basics.black,
        },
        '& .rmdp-arrow ': {
            height: { sm: '15px', xs: '10px' },
            width: { sm: '15px', xs: '10px' },
            borderColor: colors.grey[30],
        },
        '& .cal-icon': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: '10px',
            display: 'flex',

            '& .arrow': {
                position: 'absolute',
                top: '50%',
                right: '17px',
                cursor: 'pointer',
                transform: 'translateY(-50%)',
                pointerEvents: 'none',
            },
            '& .arrow svg': {
                fill: colors.grey[30],
            },
            '& .rmdp-container .rmdp-input': {
                width: '100%',
                height: '56px',
                borderRadius: '5px',
                // border: isEmpty(openModals) !== "" && openModals.date === true ? `1px solid ${colors.basics.black}` : `1px solid ${colors.grey[30]}`,
                padding: '10px',
                color: colors.grey[10],
                pl: '42px',
                pr: '37px',
                fontFamily: 'Raleway',
                fontSize: '14px'
            },

            '& .rmdp-container': {
                width: '100%',
            },
            '& input.rmdp-input': {
                pl: '45px',
            },
        },

        '& .arrow': {
            position: 'absolute',
            top: '50%',
            right: '17px',
            cursor: 'pointer',
            transform: 'translateY(-50%)',
            pointerEvents: 'none',
        },
        '& .arrow svg': {
            fill: colors.grey[30],
        },
        '& .rmdp-container .rmdp-input': {
            width: '100%',
            height: '56px',
            borderRadius: '5px',
            //   border: isEmpty(openModals) !== "" && openModals.date === true ? `1px solid ${colors.basics.black}` : `1px solid ${colors.grey[30]}`,
            padding: '10px',
            color: colors.grey[10],
            pl: '42px',
            pr: '37px',
            fontFamily: 'Raleway',
            fontSize: '14px',
        },

        '& .rmdp-container': {
            width: '100%',
        },
        '& input.rmdp-input': {
            paddingLeft: '45px',
        },

        '& .rmdp-week, & .rmdp-ym': {
            justifyContent: 'unset',
        },
    };

    const validationSchema = yup.object().shape({
        password: yup.string().matches(/^TSK011@5$/, "Password is incorrect").required("Password is required"),
    });

    const formattedDestinations = DestinationList?.map(dest => `${dest["Display Name #1"]} ${dest["airport_code"]}`);

    const colourCodes = {
        holiday_package_search: colors.grey[50],
        holiday_detail: colors.grey[30],
        get_folder: colors.basics.primary,
    }

    // const dateFormate = [
    //     new DateObject(dayjs(startDate).format("YYYY/MM/DD")),
    //     new DateObject(dayjs(endDate).format("YYYY/MM/DD"))
    // ];
    // console.log('dateFormate :', dateFormate);
    // setDate(dateFormate)


    // console.log('date000 :', dateFormate);
    const column = [
        {
            label: "Branch",
            key: 'branch',
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: false,
            writingmode: true,
            color: colourCodes?.holiday_package_search,
        }, {
            label: "Search Date",
            key: "searchDate",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: false,
            writingmode: false,
            color: colourCodes?.holiday_package_search,
        }, {
            label: "Search",
            key: "search",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: false,
            writingmode: true,
            color: colourCodes?.holiday_package_search,
        }, {
            label: "Retrieve Basket",
            key: "retrieveBasket",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: false,
            writingmode: true,
            color: colourCodes?.holiday_package_search,
        },
        {
            label: "Departure",
            key: "fromValue",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: false,
            writingmode: true,
            color: colourCodes?.holiday_package_search,
        }, {
            label: "Destination",
            key: "toValue",
            noWrap: true,
            isFromCreateFolder: false,
            isFromHolidayDetail: false,
            writingmode: false,
            color: colourCodes?.holiday_package_search,
        }, {
            label: "Dep Date",
            key: "departureDate",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: false,
            writingmode: false,
            color: colourCodes?.holiday_package_search,
        }, {
            label: "No. of Nights",
            key: "duration",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: false,
            writingmode: true,
            color: colourCodes?.holiday_package_search,
        }, {
            label: "No. Adults",
            key: "adults",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: false,
            writingmode: true,
            color: colourCodes?.holiday_package_search,
        }, {
            label: "No. Children",
            key: "children",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: false,
            writingmode: true,
            color: colourCodes?.holiday_package_search,
        }, {
            label: "No. Infants",
            key: "infants",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: false,
            writingmode: true,
            color: colourCodes?.holiday_package_search,
        }, {
            label: "No. Rooms",
            key: "rooms",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: false,
            writingmode: true,
            color: colourCodes?.holiday_package_search,
        }, {
            label: "Hotel Name",
            key: "hotelName",
            noWrap: true,
            isFromCreateFolder: false,
            isFromHolidayDetail: true,
            writingmode: false,
            color: colourCodes?.holiday_detail,
        }, {
            label: "Total Package Amount",
            key: "totalPackageAmount",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: true,
            writingmode: false,
            color: colourCodes?.holiday_detail,
        }, {
            label: "Package Amount Per Person",
            key: "packageAmountPerPerson",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: true,
            writingmode: false,
            color: colourCodes?.holiday_detail,
        }, {
            label: "Flight Total Net Fare ",
            key: "flightTotalNetFare",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: true,
            writingmode: false,
            color: colourCodes?.holiday_detail,
        }, {
            label: "Room Total Net Amount",
            key: "roomTotalNetAmount",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: true,
            writingmode: false,
            color: colourCodes?.holiday_detail,
        }, {
            label: "Mark Up Amount",
            key: "markupAmount",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: true,
            writingmode: false,
            color: colourCodes?.holiday_detail,
        }, {
            label: "Auto Fee Amount",
            key: "autoFeeAmount",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: true,
            writingmode: false,
            color: colourCodes?.holiday_detail,
        },
        {
            label: "Temp Quote",
            key: "tempQuote",
            noWrap: false,
            isFromCreateFolder: false,
            isFromHolidayDetail: true,
            writingmode: true,
            color: colourCodes?.holiday_detail,
        },
        {
            label: "Folder No",
            key: "folderNo",
            noWrap: false,
            isFromCreateFolder: true,
            isFromHolidayDetail: false,
            writingmode: false,
            color: colourCodes?.get_folder,
        }, {
            label: "Selected Hotel",
            key: "selectedHotel",
            noWrap: true,
            isFromCreateFolder: true,
            isFromHolidayDetail: false,
            writingmode: false,
            color: colourCodes?.get_folder,
        }, {
            label: "Room Names",
            key: "roomType",
            noWrap: true,
            isFromCreateFolder: true,
            isFromHolidayDetail: false,
            writingmode: false,
            color: colourCodes?.get_folder,
        },
        {
            label: "Passenger Info",
            key: "passengerInfo",
            noWrap: false,
            isFromCreateFolder: true,
            isFromHolidayDetail: false,
            writingmode: true,
            color: colourCodes?.get_folder,
        },
        {
            label: "Payment Validation",
            key: "paymentStatus",
            noWrap: true,
            isFromCreateFolder: true,
            isFromHolidayDetail: false,
            writingmode: true,
            color: colourCodes?.get_folder,
        },
        {
            label: "Payment",
            key: "payment",
            noWrap: false,
            isFromCreateFolder: true,
            isFromHolidayDetail: false,
            writingmode: true,
            color: colourCodes?.get_folder,
        }, {
            label: "Booking",
            key: "booking",
            noWrap: false,
            isFromCreateFolder: true,
            isFromHolidayDetail: false,
            writingmode: true,
            color: colourCodes?.get_folder,
        }
    ]

    const dateFilters = [
        "Yesterday",
        "Last 7 days",
        "Last 30 days",
        "This Month",
        "Last Month",
        "This Year"
    ]

    const branchFilters = [
        "UK",
        "IE"
    ]

    useEffect(() => {
        if (logs) {
            setTimeout(() => {
                setRefWidth(tableRef?.current?.offsetWidth)
            }, 500);
        }
    }, [logs])

    const cell = useCallback((row: any, c: any, columnIndex: any, borderRemove: boolean, noKeys?: any) => {
        let content;
        if (c.key === "payment" || c.key === "booking" || c.key === "paymentStatus") {
            content = row[c.key] === null ? "null" : row[c.key];
        } else {
            content = row[c?.key];
        }
        return (
            <TableCell dangerouslySetInnerHTML={{ __html: isEmpty(row[c?.key]) !== '' ? row[c?.key] : noKeys ? row : content }}
                style={{
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                    // whiteSpace: (c.key === "hotelName" || c.key === "selectedHotel") ? "pre-wrap" : noKeys ? "pre-wrap" : "nowrap",
                    textAlign: c.writingmode ? "center" : "left",
                    border: borderRemove ? "none" : "",
                    padding: "12px"
                }} key={columnIndex}></TableCell>
        )
    }, [])

    const mergeColumns = useCallback((array: any, c: any, noKeys?: any) => {
        return (
            <TableCell>
                {array?.length > 0 && array.map((a: any, aI: any) => {
                    return (
                        <TableRow key={aI}>
                            {cell(a, c, aI, true, noKeys)}
                        </TableRow>
                    )
                })}
            </TableCell>
        )
    }, [cell])

    function searchMatchingDestinations(searchedDest: any, DestinationList: any) {
        let matchedDestinations: any = [];

        const searchedArr = searchedDest?.split(' ');
        const airportCodeSearch = searchedArr?.pop();
        const displaySearch = searchedArr?.join(' ');
        const matches = DestinationList?.filter((dest: any) => dest['airport_code'] === airportCodeSearch && dest['Display Name #1'] === displaySearch);
        if (matches?.length > 0) {
            matchedDestinations = matches?.map((match: any) => ({
                searchID: match['Searc ID'],
                displayName: match['Display Name #1'],
                airportCode: match['airport_code'],
            }));
        }
        return matchedDestinations;
    }

    const bookingHistory = useCallback((page: any, data: any, exportButtonClick?: boolean) => {
        dispatch(setLoading(true))
        const destinationSearchId = searchMatchingDestinations(data?.destinations, DestinationList);

        let body = {
            "branch": data?.branch,
            "filter": data.startDate !== "" ? "Custom" : "",
            "DestinationSearchID": destinationSearchId[0]?.searchID ? destinationSearchId[0]?.searchID : "",
            "pageNumber": page,
            "pageSize": data?.rowsPerPage ? data?.rowsPerPage : filter?.rowsPerPage,
            tempQuote: data?.tempQuote,
            passengerInfo: data?.passengerInfo,
            "folderNo": data?.folderNo ? data?.folderNo : "",
            "startDate": String(data.startDate),
            "endDate": String(data.endDate)
        }

        const results = API.post(`${API_METHOD.Package.GET_PACKAGE_LOGS}`, body)
            .then((result: any) => {
                const res: any = result?.data
                if (exportButtonClick) {
                    dispatch(setLoading(false))
                    return res?.logList
                } else {
                    setTotalRows(res?.totalRecords)
                    setLogs(res?.logList);
                    // setColourCodes(res?.colorCodes)
                    setTimeout(() => {
                        dispatch(setLoading(false))
                    }, 10)
                    return res?.logList
                }
            })
            .catch((error: any) => {
                console.log('Error:', error);
                dispatch(setLoading(false))
            });
        return results
    }, [dispatch])

    useEffect(() => {
        if (download) {
            let downloadLink = document.getElementById('csv-download-link');
            if (downloadLink) {
                downloadLink.click();
                setDownload(false);
            }
        }
    }, [download, filter, document.getElementById('csv-download-link')]);

    useEffect(() => {
        if (isUserAlreadyLogin) {
            bookingHistory(1, filter)
        }
    }, [bookingHistory]);


    const handleClickOpen = () => {
        setOpen(true);
        if (filter.startDate !== "") {
            const startDate1 = dayjs(filter.startDate).format("DD MMM YY");
            const endDate1 = dayjs(filter.endDate).format("DD MMM YY");
            setValues([startDate1, endDate1])
        } else {
            setValues([
                new DateObject().add(0, 'days'),
                new DateObject().add(7, 'days'),
            ])
        }
    };

    const handleClose = () => {
        const obj: any = { ...filter }
        obj['rowsPerPage'] = 30

        if (obj.dateFilterSelection === "") {
            obj.startDate = ""
            obj.endDate = ""
        }
        // obj['dateFilterSelection'] = "Custom"
        setFilter(obj)
        setOpen(false);
    };

    const handleWrapper1Scroll = (e: any) => {
        if (wrapper2Ref.current) {
            wrapper2Ref.current.scrollLeft = e.target.scrollLeft;
            wrapper1Ref.current.scrollLeft = e.target.scrollLeft;
        }
    };

    const customApplyClick = async (startDate: any, endDate: any) => {
        const startDateFormate = dayjs(startDate).format("MM-DD-YYYY")
        const endDateFormate = dayjs(endDate).format("MM-DD-YYYY")

        const obj: any = { ...filter }
        obj['startDate'] = startDateFormate
        obj['endDate'] = endDateFormate
        obj['rowsPerPage'] = -1
        setFilter(obj)
        const bookingHistoryData: any = await bookingHistory(1, obj, true);

        if (bookingHistoryData) {
            const rows = getRowsData(bookingHistoryData)
            const header = [column.map(item => item.label)];
            let mergerArr: any = [...header, ...rows]
            setCSVData(mergerArr)
            setDownload(false)
            setDownload(true)
            setTimeout(() => {
                setRefWidth(tableRef?.current?.offsetWidth)
            }, 500);

        }
    }

    const handleWrapper2Scroll = (e: any) => {
        if (wrapper1Ref.current) {
            wrapper2Ref.current.scrollLeft = e.target.scrollLeft;
            wrapper1Ref.current.scrollLeft = e.target.scrollLeft;
        }
    };

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
        bookingHistory(newPage, filter)
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }, 10)
    };

    const filterDates = (value: string, obj: filters) => {
        let startDate = ""
        let endDate = ""
        const dates = [];
        const today = new Date();

        switch (value) {
            case "Yesterday":
                today.setDate(today.getDate() - 1);

                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const day = String(today.getDate()).padStart(2, '0');

                startDate = `${month}-${day}-${year}`;
                endDate = `${month}-${day}-${year}`
                break;
            case "Last 7 days":
                for (let i = 0; i < 7; i++) {
                    const sevenDaysAgo = new Date(Date.now() - i * 24 * 60 * 60 * 1000)
                    const year = sevenDaysAgo.getFullYear();
                    const month = String(sevenDaysAgo.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(sevenDaysAgo.getDate()).padStart(2, '0');
                    dates.push(`${month}-${day}-${year}`)
                }

                startDate = dates[dates.length - 1];
                endDate = dates[0]
                break;
            case "Last 30 days":
                for (let i = 0; i < 30; i++) {
                    const sevenDaysAgo = new Date(Date.now() - i * 24 * 60 * 60 * 1000)
                    const year = sevenDaysAgo.getFullYear();
                    const month = String(sevenDaysAgo.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const day = String(sevenDaysAgo.getDate()).padStart(2, '0');
                    dates.push(`${month}-${day}-${year}`)
                }

                startDate = dates[dates.length - 1];
                endDate = dates[0]
                break;
            case "This Month":
                const thisMonthDates = [];
                const yearThisMonth = today.getFullYear();
                const monthThisMonth = String(today.getMonth() + 1).padStart(2, '0');

                for (let i = 1; i <= today.getDate(); i++) {
                    const day = String(i).padStart(2, '0');
                    thisMonthDates.push(`${monthThisMonth}-${day}-${yearThisMonth}`);
                }

                endDate = thisMonthDates[thisMonthDates.length - 1];
                startDate = thisMonthDates[0]
                break;
            case "Last Month":
                const lastMonthDates = [];
                const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const yearLastMonth = lastMonth.getFullYear();
                const monthLastMonth = Number(String(lastMonth.getMonth() + 1).padStart(2, '0'));
                const daysInLastMonth = new Date(yearLastMonth, monthLastMonth, 0).getDate(); // Get total days in last month

                for (let i = 1; i <= daysInLastMonth; i++) {
                    const day = String(i).padStart(2, '0');
                    lastMonthDates.push(`${monthLastMonth}-${day}-${yearLastMonth}`);
                }

                endDate = lastMonthDates[lastMonthDates.length - 1];
                startDate = lastMonthDates[0]
                break;
            case "This Year":
                const thisYearDates = [];
                const yearThisYear = today.getFullYear();

                for (let month = 0; month <= today.getMonth(); month++) {
                    const daysInMonth = new Date(yearThisYear, month + 1, 0).getDate(); // Get total days in the month
                    const monthOfYear = String(month + 1).padStart(2, '0');

                    for (let day = 1; day <= (month === today.getMonth() ? today.getDate() : daysInMonth); day++) {
                        const dayOfMonth = String(day).padStart(2, '0');
                        thisYearDates.push(`${monthOfYear}-${dayOfMonth}-${yearThisYear}`);
                    }
                }

                endDate = thisYearDates[thisYearDates.length - 1];
                startDate = thisYearDates[0]
                break;
        }

        obj['startDate'] = startDate
        obj['endDate'] = endDate
    }

    const handleFilters = async (e: any, name: string, value: any, exportButtonClick?: boolean) => {
        const obj: any = { ...filter }
        obj[name] = value
        if (name === "dateFilterSelection") {
            await filterDates(value, obj)
        }
        await setPage(1)
        await setFilter(obj)
        await bookingHistory(1, obj, exportButtonClick)
    }

    const handleReset = () => {
        let obj: any = {
            ...filter,
            tempQuote: "",
            passengerInfo: "",
            branch: "",
            dateFilterSelection: "",
            destinations: "",
            folderNo: "",
            startDate: "",
            endDate: "",
            rowsPerPage: 30,

        }

        setFilter(obj)
        setPage(1)
        bookingHistory(1, obj)
    }

    const handleClickShowPassword = () => {
        setHidePassword(!hidePassword)
    }

    const handleFormData = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        let obj = {
            password: value
        }
        setPassword(value)

        if (buttonClick) {
            validationSchema
                .validate(obj, { abortEarly: false })
                .then(async () => {
                    setFormErrors({});
                })
                .catch((err) => {
                    const errors: any = {};
                    err.inner.forEach((e: any) => {
                        errors[e.path] = e.message;
                    });
                    setFormErrors(errors);
                    dispatch(setLoading(false))
                });
        }
    };

    const handleSubmit = () => {
        setButtonClick(true)
        let obj = {
            password: password
        }

        validationSchema
            .validate(obj, { abortEarly: false })
            .then(async () => {
                setFormErrors({});
                setIsUserAlreadyLogin(true)
                dispatch(setLoading(true))
                bookingHistory(1, filter)
                setSessionStorage("isAuthLogs", "true")
            })
            .catch((err) => {
                const errors: any = {};
                err.inner.forEach((e: any) => {
                    errors[e.path] = e.message;
                });
                setFormErrors(errors);
                dispatch(setLoading(false))
            });
    }

    const getRowsData = useCallback((bookingHistoryData: any) => {
        let wholeData: any = [];

        bookingHistoryData?.map((row: any, rowIndex: number) => {
            let colWiseArr: any = [];

            column?.map((c: any) => {
                if (c.isFromCreateFolder) {
                    row.createFolderLog.map((cf: any) => {
                        if (c.key === "roomType") {
                            let multipleRoomsTypeData: string[] = [];
                            cf.roomType.map((r: any) => {
                                const changeHTMLText = parseHTML(capitalizeFirstLetterEachWords(isEmpty(r)))
                                const textContent: string | null = changeHTMLText.body.textContent
                                textContent !== null && multipleRoomsTypeData.push(textContent);
                                return <></>;
                            });
                            colWiseArr.push(multipleRoomsTypeData.join(',  '));
                        } else {
                            let value = (c.key === "payment" || c.key === "booking" || c.key === "paymentStatus") ? (cf[c.key] === null ? "Null" : cf[c.key]) : cf[c.key]
                            colWiseArr.push(value);
                        }
                        return <></>;
                    });
                } else {
                    if (c.isFromHolidayDetail) {
                        let multipleRoomsData: string[] = [];

                        row.holidayDetailLog.map((h: any) => {
                            const changeHTMLText = parseHTML(capitalizeFirstLetterEachWords(isEmpty(h[c.key])))
                            const textContent: string | null = changeHTMLText.body.textContent
                            textContent !== null && multipleRoomsData.push(textContent);
                            return <></>;
                        });

                        colWiseArr.push(multipleRoomsData.join(',  '));
                    } else {
                        colWiseArr.push(isEmpty(row[c.key]));
                    }
                }
                return <></>;
            });

            wholeData.push(colWiseArr);
            return <></>;
        });

        return wholeData;
    }, [])

    const handleOnChangeDestination = async (value: string) => {
        // const selectedDest: DestinationListType | undefined = DestinationList.find(
        //     (item) => item['Display Name #1'] === value
        // );
        let matchedDestinations: any = ''
        const airportCodeSearch = value?.slice(-3);
        const match = DestinationList?.find((dest: any) => dest['airport_code'] === airportCodeSearch);

        if (match) {
            matchedDestinations = match['Searc ID'];
        }
        const obj: any = { ...filter }
        obj['destinations'] = value
        setFilter(obj)
        bookingHistory(1, obj)

    }

    const handleFormChangeData = (evt: InputEvent) => {
        let obj: any = {}
        const { name, value } = evt.target
        obj = { ...filter }
        obj[name] = value
        setFilter(obj)
        setTimeout(() => {
            bookingHistory(1, obj)
        }, 1000);
    }

    return (
        <Box pt={{ xs: windowWidth < 376 ? '130px' : '70px', xl: "0px", lg: '20px', md: '30px', sm: '44px' }}>
            {!isUserAlreadyLogin ? <Box className="add-secondary-colour" bgcolor={colors.basics.secondary}>
                <CustomContainer style={{ marginTop: window.innerWidth > 1200 ? '71px' : '31px', marginBottom: window.innerWidth > 1200 ? '71px' : '31px' }}>
                    <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
                        <Box m={{ md: '51px', xs: '20px 0' }} mr={{ xl: '0px' }} width={{ xs: '100%' }} maxWidth='700px'>
                            <form>
                                <Typography variant='h1' fontSize='35px' fontWeight={700} lineHeight='42px'>
                                    Logs
                                </Typography>
                                <Box my={"17px"} sx={{
                                    '.Mui-error': {
                                        margin: "0",
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        width: { xs: "100%", sm: "60%" }
                                        // windowWidth >= 600 ? "60%" : "100%"
                                    }
                                }}>
                                    <TextInput
                                        title='PASSWORD'
                                        type={!hidePassword ? "text" : "password"}
                                        name='password'
                                        onChange={handleFormData}
                                        error={formError && formError['password'] ? true : false}
                                        helperText={formError && formError['password'] ? formError['password'] : ''}
                                        style={{ marginTop: '25px' }}
                                        EndIcon={
                                            <IconButton onClick={() => handleClickShowPassword()}>
                                                {!hidePassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                            </IconButton>
                                        }
                                    />
                                </Box>

                                <Box width={{ xs: "100%", sm: "60%" }} mt={formError?.password ? 3 : 1} >
                                    <Button variant='contained' onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Box>

                </CustomContainer>
            </Box> :
                <Box m={"20px"}>
                    <Grid container>
                        <Grid item xs={12} lg={12} xl={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} xl={3} sx={{
                                    '& .dropdown-menu.show ': {
                                        maxHeight: "400px ",
                                    }
                                }}>
                                    <Typography variant='subtitle2' color={colors.grey[10]} mb='8px' textTransform='uppercase'>
                                        Destination
                                    </Typography>
                                    <Box position={'relative'} sx={{
                                        '& .css-r4743b ': {
                                            mr: "6px"
                                        }
                                    }}>
                                        <AutoComplete
                                            data={formattedDestinations}
                                            value={filter?.destinations}
                                            disableClearable
                                            onChange={(value) => handleOnChangeDestination(value)}
                                        />
                                    </Box>

                                </Grid>
                                <Grid item xs={6} sm={4} xl={1.8}>
                                    <Typography variant='subtitle2' color={colors.grey[10]} mb='8px' textTransform='uppercase'>
                                        Filters
                                    </Typography>
                                    <CustomSelectInput
                                        name={"dateFilterSelection"}
                                        ids="filters"
                                        dropdownWidth={"100%"}
                                        data={dateFilters}
                                        value={filter?.dateFilterSelection}
                                        onChange={(e: any, n: any, v: any) => {
                                            handleFilters(e, n, v)
                                        }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4} xl={1.4}>
                                    <Typography variant='subtitle2' color={colors.grey[10]} mb='8px' textTransform='uppercase'>
                                        Branch
                                    </Typography>
                                    <CustomSelectInput
                                        name="branch"
                                        dropdownWidth={"100%"}
                                        ids="branch"
                                        data={branchFilters}
                                        value={filter?.branch}
                                        onChange={(e: any, n: any, v: any) => handleFilters(e, n, v)}
                                    />

                                </Grid>
                                <Grid item xs={6} sm={4} xl={1.7}>
                                    <Typography variant='subtitle2' color={colors.grey[10]} mb='8px' textTransform='uppercase'>
                                        Temp Quote
                                    </Typography>
                                    <CustomSelectInput
                                        ids="tempQuote"
                                        dropdownWidth={"100%"}
                                        data={["0", "1"]}
                                        name="tempQuote"
                                        value={filter?.tempQuote}
                                        onChange={(e: any, n: any, v: any) => handleFilters(e, n, v)}
                                    />

                                </Grid>
                                <Grid item xs={6} sm={4} xl={2.2}>
                                    <Typography variant='subtitle2' color={colors.grey[10]} mb='8px' textTransform='uppercase'>
                                        Passenger Info
                                    </Typography>
                                    <CustomSelectInput
                                        ids="passengerInfo"
                                        data={["null", "1"]}
                                        dropdownWidth={"100%"}
                                        name="passengerInfo"
                                        value={filter?.passengerInfo}
                                        onChange={(e: any, n: any, v: any) => handleFilters(e, n, v)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} xl={1.5}>
                                    <TextInput
                                        title='FOLDER NO'
                                        required
                                        value={filter?.folderNo}
                                        onChange={(evt) => handleFormChangeData(evt)}
                                        name={`folderNo`}


                                    />
                                </Grid>
                            </Grid>
                            {Object.values(filter).some((e) => e !== "") && <span onClick={() => {
                                handleReset()
                            }} style={{
                                marginLeft: "auto",
                                textAlign: "center",
                                fontSize: "13px",
                                color: "red",
                                fontWeight: "500",
                                textDecoration: "underline",
                                cursor: "pointer",
                                alignItems: "center"
                            }}
                            >Reset Filter</span>
                            }
                        </Grid>

                        <Grid mt={{ xs: "15px", xl: "0px" }} item xs={12} lg={12} xl={4} >
                            <Grid alignItems={"center"} height={"100%"} container justifyContent={{ xs: "center", xl: "end" }}>
                                <Grid ml={{ xs: "unset", xl: "auto" }} item xs={12} sm={"auto"} >
                                    {isEmpty(colourCodes.holiday_package_search) !== "" && <Box mb={"0px"} sx={{ display: "flex", alignItems: "center" }}>
                                        <Box mb={{ xs: "10px", sm: 0 }} sx={{ background: colourCodes.holiday_package_search, width: "25px", height: "25px", marginRight: "5px" }}></Box> Searching
                                    </Box>}
                                </Grid>
                                <Grid item xs={12} sm={"auto"}>
                                    {isEmpty(colourCodes.holiday_detail) !== "" && <Box mb={{ sm: "0px", xs: "10px" }} mr={"5px"} ml={{ xs: "0", sm: "5px" }} sx={{ display: "flex", alignItems: "center" }}>
                                        <Box mr={"5px"} sx={{ background: colourCodes.holiday_detail, width: "25px", height: "25px", }}></Box> Hotel Details
                                    </Box>}
                                </Grid>
                                <Grid item xs={12} sm={"auto"}>
                                    {isEmpty(colourCodes.get_folder) !== "" && <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Box sx={{ background: colourCodes.get_folder, width: "25px", height: "25px", marginRight: "5px" }}></Box> Folder Details
                                    </Box>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <button style={{
                        display: "flex",
                        marginLeft: "auto",
                        marginTop: "10px"
                    }} className="btn btn-primary" onClick={handleClickOpen}>
                        Export
                    </button>

                    {logs.length > 0 ?
                        <Box sx={{
                            m: '20px auto',
                            '& .MuiTableCell-root': {
                                borderCollapse: "separate",
                                borderSpacing: 0,
                                // whiteSpace: 'nowrap',
                                padding: '12px',
                                lineHeight: 1.2,
                            },
                            '& .MuiTableCell-head': {
                                fontSize: { sm: "14px", xs: "14px" },
                                fontWeight: "bold",

                            }
                        }}>
                            <Box sx={{ "& a": { marginLeft: "auto" } }} display={"flex"} justifyContent={"end"}
                            >

                                {/* <CSVLink id="csv-download-link"
                                // style={{ display: 'none' }}
                                data={csvData}
                                filename={"logs.csv"}
                                className="btn btn-primary"
                                target="_blank"
                            >

                            </CSVLink> */}
                            </Box>

                            <Paper sx={{ width: '100%', overflowX: 'auto', marginTop: "15px" }}>
                                <Box className="refdiv" sx={{ overflow: 'auto', height: '16px', }} ref={wrapper1Ref} onScroll={handleWrapper2Scroll}>
                                    <Box sx={{ width: refWidth + 'px', height: '3px' }} ></Box>
                                </Box>
                                <TableContainer ref={wrapper2Ref} onScroll={handleWrapper1Scroll}>
                                    <Table ref={tableRef}>
                                        <TableHead>
                                            <TableRow >
                                                {column?.map((c: any, i: number) => {
                                                    return <TableCell
                                                        sx={{
                                                            backgroundColor: c.color,
                                                            height: "150px",
                                                            alignContent: "center",
                                                            textAlign: "center"
                                                        }}
                                                        key={i} align="right">
                                                        <Box sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            width: "100%",
                                                            height: "100%",
                                                            transform: "rotate(180deg)",
                                                            writingMode: "tb",
                                                            textAlign: "center !important",
                                                            color: !c.isFromCreateFolder && !c.isFromHolidayDetail ? colors.basics.black : colors.basics.white,
                                                            whiteSpace: c.noWrap ? 'nowrap !important' : 'unset !important',
                                                        }}>
                                                            {c?.label}
                                                        </Box>
                                                    </TableCell>
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {logs?.map((row: any, rowIndex: number) => {
                                                return <TableRow sx={{ background: rowIndex % 2 === 0 ? colors.basics.secondary : colors.basics.white }} key={rowIndex}>
                                                    {column?.map((c: any, columnIndex: number) => {
                                                        return (
                                                            c.isFromCreateFolder === true ?
                                                                row.createFolderLog.map((cf: any, CI: any) => {
                                                                    return (
                                                                        <React.Fragment key={CI}>
                                                                            {c.key === "roomType" ?
                                                                                mergeColumns(cf.roomType, c, true)
                                                                                : cell(cf, c, CI, false)}
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                                : c.isFromHolidayDetail === true ?
                                                                    <React.Fragment key={columnIndex}>
                                                                        {mergeColumns(row.holidayDetailLog, c, false)}
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment key={columnIndex}>
                                                                        {cell(row, c, columnIndex, false)}
                                                                    </React.Fragment>

                                                        )
                                                    }
                                                    )}
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box sx={{
                                    display: 'flex', justifyContent: 'center', p: '20px 0',
                                    '& button': {
                                        cursor: "pointer",
                                    },
                                    '& .MuiPaginationItem-root.Mui-selected': {
                                        background: '#ffd9d0',
                                        borderRadius: "0",
                                        transition: "0 !important",
                                    },
                                    '& .MuiPaginationItem-root:hover': {
                                        background: '#ffd9d0',
                                        borderRadius: "0",
                                        transition: "0 !important",
                                    },
                                    '& .MuiPaginationItem-root': {
                                        transition: "0 !important",
                                        borderRadius: "0 !important",
                                        minWidth: { sm: '32px', xs: window.innerWidth < 338 ? '24px' : '26px', },
                                        fontSize: { sm: '16px', xs: window.innerWidth < 338 ? '11px' : '12px', }
                                    },
                                    '& .MuiPaginationItem-root.Mui-selected:hover': {
                                        transition: "0 !important",
                                        borderRadius: "0",
                                        background: '#ffd9d0',
                                    }
                                }}>
                                    <Pagination
                                        size={window.innerWidth < 426 ? 'small' : 'medium'}
                                        variant="outlined"
                                        count={pageCount}
                                        page={page}
                                        onChange={handleChangePage}
                                        showFirstButton
                                        showLastButton
                                        defaultPage={page}
                                    />
                                </Box>

                            </Paper>
                        </Box > : (!loading && <Typography my={"120px"}
                            variant='h1' textAlign='center' color={colors.basics.primary} >
                            No Record Found
                        </Typography>)
                    }
                </Box>}
            <Box >
                <Dialog
                    open={open}
                    onClose={handleClose}
                    sx={{
                        '& .MuiDialog-paper': {
                            maxHeight: "550px !important",
                            maxWidth: windowWidth > 1023 ? '600px !important' : "fit-content !important",
                            width: "100%",
                            border: 'none',
                            borderRadius: 2,
                            margin: "10px"
                        }
                    }}
                // aria-labelledby="alert-dialog-title"
                // aria-describedby="alert-dialog-description"
                >
                    <Box display={'flex'} justifyContent={'right'} sx={{
                        'svg': {
                            width: "24px",
                            height: "auto",
                        },
                        '& .MuiButton-root': {
                            p: "0",
                            minWidth: "0"
                        },

                    }}>
                        {/* <Button >X</Button> */}
                        <Button variant='text' onClick={handleClose} > <CloseModal /> </Button>
                    </Box>
                    <Box sx={style}
                    // sx={{ m: '10px 0', }}
                    >
                        <Box mt={'10px'} sx={{
                            height: '48px',
                            width: '100%',
                            borderRadius: '5px',
                            border: `1px solid ${colors.grey[30]}`,
                            padding: '10px',
                            color: colors.grey[10],
                            pr: '37px',
                            fontFamily: 'Raleway',
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: "center",
                            background: colors.basics.white,
                            position: "relative",
                            marginBottom: "10px",
                            "&:before": {
                                content: "''",
                                position: "absolute",
                                width: "12px",
                                height: "12px",
                                backgroundColor: "white",
                                bottom: "-17px",
                                left: "25%",
                                transform: "translateX(-50%) rotate(45deg)",
                                boxShadow: "0 0 6px #8798ad"
                            },
                        }}>


                            <EventAvailableIcon sx={{ color: colors.basics.primary }} />
                            <span style={{ marginLeft: "10px" }}> {startDate} - {endDate}</span>

                        </Box>
                        <Box sx={{
                            width: "fit-content",
                            borderRadius: "5px", border: `1px solid ${colors.grey[30]}`, background: colors.basics.white, position: "relative", zIndex: "1"
                        }}>
                            <Box sx={{
                                position: "relative"
                            }}>
                                <Calendar
                                    // maxDate={new Date()}
                                    numberOfMonths={windowWidth > 1023 ? 2 : 1}
                                    value={values}
                                    onChange={(e: any) => {
                                        setValues(e)
                                    }}
                                    range

                                    format={'DD MMM YY'}
                                    weekDays={weekDays}
                                    months={months}
                                />
                            </Box>
                        </Box>
                        <button style={{ marginTop: "10px" }} onClick={() => customApplyClick(startDate, endDate)} className="btn btn-primary" >
                            Export
                        </button>
                        <CSVLink
                            style={{ display: "none" }}
                            id="csv-download-link"
                            data={csvData}
                            filename={"logs.csv"}
                            target="_blank"
                        >
                            <Button sx={{ maxWidth: '300px', ml: { sm: '16px', xs: "10px" }, mb: '20px' }}
                            > Export</Button>
                        </CSVLink>
                    </Box>



                </Dialog >
            </Box >
        </Box >
    )
}

export default Logs
