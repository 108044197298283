import { Box, Card, CardMedia, Chip, Rating, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/useAppReducer";
import StarIcon from "@mui/icons-material/Star";
import { CustomIconButton } from "./CustomIconButton";
import { addCommasToNumber } from "../helpers/commaSeparatedPrice";
import { clearPackageDetails } from "../features/holidayPackage/packageDetail";
import { colors } from "../styles/colors";
import { generateUniqueSessionId } from "../helpers/randomSessionId";
import platinumLogo from "../assets/images/platinum-logo.png";
import { roundPrice } from "../helpers/RoundPrice";
import { setDialogState } from "../features/dialog/dialogSlice";
import { setFlightRoomState } from "../features/flightRoom/flightRoomSlice";
import { setStepperState } from "../features/stepper/stepperSlice";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../helpers/isEmpty";
import { addSpace } from "../helpers/addSpace";
import Image from "./Image";
import { getSessionStorage, removeSessionStorage, setSessionStorage } from "../helpers/storage";
import { ApplicationStateType } from "../features/store";
import { sortRoom } from "../helpers/sortRoom";
import { RoomMealWrapper } from "../helpers/roomMealWrapper";
import { capitalizeFirstLetterEachWords } from "../helpers/capitalizeFirstLetterEachWords";
import { calculatePerPersonPrice } from "../helpers/calculatePerPersonPrice";
import { calculateTotalPackagePrice } from "../helpers/calculateTotalPackagePrice";
import { isDiscountValidDate } from "../helpers/isDiscountValidDate";
import { formatPrice } from "../helpers/formatPrice";

interface PackageCardProps {
  item?: any;
  psw_result_id: number;
  psw_result_id_combo: number;
  elementRef?: any;
}

//! States
export const PackageCard = ({
  item,
  psw_result_id,
  psw_result_id_combo,
  elementRef,
}: PackageCardProps) => {
  const { dialogState } = useAppSelector((state) => state.dialog);
  const { stepper } = useAppSelector((state) => state.stepper);
  const { flightRoom } = useAppSelector((state) => state.flightRoom);
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  const { filters } = useAppSelector((state) => state.filters);
  const { holidayPackage } = useAppSelector((state: ApplicationStateType) => state.holidayPackage);
  const {
    flightRoom: {
      searchCriteria: { guest, adults, childrens },
    },
  } = useAppSelector((state) => state.flightRoom);

  /**
   * @Variables
   */
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const data: any = JSON.parse(JSON.stringify(item?.SearchResult));
  const isEqual = data?.hotel_id === flightRoom?.hotel_id;
  let { hotel_rating, platinumHotel, hotel_classification } = item.HotelInfo;
  const { setSession_id } = useAuth();
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL

  let rating =
    hotel_classification === "2"
      ? Number(hotel_rating) + 0.5
      : Number(hotel_rating);
  //* Guests count excluding infants
  const totalGuests = Number(
    flightRoom.searchCriteria.adults + flightRoom.searchCriteria.childrens
  );

  //! Functions

  useEffect(() => {
    sortRoom(data, filters);
  }, [filters?.holidayType, data]);

  const flightPrice: any = useMemo(() => {
    let price: any = 0
    const flightPrice = getSessionStorage("stepper") === "0" ? "0" : flightRoom.diffPrice || "0"

    if (getSessionStorage("nextAndSelectedFlights") === "false") {
      price = Math.ceil(flightPrice)
    } else {
      if (flightPrice.includes("-")) {
        price = Math.floor(flightPrice);
      } else {
        price = Math.ceil(flightPrice)
      }
    }
    return price
  }, [flightRoom.diffPrice, getSessionStorage("nextAndSelectedFlights")])

  const packagePrice = useMemo(() => {
    let price = 0;
    if (stepper === 0) {
      if (sortRoom(data, filters).length > 0) {
        sortRoom(data, filters).map((f: any) => {
          price = price + Number(f.total_package);
          return <></>;
        });
      }
    } else {
      if (data.hotel_id !== flightRoom.hotel_id) {
        if (sortRoom(data, filters).length > 0) {
          sortRoom(data, filters).map((f: any) => {
            price = price + Number(f.total_package);
            return <></>;
          });
        }
      } else {
        if (flightRoom.multiRooms.length > 0) {
          flightRoom.multiRooms.map((f: any) => {
            price = price + Number(f.total_package);
            return <></>;
          });
        }
      }
    }

    const addedFlightPrice = getSessionStorage("stepper") === "0" ? 0 : flightPrice
    const totalPrice = price / totalGuests;
    // return stepper === 0 ? roundPrice(String(totalPrice)) : data.hotel_id !== flightRoom.hotel_id ? roundPrice(String(totalPrice)) : roundPrice(String(totalPrice + addedFlightPrice))
    const unroundedPrice =
      stepper === 0
        ? totalPrice
        : data.hotel_id !== flightRoom.hotel_id
          ? totalPrice
          : totalPrice + addedFlightPrice;

    const roundedPrice = roundPrice(String(unroundedPrice));

    return {
      roundedPrice,
      unroundedPrice,
    };
    // eslint-disable-next-line
  }, [
    sortRoom(data, filters),
    flightRoom.multiRooms,
    flightRoom.hotel_id,
    data,
    totalGuests,
    isEqual,
    stepper,
    flightPrice
  ]);
  const { roundedPrice, unroundedPrice } = packagePrice;

  const getRoomIds = (arr: any) => {
    const roomsArr: any = arr.map((e: any) => e.id);
    let passengerDetails: any = {};

    let totalPassenger = [...Array(adults + childrens)].map((value, index) =>
      (index + 1).toString()
    );

    roomsArr.map((r: any, i: any) => {
      const totalLength = guest[i].adults + guest[i].children.length;

      const totalPassengerPerRoom = [];

      for (let i = 0; i < totalLength; i++) {
        totalPassengerPerRoom.push(totalPassenger[i]);
      }

      totalPassenger.splice(0, totalLength);
      return (passengerDetails[r] = totalPassengerPerRoom.join(","));
    });
    return roomsArr.toString();
  };

  const queryData = useMemo(() => {
    const search = window.location.search
    const urlSearchParams = new URLSearchParams(search);
    const queryData: any = {};
    urlSearchParams.forEach((value, key) => {
      queryData[key] = value;
    });
    return queryData
  }, [])
  const handleMoreDetails = () => {
    dispatch(setStepperState(1));
    dispatch(setDialogState({ ...dialogState, mapDialog: false }));
    dispatch(clearPackageDetails());
    const multiRoomIds = getRoomIds(sortRoom(data, filters));

    if (stepper === 0) {
      removeSessionStorage("basketId")
      setSessionStorage("previous", "false");
      setSessionStorage("nextButtonClicked", "false");
      setSessionStorage("nextAndSelectedFlights", "false");
      setSessionStorage("newHotelClick", "null")
      removeSessionStorage("OutboundFlights")
      removeSessionStorage("InboundFlights")
    } else {
      setSessionStorage("newHotelClick", "true")
      if (data.hotel_id !== flightRoom.hotel_id) {
        removeSessionStorage("basketId")
        setSessionStorage("previous", "false");
        setSessionStorage("nextAndSelectedFlights", "false");
        setSessionStorage("nextButtonClicked", "false");
      } else {
        setSessionStorage("nextButtonClicked", "true");
        setSessionStorage("nextAndSelectedFlights", "true");
        setSessionStorage("previous", "true")
      }
    }

    setSessionStorage("stepper", stepper.toString())
    setSessionStorage("more-details-click", "true");

    dispatch(
      setFlightRoomState({
        ...flightRoom,
        callHolidaySummaryApi: (stepper === 0 || (data.hotel_id !== flightRoom.hotel_id)) ? true : false,
        confirmSelection: (stepper === 0 || (data.hotel_id !== flightRoom.hotel_id)) ? {
          flights: false,
          room: false,
        } : flightRoom.confirmSelection,
        selectedFlights:
          stepper === 0
            ? { inbound: [], outbound: [] }
            : data.hotel_id !== flightRoom.hotel_id ? { inbound: [], outbound: [] } : flightRoom.selectedFlights,
        psw_result_id: stepper === 0 ? psw_result_id : flightRoom.store_psw_result_id,
        psw_result_id_combo: stepper === 0 ? psw_result_id_combo : flightRoom.psw_result_id_combo,
        store_psw_result_id: stepper === 0 ? psw_result_id : flightRoom.store_psw_result_id,
        psc_request_id: stepper === 0 ? holidayPackage.flightSearchCriteriaId : flightRoom.psc_request_id,
        roomids: (stepper === 0 || (data.hotel_id !== flightRoom.hotel_id)) ? multiRoomIds : flightRoom.roomids,
        hotel_id: data.hotel_id,
        diffPrice: (stepper === 0 || (data.hotel_id !== flightRoom.hotel_id)) ? '0' : flightRoom.diffPrice,
        holidayDetailLogId: (stepper === 0 || (data.hotel_id !== flightRoom.hotel_id)) ? "0" : flightRoom.holidayDetailLogId,
        selectHotelId: data.id,
        searchCriteriaId: data.searchCriteriaId,
        search_result_id: data.id,
        multiRooms: (stepper === 0 || (data.hotel_id !== flightRoom.hotel_id)) ? sortRoom(data, filters) : flightRoom.multiRooms,
        storeMultiRoom: (stepper === 0 || (data.hotel_id !== flightRoom.hotel_id)) ? sortRoom(data, filters) : flightRoom.storeMultiRoom,
        cheapestFlightPSW:
          holidayPackage?.FlightDetails?.outbound?.flights[0]?.psw_result_id,
        initialPackagePrice: packagePrice,
        roomData: null,
      })
    );

    const session_id = generateUniqueSessionId();
    setSession_id(session_id);
    dispatch(setDialogState({ ...dialogState, hotelInfoDialog: false }))
    navigate(`/holiday-summary/${session_id}${window.location.search}`);
  };

  const formatRoomType = (inputString: any) => {
    // Replace '&amp;' with '&'
    const formattedString = inputString?.replace('&amp;', '&');
    // Capitalize the first letter of each word
    const words = formattedString?.toLowerCase().split(' ');
    const capitalizedWords = words?.map((word: any) => word?.charAt(0).toUpperCase() + word?.slice(1));
    return capitalizedWords?.join(' ');
  };

  return (
    <>
      <Card
        sx={{
          borderRadius: 0,
          boxShadow: "none",
          display: { xs: "block", lg: "flex" },
          width: "100%",
        }}
      >
        <Box sx={{
          position: "relative",
          overflow: "hidden"
        }}>
          <CardMedia
            className="package-card-img"
            component="img"
            image={
              data?.image_name ||
              "https://clients.cylindo.com/viewer/3.x/v3.0/documentation/img/not_found.gif"
            }
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.onerror = null;
              target.src =
                "https://clients.cylindo.com/viewer/3.x/v3.0/documentation/img/not_found.gif";
            }}
            alt={data?.hotel_name}
            onClick={() => {
              handleMoreDetails()
            }}
            sx={{
              // width: { xs: "100%", lg: "320px" },
              // height: { xs: "100%", lg: "245px" },
              width: "100%",
              // height: "100%",
              height: "auto",
              objectFit: "contain"
            }}
          />
        </Box>
        <Box ml={{ xs: 0, lg: "19px" }} flex={1}>
          <Box display={"flex"} flexDirection={{ xs: "column", sm: "row" }} justifyContent="space-between">
            <Box order={{ xs: 2, sm: 1 }} mt={{ lg: 0, xs: "10px" }}>
              <Box>
                <Typography
                  sx={{
                    cursor: "pointer",
                    textTransform: "capitalize",
                    '&:hover': {
                      textDecoration: "underline",
                      color: colors.basics.primary,
                      textTransform: "capitalize"
                    },
                  }}
                  onClick={() => handleMoreDetails()}
                  lineHeight={"1.2"}
                  variant="h1"
                  fontSize={{ sm: "26px", xs: "22px" }}
                  dangerouslySetInnerHTML={{ __html: capitalizeFirstLetterEachWords(isEmpty(data?.hotel_name)) }}
                />
              </Box>

              {isEmpty(item.HotelInfo.resort_name) !== "" && <Box mt={"5px"}>
                <Typography
                  variant="body2"
                  sx={{ fontFamily: "El Messiri", fontSize: "16px !important" }}
                  color={colors.basics.black}
                  lineHeight={"1.2"}
                >
                  {capitalizeFirstLetterEachWords(isEmpty(item?.HotelInfo?.resort_name?.replace(/&amp;/g, '&'))) + "     "}
                  {/* {data.hotel_id} */}
                </Typography>
              </Box>}
            </Box>
            <Box
              display={{ xs: "flex", sm: "block" }}
              alignItems={"center"}
              order={{ xs: 1, sm: 2 }}
              sx={{
                textAlign: { xs: "left", sm: "center" },
                mt: { lg: 0, xs: "15px" },
                "& .MuiRating-root .MuiRating-iconFilled": {
                  color: Number(platinumHotel) !== 0 ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary,
                },
                "& .MuiRating-iconHover": {
                  color: colors.basics.primary,
                },
              }}
            >
              <Rating
                sx={{ fontSize: "18px" }}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55, }} fontSize="inherit" />
                }
                value={rating}
                precision={hotel_classification === "2" ? 0.5 : 1}
                readOnly={true}
              />
              <Box ml={{ xs: "auto", sm: 0 }} hidden={Number(platinumHotel) === 0}>
                <Image
                  src={platinumLogo}
                  alt="logo"
                  // height="40px"
                  // width="100%"
                  style={{ width: "130px", height: "40px" }}
                />
              </Box>
            </Box>
          </Box>

          {/* <Box>
            {data?.offerDetails &&
              data?.offerDetails?.length > 0 &&
              data?.offerDetails?.map((d: any, i: number) => {
                return (
                  isEmpty(d?.subTitle) !== "" ? <Box
                    key={i}
                    className="package-card-chip item-star"
                    mr={1}
                    mt="5px"
                    bgcolor={Number(platinumHotel) !== 0
                      ? colors.basics.plataniumGreenColor + "!important" : colors.basics.primary}
                    sx={{
                      "& .package-card-chip": {
                        background: Number(platinumHotel) !== 0
                          ? colors.basics.plataniumGreenColor + "!important" : colors.basics.primary
                      },
                      "& .MuiChip-root": {
                        background: Number(platinumHotel) !== 0
                          ? colors.basics.plataniumGreenColor + "!important" : colors.basics.primary
                      }
                    }}
                  >

                    <Chip
                      label={
                        <Typography
                          variant="subtitle1"
                          // textTransform='uppercase'
                          color={colors.basics.white}
                          fontSize="12px"
                          fontWeight={500}
                          lineHeight={"1.2"}
                        // sx={{ textTransform: "capitalize" }}
                        >
                          {addSpace(d?.subTitle)}
                        </Typography>
                      }
                      sx={{
                        background: colors.basics.primary,
                        height: "18px",
                        "& .MuiChip-label": { p: 0 },
                      }}
                    />
                  </Box> : <React.Fragment key={i}></React.Fragment>
                )
              })}
          </Box> */}
          <Box>
            {data?.offerDetails &&
              data?.offerDetails?.length > 0 &&
              (() => {
                const uniqueSubTitles = new Set<string>(); // To track unique subtitles
                return data?.offerDetails.map((d: any, i: number) => {
                  if (d?.subTitle && !uniqueSubTitles.has(d?.subTitle)) {
                    uniqueSubTitles.add(d.subTitle); // Add to the set
                    return (
                      <Box
                        key={i}
                        className="package-card-chip item-star"
                        mr={1}
                        mt="5px"
                        bgcolor={
                          Number(platinumHotel) !== 0
                            ? colors.basics.plataniumGreenColor + "!important"
                            : colors.basics.primary
                        }
                        sx={{
                          "& .package-card-chip": {
                            background:
                              Number(platinumHotel) !== 0
                                ? colors.basics.plataniumGreenColor + "!important"
                                : colors.basics.primary,
                          },
                          "& .MuiChip-root": {
                            background:
                              Number(platinumHotel) !== 0
                                ? colors.basics.plataniumGreenColor + "!important"
                                : colors.basics.primary,
                          },
                        }}
                      >
                        <Chip
                          label={
                            <Typography
                              variant="subtitle1"
                              color={colors.basics.white}
                              fontSize="12px"
                              fontWeight={500}
                              lineHeight={"1.2"}
                            >
                              {addSpace(d?.subTitle)}
                            </Typography>
                          }
                          sx={{
                            background: colors.basics.primary,
                            height: "21px",
                            "& .MuiChip-label": { p: 0 },
                          }}
                        />
                      </Box>
                    );
                  }
                  return <React.Fragment key={i}></React.Fragment>; // Render nothing for duplicates
                });
              })()}
          </Box>
          <Box
            mt="15px"
            sx={{
              "& p": {
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: { lg: 16, xs: 14 },
                lineHeight: "140%",
                color: colors.basics.black,
              },
              fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: { lg: 16, xs: 14 },
              lineHeight: "140%",
              color: colors.basics.black,
            }}
            dangerouslySetInnerHTML={{ __html: data?.quickDescription }}
          />

          <Box
            mt="18px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="subtitle2"
                  color={colors.basics.black}
                  fontSize="12px"
                >
                  {sortRoom(data, filters)[0]?.days_spent} Nights{" "}
                </Typography>

                <Typography
                  dangerouslySetInnerHTML={{ __html: RoomMealWrapper(formatRoomType(sortRoom(data, filters)[0]?.meal_name)) }} variant="subtitle2"
                  color={colors.basics.black}
                  fontSize="12px" sx={{ ml: '3px' }}>

                </Typography>
              </Box>
              {/* <Typography
                variant="subtitle2"
                color={colors.basics.black}
                fontSize="12px"
              >
                From
              </Typography> */}
            </Box>
          </Box>
          <Box display="flex" alignItems="flex-end">
            <Box width="100%">
              <Box width="100%" display="flex">
                <Typography sx={{
                  fontFamily: "El Messiri",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: { sm: '26px !important', xs: '24px !important' },
                  lineHeight: "31px",
                }}>
                  {/* {signForPricing + addCommasToNumber(Number(packagePrice))} */}
                  {flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountUK && process.env.REACT_APP_PROJECT_COUNTRY === "uk" && isDiscountValidDate()
                    ? `${signForPricing}${addCommasToNumber(formatPrice(String(calculatePerPersonPrice(roundPrice(unroundedPrice), totalGuests))))}`
                    : flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountIE && process.env.REACT_APP_PROJECT_COUNTRY === "ie" && isDiscountValidDate() ?
                      `${signForPricing}${addCommasToNumber(formatPrice(String(calculatePerPersonPrice(roundPrice(unroundedPrice), totalGuests))))}` :
                      `${signForPricing}${addCommasToNumber(Number(roundedPrice))}`}
                </Typography>
                <Typography
                  mx={0}
                  sx={{
                    fontFamily: "El Messiri",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: { sm: '20px !important', xs: '18px !important' },
                    lineHeight: "31px",
                  }}
                // variant="h1"
                // fontWeight={600}
                >
                  &nbsp;pp
                </Typography>
              </Box>
              <Typography variant="subtitle2" fontSize="12px">
                Including Flights{" "}
                {/* {data && Object.keys(data)?.includes("transfer")
                  ? "& Transfers"
                  : null} */}
              </Typography>
            </Box>
            <Box display={{ sm: "flex", xs: "none" }}
              className="button-more"
              sx={{
                "& .itemCardMoreDetail": {
                  p: "9px",
                },
                "& .MuiButtonBase-root": {
                  height: "100% !important"
                },
                "& .button-background": {
                  background: Number(platinumHotel) !== 0 ? colors.basics.plataniumGreenColor : colors.basics.primary
                }
              }}
            >
              <CustomIconButton
                fullClassName="btn-olb-view-hotel"
                label="Choose & Continue"
                onClick={handleMoreDetails}
                IconClassName="button-background"
                className="itemCardMoreDetail"
              />
            </Box>
          </Box>
          <Box display={{ sm: "none", xs: "flex" }}
            className="button-more"
            sx={{
              justifyContent: "flex-end",
              width: "100%",
              "& .itemCardMoreDetail": {
                p: "9px",
              },
              "& .MuiButtonBase-root": {
                height: "100% !important",
                maxWidth: "159px !important",
                // width: "100% !important"
                width: '100%',
              },
              "& .button-background": {
                background: Number(platinumHotel) !== 0 ? colors.basics.plataniumGreenColor : colors.basics.primary
              }
            }}
          >
            <CustomIconButton
              fullClassName="btn-olb-view-hotel"
              label="Choose & Continue"
              onClick={handleMoreDetails}
              IconClassName="button-background"
              className="itemCardMoreDetail"
            />
          </Box>
        </Box>
      </Card >
      <Box
        border={`1px solid ${colors.grey[30]}`}
        width="100%"
        mb="25px"
        mt="28px"
        className="bottom-border"
      />
    </>
  );
};
