export function calculateTotalPackagePrice(packagePrice: number, numberOfGuests: number) {
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL
 
  if (numberOfGuests <= 0) {
    throw new Error('Number of guests must be greater than 0.');
  }
  // Calculate the total package price
  const totalPrice = packagePrice;

  let discount = 0;
  let discountMessage = '';

  // Determine discount and message based on total price

  if(process.env.REACT_APP_PROJECT_COUNTRY === "uk"){
    if (totalPrice >= 6000) {
      discount = 300;
      discountMessage = `‘Special Offer ${signForPricing}300 off’`;
    } else if (totalPrice >= 5000) {
      discount = 250;
      discountMessage = `‘Special Offer ${signForPricing}250 off’`;
    } else if (totalPrice >= 4000) {
      discount = 200;
      discountMessage = `‘Special Offer ${signForPricing}200 off’`;
    }
    //  else if (totalPrice >= 3000) {
    //   discount = 150;
    //   discountMessage = `‘Special Offer ${signForPricing}150 off’`;
    // } 
    else {
      discountMessage = "No discounts are available for this itinerary.";
    }}
    else if(`${process.env.REACT_APP_PROJECT_COUNTRY}` === "ie"){
      discount = 100;
      discountMessage = `‘Special Offer ${signForPricing}100 off’`;
    }
  

  const finalPriceDiscount = totalPrice - discount;

  return {
    finalPriceDiscount,
    discountMessage,
  };
}
