import { Box, Button, Drawer, Grid, Typography } from "@mui/material";
import { colors } from "../../../styles/colors";
import { CarHireComponent } from "./CarHireComponent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../hooks/useAppReducer";
import { useDispatch } from "react-redux";
import {
  fetchCarCities,
  getCarHireAvailability,
  getCarHireAvailabilityResult,
} from "../../../features/carHire/carHireSlice";
import { ApplicationStateType } from "../../../features/store";
import { CustomContainer } from "../../../components/CustomContainer";
import { PricingCard } from "../../../components/PricingCard";
import { HotelDetails } from "../../HolidaySummary/Components/HotelDetails";
import { useNavigate, useParams } from "react-router-dom";
import { StepperCard } from "../../../components/StepperComponent";
import { AttractionComponent } from "../../HolidaySummary/Components/AttractionComponent";
import { retrieveBasket } from "../../../features/actions";
import { setLottieLoading } from "../../../features/lottieLoader/lottieLoader";
import {
  clearSessionValues,
  getSessionStorage,
  removeSessionStorage,
  setSessionStorage,
} from "../../../helpers/storage";
import { setFlightRoomState } from "../../../features/flightRoom/flightRoomSlice";
import { setHolidayDetailsState } from "../../../features/holidaySummary/holidaySummarySlice";
import {
  fetchAsyncHolidayPackages,
  setFilteredHolidayPackage,
  setHolidayPackage,
} from "../../../features/holidayPackage/holidayPackageSlice";
import { setLoading } from "../../../features/loading/loadingSlice";
import { fetchAsyncHolidayPackageDetails } from "../../../features/holidayPackage/packageDetail";
import { directFlights, directFlightsIE } from "../../../constants/directFlights";
import { isEmpty } from "../../../helpers/isEmpty";
import { removeSessionElements } from "../../../common/removeSessionElements";
import { setFilterState } from "../../../features/filter/filterSlice";
import dayjs from "dayjs";
import { capitalizeFirstLetterEachWords } from "../../../helpers/capitalizeFirstLetterEachWords";
import { DestinationList } from "../../../constants/Destinations";
import { DateObject } from "react-multi-date-picker";
import { IE_DEPARTURE, UK_DEPARTURE, US_DEPARTURE } from "../../../constants/departure";
import { sortRoom } from "../../../helpers/sortRoom";
import { formatTime } from "../../../helpers/formatTime";
import { flightTime, getFlightTimesObjectHolidayPackage } from "../../../helpers/TotalFlightTime";
import { API } from "../../../api/services";
import { API_METHOD } from "../../../api/services/apiMethods";
import { CarList } from "./CarList";
import { setStepperState } from "../../../features/stepper/stepperSlice";
import Loader from "../../../components/Loader";
import { CarTopSection } from "./CarTopSection";
interface carInterface {
  showButtons?: any;
  showButtonsAndFirstComponent?: any;
}

interface CABIN_CLASS_ENUM_TYPE {
  Any: string;
  Economy: string;
  Premium: string;
  "Business Class": string;
  "First Class": string;
}

const CABIN_CLASS_ENUM: CABIN_CLASS_ENUM_TYPE = {
  Any: "",
  Economy: "M",
  Premium: "W",
  "Business Class": "C",
  "First Class": "F",
};

type CabinClass = keyof typeof CABIN_CLASS_ENUM;

export const CarComponent = ({ showButtons, showButtonsAndFirstComponent }: carInterface) => {
  const { session_id }: any = useParams();
  const [basketId, setBasketId] = useState(getSessionStorage("basketId"));
  const [isDataLoaded, setIsDataLoaded] = useState(true);
  const { loading } = useAppSelector((state: any) => state.loading);
  const [openFlightDrawer, setOpenFlightDrawer] = useState(false);
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL;
  const dispatch = useDispatch();
  const { holidayPackage } = useAppSelector((state) => state.holidayPackage);
  const { filters } = useAppSelector((state: any) => state.filters);
  const { selectedCity, availability, availabilityLoading, carQty, driverAge } = useAppSelector(
    (state) => state.carHire
  );
  const { flightRoom } = useAppSelector((state: ApplicationStateType) => state.flightRoom);
  const {
    flightRoom: {
      searchCriteria: { adults, childrens },
    },
  } = useAppSelector((state: any) => state.flightRoom);
  const { hotelDetails } = useAppSelector((state) => state.hotelDetails);
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  const stepperState = useAppSelector((state: { stepper: { stepper: number } }) => state.stepper.stepper);
  const isPlataniumHotel = hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel
    ? Number(hotelDetails.HotelInfo.HotelInfo.platinumHotel) !== 0
    : false;
  const navigate = useNavigate();

  useEffect(() => {
    if (getSessionStorage("basketId")) {
      setBasketId(getSessionStorage("basketId"));
    } else if (window.location.search.includes("?email")) {
      setBasketId(window.location.search.replace("?email=", ""));
    } else {
      setBasketId(null);
    }
    stepperState !== 2 && dispatch(setStepperState(2));
  }, [getSessionStorage("basketId"), window.location.search]);

  const toggleFlightDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setOpenFlightDrawer(open);
  };

  const newSearch = async () => {
    await setBasketId(null);
    await clearSessionValues();
    await removeSessionStorage("basketId");
    await removeSessionStorage("search");
    await dispatch(setHolidayPackage({}));
    await dispatch(setFilteredHolidayPackage({}));
    await dispatch(
      setFilterState({
        holidayType: [],
        starRating: [],
        hotelName: "",
        price: [],
        sorting: "Popularity",
        regionType: [],
      })
    );
    await dispatch(
      setFlightRoomState({
        ...flightRoom,
        psw_result_id: null,
        searchCriteria: {
          departure: {
            depart_airport_name: "London All Airports",
            departure_airport: "LON",
            full_name: "London All Airports LON",
          },
          destination: null,
          // isAdditionalTransferAmountAdd: false,
          date: [dayjs().add(7, "days").toISOString(), dayjs().add(14, "days").toISOString()],
          child_age: {},
          guest: [
            {
              room: 1,
              adults: 2,
              children: [],
              infants: [],
            },
          ],
          class: "Economy",
          night: dayjs().add(14, "days").diff(dayjs().add(7, "days"), "day"),
          adults: 2,
          childrens: 0,
          infants: 0,
        },
      })
    );
    await setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  const saveBasket = useCallback(
    async (state?: any, notToCallLoader?: boolean) => {
      let flightObj: any = {};
      if (state) {
        flightObj = {
          ...state?.updatedFlightRoomState,
          roomData: null,
        };
      } else {
        flightObj = {
          ...flightRoom,
          roomData: null,
        };
      }

      const body = [
        {
          sessionId: session_id,
          agent_id: "1",
          agency_user_id: "396379",
          basket: JSON.stringify({
            branch: process.env.REACT_APP_PROJECT_COUNTRY?.toUpperCase(),
            cart: {
              flights: {
                [flightObj?.selectedFlights?.outbound[0]?.FlightPswFlight?.psw_result_id]:
                  state?.updatedFlightRoomState
                    ? JSON.stringify(state?.updatedFlightRoomState)
                    : JSON.stringify(flightObj),
              },
              holiday: state?.yourHolidaySummary
                ? JSON.stringify(state?.yourHolidaySummary)
                : JSON.stringify(holiday),
            },
            sessionId: session_id,
            current_stage: 0,
            last_stage: null,
          }),
          folder_no: -1,
        },
      ];

      try {
        // eslint-disable-next-line
        const response = await API.post(API_METHOD.Basket.SAVE_BASKET_TO_DB, body);
        setSessionStorage("basketId", response.data.data.id);
        setBasketId(response.data.data.id);

        if (notToCallLoader !== true) {
          // dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
      }
    },
    [flightRoom, holiday, session_id, dispatch]
  );

  const handleAddCarAmountToFlight = (carElement: any, selectedCar?: any) => {
    const updated = dispatch(
      setFlightRoomState({
        ...flightRoom,
        selectedCarHired: { ...carElement, selectedCarPrice: selectedCar },
      })
    ).payload;

    const newData = { ...updated };
    saveBasket?.({
      updatedFlightRoomState: newData,
      yourHolidaySummary: null,
    });
  };

  const queryData = useMemo(() => {
    const search = window.location.search;
    const urlSearchParams = new URLSearchParams(search);
    const queryData: any = {};
    urlSearchParams.forEach((value, key) => {
      queryData[key] = value;
    });
    return queryData;
  }, []);

  useEffect(() => {
    const search = window.location.search;
    if (
      (search.includes("departure") || search.includes("destination")) &&
      getSessionStorage("holidayRetrieveApiCall") === "false" &&
      (queryData.hotelId || queryData.hotel_id)
    ) {
      setSessionStorage("holidayRetrieveApiCall", "true");
      if (Object.keys(holidayPackage).length === 0) {
        dispatch(setLottieLoading(true));
        setIsDataLoaded(false);
        removeSessionElements();
        renderOnLoadHolidayPackageApi(queryData);
      }
    }
  }, [queryData]);

  useEffect(() => {
    if (!!flightRoom?.selectedFlights?.inbound.length) {
      dispatch(
        fetchCarCities([
          flightRoom?.selectedFlights?.inbound[0].FlightPswFlight.Airport.depart_airport_name || "",
          true,
          false,
        ])
      );
    }
  }, [dispatch, flightRoom?.selectedFlights?.inbound]);

  useEffect(() => {
    if (selectedCity?.label) {
      // dispatch(setLoading(true))
      dispatch(
        getCarHireAvailability([
          {
            p_loc: selectedCity?.label ?? "",
            p_loc_id: selectedCity?.id ?? "",
            p_loc_cc: selectedCity?.cc ?? "",
            p_dt: flightRoom?.searchCriteria.date[0],
            p_tm: "08:00",
            d_loc: selectedCity?.label ?? "",
            d_loc_id: selectedCity?.id ?? "",
            d_loc_cc: selectedCity?.cc ?? "",
            d_dt: flightRoom?.searchCriteria.date[1],
            d_tm: "16:00",
            age: driverAge || "25+",
            is_agent: 1,
          },
        ])
      );
    }
  }, [selectedCity, driverAge]);

  useEffect(() => {
    if (availability) {
      dispatch(getCarHireAvailabilityResult([{ searchCriteriaId: availability?.searchCriteriaId ?? "" }]));
    }
  }, [availability]);

  useEffect(() => {
    if (getSessionStorage("holidayPageLoad") === "false" || getSessionStorage("holidayPageLoad") === null) {
      dispatch(
        setFlightRoomState({
          ...flightRoom,
          callHolidaySummaryApi: true,
        })
      );
    } else {
      dispatch(
        setFlightRoomState({
          ...flightRoom,
          callHolidaySummaryApi: false,
        })
      );
    }

    if (Object.keys(holidayPackage).length === 0) {
      setIsDataLoaded(false);
    }
  }, []);

  useEffect(() => {
    const hotel_id = queryData.hotelId || queryData.hotel_id || null;
    if (
      basketId &&
      flightRoom.psw_result_id === null &&
      getSessionStorage("more-details-click") === "false" &&
      !hotel_id
    ) {
      dispatch(setLottieLoading(true));
      setIsDataLoaded(false);
      retrieveDB();
    }
  }, [basketId, getSessionStorage("more-details-click"), flightRoom.psw_result_id]);

  const setHotelData = (holidayPackage: any, flightRoom: any) => {
    const outBoundFlights = holidayPackage?.FlightDetails?.outbound.flights;
    const inboundFlights = holidayPackage?.FlightDetails?.inbound.flights;
    let obj = {
      ...holiday,
      hotel_name: flightRoom.hotel_name,
      hotel_id: flightRoom.hotel_id,
      hotel: `${flightRoom?.multiRooms[0]?.days_spent} nights at ${flightRoom.hotel_name}`,
      packageDate: {
        checkin: `${dayjs(flightRoom?.multiRooms[0]?.checkInDate).format("DD MMM, YYYY")}`,
        checkout: `${dayjs(flightRoom?.multiRooms[0]?.checkOutDate).format("DD MMM, YYYY")}`,
      },
      selectedFlight: flightRoom?.selectedFlights,
      guest: flightRoom.searchCriteria.guest,
      room: flightRoom?.multiRooms,
      transfer: "",
      outboundTime: getFlightTimesObjectHolidayPackage(outBoundFlights)[0],
      inboundTime: getFlightTimesObjectHolidayPackage(inboundFlights)[0],
    };

    const outboundLayover: any = [];
    const inboundLayover: any = [];

    if (isEmpty(outBoundFlights) !== "" && isEmpty(inboundFlights) !== "") {
      obj.baggage = inboundFlights[0].Baggage.includes("kg")
        ? inboundFlights[0].Baggage
        : inboundFlights[0].Baggage.replace("K", "kg");
      outBoundFlights.length > 0 &&
        outBoundFlights.map((item: any, index: number) => {
          return index !== outBoundFlights.length - 1
            ? outboundLayover.push({
              time: flightTime(
                new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
                new Date(
                  `${outBoundFlights[index + 1]?.departure_date} ${formatTime(
                    outBoundFlights[index + 1]?.departure_time
                  )}`
                )
              ),
              code: item?.arrival_airport,
              layover_airport_name: item?.arrive_airport_name,
              layover_airport_city: item?.arrive_airport_city,
            })
            : outboundLayover.push(null);
        });
      inboundFlights.length > 0 &&
        inboundFlights.map((item: any, index: number) => {
          return index !== inboundFlights.length - 1
            ? inboundLayover.push({
              time: flightTime(
                new Date(`${item?.arrival_date} ${formatTime(item?.arrival_time)}`),
                new Date(
                  `${inboundFlights[index + 1]?.departure_date} ${formatTime(
                    inboundFlights[index + 1]?.departure_time
                  )}`
                )
              ),

              code: item?.arrival_airport,
              layover_airport_name: item?.arrive_airport_name,
              layover_airport_city: item?.arrive_airport_city,
            })
            : inboundLayover.push(null);
        });
      obj.flight = {
        inbound: [
          {
            airline_code: inboundFlights[0]?.airline_code,
            airline_name: inboundFlights[0]?.airline_name,
            inbound_date: `${dayjs(inboundFlights[0]?.departure_date).format("DD MMM, YYYY")}`,
            dep_airport: `${inboundFlights[0]?.depart_airport_name
              } (${inboundFlights[0]?.departure_airport?.toUpperCase()})`,
            des_airport: `${inboundFlights[inboundFlights?.length - 1]?.arrive_airport_name
              } (${inboundFlights[inboundFlights?.length - 1]?.arrival_airport?.toUpperCase()})`,
            layover: inboundLayover,
            arrival_time:
              inboundFlights.length > 0
                ? formatTime(inboundFlights[inboundFlights.length - 1]?.arrival_time)
                : "",
            stop: `${inboundFlights?.length - 1 !== 0 ? inboundFlights?.length - 1 + " stops" : "Direct"}`,
            flightDeparture:
              isEmpty(inboundFlights[0]?.departure_time) !== ""
                ? formatTime(inboundFlights[0]?.departure_time)
                : "",
          },
        ],
        outbound: [
          {
            airline_code: outBoundFlights[0]?.airline_code,
            airline_name: outBoundFlights[0]?.airline_name,
            outbound_date: `${dayjs(outBoundFlights[0]?.departure_date).format("DD MMM, YYYY")}`,
            dep_airport: `${outBoundFlights[0]?.depart_airport_name
              } (${outBoundFlights[0]?.departure_airport?.toUpperCase()})`,
            des_airport:
              outBoundFlights.length > 0
                ? `${outBoundFlights[outBoundFlights?.length - 1]?.arrive_airport_name
                } (${flightRoom?.searchCriteria?.destination?.airport_code?.toUpperCase()})`
                : "",
            layover: outboundLayover,
            arrival_time:
              outBoundFlights.length > 0
                ? formatTime(outBoundFlights[outBoundFlights.length - 1]?.arrival_time)
                : "",
            departure_date: outBoundFlights[0]?.departure_date,
            arrival_date: outBoundFlights[outBoundFlights?.length - 1]?.arrival_date,
            stop: `${outBoundFlights?.length - 1 !== 0 ? outBoundFlights?.length - 1 + " stops" : "Direct"}`,
            flightDeparture:
              isEmpty(outBoundFlights[0]?.departure_time) !== ""
                ? formatTime(outBoundFlights[0]?.departure_time)
                : "",
          },
        ],
      };
    }

    dispatch(setHolidayDetailsState(obj));
    return obj;
  };

  const retrieveholidayBasketRetrieve = async (flightRoom: any) => {
    // setApiCallPageLoad(true)
    let isDirectFlight: boolean = false;
    const adultPerRoom: any = [];
    const childPerRoom: any = [];
    const childAge: any = [];
    const cabinClass: CabinClass = flightRoom.searchCriteria.class;
    const room = flightRoom.searchCriteria.guest;
    const infantPerRoom: any = [];
    removeSessionElements();
    if (room.length > 0) {
      room?.map((r: any) => {
        adultPerRoom.push(r.adults.toString());
        childPerRoom.push(r.children.length.toString());
        infantPerRoom.push(r.infants.length.toString());
        const childrenAges: any = {};

        if (r?.children?.length > 0) {
          r?.children.forEach((child: any) => {
            childrenAges[child.child.toString()] = child.age;
          });
          childAge.push(childrenAges);
        } else {
          childAge.push({});
        }
        return r;
      });
    }

    if (process.env.REACT_APP_PROJECT_COUNTRY === "ie") {
      directFlightsIE.map((d: any) => {
        if (
          d.dep_code === flightRoom.searchCriteria.departure.departure_airport &&
          d.des_code.toUpperCase() ===
          isEmpty(
            flightRoom.searchCriteria.destination.airport_code.toUpperCase() +
            " ;" +
            flightRoom.searchCriteria.destination["Searc ID"]
          )
        ) {
          isDirectFlight = true;
          return d;
        } else {
          return <></>;
        }
      });
    } else if (process.env.REACT_APP_PROJECT_COUNTRY === "uk") {
      directFlights.map((d: any) => {
        if (
          d.dep_code === flightRoom.searchCriteria.departure.departure_airport &&
          d.des_code.toUpperCase() ===
          isEmpty(
            flightRoom.searchCriteria.destination.airport_code.toUpperCase() +
            " ;" +
            flightRoom.searchCriteria.destination["Searc ID"]
          )
        ) {
          isDirectFlight = true;
          return d;
        } else {
          return <></>;
        }
      });
    }

    const fetchClass: any = CABIN_CLASS_ENUM[cabinClass];

    let holidayPackageJson: any = [
      {
        DestinationFrom: flightRoom.searchCriteria.departure.departure_airport,
        Destination:
          flightRoom.searchCriteria.destination.airport_code?.toUpperCase() +
          " ;" +
          flightRoom.searchCriteria.destination["Searc ID"],
        departure_date: flightRoom.searchCriteria.date[0],
        rooms: flightRoom.searchCriteria.guest.length.toString(),
        nights: flightRoom.searchCriteria.night,
        cabin_class: fetchClass,
        adults: adultPerRoom,
        children: childPerRoom,
        infants: infantPerRoom,
        siteId: process.env.REACT_APP_SITE_ID,
        cheapestFareCat: "IT",
        direct_flight_only: isDirectFlight ? 1 : 0,
        basketId: basketId,
        hotel_id: Number(flightRoom?.hotel_id),
        only_pu_fares: false,
        // hotel_name: flightRoom.roomData[0]?.SearchResult?.hotel_name
      },
    ];

    if (basketId !== "0") {
      holidayPackageJson[0].packageSearchId = flightRoom.packageSearchId;
    }

    if (flightRoom?.searchCriteria?.childrens > 0) {
      holidayPackageJson[0].child_age = flightRoom?.searchCriteria?.child_age;
    }
    if (flightRoom?.searchCriteria?.infants > 0) {
      holidayPackageJson[0].infant_ages = flightRoom?.searchCriteria?.infant_age;
    }

    const { payload } = await dispatch(fetchAsyncHolidayPackages(holidayPackageJson));
    if (payload?.Packages) {
      await setIsDataLoaded(true);
      await setLottieLoading(false);
      await dispatch(setLoading(false));
    } else {
      await setIsDataLoaded(false);
      await dispatch(setLottieLoading(false));
      await dispatch(setLoading(false));
    }
    // await dispatch(setLoading(false))
    return payload;
  };

  const holidayPackageStoreCriteria = (flightRoom: any, holiday: any) => {
    const criteria = flightRoom.searchCriteria;

    const adultPerRoom: any = [];
    const childPerRoom: any = [];
    const infantPerRoom: any = [];

    if (criteria.guest.length > 0) {
      criteria.guest?.map((r: any) => {
        adultPerRoom.push(r.adults.toString());
        childPerRoom.push(r.children.length.toString());
        infantPerRoom.push(r.infants.length.toString());
        return r;
      });
    }

    let holidayPackageJson: any = [
      {
        DestinationFrom: criteria.departure.departure_airport,
        Destination: `${criteria.destination.airport_code} ;${criteria.destination?.["Searc ID"]}`,
        departure_date: criteria.date[0],
        rooms: criteria.guest.length,
        nights: criteria.night,
        cabin_class: criteria.class,
        adults: adultPerRoom,
        children: childPerRoom,
        infants: infantPerRoom,
        siteId: process.env.REACT_APP_SITE_ID,
        cheapestFareCat: "IT",
        hotel_id: Number(flightRoom?.hotel_id),
        // hotel_name: flightRoom.roomData[0]?.SearchResult?.hotel_name
      },
      {
        ids: {
          flightSearchCriteriaId: Number(flightRoom.flightSearchCriteriaId),
          hotelSearchCriteriaIds: Number(flightRoom.hotelSearchCriteriaIds),
          selectedFlight: Number(flightRoom?.psw_result_id),
          hotelSearchCriteriaId_nextDay: Number(flightRoom?.hotelSearchCriteriaId_nextDay),
        },
        filter: {
          // "selected_flight_id": Number(flightRoom?.psw_result_id),
          selected_flight_id: Number(flightRoom.psw_result_id),
          selected_hotel_id: Number(flightRoom?.hotel_id),
        },
      },
    ];

    if (criteria?.childrens > 0) {
      holidayPackageJson[0].child_age = criteria?.child_age;
    }
    return holidayPackageJson;
  };

  const retrieveDB = useCallback(async () => {
    let res = await dispatch(retrieveBasket(basketId));
    res = res.payload;
    removeSessionElements();
    let data: any = {};
    if (res?.data?.length > 0) {
      data = res.data[0];
      let flightData: any = {};
      flightData = JSON.parse(Object.values(data.basket.cart.flights)[0] as string);
      dispatch(setLottieLoading(true));
      setSessionStorage("more-details-click", "true");
      setSessionStorage("OutboundFlights", JSON.stringify(flightData.selectedFlights.outbound));
      setSessionStorage("InboundFlights", JSON.stringify(flightData.selectedFlights.inbound));
      setSessionStorage("stepper", "1");
      const holidayData: any = JSON.parse(data.basket.cart.holiday);
      flightData = {
        ...flightData,
        searchCriteria2: await holidayPackageStoreCriteria(flightData, holidayData),
      };
      dispatch(setFlightRoomState(flightData));
      dispatch(setHolidayDetailsState(holidayData));
      const resp = await retrieveholidayBasketRetrieve(flightData);
      if (resp) {
        await dispatch(setHolidayPackage(resp));
        dispatch(setLoading(false));
        dispatch(setLottieLoading(false));
        setIsDataLoaded(true);
      }
      // const resHoliday = await fetchHolidayDetail(flightData);
      // dispatch(setHolidayDetials(resHoliday))
      // if (resHoliday?.length > 0) {
      //   flightData.holidayDetailLogId = resHoliday[0].holidayDetailLogId || 0
      // }
      // setIsRetrieveBasketApiCall(true)
      // await fetchHotelDetails(flightData.hotel_id);
      // // await getChangeFlight(flightData, true);
      // const abc = await retrieveholidayBasketRetrieve(flightData)
      // const holidayData: any = JSON.parse(data.basket.cart.holiday);
      // dispatch(setFlightRoomState(flightData));
      // setOnChangeMultiRoom(flightRoom.multiRooms);
      // dispatch(setHolidayPackage(abc))
      // setSessionStorage("more-details-click", "true")
      // dispatch(setHolidayDetailsState(holidayData));
      // dispatch(setLoading(false));
      // setIsDataLoaded(true)
      // dispatch(setLottieLoading(false))
      // await dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
      dispatch(setLottieLoading(false));
    }
  }, [flightRoom, basketId, holiday]);

  const reverseString = (str: any) => {
    var splitString = str?.split("/");
    var reverseArray = splitString.reverse();
    var joinArray = reverseArray.join("/");
    return joinArray;
  };

  const fetchHolidayDetail = async (flightRoom: any) => {
    const body = [
      {
        psw_result_id: flightRoom?.psw_result_id,
        roomids: flightRoom?.roomids,
        packageSearchId: flightRoom.packageSearchId,
      },
    ];
    let res = await dispatch(fetchAsyncHolidayPackageDetails(body));
    res = res.payload;
    return res;
  };

  const setInitialDeparture = (e: string, code?: boolean) => {
    let data =
      process.env.REACT_APP_PROJECT_COUNTRY === "ie"
        ? [...IE_DEPARTURE]
        : process.env.REACT_APP_PROJECT_COUNTRY === "us"
          ? [...US_DEPARTURE]
          : [...UK_DEPARTURE];
    data = data.map((item: any, index: number) => {
      return {
        ...item,
        selected: index === 0 ? true : false,
      };
    });

    let d = data?.map((item) => {
      code === true
        ? item.departure_airport === e
          ? (item.selected = true)
          : (item.selected = false)
        : item.full_name === e
          ? (item.selected = true)
          : (item.selected = false);
      return item;
    });
    return d;
  };

  const getRoomIds = (arr: any, guest: any) => {
    const roomsArr: any = arr.map((e: any) => e.id);
    let passengerDetails: any = {};

    let totalPassenger = [...Array(adults + childrens)].map((value, index) => (index + 1).toString());

    roomsArr.map((r: any, i: any) => {
      const totalLength = guest[i].adults + guest[i].children?.length;

      const totalPassengerPerRoom = [];

      for (let i = 0; i < totalLength; i++) {
        totalPassengerPerRoom.push(totalPassenger[i]);
      }

      totalPassenger.splice(0, totalLength);
      return (passengerDetails[r] = totalPassengerPerRoom.join(","));
    });
    return roomsArr.toString();
  };

  const renderOnLoadHolidayPackageApi = async (queryData: any) => {
    const adult = queryData.adult.split(",");
    dispatch(setLottieLoading(true));
    const cabinClass: CabinClass = capitalizeFirstLetterEachWords(queryData.cabin_class)
      .split("_")
      .join(" ") as CabinClass;
    const child_age =
      queryData?.child_age && queryData?.child_age?.length > 0 ? JSON.parse(queryData.child_age) : [];
    const children = queryData.children_count.split(",");
    const startDate = queryData.daterange.split(",")[0];
    const endDate = queryData.daterange.split(",")[1];
    let destination = queryData.destination;
    const infantAge =
      queryData.infant_age && queryData.infant_age !== "undefined" && queryData.infant_age.length > 0
        ? JSON.parse(queryData.infant_age)
        : [];
    const infantCount = queryData.infant_count.split(",");
    const roomCount = queryData.room_count;
    const DestinationFrom = queryData.departure.toUpperCase();
    const des = queryData.destinationId || null;

    if (des) {
      const destinationData = DestinationList.filter((f) => Number(des) === f["Searc ID"]);
      if (destinationData.length > 0) {
        destination = destinationData[0].airport_code + "_;" + destinationData[0]["Searc ID"];
      }
    }

    const destinationCode = destination.split("_").join("").toUpperCase();
    const desParts = destinationCode?.split(";");
    const desCodeName = desParts[0] + " ;" + desParts[1];
    const hotelId = queryData.hotelId || queryData.hotel_id || null;

    let isDirectFlight: boolean = false;

    if (process.env.REACT_APP_PROJECT_COUNTRY === "ie") {
      directFlightsIE.map((d: any) => {
        if (d.dep_code === DestinationFrom && d.des_code === isEmpty(desCodeName?.toUpperCase())) {
          isDirectFlight = true;
          return d;
        } else {
          return <></>;
        }
      });
    } else if (process.env.REACT_APP_PROJECT_COUNTRY === "uk") {
      directFlights.map((d: any) => {
        if (d.dep_code === DestinationFrom && d.des_code === isEmpty(desCodeName?.toUpperCase())) {
          isDirectFlight = true;
          return d;
        } else {
          return <></>;
        }
      });
    }

    // Set Rooms
    let roomArr: any = [];
    for (let i = 0; i < Number(roomCount); i++) {
      let childArr: any = [];
      let infantArr: any = [];

      for (let j = 0; j < Number(children[i]); j++) {
        let child = {
          child: j + 1,
          age: Number(child_age[i][j + 1]),
        };

        childArr.push(child);
      }

      for (let j = 0; j < Number(infantCount[i]); j++) {
        let child = {
          child: j + 1,
          age: Number(infantAge[i][j + 1]),
        };

        infantArr.push(child);
      }

      let obj = {
        room: i + 1,
        adults: Number(adult[i]),
        children: childArr,
        infants: infantArr,
      };
      roomArr.push(obj);
    }

    // Set Departure
    const dep = setInitialDeparture(DestinationFrom, true);

    // Set Dates
    const date = [
      new DateObject(dayjs(reverseString(startDate)).format("YYYY/MM/DD")),
      new DateObject(dayjs(reverseString(endDate)).format("YYYY/MM/DD")),
    ];

    // Set Destination
    const selectedDestination = DestinationList.filter(
      (f) => f.airport_code.toUpperCase() + ";" + f["Searc ID"] === destinationCode
    );

    // set individual adults , infants and childrens
    const adults = roomArr?.reduce((a: number, b: any) => a + b?.adults, 0);
    const childrens = roomArr?.reduce(
      (a: number, b: any) =>
        a + b?.children?.filter((item: { age: string }) => Number(item?.age) >= 2)?.length || 0,
      0
    );
    const infants = roomArr?.reduce((a: number, b: any) => a + b?.infants?.length, 0);

    const date1: any = new Date(reverseString(startDate));
    const date2: any = new Date(reverseString(endDate));

    const timeDifference = date2 - date1;

    const nights = timeDifference / (1000 * 60 * 60 * 24);
    const isDubai =
      selectedDestination?.length > 0 ? selectedDestination[0]["Display Name #1"].includes("Dubai") : false;

    const totalChildren = roomArr.flatMap((el: any) => el.children);
    const hasAgeSelected = totalChildren?.every((el: any) => el.age);
    const totalInfantsChildren = roomArr.flatMap((el: any) => el.infants);
    const hasInfantAgeSelected = totalInfantsChildren?.every((el: any) => el.age);
    const totalGuestWithInfants = adults + childrens + infants;

    let isError = false;
    if (DestinationFrom === "") {
      isError = true;
    } else if (selectedDestination?.length === 0) {
      isError = true;
    } else if (isDubai === false && !(nights >= 3)) {
      isError = true;
    } else if (!hasAgeSelected) {
      isError = true;
    } else if (!hasInfantAgeSelected) {
      isError = true;
    } else if (totalGuestWithInfants === 0) {
      isError = true;
    } else if (isEmpty(cabinClass) === "") {
      isError = true;
    } else if (roomCount === "") {
      isError = true;
    } else if (queryData.adult === "") {
      isError = true;
    } else {
      isError = false;
    }

    if (hotelId === null) {
      window.location.href = "/" + window.location.search;
    }

    if (hasAgeSelected && hasInfantAgeSelected && selectedDestination?.length > 0 && !isError) {
      let body: any = [
        {
          DestinationFrom,
          Destination:
            selectedDestination?.length > 0
              ? selectedDestination[0].airport_code?.toUpperCase() + " ;" + selectedDestination[0]["Searc ID"]
              : "",
          departure_date: startDate,
          rooms: roomCount,
          nights: String(nights),
          cabin_class: CABIN_CLASS_ENUM[cabinClass],
          adults: adult,
          children: children,
          infants: infantCount,
          siteId: process.env.REACT_APP_SITE_ID,
          only_it_fares: true,
          cheapestFareCat: "IT",
          hotel_id: hotelId,
          direct_flight_only: isDirectFlight ? 1 : 0,
          basketId: "0",
          only_pu_fares: false,
        },
      ];

      // if (queryData?.flight_search_criteria_id) {
      //   body[1] = {
      //     ids: {
      //       "flightSearchCriteriaId": Number(queryData.flight_search_criteria_id),
      //       "hotelSearchCriteriaIds": Number(queryData.hotel_search_criteria_ids),
      //       "selectedFlight": Number(queryData.selected_flight),
      //       "hotelSearchCriteriaId_nextDay": Number(queryData.hotel_search_criteria_id_nextDay),
      //     },
      //     filter: {
      //       // "selected_flight_id": Number(flightRoom?.psw_result_id),
      //       "selected_flight_id": Number(queryData.selected_flight),
      //       "selected_hotel_id": Number(queryData.hotel_id)
      //     }
      //   }
      // }

      if (childrens !== 0) {
        body[0].child_age = child_age;
      }
      if (infants !== 0) {
        body[0].infant_ages = infantAge;
      }

      setSessionStorage("search", JSON.stringify(body));
      setSessionStorage("room", JSON.stringify({ room: roomArr, date }));

      const { payload } = await dispatch(fetchAsyncHolidayPackages(body));

      const selectedDeparture = dep.filter((f) => f.selected);
      const { depart_airport_name, departure_airport, full_name } = selectedDeparture[0];
      setSessionStorage("more-details-click", "true");

      if (payload?.Packages) {
        dispatch(setHolidayPackage(payload));
        const res = payload?.Packages?.results;

        if (res?.length > 0) {
          const step = 1;
          let filterHotel =
            hotelId !== null
              ? payload?.Packages?.results.filter((f: any) => f.SearchResult.hotel_id === hotelId)
              : res;
          filterHotel = filterHotel?.length > 0 ? filterHotel : res;
          const multiRoomIds = getRoomIds(
            sortRoom(
              hotelId !== null && step === 1
                ? filterHotel[0].SearchResult
                : payload.Packages.results[0].SearchResult,
              filters
            ),
            roomArr
          );
          let obj = {
            ...flightRoom,
            packageSearchId: payload.packageSearchId,
            flightSearchCriteriaId: payload.SearchCriteria[1].ids.flightSearchCriteriaId,
            hotelSearchCriteriaIds: payload.SearchCriteria[1].ids.hotelSearchCriteriaIds,
            hotelSearchCriteriaId_nextDay: payload.SearchCriteria[1].ids.hotelSearchCriteriaId_nextDay,
            psc_request_id: payload.flightSearchCriteriaId,
            psw_result_id: payload.FlightDetails.outbound.flights[0].psw_result_id,
            roomids: multiRoomIds,
            store_psw_result_id: payload.FlightDetails.outbound.flights[0].psw_result_id,
            hotel_name:
              hotelId !== null && step === 1
                ? filterHotel[0].SearchResult.hotel_name
                : res[0].SearchResult.hotel_name,
            hotel_id: hotelId !== null && step === 1 ? hotelId : res[0].SearchResult.hotel_id,
            selectHotelId:
              hotelId !== null && step === 1 ? filterHotel[0].SearchResult.id : res[0].SearchResult.id,
            search_result_id:
              hotelId !== null && step === 1 ? filterHotel[0].SearchResult : res[0].SearchResult.id,
            multiRooms:
              hotelId !== null && step === 1
                ? sortRoom(filterHotel[0].SearchResult, filters)
                : sortRoom(res[0].SearchResult, filters),
            storeMultiRoom:
              hotelId !== null && step === 1
                ? sortRoom(filterHotel[0].SearchResult, filters)
                : sortRoom(res[0].SearchResult, filters),
            roomData: hotelId !== null && step === 1 ? filterHotel : res,
            searchCriteria: {
              // isAdditionalTransferAmountAdd: selectedDestination.length > 0 ? selectedDestination[0]?.transfer_add : false,
              child_age: child_age,
              departure: {
                depart_airport_name,
                departure_airport,
                full_name,
                selected: true,
              },
              infant_age: infantAge,
              destination: selectedDestination[0],
              date: [startDate, endDate],
              guest: roomArr,
              class: cabinClass,
              cabin_class: cabinClass,
              night: nights,
              adults,
              childrens,
              infants,
            },
          };

          await dispatch(setFlightRoomState(obj));
          await fetchHolidayDetail(obj);
          await setHotelData(payload, obj);
          setIsDataLoaded(true);
          dispatch(setLoading(false));
          dispatch(setLottieLoading(false));
        } else {
          setIsDataLoaded(false);
          dispatch(setLoading(false));
          dispatch(setLottieLoading(false));
        }
      } else {
        setIsDataLoaded(false);
        dispatch(setHolidayPackage({}));
        // console.log(payload)
        dispatch(setLoading(false));
        dispatch(setLottieLoading(false));
      }
    } else {
      setIsDataLoaded(false);
      dispatch(setLottieLoading(false));
    }
  };

  return (
    <Box
      mt="10px"
      sx={{
        "& ::selection": {
          background: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
        },
      }}
    >
      {/* {isDataLoaded ? (
        <Box
          sx={{
            "& ::selection": {
              background: isPlataniumHotel
                ? colors.basics.plataniumGreenColor
                : colors.basics.primary,
            },
          }}
        > */}
      <CustomContainer>
        <Button
          disableRipple
          sx={{
            fontSize: { sm: "16px", xs: "14px" },
            p: 0,
            "&:hover": {
              color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
              background: "transparent",
            },
            height: "fit-content",
            margin: "8px 0 ",
            color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
            background: "transparent",
          }}
          onClick={() => {
            // navigate(-1);
            dispatch(setStepperState(1));
            navigate(`/holiday-summary/${session_id}${window.location.search}`);
          }}
        >
          Back to accommodation & flights
        </Button>
        <StepperCard />
        <Box sx={{ display: "flex", flexWrap: "wrap", py: { md: "40px", xs: "20px" } }}>
          <Box sx={{ width: { md: "65%", xs: "100%" } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: { md: "calc(100% - 20px)", xs: "100%" },
              }}
            >
              <Box
                sx={{
                  "&:not(:last-child)": {
                    borderBottom: "1px solid #ccc",
                  },
                  backgroundColor: colors.basics.secondary,
                  border: `1px solid ${colors.border.grey}`,
                  borderRadius: "4px",
                }}
              >
                <Box sx={{ padding: "20px" }}>
                  <Typography variant="h1">Car Hire</Typography>
                  <CarTopSection />
                  <Grid
                    container
                    columnSpacing={2}
                    sx={{
                      "& button": {
                        backgroundColor: isPlataniumHotel
                          ? colors.basics.plataniumGreenColor
                          : colors.basics.primary,
                        "&:hover": {
                          backgroundColor: isPlataniumHotel
                            ? colors.basics.plataniumGreenColor
                            : colors.basics.primary,
                        },
                      },
                    }}
                  >
                    {availabilityLoading ? (
                      <Typography
                        variant="h1"
                        sx={{
                          textAlign: "center",
                          width: "100%",
                          color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                        }}
                      >
                        Loading...
                      </Typography>
                    ) : !!availability?.newResult?.length ? (
                      availability?.newResult?.slice(0, 2).map((car) => {
                        const selected =
                          flightRoom?.selectedCarHired?.model.toLowerCase() === car.model.toLowerCase();
                        return (
                          <CarList
                            car={car}
                            selected={selected}
                            selectedCar={flightRoom?.selectedCarHired}
                            isDrawer={false}
                            isPlataniumHotel={isPlataniumHotel}
                            signForPricing={signForPricing}
                            handleAddCarAmountToFlight={handleAddCarAmountToFlight}
                          />
                        );
                      })
                    ) : (
                      <Typography
                        variant="h1"
                        sx={{
                          textAlign: "center",
                          width: "100%",
                          color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                        }}
                      >
                        No data found
                      </Typography>
                    )}
                  </Grid>

                  {!!availability?.newResult.length ? (
                    <Box
                      sx={{
                        "& .MuiButtonBase-root": {
                          color: colors.grey[10],
                          background: "transparent",
                          height: { xs: "40px", sm: "50px" },
                          border: `1px solid ${colors.border.grey}`,
                          "&:hover": {
                            background: "transparent",
                          },
                        },
                      }}
                    >
                      <Button
                        sx={{
                          p: "10px 15px ",
                          whiteSpace: "nowrap",
                          fontSize: { sm: "16px", xs: "14px" },
                          width: "100%",
                        }}
                        onClick={toggleFlightDrawer(true)}
                      >
                        Show more options
                      </Button>
                      <Drawer
                        anchor={"right"}
                        open={openFlightDrawer}
                        // onClose={toggleFlightDrawer(false)}
                        PaperProps={{
                          sx: { width: { md: 1050, sm: "100%" } },
                        }}
                      >
                        <CarHireComponent
                          setOpenFlightDrawer={setOpenFlightDrawer}
                          handleAddCarAmountToFlight={handleAddCarAmountToFlight}
                          isPlataniumHotel={isPlataniumHotel}
                        />
                      </Drawer>
                    </Box>
                  ) : null}
                </Box>
              </Box>
              <Box
                mt={{ xs: "5px", sm: "10px" }}
                sx={{
                  backgroundColor: colors.basics.secondary,
                  borderRadius: "4px",
                }}
                order={{ xs: 7, sm: 6 }}
              >
                <AttractionComponent isPlataniumHotel={isPlataniumHotel} />
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: { md: "35%", xs: "100%" } }}>
            <Grid
              width={"100%"}
              item
              xl={4}
              lg={4}
              xs={12}
              sx={{
                order: { lg: 2, xs: 1 },
                position: { lg: "sticky !important", xs: "unset" },
                top: "110px",
                height: "fit-content",
                mt: { md: "0", xs: "14px" },
              }}
            >
              <Box
                // mt={3}
                sx={{
                  "& .car-book-button": {
                    display: "none",
                  },
                  display: { sm: "block", md: "block", xs: "none" },
                  // '& .MuiBox-root svg':{
                  //   fill:'red'
                  // }
                }}
              >
                <PricingCard session_id={session_id} />
              </Box>
              <Box
                mt={3}
                // p={'21px 16px'}
                // p={{ sm: "16px 16px", xs: "0 8px 8px" }}
                // border={`1px solid ${colors.grey[30]}`}
                // borderRadius="5px"
                // bgcolor={colors.basics.secondary}
                sx={{
                  "& .MuiAccordionSummary-expandIconWrapper": {
                    // display: expanded == 1 ? 'none' : 'flex'
                    display: "none",
                  },
                  "& .MuiAccordionDetails-root": {
                    paddingTop: "0",
                    padding: { sm: "0px 16px 16px", xs: "0px 8px 8px" },
                  },
                  "& .MuiAccordionSummary-root": {
                    cursor: "text !important",
                  },
                  "& .MuiAccordionSummary-content ": {
                    // margin: '16px 0',
                    display: "flex",

                    // fontSize: { sm: "26px", xs: "24px" }
                    width: "100%",
                  },
                  "& svg": {
                    "& circle:first-of-type": {
                      fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : "",
                    },
                    "& path": {
                      fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : "",
                    },
                    fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : "",
                  },
                }}
              >
                <HotelDetails showButtons={true} />
              </Box>
            </Grid>
          </Box>
        </Box>
      </CustomContainer>
      {/* </Box>) : !loading ? (
          <Box
            my={"120px"}
            sx={{
              width: "100%",
              // mt: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // gap: "10px"
            }}
          >
            <Typography
              variant="h1"
              textAlign="center"
              color={colors.basics.primary}
            >
              Unfortunately, no results were returned for the package you have
              searched.
            </Typography>
            <Typography
              variant="h1"
              textAlign="center"
              color={colors.basics.primary}
            >
              Please search again for alternatives.
            </Typography>
            <Button
              sx={{
                mt: "20px",
                width: "fit-content",
              }}
              onClick={() => {
                newSearch();
              }}
            >
              New Search
            </Button>
          </Box>
        ) : (
        <Box minHeight={500}></Box>
      )} */}
    </Box>
  );
};
