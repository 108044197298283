import React, { useEffect, useRef } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { colors } from "../../../styles/colors";
import { useAppSelector } from "../../../hooks/useAppReducer";
import { getApi } from "../../../utils/api";
import { getSiteID } from "../../../helpers/getSiteId";
import { setStaticSiteAccordianContent } from "../../../features/staticSiteAccordianData/staticSiteAccordianData";
import { useDispatch } from "react-redux";
import useWindowWidth from "../../../hooks/useWindowWidth";
import ReviewsTab from "./ReviewsTab";

export const StaticAccordion = (props: any) => {
    const { apiCallAccordianData, setApicallAccordianData } = props;
    const { flightRoom } = useAppSelector((state) => state.flightRoom);
    const { countryRemark } = useAppSelector((state) => state.packageDetails);
    const { staticSiteAccordianData } = useAppSelector((state) => state.staticSiteAccordianData);
    const [expanded, setExpanded] = React.useState<any>(props.openReviewTab ? `panel0` : `panel${20}`);
    const [showMoreInfo, setShowMoreInfo] = React.useState<any>(false);
    const { hotelDetails, tripHotelDetails } = useAppSelector((state) => state.hotelDetails);
    const isPlataniumHotel = hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel
        ? Number(hotelDetails.HotelInfo.HotelInfo.platinumHotel) !== 0
        : false;
    const expandedAccordionRef = useRef<any>(null);
    const windowWidth = useWindowWidth();

    const dispatch = useDispatch();
    // 8=IE, 9=COM
    useEffect(() => {
        const fetchData = async () => {
            try {
                const siteID = getSiteID();
                const apiUrl = `${process.env.REACT_APP_PUBLIC_STATIC_URL}productfooter?tsrProductID=${flightRoom.hotel_id}&siteID=${siteID}`;
                const response = await getApi(apiUrl);
                if (response !== "error") {
                    dispatch(setStaticSiteAccordianContent(response));
                    // setStaticAccodianData(response);
                    setApicallAccordianData(false);
                } else {
                    setApicallAccordianData(false);
                }
            } catch (error) {
                console.error("Error:", error);
                setApicallAccordianData(false);
            }
        };
        if (apiCallAccordianData && flightRoom.callHolidaySummaryApi) {
            fetchData();
        }
    }, [apiCallAccordianData, flightRoom.hotel_id, flightRoom.callHolidaySummaryApi]);

    useEffect(() => {
        const reviewsAccordion = props.reviewData?.title === "Reviews";
        if (reviewsAccordion) {
            setExpanded(props.openReviewTab && `panel0`);
        } else if (staticSiteAccordianData?.accordions?.length > 0) {
            setExpanded(`panel${staticSiteAccordianData?.accordions[0]?.sortOrder}`);
        }
        // }
    }, [props.reviewData?.title, staticSiteAccordianData]);

    useEffect(() => {
        if (props.openReviewTab) {
            setExpanded(props.openReviewTab && `panel0`);
        }
    }, [props.openReviewTab]);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        if (props.openReviewTab) {
            props.setOpenReviewTab(false);
        }
        setExpanded(newExpanded ? panel : false);

        if (newExpanded) {
            expandedAccordionRef.current = panel;

            setTimeout(() => {
                const element = document.getElementById(panel);
                if (element) {
                    const yOffset = window.innerWidth < 769 ? 65 : 90;
                    const y = element.getBoundingClientRect().top + window.scrollY - yOffset;
                    window.scrollTo({ top: y, behavior: "smooth" });
                }
            }, 500);
        }
    };

    return (
        <Box
            sx={{
                border: `1px solid ${colors.grey[30]}`,
                borderRadius: "4px",
                "& .MuiAccordion-root": {
                    margin: "0",
                    boxShadow: 0,
                },
                "& .MuiAccordionSummary-root": {
                    "&:hover": {
                        textDecoration: "underline",
                    },
                    padding: 0,
                    minHeight: "48px",
                    cursor: "pointer",
                },
                "& .MuiAccordionSummary-content": {
                    margin: 0,
                },
                "& .MuiAccordionDetails-root": {
                    padding: "8px 0 16px",
                },
                "& .MuiAccordionSummary-root.Mui-expanded": {
                    borderBottom: `1px solid ${colors.grey[30]}!important`,
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                    margin: 0,
                },
                "& .Mui-expanded": {
                    minHeight: "48px !important",
                    alignItems: "center",
                    margin: "0 !important",
                },
                "& .MuiAccordionDetails-root ": {
                    padding: {
                        md: "20px 0 ",
                        xs: "20px 0",
                    },
                },
                "& MuiAccordion-root.Mui-expanded": {
                    margin: "0 !important",
                },
                "& .MuiSvgIcon-root": {
                    color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                    size: "1.25rem",
                },
                "& .MuiAccordion-root:before": {
                    background: "none",
                },
                ".MuiAccordion-root:last-of-type": {
                    boxShadow: "0",
                    borderBottomRightRadius: "0",
                    borderBottomLeftRadius: "0",
                    border: "0 !important",
                },
                "& .heading": {
                    "&:hover": {
                        textDecoration: "underline",
                    },
                },
                "& ul": {
                    marginLeft: "20px",
                    paddingBottom: "16px",
                },
                "& li": {},
                "& ol": {
                    marginLeft: "20px",
                    paddingBottom: "16px",
                },
                "& .MuiAccordionSummary-content p": {
                    paddingBottom: "0",
                },
                "& p": {
                    paddingBottom: "8px",
                },
            }}
        >
            {[props.reviewData, ...staticSiteAccordianData?.accordions,]?.map(
                (panelData: any, index: any) =>
                    // {[...staticSiteAccordianData?.accordions, props.reviewData]
                    //     .filter((panelData: any) => panelData?.title !== "Reviews") // Exclude the Reviews tab
                    //     .map((panelData: any, index: any) => (
                    Object.keys(panelData).length > 0 && (

                        <Accordion
                            key={index}
                            expanded={
                                expanded === `panel${panelData.sortOrder}`
                                    ? true
                                    : false
                            }
                            // expanded={expanded === `panel${panelData?.sortOrder}`}
                            onChange={handleChange(`panel${panelData.sortOrder}`)}
                            id={`panel${panelData.sortOrder}`}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${panelData.sortOrder}bh-content`}
                                id={`panel${panelData.sortOrder}bh-header`}
                                sx={{
                                    borderBottom:
                                        expanded === panelData?.id ? "1px solid transparent" : `1px solid ${colors.grey[30]}`,
                                }}
                            >
                                <Typography
                                    fontWeight={700}
                                    color="#303030"
                                    fontSize={{ sm: "16px", xs: "14px" }}
                                    lineHeight={"1.2"}
                                    padding="10px 5px"
                                    className="heading"
                                    variant="body1"
                                >
                                    {panelData?.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box padding="0 5px">
                                    {tripHotelDetails?.reviews_rating && panelData?.title == "Reviews" && (
                                        <ReviewsTab
                                            reviewsTripAdvisorData={props.reviewsTripAdvisorData}
                                            tripAdvisorData={props.tripAdvisorData}
                                            tripHotelDetails={props.tripHotelDetails}
                                        />
                                    )}
                                    {panelData?.title !== "Reviews" && (
                                        <Typography
                                            sx={{
                                                fontFamily: "Raleway",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                fontSize: { xl: 16, xs: 14 },
                                                paddingBottom: "10px",
                                                lineHeight: "1.2",
                                            }}
                                            dangerouslySetInnerHTML={{ __html: panelData?.html }}
                                        />
                                    )}
                                    {/* <div style={{ fontSize: window.innerWidth < 1025 ? '14px' : "16px", paddingBottom: "10px", lineHeight: "1.2" }} dangerouslySetInnerHTML={{ __html: panelData?.html }} /> */}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )
            )}
            {Object.values(countryRemark)?.length === 0 && staticSiteAccordianData?.importantInfo == null ? (
                ""
            ) : (
                <Accordion
                    //20 Rendam number for importantInfo scroll
                    expanded={expanded === `panel${20}`}
                    onChange={handleChange(`panel${20}`)}
                    id={`panel${20}`}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${20}bh-content`}
                        id={`panel${20}bh-content`}
                        sx={{
                            borderBottom: expanded === 20 ? "1px solid transparent" : `1px solid ${colors.grey[30]}`,
                        }}
                    >
                        <Typography
                            fontWeight={700}
                            color="#303030"
                            fontSize={{ sm: "16px", xs: "14px" }}
                            padding="10px 5px"
                            className="heading"
                            variant="body1"
                            lineHeight={"1.2"}
                        >
                            Important Info
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box padding="0 5px">
                            {Object.values(countryRemark)?.length ? (
                                <>
                                    {Object.values(countryRemark)
                                        .slice(0, showMoreInfo ? Object.values(countryRemark).length : 1)
                                        .map((country: any, index) => (
                                            <React.Fragment key={index}>
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Raleway",
                                                        fontStyle: "normal",
                                                        fontWeight: 700,
                                                        fontSize: { xl: 20, xs: 18 },
                                                        lineHeight: "1.2",
                                                    }}
                                                >
                                                    {country?.title} :{" "}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Raleway",
                                                        fontStyle: "normal",
                                                        fontWeight: 400,
                                                        // lineClamp:{xl:3},
                                                        fontSize: { xl: 16, xs: 14 },
                                                        lineHeight: "1.2",
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: country?.remark }}
                                                />
                                            </React.Fragment>
                                        ))}
                                    {(Object.values(countryRemark).length > 1 || windowWidth < 600) && (
                                        <span
                                            onClick={() => {
                                                // setTimeout(() => {
                                                // window.scrollTo({
                                                //     top: scrollToFirstPara,
                                                //     behavior: 'smooth',
                                                // })
                                                // }, 1)
                                                setShowMoreInfo(!showMoreInfo);
                                            }}
                                            style={{
                                                fontSize: "12px",
                                                color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                                                cursor: "pointer",
                                            }}
                                        >
                                            {showMoreInfo ? "Show less.." : "Show more.."}
                                        </span>
                                    )}
                                    {/* <div style={{ fontSize: window.innerWidth < 1025 ? '14px' : "16px", lineHeight: '1.2' }} dangerouslySetInnerHTML={{ __html: data?.importantInfo }} /> */}
                                </>
                            ) : (
                                <Typography
                                    sx={{
                                        fontFamily: "Raleway",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: { xl: 16, xs: 14 },
                                        lineHeight: "1.2",
                                    }}
                                    dangerouslySetInnerHTML={{ __html: staticSiteAccordianData?.importantInfo }}
                                />
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            )}
        </Box>
    );
};
