import { Box, Chip, Typography } from "@mui/material"
import { colors } from "../styles/colors"
import '../assets/css/fontawesome.css'
import { addSpace } from "../helpers/addSpace"

const OfferDetails = ({ data }: any) => {
    return (
        <ul>
            {data.map((item: any, index: any) => (
                <li key={index} style={{ marginBottom: "10px" }}>
                    <Typography variant="body1" pb='3px' fontWeight={700} color={colors.grey[60]} lineHeight={'1.5'}>
                        {item?.title}
                    </Typography>
                    {/* <Box
                        className="package-card-chip item-star"
                        sx={{
                            '& .MuiChip-root': {
                                height: "20px"
                            },
                        }}
                    >
                        <Chip
                            label={
                                <Typography
                                    variant="subtitle1"
                                    color={colors.basics.white}
                                    fontSize="12px"
                                    fontWeight={500}
                                    lineHeight={"1.5"}
                                >
                                    {addSpace(item?.subTitle)}
                                </Typography>
                            }
                            sx={{
                                background: colors.basics.primary,
                                height: "21px",
                                "& .MuiChip-label": { p: 0 },
                            }}
                        />
                    </Box> */}

                    <Box pt='3px !important'>
                        <Typography variant="body1" fontWeight={400} lineHeight={'1.5'} color={colors.grey[60]}>
                            {item?.description?.replace('Â', '')}
                        </Typography>
                        <span style={{
                            lineHeight: 1.5,
                            fontWeight: 400,
                            fontSize: "75%",
                            color: colors.grey[60],
                        }}>
                            {item?.descriptionExtra}
                        </span>
                    </Box>
                </li>
            ))}
        </ul>
    )
}

export default OfferDetails