export const colors = {
  basics: {
    white: "#ffffff",
    black: "#000000",
    primary: "#e10f3f",
    secondary: "#F9F9F9",
    transparent: "transparent",
    lightmain: "#D9D9D9",
    red: "#d32f2f",
    pink: "#e10f3f20",
    plataniumGreenColor: "#898064",
    plataniumGreenAccentColor: "#89806420",
  },
  grey: {
    10: "#333333",
    20: "#F2F2F2",
    30: "#999999",
    40: "#6D6D6D",
    50: "#E3E3E3",
    60: "#212529",
  },
  destinationHeading: {
    pink: "#e10f3f",
  },
  hover: {
    primary: "#b10c32",
  },
  disable: {
    grey: "#ccc",
    2: "#dbdbdb",
  },
  border: {
    grey: "#BDBDBD",
  },
};
