import { Box, Button, Grid, Typography } from '@mui/material';
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  setFilterCounts,
  setFilteredHolidayPackage,
  setHolidayTypes,
  setRegionCounts,
  setRegionsTypes,
} from '../../../features/holidayPackage/holidayPackageSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppReducer';
import { CustomContainer } from '../../../components/CustomContainer';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { FilterComponent } from '../../../components/FilterComponent';
import FindHolidayWidget from '../../../components/FindHolidayWidget';
import { ReactComponent as LocationIcon } from '../../../assets/icons/LocationIcon.svg';
import { PackageCard } from '../../../components/PackageCardComponent';
import { colors } from '../../../styles/colors';
import { headerHeight } from '../../../common/headerHeight';
import { roundPrice } from '../../../helpers/RoundPrice';
import { setDialogState } from '../../../features/dialog/dialogSlice';
import { setFilterState } from '../../../features/filter/filterSlice';
import { setHotelId } from '../../../features/map/mapSlice';
import { setLoading } from '../../../features/loading/loadingSlice';
import { useNavigate } from 'react-router-dom';
import { setHolidayDetailsState } from '../../../features/holidaySummary/holidaySummarySlice';
import TuneIcon from '@mui/icons-material/Tune';
import MapIcon from '@mui/icons-material/Map';
import { useSelector } from 'react-redux';
import CustomSelectInput from '../../../components/CustomSelectInput';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { getSessionStorage, setSessionStorage } from '../../../helpers/storage';
import { sortRoom } from '../../../helpers/sortRoom';
import parseHTML from '../../../helpers/parseHtml';
import { setIsClickedFilters, setIsHolidayApiCall, setPosition } from '../../../features/commonState';
import { calculatePerPersonPrice } from '../../../helpers/calculatePerPersonPrice';
import { isDiscountValidDate } from '../../../helpers/isDiscountValidDate';
import { formatPrice } from '../../../helpers/formatPrice';

export const HotelComponent = () => {
  //! States
  const dialogState = useAppSelector((state) => state.dialog.dialogState);
  const { holidayPackage, filteredHolidayPackage, status } = useAppSelector((state) => state.holidayPackage);
  const { filters } = useAppSelector((state: any) => state.filters);
  const { loading } = useSelector((state: any) => state.loading)
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  const { flightRoom } = useAppSelector((state) => state.flightRoom);
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate()
  const elementRef = useRef(null);
  const { isClickedFilters, isHolidayApiCall, position } = useSelector((state: any) => state.commonState);

  // const [position, setPosition] = useState({ top: 0, left: 0 });
  // const [isClickedFilters, setIsClickedFilters] = useState(false)
  // const [isHolidayApiCall, setIsHolidayApiCall] = useState(false)

  const windowWidth = useWindowWidth();

  const totalGuest = flightRoom.searchCriteria.adults + flightRoom.searchCriteria.childrens
  //! Hooks
  // const [hotelResultSort, setHotelResultSort] = useState('Popularity');

  //! Variables
  const dispatch = useAppDispatch();
  const filterBoxRef = useRef<any>();

  useEffect(() => {
    if (window.innerWidth < 1024) {
      if (getSessionStorage("click") === "false") {
        getPosition(filteredHolidayPackage, false, true)
      }
    }
  }, [window.innerWidth, filteredHolidayPackage, getSessionStorage("click")])
  useEffect(() => {
    const onScroll = () => {
      if (filterBoxRef?.current) {
        if (filterBoxRef?.current?.getBoundingClientRect()?.top <= 90) {
        } else {
          filterBoxRef.current.style.height = '100%';
          filterBoxRef.current.style.overflow = 'auto';
          filterBoxRef.current.style.overflowX = 'hidden';
        }
      }
    };
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    // display counts of filters hotel
    const counts: any = {};
    const facilities: string[] = [];
    const regionCounts: any = {}
    const regions: string[] = [];

    filteredHolidayPackage?.Packages?.results?.forEach((item: any, i: number) => {
      if (item?.HotelInfo?.resort_name) {
        regions.push(item.HotelInfo.resort_name)
      }

      item.facilities.forEach((ele: string) => {
        const parseString: string | null = parseHTML(ele).body.textContent || null;
        return parseString !== null && facilities.push(parseString)
      })
    })

    regions.forEach(item => {
      regionCounts[item] = (regionCounts[item] || 0) + 1;
    })

    const remove17AllHoliday = facilities.filter((f: any) => f !== "17~All Holidays")

    remove17AllHoliday.forEach(item => {
      const name = item.split('~')[1];
      counts[name] = (counts[name] || 0) + 1;
    });

    dispatch(setRegionCounts(regionCounts))
    dispatch(setFilterCounts(counts))
  }, [filteredHolidayPackage?.Packages?.results])


  useEffect(() => {
    if (status === 'SUCCESS') {
      //* Unique Array for facilities

      const facilities: string[] = [];
      holidayPackage?.Packages?.results?.forEach((item: any, i: number) =>
        item.facilities.forEach((ele: string) => {
          const parseString: string | null = parseHTML(ele).body.textContent || null;
          return parseString !== null && facilities.push(parseString)
        }));

      const remove17AllHoliday = facilities.filter((f: any) => f !== "17~All Holidays")

      var uniqueArr = Array.from(new Set(remove17AllHoliday));
      dispatch(setHolidayTypes(uniqueArr));

      //* unique array for region
      const regions: string[] = [];
      holidayPackage?.Packages?.results?.forEach(
        (item: any, i: number) => item.HotelInfo.resort_name && regions.push(item.HotelInfo.resort_name)
      );
      var uniqueRegions = Array.from(new Set(regions));
      dispatch(setRegionsTypes(uniqueRegions));
    }

    if (status === 'SUCCESS' && !holidayPackage) {
      dispatch(setLoading(false));
    }
  }, [status, holidayPackage?.Packages?.results]);

  useEffect(() => {
    if (holidayPackage?.Packages && status === 'SUCCESS') {
      let data = JSON.parse(JSON.stringify(holidayPackage));
      let filteredData = [];
      setTimeout(() => {
        //* Rating wise filters
        filteredData = holidayPackage.Packages.results.filter((item: any) => {
          const value = item.HotelInfo.hotel_classification === "0" ? Number(item.HotelInfo.hotel_rating) :
            item.HotelInfo.hotel_classification === "2" ? Number(item.HotelInfo.hotel_rating) === 5 ? 5 : Number(item.HotelInfo.hotel_rating) + 0.5 : Number(item.HotelInfo.hotel_rating)

          return filters.starRating.length === 0
            ? item
            : filters.starRating.includes(value);
        });

        //* Region wise filter
        if (filters.regionType.length) {
          filteredData = filteredData?.filter((item: any) => {
            if (filters.regionType.includes(item.HotelInfo?.resort_name)) {
              return item;
            }
            // return item
          });
        }


        //* Hotel Name wise filter
        if (filters.hotelName) {
          filteredData =
            filteredData.length > 0 &&
            filteredData?.filter((item: any) => {
              return item.SearchResult.hotel_name.toLowerCase().includes(filters.hotelName.toLowerCase());
            });
        }

        if (filters.price) {
          filteredData = filteredData.filter((item: any) => {
            const data = sortRoom(item.SearchResult, filters);

            let price1: any = 0
            if (data.length > 0) {
              data.map((f: any) => {
                price1 = price1 + Number(f.total_package)
                return <></>
              })
            }

            const totalPrice = price1 / totalGuest

            let finalPrice;

            if (flightRoom?.searchCriteria?.destination?.discountPrice?.isDiscountUK
              && process.env.REACT_APP_PROJECT_COUNTRY === "uk"
              && isDiscountValidDate()) {
              finalPrice = Number(formatPrice(String(calculatePerPersonPrice(roundPrice(totalPrice), totalGuest))));
            } else if (flightRoom?.searchCriteria?.destination?.discountPrice?.isDiscountIE
              && process.env.REACT_APP_PROJECT_COUNTRY === "ie"
              && isDiscountValidDate()) {

              finalPrice = Number(formatPrice(String(calculatePerPersonPrice(roundPrice(totalPrice), totalGuest))));
            } else {
              finalPrice = (Number(roundPrice(String(totalPrice))).toFixed());
            }
            const price = finalPrice;
            // });
            // const price = Number(
            //   Number(
            //     roundPrice(
            //       String(
            //         disTotalPrice
            //       )
            //     )
            //   ).toFixed()
            // )
            return price >= filters.price[0] && price <= filters.price[1]
          });
        }

        //* Holiday types wise filters
        if (filters.holidayType.length) {
          filteredData = filteredData.filter((item: any) => {
            // if (filters.holidayType.includes('18~All Inclusive Holidays')) {
            //* All Inclusive meal type filter
            const parseString: string | null = parseHTML(item.facilities).body.textContent || null;

            return (
              // item.SearchResult.Rooms[1].map((room: any) => room.meal_name).includes('All Inclusive') ||
              filters.holidayType.every((facility: string) => parseString !== null ? parseString.includes(facility) : false)
            );
            // } else {
            //   return filters.holidayType.some((facility: string) => parseString !== null ? parseString.includes(facility) : false);
            // }
          });
        }

        // * Sorting data
        if (filters.sorting === 'Popularity') {
          filteredData = filteredData
            .slice()
            .sort(
              (a: any, b: any) =>
                parseInt(a.HotelInfo.preferredOnSites) - parseInt(b.HotelInfo.preferredOnSites)
            );
        }
        if (filters.sorting === 'Price (low to high)') {
          filteredData = filteredData.slice().sort((a: any, b: any) => {

            const aData = sortRoom(a.SearchResult, filters);
            const bData = sortRoom(b.SearchResult, filters);

            let aPrice: any = 0
            if (aData.length > 0) {
              aData.map((f: any) => {
                aPrice = aPrice + Number(f.total_package)
                return <></>
              })
            }
            const aTotalPrice = aPrice / totalGuest

            let bPrice: any = 0
            if (bData.length > 0) {
              bData.map((f: any) => {
                bPrice = bPrice + Number(f.total_package)
                return <></>
              })
            }

            const bTotalPrice = bPrice / totalGuest

            return (
              aTotalPrice -
              bTotalPrice
            );
          });
        }

        if (filters.sorting === 'Price (high to low)') {
          filteredData = filteredData.slice().sort((a: any, b: any) => {

            const aData = sortRoom(a.SearchResult, filters);
            const bData = sortRoom(b.SearchResult, filters);

            let aPrice: any = 0
            if (aData.length > 0) {
              aData.map((f: any) => {
                aPrice = aPrice + Number(f.total_package)
                return <></>
              })
            }

            let bPrice: any = 0
            if (bData.length > 0) {
              bData.map((f: any) => {
                bPrice = bPrice + Number(f.total_package)
                return <></>
              })
            }

            const aTotalPrice = aPrice / totalGuest
            const bTotalPrice = bPrice / totalGuest

            return (
              bTotalPrice - aTotalPrice
            );
          });
        }

        data.Packages.results = filteredData;
        dispatch(setFilteredHolidayPackage(data));
        // dispatch(setLoading(false));
      }, 500);
    }
  }, [filters]);

  const randomId = function (length = 6) {
    return Math.random().toString(36).substring(2, length + 2);
  };

  const handleShowHotelsOnMap = () => {
    dispatch(setHolidayDetailsState({
      ...holiday,
      guest: flightRoom.searchCriteria.guest,
    }))
    dispatch(setHotelId([]));
    navigate(`/${randomId()}${window.location.search}`)
    dispatch(setDialogState({ ...dialogState, mapDialog: true }));
  };

  useEffect(() => {
    getPosition(filteredHolidayPackage, false, false)
  }, [])

  const getPosition = (data?: any, isButtonClicked?: boolean, filterApply?: any) => {
    const element = elementRef.current;
    if (element) {
      const rect = (element as any).getBoundingClientRect();
      const pos = {
        top: rect.top,
        left: rect.left,
      }
      if (window.innerWidth < 1025 && window.innerWidth > 769) {
        if (filterApply !== true) {
          dispatch(setPosition(pos))
        }
        setTimeout(() => {
          window.scrollTo({
            top: filterApply ? position.top : !loading && data?.Packages?.results?.length > 0 ? pos.top : 120,
            behavior: 'smooth',
          });
        }, 5)
      } else if (window.innerWidth < 769) {
        if (filterApply !== true) {
          dispatch(setPosition(pos))
        }
        setTimeout(() => {
          window.scrollTo({
            top: filterApply ? position.top : !loading && data?.Packages?.results?.length > 0 ? pos.top : 64,
            behavior: 'smooth',
          });
        }, 5)
      } else {
        if (!isButtonClicked) {
          setTimeout(() => {
            window.scrollTo({
              top: !loading && data?.Packages?.results?.length > 0 ? 435 : 200,
              behavior: 'smooth',
            });
          }, 5)
        }
      }
    }
  }
  const queryData = useMemo(() => {
    const search = window.location.search
    const urlSearchParams = new URLSearchParams(search);
    const queryData: any = {};
    urlSearchParams.forEach((value, key) => {
      queryData[key] = value;
    });
    return queryData
  }, [])

  return (
    <Box mt={{ xs: 1, lg: '35px', sm: '20px' }}>
      <Box>
        <Box bgcolor={colors.basics.secondary} sx={{ pb: { md: '35px', xs: '20px' }, pt: { md: '35px', xs: '20px' } }}>
          <CustomContainer style={{ maxWidth: '1340px !important' }}>
            <Box>
              <Typography variant='h1' fontSize='28px' fontWeight={600} lineHeight='44px' mb='15px'>
                Find your perfect holiday
              </Typography>
              {window.location.host.includes("localhost") && <h2 style={{ marginBottom: "10px" }}> Search date after 4 months</h2>}
              <FindHolidayWidget
                setIsHolidayApiCall={setIsHolidayApiCall}
                setIsClickedFilters={setIsClickedFilters}
                getPosition={getPosition}
              />
            </Box>
          </CustomContainer>
        </Box>
        <Box mt={{ md: '35px', sm: '0px', xs: '0' }} ref={elementRef}>
          <CustomContainer>
            <Box display='flex'>
              {holidayPackage?.Packages?.results?.length > 0 && <Box
                maxWidth='288px'
                width='100%'
                display={{ xs: 'none', lg: 'block' }}
                position='sticky'
                // top={headerHeight.desktop}
                top={'122px'}
                mb={1}
                ref={filterBoxRef}
                mr='20px'
              >
                <FilterComponent getPosition={getPosition} isClickedFilters={isClickedFilters} setIsClickedFilters={setIsClickedFilters} />
              </Box>}
              <Box width='100%' mr={{ lg: '0' }}>
                {filteredHolidayPackage && Object.keys(filteredHolidayPackage)?.length !== 0 ? (
                  <>
                    <Box display='flex' alignItems='center' sx={{
                      justifyContent: { xs: 'center', sm: filteredHolidayPackage?.Packages?.results?.length !== 0 ? 'space-between' : 'flex-end' },
                      position: { xs: 'sticky', lg: 'static' },
                      top: { xs: windowWidth < 376 ? "183px" : "120px", sm: '100px', lg: 'auto' },
                      backgroundColor: 'white',
                      zIndex: 10,
                      pb: { xs: '10px', sm: "10px", lg: 0 },
                      pt: { xs: '10px', sm: "24px", lg: 0 },
                      mb: { xs: 0, sm: '14px' },
                      '& .MuiInputBase-root': {
                        mt: "0 !important"
                      },
                      '& .custom-dropdown': {
                        marginTop: 0,
                      },
                    }}>
                      {filteredHolidayPackage?.Packages?.results?.length !== 0 &&
                        <Box>
                          <CustomSelectInput
                            className={'dropdown-menu-sort'}
                            menu={windowWidth > 599 ? false : true}
                            ids="options"
                            boxStyle={{ minWidth: windowWidth < 600 ? "107px" : "212px" }}
                            data={['Popularity', 'Price (low to high)', 'Price (high to low)']}
                            onChange={(e: any, n: any, v: any) => {
                              setSessionStorage("click", "false")
                              dispatch(setFilterState({ ...filters, sorting: v }));
                            }}
                            value={filters?.sorting}
                            StartIcon={
                              <>
                                <Box fontSize={"16px"} display={{ xs: 'flex', sm: 'none' }} sx={{
                                  color: `${colors.basics.primary} !important`,
                                  '& svg': {
                                    fill: `${colors.basics.primary} !important`
                                  },

                                }} alignItems={'center'} onClick={() => setMenu(!menu)}>
                                  <SwapVertIcon />
                                  Sort
                                </Box>
                                <Typography variant='h3' fontWeight={500} display={{ xs: 'none', sm: 'block' }}>
                                  Sort by:
                                </Typography>
                              </>
                            }
                          // style={{ fontSize: '16px', maxWidth: '100px' }}
                          />
                        </Box>
                      }
                      <Box display='flex' sx={{
                        gap: { xs: '40px', sm: 0 },
                        '& .MuiButton-root': {
                          marginTop: "0"
                        }
                      }} >
                        {holidayPackage?.Packages?.results?.length > 0 &&
                          <Button
                            sx={{
                              mt: { sm: '10px', xs: '0' },
                              outline: 0,
                              border: { xs: '0 !important', sm: '1px solid !important' },
                              p: { xs: 0, sm: '14px 23px' },
                              color: { xs: '#000', sm: '#e10f3f' },
                              display: { xs: 'flex', lg: 'none' },
                              mr: { xs: 0, sm: '17px' },
                              maxHeight: '48px',
                              backgroundColor: colors.basics.white,
                              ml: 1,
                            }}
                            variant='outlined'
                            onClick={() => {
                              setSessionStorage("click", "true")
                              dispatch(setDialogState({ ...dialogState, filterDialog: true }))
                            }
                            }
                          >
                            <Box display={{ xs: 'flex', sm: 'none' }} alignItems={'center'} sx={{
                              color: `${colors.basics.primary} !important`,
                              '& svg': {
                                fill: `${colors.basics.primary} !important`
                              }
                            }} gap={1} >
                              <TuneIcon />
                              Filter
                            </Box>
                            <Typography
                              variant='body2'
                              color={colors.basics.primary}
                              display={{ xs: 'none', sm: 'block' }}
                            >
                              Filter by
                            </Typography>
                          </Button>
                        }
                        {/* {!loading && filteredHolidayPackage?.Packages?.results?.length > 0 &&
                          <Button
                            variant='outlined'
                            onClick={() => dispatch(setDialogState({ ...dialogState, mapDialog: true }))}
                            sx={{
                              mt: { sm: '10px', xs: '0' },
                              outline: 0,
                              border: { xs: '0 !important', sm: '1px solid !important' },
                              p: { xs: 0, sm: '14px 23px' },
                              color: { xs: '#000', sm: '#e10f3f' },
                              display: { xs: 'flex', sm: 'none' },
                              mr: { xs: ' 5px', sm: '17px' },
                              maxHeight: '48px',
                              backgroundColor: colors.basics.white,
                            }}
                          >
                            <Box display={{ xs: 'flex', sm: 'none' }} sx={{
                              color: `${colors.basics.primary} !important`,
                              '& svg': {
                                fill: `${colors.basics.primary} !important`

                              }
                            }} alignItems={'center'} gap={1} onClick={handleShowHotelsOnMap} >
                              <MapIcon />
                              Map
                            </Box>
                          </Button>
                        }
                        {!loading && filteredHolidayPackage?.Packages?.results?.length > 0 && <Button
                          variant='text'
                          onClick={handleShowHotelsOnMap}
                          sx={{
                            p: 1,
                            minWidth: 'auto',
                            alignSelf: 'center',
                            display: { xs: 'none', sm: 'flex' },
                            mt: { sm: '10px', xs: '0' },
                          }}
                          endIcon={<LocationIcon />}
                          disableRipple={true}
                        >
                          <Typography
                            variant='body1'
                            fontWeight={500}
                            display={{ xs: 'none', sm: 'block' }}
                            // mt={{ sm: '10px', xs: '0' }}
                            sx={(theme) => ({ color: theme.palette.primary.main })}

                          >
                            Show on map
                          </Typography>
                        </Button>} */}
                      </Box>
                    </Box>
                    {filteredHolidayPackage?.Packages?.results?.length !== 0 ? (
                      <Grid container>
                        {filteredHolidayPackage?.Packages?.results?.map((item: any, index: number) => {
                          return (
                            <PackageCard
                              // elementRef={elementRef}
                              psw_result_id={
                                filteredHolidayPackage?.FlightDetails?.outbound?.flights[0].psw_result_id
                              }
                              psw_result_id_combo={
                                filteredHolidayPackage?.FlightDetails?.inbound?.flights[0].psw_result_id
                              }
                              item={item}
                              key={index}
                            />
                          );
                        })}
                      </Grid>
                    ) : (
                      !loading &&
                      <>
                        <Typography variant='h1' marginTop={{
                          sm: 0, xs: "20px"
                        }} textAlign='center' color={colors.basics.primary} >
                          Unfortunately, no results were returned for the package you have searched.
                        </Typography>
                        <Typography variant='h1' marginBottom={{
                          sm: 0, xs: "20px"
                        }} textAlign='center' color={colors.basics.primary} >
                          Please search again for alternatives.
                        </Typography>
                      </>
                    )}
                  </>
                ) : (
                  !loading && <>
                    <Typography mt={{ xs: 6, sm: 5 }}
                      // marginTop={{
                      //   sm: 0, xs: "20px"
                      // }}
                      variant='h1' textAlign='center' color={colors.basics.primary} >
                      {isHolidayApiCall || queryData.UAT == '0' ? "Unfortunately, no results were returned for the package you have searched." : "Please complete the form above to explore our range of holidays."}
                    </Typography>
                    <Typography mb={{ xs: 6, sm: 5 }}
                      // marginTop={{
                      //   sm: 0, xs: "20px"
                      // }}
                      variant='h1' textAlign='center' color={colors.basics.primary} >
                      {isHolidayApiCall || queryData.UAT == '0' ? "Please search again for alternatives." : ""}
                    </Typography>
                  </>
                )
                }
              </Box >

            </Box >
          </CustomContainer >
        </Box >
      </Box >
    </Box >
  );
};
