import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../api/services";
import { API_METHOD } from "../../api/services/apiMethods";

interface CityProps {
  label: string;
  id: string;
  cc: string;
}

interface AvailibilityProps {
  searchCriteriaId: number;
  newResult: any[];
  sorted_result: any[];
}

interface State {
  cities: any[];
  selectedCity: CityProps | null;
  availability: AvailibilityProps | null;
  availabilityLoading: boolean;
  availabilityResults: any[];
  availabilityCriteria: any[];
  detail: any[];
  carQty: any;
  driverAge: any;
}

const initialState: State = {
  cities: [],
  selectedCity: null,
  availability: null,
  availabilityLoading: false,
  availabilityResults: [],
  availabilityCriteria: [],
  detail: [],
  carQty: null,
  driverAge: "25+",
};

export const fetchCarCities: any = createAsyncThunk("carHire/fetchCarCities", async (res) => {
  const response = await API.post(API_METHOD.Car.CAR_CITIES, res);
  return response.data;
});

export const getCarHireAvailability: any = createAsyncThunk("carHire/getCarHireAvailability", async (res) => {
  const response = await API.post(API_METHOD.Car.CAR_HIRE_AVAILABILITY, res);
  return response.data;
});

export const getCarHireAvailabilityResult: any = createAsyncThunk(
  "carHire/getCarHireAvailabilityResult",
  async (res) => {
    const response = await API.post(API_METHOD.Car.CAR_HIRE_AVAILABILITY_RESULTS, res);
    return response.data;
  }
);

export const getCarHireAvailabilityCriteria: any = createAsyncThunk(
  "carHire/getCarHireAvailabilityCriteria",
  async (res) => {
    const response = await API.post(API_METHOD.Car.CAR_HIRE_AVAILABILITY_CRITERIA, res);
    return response.data;
  }
);

export const getCarHireDetails: any = createAsyncThunk("carHire/getCarHireDetails", async (res) => {
  const response = await API.post(API_METHOD.Car.CAR_HIRE_DETAILS, res);
  return response.data;
});

const carHireSlice = createSlice({
  name: "carHire",
  initialState,
  reducers: {
    setCarQtyFilter: (state, { payload }) => {
      state.carQty = payload || state.carQty;
    },
    setDriverAgeFilter: (state, { payload }) => {
      state.driverAge = payload || state.driverAge;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCarCities.pending, (state) => {
        state.cities = [];
      })
      .addCase(fetchCarCities.fulfilled, (state, action) => {
        state.cities = action.payload;
        state.selectedCity = action.payload[0];
      })
      .addCase(fetchCarCities.rejected, (state) => {
        state.cities = [];
      })
      .addCase(getCarHireAvailability.pending, (state) => {
        state.availability = null;
        state.availabilityLoading = true;
      })
      .addCase(getCarHireAvailability.fulfilled, (state, action) => {
        state.availability = action.payload;
        state.availabilityLoading = false;
      })
      .addCase(getCarHireAvailability.rejected, (state) => {
        state.availability = null;
        state.availabilityLoading = false;
      })
      .addCase(getCarHireAvailabilityResult.pending, (state) => {
        state.availabilityResults = [];
      })
      .addCase(getCarHireAvailabilityResult.fulfilled, (state, action) => {
        state.availabilityResults = action.payload;
      })
      .addCase(getCarHireAvailabilityResult.rejected, (state) => {
        state.availabilityResults = [];
      })
      .addCase(getCarHireAvailabilityCriteria.pending, (state) => {
        state.availabilityCriteria = [];
      })
      .addCase(getCarHireAvailabilityCriteria.fulfilled, (state, action) => {
        state.availabilityCriteria = action.payload;
      })
      .addCase(getCarHireAvailabilityCriteria.rejected, (state) => {
        state.availabilityCriteria = [];
      })
      .addCase(getCarHireDetails.pending, (state) => {
        state.detail = [];
      })
      .addCase(getCarHireDetails.fulfilled, (state, action) => {
        state.detail = action.payload;
      })
      .addCase(getCarHireDetails.rejected, (state) => {
        state.detail = [];
      });
  },
});

export default carHireSlice.reducer;

export const { setCarQtyFilter, setDriverAgeFilter } = carHireSlice.actions;
