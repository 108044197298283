import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as Euro } from '../../../assets/icons/Euro.svg';
import { ReactComponent as Dollar } from '../../../assets/icons/Dollar.svg';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
// import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { ReactComponent as KeyboardDoubleArrowRightIcon } from '../../../assets/icons/DoubleArrow.svg';
import { ReactComponent as Bed } from '../../../assets/icons/Bed.svg';
import { ReactComponent as Building } from '../../../assets/icons/BuildingOutline.svg';
import { ReactComponent as Calender } from '../../../assets/icons/Calender.svg';
import { ReactComponent as Car } from '../../../assets/icons/Car.svg';
import { ReactComponent as Person } from '../../../assets/icons/Person.svg';
import { ReactComponent as Pound } from '../../../assets/icons/Pound.svg';
import { ReactComponent as Spoon } from '../../../assets/icons/Spoon.svg';
import { calculateDeposit } from '../../../helpers/deposit';
import { colors } from '../../../styles/colors';
import { roundPrice } from '../../../helpers/RoundPrice';
import { useAppSelector } from '../../../hooks/useAppReducer';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { isEmpty } from '../../../helpers/isEmpty';
import { setHolidayDetailsState } from '../../../features/holidaySummary/holidaySummarySlice';
import { addCommasToNumber } from '../../../helpers/commaSeparatedPrice';
import { getFlightTimesObjectHolidayPackage, sumTime } from '../../../helpers/TotalFlightTime';
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirstLetter';
import LuggageIcon from '@mui/icons-material/Luggage';
import { capitalizeFirstLetterEachWords } from '../../../helpers/capitalizeFirstLetterEachWords';
import { getSessionStorage } from '../../../helpers/storage';
import { RoomMealWrapper } from '../../../helpers/roomMealWrapper';
import useWindowWidth from '../../../hooks/useWindowWidth';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  fetchAsyncPaymentConfiguration,
  setDepositeConfiguration,
} from '../../../features/paymentConfiguration/paymentConfiguration';
import { ApplicationStateType } from '../../../features/store';
import customParseFormat from "dayjs/plugin/customParseFormat";
import { formatStringTime } from '../../../helpers/formatStringTime';
dayjs.extend(customParseFormat);

interface hotelDetailsProps {
  showButtons?: boolean;
  showButtonsAndFirstComponent?: () => void;
}

export const HotelDetails = (props: hotelDetailsProps) => {
  const { showButtons, showButtonsAndFirstComponent } = props;
  //! States
  const { flightRoom } = useAppSelector((state: ApplicationStateType) => state.flightRoom);
  const { holidayPackage } = useAppSelector((state) => state.holidayPackage);
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  // const { hotelDetails } = useAppSelector((state) => state.hotelDetails);
  const { depositeConfiguration } = useAppSelector((state) => state.depositeConfiguration);
  // const [deposit, setDeposit] = useState(0)

  const windowWidth = useWindowWidth();
  const [expandedHolidayDetails, setExpandedHolidayDetails] = useState(false);

  const inbound = getFlightTimesObjectHolidayPackage(holidayPackage?.FlightDetails?.inbound?.flights);
  const inboundTime = sumTime(inbound);
  const outbound = getFlightTimesObjectHolidayPackage(holidayPackage?.FlightDetails?.outbound?.flights);
  const outboundTime = sumTime(outbound);

  //* Guests count excluding infants
  const totalGuests = flightRoom.searchCriteria.adults + flightRoom.searchCriteria.childrens;
  const hotelDetails = holidayPackage?.Packages?.results?.filter(
    (f: any) => f?.SearchResult?.hotel_id === flightRoom?.hotel_id
  );
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL;
  const [deposit, setDeposit] = useState(0);
  // const isPlataniumHotel = Number(hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel) !== 0
  const isPlataniumHotel = hotelDetails?.length > 0 ? Number(hotelDetails[0]?.HotelInfo?.platinumHotel) !== 0 : false;
  const totalGuestsWithInfants: any = flightRoom.searchCriteria.adults + flightRoom.searchCriteria.childrens + flightRoom.searchCriteria.infants
  // const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL
  const [day, month, year] = flightRoom.searchCriteria.date[0].split("/");
  const formattedDate = `${year}-${month}-${day}`

  const flightPrice: any = useMemo(() => {
    let price: any = 0
    const flightPrice = getSessionStorage("stepper") === "0" ? "0" : flightRoom.diffPrice || "0"

    if (getSessionStorage("nextAndSelectedFlights") === "false") {
      price = Math.ceil(flightPrice)
    } else {
      if (flightPrice.includes("-")) {
        price = Math.floor(flightPrice);
      } else {
        price = Math.ceil(flightPrice)
      }
    }
    return price
  }, [flightRoom.diffPrice, getSessionStorage("stepper"), getSessionStorage("nextAndSelectedFlights")])

  const packagePricePerPerson: any = useMemo(() => {
    let price = 0
    if (flightRoom.multiRooms.length > 0) {
      flightRoom.multiRooms.map((f: any) => {
        price = price + Number(f?.total_package)
        return <></>
      })
    }
    const totalPrice = (price) / totalGuests
    return (
      String(totalPrice + flightPrice)
    );
  }, [flightRoom.multiRooms, totalGuests, flightPrice]);

  const additionalFlightPrice = useMemo(() => {
    let price = 0
    if (flightRoom.multiRooms.length > 0) {
      flightRoom.multiRooms.map((f: any) => {
        price = price + Number(f?.package_breakdown?.flight_tot_net_amt)
        return <></>
      })
    }

    return (
      price
    );
  }, [flightRoom.multiRooms])

  const totalPrice = useMemo(() => {
    return Number(roundPrice(String(packagePricePerPerson))) * totalGuests;
  }, [packagePricePerPerson, totalGuests, flightPrice]);

  //! Variables
  // const dispatch = useDispatch();

  useEffect(() => {
    const depositValue = calculateDeposit({
      tripDate: formattedDate || '',
      ticketingDate: Object.keys(holidayPackage).length > 0 && holidayPackage?.FlightDetails
        ? (holidayPackage.FlightDetails.outbound?.flights?.length > 0
          ? holidayPackage.FlightDetails.outbound?.flights[0]?.FlightPswResult.last_ticket_date
          : ""
        )
        : "",
      totalCost: totalPrice,
      totalGuests,
      totalGuestsWithInfants,
      flightPrice: additionalFlightPrice,
      depositeConfiguration
    });
    setDeposit(depositValue)
  }, [formattedDate, additionalFlightPrice, holidayPackage?.FlightDetails?.outbound?.flights, totalPrice, totalGuests, totalGuestsWithInfants, flightPrice, depositeConfiguration])

  const getBalanceDueDate = useCallback(() => {
    const beforeFullPaymentDueDays = Number(depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue) ? Number(depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue) : 0
    const todayDate = new Date()
    const tripDateDiff = dayjs(new Date(formattedDate)).diff(dayjs(), 'day'); // two date difference

    if (tripDateDiff > beforeFullPaymentDueDays) {
      const dateFormat = new Date(formattedDate)
      const subtractedDate = new Date(dateFormat.getTime() - (beforeFullPaymentDueDays * 24 * 60 * 60 * 1000))
      const returnDate = `${subtractedDate.getDate()}/${subtractedDate.getMonth() + 1}/${subtractedDate.getFullYear()}`
      return returnDate
    } else {
      const returnDate = `${todayDate.getDate()}/${todayDate.getMonth() + 1}/${todayDate.getFullYear()}`
      return returnDate
    }
  }, [formattedDate, depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue])

  //! Functions
  // useEffect(() => {
  //   const balaneDue = getBalanceDueDate()
  //   if (depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue) {
  //     // setTimeout(() => {
  //       dispatch(setHolidayDetailsState({
  //         ...holiday,
  //         deposit: deposit !== 0 ? `${signForPricing + addCommasToNumber(deposit)}` : signForPricing + addCommasToNumber(totalPrice),
  //         isDepositPayment: deposit !== 0 ? true : false,
  //         balance_due_date: balaneDue
  //       }))
  //     // }, 200)
  //   }
  //   // eslint-disable-next-line
  // }, [getBalanceDueDate(), deposit]);

  // useEffect(() => {
  //   if (depositeConfiguration === null) {
  //     (async () => {
  //       const res = await dispatch(fetchAsyncPaymentConfiguration())
  //       if (res?.payload) {
  //         dispatch(setDepositeConfiguration(res?.payload))
  //       }
  //     })();
  //   }
  // }, [])

  const nights = useMemo(() => {
    return flightRoom.multiRooms[0]?.days_spent;
  }, [flightRoom.multiRooms]);

  const hotelName = useMemo(() => {
    return holiday?.hotel_name;
  }, [holiday?.hotel_name]);

  const checkOutDate = useMemo(() => {
    const date = holiday?.packageDate?.checkout;
    if (date) {
      return dayjs(date).format('DD MMM, YYYY');
    } else return false;
  }, [holiday?.packageDate?.checkout]);

  const handleHolidayAccordionChange = () => {
    setExpandedHolidayDetails((prev) => !prev);
  };

  /**
   * @returns
   */

  return (
    // <Box
    //   sx={{
    //     '& svg': {
    //       fontSize: { sm: "1.7rem", xs: windowWidth < 375 ? '1.4rem' : '1.5rem' },
    //     },
    //     '& ul': {
    //       padding: '0',
    //       listStyle: 'none',
    //       '& li': {
    //         padding: { sm: '10px 18px', xs: '5px' },
    //         '& .content': {
    //           alignSelf: 'center'
    //         },
    //         '&:nth-of-type(odd)': {
    //           background: colors.grey[20],
    //         },
    //       },
    //     },
    //     '.Icon': {
    //       width: '100%',
    //       maxWidth: { sm: '40px', xs: "36px" },
    //     },
    //   }}
    // >
    //   <ul>
    //     <li>
    //       {isEmpty(holiday?.flight?.outbound[0]?.des_airport !== "") && <Box display='flex' flexDirection='column'>
    //         <Box display='flex' mb='6px'>
    //           <Box
    //             className='Icon'
    //             // mt={0.4}
    //             sx={{
    //               '& svg': {
    //                 fill: colors.basics.primary,
    //                 transform: 'rotate(-270deg)',
    //               },
    //             }}
    //           >
    //             <Tooltip title='Outbound'>
    //               <AirplanemodeActiveIcon />
    //             </Tooltip>
    //           </Box>
    //           <Box display={'flex'} alignItems={'center'}>
    //             <Typography variant='body1' fontSize={'18px'} sx={{ color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary }} fontWeight={500} lineHeight={'1.2'}>
    //               Outbound Flight
    //             </Typography>
    //           </Box>
    //         </Box>
    //         <Box display='flex' mb='14px'>
    //           <Box
    //             className='Icon'
    //             // mt={0.4}
    //             sx={{
    //               '& svg': {
    //                 fill: colors.basics.primary,
    //                 transform: 'rotate(-270deg)',
    //               },
    //             }}
    //           >
    //             {/* <Tooltip title='Outbound'>
    //               <AirplanemodeActiveIcon />
    //             </Tooltip> */}
    //           </Box>
    //           <Box className='content'>
    //             <Typography variant='body1' fontWeight={500} lineHeight={'1.2'}>
    //               {holiday?.flight?.outbound[0]?.outbound_date}  {isEmpty(holiday?.flight?.outbound[0]?.airline_name) !== "" && <>
    //                 {" - " + `${holiday?.flight?.outbound[0]?.airline_name + " - "} (${holiday?.flight?.outbound[0]?.airline_code})`}
    //               </>}
    //             </Typography>
    //             {/* <Typography variant='body1' fontWeight={500} lineHeight={'1.2'}>

    //             </Typography> */}

    //             {isEmpty(holiday.flight.outbound[0].dep_airport) !== "" && isEmpty(holiday.flight.outbound[0].des_airport) !== "" && <Typography
    //               variant='body1'
    //               fontWeight={500}
    //               lineHeight={'1.2'}
    //               // display={'flex'}
    //               // alignItems={'center'}
    //               sx={{
    //                 '& svg': {
    //                   display: "inline-block",
    //                   verticalAlign: "center",
    //                   height: { sm: "14px", xs: "12px" },
    //                   width: { sm: "14px", xs: "12px" },
    //                   ml: "4px"
    //                 }
    //               }}
    //             >
    //               {isEmpty(holiday.flight.outbound[0].flightDeparture) !== "" && " (" + isEmpty(holiday.flight.outbound[0].flightDeparture) + ") -  "}
    //               <span >{isEmpty(holiday.flight.outbound[0].dep_airport)} <KeyboardDoubleArrowRightIcon /></span>
    //               {' '}
    //             </Typography>
    //             }

    //             {isEmpty(holiday.flight.outbound[0].des_airport) !== "" && <Typography mb="8px" variant='body1' lineHeight={'1.2'}
    //               fontWeight={500}
    //             >
    //               {isEmpty(holiday.flight.outbound[0].arrival_time) !== "" && <span>{"(" + isEmpty(holiday.flight.outbound[0].arrival_time) + ") - "}</span>}
    //               {isEmpty(holiday.flight.outbound[0].des_airport) !== "" && <span>{isEmpty(holiday.flight.outbound[0].des_airport)}</span>}
    //             </Typography>}
    //             {isEmpty(holiday.flight.outbound[0].stop) !== "" && !holiday.flight.outbound[0].stop.includes("-1") && <Typography lineHeight={'1.2'} variant='body1' fontWeight={500} >
    //               {holiday?.selectedFlight?.outbound.length > 0 ? isEmpty(formatStringTime(holiday.outboundTime)) : formatStringTime(outboundTime)}  ({isEmpty(holiday.flight.outbound[0].stop)})
    //             </Typography>}
    //             <Typography variant="body1" fontWeight={500} lineHeight={1.2}>
    //               {Array.isArray(holiday?.flight?.outbound[0].layover) && (holiday?.flight?.outbound[0].layover?.filter((layover: any) => layover !== null)
    //                 .map((layover: any, index: any) => (
    //                   <span style={{ fontWeight: '500', lineHeight: '1.2' }} key={index}>
    //                     {index > 0 && <br />}
    //                     {`Layover ${index + 1} (${layover?.time
    //                       ?.replace(/0\s*hr\s*/g, '')                    // Remove "0 hr"
    //                       .replace(/\s*m/g, 'm')                         // Remove space before "m"
    //                       .replace(/(\d+)\s*hr/g, '$1hr')                // Ensure "1 hr" becomes "1hr"
    //                       .trim()                                        // Remove any leading/trailing spaces
    //                       }) `}
    //                     {/* - {layover?.layover_airport_name} ({layover?.code}) - {layover?.layover_airport_city} */}
    //                     -  {layover?.layover_airport_city} ({layover?.code})
    //                   </span>
    //                 )))}
    //             </Typography>
    //           </Box>
    //         </Box>

    //         <Box display='flex' mb='6px' >
    //           <Box
    //             className='Icon'
    //             // mt={0.4}
    //             sx={{
    //               '& svg': {
    //                 fill: colors.basics.primary,
    //                 transform: 'rotate(270deg)',
    //               },
    //             }}
    //           >
    //             <Tooltip title='Outbound'>
    //               <AirplanemodeActiveIcon />
    //             </Tooltip>
    //           </Box>
    //           <Box display={'flex'} alignItems={'center'}>
    //             <Typography variant='body1' fontSize={'18px'} sx={{ color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary }} fontWeight={500} lineHeight={'1.2'}>
    //               Inbound Flight
    //             </Typography>
    //           </Box>
    //         </Box>
    //         <Box display='flex'>
    //           <Box
    //             className='Icon'
    //             // mt={0.4}
    //             sx={{
    //               '& svg': {
    //                 fill: colors.basics.primary,
    //                 transform: 'rotate(270deg)',
    //               },
    //             }}
    //           >
    //             {/* <Tooltip title='Inbound'>
    //               <AirplanemodeActiveIcon />
    //             </Tooltip> */}
    //           </Box>

    //           <Box className='content'>
    //             <Typography variant='body1' fontWeight={500} lineHeight={'1.2'}>
    //               {holiday.flight.inbound[0].inbound_date + " "} {isEmpty(holiday?.flight?.inbound[0]?.airline_name) !== "" && <>
    //                 {" - " + `${holiday?.flight?.inbound[0]?.airline_name + " - "}  (${holiday?.flight?.inbound[0]?.airline_code})`}
    //               </>}
    //             </Typography>
    //             {/* <Typography variant='body1' fontWeight={500} lineHeight='1.2'>

    //             </Typography> */}

    //             {isEmpty(holiday.flight.inbound[0].des_airport) !== "" && isEmpty(holiday.flight.inbound[0].dep_airport) !== ""
    //               && <Typography variant='body1' fontWeight={500} lineHeight='1.2'
    //                 alignItems={'center'}
    //                 sx={{
    //                   '& svg': {
    //                     display: "inline-block",
    //                     verticalAlign: "center",
    //                     height: { sm: "14px", xs: "12px" },
    //                     width: { sm: "14px", xs: "12px" },
    //                     ml: "4px"
    //                   }
    //                 }}>
    //                 {isEmpty(holiday.flight.inbound[0].flightDeparture) !== "" && "(" + holiday.flight.inbound[0].flightDeparture + ") - "}
    //                 <span>{holiday.flight.inbound[0].dep_airport} <KeyboardDoubleArrowRightIcon /></span>
    //               </Typography>}

    //             {isEmpty(holiday.flight.inbound[0].des_airport) !== "" && <Typography mb="8px" variant='body1'
    //               fontWeight={500}
    //               lineHeight='1.2'>
    //               {isEmpty(holiday.flight.inbound[0].arrival_time) && <span>{"(" + isEmpty(holiday.flight.inbound[0].arrival_time) + ") - "}</span>}
    //               <span>{holiday.flight.inbound[0].des_airport}</span>
    //             </Typography>}

    //             {isEmpty(holiday.flight.inbound[0].stop) !== "" && !holiday.flight.inbound[0].stop.includes("-1") && <Typography lineHeight={'1.2'} variant='body1' fontWeight={500} >
    //               {holiday?.selectedFlight?.inbound.length > 0 ? isEmpty(formatStringTime(holiday.inboundTime)) : formatStringTime(inboundTime)} ({holiday.flight.inbound[0].stop})
    //             </Typography>}
    //             <Typography variant="body1" fontWeight={500} lineHeight={1.2}>
    //               {Array.isArray(holiday?.flight?.inbound[0].layover) && (holiday?.flight?.inbound[0].layover?.filter((layover: any) => layover !== null)
    //                 .map((layover: any, index: any) => (
    //                   <span style={{ fontWeight: '500', lineHeight: '1.2' }} key={index}>
    //                     {index > 0 && <br />}
    //                     {/* {`Layover ${index + 1} (${layover?.replace(/(\d+)\s*hr\s*(\d+)\s*m/g, '$1hr $2m')})`} */}
    //                     {`Layover ${index + 1} (${layover?.time
    //                       ?.replace(/0\s*hr\s*/g, '')                    // Remove "0 hr"
    //                       .replace(/\s*m/g, 'm')                         // Remove space before "m"
    //                       .replace(/(\d+)\s*hr/g, '$1hr')                // Ensure "1 hr" becomes "1hr"
    //                       .trim()                                        // Remove any leading/trailing spaces
    //                       }) `}
    //                     - {layover?.layover_airport_city} ({layover?.code})
    //                   </span>
    //                 )))}
    //             </Typography>
    //           </Box>

    //         </Box>
    //         <Box display={'flex'} mt={'5px'}>
    //           {isEmpty(holiday?.baggage) !== '' &&
    //             <Box
    //               className='Icon'
    //               mt={0.4}
    //               sx={{
    //                 '& svg': {
    //                   width: "26px",
    //                   height: "26px",
    //                   fill: colors.basics.primary,
    //                   // transform: 'rotate(-270deg)',
    //                 },
    //               }}>
    //               <Tooltip title='Baggage' >
    //                 <LuggageIcon />
    //               </Tooltip>
    //             </Box>}
    //           {isEmpty(holiday?.baggage) !== '' && <Box className='content'>
    //             <Typography variant='body1' fontWeight={500} lineHeight='1.2'>
    //               {/* {flightRoom?.selectedFlights.inbound.length > 0 ? flightRoom?.selectedFlights?.inbound[0].FlightPswFlight?.Baggage : ''} */}
    //               {holiday?.baggage.includes("kg") ? holiday?.baggage : holiday?.baggage.replace("K", "kg")}
    //             </Typography>
    //           </Box>}
    //         </Box>
    //       </Box>
    //       }
    //     </li>
    //     <li />
    //     <li>
    //       <Box display='flex' mb='10px'>
    //         <Box className='Icon ' mt={0.5}>
    //           <Tooltip title='Hotel'>
    //             <building />
    //           </Tooltip>
    //         </Box>
    //         <Box sx={{ display: "flex", alignItems: "center" }}>
    //           <Typography variant='body1' fontSize={'18px'} sx={{ color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary }} fontWeight={500} lineHeight={'1.2'}>Hotel Info</Typography>
    //         </Box>
    //       </Box>
    //       <Box display='flex' mb='14px'>
    //         <Box className='Icon ' mt={0.5}>
    //           {/* <Tooltip title='Hotel'>
    //             <building />
    //           </Tooltip> */}
    //         </Box>
    //         <Box className='content'>
    //           <Typography variant='body1' fontWeight={500} lineHeight='1.2'>
    //             {nights ? (
    //               <span>
    //                 {`${nights} nights at the`} <span style={{ textTransform: "capitalize" }} dangerouslySetInnerHTML={{ __html: isEmpty(hotelName) }} />
    //               </span>
    //             ) : (
    //               'Loading...'
    //             )}
    //           </Typography>
    //         </Box>
    //       </Box>
    //       <Box display='flex' mb='14px'>
    //         <Box className='Icon' mt={0.3}>
    //           <Tooltip title='Holiday Date'>
    //             <Calender />
    //           </Tooltip>
    //         </Box>
    //         <Box className='content' sx={{ paddingTop: '3px' }}>
    //           <Typography variant='body1' fontWeight={500} lineHeight='1.2'>
    //             <span>Check In: </span>
    //             <span>
    //               {holiday?.packageDate?.checkin} {" "}
    //             </span>
    //             <span>
    //               (1500 hrs)
    //             </span>
    //           </Typography>
    //           <Typography variant='body1' fontWeight={500} lineHeight='1.2'>
    //             <span>Checkout: </span>
    //             <span>
    //               {checkOutDate || 'Loading...'}{" "}
    //             </span>
    //             <span>
    //               (1000 hrs)
    //             </span>
    //           </Typography>
    //         </Box>
    //       </Box>

    //       <Box display='block' >
    //         {flightRoom?.multiRooms?.map((f: any, i: any) => {
    //           return (
    //             <React.Fragment key={i}>
    //               <Box display='flex' mb='5px'>
    //                 <Box alignItems={"center"} className='Icon' mt={0.3}>
    //                   <Tooltip title='Room'>
    //                     <Bed />
    //                   </Tooltip>
    //                 </Box>
    //                 <Box className='content' sx={{
    //                   paddingTop: '3px',
    //                   "& p:not(:last-child)": {
    //                     paddingBottom: '8px'
    //                   }
    //                 }}>
    //                   <Typography dangerouslySetInnerHTML={{ __html: `Room ${i + 1} -  ${capitalizeFirstLetter(f?.room_name)}` }} textTransform={"capitalize"} variant='body1' fontWeight={500} lineHeight='1.2'>

    //                   </Typography>
    //                 </Box>
    //               </Box >
    //               <Box alignItems={"center"} display={'flex'}>
    //                 <Box className='Icon' mt={0.4}>
    //                   <Tooltip title='Guest'>
    //                     <Person />
    //                   </Tooltip>
    //                 </Box>
    //                 <Box>
    //                   <Typography variant='body1' fontWeight={500} lineHeight='1.2'>
    //                     {flightRoom?.searchCriteria?.guest[i]?.adults + " Adults "}
    //                     {flightRoom?.searchCriteria?.guest[i]?.children?.length === 0
    //                       ? ''
    //                       : flightRoom?.searchCriteria?.guest[i]?.children?.length === 1
    //                         ? `${flightRoom?.searchCriteria?.guest[i]?.infants?.length === 0 ? "and" : ","} ${flightRoom?.searchCriteria?.guest[i]?.children?.length} Children `
    //                         : `${flightRoom?.searchCriteria?.guest[i]?.infants?.length === 0 ? "and" : ","} ${flightRoom?.searchCriteria?.guest[i]?.children?.length} Childrens `}
    //                     {`${flightRoom?.searchCriteria?.guest[i]?.infants.length > 0 ? "and " + flightRoom?.searchCriteria?.guest[i]?.infants?.length + (flightRoom?.searchCriteria?.guest[i]?.infants.length === 1 ? " Infant" : " Infants") : ""}`}

    //                   </Typography>
    //                 </Box>
    //               </Box>
    //               <Box alignItems={"center"} mt={0.5} mb={0.5} display={'flex'}>
    //                 <Box className='Icon' mt={0.3}>
    //                   <Tooltip title='Board'>
    //                     <Spoon />
    //                   </Tooltip>
    //                 </Box>
    //                 <Box className='content'>
    //                   <Box
    //                     sx={{
    //                       '& p': {
    //                         fontFamily: 'Raleway',
    //                         fontStyle: 'normal',
    //                         fontWeight: 500,
    //                         fontSize: { sm: 16, xs: 14 },
    //                         lineHeight: '19px',
    //                         color: colors.basics.black,
    //                       },
    //                       fontFamily: 'Raleway',
    //                       fontStyle: 'normal',
    //                       fontWeight: 500,
    //                       fontSize: { sm: 16, xs: 14 },
    //                       lineHeight: '19px',
    //                       color: colors.basics.black,
    //                       textTransform: "capitalize"
    //                     }}
    //                     dangerouslySetInnerHTML={{ __html: RoomMealWrapper(f?.meal_name?.toLowerCase()) || 'Loading...' }}
    //                   />
    //                 </Box>
    //               </Box>
    //             </React.Fragment>
    //           )
    //         })}
    //       </Box>
    //     </li>
    //     {holiday?.transfer?.transfer_type && <li />}
    //     {holiday?.transfer?.transfer_type ? (
    //       <li>
    //         <Box display={'flex'} mb='10px'>
    //           <Box className='Icon' mt={0.3}>
    //             <Tooltip title='Transfer'>
    //               <Car />
    //             </Tooltip>
    //           </Box>
    //           <Box display={'flex'} alignItems={'center'}>
    //             <Typography variant='body1' fontSize={'18px'} sx={{ color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary }} fontWeight={500} lineHeight={'1.2'}>
    //               Transfers
    //             </Typography>
    //           </Box>
    //         </Box>
    //         <Box display='flex' mb='10px'>
    //           <Box className='Icon' mt={0.3}>
    //             {/* <Tooltip title='Transfer'>
    //               <Car />
    //             </Tooltip> */}
    //           </Box>
    //           <Box className='content' >
    //             <Typography variant='body1' fontWeight={500} lineHeight='130%' mb='14px'>
    //               <span>Airport to Hotel </span>- <span> {capitalizeFirstLetterEachWords(holiday?.transfer?.transfer_type)}</span>
    //             </Typography>
    //             <Typography variant='body1' fontWeight={500} lineHeight='130%'>
    //               <span>Hotel to Airport </span>- <span> {capitalizeFirstLetterEachWords(holiday?.transfer?.transfer_type)}</span>
    //             </Typography>
    //           </Box>
    //         </Box>
    //       </li>
    //     ) : null}
    //     {/* <li /> */}
    //     {/* <li>
    //       <Box display='flex'>
    //         <Box className='Icon' sx={{
    //           '& g path:nth-of-type(2)': {
    //             fill: colors.basics.white + " !important"
    //           }
    //         }}>
    //           {process.env.REACT_APP_PROJECT_COUNTRY === "uk" && <Pound height={24} width={24} fill={colors.basics.primary} />}
    //           {process.env.REACT_APP_PROJECT_COUNTRY === "ie" && <Euro />}
    //           {process.env.REACT_APP_PROJECT_COUNTRY === "us" && <Dollar />}
    //         </Box>

    //         <Box className='content'>
    //           <Typography variant='body1' fontWeight={500} lineHeight='130%'>
    //             {deposit !== 0 ? `Due today: Deposit ${signForPricing + addCommasToNumber(deposit)}` : `Due today: Full Payment`}
    //           </Typography>
    //         </Box>
    //       </Box>
    //     </li > */}
    //     {/* <Typography variant='body1' fontWeight={500} lineHeight='130%'>
    //             Balance due May 11, 2022
    //           </Typography> */}
    //   </ul>

    //   {/* <Box
    //     mt={'20px'}
    //     sx={{
    //       '& .MuiButtonBase-root': {
    //         background: isPlataniumHotel ? `${colors.basics.plataniumGreenColor} !important` : `${colors.basics.primary} !important`,
    //         color: colors.basics.white,
    //         border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor} !important` : `1px solid ${colors.basics.primary} !important`,
    //         // background: colors.grey[30],
    //         '&:hover': {
    //           background: isPlataniumHotel ? `${colors.basics.plataniumGreenColor} !important` : `${colors.basics.primary} !important`,
    //           color: colors.basics.white,
    //           border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor} !important` : `1px solid ${colors.basics.primary} !important`,
    //         }
    //       },
    //       display: "flex",
    //       // display: showButtons === true ? "none" : "flex",
    //       width: "100%",
    //     }}
    //   >
    //     <Button
    //       id="customize-holiday-btn"
    //       onClick={showButtonsAndFirstComponent}
    //       sx={{ width: "100%", }}
    //       variant="outlined"
    //       className='btn-olb-customise-holiday'
    //     >
    //       Customise Your Holiday
    //     </Button>
    //   </Box> */}
    // </Box >
    <Box
      sx={{
        "& svg": {
          '& path': {
            fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary + " !important"
          },
          fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary + " !important"
        },
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${colors.grey[30]}`,
        borderRadius: "5px",
        background: colors.basics.secondary,
        boxShadow: "none",
        "& .MuiAccordion-root.Mui-expanded": {
          margin: "0 !important",
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          margin: "14px 0 !important",
        },
        "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
          minHeight: "auto",
        },
        "& .MuiAccordionSummary-expandIconWrapper": {
          display: "flex !important",
        },
        padding: { sm: "3px", md: "3px", xs: 0 },
        paddingTop: { sm: 1, md: 1, xs: 0 },
      }}
    >
      <Box
        sx={{
          padding: "10px 10px 0 10px",
          gap: 0.5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h1" fontWeight={500} mx={2} mb={2}>
          Your Holiday Summary
        </Typography>
        <Box display="flex" alignItems="center" gap={2} my={1} sx={{ mx: { sm: "24px", xs: "8px" } }}>
          <Building width={26} height={22} />
          <Typography variant="body1" width={"100%"} fontWeight={600}>
            {nights ? (
              <span>
                {`${nights} nights at the`}{" "}
                <span
                  style={{ textTransform: "capitalize" }}
                  dangerouslySetInnerHTML={{ __html: capitalizeFirstLetterEachWords(isEmpty(hotelName)) }}
                />
              </span>
            ) : (
              "Loading..."
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2} my={1} sx={{ mx: { sm: "24px", xs: "8px" } }}>
          <Calender width={24} height={24} />
          <Typography variant="body1" fontWeight={600}>
            {dayjs(holiday?.packageDate?.checkin).format("MMM DD, YYYY")} -{" "}
            {dayjs(holiday?.packageDate?.checkout).format("MMM DD, YYYY")}
          </Typography>
        </Box>
        <Box
          mx={{ sm: 1, x: 0 }}
          sx={{
            bgcolor: colors.grey[50],
            borderRadius: 1,
            padding: 2,
            display: { sm: "block", xs: "none", md: "block" },
          }}
        >
          {/* padding: expandedHolidayDetails ? "10px 10px 0 10px" : 2 above code */}
          <Box display="flex" alignItems="center" gap={2}>
            <Box
              className="Icon"
              // mt={0.4}
              sx={{
                "& svg": {
                  fill: colors.basics.primary,
                  transform: "rotate(-270deg)",
                },
              }}
            >
              <AirplanemodeActiveIcon />
            </Box>
            <Typography variant="body1" fontWeight={600}>
              {dayjs(holiday?.flight?.outbound[0]?.outbound_date).format("MMM DD")}{' '}
            </Typography>
          </Box>
          <Typography variant="body1" ml={5} fontWeight={600} sx={{
            display: "flex",
            '& svg': {
              display: 'inline-block',
              verticalAlign: 'center',
              ml: '8px',
            },
          }}>
            {/* {holiday.flight.outbound[0].dep_airport} <KeyboardArrowRightIcon width={1} height={1} /> {holiday.flight.inbound[0].dep_airport} <br /> */}
            {/* {holiday?.selectedFlight?.outbound.length > 0
              ? isEmpty(formatStringTime(holiday.outboundTime))
              : formatStringTime(outboundTime)} */}
            {/* ({isEmpty(holiday.flight.outbound[0].stop)})  */}
            <span style={{ display: "inline-block", alignItems: 'center' }}>{holiday.flight.outbound[0].dep_airport}
              <KeyboardDoubleArrowRightIcon />
              {holiday.flight.inbound[0].dep_airport} </span>
          </Typography>
          <Typography variant="body1" ml={5} fontWeight={600}>
            {/* {holiday?.selectedFlight?.outbound.length > 0
              ? isEmpty(formatStringTime(holiday.outboundTime))
              : formatStringTime(outboundTime)} */}
            {holiday?.outboundTime?.replace(/(\d+)\s?hr/g, '$1hr')?.replace(/\s?m/g, 'm')}
          </Typography>
          {/* <Typography variant="body1" mt={1} ml={4} fontWeight={600}>
            {Array.isArray(holiday?.flight?.outbound[0].layover) && (holiday?.flight?.outbound[0].layover?.filter((layover: any) => layover !== null)
              .map((layover: any, index: any) => (
                <span style={{ lineHeight: '1.2' }} key={index}>
                  {index > 0 && <br />}
                  {`Layover ${index + 1} (${layover?.time
                    ?.replace(/0\s*hr\s*g, '') '/g'
                    .replace(/\s*m/g, 'm')
                    .replace(/(\d+)\s*hr/g, '$1hr')
                    .trim()
                    }) `}
                  -  {layover?.layover_airport_city} ({layover?.code})
                </span>
              )))}
          </Typography> */}

          <Box display="flex" alignItems="center" gap={2} mt={2}>
            <Box
              className="Icon"
              // mt={0.4}
              sx={{
                "& svg": {
                  fill: colors.basics.primary,
                  transform: "rotate(270deg)",
                },
              }}
            >
              <AirplanemodeActiveIcon />
            </Box>
            <Typography variant="body1" fontWeight={600}>
              {dayjs(holiday.flight.inbound[0].inbound_date).format("MMM DD")}{' '}
            </Typography>
          </Box>
          {/* <Typography variant="body1" ml={5} fontWeight={600}>
            {holiday.flight.inbound[0].dep_airport} <KeyboardArrowRightIcon /> {holiday.flight.outbound[0].dep_airport} <br />
            {holiday?.selectedFlight?.inbound.length > 0
              ? isEmpty(formatStringTime(holiday.inboundTime))
              : formatStringTime(inboundTime)}
            {/* ({holiday.flight.inbound[0].stop}) 
          </Typography> */}
          <Typography variant="body1" ml={5} fontWeight={600} sx={{
            display: "flex",
            '& svg': {
              display: 'inline-block',
              verticalAlign: 'center',
              // height: { sm: '24px', xs: '12px' },
              // width: { sm: '24px', xs: '12px' },
              ml: '8px',
            },
          }}>
            {/* {holiday.flight.inbound[0].dep_airport} <KeyboardArrowRightIcon width={1} height={1} /> {holiday.flight.outbound[0].dep_airport} <br /> */}
            {/* {holiday?.selectedFlight?.outbound.length > 0
              ? isEmpty(formatStringTime(holiday.outboundTime))
              : formatStringTime(outboundTime)} */}
            {/* ({isEmpty(holiday.flight.outbound[0].stop)})  */}
            <span style={{ display: "inline-block", alignItems: 'center' }}>{holiday.flight.inbound[0].dep_airport} <KeyboardDoubleArrowRightIcon /> {holiday.flight.outbound[0].dep_airport} </span> <span style={{ display: "flex", alignItems: 'center' }}>  </span>

          </Typography>
          <Typography variant="body1" ml={5} fontWeight={600}>
            {/* {holiday?.selectedFlight?.inbound.length > 0
              ? isEmpty(formatStringTime(holiday.inboundTime))
              : formatStringTime(inboundTime)} */}
            {holiday?.inboundTime?.replace(/(\d+)\s?hr/g, '$1hr')?.replace(/\s?m/g, 'm')}
          </Typography>
          {/* <Typography variant="body1" mt={1} ml={4} fontWeight={600}>
            {Array.isArray(holiday?.flight?.inbound[0].layover) && (holiday?.flight?.inbound[0].layover?.filter((layover: any) => layover !== null)
              .map((layover: any, index: any) => (
                <span style={{ lineHeight: '1.2' }} key={index}>
                  {index > 0 && <br />}
                  {`Layover ${index + 1} (${layover?.time
                    ?.replace(/0\s*hr\s*g, '')        '/g'            // Remove "0 hr"
                    .replace(/\s*m/g, 'm')                         // Remove space before "m"
                    .replace(/(\d+)\s*hr/g, '$1hr')                // Ensure "1 hr" becomes "1hr"
                    .trim()                                        // Remove any leading/trailing spaces
                    }) `}
                  - {layover?.layover_airport_city} ({layover?.code})
                </span>
              )))}

          </Typography> */}
        </Box>
        <Box display="flex" gap={2} my={1} sx={{ mx: { sm: "16px", xs: "8px" } }} flexDirection={"column"}>
          {flightRoom?.multiRooms?.map((f: any, i: any) => (
            <>
              <Box key={i} display="flex" justifyContent={"space-between"}>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <Box
                    ml={{ sm: 1, xs: 0 }}
                  // sx={{
                  //   ":first-of-type": {
                  //     display: "flex",
                  //   },
                  //   display: "none",
                  // }}
                  >
                    <Bed width={26} height={26} />
                  </Box>
                  <Typography
                    variant="body1"
                    fontWeight={600}
                    dangerouslySetInnerHTML={{ __html: `${capitalizeFirstLetter(f?.room_name.toLowerCase())}` }} // ${i + 1}
                  ></Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <Person />
                  <Typography variant="body1" fontWeight={600}>
                    {flightRoom?.searchCriteria?.guest[i]?.adults +
                      flightRoom?.searchCriteria?.guest[i]?.children?.length +
                      flightRoom?.searchCriteria?.guest[i]?.infants.length}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  mx: { sm: -1, xs: -1 },
                  mb: "1px",
                  bgcolor: colors.grey[50],
                  padding: 1,
                  borderRadius: 1,
                  alignItems: "center",
                  display: { sm: "flex", xs: expandedHolidayDetails ? "flex" : "none", md: "flex" },
                }}
                gap={2}
              >
                <Box sx={{ marginLeft: { sm: 1.5, xs: 0.5 }, marginRight: { sm: 0.5, xs: 0.5 } }}>
                  <Spoon />
                </Box>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html:
                      RoomMealWrapper(capitalizeFirstLetter(f?.meal_name?.toLowerCase())) || "Loading...",
                  }}
                  variant="body1"
                  fontWeight={600}
                ></Typography>
              </Box>
            </>
          ))}
        </Box>
        {/* {holiday?.transfer?.transfer_type ? (
          <Box
            mx={3}
            sx={{ display: { sm: "flex", xs: "none", md: "flex" } }}
            alignItems="start"
            gap={2}
            my={1}
          >
            <Car />
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography variant="body1" fontWeight={600}>
                Airport to hotel - {capitalizeFirstLetterEachWords(holiday?.transfer?.transfer_type)}
              </Typography>
              <Typography variant="body1" fontWeight={600}>
                Hotel to airport - {capitalizeFirstLetterEachWords(holiday?.transfer?.transfer_type)}
              </Typography>
            </Box>
          </Box>
        ) : null} */}
        <Box
          className="Icon"
          mx={3}
          sx={{
            display: {
              sm: "flex",
              xs: "none",
              md: "flex",
              "& svg": {
                "& path": {
                  fill: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor + " !important"
                    : colors.basics.primary + " !important",
                },
                fill: isPlataniumHotel
                  ? colors.basics.plataniumGreenColor + " !important"
                  : colors.basics.primary + " !important",
              },
              "& g path:nth-of-type(2)": {
                fill: colors.basics.white + " !important",
              },
            },
          }}
          alignItems="start"
          gap={2}
          my={1}
        >
          {process.env.REACT_APP_PROJECT_COUNTRY === "uk" && (
            <Pound height={24} width={24} fill={colors.basics.primary} />
          )}
          {process.env.REACT_APP_PROJECT_COUNTRY === "ie" && <Euro />}
          {process.env.REACT_APP_PROJECT_COUNTRY === "us" && <Dollar />}
      
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="body1" fontWeight={600}>
              {deposit !== 0
                ? `Due today: Deposit ${signForPricing + addCommasToNumber(deposit)}`
                : `Due today: Full Payment`}
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              {/* {`Balance due: ${dayjs(holiday?.balance_due_date).format("MMM DD, YYYY")}`} */}
              {/* {`Balance due: ${dayjs(holiday?.balance_due_date, "M/D/YYYY").format("MMM DD, YYYY")}`} */}
              {deposit !== 0 && `Balance due: ${dayjs(holiday?.balance_due_date, "D/M/YYYY").format("MMM DD, YYYY")}`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Accordion
        expanded={expandedHolidayDetails}
        onChange={handleHolidayAccordionChange}
        sx={{ background: colors.basics.secondary, display: { sm: "none", xs: "flex", md: "none" }, flexDirection: "column-reverse" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ borderTop: `${expandedHolidayDetails ? `2px solid ${colors.grey[30]}` : "none"} ` }}
        >
          <Typography variant="body1" fontWeight={500}>
            {expandedHolidayDetails ? "Hide" : "Show"} holiday details
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "#f9f9f9" }}>
          <Box sx={{ px: { sm: '6px', xs: "0" } }}>
            <Box sx={{ bgcolor: colors.grey[50], borderRadius: 1, padding: "8px", my: 1, mx: { sm: "12px", xs: "0px" } }} >
              <Box display="flex" alignItems="center" gap={2}>
                <Box
                  className="Icon"
                  // mt={0.4}
                  sx={{
                    "& svg": {
                      fill: colors.basics.primary,
                      transform: "rotate(-270deg)",
                    },
                  }}
                >
                  <AirplanemodeActiveIcon />
                </Box>
                <Typography variant="body1" fontWeight={600}>
                  {dayjs(holiday?.flight?.outbound[0]?.outbound_date).format("MMM DD")}{' '}
                </Typography>
              </Box>
              {/* <Typography variant="body1" ml={5} fontWeight={600}>
                {holiday.flight.outbound[0].dep_airport} <KeyboardArrowRightIcon /> {holiday.flight.inbound[0].dep_airport} <br />
                {holiday?.selectedFlight?.outbound.length > 0
                  ? isEmpty(formatStringTime(holiday.outboundTime))
                  : formatStringTime(outboundTime)}
                {/* ({isEmpty(holiday.flight.outbound[0].stop)}) 
              </Typography> */}
              {/* <Typography sx={{ display: 'flex', alignItems: 'center' }} variant="body1" ml={5} fontWeight={600}>
                {holiday.flight.outbound[0].dep_airport} <KeyboardArrowRightIcon width={1} height={1} /> {holiday.flight.inbound[0].dep_airport} <br />
                {/* {holiday?.selectedFlight?.outbound.length > 0
              ? isEmpty(formatStringTime(holiday.outboundTime))
              : formatStringTime(outboundTime)} */}
              {/* ({isEmpty(holiday.flight.outbound[0].stop)}) 
              </Typography> */}
              <Typography variant="body1" ml={5} fontWeight={600} sx={{
                display: "flex",
                '& svg': {
                  display: 'inline-block',
                  verticalAlign: 'center',
                  // height: { sm: '24px', xs: '12px' },
                  // width: { sm: '24px', xs: '12px' },
                  ml: '8px',
                },
              }}>
                <span style={{ display: "inline-block", alignItems: 'center' }}>{holiday.flight.outbound[0].dep_airport} <KeyboardDoubleArrowRightIcon /> {holiday.flight.inbound[0].dep_airport} </span>


                {/* {holiday?.selectedFlight?.outbound.length > 0
              ? isEmpty(formatStringTime(holiday.outboundTime))
              : formatStringTime(outboundTime)} */}
                {/* ({isEmpty(holiday.flight.outbound[0].stop)})  */}
              </Typography>
              <Typography variant="body1" ml={5} fontWeight={600}>
                {holiday?.selectedFlight?.outbound.length > 0
                  ? isEmpty(formatStringTime(holiday.outboundTime))
                  : formatStringTime(outboundTime)}
              </Typography>
              {/* <Typography variant="body2" mt={1} ml={4} fontWeight={600}>
                {Array.isArray(holiday?.flight?.outbound[0].layover) && (holiday?.flight?.outbound[0].layover?.filter((layover: any) => layover !== null)
                  .map((layover: any, index: any) => (
                    <span style={{ lineHeight: '1.2' }} key={index}>
                      {index > 0 && <br />}
                      {`Layover ${index + 1} (${layover?.time
                        ?.replace(/0\s*hr\s*g, '')       '/g'             // Remove "0 hr"
                        .replace(/\s*m/g, 'm')                         // Remove space before "m"
                        .replace(/(\d+)\s*hr/g, '$1hr')                // Ensure "1 hr" becomes "1hr"
                        .trim()                                        // Remove any leading/trailing spaces
                        }) `}
                      {/* - {layover?.layover_airport_name} ({layover?.code}) - {layover?.layover_airport_city}
                      -  {layover?.layover_airport_city} ({layover?.code})
                    </span>
                  )))}
              </Typography> */}

              <Box display="flex" alignItems="center" gap={2} mt={2}>
                <Box
                  className="Icon"
                  // mt={0.4}
                  sx={{
                    "& svg": {
                      fill: colors.basics.primary,
                      transform: "rotate(270deg)",
                    },
                  }}
                >
                  <AirplanemodeActiveIcon />
                </Box>
                <Typography variant="body1" fontWeight={600}>
                  {dayjs(holiday.flight.inbound[0].inbound_date).format("MMM DD")}{' '}
                </Typography>
              </Box>
              {/* <Typography variant="body1" ml={5} fontWeight={600}>
                {holiday.flight.inbound[0].dep_airport} <KeyboardArrowRightIcon /> {holiday.flight.outbound[0].dep_airport} <br />
                {holiday?.selectedFlight?.inbound.length > 0
                  ? isEmpty(formatStringTime(holiday.inboundTime))
                  : formatStringTime(inboundTime)}
                {/* ({holiday.flight.inbound[0].stop}) 
              </Typography> */}
              <Typography sx={{ display: 'flex', alignItems: 'center' }} variant="body1" ml={5} fontWeight={600}>
                {holiday.flight.inbound[0].dep_airport} <KeyboardArrowRightIcon width={1} height={1} /> {holiday.flight.outbound[0].dep_airport} <br />
                {/* {holiday?.selectedFlight?.outbound.length > 0
              ? isEmpty(formatStringTime(holiday.outboundTime))
              : formatStringTime(outboundTime)} */}
                {/* ({isEmpty(holiday.flight.outbound[0].stop)})  */}
              </Typography>
              <Typography variant="body1" ml={5} fontWeight={600}>
                {holiday?.selectedFlight?.inbound.length > 0
                  ? isEmpty(formatStringTime(holiday.inboundTime))
                  : formatStringTime(inboundTime)}
              </Typography>
              {/* <Typography variant="body2" mt={1} ml={4} fontWeight={600}>
                {Array.isArray(holiday?.flight?.inbound[0].layover) && (holiday?.flight?.inbound[0].layover?.filter((layover: any) => layover !== null)
                  .map((layover: any, index: any) => (
                    <span style={{ lineHeight: '1.2' }} key={index}>
                      {index > 0 && <br />}
                      {/* {`Layover ${index + 1} (${layover?.replace(/(\d+)\s*hr\s*(\d+)\s*m/g, '$1hr $2m')})`}
                      {`Layover ${index + 1} (${layover?.time
                        ?.replace(/0\s*hr\s*g, '')       '/g'             // Remove "0 hr"
                        .replace(/\s*m/g, 'm')                         // Remove space before "m"
                        .replace(/(\d+)\s*hr/g, '$1hr')                // Ensure "1 hr" becomes "1hr"
                        .trim()                                        // Remove any leading/trailing spaces
                        }) `}
                      - {layover?.layover_airport_city} ({layover?.code})
                    </span>
                  )))}

              </Typography> */}
            </Box>
            <Box
              // mx={2.5}
              sx={{
                mx: { sm: "20px", xs: "8px" },
                display: "flex",
                "& svg": {
                  "& path": {
                    fill: isPlataniumHotel
                      ? colors.basics.plataniumGreenColor + " !important"
                      : colors.basics.primary + " !important",
                  },
                  fill: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor + " !important"
                    : colors.basics.primary + " !important",
                },
                "& g path:nth-of-type(2)": {
                  fill: colors.basics.white + " !important",
                },

              }}
              alignItems="start"
              gap={2}
              mt={2}
            >
              {process.env.REACT_APP_PROJECT_COUNTRY === "uk" && (
                <Pound height={24} width={24} fill={colors.basics.primary} />
              )}
              {process.env.REACT_APP_PROJECT_COUNTRY === "ie" && <Euro />}
              {process.env.REACT_APP_PROJECT_COUNTRY === "us" && <Dollar />}
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography variant="body1" fontWeight={600}>
                  {deposit !== 0
                    ? `Due today: Deposit ${signForPricing + addCommasToNumber(deposit)}`
                    : `Due today: Full Payment`}
                </Typography>
                <Typography variant="body1" fontWeight={600}>
                  {/* {`Balance due: ${dayjs(holiday?.balance_due_date).format("MMM DD, YYYY")}`} */}
                  {deposit !== 0 && `Balance due: ${dayjs(holiday?.balance_due_date, "D/M/YYYY").format("MMM DD, YYYY")}`}
                </Typography>

              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box >
  );
};
