import { createSlice } from "@reduxjs/toolkit";

interface state {
    staticSiteAccordianData: {
        accordions: { title: string; sortOrder: number }[],
        importantInfo: string,
        videoURL: string | undefined | null
    }
}

const initialState: state = {
    staticSiteAccordianData: {
        accordions: [],
        importantInfo: "",
        videoURL: ""
    }
};

const staticSiteAccordianDataSlicer = createSlice({
    name: 'slider',
    initialState,
    reducers: {
        setStaticSiteAccordianContent: (state, { payload }): void => {
            state.staticSiteAccordianData = payload;
        },

    }
});

export const { setStaticSiteAccordianContent } = staticSiteAccordianDataSlicer.actions;
export default staticSiteAccordianDataSlicer.reducer;