import { Typography, Box, FormControlLabel, Checkbox, Button } from '@mui/material';
import React, { useState } from 'react';
import { CustomContainer } from '../../components/CustomContainer';
import { TextInput } from '../../components/TextInput';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { ReactComponent as FormOutlined } from '../../assets/icons/FormOutlined.svg';
import { colors } from '../../styles/colors';
import { ReactComponent as GreenTick } from '../../assets/icons/GreenTick.svg';
import useWindowWidth from '../../hooks/useWindowWidth';

export default function Signin() {
  //! Hooks
  const [accountCreated, setAccountCreated] = useState(false);
  const windowWidth = useWindowWidth();
  if (accountCreated) {
    return (
      <Box pt={{ xs: windowWidth < 376 ? '130px' : '70px', xl: "0px", lg: '20px', md: '30px', sm: '44px' }}>
        <Box bgcolor={colors.basics.secondary}>
          <CustomContainer>
            <Box display='flex' p={{ sm: '30px 40px', xs: '30px 10px' }} mt='20px' mb='30px'>
              <Box mr={{ md: '22px', xs: '10px' }}>
                <GreenTick />
              </Box>
              <Box>
                <Box mb='30px'>
                  <Typography variant='h2' fontSize='35px' fontWeight={600} lineHeight={'42px'}>
                    ACCOUNT SUCCESSFULLY CREATED
                  </Typography>
                </Box>

                <Box mb='30px' sx={{ display: { sm: 'flex', xs: 'block' } }}>
                  <Typography variant='body1' fontSize='16px' fontWeight={400}>
                    Great - you've successfully created an account with us.
                    <br />
                    To get up and running, click the link in the email we've just sent you.
                    <br />
                    If you can't see the email in your inbox, be sure to check your junk mail box.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CustomContainer>
        </Box>
        <CustomContainer>
          <Box p={{ sm: '30px 40px', xs: '30px 10px' }} display='flex' ml={{ sm: '62px' }} flexWrap='wrap'>
            <Button
              variant='outlined'
              sx={{ mr: { sm: 2 }, width: { xs: '100%', sm: 'auto' }, mb: { xs: 2, sm: 0 } }}
            >
              Return to Booking
            </Button>
            <Button sx={{ width: { xs: '100%', sm: 'auto' } }}>Login to your Account</Button>
          </Box>
        </CustomContainer>
      </Box>
    );
  }

  return (
    <Box bgcolor={colors.basics.secondary}>
      <CustomContainer style={{ marginTop: '71px', marginBottom: '71px' }}>
        <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
          <Box m={{ md: '51px' }} mr={{ xl: '0px' }} width={{ xs: '100%', xl: 'auto' }}>
            <Typography variant='h1' fontSize='35px' fontWeight={700} lineHeight='42px'>
              Create account
            </Typography>
            <Typography variant='h1'>Members can access saved quotes and special features</Typography>
            <Box my='66px'>
              <form>
                <TextInput title='Title' />
                <TextInput
                  title='FIRST NAME (as per passpoort)'
                  style={{ marginTop: '25px', marginBottom: '25px' }}
                />
                <TextInput title='LAST NAME' style={{ marginTop: '25px', marginBottom: '25px' }} />
                <TextInput
                  title='EMAIL ADDRESS'
                  style={{ marginTop: '25px', marginBottom: '25px' }}
                  type='email'
                />
                <TextInput
                  title='PASSWORD'
                  type='password'
                  style={{ marginTop: '25px', marginBottom: '22px' }}
                />
                <FormControlLabel
                  sx={{
                    ml: 0,
                    '& .MuiTypography-body1': {
                      fontWeight: 300,
                      fontSize: '14px',
                    },
                  }}
                  control={<Checkbox sx={{ pr: '12px' }} disableRipple />}
                  label='Show password'
                />
                <Typography variant='body2' sx={{ fontWeight: 300, mt: '16px', mb: '26px' }}>
                  Signing up means you agree to our website terms and conditions
                </Typography>
                <FormControlLabel
                  sx={{
                    ml: 0,
                    '& .MuiTypography-body1': {
                      fontWeight: 300,
                      fontSize: '14px',
                    },
                  }}
                  control={<Checkbox sx={{ pr: '12px' }} disableRipple />}
                  label='If you would like to receive email marketing from us, please tick here'
                />
                <Box display='flex' justifyContent='space-between' mt='50px'>
                  <Button onClick={() => setAccountCreated(true)}>Create Account</Button>
                  <Button variant='outlined'>Return to Booking</Button>
                </Box>
              </form>
            </Box>
          </Box>
          <Box m={{ md: '51px' }} ml={{ xl: '0px' }} width={{ xs: '100%', xl: 'auto' }}>
            <Box bgcolor={colors.basics.white} py='22px' px='30px'>
              <Typography variant='h1' fontSize='25px' mb={3} ml='35px'>
                WHY GET AN ACCOUNT
              </Typography>
              <Box display='flex' alignItems='center'>
                <FavoriteBorderIcon
                  sx={{ width: 16, height: 16, color: colors.basics.primary, mr: '19px' }}
                />
                <Typography variant='body1'>Save and view your quotes from any device</Typography>
              </Box>
              <Box display='flex' my={2} alignItems='center'>
                <SettingsOutlinedIcon
                  sx={{ width: 16, height: 16, color: colors.basics.primary, mr: '19px' }}
                />
                <Typography variant='body1'>Manage your bookings anytime</Typography>
              </Box>
              <Box display='flex' alignItems='center'>
                <FormOutlined style={{ width: 16, height: 16, marginRight: '19px' }} />
                <Typography variant='body1'>Enter your passport details and check in online</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </CustomContainer>
    </Box>
  );
}
