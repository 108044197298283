// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { colors } from '../../../styles/colors';
import { TabComponent } from '../../../components/TabComponent';
import { TabPanel } from '../../../components/TabPanel';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Slider,
  SelectChangeEvent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import CustomSelectInput from '../../../components/CustomSelectInput';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import LuggageIcon from '@mui/icons-material/Luggage';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { ReactComponent as RightChevron } from '../../../assets/icons/RightChervon.svg';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppReducer';
import { getSessionStorage, setSessionStorage } from '../../../helpers/storage';
import { setFlightRoomState } from '../../../features/flightRoom/flightRoomSlice';
import { setLoading } from '../../../features/loading/loadingSlice';
import { getFlightTimesObject, singleFlightTravelTime, sumTime } from '../../../helpers/TotalFlightTime';
import { formatTime } from '../../../helpers/formatTime';
import dayjs from 'dayjs';
import { isEmpty } from '../../../helpers/isEmpty';
import { setHolidayDetailsState } from '../../../features/holidaySummary/holidaySummarySlice';
import FlightDetailsAccordion from './FlightDetailsAccordion';
import { ReactComponent as CloseModal } from '../../../assets/icons/CloseModal.svg';
import useWindowWidth from '../../../hooks/useWindowWidth';
// import FlightDetailsInboundAccordion from './FlightDetailsInboundAccordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TuneIcon from '@mui/icons-material/Tune';
import useOutsideClick from '../../../hooks/useOutsideClick';
const InnerAccordion = React.lazy(() => import('./FlightDetailsInnerAccordion'));
interface flightDetailsProps {
  expanded: any;
  setDisableButton?: any;
  setCurrentTab: (tab: string) => void;
  currentTab: string;
  isDirect: boolean;
  setDisabledFlightTabsInbound: (tabs: boolean) => void;
  disabledFlightTabsInbound: boolean;
  openChangeFlightDrawer?: boolean;
  setOpenChangeFlightDrawer?: any;
  setIsDirect?: any;
  // handleConfirmSelectedFlight?: any;
  setActiveIndex?: any;
  setOnChangeMultiRoom?: any;
  showPreviousComponent?: any;
  // handleSelectFlights?: any;
  setonlyShowFlights?: any;
  onlyShowFlights?: any;
  getHolidayPackageRetrieveData?: any;
  getChangeFlight?: any;
  saveBasket?: any;
  sortRoom?: any;
  isRetrieveBasketApiCall?: any;
  retrieveholidayBasketRetrieve?: any;
  inboundSorting: any;
  setInboundSorting: any;
  setFirstTimeLoad: any;
  firstTimeLoad: any;
  setContiuneToBookClick: any;
  contiuneToBookClick: any;
  setIsLoading: any;
  isLoading: any;
  setSaveRooms: any;
  idToAdd?: any;
}

export const TabType = {
  Outbound: 'Outbound',
  Inbound: 'Inbound',
};

type StopType = 'Direct' | '1 Stop' | '2+ Stops';
interface Stop {
  label: StopType;
  value: number;
}

interface Flights {
  data: [
    {
      combo: string[];
      inboundFlight: object[];
      outboundFlight?: [{ FlightPswFlight: any }];
      psw_result_id: string;
      targetSelfId: string;
    }
  ];
  filters: {
    airlines: string[];
  };
}

interface FlightData {
  FlightPswFlight?: {
    airline_name: string | null;
  };
}

interface depTime {
  day: string;
  time: string;
}

const DepartureTime = [
  {
    time: '00:00 - 10:59',
    day: 'Morning Flights',
  },
  {
    time: '11:00 - 14:59',
    day: 'Day Flights',
  },
  {
    time: '15:00 - 18:59',
    day: 'Evening Flights',
  },
  {
    time: '19:00 - 23:59',
    day: 'Night Flights',
  },
];
const DepartureTimeInbound = [
  {
    time: '00:00 - 10:59',
    day: 'Morning Flights',
  },
  {
    time: '11:00 - 14:59',
    day: 'Day Flights',
  },
  {
    time: '15:00 - 18:59',
    day: 'Evening Flights',
  },
  {
    time: '19:00 - 23:59',
    day: 'Night Flights',
  },
];

interface FlightStop {
  Outbound: string[];
  Inbound: string[];
}

type Tabs = 'Outbound' | 'Inbound';

const ChangeFlightDrawer = ({
  contiuneToBookClick,
  setContiuneToBookClick,
  openChangeFlightDrawer,

  getHolidayPackageRetrieveData,
  setOpenChangeFlightDrawer,
  expanded,
  disabledFlightTabsInbound,
  setDisabledFlightTabsInbound,
  setDisableButton,
  setCurrentTab,
  currentTab,
  isDirect,
  setIsDirect,
  setActiveIndex,
  // handleConfirmSelectedFlight,
  setOnChangeMultiRoom,
  showPreviousComponent,
  // handleSelectFlights,
  onlyShowFlights,
  setonlyShowFlights,
  getChangeFlight,
  saveBasket,
  sortRoom,
  isRetrieveBasketApiCall,
  retrieveholidayBasketRetrieve,
  inboundSorting,
  setInboundSorting,
  setFirstTimeLoad,
  firstTimeLoad,
  setIsLoading,
  isLoading,
  setSaveRooms,
  idToAdd,
}: flightDetailsProps) => {
  const { flightRoom } = useAppSelector((state) => state.flightRoom);
  // console.log('flightRoom******* :', flightRoom);
  // console.log('flightRoom :', flightRoom);
  const { loading } = useAppSelector((state: any) => state.loading);
  // console.log('loading :', loading);
  const { flightOptions }: any = useAppSelector((state) => state.changeFlight);
  // console.log('flightOptions :', flightOptions);
  // console.log('flightOptions :', flightOptions);
  const { hotelDetails } = useAppSelector((state) => state.hotelDetails);
  //! Hooks
  const airlineRef = useRef<HTMLDivElement>(null);
  const departureAirportRef = useRef<HTMLDivElement>(null);
  const stopRef = useRef<HTMLDivElement>(null);
  const packageRef = useRef<HTMLDivElement>(null);
  const departureTimeOutboundRef = useRef<HTMLDivElement>(null);
  const departureTimeInboundRef = useRef<HTMLDivElement>(null);
  const durationInboundRef = useRef<HTMLDivElement>(null);
  const durationOutboundRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (clickedRef?: React.RefObject<HTMLElement>) => {
    const dropdowns = [
      airlineRef,
      departureAirportRef,
      stopRef,
      packageRef,
      departureTimeOutboundRef,
      departureTimeInboundRef,
      durationInboundRef,
      durationOutboundRef,
    ];

    dropdowns.forEach((ref) => {
      if (ref.current) {
        if (clickedRef && ref === clickedRef) {
          return; // Keep the clicked dropdown open
        }
        // Remove 'show' class from all nested elements
        const showElements = ref.current.querySelectorAll('.show');
        showElements.forEach((element) => element.classList.remove('show'));
      }
    });
  };

  // useOutsideClick({
  //   refs: [airlineRef, departureAirportRef, stopRef, departureTimeOutboundRef, departureTimeInboundRef, durationInboundRef, durationOutboundRef],
  //   // callback: () => {
  //   //   [airlineRef, departureAirportRef, stopRef, departureTimeOutboundRef, departureTimeInboundRef, durationInboundRef, durationOutboundRef].forEach(ref => {
  //   //     if (ref.current) {
  //   // const showElements = ref.current.querySelectorAll('.show');
  //   // showElements.forEach(element => element.classList.remove('show'));
  //   //     }
  //   //   });
  //   // },
  //   callback: handleOutsideClick,
  // });

  //* Filters States--------------------------------------------------------------
  const [airline, setAirline] = useState<string[]>([]);
  const [airlineFilterOptions, setAirlineFilterOptions] = useState<string[]>([]);
  const [departure, setDeparture] = useState<string>(flightRoom?.searchCriteria?.departure?.full_name);
  const [departureAirport, setDepartureAirport] = useState<string[]>([]);
  const [packageType, setPackageType] = useState<string[]>([]);
  const [duration, setDuration] = useState<number[]>([0, 5819]);
  const [durationInbound, setDurationInbound] = useState<number[]>([0, 5819]);
  const [minMaxSlider, setMinMaxSlider] = useState([0, 5819]);
  const [minMaxSliderInbound, setMinMaxSliderInbound] = useState([0, 5819]);
  const [departureTime, setDepartureTime] = useState<string[]>([]);
  const [departureTimeInbound, setDepartureTimeInbound] = useState<string[]>([]);
  const [renderInitial, setRenderInitially] = useState(true);
  const [renderInitialInbound, setRenderInitiallyInbound] = useState(true);
  const [flightStops, setFlightStops] = useState<FlightStop>({
    Outbound: [],
    Inbound: [],
  });

  // console.log('flightStops :', flightStops);

  const [isRender, setisRender] = useState(true);
  const [dynamicDepartureTimes, setDynamicDepartureTimes] = useState(DepartureTime);
  const [dynamicDepartureTimesInbound, setDynamicDepartureTimesInbound] = useState(DepartureTimeInbound);
  // console.log('dynamicDepartureTimesInbound :', dynamicDepartureTimesInbound);
  const [onChangeInbound, setOnChangeInbound] = useState(false);
  const [isFlightDataLoad, setIsFlightDataLoad] = useState(false);
  const [stopsRender, setStopsRender] = useState<any>(true);
  const [expandedHolidayDetails, setExpandedHolidayDetails] = useState(false);
  // console.log('stopsRender :', stopsRender);
  const [departureAirportNames, setDepartureAirportNames] = useState<string[]>([]);
  //*-----------------------------------------------------------------------------

  const [selectedPswId, setSelectedPswId] = useState<any>(flightRoom.psw_result_id);
  // console.log('selectedPswId@@@@@ :', selectedPswId);
  const [selectedInboundFlight, setSelectedInboundFlight] = useState<any>(
    flightRoom?.selectedFlights?.inbound || []
  );
  // console.log('selectedInboundFlight :', selectedInboundFlight);
  const windowWidth = useWindowWidth();
  const [selectedOutboundFlight, setSelectedOutboundFlight] = useState<any>(
    flightRoom?.selectedFlights?.outbound || []
  );
  // console.log('selectedOutboundFlight :', selectedOutboundFlight);
  const [flights, setFlights] = useState<Flights>();

  // console.log('flights :', flights);
  const [innerAccordionExpanded, setInnerAccordionExpanded] = useState<number>(0);
  const [hourMinutes, setHourMinutes] = useState<any>([]);
  // console.log('hourMinutes :', hourMinutes);
  const [hourMinutesInbound, setHourMinutesInbound] = useState<any>([]);
  // console.log('hourMinutesInbound :', hourMinutesInbound);
  const [filteredFlights, setFilteredFlights] = useState<{ inbound: any[]; outbound: any[] }>({
    inbound: [],
    outbound: [],
  });
  // console.log('filteredFlights :', filteredFlights);
  // console.log('filteredFlights :', filteredFlights);

  const [filterNewFlightsData, setFilterNewFlightsData] = useState<{ inbound: any[]; outbound: any[] }>({
    inbound: [],
    outbound: [],
  });

  const [resetDisable, setResetDisable] = useState(false);
  const [matchedFlights, setMatchedFlights] = useState([]);
  const [flightPage, setFlightPage] = useState<number>(10);
  const [stops, setStops] = useState<any>([]);
  const [packages, setPackages] = useState<any>([]);
  const [renderAirlines, setRenderAirlines] = useState(false);
  const [storeFilters, setStoreFilters] = useState<any>({
    Outbound: {
      airline: [],
      departureAirport: [],
      departureTime: [],
      stops: [],
      packages: [],
      duration: [0, 5819],
      maxDuration: [0, 5819],
      stopsArray: [],
      departureTimeArr: [],
      departureAirports: [],
    },
    Inbound: {
      airline: [],
      departureAirport: [],
      departureTime: [],
      stops: [],
      packages: [],
      duration: [0, 5819],
      maxDuration: [0, 5819],
      stopsArray: [],
      departureTimeArr: [],
      departureAirports: [],
    },
  });
  // console.log('storeFilters :', storeFilters);
  /**
   * @var
   */
  const dispatch = useAppDispatch();
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  let outboundFlightIndex = 0;
  const departureRef = useRef(departure);
  const tabCurrent: any = useRef(null);
  const { cheapestFlightPSW } = flightRoom;
  // console.log('cheapestFlightPSW@ :', cheapestFlightPSW);

  const isPrevious = getSessionStorage('previous');
  const departureLocation =
    flightRoom?.searchCriteria?.departure &&
    flightRoom?.searchCriteria?.departure.depart_airport_name.split(' ')[0];
  const ArrivalLocation =
    flightRoom?.searchCriteria?.destination &&
    flightRoom?.searchCriteria?.destination['Display Name #1']?.split(', ')[0];
  const isPlataniumHotel = Number(hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel) !== 0;

  // useEffect(() => {
  //   setSelectedInboundFlight(flightRoom?.selectedFlights?.inbound)
  //   setSelectedOutboundFlight(flightRoom?.selectedFlights?.outbound)
  // }, [flightRoom])

  useEffect(() => {
    if (currentTab === 'Outbound' && isPrevious === 'true' && !isDirect) {
      setMinMaxSlider(storeFilters.Outbound.maxDuration);
      setDuration(storeFilters.Outbound.duration);

      // setMinMaxSliderInbound(storeFilters.Inbound.maxDuration);
      // setDurationInbound(storeFilters.Inbound.duration);

      setRenderInitially(false);
      setRenderInitiallyInbound(false);
    }
  }, [isPrevious, currentTab, isDirect, storeFilters.Outbound, storeFilters.Inbound]);

  const renderStops = (filteredFlights: any) => {
    let formattedStops: Stop[] = [];

    // if (currentTab === 'Inbound') {
    //   formattedStops = filteredFlights
    //     ?.filter((ele: any) => ele.psw_result_id === selectedPswId)
    //     .map((item: any) => {
    //       if (isEmpty(item.inboundFlight) !== '') {
    //         const value = item.inboundFlight.length - 1;
    //         if (value === 0) {
    //           return { label: 'Direct', value: 0 };
    //         } else if (value === 1) {
    //           return { label: '1 Stop', value: 1 };
    //         } else {
    //           return { label: '2+ Stops', value: 2 };
    //         }
    //       }
    //       return null;
    //     });
    // } else {
    formattedStops = filteredFlights.map((item: any) => {
      if (isEmpty(item.outboundFlight) !== '') {
        const value = item.outboundFlight.length - 1;
        if (value === 0) {
          return { label: 'Direct', value: 0 };
        } else if (value === 1) {
          return { label: '1 Stop', value: 1 };
        } else {
          return { label: '2+ Stops', value: 2 };
        }
      }
      return null;
    });
    // }

    const uniqueStops: any = Array.from(
      formattedStops
        .filter((f) => f !== null)
        .reduce((map, item) => {
          if (!map.has(item!.value)) {
            map.set(item!.value, item);
          }
          return map;
        }, new Map<number, { label: string; value: number }>())
        .values()
    );

    // console.log('uniqueStops :', uniqueStops);
    const sortedData = uniqueStops.sort((a: Stop, b: Stop) => {
      const order: any = {
        Direct: 0,
        '1 Stop': 1,
        '2+ Stops': 2,
      };

      return order[a.label] - order[b.label];
    });
    // console.log('sortedData :', sortedData);

    setStops([...sortedData]);
  };

  const renderPackages = (filteredFlights: any) => {
    let formattedDep: (string | null)[] = [];
    formattedDep = filteredFlights.map((item: any) => {
      if (isEmpty(item.outboundFlight) !== '') {
        return isEmpty(item.outboundFlight[0].FlightPswFlight?.Baggage);
      }
      return null;
    });

    const uniqueDept = new Set(
      formattedDep
        .filter((dep) => dep !== null && dep !== '' && dep !== '0p' && dep !== '0pc')
        .map((dep) => dep?.replace(/pc$/, 'p'))
    );
    setPackages(formattedDep.length === 0 ? [] : (Array.from(uniqueDept) as string[]));
  };

  const renderDepartureAirports = (filteredFlights: any) => {
    let formattedDep: (string | null)[] = [];

    // if (currentTab === 'Inbound') {
    //   formattedDep = filteredFlights
    //     ?.filter((ele: any) => ele.psw_result_id === selectedPswId)
    //     .map((item: any) => {
    //       if (isEmpty(item.inboundFlight) !== '') {
    //         return (
    //           isEmpty(item.inboundFlight[0].FlightPswFlight?.Airport?.depart_airport_name) +
    //           ' ' +
    //           isEmpty(item.inboundFlight[0].FlightPswFlight?.departure_airport)
    //         );
    //       }
    //       return null;
    //     });
    // } else {
    formattedDep = filteredFlights.map((item: any) => {
      if (isEmpty(item.outboundFlight) !== '') {
        return (
          isEmpty(item.outboundFlight[0].FlightPswFlight?.Airport?.depart_airport_name) +
          ' ' +
          isEmpty(item.outboundFlight[0].FlightPswFlight?.departure_airport)
        );
      }
      return null;
    });
    // }

    const uniqueDept = new Set(formattedDep.filter((dep) => dep !== null));
    setDepartureAirportNames(formattedDep.length === 0 ? [] : (Array.from(uniqueDept) as string[]));
  };

  const renderDepartureTime = (filteredFlights: any) => {
    const filterFlightsTime: depTime[] = [];

    const renderData = (flight: any) => {
      const departureTime: any = formatTime((flight as any).FlightPswFlight.departure_time);
      const timeSplit = departureTime.split(':');
      const removeLeadingZero = timeSplit[0].replace(/^0/, '');

      const flightTime = dayjs()
        .set('hour', Number(removeLeadingZero))
        .set('minute', timeSplit[1])
        .set('second', 0);

      DepartureTime.some((value: depTime) => {
        let timeSepration = value?.time?.split(' - ');

        const time1 = timeSepration[0];
        const time2 = timeSepration[1];

        const splitTime1 = time1.split(':');
        const splitTime2 = time2.split(':');
        const flightStartTime = dayjs().set('hour', Number(splitTime1[0])).set('minute', 0).set('second', 0);
        const flightEndTime = dayjs().set('hour', Number(splitTime2[0])).set('minute', 59).set('second', 59);

        if (flightTime.isAfter(flightStartTime) && flightTime.isBefore(flightEndTime)) {
          filterFlightsTime.push(value);
        }
      });
    };

    // if (currentTab === 'Inbound') {
    //   filteredFlights
    //     ?.filter((ele: any) => ele.psw_result_id === selectedPswId)
    //     .map((item: any) => {
    //       if (isEmpty(item.inboundFlight) !== '') {
    //         renderData(item.inboundFlight[0]);
    //       }
    //     });
    // }
    // else {
    filteredFlights.map((item: any) => {
      if (isEmpty(item.outboundFlight) !== '') {
        renderData(item.outboundFlight[0]);
      }
    });
    // }
    const uniqueArray: depTime[] = [];
    const storeTime: string[] = [];

    DepartureTime.map((v) => {
      filterFlightsTime
        .filter((e) => e !== undefined && e !== null)
        .map((e) => {
          if (e.time === v.time && !storeTime.includes(e.time)) {
            uniqueArray.push(e);
            storeTime.push(e.time);
          }
        });
    });

    setDynamicDepartureTimes(uniqueArray);
  };
  const renderDepartureTimeInbound = (filteredFlights: any) => {
    const filterFlightsTime: depTime[] = [];

    const renderData = (flight: any) => {
      const departureTime: any = formatTime((flight as any).FlightPswFlight.departure_time);
      const timeSplit = departureTime.split(':');
      const removeLeadingZero = timeSplit[0].replace(/^0/, '');

      const flightTime = dayjs()
        .set('hour', Number(removeLeadingZero))
        .set('minute', timeSplit[1])
        .set('second', 0);

      DepartureTimeInbound.some((value: depTime) => {
        let timeSepration = value?.time?.split(' - ');

        const time1 = timeSepration[0];
        const time2 = timeSepration[1];

        const splitTime1 = time1.split(':');
        const splitTime2 = time2.split(':');
        const flightStartTime = dayjs().set('hour', Number(splitTime1[0])).set('minute', 0).set('second', 0);
        const flightEndTime = dayjs().set('hour', Number(splitTime2[0])).set('minute', 59).set('second', 59);

        if (flightTime.isAfter(flightStartTime) && flightTime.isBefore(flightEndTime)) {
          filterFlightsTime.push(value);
        }
      });
    };

    // if (currentTab === 'Inbound') {
    // ?.filter((ele: any) => ele.psw_result_id === selectedPswId)
    filteredFlights.map((item: any) => {
      if (isEmpty(item.inboundFlight) !== '') {
        renderData(item.inboundFlight[0]);
      }
    });
    // }
    // else {
    //   filteredFlights.map((item: any) => {
    //     if (isEmpty(item.outboundFlight) !== '') {
    //       renderData(item.outboundFlight[0]);
    //     }
    //   });
    // }
    const uniqueArray: depTime[] = [];
    const storeTime: string[] = [];

    DepartureTimeInbound.map((v) => {
      filterFlightsTime
        .filter((e) => e !== undefined && e !== null)
        .map((e) => {
          if (e.time === v.time && !storeTime.includes(e.time)) {
            uniqueArray.push(e);
            storeTime.push(e.time);
          }
        });
    });

    setDynamicDepartureTimesInbound(uniqueArray);
  };

  useEffect(() => {
    if (filteredFlights?.outbound?.length > 0) {
      const calcualteTimeArr: any = [];
      // if (currentTab === 'Inbound') {
      // filteredFlights?.inbound
      //   .filter((ele: any) => ele.psw_result_id === selectedPswId)
      //   .map((item: any, i: number) => {
      //     if (isEmpty(item.inboundFlight) !== '') {
      //       const time = getFlightTimesObject(item.inboundFlight);
      //       calcualteTimeArr.push(sumTime(time));
      //     }
      //     return <></>;
      //   });
      // } else {
      filteredFlights?.outbound.map((item: any, num: number) => {
        if (isEmpty(item.outboundFlight) !== '') {
          const time = singleFlightTravelTime(
            item.inboundFlight[0].FlightPswFlight?.segment1_elapse_flying_time ?? 0
          );
          calcualteTimeArr.push(sumTime([time]));
        }
        return <></>;
      });
      // }

      const convertedTimes = calcualteTimeArr.map(convertToMinutes);
      // console.log('convertedTimes OUTBOUBDFVGHV :', convertedTimes);

      const maxTime = Math.max(...convertedTimes);
      // console.log('maxTime :', maxTime);
      const minTime = Math.min(...convertedTimes);
      // console.log('minTime :', minTime);

      const maxTimeFormat = formatTime(
        Math.floor(maxTime / 60)
          .toString()
          .padStart(2, '0') + (maxTime % 60).toString().padStart(2, '0')
      );
      const minTimeFormat = formatTime(
        Math.floor(minTime / 60)
          .toString()
          .padStart(2, '0') + (minTime % 60).toString().padStart(2, '0')
      );

      const maxValueForSlider = hourMinutes.filter((f: any) => f.label === maxTimeFormat);
      const minValueForSlider = hourMinutes.filter((f: any) => f.label === minTimeFormat);

      // if (currentTab === 'Outbound') {

      if (renderInitial) {
        // console.log("LOADDD");

        setRenderInitially(false);
        setMinMaxSlider([
          minValueForSlider[0]?.value || 0,
          isEmpty(maxValueForSlider[0]?.value) !== '' ? maxValueForSlider[0]?.value : 5819,
        ]);
        setDuration([
          minValueForSlider[0]?.value || 0,
          isEmpty(maxValueForSlider[0]?.value) !== '' ? maxValueForSlider[0]?.value : 5819,
        ]);
      }
      // }
      //  else {
      //   setTimeout(() => {
      //     if (
      //       renderInitial &&
      //       isEmpty(minValueForSlider[0]?.value) !== '' &&
      //       isEmpty(maxValueForSlider[0]?.value) !== ''
      //     ) {
      //       setRenderInitially(false);
      //       setMinMaxSlider([
      //         minValueForSlider[0]?.value || 0,
      //         isEmpty(maxValueForSlider[0].value) !== '' ? maxValueForSlider[0]?.value : 5819,
      //       ]);
      //       setDuration([
      //         minValueForSlider[0]?.value || 0,
      //         isEmpty(maxValueForSlider[0].value) !== '' ? maxValueForSlider[0]?.value : 5819,
      //       ]);
      //     }
      //   }, 2);
      // }
    }
    // eslint-disable-next-line
  }, [filteredFlights.outbound, selectedPswId, currentTab, renderInitial]);

  useEffect(() => {
    if (filteredFlights?.outbound?.length > 0) {
      const calcualteTimeArr: any = [];
      // if (currentTab === 'Inbound') {
      filteredFlights?.inbound
        // .filter((ele: any) => ele.psw_result_id === selectedPswId)
        .map((item: any, i: number) => {
          if (isEmpty(item.inboundFlight) !== '') {
            const time = singleFlightTravelTime(
              item.inboundFlight[0].FlightPswFlight?.segment2_elapse_flying_time ?? 0
            );
            // getFlightTimesObject(item.inboundFlight);
            calcualteTimeArr.push(sumTime([time]));
          }
          return <></>;
        });
      // } else {
      // filteredFlights?.outbound.map((item: any, num: number) => {
      //   if (isEmpty(item.outboundFlight) !== '') {
      //     const time = getFlightTimesObject(item.outboundFlight);
      //     calcualteTimeArr.push(sumTime(time));
      //   }
      //   return <></>;
      // });
      // }

      const convertedTimes = calcualteTimeArr.map(convertToMinutesIn);

      const maxTime = Math.max(...convertedTimes);
      // console.log('maxTime :', maxTime);
      const minTime = Math.min(...convertedTimes);
      // console.log('minTime :', minTime);

      const maxTimeFormat = formatTime(
        Math.floor(maxTime / 60)
          .toString()
          .padStart(2, '0') + (maxTime % 60).toString().padStart(2, '0')
      );
      const minTimeFormat = formatTime(
        Math.floor(minTime / 60)
          .toString()
          .padStart(2, '0') + (minTime % 60).toString().padStart(2, '0')
      );

      const maxValueForSlider = hourMinutesInbound.filter((f: any) => f.label === maxTimeFormat);
      // console.log('maxValueForSlider :', maxValueForSlider);
      const minValueForSlider = hourMinutesInbound.filter((f: any) => f.label === minTimeFormat);
      // console.log('minValueForSlider :', minValueForSlider);

      // if (currentTab === 'Outbound') {
      //   console.log("gdhj");

      //   if (renderInitial) {
      //     setRenderInitially(false);
      //     setMinMaxSlider([
      //       minValueForSlider[0]?.value || 0,
      //       isEmpty(maxValueForSlider[0]?.value) !== '' ? maxValueForSlider[0]?.value : 5819,
      //     ]);
      //     setDuration([
      //       minValueForSlider[0]?.value || 0,
      //       isEmpty(maxValueForSlider[0]?.value) !== '' ? maxValueForSlider[0]?.value : 5819,
      //     ]);
      //   }
      // } else {

      setTimeout(() => {
        if (
          renderInitialInbound &&
          isEmpty(minValueForSlider[0]?.value) !== '' &&
          isEmpty(maxValueForSlider[0]?.value) !== ''
        ) {
          // console.log("LOADDD!!!!");
          // setRenderInitially(false);
          setRenderInitiallyInbound(false);
          setMinMaxSliderInbound([
            minValueForSlider[0]?.value || 0,
            isEmpty(maxValueForSlider[0].value) !== '' ? maxValueForSlider[0]?.value : 5819,
          ]);
          setDurationInbound([
            minValueForSlider[0]?.value || 0,
            isEmpty(maxValueForSlider[0].value) !== '' ? maxValueForSlider[0]?.value : 5819,
          ]);
        }
      }, 2);
    }
    // }
    // eslint-disable-next-line
  }, [filteredFlights.inbound, selectedPswId, currentTab, renderInitialInbound]);

  useEffect(() => {
    setCurrentTab(TabType.Outbound);
    setDisabledFlightTabsInbound(true);
  }, [expanded]);

  // useEffect(() => {
  //   if (!flightOptions?.length) {
  //     console.log("FLIGHT OPTIONSSSSSSSSSS");

  //     setSelectedInboundFlight([]);
  //     setSelectedOutboundFlight([]);
  //     // setFilteredFlights([]);
  //     setFilteredFlights({
  //       inbound: [],
  //       outbound: [],
  //     });
  //   }
  // }, [flightOptions]);

  useEffect(() => {
    calculateHoursMinute();
    calculateHoursMinuteIN();
  }, []);

  const handleConfirmFlight = () => {
    //* Outbound to Redux

    if (selectedOutboundFlight?.length && selectedInboundFlight?.length) {
      // console.log("ISSUE---39")
      // debugger
      const updatedFlightRoomState: any = dispatch(
        setFlightRoomState({
          ...flightRoom,
          // psw_result_id: selectedOutboundFlight[0]?.FlightPswFlight?.psw_result_id,
          // psw_result_id_combo: selectedInboundFlight[0]?.FlightPswFlight?.psw_result_id,
          // store_psw_result_id: selectedOutboundFlight[0]?.FlightPswFlight?.psw_result_id,

          selectedFlights: { inbound: selectedInboundFlight, outbound: selectedOutboundFlight },
          storeSelectedFlights: { inbound: selectedInboundFlight, outbound: selectedOutboundFlight },
        })
      ).payload;
      // debugger
      const yourHolidaySummary = dispatch(
        setHolidayDetailsState({
          ...holiday,
          outboundTime: singleFlightTravelTime(
            selectedOutboundFlight?.[0]?.FlightPswFlight?.segment1_elapse_flying_time
          )
            ?.replace(/(\d+)\s?hr/g, '$1hr')
            .replace(/\s?m/g, 'm'),
          inboundTime: singleFlightTravelTime(
            selectedInboundFlight?.[0]?.FlightPswFlight?.segment2_elapse_flying_time
          )
            ?.replace(/(\d+)\s?hr/g, '$1hr')
            .replace(/\s?m/g, 'm'),
        })
      ).payload;

      return { updatedFlightRoomState, yourHolidaySummary };
    }
  };

  useEffect(() => {
    if (!flightRoom.selectedFlights.outbound?.length) {
      handleConfirmFlight();
    }
  }, [selectedOutboundFlight, flightRoom.selectedFlights.outbound, selectedInboundFlight]);

  useEffect(() => {
    // console.log("Flight OPtion");

    if (flightOptions?.length && flightOptions[3]) {
      let airlines: string[] = [];
      let flightArr: any = [];
      let myArrr: any =
        typeof flightOptions === 'object' &&
        Object.entries(flightOptions[3][1]).map((item: any, index: number) => {
          let flights: any = [];
          // if (index <= 5) {
          //* Outbound Flights
          let outboundIds: string[] = [];
          flights = Object.values(Object.entries(item[1]?.combos)).map((comboArr) => {
            let arr: any = {};

            if (!outboundIds.includes(String(comboArr[1]).split(',')[0])) {
              arr.outboundFlight = getOutboundFlights(
                flightOptions[3][0][item[0]],
                String(comboArr[1]).split(',')[0]
              );
              flightArr.push(
                getOutboundFlights(flightOptions[3][0][item[0]], String(comboArr[1]).split(',')[0])
              );
            }
            arr.targetSelfId = item[0];
            outboundIds.push(String(comboArr[1]).split(',')[0]);
            arr.psw_result_id = String(comboArr[1]).split(',')[0];
            // arr.psw_result_id = comboArr[1];

            arr.inboundFlight = getInboundFlights(
              flightOptions[3][0][item[0]],
              String(comboArr[1]).split(',')[1]
            );
            arr.combo = comboArr;
            arr?.outboundFlight?.forEach((flight: any) => {
              if (
                !airlines.includes(flight.FlightPswFlight.airline_name) &&
                isEmpty(flight.FlightPswFlight.airline_name) !== '' &&
                flight?.FlightPswFlight?.Baggage
              ) {
                airlines.push(flight.FlightPswFlight.airline_name);
              }
            });

            // arr?.inboundFlight?.forEach((flight: any) => {
            //   if (!airlines.includes(flight.FlightPswFlight.airline_name) && isEmpty(flight.FlightPswFlight.airline_name) !== "") {
            //     airlines.push(flight.FlightPswFlight.airline_name);
            //   }
            // });
            return arr;
          });
          // const outboundSet = new Set();
          // outboundFlights = outboundFlights.filter((item: any) => {
          //   const str = JSON.stringify(item);
          //   return !outboundSet.has(str) && outboundSet.add(str);
          // });
          // setSelectedPswId(outboundFlights[0].psw_result_id);
          // console.log({ outboundFlights });
          // }
          return flights;
        });
      // console.log(myArrr, 'myArrr');

      if (myArrr) {
        if (!selectedPswId) {
          if (myArrr) {
            // setSelectedPswId(myArrr?.[0][0]?.outboundFlight[0]?.FlightPswFlight?.psw_result_id);
            // setSelectedPswId(flightRoom.psw_result_id);
          }
        }
        // console.log("myArrr?.filter((item: any) => Boolean(item.length))?.flat()", myArrr?.filter((item: any) => Boolean(item.length))?.flat());

        setFlights({
          data: myArrr?.filter((item: any) => Boolean(item.length))?.flat(),
          filters: {
            airlines,
          },
        });
      }
      // dispatch(setLoading(false));
    }
  }, [flightOptions]);

  useEffect(() => {
    const airlineNames = new Set();
    // const departureAirport = new Set();
    // const baggageNames = new Set();
    // const outboundDepartureTime = new Set();
    // const inboundDepartureTime = new Set();
    // const outboundDuration = new Set();
    // const inboundDuration = new Set();
    if (!airline.length) {
      matchedFlights.forEach((flight: any) => {
        flight.outboundFlight?.outboundFlight?.forEach((segment: any) => {
          if (
            segment.FlightPswFlight?.airline_name &&
            segment?.FlightPswFlight?.Baggage !== '0p' &&
            segment?.FlightPswFlight?.Baggage !== '0pc' &&
            segment?.FlightPswFlight?.Baggage
          ) {
            airlineNames.add(segment.FlightPswFlight.airline_name);
            // departureAirport.add(isEmpty(segment.FlightPswFlight?.Airport?.depart_airport_name) +
            //   ' ' +
            //   isEmpty(segment.FlightPswFlight?.departure_airport))
          }
        });
      });
      setAirlineFilterOptions([...airlineNames]);
    }
  }, [packageType, matchedFlights]);

  const handleHolidayAccordionChange = () => {
    setExpandedHolidayDetails((prev) => !prev);
  };

  useEffect(() => {
    // if (!selectedOutboundFlight?.length) {
    //   setSelectedOutboundFlight(outbound);
    // }

    if (!selectedInboundFlight?.length) {
      const data = filteredFlights?.inbound?.filter(
        (ele: any, index: number) => ele?.psw_result_id === selectedPswId
      );
      const dataOut = filteredFlights?.outbound?.filter(
        (ele: any, index: number) => ele?.psw_result_id === selectedPswId
      );
      if (data[0]?.inboundFlight && !firstTimeLoad) {
        setSessionStorage('InboundFlights', JSON.stringify(data[0]?.inboundFlight));
        setSelectedInboundFlight(data[0]?.inboundFlight);
      }
      if (dataOut[0]?.outboundFlight && !firstTimeLoad) {
        setSessionStorage('OutboundFlights', JSON.stringify(data[0]?.outboundFlight));
        setSelectedOutboundFlight(dataOut[0]?.outboundFlight);
      }
    }
  }, [filteredFlights, selectedPswId, flightRoom]);

  const flightSorting = (flight: any) => {
    const newFlights = flight.sort((a: any, b: any) => {
      const aIsEqual = (a as any)?.psw_result_id === (flightRoom as any)?.psw_result_id;
      const bIsEqual = (b as any)?.psw_result_id === (flightRoom as any)?.psw_result_id;

      if (aIsEqual && !bIsEqual) {
        return -1;
      } else if (!aIsEqual && bIsEqual) {
        return 1;
      } else {
        return 0;
      }
    });
    return newFlights;
  };

  // const filterInboundAirlines = (flight: any) => {
  //   if (currentTab === 'Inbound') {
  //     let storeInboundAirlines: string[] = [];
  //     flight
  //       ?.filter((ele: any) => ele.psw_result_id === selectedPswId)
  //       .map((item: any, i: number) => {
  //         if (isEmpty(item.inboundFlight) !== '') {
  //           const uniqueAirlineNames: string[] = Array.from(
  //             new Set(item.inboundFlight.map((f: any) => f.FlightPswFlight.airline_name))
  //           );
  //           uniqueAirlineNames.map((e) => {
  //             if (isEmpty(e) !== '' && !storeInboundAirlines.includes(e)) {
  //               storeInboundAirlines.push(e);
  //             }
  //           });
  //         }
  //       });

  //     const mergeFilters: string[] = [...storeFilters[currentTab].airline, ...storeInboundAirlines];
  //     const removeDuplicateFilters: string[] = Array.from(new Set(mergeFilters));
  //     setAirline(removeDuplicateFilters);
  //     setDuration(storeFilters[currentTab].duration);
  //     setMinMaxSlider(storeFilters[currentTab].maxDuration);
  //     setDepartureAirport(storeFilters[currentTab].departureAirport);
  //     setDepartureTime(storeFilters[currentTab].departureTime);
  //     setDepartureTimeInbound(storeFilters[currentTab].departureTime);
  //     setRenderAirlines(false);
  //   }
  // };

  // useEffect(() => {
  //   if (renderAirlines && selectedPswId) {
  //     filterInboundAirlines(filteredFlights.inbound);
  //   }
  // }, [filteredFlights, selectedPswId, storeFilters['Inbound'], currentTab, renderAirlines]);

  useEffect(() => {
    const getFilteredFlights = async () => {
      if (flights?.data?.length) {
        const flight = await filteredData();

        if (flight?.length) {
          setIsFlightDataLoad(false);
          const newFlights = await flightSorting(flight);
          // setFilteredFlights(newFlights);
          setFilteredFlights((prev: any) => ({
            ...prev,
            outbound: [...newFlights], // new array of outbound flights
          }));
          if (stopsRender) {
            if (stops.length === 0) {
              renderStops(flight);
            }
            if (packages.length === 0) {
              renderPackages(flight);
            }

            if (departureTime.length === 0) {
              renderDepartureTime(newFlights);
            }

            if (departureAirportNames.length === 0) {
              renderDepartureAirports(newFlights);
            }

            setStopsRender(false);
          }
          setIsFlightDataLoad(true);
          setStopsRender(false);
        }
      }
    };
    getFilteredFlights();
  }, [
    isRender,
    flights,
    dynamicDepartureTimes,
    airline,
    duration,
    stops,
    packages,
    departureAirport,
    currentTab,
    departureAirportNames,
    stopsRender,
    selectedPswId,
    isPrevious,
    selectedInboundFlight,
    selectedOutboundFlight,
    flightStops,
    departureTime,
    onChangeInbound,
  ]);

  useEffect(() => {
    const getFilteredFlights = async () => {
      if (flights?.data?.length) {
        const flight = await filteredDataIn();
        // console.log('flight££££££ :', flight);
        // if (currentTab === 'Inbound') {
        // if (flight?.length && isRender) {
        // console.log('flight?.length :', flight?.length);
        if (flight?.length) {
          setIsFlightDataLoad(false);
          // setFilteredFlights(flight);
          setFilteredFlights((prev: any) => ({
            ...prev,
            inbound: [...flight], // new array of inbound flights
          }));
          setIsFlightDataLoad(true);
          if (stopsRender) {
            if (stops?.length === 0) {
              renderStops(flight);
            }
            if (departureTimeInbound?.length === 0) {
              renderDepartureTimeInbound(flight);
            }
            // if (departureAirportNames?.length === 0) {
            //   renderDepartureAirports(flight);
            // }
            setStopsRender(false);
          }
          setisRender(false);
        }
      }
    };
    // }
    // }

    // if (outboundLenght.length > 0 && !isUpdate) {
    //   setIsUpdate(true)
    //   getFilteredFlights();
    // }

    // }
    getFilteredFlights();
  }, [
    // isUpdate,
    isRender,
    flights,
    dynamicDepartureTimesInbound,
    // airline,
    // duration,
    durationInbound,
    stops,
    packages,
    departureAirport,
    currentTab,
    // departureAirportNames,
    stopsRender,
    selectedPswId,
    isPrevious,
    selectedInboundFlight,
    selectedOutboundFlight,
    flightStops,
    // departureTime,
    departureTimeInbound,
    onChangeInbound,
  ]);

  const resetFilterFields = () => {
    setResetDisable(false);

    setDepartureAirport([]);
    setAirline([]);
    setDepartureTime([]);
    setDepartureTimeInbound([]);
    setDuration([0, 5819]);
    setStops([]);
    setMinMaxSlider([0, 5819]);
    setDynamicDepartureTimes([]);
    setDynamicDepartureTimesInbound([]);
    setDepartureAirportNames([]);
    setFlightStops({
      Outbound: [],
      Inbound: [],
    });
  };

  const convertToMinutes = (timeString: any) => {
    const [hours, minutes] = timeString.match(/\d+/g).map(Number);
    return hours * 60 + minutes;
  };
  const convertToMinutesIn = (timeString: any) => {
    const [hours, minutes] = timeString.match(/\d+/g).map(Number);
    return hours * 60 + minutes;
  };

  const calculateHoursMinute = () => {
    let hourMinutes1: any = [];
    for (let i = 0; i < 96 + 1; i++) {
      for (let j = 0; j < 60; j += 1) {
        hourMinutes1.push({ hour: i, minutes: j });
      }
    }
    const marks = hourMinutes1.map((hourMinute: any, index: number) => ({
      value: index,
      label: `${hourMinute.hour.toString().padStart(2, '0')}:${hourMinute.minutes
        .toString()
        .padStart(2, '0')}`,
    }));
    setHourMinutes(marks);
  };
  const calculateHoursMinuteIN = () => {
    let hourMinutes1: any = [];
    for (let i = 0; i < 96 + 1; i++) {
      for (let j = 0; j < 60; j += 1) {
        hourMinutes1.push({ hour: i, minutes: j });
      }
    }
    const marks = hourMinutes1.map((hourMinute: any, index: number) => ({
      value: index,
      label: `${hourMinute.hour.toString().padStart(2, '0')}:${hourMinute.minutes
        .toString()
        .padStart(2, '0')}`,
    }));
    // console.log('marks :', marks);
    setHourMinutesInbound(marks);
  };

  const dataAddAndRemove = (arr: any, value: any) => {
    if (arr.includes(value)) {
      const index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      }
    } else {
      arr.push(value);
    }
    return arr;
  };

  const onSelectAirline = (event: SelectChangeEvent<typeof airline>, name: any, value: any) => {
    setIsLoading(false);
    setResetDisable(true);
    let arr = [...airline];
    const newValue = dataAddAndRemove(arr, value);
    setAirline(newValue);
    setOnChangeInbound(false);
    setisRender(true);
  };

  const onSelectDepartureAirport = (e: SelectChangeEvent<typeof departureAirport>, name: any, value: any) => {
    setIsLoading(false);
    setResetDisable(true);
    let arr = [...departureAirport];
    const newValue = dataAddAndRemove(arr, value);
    setDepartureAirport(newValue);
    setOnChangeInbound(false);
    setisRender(true);
  };

  const onSelectPackage = (e: SelectChangeEvent<typeof packageType>, name: any, value: any) => {
    setIsLoading(false);
    setResetDisable(true);

    let arr = [...packageType];
    let newValue = dataAddAndRemove(arr, value);

    const autoMapping: Record<string, string> = {
      '1p': '1pc',
      '2p': '2pc',
    };

    Object.keys(autoMapping).forEach((base) => {
      if (newValue.includes(base) && !newValue.includes(autoMapping[base])) {
        newValue.push(autoMapping[base]);
      } else if (!newValue.includes(base)) {
        newValue = newValue.filter((item) => item !== autoMapping[base]);
      }
    });

    setPackageType(newValue);
    setOnChangeInbound(false);
    setisRender(true);
  };

  const onSelectStopsChange = (event: SelectChangeEvent<typeof flightStops>, name: any, value: any) => {
    setIsLoading(false);
    setResetDisable(true);
    if (!value) {
      return;
    } else {
      let arr = [...flightStops[currentTab === 'Outbound' ? 'Outbound' : 'Inbound']];
      const newValue = dataAddAndRemove(arr, value);
      setFlightStops({
        ...flightStops,
        [currentTab]: newValue,
      });
    }
    setisRender(true);
    setOnChangeInbound(false);
  };

  const handleChangeDepartuteTime = (e: any, name: any, value: any) => {
    setIsLoading(false);
    setResetDisable(true);
    setisRender(true);
    setOnChangeInbound(true);
    let arr = [...departureTime];
    const newValue = dataAddAndRemove(arr, value);
    setDepartureTime(newValue);
  };
  const handleChangeDepartuteTimeInbound = (e: any, name: any, value: any) => {
    setIsLoading(false);
    setResetDisable(true);
    setisRender(true);
    setOnChangeInbound(true);
    let arr = [...departureTimeInbound];
    // console.log('arr :', arr);
    const newValue = dataAddAndRemove(arr, value);
    setDepartureTimeInbound(newValue);
  };

  const getOutboundFlights = (obj: any, id: string) => {
    const flightIds: string[] = obj?.segments[1][id].split(',');

    let outboundFlight = flightOptions[1].rows.filter(
      (item: any) => item.FlightPswFlight.psw_result_id === id && flightIds?.includes(item.FlightPswFlight.id)
      // && item?.FlightPswFlight?.FlightPswResult?.FareCat === 'IT'
    );
    let flight = JSON.parse(JSON.stringify(outboundFlight));
    // if (JSON.stringify(flightIds) === JSON.stringify(cheapestOutbound)) {
    //   const curr_flight = flight.map((item: any) => {
    //     return {
    //       travel_time: item.FlightPswFlight.travel_time,
    //       flight: item?.FlightPswFlight?.airline_code + item?.FlightPswFlight?.flight_number,
    //     };
    //   });
    //   const cheap_flight = holidayPackage.FlightDetails.outbound.flights.map((item: any) => {
    //     return { travel_time: item.travel_time, flight: item.airline_code + item.flight_number };
    //   });
    //   if (JSON.stringify(curr_flight) === JSON.stringify(cheap_flight)) {
    //     flight?.forEach((item: any) => (item.FlightPswFlight.Cheapest = true));
    //   }
    // }
    return flight;
  };

  const getInboundFlights = (obj: any, id: string) => {
    //
    const flightIds: string[] = obj?.segments[2][id].split(',');
    const data = flightOptions[1].rows.filter(
      (item: any) => item.FlightPswFlight.psw_result_id === id && flightIds?.includes(item.FlightPswFlight.id)
      // && item?.FlightPswFlight?.FlightPswResult?.FareCat === 'IT'
    );
    return data;
  };

  const filteredData: any = () => {
    //OUTBOUND
    const filteredFlightsData = flights?.data.map((flights, index) => {
      let selectedFlight = flights.outboundFlight;

      if (!selectedFlight) {
        return null;
      }

      const airlineNames = selectedFlight.map(
        (flight: FlightData) => flight?.FlightPswFlight?.airline_name || null
      );

      const airLineIncludes = airlineNames.filter((air) => (air !== null ? airline.includes(air) : false));
      const packageNames = selectedFlight.map(
        (flight: FlightData) => flight?.FlightPswFlight?.Baggage || null
      );
      const packageIncludes = packageNames.filter((air) =>
        air !== null ? packageType.includes(air) : false
      );
      let filteredFlights = selectedFlight.filter(() => {
        if (airline.length > 0 && packageType.length > 0) {
          return airLineIncludes.length > 0 && packageIncludes.length > 0 ? true : false;
        } else if (packageType.length > 0 || airline.length > 0) {
          return packageIncludes.length > 0 || airLineIncludes.length > 0 ? true : false;
        } else {
          return true;
        }
      });

      if (departureAirport.length > 0) {
        const departureAirports = departureAirport?.map((airport) => {
          let airportObj: string[] = airport.split(' ');
          return airportObj[airportObj.length - 1];
        });
        if (departureAirports.length) {
          if (filteredFlights.length > 0) {
            const isAirportNameIncludes = departureAirports.includes(
              (filteredFlights[0] as any)?.FlightPswFlight?.departure_airport
            );
            if (isAirportNameIncludes) {
            } else {
              return [];
            }
          }
        }
      }
      // if (packageType.length > 0) {
      //   if (filteredFlights.length > 0) {
      //     const packageNames = selectedFlight.map(
      //       (flight: FlightData) => flight?.FlightPswFlight?.Baggage || null
      //     );
      //     const packageIncludes = packageNames.filter((air) => (air !== null ? packageType.includes(air) : false));
      //     if (packageIncludes) {
      //     } else {
      //       return [];
      //     }
      //   }
      // }

      if (departureTime?.length) {
        const depTime: string[] = [];

        departureTime?.forEach((value: string) => {
          dynamicDepartureTimes.forEach((v) => {
            if (v.day === value) {
              depTime.push(v.time);
            }
          });
        });

        if (depTime.length > 0 && filteredFlights.length > 0) {
          const departureTime: any = formatTime((filteredFlights[0] as any).FlightPswFlight.departure_time);
          const timeSplit = departureTime.split(':');
          const removeLeadingZero = timeSplit[0].replace(/^0/, '');

          const flightTime = dayjs()
            .set('hour', Number(removeLeadingZero))
            .set('minute', timeSplit[1])
            .set('second', 0);

          const filterDepTime = depTime.some((value) => {
            let timeSepration = value?.split(' - ');

            const time1 = timeSepration[0];
            const time2 = timeSepration[1];

            const splitTime1 = time1.split(':');
            const splitTime2 = time2.split(':');
            const flightStartTime = dayjs()
              .set('hour', Number(splitTime1[0]))
              .set('minute', 0)
              .set('second', 0);
            const flightEndTime = dayjs()
              .set('hour', Number(splitTime2[0]))
              .set('minute', 59)
              .set('second', 59);

            return flightTime.isAfter(flightStartTime) && flightTime.isBefore(flightEndTime);
          });

          filteredFlights = filteredFlights.filter(() => {
            if (filterDepTime) {
              return true;
            } else {
              return false;
            }
          });
        }
      }

      if (flightStops.Outbound?.length) {
        //* For Outbound
        let outBoundStops: number[] = [];
        stops.forEach((stop: any, i: number) => {
          return flightStops.Outbound.includes(stop.label) && outBoundStops.push(Number(stops[i].value));
        });
        filteredFlights = outBoundStops.includes(filteredFlights?.length - 1) ? filteredFlights : [];
      }
      // else if (flightStops.Inbound?.length && currentTab === TabType.Inbound) {
      //   //* For Inbound
      //   let inBoundStops: number[] = [];
      //   stops.forEach((stop: any, i: number) => {
      //     return flightStops.Inbound.includes(stop.label) && inBoundStops.push(Number(stops[i].value));
      //   });

      //   filteredFlights = inBoundStops.includes(filteredFlights?.length - 1) ? filteredFlights : [];
      // }
      const calcualteTimeArr: any = [];
      const time = singleFlightTravelTime(
        filteredFlights?.[0]?.FlightPswFlight?.segment1_elapse_flying_time ?? 0
      );
      calcualteTimeArr.push(sumTime([time]));

      const convertedTimes = calcualteTimeArr.map(convertToMinutes);
      const maxTime = Math.max(...convertedTimes);

      const maxTimeFormat = formatTime(
        Math.floor(maxTime / 60)
          .toString()
          .padStart(2, '0') + (maxTime % 60).toString().padStart(2, '0')
      );
      const maxValueForSlider = hourMinutes.filter((f: any) => f.label === maxTimeFormat);

      if (duration[0] <= maxValueForSlider[0]?.value && duration[1] >= maxValueForSlider[0]?.value) {
        filteredFlights =
          filteredFlights.length > 0
            ? filteredFlights?.filter(
              (e, i) =>
                duration[0] <= maxValueForSlider[0].value && duration[1] >= maxValueForSlider[0].value
            )
            : [];
      } else {
        if (onChangeInbound) {
          filteredFlights = [];
        } else {
          if (currentTab === 'Outbound') {
            return true;
          }
        }
      }

      if (!filteredFlights.length) {
        return [];
      }

      return { ...flights, outboundFlight: filteredFlights, inboundFlight: flights.inboundFlight };
    });
    return filteredFlightsData?.filter(Boolean);
  };

  const filteredDataIn: any = () => {
    //INBOUND
    const filteredFlightsData = flights?.data.map((flights, index) => {
      let selectedFlight = flights.inboundFlight;

      if (!selectedFlight) {
        return null;
      }

      const airlineNames = selectedFlight.map(
        (flight: FlightData) => flight?.FlightPswFlight?.airline_name || null
      );

      const airLineIncludes = airlineNames.filter((air) => (air !== null ? airline.includes(air) : false));

      let filteredFlights = selectedFlight.filter(() => {
        if (airline.length > 0) {
          return airLineIncludes.length > 0 ? true : false;
        } else {
          return true;
        }
      });

      // if (departureAirport.length > 0) {

      //   const departureAirports = departureAirport?.map((airport) => {
      //     let airportObj: string[] = airport.split(' ');
      //     return airportObj[airportObj.length - 1];
      //   });
      //   if (departureAirports.length) {
      //     if (filteredFlights.length > 0) {
      //       const isAirportNameIncludes = departureAirports.includes(
      //         (filteredFlights[0] as any)?.FlightPswFlight?.departure_airport
      //       );
      //       if (isAirportNameIncludes) {
      //       } else {
      //         return [];
      //       }
      //     }
      //   }
      // }

      if (departureTimeInbound?.length) {
        const depTime: string[] = [];

        departureTimeInbound?.forEach((value: string) => {
          dynamicDepartureTimesInbound.forEach((v) => {
            if (v.day === value) {
              depTime.push(v.time);
            }
          });
        });

        if (depTime.length > 0 && filteredFlights.length > 0) {
          const departureTime: any = formatTime((filteredFlights[0] as any).FlightPswFlight.departure_time);
          const timeSplit = departureTime.split(':');
          const removeLeadingZero = timeSplit[0].replace(/^0/, '');

          const flightTime = dayjs()
            .set('hour', Number(removeLeadingZero))
            .set('minute', timeSplit[1])
            .set('second', 0);

          const filterDepTime = depTime.some((value) => {
            let timeSepration = value?.split(' - ');

            const time1 = timeSepration[0];
            const time2 = timeSepration[1];

            const splitTime1 = time1.split(':');
            const splitTime2 = time2.split(':');
            const flightStartTime = dayjs()
              .set('hour', Number(splitTime1[0]))
              .set('minute', 0)
              .set('second', 0);
            const flightEndTime = dayjs()
              .set('hour', Number(splitTime2[0]))
              .set('minute', 59)
              .set('second', 59);

            return flightTime.isAfter(flightStartTime) && flightTime.isBefore(flightEndTime);
          });

          filteredFlights = filteredFlights.filter(() => {
            if (filterDepTime) {
              return true;
            } else {
              return false;
            }
          });
        }
      }

      // if (flightStops.Outbound?.length && currentTab === TabType.Outbound) {
      //   //* For Outbound
      //   let outBoundStops: number[] = [];
      //   stops.forEach((stop: any, i: number) => {
      //     return flightStops.Outbound.includes(stop.label) && outBoundStops.push(Number(stops[i].value));
      //   });

      //   filteredFlights = outBoundStops.includes(filteredFlights?.length - 1) ? filteredFlights : [];
      // }

      // if (flightStops.Inbound?.length) {

      //   //* For Inbound
      //   let inBoundStops: number[] = [];
      //   stops.forEach((stop: any, i: number) => {
      //     return flightStops.Inbound.includes(stop.label) && inBoundStops.push(Number(stops[i].value));
      //   });

      //   filteredFlights = inBoundStops.includes(filteredFlights?.length - 1) ? filteredFlights : [];
      // }

      const calcualteTimeArr: any = [];
      const flight = filteredFlights?.[0]?.FlightPswFlight;
      const time = singleFlightTravelTime(flight?.segment2_elapse_flying_time ?? 0);
      calcualteTimeArr.push(sumTime([time]));

      const convertedTimes = calcualteTimeArr.map(convertToMinutesIn);
      // console.log('convertedTimes INBUND :', convertedTimes);
      const maxTime = Math.max(...convertedTimes);

      const maxTimeFormat = formatTime(
        Math.floor(maxTime / 60)
          .toString()
          .padStart(2, '0') + (maxTime % 60).toString().padStart(2, '0')
      );
      const maxValueForSlider = hourMinutesInbound.filter((f: any) => f.label === maxTimeFormat);
      // console.log('maxValueForSliderINNN :', maxValueForSlider);

      // console.log('durationInbound[0] <= maxValueForSlider[0]?.value && durationInbound[1] >= maxValueForSlider[0]?.value :', durationInbound[0] <= maxValueForSlider[0]?.value && durationInbound[1] >= maxValueForSlider[0]?.value);
      if (
        durationInbound[0] <= maxValueForSlider[0]?.value &&
        durationInbound[1] >= maxValueForSlider[0]?.value
      ) {
        // console.log('!!!AAAA');
        filteredFlights =
          filteredFlights.length > 0
            ? filteredFlights.filter(
              (e, i) =>
                durationInbound[0] <= maxValueForSlider[0].value &&
                durationInbound[1] >= maxValueForSlider[0].value
            )
            : [];
      } else {
        if (onChangeInbound) {
          filteredFlights = [];
        } else {
          // if (currentTab === 'Outbound') {
          //   return 'ttttttt';
          // }
        }
      }
      // console.log('filteredFlights@@@@ :', filteredFlights);

      if (!filteredFlights.length) {
        return [];
      }

      return { ...flights, inboundFlight: filteredFlights };
    });

    return filteredFlightsData?.filter(Boolean);
  };

  const onSelectInputSubmit = () => {
    setFilteredFlights((prev: any) => ({
      ...prev,
      outbound: [...filteredData()], // new array of outbound flights
    }));
  };

  // const handleSelectFlight = (isEqual?: boolean, type?: string, psw_result_id?: string, data?: any) => {
  //   resetFilterFields();
  //   window.scrollTo(0, 1300);
  //   setDisableButton(true);
  //   setSessionStorage('previous', 'false');
  //   setSessionStorage('nextAndSelectedFlights', 'false');
  //   setDisabledFlightTabsInbound(!disabledFlightTabsInbound);

  //   let filters = { ...storeFilters };
  //   filters[type as Tabs] = {
  //     airline: airline as string[],
  //     departureAirport: departureAirport,
  //     departureTime: departureTime,
  //     stops: flightStops[type as Tabs],
  //     duration: duration,
  //     maxDuration: minMaxSlider,
  //     stopsArray: stops,
  //     departureTimeArr: dynamicDepartureTimes,
  //     departureAirports: departureAirportNames,
  //   };

  //   if (type === 'Outbound') {
  //     setRenderAirlines(true);
  //     dispatch(
  //       setFlightRoomState({
  //         ...flightRoom,
  //         psw_result_id: psw_result_id,
  //         isNewFlightSelected: {
  //           ...flightRoom.isNewFlightSelected,
  //           flights: true,
  //         },
  //       })
  //     );
  //     setSelectedOutboundFlight(data);
  //     dispatch(setLoading(true));
  //     setTimeout(() => {
  //       setCurrentTab('Inbound');
  //       // setRenderInitially(true);
  //       setStopsRender(true);
  //       dispatch(setLoading(false));
  //     }, 1500);
  //     setSessionStorage('OutboundFlights', JSON.stringify(data));

  //     if (!isEqual) {
  //       setSelectedInboundFlight([]);
  //       filters['Inbound'] = {
  //         airline: [],
  //         departureAirport: [],
  //         departureTime: [],
  //         stops: [],
  //         duration: [0, 5819],
  //         maxDuration: [0, 5819],
  //         stopsArray: [],
  //         departureTimeArr: [],
  //         departureAirports: [],
  //       };
  //     }
  //   } else {
  //     setRenderAirlines(false);
  //     setInnerAccordionExpanded(0);
  //     setSelectedInboundFlight(data);
  //     setSessionStorage('InboundFlights', JSON.stringify(data));
  //     dispatch(
  //       setFlightRoomState({
  //         ...flightRoom,
  //         isNewFlightSelected: {
  //           ...flightRoom.isNewFlightSelected,
  //           flights: true,
  //         },
  //       })
  //     );
  //     setCurrentTab('Outbound');
  //     setStopsRender(true);
  //   }
  //   if (isEqual) {
  //     const moveToTab = currentTab === 'Outbound' ? 'Inbound' : 'Outbound';
  //     setAirline(storeFilters[moveToTab].airline);
  //     setDepartureTime(storeFilters[moveToTab].departureTime);
  //     setDepartureTimeInbound(storeFilters[moveToTab].departureTime);
  //     setDepartureAirport(storeFilters[moveToTab].departureAirport);
  //     setDuration(storeFilters[moveToTab].duration.length > 0 ? storeFilters[moveToTab].duration : [0, 5819]);
  //     setMinMaxSlider(
  //       storeFilters[moveToTab].maxDuration.length > 0 ? storeFilters[moveToTab].maxDuration : [0, 5819]
  //     );
  //     setFlightStops({
  //       ...flightStops,
  //       [moveToTab]: storeFilters[moveToTab].stops,
  //     });

  //     setStops(storeFilters[moveToTab].stopsArray);
  //     setDynamicDepartureTimes(storeFilters[moveToTab].departureTimeArr);
  //     setDepartureAirportNames(storeFilters[moveToTab].departureAirports);
  //   }
  //   setStoreFilters(filters);
  //   setisRender(true);
  //   setSelectedPswId(psw_result_id);
  //   setonlyShowFlights(true);
  // };

  const resetFilter = () => {
    setResetDisable(false);

    setAirline([]);
    setAirlineFilterOptions([]);
    setStoreFilters({
      ...storeFilters,
      [currentTab as Tabs]: {
        airline: [],
        departureAirport: [],
        departureTime: [],
        stops: [],
        packages: [],
        duration: minMaxSlider,
        maxDuration: minMaxSlider,
        stopsArray: [],
      },
    });
    setMinMaxSlider(minMaxSlider);
    setMinMaxSliderInbound(minMaxSliderInbound);
    setDuration(minMaxSlider);
    setDurationInbound(minMaxSliderInbound);
    setOnChangeInbound(false);
    setDeparture(flightRoom?.searchCriteria?.departure?.full_name);
    setDepartureAirport([]);
    setPackages([]);
    setPackageType([]);
    setDepartureTime([]);
    setDepartureTimeInbound([]);
    setisRender(true);
    setStopsRender(true);
    setFlightStops({
      ...flightStops,
      [currentTab]: [],
    });
  };

  // const changeFlight = () => {
  //   if (currentTab !== 'Outbound') {
  //     setStoreFilters({
  //       ...storeFilters,
  //       Inbound: {
  //         airline: airline,
  //         departureAirport: departureAirport,
  //         departureTime: departureTime,
  //         stops: flightStops['Inbound'],
  //         duration: duration,
  //         maxDuration: minMaxSlider,
  //         stopsArray: stops,
  //       },
  //     });

  //     setCurrentTab('Outbound');
  //     setDisabledFlightTabsInbound(true);
  //     setAirline(storeFilters.Outbound.airline);
  //     setDepartureAirport(storeFilters.Outbound.departureAirport);
  //     setDepartureTime(storeFilters.Outbound.departureTime);
  //     setDepartureTimeInbound(storeFilters.Outbound.departureTime);
  //     setMinMaxSlider(storeFilters['Outbound'].maxDuration);
  //     setDuration(storeFilters['Outbound'].duration);
  //     setStops(storeFilters['Outbound'].stopsArray);
  //     setDynamicDepartureTimes(storeFilters['Outbound'].departureTimeArr);
  //     setDepartureAirportNames(storeFilters['Outbound'].departureAirports);
  //     setFlightStops({
  //       ...flightStops,
  //       Outbound: storeFilters['Outbound'].stops,
  //     });
  //   }
  // };

  const removeDuplicateOutboundFlights = (flights: any, flightKey: any) => {
    // console.log('flights)))) :', flights);
    const uniqueFlights: any = {};

    flights.forEach((item: any) => {
      // if (item && typeof item === "object" && "outboundFlight" in item) {
      const flight = item?.[flightKey][0].FlightPswFlight;
      const key = [
        flight.airline_code,
        flight.Baggage,
        flight.departure_time,
        flight.arrival_airport,
        flight.departure_date,
        flight.arrival_date,
        flight.arrival_time,
        flight.departure_airport,
      ].join('-');

      if (
        !uniqueFlights[key] ||
        parseFloat(flight.FlightPswResult.holiday_diff_per_person) <
        parseFloat(
          uniqueFlights[key]?.[flightKey][0].FlightPswFlight.FlightPswResult.holiday_diff_per_person
        )
      ) {
        uniqueFlights[key] = item;
      }
      // }
    });

    return Object.values(uniqueFlights);
  };

  const removeDuplicateFlights = (flights: any) => {
    const uniqueFlights: any = [];
    const pswIdSet = new Set();

    flights.forEach((flight: any) => {
      const pswId = flight?.inboundFlight?.[0]?.FlightPswFlight?.psw_result_id;
      if (pswId && !pswIdSet.has(pswId)) {
        pswIdSet.add(pswId);
        uniqueFlights.push(flight);
      }
    });

    return uniqueFlights;
  };

  useEffect(() => {
    if (filteredFlights?.outbound) {
      const newOutboundFlights = removeDuplicateOutboundFlights(
        filteredFlights?.outbound?.filter((ele: any) => ele.outboundFlight),
        'outboundFlight'
      );
      setFilterNewFlightsData((prev) => ({
        ...prev,
        outbound: newOutboundFlights,
      }));
    }

    if (filteredFlights?.inbound) {
      // console.log('filteredFlights :', filteredFlights);
      const newInboundFlights = filteredFlights?.inbound?.filter((ele: any) => ele.inboundFlight);
      // console.log('newInboundFlights :', newInboundFlights);
      setFilterNewFlightsData((prev) => ({
        ...prev,
        inbound: newInboundFlights,
      }));
    }
  }, [filteredFlights, selectedPswId]);
  const [isLoadMoreButton, setIsLoadMoreButton] = useState(false);
  useEffect(() => {
    const { inbound, outbound } = filterNewFlightsData;

    const filteredFlightsData: any = outbound?.map((outboundFlight) => {
      // Find all inbound flights with the same psw_result_id as the current outbound flight
      const matchingInbounds = inbound.filter(
        (inboundFlight) => inboundFlight?.psw_result_id === outboundFlight?.psw_result_id
      );

      // Return an object with outbound flight and its matching inbound flights
      return { outboundFlight, inboundFlights: matchingInbounds };
    });
    // console.log("£££££££");

    setMatchedFlights(filteredFlightsData);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    setIsLoadMoreButton(true);
  }, [filterNewFlightsData]);

  return (
    <Box
      sx={{
        '& .title-style': {
          fontWeight: 500,
          lineHeight: '130%',
          color: colors.grey[30],
          mb: '2px',
        },
        '& ::selection': {
          background: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: colors.grey[50],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '& .MuiButton-root svg': {
            width: '25.46px',
            height: '18px',
            p: '0',
          },
          '& .MuiButton-root': {
            '&:hover': {
              background: colors.basics.white,
            },
            px: {
              sm: '10px',
              xs: '6px',
            },
            minWidth: '0',
            background: colors.basics.white,
          },
          p: { sm: '15px 16px', xs: '15px 10px' },
        }}
      >
        <Button
          disableRipple
          variant='text'
          onClick={() => {
            setOpenChangeFlightDrawer(false);
            showPreviousComponent(0);
            setInboundSorting(false);
            setIsLoading(false);
            document.body.style.overflow = 'visible';
          }}
        >
          {' '}
          <CloseModal />{' '}
        </Button>
        <Box>Change your flight</Box>
        <Box></Box>
      </Box>
      <Box
        m={{ sm: '20px 0px 30px 0px', xs: '15px 0 20px 0' }}
        sx={{ padding: { md: '10px 30px', xs: '10px' } }}
        display={'block'}
        alignItems={'center'}
      // padding={{ md: "0", xs: "16px" }}
      >
        {/* <Typography
          variant='h1'
          fontSize={{ lg: '20px', xs: '17px' }}
          sx={{ textAlign: 'center' }}
        >
          Available flights and class options
        </Typography> */}
        <Grid
          p={{ sm: 0, xs: '0' }}
          // mt={'20px'}
          sx={{
            '& button': {
              backgroundColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
              '&:hover': {
                backgroundColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
              },
            },
          }}
          container
          columnSpacing={1}
        >
          {/* <Grid
            lg={3}
            xs={windowWidth > 374 ? 6 : 12}
            sm={6}
            item
            sx={{
              mb: '20px',
              '& .MuiSvgIcon-root': {
                fill: isPlataniumHotel
                  ? colors.basics.plataniumGreenColor + '!important'
                  : colors.basics.primary,
                width: '20px',
                height: '20px',
              },
            }}
          >
            <Typography variant='body1' className='title-style'>
              Airline
            </Typography>
            <Box>
              <CustomSelectInput
                disabled={!disabledFlightTabsInbound}
                data={flights?.filters.airlines}
                onChange={(e: any, n: any, v: any) => onSelectAirline(e, n, v)}
                value={airline}
                multiple={true}
                checkbox={true}
                handleSubmit={() => { }}
                // handleSubmit={() => setFilteredFlights(filteredData())}
                placeholder='Select Airline'
                ids='airline'
                // keys={"airline"}
                isPlataniumHotel={isPlataniumHotel}
              // StartIcon={<AirplanemodeActiveIcon />}
              />
            </Box>
          </Grid> */}
          {/* {departure?.includes('London All Airports LON') && ( */}

          {/* <Grid
            sx={{
              '& .custom-dropdown .dropdown-toggle svg, & .MuiSvgIcon-root': {
                marginTop: '3px',
                fill: isPlataniumHotel
                  ? colors.basics.plataniumGreenColor + '!important'
                  : colors.basics.primary,
                fontSize: '1.4rem',
              },
            }}
            lg={3}
            xs={windowWidth > 374 ? 6 : 12}
            sm={6}
            item
            mb={'20px'}
          >
            <Typography variant='body1' className='title-style'>
              Departure Airport
            </Typography>
            <CustomSelectInput
              ids={'departure'}
              // keys={"departure"}
              // disabled={flightRoom?.searchCriteria?.departure?.departure_airport === "LON" ? false : true}
              data={departureAirportNames}
              // menuPaperPropsStyle={{ paddingRight: '8px' }}
              onChange={(e: any, n: any, v: any) => onSelectDepartureAirport(e, n, v)}
              multiple={true}
              checkbox={true}
              handleSubmit={() => { }}
              // handleSubmit={() => setFilteredFlights(filteredData())}
              value={departureAirport}
              isPlataniumHotel={isPlataniumHotel}
              placeholder='Select Departure Airport'
              StartIcon={<LocationOnIcon />}
            />
          </Grid> */}
          {/* )} */}
          {/* <Grid
            lg={3}
            xs={windowWidth > 374 ? 6 : 12}
            sm={6}
            item
            sx={{
              '& .custom-dropdown .dropdown-toggle svg': {
                marginTop: '3px',
              },
              mb: '20px',
              '& .MuiSvgIcon-root': {
                fill: isPlataniumHotel
                  ? colors.basics.plataniumGreenColor + '!important'
                  : colors.basics.primary,
                width: '18px',
                height: '18px',
              },
            }}
          >
            <Typography variant='body1' className='title-style'>
              Departure Time
            </Typography>
            <CustomSelectInput
              data={dynamicDepartureTimes?.length > 0 ? dynamicDepartureTimes.map((v) => v.day) : []}
              subData={dynamicDepartureTimes?.length > 0 ? dynamicDepartureTimes.map((v) => v.time) : []}
              multiple={true}
              checkbox={true}
              // onSubmit={filteredData}
              ids={'departureTime'}
              // keys={"departureTime"}
              onChange={(e: any, n: any, v: any) => {
                handleChangeDepartuteTime(e, n, v);
              }}
              value={departureTime}
              isPlataniumHotel={isPlataniumHotel}
              placeholder='Select Departure Time'
            // StartIcon={<AccessTimeFilledIcon />}
            />
          </Grid> */}
          {/* <Grid
            lg={3}
            xs={windowWidth > 374 ? 6 : 12}
            sm={6}
            item
            sx={{
              mb: '20px',
              '& .MuiSvgIcon-root': {
                fill: isPlataniumHotel
                  ? colors.basics.plataniumGreenColor + '!important'
                  : colors.basics.primary,
                width: '20px',
                height: '22px',
              },
            }}
          >
            <Box
              width='100%'
              sx={{
                '& .dropdown-menu.show': {
                  zIndex: '99999',
                },
              }}
            >
              <Typography variant='body1' className='title-style'>
                Stops
              </Typography>
              <CustomSelectInput
                ids='stops'
                // keys={'stops'}
                data={stops.map((stop: any) => stop.label)}
                onChange={(e: any, n: any, v: any) => onSelectStopsChange(e, n, v)}
                // handleSubmit={onSelectInputSubmit}
                value={Object.values(flightStops[currentTab === 'Outbound' ? 'Outbound' : 'Inbound']).map(
                  (item: any) => item
                )}
                multiple={true}
                isPlataniumHotel={isPlataniumHotel}
                checkbox={true}
                placeholder='Select Stops'
              // StartIcon={<AirlineStopsIcon />}
              />
            </Box>
          </Grid> */}
        </Grid>
        {/* {process.env.REACT_APP_PROJECT_COUNTRY !== 'uk' && (
          <Divider sx={{ display: { md: 'flex', xs: 'none' } }} />
        )} */}
        <Grid container mt={{ md: '10px', xs: '0' }}>
          <Grid
            md={6}
            sm={6}
            xs={12}
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              order: { md: '1', xs: '2' },
              // mt: { sm: '0', xs: '10px' },
            }}
          >
            <Box
              display={'flex'}
              gap={2}
              sx={{
                textAlign: { lg: 'left', xs: 'center' },
                justifyContent: { lg: 'flex-start', xs: 'center' },
              }}
            >
              <span
                style={{
                  fontSize: window.innerWidth > 576 ? '15px' : '13px',
                  color: !resetDisable
                    ? colors.grey[30]
                    : isPlataniumHotel
                      ? colors.basics.plataniumGreenColor
                      : colors.basics.primary,
                  fontWeight: '500',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  resetFilter();
                }}
              >
                Reset Filters
              </span>

              {/* <span
                style={{
                  fontSize: window.innerWidth > 576 ? '15px' : '13px',
                  color: isPlataniumHotel
                    ? disabledFlightTabsInbound
                      ? '#00000061'
                      : colors.basics.plataniumGreenColor
                    : disabledFlightTabsInbound
                      ? '#00000061  '
                      : colors.basics.primary,
                  fontWeight: '500',
                  textDecoration: 'underline',
                  cursor: disabledFlightTabsInbound ? 'auto' : 'pointer',
                }}
                onClick={() => {
                  if (!disabledFlightTabsInbound) {
                    changeFlight();
                  }
                }}
              >
                Change Flight
              </span> */}
            </Box>
          </Grid>
          <Grid md={4} sm={6} xs={12} item sx={{ order: { sm: '2', xs: '1' } }}>
            <Box
              width={'100%'}
              m={{ sm: 0, xs: '0' }}
              sx={{
                display: 'flex',
                // justifyContent: "end"
              }}
            >
              {/* <Box display='flex'> */}
              {/* <Box
                sx={{
                  '& .dropdown-menu.show ': {
                    zIndex: '999999',
                  },
                  width: '100%',
                }}
              >
                <Typography variant='body1' className='title-style'>
                  Duration
                </Typography>
                <DurationSlider
                  isPlataniumHotel={isPlataniumHotel}
                  setisRender={setisRender}
                  setOnChangeInbound={setOnChangeInbound}
                  currentTab={currentTab}
                  setDuration={setDuration}
                  duration={duration}
                  minMaxSlider={minMaxSlider}
                  hourMinutes={hourMinutes}
                />
              </Box> */}
              {/* <Divider
              sx={{
                display: { md: 'none', xs: 'flex' },
                m: { sm: '20px 0px 0 !important', xs: '20px -10px 0 !important' },
              }}
            /> */}
            </Box>
          </Grid>
        </Grid>
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'} mt='20px'></Box>
        {/* {process.env.REACT_APP_PROJECT_COUNTRY === 'uk' && (
          <Divider sx={{ display: { md: 'flex', xs: 'none' } }} />
        )} */}
        <Box
          sx={{
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: '15px 0 10px 0',
            },
          }}
        >
          <Accordion
            expanded={expandedHolidayDetails}
            onChange={handleHolidayAccordionChange}
            sx={{
              // color: `${colors.basics.primary} !important`,
              '& svg': {
                mr: '4px',
                fill: isPlataniumHotel
                  ? colors.basics.plataniumGreenColor + '!important'
                  : colors.basics.primary,
              },
              display: { sm: 'none', xs: 'flex', md: 'none' },
              flexDirection: 'column',
              boxShadow: 'none',
              border: '1px solid #999999',
              borderRadius: '4px',
              ':before': { backgroundColor: 'transparent' },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
            >
              {' '}
              <TuneIcon />
              <Typography
                variant='body1'
                fontWeight={500}
                sx={{
                  color: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor + '!important'
                    : colors.basics.primary,
                }}
              >
                {/* {expandedHolidayDetails ? "Hide" : "Show"} filter */}
                Filters
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container sx={{ display: { md: 'flex', xs: 'block' }, width: '100%' }}>
                <Grid
                  item
                  md={3}
                  xs={12}
                  sx={{
                    pr: { md: '10px', xs: '0px' },
                    display: { md: 'block', xs: 'grid' },
                    gridGap: { md: '0', xs: '20px' },
                    gridTemplateColumns: {
                      // xs: 'repeat(1, 1fr)',
                      // sm: 'repeat(3, 1fr)',
                    },
                    gridTemplateAreas: {
                      xs: `
                "input1"
                "input2"
                "input3"
                "input8"
                "input4"
                "input5"
                "input6"
                "input7"
              `,
                      sm: `
                "input1 input1 input1 input2 input2 input2"
                "input3 input3 input3 input8 input8 input8"
                "input4 input4 input4 input6 input6 input6"
                "input5 input5 input5 input7 input7 input7"
              `,
                    },
                  }}
                >
                  <Box
                    sx={{
                      // width: { md: 'auto', sm: '31.5%', xs: '100%' },
                      gridArea: 'input1',
                      mb: { md: '20px', xs: '0' },
                      '& .MuiSvgIcon-root': {
                        fill: isPlataniumHotel
                          ? colors.basics.plataniumGreenColor + '!important'
                          : colors.basics.primary,
                        width: '20px',
                        height: '20px',
                      },
                    }}
                  >
                    <Typography variant='body1' className='title-style'>
                      Airline
                    </Typography>
                    <Box ref={airlineRef}>
                      <CustomSelectInput
                        // ids='airline'
                        disabled={!disabledFlightTabsInbound}
                        data={airlineFilterOptions}
                        onChange={(e: any, n: any, v: any) => onSelectAirline(e, n, v)}
                        value={airline}
                        multiple={true}
                        checkbox={true}
                        handleSubmit={() => {
                          setFilteredFlights((prev: any) => ({
                            ...prev,
                            outbound: [...filteredData()], // new array of outbound flights
                          }));
                        }}
                        // handleSubmit={() => setFilteredFlights(filteredData())}
                        placeholder='Select airline'
                        ids={`airline-flight-${idToAdd}`}
                        keys={`airline-flight-${idToAdd}`}
                        StartIcon={<AirplanemodeActiveIcon />}
                      />
                    </Box>
                  </Box>

                  <Grid
                    sx={{
                      '& .custom-dropdown .dropdown-toggle svg, & .MuiSvgIcon-root': {
                        marginTop: '3px',
                        fill: isPlataniumHotel
                          ? colors.basics.plataniumGreenColor + '!important'
                          : colors.basics.primary,
                        fontSize: '1.4rem',
                      },
                    }}
                    lg={3}
                    xs={12}
                    sm={6}
                    item
                    // mb={'20px'}
                    ref={departureAirportRef}
                  >
                    <Typography variant='body1' className='title-style'>
                      Departure airport
                    </Typography>
                    <CustomSelectInput
                      ids={`departure-flight-${idToAdd}`}
                      keys={`departure-flight-${idToAdd}`}
                      // disabled={flightRoom?.searchCriteria?.departure?.departure_airport === "LON" ? false : true}
                      data={departureAirportNames}
                      // menuPaperPropsStyle={{ paddingRight: '8px' }}
                      onChange={(e: any, n: any, v: any) => onSelectDepartureAirport(e, n, v)}
                      multiple={true}
                      checkbox={true}
                      // handleSubmit={() => { }}
                      handleSubmit={() => {
                        setFilteredFlights((prev: any) => ({
                          ...prev,
                          outbound: [...filteredData()], // new array of outbound flights
                        }));
                      }}
                      // ids={'departure'}
                      // handleSubmit={() => setFilteredFlights(filteredData())}
                      value={departureAirport}
                      // isPlataniumHotel={isPlataniumHotel}
                      placeholder='Select departure airport'
                      StartIcon={<LocationOnIcon />}
                    />
                  </Grid>
                  <Box
                    sx={{
                      // width: { md: 'auto', sm: '31.5%', xs: '100%' },
                      gridArea: 'input3',
                      my: { md: '20px', xs: 0 },
                      '& .MuiSvgIcon-root': {
                        fill: isPlataniumHotel
                          ? colors.basics.plataniumGreenColor + '!important'
                          : colors.basics.primary,
                        width: '20px',
                        height: '22px',
                      },
                    }}
                  >
                    <Box
                      width='100%'
                      sx={{
                        '& .dropdown-menu.show': {
                          zIndex: '99999',
                        },
                      }}
                      ref={stopRef}
                    >
                      <Typography variant='body1' className='title-style'>
                        Stops
                      </Typography>
                      <CustomSelectInput
                        ids={`stops-flight-${idToAdd}`}
                        keys={`stops-flight-${idToAdd}`}
                        // keys={'stops'}
                        data={stops.map((stop: any) => stop.label)}
                        onChange={(e: any, n: any, v: any) => onSelectStopsChange(e, n, v)}
                        handleSubmit={onSelectInputSubmit}
                        value={Object.values(
                          flightStops[currentTab === 'Outbound' ? 'Outbound' : 'Inbound']
                        ).map((item: any) => item)}
                        multiple={true}
                        // isPlataniumHotel={isPlataniumHotel}
                        checkbox={true}
                        placeholder='Select stops'
                        StartIcon={<AirlineStopsIcon />}
                      // ids='stops'
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      // width: { md: 'auto', sm: '31.5%', xs: '100%' },
                      gridArea: 'input8',
                      my: { md: '20px', xs: 0 },
                      '& .MuiSvgIcon-root': {
                        fill: isPlataniumHotel
                          ? colors.basics.plataniumGreenColor + '!important'
                          : colors.basics.primary,
                        width: '20px',
                        height: '22px',
                      },
                    }}
                  >
                    <Box
                      width='100%'
                      sx={{
                        '& .dropdown-menu.show': {
                          zIndex: '99999',
                        },
                      }}
                      ref={packageRef}
                    >
                      <Typography variant='body1' className='title-style'>
                        Baggage
                      </Typography>
                      <CustomSelectInput
                        ids={`package-flight-${idToAdd}`}
                        keys={`package-flight-${idToAdd}`}
                        // keys={'package'}
                        data={packages}
                        onChange={(e: any, n: any, v: any) => onSelectPackage(e, n, v)}
                        handleSubmit={onSelectInputSubmit}
                        value={packageType}
                        multiple={true}
                        // isPlataniumHotel={isPlataniumHotel}
                        checkbox={true}
                        placeholder='Select baggage'
                        StartIcon={<LuggageIcon />}
                      // ids='stops'
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      // width: { md: 'auto', sm: '31.5%', xs: '100%' },
                      gridArea: 'input4',
                      '& .custom-dropdown .dropdown-toggle svg': {
                        marginTop: '3px',
                      },
                      my: { md: '20px', xs: '0' },
                      '& .MuiSvgIcon-root': {
                        fill: isPlataniumHotel
                          ? colors.basics.plataniumGreenColor + '!important'
                          : colors.basics.primary,
                        width: '18px',
                        height: '18px',
                      },
                    }}
                    ref={departureTimeOutboundRef}
                  >
                    <Typography variant='body1' className='title-style'>
                      Outbound departure time
                    </Typography>
                    <CustomSelectInput
                      // ids={'departureTime'}
                      data={dynamicDepartureTimes?.length > 0 ? dynamicDepartureTimes.map((v) => v.day) : []}
                      subData={
                        dynamicDepartureTimes?.length > 0 ? dynamicDepartureTimes.map((v) => v.time) : []
                      }
                      multiple={true}
                      checkbox={true}
                      // onSubmit={filteredData}
                      ids={`departureTime-flight-${idToAdd}`}
                      keys={`departureTime-flight-${idToAdd}`}
                      // keys={"departureTime"}
                      onChange={(e: any, n: any, v: any) => {
                        handleChangeDepartuteTime(e, n, v);
                      }}
                      value={departureTime}
                      placeholder='Select outbound time'
                      StartIcon={<AccessTimeFilledIcon />}
                    // ids={'departureTime'}
                    />
                  </Box>

                  <Box
                    width='100%'
                    m={{ sm: 0, xs: '0' }}
                    sx={{
                      // width: { md: '100%', sm: '31.5%', xs: '100%' },
                      gridArea: 'input5',
                      display: 'flex',
                      // justifyContent: "end"
                    }}
                  >
                    {/* <Box display='flex'> */}
                    <Box
                      sx={{
                        '& .dropdown-menu.show ': {
                          zIndex: '999999',
                        },
                        width: '100%',
                      }}
                      ref={durationOutboundRef}
                    >
                      <Typography variant='body1' className='title-style'>
                        Outbound duration
                      </Typography>
                      <DurationSlider
                        setIsLoading={setIsLoading}
                        setResetDisable={setResetDisable}
                        isPlataniumHotel={isPlataniumHotel}
                        setisRender={setisRender}
                        setOnChangeInbound={setOnChangeInbound}
                        currentTab={currentTab}
                        setDuration={setDuration}
                        duration={duration}
                        minMaxSlider={minMaxSlider}
                        hourMinutes={hourMinutes}
                        idToAdd={`${idToAdd}-mobile`}
                      />
                    </Box>
                    {/* <Divider
              sx={{
                display: { md: 'none', xs: 'flex' },
                m: { sm: '20px 0px 0 !important', xs: '20px -10px 0 !important' },
              }}
            /> */}
                  </Box>
                  <Box
                    sx={{
                      // width: { md: 'auto', sm: '31.5%', xs: '100%' },
                      gridArea: 'input6',
                      '& .custom-dropdown .dropdown-toggle svg': {
                        marginTop: '3px',
                      },
                      my: { md: '20px', xs: '0' },
                      '& .MuiSvgIcon-root': {
                        fill: isPlataniumHotel
                          ? colors.basics.plataniumGreenColor + '!important'
                          : colors.basics.primary,
                        width: '18px',
                        height: '18px',
                      },
                    }}
                    ref={departureTimeInboundRef}
                  >
                    <Typography variant='body1' className='title-style'>
                      Inbound departure time
                    </Typography>
                    <CustomSelectInput
                      // ids={'departureTimeInbound'}
                      data={
                        dynamicDepartureTimesInbound?.length > 0
                          ? dynamicDepartureTimesInbound.map((v) => v.day)
                          : []
                      }
                      subData={
                        dynamicDepartureTimesInbound?.length > 0
                          ? dynamicDepartureTimesInbound.map((v) => v.time)
                          : []
                      }
                      multiple={true}
                      checkbox={true}
                      // onSubmit={filteredData}
                      ids={`departureTimeInbound-flight-${idToAdd}`}
                      keys={`departureTimeInbound-flight-${idToAdd}`}
                      // keys={"departureTime"}
                      onChange={(e: any, n: any, v: any) => {
                        handleChangeDepartuteTimeInbound(e, n, v);
                      }}
                      value={departureTimeInbound}
                      placeholder='Select inbound time'
                      StartIcon={<AccessTimeFilledIcon />}
                    // ids={'departureTimeInbound'}
                    />
                  </Box>

                  <Box
                    width={'100%'}
                    m={{ sm: 0, xs: '0' }}
                    sx={{
                      display: 'flex',
                      gridArea: 'input7',
                    }}
                  >
                    <Box
                      mt={{ md: '20px', xs: '0' }}
                      sx={{
                        '& .dropdown-menu.show ': {
                          zIndex: '999999',
                        },
                        width: '100%',
                      }}
                      ref={durationInboundRef}
                    >
                      <Typography variant='body1' className='title-style'>
                        Inbound duration
                      </Typography>
                      <DurationSliderIn
                        setIsLoading={setIsLoading}
                        setResetDisable={setResetDisable}
                        isPlataniumHotel={isPlataniumHotel}
                        setisRender={setisRender}
                        setOnChangeInbound={setOnChangeInbound}
                        currentTab={currentTab}
                        setDurationInbound={setDurationInbound}
                        durationInbound={durationInbound}
                        minMaxSliderInbound={minMaxSliderInbound}
                        hourMinutesInbound={hourMinutesInbound}
                        idToAdd={`${idToAdd}-mobile`}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Grid container sx={{ display: { sm: 'flex', xs: 'block' }, width: '100%' }}>
          <Grid
            item
            md={3}
            xs={12}
            sx={{
              pr: { md: '10px', xs: '0px' },
              display: { md: 'block', sm: 'grid', xs: 'none' },
              gridGap: { md: '0', xs: '20px' },
              gridTemplateColumns: {
                // xs: 'repeat(1, 1fr)',
                // sm: 'repeat(3, 1fr)',
              },
              gridTemplateAreas: {
                xs: `
                "input1"
                "input2"
                "input3"
                "input4"
                "input5"
                "input6"
                "input7"
                "input8"
              `,
                sm: `
                "input1 input1 input1 input2 input2 input2"
                "input3 input3 input3 input8 input8 input8"
                "input4 input4 input4 input6 input6 input6"
                "input5 input5 input5 input7 input7 input7"
              `,
              },
            }}
          >
            <Box
              sx={{
                // width: { md: 'auto', sm: '31.5%', xs: '100%' },
                gridArea: 'input1',
                mb: { md: '20px', xs: '0' },
                '& .MuiSvgIcon-root': {
                  fill: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor + '!important'
                    : colors.basics.primary,
                  width: '20px',
                  height: '20px',
                },
              }}
            >
              <Typography variant='body1' className='title-style'>
                Airline
              </Typography>
              <Box ref={airlineRef}>
                <CustomSelectInput
                  // ids='airline'
                  disabled={!disabledFlightTabsInbound}
                  data={airlineFilterOptions}
                  onChange={(e: any, n: any, v: any) => onSelectAirline(e, n, v)}
                  value={airline}
                  multiple={true}
                  checkbox={true}
                  handleSubmit={() => {
                    setFilteredFlights((prev: any) => ({
                      ...prev,
                      outbound: [...filteredData()], // new array of outbound flights
                    }));
                  }}
                  // handleSubmit={() => setFilteredFlights(filteredData())}

                  placeholder='Select airline'
                  ids={`airline-flight-medium-${idToAdd}`}
                  keys={`airline-flight-medium-${idToAdd}`}
                  // keys={"airline"}
                  StartIcon={<AirplanemodeActiveIcon />}
                // ids='airline'
                />
              </Box>
            </Box>

            <Box
              sx={{
                // width: { md: 'auto', sm: '31.5%', xs: '100%' },
                gridArea: 'input2',
                '& .custom-dropdown .dropdown-toggle svg, & .MuiSvgIcon-root': {
                  marginTop: '3px',
                  fill: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor + '!important'
                    : colors.basics.primary,
                  fontSize: '1.4rem',
                },
              }}
              ref={departureAirportRef}
            >
              <Typography variant='body1' className='title-style'>
                Departure airport
              </Typography>
              <CustomSelectInput
                ids={`departure-flight-medium-${idToAdd}`}
                keys={`departure-flight-medium-${idToAdd}`}
                // keys={"departure"}
                // disabled={flightRoom?.searchCriteria?.departure?.departure_airport === "LON" ? false : true}
                data={departureAirportNames}
                // menuPaperPropsStyle={{ paddingRight: '8px' }}
                onChange={(e: any, n: any, v: any) => onSelectDepartureAirport(e, n, v)}
                multiple={true}
                checkbox={true}
                handleSubmit={() => { }}
                // handleSubmit={() => setFilteredFlights(filteredData())}
                value={departureAirport}
                // isPlataniumHotel={isPlataniumHotel}
                placeholder='Select departure airport'
                StartIcon={<LocationOnIcon />}
              // ids={'departure'}
              />
            </Box>
            <Box
              sx={{
                // width: { md: 'auto', sm: '31.5%', xs: '100%' },
                gridArea: 'input3',
                my: { md: '20px', xs: 0 },
                '& .MuiSvgIcon-root': {
                  fill: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor + '!important'
                    : colors.basics.primary,
                  width: '20px',
                  height: '22px',
                },
              }}
            >
              <Box
                width='100%'
                sx={{
                  '& .dropdown-menu.show': {
                    zIndex: '99999',
                  },
                }}
                ref={stopRef}
              >
                <Typography variant='body1' className='title-style'>
                  Stops
                </Typography>
                <CustomSelectInput
                  ids={`stops-flight-medium-${idToAdd}`}
                  keys={`stops-flight-medium-${idToAdd}`}
                  // keys={'stops'}
                  data={stops.map((stop: any) => stop.label)}
                  onChange={(e: any, n: any, v: any) => onSelectStopsChange(e, n, v)}
                  handleSubmit={onSelectInputSubmit}
                  value={Object.values(flightStops[currentTab === 'Outbound' ? 'Outbound' : 'Inbound']).map(
                    (item: any) => item
                  )}
                  multiple={true}
                  // isPlataniumHotel={isPlataniumHotel}
                  checkbox={true}
                  placeholder='Select stops'
                  StartIcon={<AirlineStopsIcon />}
                // ids='stops'
                />
              </Box>
            </Box>
            <Box
              sx={{
                // width: { md: 'auto', sm: '31.5%', xs: '100%' },
                gridArea: 'input8',
                my: { md: '20px', xs: 0 },
                '& .MuiSvgIcon-root': {
                  fill: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor + '!important'
                    : colors.basics.primary,
                  width: '20px',
                  height: '22px',
                },
              }}
            >
              <Box
                width='100%'
                sx={{
                  '& .dropdown-menu.show': {
                    zIndex: '99999',
                  },
                }}
                ref={packageRef}
              >
                <Typography variant='body1' className='title-style'>
                  Baggage
                </Typography>
                <CustomSelectInput
                  ids={`package-flight-medium-${idToAdd}`}
                  keys={`package-flight-medium-${idToAdd}`}
                  // keys={'package'}
                  data={packages}
                  onChange={(e: any, n: any, v: any) => onSelectPackage(e, n, v)}
                  handleSubmit={onSelectInputSubmit}
                  value={packageType}
                  multiple={true}
                  // isPlataniumHotel={isPlataniumHotel}
                  checkbox={true}
                  placeholder='Select baggage'
                  StartIcon={<LuggageIcon />}
                // ids='stops'
                />
              </Box>
            </Box>
            <Box
              sx={{
                // width: { md: 'auto', sm: '31.5%', xs: '100%' },
                gridArea: 'input4',
                '& .custom-dropdown .dropdown-toggle svg': {
                  marginTop: '3px',
                },
                my: { md: '20px', xs: '0' },
                '& .MuiSvgIcon-root': {
                  fill: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor + '!important'
                    : colors.basics.primary,
                  width: '18px',
                  height: '18px',
                },
              }}
              ref={departureTimeOutboundRef}
            >
              <Typography variant='body1' className='title-style'>
                Outbound departure time
              </Typography>
              <CustomSelectInput
                // ids={'departureTime'}
                data={dynamicDepartureTimes?.length > 0 ? dynamicDepartureTimes.map((v) => v.day) : []}
                subData={dynamicDepartureTimes?.length > 0 ? dynamicDepartureTimes.map((v) => v.time) : []}
                multiple={true}
                checkbox={true}
                // onSubmit={filteredData}
                ids={`departureTime-flight-medium-${idToAdd}`}
                keys={`departureTime-flight-medium-${idToAdd}`}
                // keys={"departureTime"}
                onChange={(e: any, n: any, v: any) => {
                  handleChangeDepartuteTime(e, n, v);
                }}
                value={departureTime}
                placeholder='Select outbound time'
                StartIcon={<AccessTimeFilledIcon />}
              // ids={'departureTime'}
              />
            </Box>

            <Box
              sx={{
                // width: { md: 'auto', sm: '31.5%', xs: '100%' },
                gridArea: 'input5',
                '& .custom-dropdown .dropdown-toggle svg': {
                  marginTop: '3px',
                },
                my: { md: '20px', xs: '0' },
                '& .MuiSvgIcon-root': {
                  fill: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor + '!important'
                    : colors.basics.primary,
                  width: '18px',
                  height: '18px',
                },
              }}
              ref={departureTimeInboundRef}
            >
              <Typography variant='body1' className='title-style'>
                Inbound departure time
              </Typography>
              <CustomSelectInput
                // ids={'departureTimeInbound'}
                data={
                  dynamicDepartureTimesInbound?.length > 0
                    ? dynamicDepartureTimesInbound.map((v) => v.day)
                    : []
                }
                subData={
                  dynamicDepartureTimesInbound?.length > 0
                    ? dynamicDepartureTimesInbound.map((v) => v.time)
                    : []
                }
                multiple={true}
                checkbox={true}
                // onSubmit={filteredData}
                ids={`departureTimeInbound-flight-medium-${idToAdd}`}
                keys={`departureTimeInbound-flight-medium-${idToAdd}`}
                // keys={"departureTime"}
                onChange={(e: any, n: any, v: any) => {
                  handleChangeDepartuteTimeInbound(e, n, v);
                }}
                value={departureTimeInbound}
                placeholder='Select inbound time'
                StartIcon={<AccessTimeFilledIcon />}
              // ids={'departureTimeInbound'}
              />
            </Box>

            <Box
              width='100%'
              m={{ sm: 0, xs: '0' }}
              sx={{
                // width: { md: '100%', sm: '31.5%', xs: '100%' },
                gridArea: 'input6',
                display: 'flex',
                // justifyContent: "end"
              }}
            >
              {/* <Box display='flex'> */}
              <Box
                sx={{
                  '& .dropdown-menu.show ': {
                    zIndex: '999999',
                  },
                  width: '100%',
                }}
                ref={durationOutboundRef}
              >
                <Typography variant='body1' className='title-style'>
                  Outbound duration
                </Typography>
                <DurationSlider
                  setIsLoading={setIsLoading}
                  setResetDisable={setResetDisable}
                  isPlataniumHotel={isPlataniumHotel}
                  setisRender={setisRender}
                  setOnChangeInbound={setOnChangeInbound}
                  currentTab={currentTab}
                  setDuration={setDuration}
                  duration={duration}
                  minMaxSlider={minMaxSlider}
                  hourMinutes={hourMinutes}
                  idToAdd={idToAdd}
                />
              </Box>
              {/* <Divider
              sx={{
                display: { md: 'none', xs: 'flex' },
                m: { sm: '20px 0px 0 !important', xs: '20px -10px 0 !important' },
              }}
            /> */}
            </Box>

            <Box
              width={'100%'}
              m={{ sm: 0, xs: '0' }}
              sx={{
                display: 'flex',
                gridArea: 'input7',
              }}
            >
              <Box
                mt={{ md: '20px', xs: '0' }}
                sx={{
                  '& .dropdown-menu.show ': {
                    zIndex: '999999',
                  },
                  width: '100%',
                }}
                ref={durationInboundRef}
              >
                <Typography variant='body1' className='title-style'>
                  Inbound duration
                </Typography>
                <DurationSliderIn
                  setIsLoading={setIsLoading}
                  setResetDisable={setResetDisable}
                  isPlataniumHotel={isPlataniumHotel}
                  setisRender={setisRender}
                  setOnChangeInbound={setOnChangeInbound}
                  currentTab={currentTab}
                  setDurationInbound={setDurationInbound}
                  durationInbound={durationInbound}
                  minMaxSliderInbound={minMaxSliderInbound}
                  hourMinutesInbound={hourMinutesInbound}
                  idToAdd={idToAdd}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={9} xs={12}>
            <Box sx={{ mt: { md: '24px', xs: '0' } }} width='100%'>
              <Box
                display={{ sm: 'flex', xs: 'block' }}
                width='100%'
                my={1}
                sx={{
                  '& .MuiTabs-root': {
                    backgroundColor: 'transparent',
                    p: 0,
                    m: 0,
                  },
                  '& .MuiButtonBase-root ': {
                    border: 'none',

                    width: 0,

                    ml: 0,
                    '&:hover': {},
                  },
                  '&  .Mui-selected #tabText ': {
                    borderBottom: 'none',
                  },
                  '& .MuiButtonBase-root:hover #tabText': {
                    borderBottom: 'none',
                  },
                }}
              >
                <Grid
                  container
                  columnSpacing={1}
                  rowSpacing={1}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                // p={{ sm: 0, xs: '0 10px' }}
                >
                  <Grid sx={{ order: { md: '1', xs: '2' }, alignItems: 'left' }} md={6} lg={6} xs={3} item>
                    {/* <Box
                    sx={{
                      width: '100%',
                      cursor: "auto",
                      '& .Mui-selected': {
                        borderRadius: 0,
                      },
                      '& .MuiTabs-root .Mui-selected #tabText ': {
                        borderBottom: 'none',
                        '&:hover': {
                          borderBottom: 'none !important',
                        }
                      },
                      '& .MuiTabs-flexContainer': {
                        justifyContent: { sm: 'inherit', xs: 'space-between' },
                      },
                      '& button': {
                        p: 0,
                        flex: { md: 0, xs: '1' },
                      },
                      '& .MuiTabs-root': {
                        overflow: 'unset !important',
                        height: 'auto',
                      },
                      '& .MuiTabs-fixed ': {
                        overflow: 'unset !important',
                      },
                      '& .MuiBox-root ': {
                        display: 'flex',
                      },
                      '& .MuiTypography-body1': {
                        marginRight: '4px',
                        alignItems: 'center',
                        display: 'flex',
                      },
                      '& .MuiTypography-h2 ': {
                        mb: { md: '4px', xs: '0' },
                      },
                      '& .MuiButtonBase-root': {
                        p: '0 !important',
                        minWidth: '300px',
                        alignItems: 'flex-start',
                      },
                    }}
                  >
                    <TabComponent
                      tabs={[
                        {
                          title: `Outbound`,
                          disabled: !disabledFlightTabsInbound,
                          subTitle: holiday.flight.outbound[0].outbound_date?.slice(0, -6) || '',
                        },
                        {
                          title: 'Inbound',
                          disabled: disabledFlightTabsInbound,
                          subTitle: holiday.flight.inbound[0].inbound_date?.slice(0, -6) || '',
                        },
                      ]}
                      hideTabs={true}
                      value={currentTab}
                      handleChange={() => { }}
                    />
                  </Box> */}
                  </Grid>
                  {/* <Grid
                  md={6}
                  lg={6}
                  xs={9}
                  item
                  sx={{
                    order: { md: '2', xs: '2' },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    zIndex: '9999',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      '& svg path': {
                        fill: colors.grey[10],
                      },
                    }}
                  >
                    <Box>
                      <Typography lineHeight={1.2} variant='body2' fontSize='18px'>
                        {currentTab === TabType.Outbound && departureLocation}
                        {currentTab === TabType.Inbound &&
                          (ArrivalLocation ?? hotelDetails?.HotelInfo?.Destination?.name)}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', mx: '23px' }}>
                      <RightChevron />
                    </Box>
                    <Box>
                      <Typography lineHeight={1.2} variant='body2' fontSize='18px'>
                        {currentTab === TabType.Outbound &&
                          (ArrivalLocation ?? hotelDetails?.HotelInfo?.Destination?.name)}
                        {currentTab === TabType.Inbound && departureLocation}
                      </Typography>
                    </Box>
                  </Box>
                </Grid> */}
                </Grid>
                {/* <Divider sx={{ display: { md: 'none', xs: 'flex' }, backgroundColor: "red" }} /> */}
              </Box>

              <Box
                sx={{
                  '& .MuiAccordionDetails-root': {
                    p: 0,
                  },
                  '& .root_accordion.expanded': {
                    borderBottom: 'none',
                  },
                }}
              >
                {flightOptions?.length > 0 && filteredFlights && (
                  <Box
                  // minHeight={
                  //   currentTab === TabType.Inbound
                  //     ? filteredFlights?.outbound?.filter(
                  //       (ele: any) => ele.psw_result_id === selectedPswId && isEmpty(ele.inboundFlight) !== ''
                  //     )?.length +
                  //     1 * tabCurrent?.current?.clientHeight
                  //     : 'auto'
                  // }
                  >
                    {matchedFlights?.map((ele: any) => ele)?.length &&
                      !matchedFlights.every(
                        (obj: any) =>
                          Array.isArray(obj['inboundFlights']) && obj['inboundFlights']?.length === 0
                      )
                      ? matchedFlights?.map((item: any, i: number) => {
                        return i <= flightPage ? (
                          // <TabPanel key={i} currentTab={currentTab} tab={TabType.Outbound}>
                          // <Suspense fallback={<div>Loading...</div>}>
                          <FlightDetailsAccordion
                            setSaveRooms={setSaveRooms}
                            contiuneToBookClick={contiuneToBookClick}
                            setContiuneToBookClick={setContiuneToBookClick}
                            setFirstTimeLoad={setFirstTimeLoad}
                            firstTimeLoad={firstTimeLoad}
                            inboundSorting={inboundSorting}
                            setInboundSorting={setInboundSorting}
                            isRetrieveBasketApiCall={isRetrieveBasketApiCall}
                            retrieveholidayBasketRetrieve={retrieveholidayBasketRetrieve}
                            sortRoom={sortRoom}
                            saveBasket={saveBasket}
                            getChangeFlight={getChangeFlight}
                            getHolidayPackageRetrieveData={getHolidayPackageRetrieveData}
                            matchedFlights={matchedFlights}
                            // handleSelectFlights={handleSelectFlights}
                            openChangeFlightDrawer={openChangeFlightDrawer}
                            setOpenChangeFlightDrawer={setOpenChangeFlightDrawer}
                            showPreviousComponent={showPreviousComponent}
                            setIsDirect={setIsDirect}
                            setOnChangeMultiRoom={setOnChangeMultiRoom}
                            // handleConfirmSelectedFlight={handleConfirmSelectedFlight}
                            setActiveIndex={setActiveIndex}
                            setisRender={setisRender}
                            minMaxSlider={minMaxSlider}
                            duration={duration}
                            setRenderInitially={setRenderInitially}
                            setDisableButton={setDisableButton}
                            current={currentTab === TabType.Outbound ? outboundFlightIndex++ : -1}
                            type={currentTab}
                            targetObj={flightOptions[3][0][item.targetSelfId] || ''}
                            setCurrentTab={setCurrentTab}
                            currentTab={currentTab}
                            departureLocation={departureLocation}
                            ArrivalLocation={ArrivalLocation}
                            item={item}
                            cheapestFlightPSW={cheapestFlightPSW}
                            // psw_result_id={item.psw_result_id}
                            psw_result_id={
                              item?.outboundFlight?.outboundFlight[0]?.FlightPswFlight?.psw_result_id
                            }
                            setSelectedPswId={setSelectedPswId}
                            selectedPswId={selectedPswId}
                            selectedOutboundFlight={selectedOutboundFlight}
                            setSelectedOutboundFlight={setSelectedOutboundFlight}
                            expanded={innerAccordionExpanded}
                            setInnerAccordionExpanded={setInnerAccordionExpanded}
                            setSelectedInboundFlight={setSelectedInboundFlight}
                          // children={<></>}
                          // handleSelectFlight={(
                          //   isEqual: boolean,
                          //   type: string,
                          //   psw_result_id: string,
                          //   data: any
                          // ) => handleSelectFlight(isEqual, type, psw_result_id, data)}
                          // setSelectedOptions={setSelectedOptions}
                          />
                        ) : // </Suspense>
                          // </TabPanel>
                          null;
                      })
                      : // : currentTab === TabType.Outbound
                      isFlightDataLoad &&
                      !loading &&
                      matchedFlights.every(
                        (obj: any) =>
                          Array.isArray(obj['inboundFlights']) && obj['inboundFlights']?.length === 0
                      ) && (
                        <Typography
                          textAlign='center'
                          my={5}
                          variant='h1'
                          color={
                            isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary
                          }
                        >
                          {isLoading ? 'Loading...' : ' No Flights Found'}
                        </Typography>
                      )}
                    {/* // : "null"} */}
                    {/* {currentTab === TabType.Inbound
                  ?  */}
                    {/* {filteredFlights?.inbound?.filter((ele: any) => ele.psw_result_id === selectedPswId)?.length
                  ? removeDuplicateOutboundFlights(
                    filteredFlights?.inbound
                      // filteredFlights
                      ?.filter((ele: any) => ele.psw_result_id === selectedPswId),
                    // ?.filter((ele: any) => ele.psw_result_id),
                    'inboundFlight'
                  ).map((item: any, i: number) => {
                    if (isEmpty(item.inboundFlight) !== '') {
                      return ( */}
                    {/* // <TabPanel refs={tabCurrent} key={i} currentTab={currentTab} tab={TabType.Outbound}> */}
                    {/* // <Suspense fallback={<div>Loading...</div>}> */}
                    {/* <FlightDetailsInboundAccordion
                          handleSelectFlights={handleSelectFlights}
                          openChangeFlightDrawer={openChangeFlightDrawer}
                          setOpenChangeFlightDrawer={setOpenChangeFlightDrawer}
                          showPreviousComponent={showPreviousComponent}
                          setIsDirect={setIsDirect}
                          setOnChangeMultiRoom={setOnChangeMultiRoom}
                          handleConfirmSelectedFlight={handleConfirmSelectedFlight}
                          setActiveIndex={setActiveIndex}
                          setisRender={setisRender}
                          minMaxSlider={minMaxSlider}
                          duration={duration}
                          setRenderInitially={setRenderInitially}
                          setDisableButton={setDisableButton}
                          current={i}
                          type={currentTab}
                          setCurrentTab={setCurrentTab}
                          currentTab={currentTab}
                          departureLocation={departureLocation}
                          ArrivalLocation={ArrivalLocation}
                          item={item?.inboundFlight}
                          targetObj={flightOptions[3][0][item.targetSelfId] || ''}
                          setSelectedPswId={setSelectedPswId}
                          selectedInboundFlight={selectedInboundFlight}
                          setSelectedInboundFlight={setSelectedInboundFlight}
                          psw_result_id={String(item?.psw_result_id)}
                          expanded={innerAccordionExpanded}
                          setInnerAccordionExpanded={setInnerAccordionExpanded}
                          children={<></>}
                          handleSelectFlight={(
                            isEqual: boolean,
                            type: string,
                            psw_result_id: string,
                            data: any
                          ) => handleSelectFlight(isEqual, type, psw_result_id, data)}
                        /> */}
                    {/* // </Suspense> */}
                    {/* // </TabPanel> */}
                    {/* ); */}
                    {/* } */}
                    {/* else { */}
                    {/* return <></>; */}
                    {/* } */}
                    {/* }) */}
                    {/* : isFlightDataLoad &&
                  !loading && (
                    <Typography
                      textAlign='center'
                      variant='h1'
                      my={5}
                      color={isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary}
                    >
                      No Flights Foundwww
                    </Typography>
                  ) */}
                    {/* // : null} */}
                    {/* } */}
                  </Box>
                )}
              </Box>
              {!loading &&
                !isLoading &&
                !matchedFlights.every(
                  (obj: any) => Array.isArray(obj['inboundFlights']) && obj['inboundFlights']?.length === 0
                ) &&
                matchedFlights?.filter((ele: any) => ele?.outboundFlight)?.length > flightPage && (
                  // filteredFlights?.filter((ele: any) => ele.outboundFlight)?.length > flightPage && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mt: 2,
                      '& .MuiButtonBase-root:hover': {
                        color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                      },
                    }}
                  >
                    <Button
                      variant='text'
                      sx={{
                        border: 'none !important',
                        color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                      }}
                      onClick={() => setFlightPage(flightPage + 10)}
                    >
                      Load More
                    </Button>
                  </Box>
                )}
              {/* <Divider /> */}
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                m={{ xs: '60px 10px 30px 0', md: '100px 10px 65px 0' }}
                width='100%'
                position='relative'
              >
                <Box
                  sx={{
                    '& .MuiButtonBase-root': {
                      width: '138px',
                      border: `1px solid ${colors.basics.primary}`,
                    },
                  }}
                  mt={{ sm: 0, xs: '20px' }}
                ></Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

interface DurationSliderProps {
  setDuration: (value: any) => void;
  hourMinutes: { value: number; label: string }[];
  duration: number[];
  minMaxSlider?: any;
  setOnChangeInbound?: any;
  currentTab?: any;
  setisRender: (value: boolean) => void;
  isPlataniumHotel: boolean;
  setIsLoading: any;
  setResetDisable: any;
  idToAdd?: any;
}

const DurationSlider: FC<DurationSliderProps> = ({
  isPlataniumHotel,
  duration,
  setDuration,
  hourMinutes,
  minMaxSlider,
  setOnChangeInbound,
  currentTab,
  setisRender,
  setIsLoading,
  setResetDisable,
  idToAdd,
}) => {
  const startingTime =
    hourMinutes?.filter((num: any) => num?.value === duration[0])[0]?.label.split(':') || [];
  const endingTime = hourMinutes?.filter((num: any) => num?.value === duration[1])[0]?.label.split(':') || [];

  const start1 = startingTime.length > 0 ? (startingTime[0] == '00' ? '' : startingTime[0] + 'hr ') : '';
  const end1 = endingTime.length > 0 ? (endingTime[0] == '00' ? '' : endingTime[0] + 'hr ') : '';
  const start2 = startingTime.length > 0 ? (startingTime[1] == '00' ? '0m' : startingTime[1] + 'm') : '';
  const end2 = endingTime.length > 0 ? (endingTime[1] == '00' ? '0m' : endingTime[1] + 'm') : '';

  const handleChangeDuration = (event: Event, newValue: number | number[], activeThumb?: number) => {
    setIsLoading(false);
    setResetDisable(true);
    if (!Array.isArray(newValue)) {
      return;
    }
    // console.log('newValue :', newValue);
    setDuration(newValue);
    if (currentTab === 'Inbound') {
      setOnChangeInbound(true);
      setisRender(true);
    }
    getDurationValue();
  };

  const getDurationValue = useCallback(() => {
    if (startingTime.length > 0 && endingTime.length > 0) {
      return start1 + start2 + ' - ' + end1 + end2;
    }
  }, [duration, hourMinutes]);

  return (
    <Box
      sx={{
        '& .MuiSvgIcon-root': {
          fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + '!important' : colors.basics.primary,
          width: '20px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          mt: '4px',
        },
      }}
    >
      <CustomSelectInput
        StartIcon={<TimelapseIcon />}
        ids={`time-filter-flight-${idToAdd}`}
        keys={`time-filter-flight-${idToAdd}`}
        value={getDurationValue() || '00:10 - 23:59'}
        placeholder='Pick Duration'
        children={
          <Box width='100%'>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant='subtitle2'>{start1 + start2}</Typography>
              <Typography variant='subtitle2'>{end1 + end2}</Typography>
            </Box>
            <Box
              sx={{
                '& .MuiSlider-rail': {
                  backgroundColor: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor
                    : colors.basics.primary,
                },
                '& .MuiSlider-thumb': {
                  color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                },
                '& .MuiSlider-track': {
                  color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                },
                '& .MuiSlider-thumb, &:hover, &.Mui-focusVisible, &.Mui-active': { boxShadow: 'none' },
              }}
              px={2}
            >
              <Slider
                min={minMaxSlider[0]}
                value={duration}
                onChange={(e: any, value: any) => handleChangeDuration(e, value)}
                valueLabelDisplay='off'
                max={minMaxSlider[1]}
                sx={{ width: { xs: '100%' } }}
                aria-labelledby='range-slider'
                onChangeCommitted={() => setDuration(duration)}
              />
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

interface DurationSliderInProps {
  setDurationInbound: (value: any) => void;
  hourMinutesInbound: { value: number; label: string }[];
  durationInbound: number[];
  minMaxSliderInbound?: any;
  setOnChangeInbound?: any;
  currentTab?: any;
  setisRender: (value: boolean) => void;
  isPlataniumHotel: boolean;
  setIsLoading: any;
  setResetDisable: any;
  idToAdd?: any;
}
const DurationSliderIn: FC<DurationSliderInProps> = ({
  isPlataniumHotel,
  durationInbound,
  setDurationInbound,
  hourMinutesInbound,
  minMaxSliderInbound,
  setOnChangeInbound,
  currentTab,
  setIsLoading,
  setisRender,
  setResetDisable,
  idToAdd,
}) => {
  const startingTime =
    hourMinutesInbound?.filter((num: any) => num?.value === durationInbound[0])[0]?.label.split(':') || [];
  const endingTime =
    hourMinutesInbound?.filter((num: any) => num?.value === durationInbound[1])[0]?.label.split(':') || [];
  // console.log('endingTime :', endingTime);

  const start1 = startingTime.length > 0 ? (startingTime[0] == '00' ? '' : startingTime[0] + 'hr ') : '';
  const end1 = endingTime.length > 0 ? (endingTime[0] == '00' ? '' : endingTime[0] + 'hr ') : '';
  const start2 = startingTime.length > 0 ? (startingTime[1] == '00' ? '0m' : startingTime[1] + 'm') : '';
  const end2 = endingTime.length > 0 ? (endingTime[1] == '00' ? '0m' : endingTime[1] + 'm') : '';

  const handleChangeDuration = (event: Event, newValue: number | number[], activeThumb?: number) => {
    setIsLoading(false);
    setResetDisable(true);
    if (!Array.isArray(newValue)) {
      return;
    }
    // console.log('newValueINBOUND :', newValue);
    setDurationInbound(newValue);
    // if (currentTab === 'Outbound') {
    setOnChangeInbound(true);
    setisRender(true);
    // }
    getDurationValue();
  };

  const getDurationValue = useCallback(() => {
    if (startingTime.length > 0 && endingTime.length > 0) {
      return start1 + start2 + ' - ' + end1 + end2;
    }
  }, [durationInbound, hourMinutesInbound]);

  return (
    <Box
      sx={{
        '& .MuiSvgIcon-root': {
          fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + '!important' : colors.basics.primary,
          width: '20px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          mt: '4px',
        },
      }}
    >
      <CustomSelectInput
        StartIcon={<TimelapseIcon />}
        ids={`time-filter-inbound-flight-${idToAdd}`}
        keys={`time-filter-inbound-flight-${idToAdd}`}
        value={getDurationValue() || '00:10 - 23:59'}
        placeholder='Pick Duration'
        children={
          <Box width='100%'>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant='subtitle2'>{start1 + start2}</Typography>
              <Typography variant='subtitle2'>{end1 + end2}</Typography>
            </Box>
            <Box
              sx={{
                '& .MuiSlider-rail': {
                  backgroundColor: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor
                    : colors.basics.primary,
                },
                '& .MuiSlider-thumb': {
                  color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                },
                '& .MuiSlider-track': {
                  color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                },
                '& .MuiSlider-thumb, &:hover, &.Mui-focusVisible, &.Mui-active': { boxShadow: 'none' },
              }}
              px={2}
            >
              <Slider
                min={minMaxSliderInbound[0]}
                value={durationInbound}
                onChange={(e: any, value: any) => handleChangeDuration(e, value)}
                valueLabelDisplay='off'
                max={minMaxSliderInbound[1]}
                sx={{ width: { xs: '100%' } }}
                aria-labelledby='range-slider'
                onChangeCommitted={() => setDurationInbound(durationInbound)}
              />
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default ChangeFlightDrawer;
