import { Box } from '@mui/material';
import React from 'react';
import { colors } from '../styles/colors';
interface flightTrackProps {
  stopPoint?: any;
  singleLine?: boolean;
}
export const FlightTrack = ({ stopPoint, singleLine }: flightTrackProps) => {
  return (
    <Box position='relative'>
      <Box
        height={singleLine === true ? '1px' : '4px'}
        bgcolor={colors.grey[10]}
        position='relative'
        width='100%'
        overflow='hidden'
        top='2px'
      ></Box>
      <Box display='flex' justifyContent='space-between' position='absolute' top={0} right={0} left={0}>
        <Box
          width={singleLine === true ? '10px' : '20px'}
          height={singleLine === true ? '5px' : '7px'}
          bgcolor={colors.basics.secondary}
          border={`1px solid ${colors.basics.black}`}
        />
        {[...Array(stopPoint)].map((e, i: number) => {
          return (
            <Box
              key={i}
              width={singleLine === true ? '10px' : '20px'}
              height={singleLine === true ? '5px' : '7px'}
              bgcolor={colors.basics.secondary}
              border={`1px solid ${colors.basics.black}`}
            />
          );
        })}

        <Box
          width={singleLine === true ? '10px' : '20px'}
          height={singleLine === true ? '5px' : '7px'}
          bgcolor={colors.basics.secondary}
          border={`1px solid ${colors.basics.black}`}
        />
      </Box>
    </Box>
  );
};
