import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { Typography } from '@mui/material';
import { colors } from '../styles/colors';
import { useAppSelector } from '../hooks/useAppReducer';
import useWindowWidth from '../hooks/useWindowWidth';
import StepButton from '@mui/material/StepButton';
interface stepperCardProps { }

const steps = [
  { title: 'Select your holiday', sub: 'Accommodation & flight' },
  { title: 'Customise your holiday', sub: 'Flight, room & extras' },
  { title: 'Book your holiday', sub: 'Contact & payment details' },
];

export function StepperCard(props: stepperCardProps) {
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const stepperState = useAppSelector((state) => state.stepper.stepper);
  const { flightRoom } = useAppSelector((state: any) => state.flightRoom);
  const windowWidth = useWindowWidth()
  const { holidayPackage } = useAppSelector(
    (state) => state.holidayPackage
  );
  const hotelDetails = holidayPackage?.Packages?.results?.filter((f: any) => f?.SearchResult?.hotel_id === flightRoom?.hotel_id)
  const isPlataniumHotel = hotelDetails?.length > 0 ? Number(hotelDetails[0]?.HotelInfo?.platinumHotel) !== 0 : false

  useEffect(() => {
    let obj: any = {}
    for (let i = 0; i < (stepperState + 1); i++) {
      obj[i] = true
    }
    setCompleted(obj)
  }, [stepperState])

  return (
    <Box sx={{
      border: `1px solid ${colors.grey[30]}`,
      padding: "7px",
      width: '100%',
      "& .MuiStep-root": {
        paddingRight: windowWidth < 360 ? 0 : "8px",
        '&:first-of-type': {
          paddingLeft: 0
        },
        paddingLeft: windowWidth < 680 ? (windowWidth < 420 ? windowWidth < 351 ? "8px" : "18px" : "35px") : 0,
      },
      "& .Mui-completed ": {
        backgroundColor: colors.basics.lightmain
      },
      "& .MuiStep-horizontal": {
        width: "33.33%",
      },
      "& .MuiStepper-horizontal": {
        "& .MuiStepConnector-root": {
          display: "none",
          flex: 0
        },
      }
    }}>
      <Stepper activeStep={stepperState}>
        {steps.map((label, index) => {
          return <Step className={stepperState === 2 ? "stepper-last-class" : (stepperState !== 2 && stepperState === index) ? "active-content" : ""} sx={{
            position: "relative",
            "& svg": {
              display: "none"
            },
            "& .MuiStepLabel-root": {
              marginLeft: windowWidth > 991 && index === 0 ? "-20%" : "unset"
            },
            "& .MuiStepLabel-label": {
              color: stepperState === index ? stepperState === 1 || stepperState === 2 ? isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary : colors.basics.primary + " !important" : colors.basics.black,
              textAlign: windowWidth > 530 ? "left" : "center",
              fontWeight: { xs: 500, sm: 700 + "!important" },
              fontSize: windowWidth < 760 ? "12px" : windowWidth < 992 ? "13px" : "16px",
              display: windowWidth < 992 ? "flex" : "block",
              flexDirection: windowWidth > 375 && windowWidth < 1200 ? "row !important" : "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: windowWidth > 530 ? "unset" : windowWidth < 468 ? "50px" : "100px",
              whiteSpace: windowWidth > 670 ? "nowrap" : "unset",
              lineHeight: 1.2
            },
            "&  h6": {
              marginLeft: 2,
              marginTop: "3px",
              fontSize: windowWidth < 760 ? "12px" : windowWidth < 992 ? "13px" : "16px",
            },
            "& .index": {
              paddingRight: "9px",
              fontWeight: 400,
              paddingBottom: { xs: "2px", sm: "unset" }
            },
            "& button ": {
              padding: 0,
              margin: 0,
              height: windowWidth > 991 ? "72px" : windowWidth > 530 ? "52px" : "72px",
              paddingRight: index === 2 ? 0 : (windowWidth > 720 ? "48px" : windowWidth > 686 ? "18px" : 0),

            },

          }} key={label.title} completed={completed[index]}>
            <StepButton color="inherit">
              <span className='index'>{index + 1}</span>
              {label.title}
              <Typography
                variant='subtitle2'
                ml='20px'
                textAlign={"left"}
                display={{ xs: 'none', md: 'block' }}
                sx={{ fontSize: { sm: '16px', xs: '12px' } }}
              >
                {label.sub}
              </Typography>
            </StepButton>
          </Step>
        })}
      </Stepper>
    </Box >
  );
}