import Tabs from '@mui/material/Tabs';
import { Tab, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface props {
  tabs: string[] | any;
  value: string | object;
  handleChange: (e: any) => void;
  tabScrollRef?: any;
  variant?: any;
  scrollButtons?: any;
  tabStyle?: any | React.CSSProperties;
  labels?: string[];
  hideTabs?: boolean;
  allowScrollButtonsMobile?: any
  useBrokenWord?: any
}

const TextWithLineBreak = ({ text }: { text: string }) => {
  const formattedText = text.includes(" ") ? text.replace(" ", "\n") : text;

  return (
    <Typography variant="body2" id="tabText" sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
      {formattedText}
    </Typography>
  );
};

export const TabComponent = ({
  tabs,
  value,
  handleChange,
  tabScrollRef,
  variant,
  scrollButtons,
  tabStyle,
  labels,
  hideTabs,
  allowScrollButtonsMobile,
  useBrokenWord
}: props) => {

  return (
    <div style={{ width: '100%', zIndex: 999 }} ref={tabScrollRef}>
      <Tabs value={value} variant={variant} allowScrollButtonsMobile={allowScrollButtonsMobile ? allowScrollButtonsMobile : false} scrollButtons={scrollButtons}>
        {tabs.map((item: any, index: number) => {
          return (
            <Tab
              disabled={item.disabled ? item.disabled : false}
              disableRipple
              label={
                item?.title ? (
                  <Box
                    sx={{
                      '& p': {
                        height: 'auto !important',
                        borderBottom: '0 !important',
                      },
                    }}
                  >
                    <Typography variant='body1' id='tabText' sx={{ color: item.disabled ? "#00000061 !important" : "" }} fontWeight={500} pb='8px'>
                      {item.title}
                    </Typography>
                    <Typography variant='h2' textAlign='left' sx={{ color: item.disabled ? "#00000061 !important" : "" }} mb='10px' id='tabText'>
                      {item.subTitle}
                    </Typography>
                  </Box>
                ) : useBrokenWord ? (
                  <TextWithLineBreak text={labels?.length ? labels[index] : item} />
                ) : (
                  <Typography variant="body2" id="tabText" sx={{ wordBreak: "break-word" }}>
                    {labels?.length ? labels[index] : item}
                  </Typography>
                )
              }
              key={index + 1}
              value={typeof tabs[index] === 'string' ? tabs[index] : tabs[index]?.valueOf()?.title}
              onClick={() =>
                handleChange(typeof tabs[index] === 'string' ? tabs[index] : tabs[index]?.valueOf()?.title)
              }
              sx={{ ...tabStyle, ...(hideTabs ? { display: value !== item.title ? 'none' : 'flex' } : {}) }}
            />
          );
        })}
      </Tabs>
    </div>
  );
};
