import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

interface Room {
  room: number;
  adults: number;
  infants: {
    child: number;
    age: string;
  }[];
  children: {
    child: number;
    age: string;
  }[];
}
export interface SearchCriteria {
  departure: {
    depart_airport_name: string;
    departure_airport: string;
    full_name: string;
  };
  destination: any;
  date: any;
  guest: Room[];
  class: string;
  night: any;
  adults: number;
  childrens: number;
  infants: number;
  child_age?: any;
  infant_age?: any;
}
interface FlightRoom {
  flightRoom: {
    callHolidaySummaryApi: boolean,
    flightSearchCriteriaId: any;
    hotelSearchCriteriaIds: any;
    holidayDetailLogId?: any;
    store_psw_result_id?: any
    hotelSearchCriteriaId_nextDay: any;
    psw_result_id: number | null;
    psw_result_id_combo: number | null;
    cheapestFlightPSW: null | string | any;
    roomids: string | null;
    psc_request_id: number | null;
    hotel_id: null | any;
    multiRooms?: any;
    diffPrice: any;
    storeMultiRoom?: any;
    selectedCarHired?: any;
    packageSearchId?: any,
    searchCriteriaId: string | null;
    selectHotelId: string | null;
    search_result_id: null | string;
    searchCriteria: SearchCriteria;
    storeSelectedFlights: {
      inbound: Record<any, any>;
      outbound: Record<any, any>;
    }
    selectedFlights: {
      inbound: Record<any, any>;
      outbound: Record<any, any>;
    };
    searchCriteria2: any;
    roomData: null | any;
    flightPrice: {
      price: null | string;
      isCheapest: boolean;
      tempPrice: string | null;
      cheapestFlightPrice: null | string;
    };
    initialPackagePrice: string | null;
    confirmSelection: {
      flights: boolean;
      room: boolean;
    },
    isNewFlightSelected: {
      flights: boolean;
      room: boolean;
    };
  };
}
const getDefaultDeparture = () => {
  if (process.env.REACT_APP_PROJECT_COUNTRY === "ie") {
    return {
      depart_airport_name: "Dublin",
      departure_airport: "DUB",
      full_name: "Dublin DUB",
    };
  }
  if (process.env.REACT_APP_PROJECT_COUNTRY === "UK") {
    return {
      depart_airport_name: "London All Airports",
      departure_airport: "LON",
      full_name: "London All Airports LON",
    };
  }
  return {
    depart_airport_name: "London All Airports",
    departure_airport: "LON",
    full_name: "London All Airports LON",
  };
};

const initialState: FlightRoom = {
  flightRoom: {
    callHolidaySummaryApi: true,
    flightSearchCriteriaId: 0,
    hotelSearchCriteriaIds: 0,
    hotelSearchCriteriaId_nextDay: 0,
    psw_result_id: null,
    psw_result_id_combo: null,
    cheapestFlightPSW: null,
    roomids: null,
    psc_request_id: null,
    hotel_id: null,
    searchCriteriaId: null,
    selectedCarHired: null,
    selectHotelId: null,
    packageSearchId: "",
    multiRooms: [],
    search_result_id: null,
    holidayDetailLogId: 0,
    searchCriteria2: {},
    store_psw_result_id: null,
    searchCriteria: {
      departure: getDefaultDeparture(),
      destination: null,
      date: [
        dayjs().add(7, "days").toISOString(),
        dayjs().add(14, "days").toISOString(),
      ],
      child_age: {},
      guest: [
        {
          room: 1,
          adults: 2,
          children: [],
          infants: [],
        },
      ],
      class: "Economy",
      night: dayjs().add(14, "days").diff(dayjs().add(7, "days"), "day"),
      adults: 2,
      childrens: 0,
      infants: 0,
    },
    storeSelectedFlights: { inbound: [], outbound: [] },
    selectedFlights: { inbound: [], outbound: [] },
    diffPrice: "0",
    flightPrice: {
      price: null,
      isCheapest: true,
      tempPrice: null,
      cheapestFlightPrice: null,
    },
    initialPackagePrice: null,
    storeMultiRoom: [],
    isNewFlightSelected: {
      flights: false,
      room: false,
    },
    confirmSelection: {
      flights: false,
      room: false,
    },
    roomData: null,
  },
};

const flightRoomSlice = createSlice({
  name: "flightRoom",
  initialState,
  reducers: {
    setFlightRoomState: (state, { payload }) => {
      state.flightRoom = payload || state.flightRoom;
    },
    clearFlightRoomState: (state) => {
      state.flightRoom = initialState.flightRoom;
    },
  },
});

export default flightRoomSlice.reducer;
export const { setFlightRoomState, clearFlightRoomState } =
  flightRoomSlice.actions;
