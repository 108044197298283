import { Box, Button, Grid, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Image from "../../../components/Image";
import { colors } from "../../../styles/colors";
import { CircleRating } from "../HolidaySummary";
import { useAppSelector } from "../../../hooks/useAppReducer";
import { addCommasToNumber } from "../../../helpers/commaSeparatedPrice";
import { isEmpty } from "../../../helpers/isEmpty";
import { capitalizeFirstLetterEachWords } from "../../../helpers/capitalizeFirstLetterEachWords";
import useWindowWidth from "../../../hooks/useWindowWidth";
import dayjs from "dayjs";
interface reviewsrProps {
  tripAdvisorData?: any;
  tripHotelDetails?: any;
  reviewsTripAdvisorData?: any;
}

const ReviewsTab = ({
  tripAdvisorData,
  tripHotelDetails,
  reviewsTripAdvisorData,
}: reviewsrProps) => {
  const windowWidth = useWindowWidth();
  const [visibleReviews, setVisibleReviews] = useState(5);
  const [expandedReviewIds, setExpandedReviewIds] = useState<string[]>([]);
  const [isOverflowing, setIsOverflowing] = useState<{
    [key: string]: boolean;
  }>({});
  const textRefs = useRef<Record<string, HTMLSpanElement | null>>({});
  const { hotelDetails } = useAppSelector((state) => state.hotelDetails);
  const isPlataniumHotel = hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel
    ? Number(hotelDetails.HotelInfo.HotelInfo.platinumHtel) !== 0
    : false;

  const handleLoadMore = () => {
    setVisibleReviews((prev) => prev + 5); // Show 3 more reviews on each click
  };

  useEffect(() => {
    // Check if the text overflows for all reviews
    reviewsTripAdvisorData?.data
      ?.slice(0, visibleReviews)
      .forEach((review: any) => {
        const element = textRefs.current[review.id];
        if (element) {
          const isOverflow = element.scrollHeight > element.clientHeight;
          setIsOverflowing((prev) => ({ ...prev, [review.id]: isOverflow }));
        }
      });
  }, [reviewsTripAdvisorData, visibleReviews]);

  const toggleText = (reviewId: string) => {
    setExpandedReviewIds((prev) =>
      prev.includes(reviewId)
        ? prev.filter((id) => id !== reviewId)
        : [...prev, reviewId]
    );
  };
  return (
    <Box p={{ md: "0", sm: "0 5px", xs: 0 }}>
      <Box
        sx={{
          background: "#e9e8e2",
          display: { sm: "flex", xs: "block" },
          alignItems: "center",
          justifyContent: "flex-end",
          p: "7px",
          borderBottom: `2px solid #b7b7b7`,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: "9px",
            mr: "10px",
            pb: "0 !important",
            fontWeight: 500,
          }}
        >
          Traveller Reviews brought to you by
        </Typography>
        <Image
          style={{ width: "132px" }}
          src="https://www.tripadvisor.co.uk/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-15081-2.svg"
        />
      </Box>
      <Box sx={{ mt: "10px" }}>
        <Typography
          variant="h1"
          lineHeight="1.2"
          // textTransform={"capitalize"}
          fontSize={{ sm: "28px", xs: "20px" }}
          fontWeight={500}
        >
          {capitalizeFirstLetterEachWords(isEmpty(tripAdvisorData?.name))}
        </Typography>
        <Typography
          variant="body1"
          lineHeight="1.2"
          // textTransform={"capitalize"}
          fontSize={{ sm: "12px", xs: "12px" }}
          fontWeight={400}
        >
          {tripAdvisorData?.address_obj?.address_string}
          {", "}
          {tripAdvisorData?.address_obj?.city}
          {", "}
          {tripAdvisorData?.address_obj?.country}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: {
            sm: "center",
            xs: windowWidth > 374 ? "center" : "flex-start",
          },
        }}
      >
        {/* <CircleRating
          value={Number(tripAdvisorData?.rating)}
          isPlataniumHotel={isPlataniumHotel}
        /> */}
        <Typography
          variant="h1"
          sx={{
            color: colors.basics.black,
            fontSize: { sm: "30px", xs: "22px" },
            pr: "8px",
            pb: "0 !important",
            fontWeight: "600 !important",
            display: "flex",
            alignItems: "center",
          }}
        >
          {tripAdvisorData?.rating}
        </Typography>
        <Box
          sx={{
            display: { sm: "flex", xs: windowWidth > 374 ? "flex" : "block" },
            // mt:"10px"
          }}
        >
          <img
            src={tripAdvisorData?.rating_image_url}
            alt="rating"
            style={{ width: "147px", height: "30px", marginLeft: "-10px" }}
          />
          <Typography
            sx={{
              // ml: "10px",
              color: colors.basics.black,
              fontSize: "14px",
              pb: "0 !important",
              fontWeight: "500 !important",
              display: "flex",
              alignItems: "center",
            }}
            variant="body1"
          >
            {addCommasToNumber(Number(tripAdvisorData?.num_reviews))} Reviews
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography
          variant="body1"
          sx={{ fontSize: "18px", fontWeight: 500, mt: "10px" }}
        >
          Tripadvisor Traveller Rating:
        </Typography>
      </Box>
      <Box>
        {/* {tripAdvisorData &&
          tripAdvisorData.map((item: any, index: number) => {
            console.log("item :", item);
            return ( */}
        <Box
          sx={{
            display: { sm: "flex", xs: "block" },
            // justifyContent: "space-between",
            width: "100%",
            borderTop: `2px solid ${colors.grey[50]}`,
            borderBottom: `2px solid ${colors.grey[50]}`,
          }}
        >
          <Box
            sx={{
              width: { sm: "50%", xs: "100%" },
              p: "10px 0",
            }}
          >
            {tripAdvisorData?.subratings &&
              Object.values(tripAdvisorData.subratings).map(
                (rating: any, subIndex: number) => (
                  <Box
                    key={subIndex}
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      pb: "2px",
                    }}
                  >
                    <Box
                      // variant="body1"
                      sx={{
                        pb: "0 !important",
                        marginRight: { sm: 2, xs: "0" },
                        color: colors.basics.black,
                        fontSize: "12px",
                        width: { sm: "30%", xs: "50%" },
                      }}
                    >
                      {rating.localized_name}
                    </Box>
                    <Box
                      sx={{
                        width: { sm: "70%", xs: "50%" },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Image
                        src={rating.rating_image_url}
                        alt={rating.localized_name}
                        style={{ height: "14px", width: "70px" }}
                      />
                    </Box>

                    {/* Subrating name */}
                    {/* <Box
                sx={{
                  // flex: 1,
                  pb: "0 !important",
                  fontSize: "12px",
                  color: colors.basics.black,                  
                  width: { sm: "30%", xs: "50%" },
                }}
              >
                {rating.localized_name}
              </Box> */}

                    {/* Progress bar */}
                    {/* <Box
                sx={{
                  display:"flex",
                  alignItems:"center",
                  mx: "10px",
                  width: { sm: "70%", xs: "50%" },
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={parseFloat(rating.value) * 20} // Convert rating (out of 5) to percentage
                  sx={{
                   width:"100px",
                    height: "8px",
                    display:"flex",
                    alignItems:"center",
                    borderRadius: "4px",
                    backgroundColor: "#e0e0e0",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#00aa6c", // Customize color of the progress bar
                    },
                  }}
                />
                <Box
                sx={{
                  fontSize: "12px",
                  color: colors.basics.black,               
                  minWidth: "30px",
                  textAlign: "right",
                  pb:'0 !important',
                }}
              >
                {rating.value}
              </Box>
              </Box> */}

                    {/* Rating value */}
                  </Box>
                )
              )}
          </Box>
          <Box
            sx={{
              width: { sm: "50%", xs: "100%" },
              p: "10px 0",
              borderLeft: { sm: `1px solid ${colors.grey[50]}`, xs: "none" },
              // borderTop: `2px solid ${colors.grey[50]}`,
            }}
          >
            <Box
              sx={{
                width: "100%",
                p: { sm: "0 10px", xs: "0" },
                // borderLeft: `1px solid ${colors.grey[50]}`,
                // borderTop: `2px solid ${colors.grey[50]}`,
              }}
            >
              {tripAdvisorData?.trip_types &&
                tripAdvisorData?.trip_types?.map((type: any, index: number) => {
                  // Calculate the percentage for the progress bar
                  const totalValues = tripAdvisorData?.trip_types.reduce(
                    (sum: number, t: any) => sum + Number(t?.value),
                    0
                  );
                  const percentage = (Number(type?.value) / totalValues) * 100;
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // mb: 2,
                        pb: "2px",
                      }}
                      key={index}
                    >
                      <Box
                        // variant="body1"
                        sx={{
                          width: { sm: "30%", xs: "50%" },
                          fontSize: "12px",
                          color: colors.basics.black,
                          marginRight: { sm: 2, xs: "0" },
                          pb: "0 !important",
                        }}
                      >
                        {type.localized_name}
                      </Box>
                      <Box
                        sx={{
                          width: { sm: "50%", xs: "50%" },
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: "100px",
                            backgroundColor: colors.grey[50],
                            // borderRadius: "4px",
                            overflow: "hidden",
                            height: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              width: `${percentage}%`,
                              height: "12px",
                              backgroundColor: "#00aa6c",
                            }}
                          />
                        </Box>
                        <Box
                          // variant="body1"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                            marginLeft: 1,
                            color: colors.basics.black,
                            pb: "0 !important",
                          }}
                        >
                          {/* {Math.round(percentage)}% */}
                          {type.value}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
        {/* ); */}
        {/* })} */}
      </Box>
      <Box>
        <Box sx={{ borderBottom: `2px solid ${colors.grey[50]}` }}>
          <Typography
            variant="body1"
            sx={{ fontSize: "18px", fontWeight: 500, mt: "10px" }}
          >
            Recent Reviews:
          </Typography>
        </Box>
        <Box>
          <Box>
            {reviewsTripAdvisorData?.data
              ?.slice(0, visibleReviews)
              .map((review: any) => {
                const isExpanded = expandedReviewIds.includes(review.id);
                const shouldShowMoreButton = isOverflowing[review.id];
                return (
                  <Grid
                    container
                    spacing={2}
                    key={review.id}
                    sx={{
                      p: { sm: "20px 0", xs: "10px 0" },
                      mt: "0",
                      borderBottom: `2px solid ${colors.grey[50]}`,
                      width: "auto",
                      ml: "0 !important",
                    }}
                  >
                    <Grid
                      item
                      md={2}
                      sm={3}
                      xs={12}
                      sx={{ pt: "0 !important", pl: "0 !important" }}
                    >
                      <Box alignItems="center">
                        {/* <Image src={review.rating_image_url} alt={review.user.username} />   */}
                        <Box>
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "12px",
                              fontWeight: 700,
                              pb:
                                review.user.user_location.id !== "null"
                                  ? "0 !important"
                                  : "8px",
                            }}
                          >
                            {review.user.username}
                          </Typography>
                          {review.user.user_location.id !== "null" ? (
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 400, fontSize: "12px" }}
                            >
                              {review.user.user_location.name}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Box>
                        <Box>
                          <Typography
                            variant="body1"
                            color={colors.grey[10]}
                            sx={{
                              fontSize: "12px",
                              fontWeight: 700,
                              pb: "0 !important",
                            }}
                          >
                            Trip type:
                          </Typography>
                          <Typography
                            variant="body1"
                            color={colors.grey[10]}
                            sx={{
                              fontSize: "12px",
                              fontWeight: 500,
                              pb: "0 !important",
                            }}
                          >
                            {review.trip_type}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={10}
                      sm={9}
                      xs={12}
                      sx={{
                        pt: "0 !important",
                        pl: "0 !important",
                        ml: { sm: 0, xs: "20px" },
                      }}
                    >
                      <Box>
                        <Box>
                          {" "}
                          <Typography
                            variant="h2"
                            color={colors.basics.black}
                            sx={{ fontSize: "18px" }}
                          >
                            {review.title}
                          </Typography>{" "}
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <Box
                            component="img"
                            src={review.rating_image_url}
                            alt={`Rating: ${review.rating}`}
                            sx={{ ml: "-10px", width: "100px", height: "auto" }}
                          />
                          <Typography
                            variant="body2"
                            color={colors.basics.black}
                            sx={{
                              fontWeight: 500,
                              fontSize: "13px",
                              pb: "0 !important",
                            }}
                          >
                            {/* {new Date(review.travel_date).toLocaleDateString()}  */}
                            {dayjs(review.travel_date).format("MMM DD, YYYY")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            ref={(el) => (textRefs.current[review.id] = el)}
                            variant="body1"
                            color={colors.grey[10]}
                            sx={{
                              fontWeight: 500,
                              fontSize: "12px",
                              marginTop: 1,
                              pb: "0 !important",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: isExpanded ? "none" : 2, // Display 2 lines by default
                              overflow: isExpanded ? "visible" : "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {review.text}
                          </Typography>
                          {shouldShowMoreButton && (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                mt: 0.5, // Adds spacing between text and button
                                "& .MuiButtonBase-root": {
                                  paddingLeft: "0 !important",
                                  paddingRight: "0 !important",
                                  height: { sm: "auto", xs: "20px" },
                                },
                              }}
                            >
                              <Button
                                variant="text"
                                onClick={() => toggleText(review.id)}
                                sx={{
                                  color: colors.basics.black,
                                  fontSize: "12px",
                                  textTransform: "none",
                                  padding: 0,
                                  minWidth: "unset",
                                  fontWeight: 400,
                                  "&:hover": {
                                    textDecoration: "underline",
                                    backgroundColor: "transparent",
                                    color: colors.basics.black,
                                  },
                                }}
                              >
                                {isExpanded ? "« Less" : "More »"}
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
            {visibleReviews < reviewsTripAdvisorData?.data?.length && (
              <Button
                variant="text"
                sx={{
                  fontSize: "14px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                onClick={handleLoadMore}
              >
                Load More Reviews
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewsTab;
