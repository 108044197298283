export const calculateDateDifference = (date1: any, date2: any) => {
    // Convert the dates to Date objects
    const firstDate: any = new Date(date1);
    const secondDate: any = new Date(date2);

    // Calculate the time difference in milliseconds
    const timeDifference = Math.abs(secondDate - firstDate);

    // Convert the time difference to days
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    if (dayDifference === 0) {
        return null;
    }

    return dayDifference;

};