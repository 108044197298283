// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import ReactDOM from "react-dom";
import { addCommasToNumber } from "../helpers/commaSeparatedPrice";
import { colors } from "../styles/colors";
import { roundPrice } from "../helpers/RoundPrice";
import { setStepperState } from "../features/stepper/stepperSlice";
import { useAppSelector } from "../hooks/useAppReducer";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchAsyncHolidayPackageDetails } from "../features/holidayPackage/packageDetail";
import { setLoading } from "../features/loading/loadingSlice";
import { setFlightRoomState } from "../features/flightRoom/flightRoomSlice";
import dayjs from "dayjs";
import { formatTime } from "../helpers/formatTime";
import { getSessionStorage, setSessionStorage } from "../helpers/storage";
import useWindowWidth from "../hooks/useWindowWidth";
import { setHolidayDetailsState } from "../features/holidaySummary/holidaySummarySlice";
import {
  fetchAsyncPaymentConfiguration,
  setDepositeConfiguration,
} from "../features/paymentConfiguration/paymentConfiguration";
import { calculateDeposit } from "../helpers/deposit";
import { calculatePerPersonPrice } from "../helpers/calculatePerPersonPrice";
import { calculateTotalPackagePrice } from "../helpers/calculateTotalPackagePrice";
import { isDiscountValidDate } from "../helpers/isDiscountValidDate";
import { ReactComponent as InfoIcon } from "../assets/icons/Info.svg";
import { ReactComponent as Euro } from "../assets/icons/Euro.svg";
import { ReactComponent as Dollar } from "../assets/icons/Dollar.svg";
import { ReactComponent as Pound } from "../assets/icons/Pound.svg";
import { ReactComponent as ArrowDown } from "../assets/icons/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "../assets/icons/ArrowUp.svg";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { formatPrice } from '../helpers/formatPrice';

interface pricingCardProps {
  totalPrice?: string | number;
  totalGuests?: number;
  session_id?: number;
  showButtons?: boolean;
  setContiuneToBookClick?: any;
  showButtonsAndFirstComponent?: () => void;
}

export const PricingCard = ({
  session_id,
  showButtons,
  showButtonsAndFirstComponent,
  setContiuneToBookClick,
}: pricingCardProps) => {
  /**
   * @states
   */
  const location = useLocation();

  const windowWidth = useWindowWidth();
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  const { flightRoom } = useAppSelector((state) => state.flightRoom);
  const { holidayPackage } = useAppSelector((state) => state.holidayPackage);
  const { taxText, packagePricePerPersonHoliday } = useAppSelector((state) => state.packageDetails);
  const { voucherResponse } = useSelector((state: any) => state.commonState);
  const hotelDetails = holidayPackage?.Packages?.results?.filter(
    (f: any) => f?.SearchResult?.hotel_id === flightRoom?.hotel_id
  );
  const isPlataniumHotel =
    hotelDetails?.length > 0 ? Number(hotelDetails[0]?.HotelInfo?.platinumHotel) !== 0 : false;
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL;
  const totalGuestsWithInfants: any =
    flightRoom.searchCriteria.adults +
    flightRoom.searchCriteria.childrens +
    flightRoom.searchCriteria.infants;
  const [deposit, setDeposit] = useState(0);
  const [day, month, year] = flightRoom.searchCriteria.date[0].split("/");
  const formattedDate = `${year}-${month}-${day}`;
  const { depositeConfiguration } = useAppSelector((state) => state.depositeConfiguration);
  const [showMore, setShowMore] = useState<any>(false);
  const stepperState = useAppSelector((state) => state.stepper.stepper);
  const maxLines = 2;
  const lineHeight = 1.4;
  const flightPriceDiff = useMemo(() => {
    let price: any = 0;
    const flightPrice = getSessionStorage("stepper") === "0" ? "0" : flightRoom.diffPrice || "0";
    if (getSessionStorage("nextAndSelectedFlights") === "false") {
      price = Math.ceil(flightPrice);
    } else {
      if (flightPrice.includes("-")) {
        price = Math.floor(flightPrice);
      } else {
        price = Math.ceil(flightPrice);
      }
    }
    return price;
  }, [flightRoom.diffPrice, getSessionStorage("stepper"), getSessionStorage("nextAndSelectedFlights")]);

  /**
   * @var
   */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**
   * @function
   */

  const holidayPackageStoreCriteria = async () => {
    let holidayPackageJson: any = await [
      {
        DestinationFrom: holidayPackage?.SearchCriteria?.[0]?.DestinationFrom,
        Destination: holidayPackage?.SearchCriteria?.[0]?.Destination,
        departure_date: holidayPackage?.SearchCriteria?.[0]?.departure_date,
        rooms: holidayPackage?.SearchCriteria?.[0]?.rooms,
        nights: holidayPackage?.SearchCriteria?.[0]?.nights,
        cabin_class: holidayPackage?.SearchCriteria?.[0]?.cabin_class,
        adults: holidayPackage?.SearchCriteria?.[0]?.adults,
        children: holidayPackage?.SearchCriteria?.[0]?.children,
        infants: holidayPackage?.SearchCriteria?.[0]?.infants,
        siteId: holidayPackage?.SearchCriteria?.[0]?.siteId,
        cheapestFareCat: holidayPackage?.SearchCriteria?.[0]?.cheapestFareCat,
        hotel_id: Number(flightRoom?.hotel_id),
        // hotel_name: flightRoom.roomData[0]?.SearchResult?.hotel_name
      },
      {
        ids: {
          flightSearchCriteriaId: Number(flightRoom.flightSearchCriteriaId),
          hotelSearchCriteriaIds: Number(flightRoom.hotelSearchCriteriaIds),
          selectedFlight: Number(flightRoom?.psw_result_id),
          hotelSearchCriteriaId_nextDay: Number(flightRoom?.hotelSearchCriteriaId_nextDay),
        },
        filter: {
          // "selected_flight_id": Number(flightRoom?.psw_result_id),
          selected_flight_id: Number(flightRoom.psw_result_id),
          selected_hotel_id: Number(flightRoom?.hotel_id),
        },
      },
    ];
    if (flightRoom?.searchCriteria?.childrens > 0) {
      holidayPackageJson[0].child_age = await flightRoom?.searchCriteria?.child_age;
    }
    const psw_result_id_combo = flightRoom?.selectedFlights?.inbound[0]?.FlightPswFlight?.psw_result_id;
    await dispatch(
      setFlightRoomState({
        ...flightRoom,
        psw_result_id_combo: psw_result_id_combo,
        searchCriteria2: holidayPackageJson,
      })
    );

    return await true;
  };

  const handleBook = async () => {
    location.pathname.includes("holiday-summary") && setContiuneToBookClick(true);
    if (getSessionStorage("nextButtonClicked") === "true") {
      setSessionStorage("nextAndSelectedFlights", "true");
      setSessionStorage("previous", "true");
    } else {
      setSessionStorage("nextAndSelectedFlights", "false");
    }
    // setSessionStorage("nextAndSelectedFlights", "true");
    // setSessionStorage("nextButtonClicked", "true");
    // dispatch(setLoading(true))
    // const body = [
    //   {
    //     psw_result_id: flightRoom?.psw_result_id,
    //     roomids: flightRoom.roomids,
    //     "packageSearchId": flightRoom?.packageSearchId || ""
    //   },
    // ];
    const res = await holidayPackageStoreCriteria();
    // eslint-disable-next-line
    // let res = await dispatch(fetchAsyncHolidayPackageDetails(body));
    // dispatch(setLoading(false))
    dispatch(setStepperState(2));
    res && navigate(`/passenger-details/${session_id}${window.location.search}`);
  };

  //* Guests count excluding infants
  const totalGuests = flightRoom.searchCriteria.adults + flightRoom.searchCriteria.childrens;
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState([0, -8]); // Default offset for larger screens

  // Update offset dynamically based on screen size
  useEffect(() => {
    const updateOffset = () => {
      if (window.innerWidth <= 992) {
        // For small screens
        setOffset([0, -15]);
      } else {
        // For larger screens
        setOffset([0, -8]);
      }
    };

    updateOffset(); // Set initial offset
    window.addEventListener("resize", updateOffset); // Update on resize

    return () => {
      window.removeEventListener("resize", updateOffset); // Cleanup
    };
  }, []);

  // Detect touch device
  const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;

  const handleOpen = () => {
    if (isTouchDevice) {
      setOpen((prevOpen) => !prevOpen); // Open tooltip on tap/long press
    }
  };

  const handleClose = () => {
    if (isTouchDevice) {
      setOpen((prevOpen) => !prevOpen); // Close tooltip on tap/long press
    }
  };
  const handleToggle = () => {
    if (isTouchDevice) {
      setOpen((prevOpen) => !prevOpen); // Toggle the state
    }
  };
  const flightPrice: any = useMemo(() => {
    let price: any = 0;
    const flightPrice = getSessionStorage("stepper") === "0" ? "0" : flightRoom.diffPrice || "0";

    if (getSessionStorage("nextAndSelectedFlights") === "false") {
      price = Math.ceil(flightPrice);
    } else {
      if (flightPrice.includes("-")) {
        price = Math.floor(flightPrice);
      } else {
        price = Math.ceil(flightPrice);
      }
    }
    return price;
  }, [flightRoom.diffPrice, getSessionStorage("stepper"), getSessionStorage("nextAndSelectedFlights")]);

  const packagePricePerPerson: any = useMemo(() => {
    let price = 0;
    if (flightRoom.multiRooms.length > 0) {
      flightRoom.multiRooms.map((f: any) => {
        price = price + Number(f?.total_package);
        return <></>;
      });
    }
    const totalPrice = price / totalGuests;

    return String(totalPrice + flightPriceDiff);
  }, [flightRoom.multiRooms, totalGuests, flightPrice]);

  const additionalFlightPrice = useMemo(() => {
    let price = 0;
    if (flightRoom.multiRooms.length > 0) {
      flightRoom.multiRooms.map((f: any) => {
        price = price + Number(f?.package_breakdown?.flight_tot_net_amt);
        return <></>;
      });
    }

    return price;
  }, [flightRoom.multiRooms]);

  const totalPrice = useMemo(() => {
    return Number(roundPrice(String(packagePricePerPerson))) * totalGuests;
  }, [packagePricePerPerson, totalGuests, flightPrice]);

  useEffect(() => {
    const depositValue = calculateDeposit({
      tripDate: formattedDate || "",
      ticketingDate: Object.keys(holidayPackage).length > 0 && holidayPackage?.FlightDetails
        ? (flightRoom?.selectedFlights?.outbound?.length > 0
          ? flightRoom?.selectedFlights?.outbound?.[0]?.FlightPswFlight?.FlightPswResult?.last_ticket_date
          : ""
        )
        : "",
      totalCost: totalPrice,
      totalGuests,
      totalGuestsWithInfants,
      flightPrice: additionalFlightPrice,
      depositeConfiguration,
    });
    setDeposit(depositValue);
  }, [formattedDate, flightRoom?.selectedFlights?.outbound, additionalFlightPrice, holidayPackage?.FlightDetails?.outbound?.flights, totalPrice, totalGuests, totalGuestsWithInfants, flightPrice, depositeConfiguration])

  const getBalanceDueDate = useCallback(() => {
    const beforeFullPaymentDueDays = Number(depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue)
      ? Number(depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue)
      : 0;
    const todayDate = new Date();
    const tripDateDiff = dayjs(new Date(formattedDate)).diff(dayjs(), "day"); // two date difference

    if (tripDateDiff > beforeFullPaymentDueDays) {
      const dateFormat = new Date(formattedDate);
      const subtractedDate = new Date(dateFormat.getTime() - beforeFullPaymentDueDays * 24 * 60 * 60 * 1000);
      const returnDate = `${subtractedDate.getDate()}/${subtractedDate.getMonth() + 1
        }/${subtractedDate.getFullYear()}`;
      return returnDate;
    } else {
      const returnDate = `${todayDate.getDate()}/${todayDate.getMonth() + 1}/${todayDate.getFullYear()}`;
      return returnDate;
    }
  }, [formattedDate, depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue]);

  useEffect(() => {
    const balaneDue = getBalanceDueDate();
    if (depositeConfiguration?.DaysBeforeDepartureBeforeFullPaymentDue) {
      // setTimeout(() => {
      dispatch(
        setHolidayDetailsState({
          ...holiday,
          deposit:
            deposit !== 0
              ? `${signForPricing + addCommasToNumber(deposit)}`
              : signForPricing + addCommasToNumber(totalPrice),
          isDepositPayment: deposit !== 0 ? true : false,
          balance_due_date: balaneDue,
        })
      );
      // }, 200)
    }
    // eslint-disable-next-line
  }, [getBalanceDueDate(), deposit]);

  useEffect(() => {
    if (depositeConfiguration === null) {
      (async () => {
        const res = await dispatch(fetchAsyncPaymentConfiguration());
        if (res?.payload) {
          dispatch(setDepositeConfiguration(res?.payload));
        }
      })();
    }
  }, []);

  const voucherAmount = parseFloat(voucherResponse?.[0]?.amount || 0);
  const totalPriceBeforeDiscount = roundPrice(packagePricePerPerson) * totalGuests;
  const finalPrice = totalPriceBeforeDiscount - voucherAmount;

  const calculateLineCount = (text: any) => {
    const div = document.createElement("div");
    div.style.position = "absolute";
    div.style.visibility = "hidden";
    div.style.width = "100%";
    div.style.lineHeight = `${lineHeight}em`;
    div.innerHTML = text;
    document.body.appendChild(div);
    const lines = Math.ceil(div.clientHeight / (lineHeight * 16)); // Assuming 16px font size
    document.body.removeChild(div);
    return lines;
  };
  // if (!taxText || typeof taxText !== 'string') return null;
  const lineCount = calculateLineCount(taxText);
  const fixedTwoDigitPrice: any = parseFloat(packagePricePerPerson).toFixed(2);

  const tPrice = roundPrice(String(calculatePerPersonPrice(packagePricePerPerson, totalGuests)));
  const { finalPriceDiscount, discountMessage } = calculateTotalPackagePrice(totalPrice, totalGuests);
  /**
   * @returns
   */

  return (
    <>
      <Box
        display={{ sm: 'flex', xs: 'none' }}
        alignItems="center"
        justifyContent="space-between"
        borderRadius="5px"
        sx={{ background: colors.basics.secondary, border: `1px solid ${colors.grey[30]}` }}
        padding={{ sm: "20px 16px", xs: "20px 10px" }}
        className="price-summary"
      >
        <Box width={"100%"} gap={"10px"}>
          <Box
            justifyContent="space-between"
            display={{ sm: "flex", xs: windowWidth <= 375 ? "block" : "flex" }}
          >
            <Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                sx={{
                  "& .Info-Icon": {
                    width: "20px",
                    ml: "5px",
                  },
                  "& svg": {
                    fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + "!important" : colors.basics.primary,
                    "& path": {
                      fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + "!important" : colors.basics.primary
                    },
                  },
                }}
              >
                <Typography
                  mx={0}
                  sx={{
                    fontFamily: "El Messiri",
                    fontStyle: "normal",
                    lineHeight: "31px",
                    fontWeight: 400,
                    alignItems: "center",
                  }}
                  fontSize={{ sm: "26px !important", xs: "24px !important" }}
                >
                  <Tooltip title={fixedTwoDigitPrice}>
                    {flightRoom?.searchCriteria?.destination?.discountPrice?.isDiscountUK &&
                      process.env.REACT_APP_PROJECT_COUNTRY === "uk" &&
                      isDiscountValidDate() ? (
                      <span>
                        {signForPricing + addCommasToNumber(formatPrice(
                          String(calculatePerPersonPrice(roundPrice(packagePricePerPerson), totalGuests)))
                        )}{' '}pp
                      </span>
                    ) : flightRoom?.searchCriteria?.destination?.discountPrice?.isDiscountIE &&
                      process.env.REACT_APP_PROJECT_COUNTRY === "ie" &&
                      isDiscountValidDate() ? (
                      <span>
                        {signForPricing + addCommasToNumber(formatPrice(
                          String(calculatePerPersonPrice(roundPrice(packagePricePerPerson), totalGuests)))
                        )}{' '}pp
                      </span>
                    ) : (
                      <span>
                        {signForPricing + addCommasToNumber(
                          roundPrice(packagePricePerPerson)
                        )}{' '}pp
                      </span>
                    )}
                  </Tooltip>
                </Typography>
                {windowWidth > 992 ? (
                  <Box sx={{
                    "& svg": {
                      '& path': {
                        fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary + " !important"
                      },
                      fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary
                    },
                  }}>
                    <Tooltip
                      title="Price per person includes adults and children (excluding infants)"
                      arrow
                      placement="top"
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -8],
                              },
                            },
                          ],
                        },
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            maxWidth: "180px",
                            p: "10px 12px",
                            textAlign: "center",
                            backgroundColor: colors.grey[30],
                            color: "#fff", // Adjust text color
                            fontSize: "13px !important", // Adjust font size
                            border: `1px solid ${colors.grey[30]}`, // Optional border
                          },
                        },
                        arrow: {
                          sx: {
                            color: colors.grey[30], // Arrow color matches the tooltip background
                          },
                        },
                      }}
                    >
                      <InfoIcon
                        className="Info-Icon"
                        fill={
                          isPlataniumHotel
                            ? colors.basics.plataniumGreenColor + "!important"
                            : colors.basics.primary
                        }
                      />
                    </Tooltip>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      "& .Info-Icon": {
                        width: "20px",
                        ml: "5px",
                      },
                      "& svg": {
                        '& path': {
                          fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary + " !important"
                        },
                        fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary
                      },
                    }}
                  >
                    <Tooltip
                      title="Price per person includes adults and children (excluding infants)"
                      arrow
                      placement="top"
                      open={isTouchDevice ? open : undefined} // Fully controlled on mobile
                      onClose={handleClose} // Close tooltip
                      onOpen={handleOpen} // Open tooltip
                      disableFocusListener={isTouchDevice} // Prevent default focus behavior on mobile
                      disableHoverListener={isTouchDevice} // Prevent hover behavior on mobile
                      disableTouchListener={!isTouchDevice} // Enable touch behavior only for touch devices
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: offset,
                              },
                            },
                          ],
                        },
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            maxWidth: { xs: "150px", sm: "180px" },
                            p: { xs: "8px 8px", sm: "10px 12px" },
                            textAlign: "center",
                            backgroundColor: colors.grey[30],
                            color: "#fff",
                            fontSize: { xs: "11px", sm: "13px !important" },
                            border: `1px solid ${colors.grey[30]}`,
                          },
                        },
                        arrow: {
                          sx: {
                            color: colors.grey[30],
                          },
                        },
                      }}
                    >
                      <InfoIcon
                        className="Info-Icon"
                        onClick={handleToggle} // Toggle tooltip on mobile
                        fill={
                          isPlataniumHotel
                            ? colors.basics.plataniumGreenColor + "!important"
                            : colors.basics.primary
                        }
                      />
                    </Tooltip>
                  </Box>
                )}
              </Box>
              <Box display={"flex"}>
                <Typography
                  sx={{
                    fontFamily: "El Messiri",
                    fontStyle: "normal",
                    lineHeight: "31px",
                    fontWeight: 400,
                  }}
                  fontSize={{ sm: "16px !important", xs: "16px !important" }}
                >
                  Total Price:
                  <Tooltip title={fixedTwoDigitPrice * totalGuests}>
                    <span style={{ marginLeft: "3px" }}>
                      {!voucherResponse || Object.keys(voucherResponse).length === 0
                        ?
                        flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountUK && process.env.REACT_APP_PROJECT_COUNTRY === "uk" && isDiscountValidDate() ?
                          // (signForPricing + addCommasToNumber(roundPrice(calculateTotalPackagePrice(packagePricePerPerson,totalGuests)) ))
                          // signForPricing + addCommasToNumber(tPrice * totalGuests):
                          signForPricing + addCommasToNumber(finalPriceDiscount) : flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountIE && process.env.REACT_APP_PROJECT_COUNTRY === "ie" && isDiscountValidDate() ? signForPricing + addCommasToNumber(finalPriceDiscount) :
                            signForPricing + addCommasToNumber(roundPrice(packagePricePerPerson) * totalGuests)

                        // (signForPricing + addCommasToNumber(roundPrice(packagePricePerPerson) * totalGuests))
                        : (signForPricing + addCommasToNumber(finalPrice))
                      }
                    </span>
                  </Tooltip>
                </Typography>

                {(flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountUK &&
                  process.env.REACT_APP_PROJECT_COUNTRY === "uk" &&
                  isDiscountValidDate() &&
                  totalPrice >= 4000) ? (
                  <Typography
                    sx={{
                      fontFamily: "El Messiri",
                      fontStyle: "normal",
                      lineHeight: "31px",
                      fontWeight: 400,
                      ml: "20px",
                    }}
                    fontSize={{ sm: "16px !important", xs: "16px !important" }}
                  >
                    Was
                    <span style={{ marginLeft: '3px', textDecoration: 'line-through' }}>
                      {signForPricing + addCommasToNumber(roundPrice(packagePricePerPerson) * totalGuests)}
                    </span>
                  </Typography>
                ) : (flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountIE &&
                  process.env.REACT_APP_PROJECT_COUNTRY === "ie" &&
                  isDiscountValidDate()) ? (
                  <Typography
                    sx={{
                      fontFamily: "El Messiri",
                      fontStyle: "normal",
                      lineHeight: "31px",
                      fontWeight: 400,
                      ml: "20px",
                    }}
                    fontSize={{ sm: "16px !important", xs: "16px !important" }}
                  >
                    Was
                    <span style={{ marginLeft: '3px', textDecoration: 'line-through' }}>
                      {signForPricing + addCommasToNumber(roundPrice(packagePricePerPerson) * totalGuests)}
                    </span>
                  </Typography>
                ) : null}
              </Box>
              <Typography variant='subtitle2' fontSize='12px' color='#212529'>
                {flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountUK && process.env.REACT_APP_PROJECT_COUNTRY === "uk" && isDiscountValidDate() && totalPrice >= 4000 &&
                  discountMessage}
              </Typography>
              <Typography variant='subtitle2' fontSize='12px' color='#212529'>
                {flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountIE && process.env.REACT_APP_PROJECT_COUNTRY === "ie" && isDiscountValidDate() &&
                  discountMessage}
              </Typography>
              <Typography variant="subtitle2" fontSize="12px" color="#212529">
                {voucherResponse.length > 0 &&
                  `Code '${voucherResponse?.[0]?.voucher_code}' applied - ${signForPricing}${voucherResponse?.[0]?.amount} off`}
              </Typography>
            </Box>
          </Box>
          <Box marginTop={"10px"} display='flex' width={'100%'} alignItems={'center'} sx={{ background: colors.grey[20] }} padding={{ sm: '10px 16px', xs: "10px 10px" }}>
            <Box className='Icon' sx={{
              mr: "20px",
              "& svg": {
                '& path': {
                  fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary + " !important"
                },
                fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary + " !important"
              },
              '& g path:nth-of-type(2)': {
                fill: colors.basics.white + " !important"
              }
            }} maxWidth={{ sm: '40px', xs: "36px" }}>
              {process.env.REACT_APP_PROJECT_COUNTRY === "uk" && <Pound height={24} width={24} fill={colors.basics.primary} />}
              {process.env.REACT_APP_PROJECT_COUNTRY === "ie" && <Euro />}
              {process.env.REACT_APP_PROJECT_COUNTRY === "us" && <Dollar />}
            </Box>

            <Box className='content' >
              <Typography variant='body1' fontWeight={500} lineHeight='130%'>
                {deposit !== 0 ? `Due today: Deposit ${signForPricing + addCommasToNumber(deposit)}` : `Due today: Full payment`}
              </Typography>
            </Box>
          </Box>
          {taxText && typeof taxText === 'string' ? <Typography variant='subtitle2' marginTop={2} fontSize='12px' color='#212529' dangerouslySetInnerHTML={{ __html: taxText }} sx={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: showMore ? 'none' : maxLines, // Clamp lines if "showMore" is false
            maxHeight: showMore ? 'none' : `${maxLines * 1.4}em`, // Adjust based on font size
            transition: 'max-height 0.3s ease',
          }} /> : null}
          {taxText && typeof taxText === 'string' && (lineCount <= maxLines || lineCount > maxLines) && (
            <span onClick={() => setShowMore(!showMore)} style={{ fontSize: "12px", color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary, cursor: "pointer" }}>
              {showMore ? 'Show less..' : 'Show more..'}</span>
          )}

          <Box
            display={{ sm: "flex" }}
            width={'100%'}
            sx={{
              alignSelf: "flex-start",
              "& .MuiButtonBase-root": {
                color: colors.basics.white,
                background: isPlataniumHotel
                  ? `${colors.basics.plataniumGreenColor} !important`
                  : `${colors.basics.primary} !important`,
                height: { xs: "40px", sm: "50px" },
                border: isPlataniumHotel
                  ? `1px solid ${colors.basics.plataniumGreenColor} !important`
                  : `1px solid ${colors.basics.primary} !important`,
                "&:hover": {
                  background: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                  color: `${colors.basics.white} !important`,
                },
              },
            }}
          >
            <Button
              sx={{
                width: "100%",
                marginTop: "10px",
              }}
              className="book-button btn-olb-passenger-details"
              onClick={handleBook}
            // onClick={() => {
            //   if (stepperState === 1) {
            //     dispatch(setStepperState(2));
            //     navigate(`/car-hire/${session_id}`);
            //   } else if (stepperState === 2) {
            //     handleBook(); // Replace with your handleBook logic
            //   }
            // }}
            >
              Continue to book
            </Button>
          </Box>
          {/* <Box
          mt={'20px'}
          sx={{
            '& .MuiButtonBase-root': {
              background: isPlataniumHotel ? `${colors.basics.plataniumGreenColor}` : `${colors.basics.primary}`,
              color: colors.basics.white,
              border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor}` : `1px solid ${colors.basics.primary}`,
              // background: colors.grey[30],
              '&:hover': {
                background: isPlataniumHotel ? `${colors.basics.plataniumGreenColor}` : `${colors.basics.primary}`,
                color: colors.basics.white,
                border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor}` : `1px solid ${colors.basics.primary}`,
              }
            },
            display: showButtons === true ? "none" : "flex",
            width: "100%",
          }}
          className='book-button'
        >
          <Button
            id="customize-holiday-btn"
            onClick={showButtonsAndFirstComponent}
            sx={{ width: "100%", }}
            variant="outlined"
            className='btn-olb-customise-holiday'
          >
            Customise Your Holiday
          </Button>
        </Box> */}
          {/* <Box
            mt={'20px'}
            sx={{
              '& .MuiButtonBase-root': {
                background: isPlataniumHotel ? `${colors.basics.plataniumGreenColor}` : `${colors.basics.primary}`,
                color: colors.basics.white,
                border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor}` : `1px solid ${colors.basics.primary}`,
                // background: colors.grey[30],
                '&:hover': {
                  background: isPlataniumHotel ? `${colors.basics.plataniumGreenColor}` : `${colors.basics.primary}`,
                  color: colors.basics.white,
                  border: isPlataniumHotel ? `1px solid ${colors.basics.plataniumGreenColor}` : `1px solid ${colors.basics.primary}`,
                }
              },
              display: "flex",
              width: "100%",
            }}
            className='car-book-button'
          >
            <Button
              id="customize-holiday-btn"
              onClick={() => {
                dispatch(setStepperState(2));
                navigate(`/car-hire/${session_id}`)
              }}
              sx={{ width: "100%", }}
              variant="outlined"
              className='btn-olb-customise-holiday'
            >
              Car Hire
            </Button>
          </Box> */}

        </Box>
      </Box>
      {location.pathname.includes("holiday-summary") ? (
        <StickyPricingBottom
          setShowMore={setShowMore}
          taxText={taxText}
          showMore={showMore}
          lineCount={lineCount}
          deposit={deposit}
          discountMessage={discountMessage}
          finalPriceDiscount={finalPriceDiscount}
          totalPrice={totalPrice}
          flightRoom={flightRoom}
          voucherResponse={voucherResponse}
          tPrice={tPrice}
          finalPrice={finalPrice}
          signForPricing={signForPricing}
          packagePricePerPersonHoliday={packagePricePerPersonHoliday}
          packagePricePerPerson={packagePricePerPerson}
          totalGuests={totalGuests}
          handleBook={handleBook}
          isPlataniumHotel={isPlataniumHotel}
        />
      ) : null}
    </>
  );
};

const StickyPricingBottom = ({
  setShowMore,
  taxText,
  showMore,
  deposit,
  lineCount,
  discountMessage,
  finalPriceDiscount,
  totalPrice,
  flightRoom,
  voucherResponse,
  tPrice,
  finalPrice,
  packagePricePerPerson,
  totalGuests,
  handleBook,
  signForPricing,
  isPlataniumHotel,
  packagePricePerPersonHoliday,
}) => {
  const winndoWidth = useWindowWidth();
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState([0, -8]); // Default offset for larger screens

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Update offset dynamically based on screen size
  useEffect(() => {
    const updateOffset = () => {
      if (window.innerWidth <= 600) {
        // For small screens
        setOffset([0, -15]);
      } else {
        // For larger screens
        setOffset([0, -8]);
      }
    };

    updateOffset(); // Set initial offset
    window.addEventListener("resize", updateOffset); // Update on resize

    return () => {
      window.removeEventListener("resize", updateOffset); // Cleanup
    };
  }, []);

  // Detect touch device
  const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;

  const handleOpen = () => {
    if (isTouchDevice) {
      setOpen((prevOpen) => !prevOpen); // Open tooltip on tap/long press
    }
  };

  const handleClose = () => {
    if (isTouchDevice) {
      setOpen((prevOpen) => !prevOpen); // Close tooltip on tap/long press
    }
  };
  const handleToggle = () => {
    if (isTouchDevice) {
      setOpen((prevOpen) => !prevOpen); // Toggle the state
    }
  };
  let maxLines = 1;
  return ReactDOM.createPortal(
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        bgcolor: "white",
        borderTop: `1px solid ${colors.grey[30]}`,
        color: "white",
        p: winndoWidth < 374 ? 1 : "10px",
        textAlign: "center",
        display: { xs: "block", sm: "none" },
        // zIndex: 1299,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Box display={"flex"} alignItems={"center"} sx={{
            "& svg": {
              '& path': {
                fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary + " !important"
              },
              fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary
            },
          }}>
            <Typography
              mx={0}
              sx={{
                fontFamily: "El Messiri",
                fontStyle: "normal",
                fontWeight: 400,
                display: "flex",
              }}
              fontSize={{ xs: "24px !important" }}
            >
              {/* {`${signForPricing + addCommasToNumber(
              roundPrice(packagePricePerPerson))} pp`} */}

              {flightRoom?.searchCriteria?.destination?.discountPrice?.isDiscountUK &&
                process.env.REACT_APP_PROJECT_COUNTRY === "uk" &&
                isDiscountValidDate() ? (
                <span>
                  {signForPricing + addCommasToNumber(formatPrice(
                    String(calculatePerPersonPrice(roundPrice(packagePricePerPerson), totalGuests)))
                  )}{' '}pp
                </span>
              ) : flightRoom?.searchCriteria?.destination?.discountPrice?.isDiscountIE &&
                process.env.REACT_APP_PROJECT_COUNTRY === "ie" &&
                isDiscountValidDate() ? (
                <span>
                  {signForPricing + addCommasToNumber(formatPrice(
                    String(calculatePerPersonPrice(roundPrice(packagePricePerPerson), totalGuests)))
                  )}{' '}pp
                </span>
              ) : (
                <span>
                  {signForPricing + addCommasToNumber(
                    roundPrice(packagePricePerPerson)
                  )}{' '}pp
                </span>
              )}
            </Typography>

            <Box
              sx={{
                "& .Info-Icon": {
                  width: "20px",
                  ml: "5px",
                },
                "& svg": {
                  '& path': {
                    fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary + " !important"
                  },
                  fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary
                },
              }}
            >
              <Tooltip
                title="Price per person includes adults and children (excluding infants)"
                arrow
                placement="top"
                open={isTouchDevice ? open : undefined} // Fully controlled on mobile
                onClose={handleClose} // Close tooltip
                onOpen={handleOpen} // Open tooltip
                disableFocusListener={isTouchDevice} // Prevent default focus behavior on mobile
                disableHoverListener={isTouchDevice} // Prevent hover behavior on mobile
                disableTouchListener={!isTouchDevice} // Enable touch behavior only for touch devices
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: offset,
                        },
                      },
                    ],
                  },
                }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: { xs: "150px", sm: "180px" },
                      p: { xs: "8px 8px", sm: "10px 12px" },
                      textAlign: "center",
                      backgroundColor: colors.grey[30],
                      color: "#fff",
                      fontSize: { xs: "11px", sm: "13px !important" },
                      border: `1px solid ${colors.grey[30]}`,
                    },
                  },
                  arrow: {
                    sx: {
                      color: colors.grey[30],
                    },
                  },
                }}
              >
                <InfoIcon
                  className="Info-Icon"
                  onClick={handleToggle} // Toggle tooltip on mobile
                  fill={
                    isPlataniumHotel
                      ? colors.basics.plataniumGreenColor + "!important"
                      : colors.basics.primary
                  }
                />
              </Tooltip>
            </Box>
          </Box>
          <Box display={winndoWidth > 376 ? "flex" : "block"} alignItems={"center !important"}>
            <Typography
              sx={{
                fontFamily: "El Messiri",
                fontStyle: "normal",
                fontWeight: 400,
                textAlign: "left",
              }}
              fontSize={{
                sm: "16px !important",
                xs: winndoWidth < 376 ? "16x !important" : "14px !important",
              }}
            >
              Total Price:
              <span style={{ marginLeft: "3px" }}>
                {!voucherResponse || Object.keys(voucherResponse).length === 0
                  ? flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountUK &&
                    process.env.REACT_APP_PROJECT_COUNTRY === "uk" &&
                    isDiscountValidDate()
                    ? // signForPricing + addCommasToNumber(tPrice * totalGuests):
                    signForPricing + addCommasToNumber(finalPriceDiscount)
                    :
                    flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountIE && process.env.REACT_APP_PROJECT_COUNTRY === "ie" && isDiscountValidDate() ? signForPricing + addCommasToNumber(finalPriceDiscount) :
                      signForPricing +
                      addCommasToNumber(roundPrice(packagePricePerPerson) * totalGuests)
                  : signForPricing + addCommasToNumber(finalPrice)}
              </span>
              {/* {signForPricing + addCommasToNumber(roundPrice(packagePricePerPerson) * totalGuests + packagePricePerPersonHoliday)} */}
              {/* }} fontSize={{ sm: "16px !important", xs: "16px !important" }}>Total Price: {signForPricing + addCommasToNumber(roundPrice(packagePricePerPerson) * totalGuests)} */}
            </Typography>
            {(flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountUK
              && process.env.REACT_APP_PROJECT_COUNTRY === "uk"
              && isDiscountValidDate() && totalPrice >= 4000) ?
              <Typography
                sx={{
                  fontFamily: "El Messiri",
                  fontStyle: "normal",
                  fontWeight: 400,
                  ml: winndoWidth > 376 ? "10px" : "0",
                  textAlign: "left",
                }}
                fontSize={{
                  sm: "16px !important",
                  xs: winndoWidth < 376 ? "16x !important" : "14px !important",
                }}
              >
                {" "}
                Was
                <span style={{ marginLeft: "3px", textDecoration: "line-through" }}>
                  {signForPricing + addCommasToNumber(roundPrice(packagePricePerPerson) * totalGuests)}
                </span>
              </Typography>

              : (flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountIE &&
                process.env.REACT_APP_PROJECT_COUNTRY === "ie" &&
                isDiscountValidDate()) ? (
                <Typography
                  sx={{
                    fontFamily: "El Messiri",
                    fontStyle: "normal",
                    fontWeight: 400,
                    ml: winndoWidth > 376 ? "10px" : '0',
                    textAlign: "left",
                  }} fontSize={{ sm: "16px !important", xs: winndoWidth < 376 ? '16x !important' : "14px !important" }}
                >
                  Was
                  <span style={{ marginLeft: '3px', textDecoration: 'line-through' }}>
                    {signForPricing + addCommasToNumber(roundPrice(packagePricePerPerson) * totalGuests)}
                  </span>
                </Typography>
              ) : null}
          </Box>
          <Typography
            sx={{
              mt: { xs: winndoWidth > 376 ? "0px" : "0px" },
              fontFamily: "El Messiri",
              fontStyle: "normal",
              textAlign: "left",
              fontWeight: 400,
            }}
            fontSize="12px"
            color="#212529"
          >
            {flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountUK && process.env.REACT_APP_PROJECT_COUNTRY === "uk" && isDiscountValidDate() && totalPrice >= 4000 &&
              discountMessage}
          </Typography>
          <Typography sx={{
            mt: { xs: winndoWidth > 376 ? '0px' : "0px" }, fontFamily: "El Messiri",
            fontStyle: "normal",
            textAlign: 'left',
            fontWeight: 400,
          }} fontSize='12px' color='#212529'>
            {flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountIE && process.env.REACT_APP_PROJECT_COUNTRY === "ie" && isDiscountValidDate() &&
              discountMessage}
          </Typography>
        </Box>
        <Box
          display={{ sm: "flex" }}
          alignSelf={"auto"}
          // width={'100%'}
          sx={{
            // alignSelf: "flex-start",
            marginTop: "-10px",
            "& .MuiButtonBase-root.MuiButton-root": {
              marginTop: "0px",
            },
            "& .MuiButtonBase-root": {
              padding: winndoWidth < 374 ?
                flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountUK && process.env.REACT_APP_PROJECT_COUNTRY === "uk" && isDiscountValidDate() ? '14px 8px' :
                  flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountIE && process.env.REACT_APP_PROJECT_COUNTRY === "ie" && isDiscountValidDate() ? '14px 8px' : '14px 16px' : '14px 16px',
              color: colors.basics.white,
              background: isPlataniumHotel
                ? `${colors.basics.plataniumGreenColor} !important`
                : `${colors.basics.primary} !important`,
              height: "auto",
              border: isPlataniumHotel
                ? `1px solid ${colors.basics.plataniumGreenColor} !important`
                : `1px solid ${colors.basics.primary} !important`,
              "&:hover": {
                background: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                color: `${colors.basics.white} !important`,
              },
            },
          }}
        >
          <Button
            sx={{
              width: "100%",
              marginTop: "10px",
              fontSize: { sm: "16px !important", xs: "12px !important" },
            }}
            className="book-button btn-olb-passenger-details"
            onClick={handleBook}
          >
            Continue to book
          </Button>
        </Box>
      </Box>

      {/* <Typography
        sx={{
          mt: { xs: winndoWidth > 376 ? "10px" : "10px" },
          fontFamily: "El Messiri",
          fontStyle: "normal",
          textAlign: "left",
          fontWeight: 400,
        }}
        fontSize="12px"
        color="#212529"
      >
        {process.env.REACT_APP_PROJECT_COUNTRY === "uk" && isDiscountValidDate() && discountMessage}
      </Typography> */}
      <Box marginTop={"10px"} display='flex' width={'100%'} alignItems={'center'} sx={{ background: colors.grey[20] }} p='5px 5px' margin={'0 -5px'}>
        <Box className='Icon' sx={{
          mr: "20px",
          "& svg": {
            '& path': {
              fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary + " !important"
            },
            fill: isPlataniumHotel ? colors.basics.plataniumGreenColor + " !important" : colors.basics.primary + " !important"
          },
          '& g path:nth-of-type(2)': {
            fill: colors.basics.white + " !important"
          }
        }} maxWidth={{ sm: '40px', xs: "10px" }}>
          {process.env.REACT_APP_PROJECT_COUNTRY === "uk" && <Pound height={24} width={24} fill={colors.basics.primary} />}
          {process.env.REACT_APP_PROJECT_COUNTRY === "ie" && <Euro />}
          {process.env.REACT_APP_PROJECT_COUNTRY === "us" && <Dollar />}
        </Box>

        <Box className='content' >
          <Typography variant='body1' fontWeight={500} lineHeight='130%'>
            {deposit !== 0 ? `Due today: Deposit ${signForPricing + addCommasToNumber(deposit)}` : `Due today: Full payment`}
          </Typography>
        </Box>
      </Box>
      <Box onClick={() => setShowMore(!showMore)} sx={{ display: "flex", overflowY: "auto", maxHeight: "60px", alignItems: !showMore ? "baseline" : "baseline", marginBottom: taxText && typeof taxText === 'string' ? "70px" : '24px' }}  >
        {taxText && typeof taxText === 'string' ? <Typography textAlign={"left"} variant='subtitle2' marginTop={1} fontSize='12px' color='#212529' dangerouslySetInnerHTML={{ __html: taxText }} sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          WebkitLineClamp: showMore ? 'none' : maxLines, // Clamp lines if "showMore" is false
          maxHeight: showMore ? 'none' : `${maxLines * 1.4}em`, // Adjust based on font size
          transition: 'max-height 0.3s ease',
        }} /> : null}
        {taxText && typeof taxText === 'string' && lineCount >= maxLines && (
          <span onClick={() => setShowMore(!showMore)} style={{ padding: winndoWidth < 374 ? '0 10px' : 0, display: "flex", textAlign: "left", fontSize: "12px", color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary, cursor: "pointer" }}>
            {showMore ? <ArrowUp /> : <ArrowDown />}</span>
        )}
      </Box>
    </Box >,
    document.body
  );
};
