import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Rating,
  Slider,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { resetFilters, setFilterState } from '../../features/filter/filterSlice';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '../../styles/colors';
import { roundPrice } from '../../helpers/RoundPrice';
import { setDialogState } from '../../features/dialog/dialogSlice';
import { useAppSelector } from '../../hooks/useAppReducer';
import { useDispatch } from 'react-redux';
import { setFindButtonClick } from '../../features/findButtonClicked/findButtonClicked';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { setSessionStorage } from '../../helpers/storage';
import { sortRoom } from '../../helpers/sortRoom';
import { isDiscountValidDate } from '../../helpers/isDiscountValidDate';
import { calculatePerPersonPrice } from '../../helpers/calculatePerPersonPrice';
import { formatPrice } from '../../helpers/formatPrice';
// const holidayType = [
//   'Adults Only',
//   'Honeymoons',
//   'Family Holidays',
//   'Twin & Multi Centre Holidays',
//   'Tours',
//   'Cruise Holidays',
//   'Weddings',
//   'Luxury Holidays',
//   'All Inclusive',
//   'Villa Holidays',
// ];

interface filterSearchDialogProps {
  // setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FilterSearchDialog = (props: filterSearchDialogProps) => {
  //! States
  const { filters } = useAppSelector((state) => state.filters);
  const dialogState = useAppSelector((state) => state.dialog.dialogState);
  const { stepper } = useAppSelector((state) => state.stepper);
  const { holidayPackage, holidayTypes, filterCounts, regionCounts, regionTypes, filteredHolidayPackage } =
    useAppSelector((state) => state.holidayPackage);
  const [showAll, setShowAll] = useState<any>({
    region: false,
    hotels: false,
  });
  const { hotelDetails } = useAppSelector((state) => state.hotelDetails);
  const isPlataniumHotel = hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel
    ? Number(hotelDetails.HotelInfo.HotelInfo.platinumHotel) !== 0
    : false;
  const { flightRoom } = useAppSelector((state) => state.flightRoom);
  const { holiday } = useAppSelector((state) => state.holidaySummary);
  //! Hooks
  const [pricePerPerson, setPricePerPerson] = useState<any>(filters.price);
  const [resultAvailableHotelRatings, setResultAvailableHotelRatings] = useState([]);
  const totalGuests = Number(flightRoom.searchCriteria.adults + flightRoom.searchCriteria.childrens);
  const signForPricing = process.env.REACT_APP_CURRENCY_SYMBOL;
  //! Variables
  const dispatch = useDispatch();

  useEffect(() => {
    let ratings: any = [];
    holidayPackage?.Packages?.results
      ?.map((item: any) => item.HotelInfo)
      .filter((item: any) => {
        if (item?.hotel_rating) {
          let value = 0;
          if (item.hotel_classification === '0') {
            value = Number(item.hotel_rating);
          } else {
            if (item.hotel_rating === '-1') {
              value = Number(item.hotel_rating);
            } else {
              value =
                item?.hotel_classification === '2'
                  ? Number(item.hotel_rating) === 5
                    ? 5
                    : Number(item.hotel_rating) + 0.5
                  : Number(item.hotel_rating);
            }
          }

          if (!ratings.includes(value)) {
            ratings.push(value);
          }
        }
        return item;
      });
    setResultAvailableHotelRatings(ratings.sort((a: number, b: number) => b - a));
  }, [holidayPackage?.Packages?.results]);

  const minPackage = useMemo(() => {
    return holidayPackage?.Packages?.results?.map((data: any, index: number) => {
      const isEqual = data?.SearchResult?.hotel_id === flightRoom?.hotel_id;

      const getRooms =
        stepper === 0
          ? sortRoom(data.SearchResult, filters)
          : isEqual
            ? flightRoom.multiRooms
            : sortRoom(data.SearchResult, filters);

      let price: any = 0;
      if (getRooms.length > 0) {
        getRooms.map((f: any) => {
          price = price + Number(f.total_package);
        });
      }

      const totalPrice = price / totalGuests;
      let finalPrice;
      if (flightRoom?.searchCriteria?.destination?.discountPrice?.isDiscountUK
        && process.env.REACT_APP_PROJECT_COUNTRY === "uk"
        && isDiscountValidDate()) {
        finalPrice = Number(formatPrice(String(calculatePerPersonPrice(roundPrice(totalPrice), totalGuests))));
      } else if (flightRoom?.searchCriteria?.destination?.discountPrice?.isDiscountIE
        && process.env.REACT_APP_PROJECT_COUNTRY === "ie"
        && isDiscountValidDate()) {

        finalPrice = Number(formatPrice(String(calculatePerPersonPrice(roundPrice(totalPrice), totalGuests))));
      } else {
        finalPrice = Number(roundPrice(String(totalPrice)));
      }
      return finalPrice;
    });
  }, [holidayPackage?.Packages?.results, stepper, flightRoom.multiRooms]);

  const price = {
    low:
      minPackage?.length !== 0
        ? minPackage?.reduce((min: number, current: number) => {
          return min > current ? current : min;
        })
        : 0,
    high:
      minPackage?.length !== 0
        ? minPackage?.reduce((max: number, current: number) => {
          return max > current ? max : current;
        })
        : 0,
  };

  //! Functions
  const handleClear = () => {
    dispatch(resetFilters());
    setPricePerPerson([price?.low, price?.high]);
    dispatch(
      setFilterState({
        holidayType: [],
        starRating: [],
        hotelName: '',
        sorting: 'Popularity',
        regionType: [],
        price: [price?.low, price?.high],
      })
    );
  };

  const handleChangeHolidayTypeFilter = (e: any) => {
    let holidayTypes: any = [...filters.holidayType];

    if (e.target.checked) {
      holidayTypes.push(e.target.value);
    } else {
      holidayTypes = holidayTypes.filter((item: any) => item !== e.target.value);
    }

    dispatch(setFilterState({ ...filters, holidayType: holidayTypes }));
  };

  const handleChangeRating = (e: any) => {
    let ratings = [...filters.starRating];

    if (e.target.checked) {
      ratings.push(Number(e.target.value));
    } else {
      ratings = ratings.filter((item: any) => item !== Number(e.target.value));
    }

    dispatch(setFilterState({ ...filters, starRating: ratings }));
  };

  const handleChangeShowAllFilters = (name: any) => {
    setShowAll({
      ...showAll,
      [name]: !showAll[name],
    });
  };

  const handleChangeRegionTypeFilter = (e: any) => {
    const regionTypes: any = [...filters.regionType];
    if (e.target.checked) {
      regionTypes.push(e.target.value);
    } else {
      regionTypes.splice(regionTypes.indexOf(e.target.value), 1);
    }
    dispatch(setFilterState({ ...filters, regionType: regionTypes }));
  };

  //! Return
  return (
    <Dialog
      sx={{
        '& .MuiPaper-elevation ': {
          maxWidth: '600px',
          width: '100%',
        },
        '& .MuiDialog-paper': {
          margin: '15px !important',
        },
      }}
      open={dialogState.filterDialog}
      // onClose={() => dispatch(setDialogState({ ...dialogState, filterDialog: false }))}
      className={'filter-modal'}
    >
      <Box p={{ sm: '11px 19px', xs: '11px 0px' }}>
        <Box
          display='flex'
          justifyContent='space-between'
          mb='21px'
          sx={{
            '& .MuiButtonBase-root': {
              p: 0,
            },
          }}
        >
          <Button
            variant='text'
            sx={{
              fontWeight: 500,
              p: '4px 0 6px',
              justifyContent: 'flex-start',
              color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
              '&:hover': {
                color: isPlataniumHotel
                  ? `${colors.basics.plataniumGreenColor} !important`
                  : `${colors.basics.primary}`,
              },
            }}
            onClick={handleClear}
          >
            Clear
          </Button>

          {/* <Close onClick={() => dispatch(setDialogState({ ...dialogState, filterDialog: false }))} /> */}
          <IconButton
            onClick={() => dispatch(setDialogState({ ...dialogState, filterDialog: false }))}
            disableRipple
          >
            <CloseIcon sx={{ color: colors.basics.black }} />
          </IconButton>
        </Box>

        <Typography variant='body1' fontWeight={600} mb='21px'>
          Filter
        </Typography>
        <Divider />

        <div hidden={regionTypes?.length <= 1}>
          {/* <Divider /> */}
          <Box m='24px 0 19px'>
            <FormControl component='fieldset' variant='standard'>
              <FormLabel sx={{ mb: '10px' }}>
                <Typography variant='subtitle1'>Region</Typography>
              </FormLabel>
              <FormGroup
                sx={{
                  display: 'flex',
                  flexDirection: { md: 'col', xs: 'row' },
                  '& label': {
                    flex: { lg: '0 0 100%', sm: '0 0 48%', xs: '0 0 100%' },
                    alignItems: 'flex-start',
                  },
                  ml: '10px',
                  '& .MuiButtonBase-root': {
                    padding: '0',
                    mr: '15px',
                    mb: '5px',
                  },
                }}
              >
                {regionTypes?.slice(0, !showAll.region ? 5 : regionTypes.length)?.map((place, index) => {
                  return (
                    regionCounts[place] > 0 && (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            name={place}
                            sx={{
                              color: isPlataniumHotel
                                ? colors.basics.plataniumGreenColor
                                : colors.basics.primary,
                              '&.Mui-checked': {
                                color: isPlataniumHotel
                                  ? colors.basics.plataniumGreenColor
                                  : colors.basics.primary,
                              },
                            }}
                          />
                        }
                        label={place + ' (' + (regionCounts[place] || 0) + ')'}
                        value={place}
                        checked={filters.regionType.includes(place)}
                        onChange={handleChangeRegionTypeFilter}
                      />
                    )
                  );
                })}
              </FormGroup>
              {regionTypes.length > 5 &&
                (!showAll.region ? (
                  <Box
                    sx={{
                      '& .MuiButton-endIcon>*:nth-of-type(1)': {
                        fontSize: '25px',
                      },
                      '& .MuiButton-endIcon': {
                        marginLeft: 0,
                      },
                      color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                      '&:hover': isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                    }}
                  >
                    {' '}
                    <Button
                      sx={{
                        justifyContent: 'left',
                        fontSize: '15px',
                        p: 0,
                        whiteSpace: 'nowrap',
                        color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                        '&:hover': {
                          color: isPlataniumHotel
                            ? `${colors.basics.plataniumGreenColor} !important`
                            : `${colors.basics.primary}`,
                        },
                      }}
                      onClick={() => {
                        handleChangeShowAllFilters('region');
                      }}
                      variant='text'
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      Show all
                    </Button>{' '}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      '& .MuiButton-endIcon>*:nth-of-type(1)': {
                        fontSize: '25px',
                      },
                      '& .MuiButton-endIcon': {
                        marginLeft: 0,
                      },
                      color: isPlataniumHotel
                        ? colors.basics.plataniumGreenColor + '!important'
                        : colors.basics.primary,
                      '&:hover': {
                        color: isPlataniumHotel
                          ? `${colors.basics.plataniumGreenColor} !important`
                          : `${colors.basics.primary}`,
                      },
                    }}
                  >
                    {' '}
                    <Button
                      onClick={() => {
                        handleChangeShowAllFilters('region');
                      }}
                      variant='text'
                      endIcon={<KeyboardArrowUpIcon />}
                      sx={{
                        justifyContent: 'left',
                        fontSize: '15px',
                        p: 0,
                        whiteSpace: 'nowrap',
                        color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                      }}
                    >
                      Show less
                    </Button>
                  </Box>
                ))}
            </FormControl>
          </Box>
          <Divider />
        </div>
        <Box m='24px 0 19px'>
          <FormControl component='fieldset' variant='standard'>
            <FormLabel sx={{ mb: '10px' }}>
              <Typography variant='subtitle1'>Holiday Type</Typography>
            </FormLabel>
            <FormGroup
              sx={{
                display: 'flex',
                ml: '10px',
                flexWrap: 'wrap',
                flexDirection: 'row',
                '& label': {
                  flex: { sm: holidayTypes.length === 1 ? '0 0 100%' : '0 0 48%', xs: '0 0 100%' },
                  alignItems: 'flex-start',
                },
              }}
            >
              {holidayTypes
                ?.slice(0, !showAll.hotels ? 5 : holidayTypes.length)
                ?.map((place: string, i: number) => {
                  return (
                    filterCounts[place.split('~')[1]] > 0 && (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            name={place}
                            sx={{
                              pr: '12px',
                              color: isPlataniumHotel
                                ? colors.basics.plataniumGreenColor
                                : colors.basics.primary,
                              '&.Mui-checked': {
                                color: isPlataniumHotel
                                  ? colors.basics.plataniumGreenColor
                                  : colors.basics.primary,
                              },
                            }}
                            disableRipple
                          />
                        }
                        label={place.split('~')[1] + ' (' + (filterCounts[place.split('~')[1]] || 0) + ')'}
                        value={place}
                        checked={filters.holidayType.includes(place)}
                        onChange={handleChangeHolidayTypeFilter}
                      />
                    )
                  );
                })}
            </FormGroup>

            {holidayTypes.length > 5 &&
              (!showAll.hotels ? (
                <Box
                  sx={{
                    '& .MuiButton-endIcon>*:nth-of-type(1)': {
                      fontSize: '25px',
                    },
                    '& .MuiButton-endIcon': {
                      marginLeft: 0,
                    },
                  }}
                >
                  {' '}
                  <Button
                    sx={{
                      justifyContent: 'left',
                      fontSize: '15px',
                      p: 0,
                      whiteSpace: 'nowrap',
                      color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                      '&:hover': {
                        color: isPlataniumHotel
                          ? `${colors.basics.plataniumGreenColor} !important`
                          : `${colors.basics.primary}`,
                      },
                    }}
                    onClick={() => {
                      handleChangeShowAllFilters('hotels');
                    }}
                    variant='text'
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Show all
                  </Button>{' '}
                </Box>
              ) : (
                <Box
                  sx={{
                    '& .MuiButton-endIcon>*:nth-of-type(1)': {
                      fontSize: '25px',
                    },
                    '& .MuiButton-endIcon': {
                      marginLeft: 0,
                    },
                    color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                  }}
                >
                  {' '}
                  <Button
                    onClick={() => {
                      handleChangeShowAllFilters('hotels');
                    }}
                    variant='text'
                    endIcon={<KeyboardArrowUpIcon />}
                    sx={{
                      justifyContent: 'left',
                      fontSize: '15px',
                      p: 0,
                      whiteSpace: 'nowrap',
                      color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                      '&:hover': {
                        color: isPlataniumHotel
                          ? `${colors.basics.plataniumGreenColor} !important`
                          : `${colors.basics.primary}`,
                      },
                    }}
                  >
                    Show less
                  </Button>
                </Box>
              ))}
          </FormControl>
        </Box>
        <Divider />
        {resultAvailableHotelRatings.length > 0 && (
          <>
            <Box m='18px 0 15px'>
              <FormControl component='fieldset' variant='standard'>
                <FormLabel sx={{ mb: '13px' }}>
                  <Typography variant='subtitle1'>Star Rating</Typography>
                </FormLabel>
                <FormGroup
                  sx={{
                    ml: '10px',
                  }}
                >
                  {resultAvailableHotelRatings?.map((rating: number, i: number) => {
                    return rating <= 5 ? (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            sx={{
                              color: isPlataniumHotel
                                ? colors.basics.plataniumGreenColor
                                : colors.basics.primary,
                              '&.Mui-checked': {
                                color: isPlataniumHotel
                                  ? colors.basics.plataniumGreenColor
                                  : colors.basics.primary,
                              },
                            }}
                            disableRipple
                          />
                        }
                        value={rating}
                        label={
                          <Rating
                            sx={{ fontSize: '18px' }}
                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />}
                            defaultValue={rating}
                            precision={rating % 1 === 0 ? 1 : 0.5}
                            readOnly={true}
                          />
                        }
                        checked={filters.starRating.includes(Number(rating))}
                        onChange={(e) => {
                          // resultAvailableHotelRatings?.includes(String(rating)) &&
                          handleChangeRating(e);
                        }}
                      />
                    ) : null;
                  })}
                </FormGroup>
              </FormControl>
            </Box>
            {/* <Divider /> */}
          </>
        )}
        {holidayPackage?.Packages?.results?.length > 1 && <Divider />}
        {holidayPackage?.Packages?.results?.length > 1 && (
          <Box m='30px 0 0'>
            <Typography variant='subtitle1' mb='10px'>
              Price (per person)
            </Typography>
            <Typography variant='body1' fontWeight={500} mb='9px'>
              {signForPricing + pricePerPerson[0]} - {signForPricing + pricePerPerson[1]}
            </Typography>
            <Box
              sx={{
                '& .MuiSlider-rail': {
                  backgroundColor: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor
                    : colors.basics.primary,
                },
                '& .MuiSlider-thumb': {
                  color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                },
                '& .MuiSlider-track': {
                  color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                },
                '& .MuiSlider-thumb, &:hover, &.Mui-focusVisible, &.Mui-active': {
                  boxShadow: 'none !important',
                },
              }}
            >
              <Slider
                onChangeCommitted={(event: React.SyntheticEvent | Event, val: any) => {
                  dispatch(setFilterState({ ...filters, price: val }));
                  dispatch(setFindButtonClick(false));
                }}
                min={price?.low || 0}
                max={price?.high || 10500}
                valueLabelDisplay='auto'
                aria-labelledby='range-slider'
                value={pricePerPerson || 0}
                onChange={(e, val: number | any) => {
                  setPricePerPerson(val);
                }}
              />
            </Box>
            <Box
              sx={{
                '& .MuiButtonBase-root': {
                  border: isPlataniumHotel
                    ? `1px solid ${colors.basics.plataniumGreenColor}`
                    : `1px solid ${colors.basics.primary}`,
                  // color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                  background: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                  // background: colors.grey[30],
                  '&:hover': {
                    border: isPlataniumHotel
                      ? `1px solid ${colors.basics.plataniumGreenColor} !important`
                      : `1px solid ${colors.basics.primary}`,
                    background: isPlataniumHotel
                      ? `${colors.basics.plataniumGreenColor} !important`
                      : `${colors.basics.primary}`,
                  },
                },
              }}
            >
              <Button
                variant='contained'
                fullWidth
                onClick={() => {
                  setSessionStorage('click', 'false');
                  dispatch(setDialogState({ ...dialogState, filterDialog: false }));
                }}
              >
                Apply
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default FilterSearchDialog;
