// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box, Button, Grid, Rating, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { RoomMealWrapper } from "../../../helpers/roomMealWrapper";
import { addCommasToNumber } from "../../../helpers/commaSeparatedPrice";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { setDialogState } from "../../../features/dialog/dialogSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { ReactComponent as SliderPhotoIcon } from "../../../assets/icons/SliderPhotoIcon.svg";
import StarIcon from "@mui/icons-material/Star";
import { capitalizeFirstLetterEachWords } from "../../../helpers/capitalizeFirstLetterEachWords";
import { isEmpty } from "../../../helpers/isEmpty";
import { useAppSelector } from "../../../hooks/useAppReducer";
import { ApplicationStateType } from "../../../features/store";
import { colors } from "../../../styles/colors";
import { ReactComponent as LocationIcon } from "../../../assets/icons/LocationIcon.svg";
import { HolidayCard } from "../../../components/HolidayCard";
import OfferDetails from "../../../components/OfferDetails";
import { TabComponent } from "../../../components/TabComponent";
import { TabPanel } from "../../../components/TabPanel";
import { StaticAccordion } from "./StaticAccordion";
import { ReactComponent as CloseModal } from "../../../assets/icons/CloseModal.svg";
import { setStaticSiteAccordianContent } from "../../../features/staticSiteAccordianData/staticSiteAccordianData";
import { getSiteID } from "../../../helpers/getSiteId";
import { getApi } from "../../../utils/api";
import { CircleRating } from "../HolidaySummary";
import Image from "../../../components/Image";
import ReviewsTab from "./ReviewsTab";
import { roundPrice } from "../../../helpers/RoundPrice";
import { calculatePerPersonPrice } from "../../../helpers/calculatePerPersonPrice";
import { isDiscountValidDate } from "../../../helpers/isDiscountValidDate";
import { formatPrice } from "../../../helpers/formatPrice";

interface hotelInfoProps {
    setSelectedImage: any;
    selectedImage: any;
    hotelImages: any;
    rating: any;
    nights: any;
    signForPricing: any;
    packagePricePerPerson: any;
    handleOpenHotelOnMap: any;
    isVisibleOverviewData: any;
    setIsVisibleOverviewData: any;
    totalOverviewParagraph: any;
    scrollToFirstPara: any;
    totalOverviewParagrap: any;
    offerDetails: any;
    openReviewTab?: boolean;
    setOpenReviewTab?: any;
    // tripAdvisorData?: (state?: any) => void;
    roundedPrice?: any;
    unroundedPrice?: any;
    tripAdvisorData?: any;
    reviewsTripAdvisorData?: any;
}

const HotelInfoModal = ({
    setSelectedImage,
    selectedImage,
    hotelImages,
    rating,
    nights,
    signForPricing,
    packagePricePerPerson,
    handleOpenHotelOnMap,
    isVisibleOverviewData,
    setIsVisibleOverviewData,
    totalOverviewParagraph,
    scrollToFirstPara,
    offerDetails,
    openReviewTab = false,
    setOpenReviewTab,
    roundedPrice,
    unroundedPrice,
    tripAdvisorData,
    reviewsTripAdvisorData
}: hotelInfoProps) => {
    const dialogState = useAppSelector((state) => state.dialog.dialogState);
    const windowWidth = useWindowWidth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [apiCallAccordianData, setApicallAccordianData] = useState(true);
    const { staticSiteAccordianData } = useAppSelector((state) => state.staticSiteAccordianData);
    const { hotelDetails, tripHotelDetails } = useAppSelector(
        (state) => state.hotelDetails
    );
    
    const { countryRemark } = useAppSelector((state) => state.packageDetails);
    const { flightRoom } = useAppSelector((state: ApplicationStateType) => state.flightRoom);
    const [tabsData, setTabsData] = useState<any>([]);
    const [currentTabHotel, setCurrentTabHotel] = useState(() => {
        if (!tripHotelDetails?.reviews_rating) {
            return tabsData[0]?.title || "Location"; // Default to first tab or "Locations"
        }
        return openReviewTab ? "Reviews" : "Important Info";
    });
    const [currentSlide, setCurrentSlide] = useState(0);
    const isPlataniumHotel = hotelDetails?.HotelInfo?.HotelInfo?.platinumHotel
        ? Number(hotelDetails.HotelInfo.HotelInfo.platinumHotel) !== 0
        : false;

    const scrollableRef = useRef(null);
    const totalGuest = flightRoom.searchCriteria.adults + flightRoom.searchCriteria.childrens;

    const handleScroll = () => {
        if (scrollableRef.current) {
            scrollableRef.current?.scrollIntoView({ top: "100%", behavior: "smooth" });
            setCurrentTabHotel("Reviews");
            setOpenReviewTab(true);
        }
    };

    useEffect(() => {
        if (hotelImages && hotelImages?.length > 0 && !selectedImage) {
          // !selectedImage && setSelectedImage(hotelImages[0]?.VendorImage?.printed_image_url);
          setSelectedImage(hotelImages[0]?.VendorImage?.printed_image_url);
        }
      }, [hotelImages,selectedImage]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const siteID = getSiteID();
                const apiUrl = `${process.env.REACT_APP_PUBLIC_STATIC_URL}productfooter?tsrProductID=${flightRoom.hotel_id}&siteID=${siteID}`;
                const response = await getApi(apiUrl);
                if (response !== "error") {
                    dispatch(setStaticSiteAccordianContent(response));
                    // setCurrentTabHotel(staticSiteAccordianData.accordions[0]?.title);
                    // setStaticAccodianData(response);
                    setApicallAccordianData(false);
                } else {
                    setApicallAccordianData(false);
                }
            } catch (error) {
                console.error("Error:", error);
                setApicallAccordianData(false);
            }
        };
        // if (apiCallAccordianData && flightRoom.callHolidaySummaryApi) {
        fetchData();
        // }
    }, [apiCallAccordianData, flightRoom.hotel_id, flightRoom.callHolidaySummaryApi]);

    const settingsHotel: any = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: windowWidth > 600 ? hotelImages.length <= 4 ? 2 : 4 : 1,
        slidesToScroll: windowWidth > 600 ? 1 : 1,
        afterChange: (index: number) => setCurrentSlide(index),
    };
    const handleImageClick = (imageUrl: string) => {
        setSelectedImage(imageUrl);
    };

    const renderImportantInfoData = () => {
        return Object.values(countryRemark)?.length ? (
            <>
                {Object.values(countryRemark).map((country: any, index) => (
                    <React.Fragment key={index}>
                        <Typography
                            sx={{
                                fontFamily: "Raleway",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: { xl: 20, xs: 18 },
                                lineHeight: "1.2",
                            }}
                        >
                            {country?.title} :{" "}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Raleway",
                                fontStyle: "normal",
                                fontWeight: 400,
                                // lineClamp:{xl:3},
                                fontSize: { xl: 16, xs: 14 },
                                lineHeight: "1.2",
                            }}
                            dangerouslySetInnerHTML={{ __html: country?.remark }}
                        />
                    </React.Fragment>
                ))}
            </>
        ) : (
            <Typography
                sx={{
                    fontFamily: "Raleway",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: { xl: 16, xs: 14 },
                    lineHeight: "1.2",
                }}
                dangerouslySetInnerHTML={{ __html: staticSiteAccordianData?.importantInfo }}
            />
        );
    };

    useEffect(() => {
        const firstData = {
            sortOrder: 8,
            title: "Important Info",
        };
        const lastData = {
            sortOrder: 0,
            title: "Reviews",
        };
        const tabs: any = [...staticSiteAccordianData.accordions];
        if (tripHotelDetails?.reviews_rating) {
            tabs.unshift(lastData);
        }
        if (Object.values(countryRemark)?.length || staticSiteAccordianData?.importantInfo) {
            tabs.push(firstData);
        }
        // tabs.push(firstData);

        setTabsData(tabs);
    }, [staticSiteAccordianData]);

    const handleChangeHotelTabs = (newValue: string) => {
        setCurrentTabHotel(newValue);
    };

    const Dashboardtabs: string[] = [
        "Locations",
        "Accommodation",
        "Restaurants & Bars",
        "Other Facilities",
        "Spa & Wellness",
        "For Families",
    ];
    const formatTabName = (name: string) => name.toLowerCase().replace(/ & /g, "").replace(/ /g, "");

    return (
        <Box sx={{
            overflowX: "hidden", padding: "0 20px", "& ::selection": {
                background: isPlataniumHotel
                    ? colors.basics.plataniumGreenColor
                    : colors.basics.primary,
            },
        }}>
            <Box
                sx={{
                    p: "14px 0",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    " & svg": {
                        width: "18px",
                        height: "18px",
                    },
                }}
            >
                <Box>
                    <Typography variant="subtitle1">Hotel details</Typography>
                </Box>
                <Button
                    sx={{ p: "0", minWidth: 0 }}
                    variant="text"
                    onClick={() => dispatch(setDialogState({ ...dialogState, hotelInfoDialog: false }))}
                >
                    {" "}
                    <CloseModal />{" "}
                </Button>
            </Box>
            {windowWidth < 992 && (
                <Box>
                    <Box sx={{ "& .slick-arrow": { display: "none !important" } }}>
                        {/* Display the first image */}
                        {windowWidth > 600  && hotelImages?.length > 0 && (
                            <Box>
                                <img
                                    src={selectedImage}
                                    alt="Selected"
                                    style={{ width: "100%", height: windowWidth > 600 ? "300px" : "491px " }}
                                // style={{ width: "100%", maxWidth: '835px ', height: '491px ' }}
                                />
                            </Box>
                        )}

                        {/* Slider for the remaining images */}
                        <Slider {...settingsHotel}>
                            {hotelImages?.length > 0 &&
                                hotelImages?.slice(0)?.map((image: any, index: any) => {
                                    const isEndOfGroup = (index + 1) % 3 === 0;
                                    return (
                                        image?.VendorImage?.id?.length > 0 && (
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    // maxWidth: windowWidth < 600 ? "267px" : "auto",
                                                    // minHeight: windowWidth < 600 ? "167px" : "auto",
                                                    height: {
                                                        xl: "600px",
                                                        lg: "500px",
                                                        md: "400px",
                                                        sm: "100%",
                                                        xs: "200px",
                                                    },
                                                    overflow: "hidden",
                                                    "& img": {
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                        minHeight: { sm: "100x", xs: "145px" },
                                                        objectPosition: "top",
                                                    },
                                                    p: {
                                                        md: isEndOfGroup ? "10px 0 0px 0" : "10px 20px 0px 0", // No right padding for the last image
                                                        sm: isEndOfGroup ? "10px 0 0px 0" : "10px 10px 0px 0", // No right padding for the last image
                                                    },
                                                    position: "relative",
                                                }}
                                                key={image.VendorImage.id}
                                                onClick={() => handleImageClick(image.VendorImage.printed_image_url)} // Update on image click
                                            >
                                                <Image
                                                    src={image.VendorImage.printed_image_url}
                                                    alt={image.VendorImage.image_label}
                                                // style={{
                                                // width: "100%",
                                                // height: {
                                                //     xl: "600px",
                                                //     lg: "500px",
                                                //     md: "400px",
                                                //     sm: "300px",
                                                //     xs: "200px",
                                                // },
                                                // maxWidth: windowWidth > 600 ? "267px" : "100%",
                                                // minHeight: windowWidth > 600 ? "167px" : "429px",
                                                // }}
                                                />
                                                {windowWidth < 600 && (
                                                    <Box
                                                        sx={{
                                                            position: "absolute",
                                                            bottom: "16px", // Position at the bottom
                                                            right: "16px",
                                                            backgroundColor: colors.basics.secondary, // Semi-transparent background
                                                            color: colors.grey[10],
                                                            padding: "7px 13px",
                                                            borderRadius: "5px",
                                                            fontSize: "14px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <span style={{ paddingRight: "9px" }}>
                                                            <SliderPhotoIcon />
                                                        </span>
                                                        <Typography variant="body1" fontWeight={500} lineHeight={"20.8px"}>
                                                            {" "}
                                                            {currentSlide + 1}/{hotelImages.length}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        )
                                    );
                                })}
                        </Slider>
                    </Box>
                </Box>
            )}

            <Box>
                {windowWidth < 992 && (
                    <Box
                        // sx={{ pl: { md: "20px", sm: "20px", xs: "10px " } }}
                        // sx={{ pl: "20px " }}
                        display={{ sm: "flex", xs: "flex" }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        width={"100%"}
                        gap={1}
                        my={1}
                    >
                        <Box
                            sx={{
                                "& .MuiRating-root .MuiRating-iconFilled": {
                                    color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                                },
                                "& .MuiRating-iconHover": {
                                    color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                                },
                                // width: "100%",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Rating
                                sx={{ fontSize: "21px" }}
                                value={rating}
                                precision={0.5}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                readOnly={true}
                            />
                        </Box>
                        {tripHotelDetails?.reviews_rating && (
                            <Box
                                sx={{
                                    // mt: "10px",
                                    display: { xs: windowWidth > 374 ? "flex" : "block" },
                                    textAlign: "right",
                                    alignItems: "center",
                                    gap: 1,
                                    // justifyContent: { xs: "space-between", md: "flex-start" },
                                    // flex: { xs: "0 0 100%" },

                                    "& .MuiButton-root": {
                                        // marginTop: '6px',
                                        background: colors.basics.white,
                                        height: "37px",
                                        borderColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                                    },
                                    // "& svg": {
                                    //     '& circle:first-of-type': {
                                    //         fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : ""
                                    //     },
                                    //     '& path': {
                                    //         fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : ""
                                    //     },
                                    //     fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : ""
                                    // },
                                }}
                            >
                                <CircleRating
                                    value={Number(tripHotelDetails?.reviews_rating)}
                                    isPlataniumHotel={isPlataniumHotel}
                                />
                                <Typography
                                    variant="body2"
                                    sx={{ cursor: "pointer", textDecoration: "underline" }}
                                    onClick={handleScroll}
                                >
                                    Reviews
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}

                {/* </Grid> */}
                <Grid container mt={1} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 0 }} sx={{}}>
                    <Grid md={10} sm={10} xs={12} sx={{ display: { md: "flex", xs: "block" } }}>
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            // alignItems={"center"}
                            width={{ xs: "100%" }}
                            sx={{ pl: { md: "0", sm: "20px", xs: "10px " } }}
                        >
                            <Box sx={{ mt: { md: "0px", xs: "10px" } }}>
                                <Typography
                                    variant="h1"
                                    lineHeight="1.2"
                                    textTransform={"capitalize"}
                                    fontSize={{ sm: "28px", xs: "28px" }}
                                    fontWeight={600}
                                >
                                    {capitalizeFirstLetterEachWords(isEmpty(hotelDetails?.HotelInfo?.Vendor?.vendor_name))}
                                </Typography>

                                {/* <Box display={"block"}> */}
                                <Typography
                                    variant="body1"
                                    fontWeight={500}
                                    sx={{ color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary }}
                                >
                                    {hotelDetails?.HotelInfo?.Country?.country_name}
                                </Typography>
                                {/* </Box> */}
                            </Box>
                            <Typography
                                // sx={{ mt: { md: "20px", xs: "10px" } }}
                                variant="body1"
                                fontWeight={500}
                                lineHeight="1.2"
                                mt={1}
                                fontSize={{ xs: "16px !important", sm: "16px !important" }}
                            // sx={{ fontFamily: "El Messiri" }}
                            >
                                <span
                                    style={{ textTransform: "capitalize" }}
                                    dangerouslySetInnerHTML={{
                                        __html: RoomMealWrapper(flightRoom?.multiRooms[0]?.meal_name.toLowerCase()),
                                    }}
                                />{", "}
                                {/* {` ${nights} nights from ${signForPricing + addCommasToNumber(Number(packagePricePerPerson))
                                    } per person including flights
                              `} */}
                                {flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountUK && process.env.REACT_APP_PROJECT_COUNTRY === "uk" && isDiscountValidDate()
                                    ? ` ${nights} nights from ${signForPricing}${addCommasToNumber(
                                        Number(formatPrice(String(calculatePerPersonPrice(roundPrice(unroundedPrice), totalGuest))))
                                    )} per person including flights`
                                    : flightRoom?.searchCriteria?.destination?.discountPrice.isDiscountIE && process.env.REACT_APP_PROJECT_COUNTRY === "ie" && isDiscountValidDate() ?
                                        ` ${nights} nights from ${signForPricing}${addCommasToNumber(
                                            Number(formatPrice(String(calculatePerPersonPrice(roundPrice(unroundedPrice), totalGuest))))
                                        )} per person including flights` :
                                        `${nights} nights from ${signForPricing}${addCommasToNumber(
                                            Number(roundedPrice)
                                        )} per person including flights`}
                                {/* ${flightRoom?.selectedTransfer ? "and transfers" : ""} */}
                            </Typography>
                        </Box>
                        {/* <Box
                            sx={{ pl: { md: "20px", sm: "20px", xs: "10px " } }}
                            // sx={{ pl: "20px " }}
                            display={{ sm: "flex", xs: "flex" }}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            width={{ md: "35%", xs: "100%" }}
                        >
                            <Box
                                sx={{
                                    // mt: "10px",
                                    display: "flex",
                                    // justifyContent: { xs: "space-between", md: "flex-start" },
                                    // flex: { xs: "0 0 100%" },

                                    "& .MuiButton-root": {
                                        // marginTop: '6px',
                                        background: colors.basics.white,
                                        height: "37px",
                                        borderColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                                    },
                                    // "& svg": {
                                    //     '& circle:first-of-type': {
                                    //         fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : ""
                                    //     },
                                    //     '& path': {
                                    //         fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : ""
                                    //     },
                                    //     fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : ""
                                    // },
                                }}
                            >
                                <CircleRating value={Number(tripHotelDetails?.reviews_rating)} isPlataniumHotel={isPlataniumHotel} />
                            </Box>

                            {windowWidth > 992 ? (
                                <Box
                                    sx={{
                                        "& .MuiRating-root .MuiRating-iconFilled": {
                                            color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                                        },
                                        "& .MuiRating-iconHover": {
                                            color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                                        },
                                        // width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Rating
                                        sx={{ fontSize: "21px" }}
                                        value={rating}
                                        precision={0.5}
                                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                        readOnly={true}
                                    />
                                </Box>
                            ) : (
                                null
                                // <Box
                                //     sx={{
                                //         // mt: "10px",
                                //         display: "flex",
                                //         // justifyContent: { xs: "space-between", md: "flex-start" },
                                //         // flex: { xs: "0 0 100%" },

                                //         "& .MuiButton-root": {
                                //             // marginTop: '6px',
                                //             background: colors.basics.white,
                                //             height: "37px",
                                //             borderColor: isPlataniumHotel
                                //                 ? colors.basics.plataniumGreenColor
                                //                 : colors.basics.primary,
                                //         },
                                //         "& svg": {
                                //             "& circle:first-of-type": {
                                //                 fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : "",
                                //             },
                                //             "& path": {
                                //                 fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : "",
                                //             },
                                //             fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : "",
                                //         },
                                //     }}
                                // >
                                //     <Button
                                //         onClick={handleOpenHotelOnMap}
                                //         variant="text"
                                //         disableRipple
                                //         startIcon={
                                //             <LocationIcon
                                //                 fill={isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary}
                                //             />
                                //         }
                                //         sx={{
                                //             "&:hover": {
                                //                 color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                                //                 background: colors.basics.white,
                                //                 borderColor: isPlataniumHotel
                                //                     ? colors.basics.plataniumGreenColor
                                //                     : colors.basics.primary,
                                //             },
                                //             p: "7px 11px",
                                //             fontSize: { sm: "16px", xs: "14px" },
                                //             whiteSpace: "nowrap",
                                //             color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                                //         }}
                                //     >
                                //         Show on map
                                //     </Button>
                                // </Box>
                            )}
                        </Box> */}
                    </Grid>

                    <Grid md={2} sm={2} xs={12} display={{ md: "flex", xs: "flex" }}>
                        <Box display={{ md: "flex", xs: "block" }} justifyContent={"flex-end"} width={"100%"}>
                            {/* <Box
                                sx={{
                                    mt: "10px",
                                    display: "flex",
                                    // justifyContent: { xs: "space-between", md: "flex-start" },
                                    // flex: { xs: "0 0 100%" },

                                    "& .MuiButton-root": {
                                        // marginTop: '6px',
                                        background: colors.basics.white,
                                        height: "37px",
                                        borderColor: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                                    },
                                    "& svg": {
                                        "& circle:first-of-type": {
                                            fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : "",
                                        },
                                        "& path": {
                                            fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : "",
                                        },
                                        fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : "",
                                    },
                                }}
                            >
                                <Button
                                    onClick={handleOpenHotelOnMap}
                                    variant="text"
                                    disableRipple
                                    startIcon={
                                        <LocationIcon
                                            fill={isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary}
                                        />
                                    }
                                    sx={{
                                        "&:hover": {
                                            color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                                            background: colors.basics.white,
                                            borderColor: isPlataniumHotel
                                                ? colors.basics.plataniumGreenColor
                                                : colors.basics.primary,
                                        },
                                        p: "7px 11px",
                                        fontSize: { sm: "16px", xs: "14px" },
                                        whiteSpace: "nowrap",
                                        color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                                    }}
                                >
                                    Show on map
                                </Button>
                            </Box> */}
                            {windowWidth > 992 && tripHotelDetails?.reviews_rating ? (
                                <Box
                                    sx={{ pl: { md: "20px", sm: "20px", xs: "10px " } }}
                                    // sx={{ pl: "20px " }}
                                    display={{ sm: "flex", xs: "flex" }}
                                    justifyContent={{ sm: "flex-end", md: "flex-end", xs: "flex-start" }}
                                    alignItems={"center"}
                                    width={"100%"}
                                    gap={1}
                                    mb={1}
                                >
                                    <Box
                                        sx={{
                                            // mt: "10px",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                            // gap: 1,
                                            // justifyContent: { xs: "space-between", md: "flex-start" },
                                            // flex: { xs: "0 0 100%" },

                                            "& .MuiButton-root": {
                                                // marginTop: '6px',
                                                background: colors.basics.white,
                                                height: "37px",
                                                borderColor: isPlataniumHotel
                                                    ? colors.basics.plataniumGreenColor
                                                    : colors.basics.primary,
                                            },
                                            // "& svg": {
                                            //     '& circle:first-of-type': {
                                            //         fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : ""
                                            //     },
                                            //     '& path': {
                                            //         fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : ""
                                            //     },
                                            //     fill: isPlataniumHotel ? colors.basics.plataniumGreenColor : ""
                                            // },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                "& .MuiRating-root .MuiRating-iconFilled": {
                                                    color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                                                },
                                                "& .MuiRating-iconHover": {
                                                    color: isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary,
                                                },
                                                // width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Rating
                                                sx={{ fontSize: "21px" }}
                                                value={rating}
                                                precision={0.5}
                                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                readOnly={true}
                                            />
                                        </Box>
                                        <CircleRating
                                            value={Number(tripHotelDetails?.reviews_rating)}
                                            isPlataniumHotel={isPlataniumHotel}
                                        />
                                        <Typography
                                            variant="body2"
                                            sx={{ cursor: "pointer", textDecoration: "underline", color: colors.basics.black, }}
                                            onClick={handleScroll}
                                        >
                                            Reviews
                                        </Typography>
                                    </Box>
                                </Box>
                            ) : null}
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: { md: "row", xs: "column" },
                        justifyContent: "space-between",
                        position: "relative",
                        mt: 1,
                        // minHeight: "800px",
                        // margin: {xs: "20px 0", lg: "40px 0 " }
                    }}
                    id="scrollable-div"
                >
                    <Box
                        sx={{
                            width: { md: "100%", xs: "100%" },
                            order: { md: "1 !important", xs: "2 !important" },
                            transition: "all 0.3s ease",
                        }}
                    >
                        {/* <Grid sm={8} xs={12}> */}

                        {windowWidth > 992 && (
                            <Box mt={{ md: "0", xs: "20px" }}>
                                <Box sx={{ "& .slick-arrow": { display: "none !important" } }}>
                                    {/* Display the first image */}
                                    {windowWidth > 600 && hotelImages?.length > 0 && (
                                        <Box>
                                            <img
                                                src={selectedImage}
                                                alt="Selected"
                                                style={{ width: "100%", height: "491px " }}
                                            // style={{ width: "100%", maxWidth: '835px ', height: '491px ' }}
                                            />
                                        </Box>
                                    )}

                                    {/* Slider for the remaining images */}
                                    <Slider {...settingsHotel}>
                                        {hotelImages?.length > 0 &&
                                            hotelImages?.slice(0)?.map((image: any, index: any) => {
                                                const isEndOfGroup = (index + 1) % 4 === 0;
                                                return (
                                                    image?.VendorImage?.id?.length > 4 && (
                                                        <Box
                                                            sx={{
                                                                p: {
                                                                    md: isEndOfGroup ? "10px 20px 10px 0" : "10px 20px 10px 0", // No right padding for the last image
                                                                    sm: isEndOfGroup ? "10px 0 10px 0" : "10px 10px 10px 0", // No right padding for the last image
                                                                },
                                                            }}
                                                            key={image.VendorImage.id}
                                                            onClick={() => handleImageClick(image.VendorImage.printed_image_url)} // Update on image click
                                                        >
                                                            <img
                                                                src={image.VendorImage.printed_image_url}
                                                                alt={image.VendorImage.image_label}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    // maxWidth: windowWidth > 600 ? "267px" : "100%",
                                                                    minHeight: windowWidth > 600 ? "167px" : "429px",
                                                                }}
                                                            />
                                                        </Box>
                                                    )
                                                );
                                            })}
                                    </Slider>
                                </Box>
                            </Box>
                        )}
                        <Grid container spacing={2} mb={2}>
                            <Grid item md={8} xs={12}>
                                {" "}
                                <Box
                                    sx={{
                                        bgcolor: colors.basics.secondary,
                                        p: { sm: "24px", xs: "16px" },
                                        mt: "20px",
                                        height: "100%",
                                    }}
                                >
                                    {isEmpty(hotelDetails?.HotelInfo?.HotelContent?.accomIntro) !== "" && (
                                        <Box
                                            // order={2}
                                            // pt={{ xs: "16px", sm: "20px" }}
                                            ml={{ sm: "0", xs: "0px" }}
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <Typography
                                                variant="body1"
                                                sx={{ fontSize: { sm: "24px", xs: "20px" } }}
                                                fontWeight={500}
                                                lineHeight="20.8px"
                                            >
                                                Overview{" "}
                                            </Typography>
                                            <Typography
                                                id="overview-para"
                                                variant="body2"
                                                fontSize={{ xl: "16px !important", xs: "14px !important" }}
                                                fontWeight={300}
                                                lineHeight="1.2"
                                                m="20px 0 0"
                                                sx={{
                                                    "& p:first-of-type": {
                                                        display: "block",
                                                    },
                                                    "& p": {
                                                        mb: 1.2,
                                                        display: { xs: isVisibleOverviewData ? "block" : "none", sm: "block" },
                                                    },
                                                }}
                                                dangerouslySetInnerHTML={{
                                                    __html: `${hotelDetails?.HotelInfo?.HotelContent?.accomIntro || ""} `,
                                                }}
                                            />
                                            {totalOverviewParagraph > 1 && windowWidth < 600 && (
                                                <span
                                                    onClick={() => {
                                                        setTimeout(() => {
                                                            window.scrollTo({
                                                                top: scrollToFirstPara,
                                                                behavior: "smooth",
                                                            });
                                                        }, 1);
                                                        setIsVisibleOverviewData(!isVisibleOverviewData);
                                                    }}
                                                    style={{
                                                        fontSize: "12px",
                                                        color: isPlataniumHotel
                                                            ? colors.basics.plataniumGreenColor
                                                            : colors.basics.primary,
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    {isVisibleOverviewData ? "Show less.." : "Show more.."}
                                                </span>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                {/* <Box sx={{ width: { md: '35%', xs: "100%" }, order: { md: "2 !important", xs: "1 !important" }, pl: { md: "24px", xs: "0" }, pt: { md: "0", xs: "24px" }, transition: 'all 0.3s ease' }} > */}

                                {/* <Box> <HolidayCard /></Box> */}
                                {offerDetails.length > 0 && (
                                    <Box
                                        sx={{
                                            background: "#DDDDDD",
                                            padding: { sm: "20px ", xs: "15px" },
                                            mt: "20px",
                                            height: "100%",
                                            mb: { md: 0, xs: "20px" },
                                            filter: "blur(0) !important",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                "& .MuiSvgIcon-root": {
                                                    color: colors.basics.primary,
                                                },
                                                borderBottom: "1px solid #212529",
                                                width: "fit-content",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    marginRight: "5px",
                                                    "& i": {
                                                        fontSize: "26px",
                                                        color: isPlataniumHotel
                                                            ? colors.basics.plataniumGreenColor
                                                            : colors.basics.primary,
                                                    },
                                                }}
                                            >
                                                <i className="fa fa-tag" />
                                            </Box>
                                            <Box sx={{}}>
                                                {" "}
                                                <Typography variant="h1" lineHeight="43.76px" fontSize="26px" fontWeight="500">
                                                    Offers & Deals
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                padding: "10px 20px 0px 30px",
                                                "& .package-card-chip": {
                                                    background: isPlataniumHotel
                                                        ? colors.basics.plataniumGreenColor + "!important"
                                                        : colors.basics.primary,
                                                },
                                                "& .MuiChip-root": {
                                                    background: isPlataniumHotel
                                                        ? colors.basics.plataniumGreenColor + "!important"
                                                        : colors.basics.primary,
                                                },
                                            }}
                                        >
                                            <OfferDetails data={offerDetails} />
                                        </Box>
                                    </Box>
                                )}
                                {/* </Box> */}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {windowWidth > 992 ? (
                    <Box
                        ref={scrollableRef}
                        mt={{ sm: 4, xs: 0 }}
                        sx={{
                            "& .MuiTabs-scrollButtons": {
                                display: "none",
                            },
                            " & .Mui-selected ": {
                                background: "white !important",
                                borderTopLeftRadius: "4px",
                                borderTopRightRadius: "4px",
                            },
                            "& .MuiButtonBase-root": {
                                // p: "0 30px 0 0",
                                // height: "-1px !important",
                                // borderBottom: "5px solid pink !important"
                            },
                            "& .MuiTabs-root": {
                                // height: "30%",
                                // borderBottom: "5px solid blue !important",
                                background: "#e3e3e3 !important",
                                borderTopLeftRadius: "4px",
                                borderTopRightRadius: "4px",
                            },
                            "& .MuiButtonBase-root:hover": {
                                background: "transparent",
                                transition: "none",
                                // borderBottom: "5px solid green !important"
                            },
                            "& .MuiTabs-root.MuiButtonBase-root": {
                                paddingTop: "15px !important",
                                paddingLeft: "29px",
                                paddingRight: "29px",
                            },
                            // '& .MuiButtonBase-root:hover #tabText ': {
                            //     borderBottom: "2px solid transparent !important",
                            //     marginBlockStartt: "9px",

                            // },
                            "& .MuiTabs-root #tabText ": {
                                borderBottom: "2px solid transparent !important",
                                // mt: "9px",
                                fontSize: "15px",
                                fontWeight: "500",
                                // height: "50px",
                                textAlign: "center",
                                alignItems: "center",
                                display: "flex",
                                lineHeight: 1.2,
                            },
                            "& .Mui-selected #tabText": {
                                borderBottom: `4px solid ${isPlataniumHotel ? colors.basics.plataniumGreenColor : colors.basics.primary
                                    } !important`,
                                // mt: "9px",import { colors } from '../../../styles/colors';
                                fontSize: "15px",
                                fontWeight: "500",
                            },
                            "& .MuiTabs-root .MuiButtonBase-root:hover #tabText": {
                                transition: "none",
                            },
                            "& .MuiTabs-flexContainer": {
                                // borderBottom: `1px solid ${colors.border.grey}`,
                                width: "100%",
                                justifyContent: "space-between",
                            },
                            "& ul": {
                                marginLeft: "20px",
                                paddingBottom: "16px",
                            },
                            "& li": {},
                            "& ol": {
                                marginLeft: "20px",
                                paddingBottom: "16px",
                            },
                            "& .MuiAccordionSummary-content p": {
                                paddingBottom: "0",
                            },
                            "& p": {
                                paddingBottom: "8px",
                            },
                        }}
                    >
                        {/* <CustomContainer> */}
                        <TabComponent
                            tabs={[...tabsData.map((tab: any) => tab.title)]}
                            value={currentTabHotel}
                            handleChange={handleChangeHotelTabs}
                            variant="scrollable"
                            allowScrollButtonsMobile={false}
                            useBrokenWord={true}
                        // tabs={Dashboardtabs.map(formatTabName)}
                        // labels={Dashboardtabs}
                        // value={currentTabHotel}
                        // handleChange={handleChangeHotelTabs}
                        // variant="scrollable"
                        // scrollButtons="auto"
                        // allowScrollButtonsMobile={false}
                        />
                        {/* </CustomContainer> */}

                        <Box
                            sx={{
                                m: "32px 0px",
                                marginTop: "0px !important",
                                padding: "20px 20px",
                                border: `1px solid #e3e3e3`,
                                borderRadius: "5px",
                                borderTop: "none",
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                lineHeight: 1.2,
                            }}
                        >
                            <Box>
                                {tabsData.map((tabData: any, index: number) => (
                                    <TabPanel
                                        key={index}
                                        currentTab={currentTabHotel}
                                        tab={tabData.title}
                                    >
                                        {tabData.sortOrder === 8
                                            ? renderImportantInfoData()
                                            : currentTabHotel === tabData.title && (
                                                <Box
                                                    dangerouslySetInnerHTML={{ __html: tabData.html }}
                                                />
                                            )}
                                    </TabPanel>
                                ))}

                                {tripHotelDetails?.reviews_rating && (
                                    <TabPanel currentTab={currentTabHotel} tab={"Reviews"}>
                                        {currentTabHotel === "Reviews" && (
                                            <ReviewsTab
                                                tripAdvisorData={tripAdvisorData}
                                                tripHotelDetails={tripHotelDetails}
                                                reviewsTripAdvisorData={reviewsTripAdvisorData}
                                            />
                                        )}
                                    </TabPanel>
                                )}
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ pt: { md: "0", xs: "24px" }, mb: 1 }} ref={scrollableRef}>
                        {" "}
                        <StaticAccordion
                            reviewsTripAdvisorData={reviewsTripAdvisorData}
                            apiCallAccordianData={apiCallAccordianData}
                            setApicallAccordianData={setApicallAccordianData}
                            openReviewTab={openReviewTab}
                            setOpenReviewTab={setOpenReviewTab}
                            reviewData={
                                tripHotelDetails?.reviews_rating
                                    ? {
                                        sortOrder: 0,
                                        id: 0,
                                        title: "Reviews",
                                        html: "<p>Reviews here</p>",
                                    }
                                    : {} // or undefined to exclude the Reviews tab
                            }
                            tripAdvisorData={tripAdvisorData}
                            tripHotelDetails={tripHotelDetails}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default HotelInfoModal;
